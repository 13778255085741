export const CustomerStatus = {
  INACTIVE: 0,
  ACTIVATION_IN_PROGRESS: 1,
  ACTIVE: 2,
  SUSPENDED: 3,
  DISCONNECTED: 4,
  HOTLINED: 5,
  REJECTED: 6,
  DISABLED: 7,
  ACTIVATION_FAILED: 8,
  PORTING_IN_PROGRESS: 9,
  PORTING_FAILED: 10,
  PORT_CANCELLED: 11,
  TERMINATED: 13,
};

export const ActivationStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED"
}

export const LastDisconnectedReason = {
  TRIGGER_BY_USER: 0,
  BILL_DUE: 1,
  PORTED_OUT: 2,
  REQUESTED_BY_CS: 3,
  ZERO_USAGE: 4,
  NON_PAY_DISCONNECT: 5,
};