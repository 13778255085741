import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import Cookies from "js-cookie";

type InitialState = {
  addCartItemsSuccess: any;
  addCartItemsError: any;
  updateCartItemsSuccess: any;
  updateCartItemsError: any;
  updateShippingMethodsSuccess: any;
  updateShippingMethodsError: any;
  getCartItemsSuccess: any;
  getCartItemsError: any;
  eye4FraudLoader: boolean;
  eye4FraudSuccess: any;
  eye4FraudError: any;
  fetchCountriesSuccess: any;
  fetchCountriesError: any;
  fetchStatesSuccess: any;
  fetchStatesError: any;
  shopwareAuthSuccess: any;
  shopwareAuthError: any;
  deleteCartItemsSuccess: any;
  deleteCartItemsError: any;
  createAddressOnShopwareSuccess: any;
  createAddressOnShopwareError: any;
  defaultShippingAddressOnShopwareSuccess: any;
  defaultShippingAddressOnShopwareError: any;
  defaultBillingAddressOnShopwareSuccess: any;
  defaultBillingAddressOnShopwareError: any;
  updateAddressOnShopwareSuccess: any;
  updateAddressOnShopwareError: any;
  updateShopwareEmailSuccess: any;
  updateShopwareEmailError: any;
  getCustomerFromShopwareSuccess: any;
  getCustomerFromShopwareError: any;
  getAddressFromShopwareSuccess: any;
  getAddressFromShopwareError: any;
};
const initialState: InitialState = {
  addCartItemsSuccess: null,
  addCartItemsError: null,
  getCartItemsSuccess: null,
  updateCartItemsSuccess: null,
  updateCartItemsError: null,
  updateShippingMethodsSuccess: null,
  updateShippingMethodsError: null,
  getCartItemsError: null,
  eye4FraudLoader: false,
  eye4FraudSuccess: null,
  eye4FraudError: null,
  fetchCountriesSuccess: null,
  fetchCountriesError: null,
  fetchStatesSuccess: null,
  fetchStatesError: null,
  shopwareAuthSuccess: null,
  shopwareAuthError: null,
  deleteCartItemsSuccess: null,
  deleteCartItemsError: null,
  createAddressOnShopwareSuccess: null,
  createAddressOnShopwareError: null,
  defaultShippingAddressOnShopwareSuccess: null,
  defaultShippingAddressOnShopwareError: null,
  updateAddressOnShopwareSuccess: null,
  updateAddressOnShopwareError: null,
  updateShopwareEmailSuccess: null,
  updateShopwareEmailError: null,
  getCustomerFromShopwareSuccess: null,
  getCustomerFromShopwareError: null,
  getAddressFromShopwareSuccess: null,
  getAddressFromShopwareError: null,
  defaultBillingAddressOnShopwareSuccess: null,
  defaultBillingAddressOnShopwareError: null,
};

export const addCartItems = createAsyncThunk(
  "shopWare/addCartItems",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.shopWareAddToCart}`,
        body: action?.data,
        isShopWare: true,
        domain: "custom",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateCartItems = createAsyncThunk(
  "shopWare/updateCartItems",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "patch",
        url: `${ApiEndPoints.shopWareUpdateCart}`,
        body: action,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateShippingMethods = createAsyncThunk(
  "shopWare/updateShippingMethods",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "patch",
        url: `${ApiEndPoints.shopWareUpdateShippingMethods}`,
        body: action,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getCartItems = createAsyncThunk(
  "shopWare/getCartItems",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.shopWareGetCart}`,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const eye4Fraud = createAsyncThunk(
  "shopWare/eye4Fraud",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.shopWareEye4fraud}`,
        isShopWare: true,
        domain: "custom",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const fetchCountries = createAsyncThunk(
  "shopWare/fetchCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.shopWareGetCountries}`,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const fetchStates = createAsyncThunk(
  "shopWare/fetchStates",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `/countries/${action}/states`,
        isShopWare: true,
        domain: "custom",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const shopwareAuth = createAsyncThunk(
  "shopWare/shopwareAuth",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.shopWareAuth}`,
        body: action.data,
        isShopWare: true,
        domain: "custom",
        isBearerTokenRequired: true,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const deleteCartItems = createAsyncThunk(
  "shopWare/deleteCartItems",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "delete",
        url: `${ApiEndPoints.shopWareDeleteCart}=${action}`,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getAddressesFromShopware = createAsyncThunk(
  "shopWare/getAddressesFromShopware",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.shopWareGetAddress}`,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createAddressOnShopware = createAsyncThunk(
  "shopWare/createAddressOnShopware",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.shopWareCreateAddress}`,
        body: action,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const defaultShippingAddressOnShopware = createAsyncThunk(
  "shopWare/defaultShippingAddressOnShopware",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "patch",
        url: `${ApiEndPoints.shopWareDefaultAddressOnShopware}${action}`,
        isShopWare: true,
        body: action,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
export const defaultBillingAddressOnShopware = createAsyncThunk(
  "shopWare/defaultBillingAddressOnShopware",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "patch",
        url: `${ApiEndPoints.shopWareDefaultBillingAddressOnShopware}${action}`,
        isShopWare: true,
        body: action,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateAddressOnShopware = createAsyncThunk(
  "shopWare/updateAddressOnShopware",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "patch",
        url: `${ApiEndPoints.shopWareUpdateAddress}${action?.addressId}`,
        isShopWare: true,
        body: action,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateShopwareEmail = createAsyncThunk(
  "shopWare/updateShopwareEmail",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.shopWareEmailUpdate}`,
        body: action,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getCustomerFromShopware = createAsyncThunk(
  "shopWare/getCustomerFromShopware",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.shopWareGetCustomer}`,
        isShopWare: true,
        domain: "shopware",
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const shopWareSlice = createSlice({
  name: "shopWare",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      addCartItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addCartItemsSuccess = action.payload?.data?.data;
        state.addCartItemsError = null;
        if (action.payload?.data) {
          let listofItems: any = action.payload?.data?.data?.lineItems.map(
            (ele: any) => ele
          );
          localStorage.setItem("lineItems", JSON.stringify(listofItems ?? []));
          let swToken = action.payload?.data?.data?.token;
          if (swToken) {
            Cookies.set("swContextToken", `${swToken}`);
          }
        }
      }
    );
    builder.addCase(addCartItems.rejected, (state, action) => {
      state.addCartItemsSuccess = null;
      state.addCartItemsError = action.payload;
    });
    builder.addCase(
      updateCartItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCartItemsSuccess = action.payload?.data;
        state.updateCartItemsError = null;
        if (action.payload?.data) {
          let listofItems: any = action.payload?.data?.lineItems.map(
            (ele: any) => ele
          );
          localStorage.setItem("lineItems", JSON.stringify(listofItems ?? []));
        }
      }

    );
    builder.addCase(updateCartItems.rejected, (state, action) => {
      state.updateCartItemsSuccess = null;
      state.updateCartItemsError = action.payload;
    });
    builder.addCase(
      updateShippingMethods.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateShippingMethodsSuccess = action.payload?.data;
        state.updateShippingMethodsError = null;
      }
    );
    builder.addCase(updateShippingMethods.rejected, (state, action) => {
      state.updateShippingMethodsSuccess = null;
      state.updateShippingMethodsError = action.payload;
    });
    builder.addCase(
      getCartItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getCartItemsSuccess = action.payload?.data;
        state.getCartItemsError = null;
        if (action.payload?.data) {
          let listofItems: any = action.payload?.data?.lineItems.map(
            (ele: any) => ele
          );
          localStorage.setItem("lineItems", JSON.stringify(listofItems ?? []));
          let swToken = action.payload?.data?.token;
          if (swToken) {
            Cookies.set("swContextToken", `${swToken}`);
          }
        }
      }
    );
    builder.addCase(getCartItems.rejected, (state, action) => {
      state.getCartItemsSuccess = null;
      state.getCartItemsError = action.payload;
    });
    builder.addCase(eye4Fraud.pending, (state) => {
      state.eye4FraudLoader = true;
    });
    builder.addCase(
      eye4Fraud.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.eye4FraudSuccess = action.payload?.data;
        state.eye4FraudError = null;
        state.eye4FraudLoader = false;
      }
    );
    builder.addCase(eye4Fraud.rejected, (state, action) => {
      state.eye4FraudSuccess = null;
      state.eye4FraudError = action.payload;
      state.eye4FraudLoader = false;
    });

    builder.addCase(
      fetchCountries.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetchCountriesSuccess = action.payload?.data;
        state.fetchCountriesError = null;
      }
    );
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.fetchCountriesSuccess = null;
      state.fetchCountriesError = action.payload;
    });
    builder.addCase(
      fetchStates.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetchStatesSuccess = action.payload?.data;
        state.fetchStatesError = null;
      }
    );
    builder.addCase(fetchStates.rejected, (state, action) => {
      state.fetchStatesSuccess = null;
      state.fetchStatesError = action.payload;
    });
    builder.addCase(
      shopwareAuth.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.shopwareAuthSuccess = action.payload?.data;
        state.shopwareAuthError = null;
      }
    );
    builder.addCase(shopwareAuth.rejected, (state, action) => {
      state.shopwareAuthSuccess = null;
      state.shopwareAuthError = action.payload;
    });
    builder.addCase(
      deleteCartItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteCartItemsSuccess = action.payload?.data;
        state.deleteCartItemsError = null;
      }
    );
    builder.addCase(deleteCartItems.rejected, (state, action) => {
      state.deleteCartItemsSuccess = null;
      state.deleteCartItemsError = action.payload;
    });
    builder.addCase(
      createAddressOnShopware.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createAddressOnShopwareSuccess = action.payload?.data;
        state.createAddressOnShopwareError = null;
      }
    );
    builder.addCase(createAddressOnShopware.rejected, (state, action) => {
      state.createAddressOnShopwareSuccess = null;
      state.createAddressOnShopwareError = action.payload;
    });
    builder.addCase(
      defaultShippingAddressOnShopware.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.defaultShippingAddressOnShopwareSuccess = action.payload?.data;
        state.defaultShippingAddressOnShopwareError = null;
      }
    );
    builder.addCase(
      defaultShippingAddressOnShopware.rejected,
      (state, action) => {
        state.defaultShippingAddressOnShopwareSuccess = null;
        state.defaultShippingAddressOnShopwareError = action.payload;
      }
    );
    builder.addCase(
      defaultBillingAddressOnShopware.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.defaultBillingAddressOnShopwareSuccess = action.payload?.data;
        state.defaultBillingAddressOnShopwareError = null;
      }
    );
    builder.addCase(
      defaultBillingAddressOnShopware.rejected,
      (state, action) => {
        state.defaultBillingAddressOnShopwareSuccess = null;
        state.defaultBillingAddressOnShopwareError = action.payload;
      }
    );
    builder.addCase(
      updateAddressOnShopware.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateAddressOnShopwareSuccess = action.payload?.data;
        state.updateAddressOnShopwareError = null;
      }
    );
    builder.addCase(updateAddressOnShopware.rejected, (state, action) => {
      state.updateAddressOnShopwareSuccess = null;
      state.updateAddressOnShopwareError = action.payload;
    });
    builder.addCase(
      getCustomerFromShopware.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getCustomerFromShopwareSuccess = action.payload?.data;
        state.getCustomerFromShopwareError = null;
      }
    );
    builder.addCase(getCustomerFromShopware.rejected, (state, action) => {
      state.getCustomerFromShopwareSuccess = null;
      state.getCustomerFromShopwareError = action.payload;
    });
    builder.addCase(
      getAddressesFromShopware.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAddressFromShopwareSuccess = action.payload?.data;
        state.getAddressFromShopwareError = null;
      }
    );
    builder.addCase(getAddressesFromShopware.rejected, (state, action) => {
      state.getAddressFromShopwareSuccess = null;
      state.getAddressFromShopwareError = action.payload;
    });
  },
});

export default shopWareSlice.reducer;
