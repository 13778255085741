import React, { useEffect, useState } from "react";
import {
  getCounriesList,
  getCounriesListATT,
} from "../../../../features/internationalService/internationalServiceSlice";
import { useAppDispatch } from "../../../../app/hooks";
import _ from "lodash";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
import AppConfig from "../../../../config/app.config.json";
import Config from "../../../../config/env.config.json";

const ViewRatesComp = isTemplateExisits(
  AppConfig.PostActivationServices.ILDViewRates
)
  ? require(`./${AppConfig.PostActivationServices.ILDViewRates.template}_ViewRates`)
      .default
  : null;

const ViewRates = (props: any) => {
  const [getCountryList, setGetCountryList] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [isCountryModal, setIsCountryModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Config.IS_ATT_COUNTRY_LIST) {
      dispatch(getCounriesListATT()).then((res) => {
        let cList = res?.payload?.data?.data?.countriesList;
        cList = _.sortBy(cList, "countryName");
        cList = cList.filter((item: any) => item.ildStatus === 1);
        setGetCountryList(cList);
      });
    } else {
      dispatch(getCounriesList()).then((res) => {
        let cList = res?.payload?.data?.data?.countriesList;
        cList = _.sortBy(cList, "name");
        setGetCountryList(cList);
      });
    }
  }, []);

  const setSelectedCountryHandler = (e: any, val: any) => {
    if (val) {
      setSelectedCountry(val);
      setTimeout(() => setIsCountryModal(true), 500);
      const countryObject = {
        code: val.code ? val.code : val.countryCode,
        name: val.name ? val.name : val.countryName,
      };
      localStorage.setItem("prRoamingCountry", JSON.stringify(countryObject));
    } else {
      setSelectedCountry(""); // Clear the selected country in your component's state
      localStorage.removeItem("prRoamingCountry");
    }
  };

  const renderOption = (props: any, option: any) => {
    if (props.customRenderOption) {
      return props.customRenderOption(props, option);
    } else {
      return (
        <span
          {...props}
          style={{ fontFamily: "var(--font_family_Medium)", fontSize: "16px" }}
        >
          {option.name ? option.name : option.countryName}
        </span>
      );
    }
  };

  const countryModalHandler = () => {
    setIsCountryModal(false);
    setSelectedCountry("");
  };

  return (
    <>
      <ViewRatesComp
        {...props}
        getCountryList={getCountryList}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        isCountryModal={isCountryModal}
        renderOption={renderOption}
        countryModalHandler={countryModalHandler}
        setSelectedCountryHandler={setSelectedCountryHandler}
      />
    </>
  );
};

export default ViewRates;
