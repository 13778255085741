import WalletCard from "../HistoryCard/WalletCard";
import { useAppSelector } from "../../../../../app/hooks";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { format } from "date-fns";
import _ from "lodash";
import DropDownMenu from "../../../../widgets/DropDownMenu/DropDownMenu";
import { CardTypes } from "../../../../../enums";

const T3_TransactionHistory = (props: any) => {
  const { less_icon, greater_icon, no_data_img, card_type } = props.content;

  const { date, amount, card, no_transaction } = props.content.wh_data;

  const {
    userList,
    handleLineItemClick,
    handleLineClose,
    lineAnchorEl,
    handleClickLineItem,
    selectedLineIndex,
    lineOpen,
    toggleButtonText,
    handleViewMoreClick,
    walletuserData,
    getStartAndEndDateData,
    newFilteredData,
    itemsToShow,
    formatDate,
  } = props;
  const { getTransactionHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "end" },
        }}
        data-testid="tranc_hist_wrapper"
      >
        <DropDownMenu
          statuslist={userList}
          handleMenuItemClick={handleLineItemClick}
          handleClose={handleLineClose}
          menuAnchorEl={lineAnchorEl}
          handleClickListItem={handleClickLineItem}
          selectedIndex={selectedLineIndex}
          menuOpen={lineOpen}
          selectedIcon={props.content.select_img}
          uparrowIcon={props.content.up_arrow_img}
          downarrowIcon={props.content.down_arrow_img}
          styleSX={{
            backgroundColor: "transparent !important",
            "& .MuiListItemText-secondary": {
              color: "var(--text_color) !important",
              fontSize: "16px !important",
              linHeight: "28px !important",
              fontWeight: "var(--font_weight_1) !important",
              fontFamily: "var(--font_family_Medium) !important",
            },
            "& .MuiList-root": {
              padding: { xs: "0px", sm: "0px 8px" },
            },
          }}
        />
      </Box>
      {walletuserData ? (
        <WalletCard lineDataForWallet={walletuserData} content={props.content}>
          {!getTransactionHistoryPending ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {newFilteredData?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    mt: "20px",
                  }}
                >
                  <Box
                    onClick={() => {
                      if (
                        getStartAndEndDateData.totalNumberOfKeys > 0 &&
                        props.selectedDateKeyIndex > 0
                      ) {
                        props.handleBillCycleChange("decrement");
                      }
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={less_icon.data.attributes.url}
                      alt={less_icon.data.attributes.alternativeText}
                      data-testid="tranc_hist_dec_icon"
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_1)",
                      fontFamily: "var(--font_family_Medium)",
                    }}
                    data-testid="tranc_hist_start_date"
                  >
                    {getStartAndEndDateData?.displayStartDate
                      ? format(
                          new Date(getStartAndEndDateData.displayStartDate),
                          "MMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                  <span>-</span>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_1)",
                      fontFamily: "var(--font_family_Medium)",
                    }}
                    data-testid="tranc_hist_end_date"
                  >
                    {getStartAndEndDateData?.displayEndDate
                      ? format(
                          new Date(getStartAndEndDateData.displayEndDate),
                          "MMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                  <Box
                    onClick={() => {
                      if (
                        getStartAndEndDateData.totalNumberOfKeys > 0 &&
                        props.selectedDateKeyIndex !==
                          getStartAndEndDateData.totalNumberOfKeys - 1
                      ) {
                        props.handleBillCycleChange("increment");
                      }
                    }}
                    data-testid="tranc_hist_inc"
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={greater_icon.data.attributes.url}
                      alt={greater_icon.data.attributes.alternativeText}
                      data-testid="tranc_hist_inc_icon"
                    />
                  </Box>
                </Box>
              )}
              {newFilteredData?.length === 0 && (
                <Box sx={{ padding: { xs: "20px", sm: "80px" } }}>
                  <Box
                    component="img"
                    src={no_data_img.data.attributes?.url}
                    alt={no_data_img.data.attributes.alternativeText}
                    data-testid="tranc_hist_no_data_icon"
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", sm: "34px" },
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      opacity: 0.4,
                    }}
                    data-testid="tranc_hist_no_tranc"
                  >
                    {no_transaction}
                  </Typography>
                </Box>
              )}
              {newFilteredData?.length !== 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: { xs: "100%", md: "85%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: "1", // Allow the first column to grow
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                    data-testid="tranc_hist_date"
                  >
                    {date}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                    data-testid="tranc_hist_amount"
                  >
                    {amount}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                    data-testid="tranc_hist_card"
                  >
                    {card}
                  </Typography>
                </Box>
              ) : null}
              {_.orderBy(newFilteredData, ["purchaseDate"], ["desc"])
                ?.slice(0, itemsToShow)
                .map((data: any, index: any) => (
                  <Box
                    key={data.uuid}
                    sx={{
                      flex: "1",
                      display: "flex",
                      justifyContent: "space-between",
                      width: { xs: "100%", md: "85%" },
                      margin: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        flex: "1", // Allow the first column to grow
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                      }}
                      data-testid={`tranc_hist_card_puch_date_{Number(index) + 1 || 1}`}
                    >
                      {formatDate(data.purchaseDate)}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                      }}
                      data-testid={`tranc_hist_card_puch_cred_${Number(index) + 1 || 1}`}
                    >
                      {GetCurrencyFixedToTwo(data.purchasedCredit)}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={
                          data.cardType?.toUpperCase() === CardTypes?.VISA
                            ? card_type?.data[0]?.attributes?.url
                            : data.cardType?.toUpperCase() ===
                              CardTypes?.DISCOVER
                            ? card_type?.data[1]?.attributes?.url
                            : data.cardType?.toUpperCase() ===
                              CardTypes?.AMERICANEXPRESS
                            ? card_type?.data[2]?.attributes?.url
                            : data.cardType?.type?.toUpperCase() ===
                              CardTypes?.MASTERCARD
                            ? card_type?.data[3]?.attributes?.url
                            : card_type?.data[0]?.attributes?.url
                        }
                        data-testid={`tranc_hist_card_puch_img_${Number(index) + 1 || 1}`}
                        alt={card_type?.data[0].attributes.alternativeText}
                        width={26}
                        height={16}
                      />
                      xx
                      {data.ccNumberMask}
                    </Typography>
                  </Box>
                ))}

              {newFilteredData?.length > 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    onClick={handleViewMoreClick}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      cursor: "pointer",
                      marginBottom: "5px",
                      color: "var(--primary_color)",
                    }}
                    data-testid="tranc_hist_card_toogle_text"
                  >
                    {toggleButtonText()}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box textAlign={"center"}>
              <CircularProgress sx={{ color: "var(--primary_color)" }} data-testid="tranc_hist_card_progress" />
            </Box>
          )}
        </WalletCard>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} data-testid="tranc_hist_card_progress" />
        </Box>
      )}
    </>
  );
};

export default T3_TransactionHistory;
