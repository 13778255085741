import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { ButtonCust } from "../../../widgets";
import {
  calculateCost,
  calculateUnlimitedCost,
  calculateUnlimitedCostMnM,
  PlanProps,
} from "./PlanCard";
import styles from "./S13_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useEffect, useState } from "react";
import { NutritionLabel } from "../NutritionLabel/NutritionLabel";
import { getConfigKeys } from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAppData } from "../../../../features/checkout/checkoutSlice";
import {
  caclulatePlanCostBasedOnEligibility,
  gtmTagManager,
  setCartHeader,
  shopWareRedirectionHandler,
  updateCartPayload,
  useNonInitialEffect,
} from "../../../../utils";
import { gaScreenNames } from "../../../../Types/GoogleAnalytics";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import unlimitedPlanDiscount from "../../../../config/discountCouponMNM.json";
import { nutritionLabels } from "../../../../features/planpage/planpageSlice";
import {
  addCartItems,
  deleteCartItems,
  getCartItems,
  updateCartItems,
} from "../../../../features/shopWare/shopWareSlice";
import { SimTypes } from "../../../../features/checkout/Types";

export const priceCalculationHandler: any = (
  data: any,
  selectedLine: any = 1
) => {
  let PlanDiscountCost, planPerLinesCost: any;
  if (data?.isUnlimited) {
    const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);
    PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
      planCost,
      unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
    );
  } else {
    const { planCost } = calculateCost(data, selectedLine);
    PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
      planCost,
      unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
    );
  }
  planPerLinesCost = PlanDiscountCost / selectedLine;
  return planPerLinesCost;
};

const T13_PlanCard = (props: PlanProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAnswer, setShowAnswer] = useState<any>(true);
  const [filteredPlans, setFilteredPlans] = useState<any>({});
  const [selectedPlanDetail, setSelectedPlanDetail] = useState<any>([]);
  const [disablePlanSelection, setDisablePlanSelection] = useState<any>(false);
  const [deviceObject, setDeviceObject] = useState({} as any);
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const { addCartItemsSuccess } = useAppSelector(
    (state: any) => state.shopWare
  );
  const { subDomainData } = useAppSelector((state: any) => state.homepage);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(nutritionLabels());
    dispatch(getConfigKeys());
  }, []);
  const {
    plandata,
    planContent,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    planSelectionHandler,
  } = props;
  useNonInitialEffect(() => {
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify(selectedPlanDetail)
    );

    let countByName = count(selectedPlanDetail, (item: any) => {
      return item?.plan?.displayName;
    });
    setFilteredPlans(countByName);
  }, [selectedPlanDetail]);

  useNonInitialEffect(() => {
    if (
      Config?.IS_SHOPWARE_ENABLED &&
      subDomainData?.data?.shopwareAccessKey &&
      addCartItemsSuccess?.lineItems
    ) {
      setDisablePlanSelection(false);
    }
  }, [addCartItemsSuccess]);

  // useEffect(() => {
  //   let selectedPlanDetails = localStorage.getItem("reachSelectedPlanDetail");
  //   if (selectedPlanDetails && selectedPlanDetails?.length > 0) {
  //     selectedPlanDetails = JSON.parse(selectedPlanDetails) || [];
  //     setSelectedPlanDetail(selectedPlanDetails);
  //   }
  // }, []);
  const selectedPlanDetailsData = localStorage.getItem(
    "reachSelectedPlanDetail"
  );
  useEffect(() => {
    if (selectedPlanDetailsData && selectedPlanDetailsData.length > 0) {
      const data = JSON.parse(selectedPlanDetailsData) || [];
      setSelectedPlanDetail(data);
    } else {
      setSelectedPlanDetail([]);
    }
  }, [selectedPlanDetailsData]);

  function planDetailFormat(planData?: any) {
    const planArr = [];
    for (const item in planData) {
      planArr.push(planData[item]);
    }
    return planArr;
  }

  useEffect(() => {
    if (Config?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
      dispatch(getCartItems()).then((res?: any) => {
        const lineItems = res?.payload?.data?.lineItems || [];
        const deviceData = [];
        const planData = [];

        for (const item of lineItems) {
          if (item?.payload?.linePreferences) {
            planData.push(...planDetailFormat(item?.payload?.linePreferences));
          } else {
            deviceData.push(item);
          }
        }

        localStorage.setItem(
          "reachSelectedDeviceDetail",
          JSON.stringify(deviceData)
        );

        const obj = {
          deviceTotalQuantity: 0,
          quantity: 0,
          topLabel: "",
        } as any;

        for (let i = 0; i < deviceData.length; i++) {
          obj.deviceTotalQuantity += deviceData?.[i]?.quantity;
          if (i === 0) {
            obj.topLabel = deviceData?.[i]?.label;
            obj.quantity = deviceData?.[i]?.quantity;
          }
        }

        setDeviceObject(obj);
        const existingPlanData = JSON.parse(
          localStorage.getItem("reachSelectedPlanDetail") || "[]"
        );
        const formattedExistingPlanData = existingPlanData.map((item: any) =>
          JSON.stringify(item)
        );
        const formattedPlanData = planData.map((item: any) =>
          JSON.stringify(item)
        );

        const isPlanDataChanged =
          formattedPlanData.length !== formattedExistingPlanData.length ||
          formattedPlanData.some(
            (item: string) => !formattedExistingPlanData.includes(item)
          );
        if (isPlanDataChanged && planData?.length > 0) {
          localStorage.setItem(
            "reachSelectedPlanDetail",
            JSON.stringify(planData)
          );
          setSelectedPlanDetail(planData);
        }
      });
    }
  }, []);

  const count = (ary: any, classifier: any) => {
    classifier = classifier || String;
    return ary?.reduce(function (counter: any, item: any) {
      var p = classifier(item);
      counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
      return counter;
    }, {});
  };

  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    fade_out,
    home_page_plan_card,
    tag,
    wrapper_div,
    mb_img,
  } = styles;
  const {
    per_month_text,
    mbl_img,
    video_img,
    tax_text,
    btn_txt,
    data,
    hd_vid,
    sd_vid,
    mb_data,
    hotspot_img,
    no_hpt_fee,
    res,
    res1,
    res2,
    checkout_cta,
    of_txt,
    lines_selected,
    plan_loader_numer,
    added,
    more_plan,
    s,
    cart_cta,
  } = planContent || {};
  const array = Array.from(
    { length: plan_loader_numer ?? 4 },
    (_, index) => index + 1
  );
  const getQuantity = (p: any) => {
    let quantity = 0;

    // Check if selectedPlanDetail is an array
    if (!Array.isArray(selectedPlanDetail)) {
      return quantity; // return 0 if selectedPlanDetail is not an array
    }

    selectedPlanDetail.forEach((el: any) => {
      if (el?.plan?.displayName === p?.displayName) {
        quantity++;
      }
    });

    return quantity;
  };

  const handleAddToCartShopware = (
    planStoreData: any,
    linePreferenceData: any,
    isButtonClick?: any
  ) => {
    dispatch(
      addCartItems({
        data: {
          planName: planStoreData?.[0].plan?.displayName,
          reachPlanId: planStoreData?.[0].plan?.name,
          quantity: parseInt(planStoreData?.length),
          price: planStoreData?.[0]?.plan?.baseLinePrice,
          discounts: [
            {
              name: "",
              percentage: "",
              applicability: "",
              eligibilityCriteria: [],
              endPointToVerifyDiscount: "",
              eligibilityStatus: "",
            },
          ],
          linePreferences: linePreferenceData,
          shippingOption: "usps_first_class_mail",
        },
      })
    ).then((resp: any) => {
      if (isButtonClick) {
        if (resp?.payload?.status === 200) {
          setDisablePlanSelection(false);
          const addCartResponse = resp?.payload?.data?.data;
          let lineItemsInCart: any = addCartResponse?.lineItems;
          let allProductName =
            lineItemsInCart &&
            lineItemsInCart.map((data: any) => data?.payload?.productNumber);
          let productNumberWithData = allProductName?.filter((data: any) =>
            data.includes("DATA")
          );
          if (allProductName?.length === productNumberWithData?.length) {
            navigate(RoutingURLs.checkout);
          } else {
            shopWareRedirectionHandler(Config?.SHOP_DOMAIN, "cart");
          }
        } else {
          setDisablePlanSelection(false);
          navigate(RoutingURLs.checkout);
        }
      } else {
        setDisablePlanSelection(false);
      }
    });
  };

  const handleUpdateCartShopware = (isButtonClick?: any) => {
    let payload = updateCartPayload(selectedPlanDetail);
    dispatch(updateCartItems(payload)).then((resp: any) => {
      if (resp?.payload?.status === 200 && isButtonClick) {
        setDisablePlanSelection(false);
        const updateCartResponse = resp?.payload?.data;
        let lineItemsInCart: any = updateCartResponse.lineItems;
        let allProductName =
          lineItemsInCart &&
          lineItemsInCart.map((data: any) => data?.payload?.productNumber);
        let productNumberWithData = allProductName?.filter((data: any) =>
          data.includes("DATA")
        );
        if (allProductName?.length === productNumberWithData?.length) {
          navigate(RoutingURLs.checkout);
        } else {
          shopWareRedirectionHandler(Config?.SHOP_DOMAIN, "cart");
        }
      } else {
        setDisablePlanSelection(false);
        navigate(RoutingURLs.checkout);
      }
    });
  };

  const handleAddToCart = (planDetails: any) => {
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);
    const linePreferenceData: any = {};
    planDetails &&
      planDetails.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei ? data.imei?.toString() : "",
          simPreference:
            data?.simPreference === SimTypes.eSim
              ? SimTypes.ESIM
              : SimTypes.phySim,
          plan: data?.plan,
          firstName: data?.firstName,
          lastName: data?.lastName,
          isPrimary: true,
          reachPlanId: data?.plan.name,
        };
      });
    handleAddToCartShopware(planDetails, linePreferenceData);
  };

  const handleNavigationToCart = (isButtonClick?: any) => {
    if (Config?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
      handleAddorUpdateShopwareCart(isButtonClick);
    } else {
      navigate(RoutingURLs.checkout);
    }
  };

  const deleteCart = () => {
    let lineItemsInCart: any = localStorage.getItem("lineItems");
    lineItemsInCart = JSON.parse(lineItemsInCart);
    let deletionId: any = lineItemsInCart[0]?.id;
    if (deletionId) {
      dispatch(deleteCartItems(deletionId)).then((res: any) => {
        if (res) {
          dispatch(getCartItems());
          setDisablePlanSelection(false);
        }
      });
    } else {
      setDisablePlanSelection(false);
    }
  };

  const handleUpdateCart = (planDetails: any) => {
    if (planDetails?.length < 1) {
      deleteCart();
    } else {
      let payload = updateCartPayload(planDetails);
      dispatch(updateCartItems(payload)).then((res: any) => {
        if (res) {
          setDisablePlanSelection(false);
        }
      });
    }
  };

  const handlePlanInCart = (type: any, planDetails: any) => {
    setDisablePlanSelection(true);
    if (type === "add" && planDetails?.length === 1) {
      handleAddToCart(planDetails);
    } else {
      handleUpdateCart(planDetails);
    }
  };

  const handleAddorUpdateShopwareCart = (isButtonClick?: any) => {
    setDisablePlanSelection(true);
    let planStoreData: any = localStorage.getItem("reachSelectedPlanDetail");
    planStoreData = JSON.parse(planStoreData);
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);
    const linePreferenceData: any = {};
    planStoreData &&
      planStoreData.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei ? data.imei?.toString() : "",
          simPreference:
            data?.simPreference === SimTypes.eSim
              ? SimTypes.ESIM
              : SimTypes.phySim,
          plan: data?.plan,
          firstName: data?.firstName,
          lastName: data?.lastName,
          isPrimary: true,
          reachPlanId: data?.plan.name,
        };
      });
    if (planStoreData?.length < 1) {
      deleteCart();
    } else if (planStoreData?.length === 1) {
      handleAddToCartShopware(planStoreData, linePreferenceData, isButtonClick);
    } else {
      handleUpdateCartShopware(isButtonClick);
    }
  };

  const selectPlanHandler = (
    _: any,
    plan: any,
    type?: string,
    firstLine?: boolean
  ) => {
    let planDetails;
    if (type === "add") {
      if (selectedPlanDetail.length + 1 <= configKeys?.MAX_LINES_GROUP) {
        let currentPlan = {
          plan: plan,
          firstName: "",
          lastName: "",
          imei: "",
          simPreference: "",
          isPrimary: "",
          simInHand: false,
          iccId: "",
        };
        planDetails = [...(selectedPlanDetail || []), currentPlan];
        setSelectedPlanDetail(planDetails);
        Config?.IS_SHOPWARE_ENABLED &&
          subDomainData?.data?.shopwareAccessKey &&
          handlePlanInCart(type, planDetails);
      }
      if (firstLine) {
        gtmTagManager({
          event: `mobile_${plan?.planData}gb_select_plan`,
          click_section: "home",
        });
      } else {
        gtmTagManager({
          event: `mobile_${plan?.planData}gb_add_line`,
          click_section: "home",
        });
      }
    } else if (type === "remove") {
      gtmTagManager({
        event: `mobile_${plan?.planData}gb_remove_line`,
        click_section: "home",
      });
      let selectedPlans = [...selectedPlanDetail];
      for (let i = 0; i < selectedPlans?.length; i++) {
        if (selectedPlans[i].plan?.displayName === plan?.displayName) {
          selectedPlans?.splice(i, 1);
          break;
        }
      }
      let updatedPlans = [...selectedPlans]; // changing the reference so that component is rerendered
      setSelectedPlanDetail(updatedPlans);
      Config?.IS_SHOPWARE_ENABLED &&
        subDomainData?.data?.shopwareAccessKey &&
        handlePlanInCart(type, updatedPlans);
    }
  };

  return configKeys ? (
    <>
      {Config?.IS_SHOPWARE_ENABLED &&
        subDomainData?.data?.shopwareAccessKey &&
        disablePlanSelection && (
          <Box
            sx={{
              opacity: 0.4,
              backgroundColor: "var(--white)",
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 1100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "var(--primary_color)" }} />
          </Box>
        )}
      <Stack className={wrapper_div}>
        <Grid container className={clsx(cards_div, plans_div)}>
          {plandata.length > 0 ? (
            plandata.map((el: any, index: number) => {
              const hotspotData = () => {
                if (el.planData >= 50) {
                  return 10;
                } else {
                  return 5;
                }
              };
              const { planCost, planPerLinesCost } = el?.unlimited
                ? calculateUnlimitedCost(el, selectedLine)
                : calculateCost(el, selectedLine);
              const isCurrentPlan =
                details?.reachPlanDisplayName === el?.displayName;
              return (
                <Card
                  id={`home${index}`}
                  key={index}
                  className={clsx(home_page_plan_card, plan_page_plan_card)}
                  raised
                  elevation={isMobile ? 0 : 8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "fit-content",
                  }}
                  data-testid={`home${Number(index) + 1 || 1}}`}
                >
                  {/* {isCurrentPlan && (
                  <>
                    {!tagLabel && (
                      <Box
                        className={tag}
                        sx={{
                          backgroundColor: tagBgColor
                            ? tagBgColor
                            : "var(--primary_background)",
                          color: tagTxtColor ? tagTxtColor : "var(--white)",
                        }}
                      >
                        {planContent?.current_plan}
                      </Box>
                    )}
                  </>
                )} */}
                  <Box sx={{ minHeight: "335px" }}>
                    <Typography
                      component={"h5"}
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontSize: { xs: "18px", md: "18px" },
                        color: "var(--dark-charcoal-fade)",
                        fontWeight: "500",
                        // my: "6px",
                        paddingBottom: "12px",
                        textAlign: "center",
                      }}
                      data-testid={`plan_plannsec_head_${
                        Number(index) + 1 || 1
                      }`}
                    >
                      {el.displayName}
                    </Typography>
                    <Typography
                      component={"div"}
                      sx={{
                        fontFamily: "var(--font_family_Bold)",
                        fontSize: { xs: "18px", md: "18px" },
                        color: "var(--dark-charcoal-fade)",
                        // my: "6px",
                        textAlign: "center",
                      }}
                      data-testid={`plan_plansec_data_${
                        Number(index) + 1 || 1
                      }`}
                    >
                      {el.planData + el.dataUnit}
                      {el.planType === "LSD" ? "+" : ""}
                    </Typography>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "32px",
                          fontFamily: "var(--font_family_Bold)",
                          color: "var(--primary_color)",
                        }}
                      >
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: "32px",
                            fontFamily: "var(--font_family_Bold)",
                            color: "inherit",
                          }}
                          data-testid={`plan_plannsec_cost_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          $
                          {GetCurrencyFixedToTwo(
                            el?.baseLinePrice ? el?.baseLinePrice : 0,
                            el.currency ? el.currency : "usd",
                            true
                          )}
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={{
                            fontFamily: "var(--font_family_Semibold)",
                            fontSize: "10px",
                            color: "var(--text_color_12)",
                          }}
                          data-testid={`plan_plannsec_per_month_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          {per_month_text}
                        </Typography>
                        <Typography
                          component={"div"}
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "var(--dark-charcoal-fade)",
                            // my: "10px",
                            marginBottom: "10px",
                          }}
                          data-testid={`plan_plannsec_tax_text_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          {tax_text}
                        </Typography>
                      </Typography>
                    </Box>
                    <Typography
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        pl: "25px",
                        my: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        alt={mbl_img?.data?.attributes?.alternativeText}
                        src={mbl_img?.data?.attributes?.url || ""}
                        width="15px"
                        // className={el?.planType !== "HSD" ? mb_img : }
                        data-testid={`plan_plannsec_boardbandfact_icon_1_${
                          Number(index) + 1 || 1
                        }`}
                      />
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontSize: "14px",
                          color: "var(--dark-charcoal-fade)",
                          ml: "15px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            el?.planType === "HSD"
                              ? res1?.buy_data
                              : res1?.streaming.replace(
                                  /plandata/g,
                                  el?.planData || ""
                                ),
                        }}
                        data-testid={`plan_plannsec_boardbandfact_text_1_${
                          Number(index) + 1 || 1
                        }`}
                      ></Typography>
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "start",
                        pl: "25px",
                        my: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        alt={hotspot_img?.data?.attributes?.alternativeText}
                        src={hotspot_img?.data?.attributes?.url || ""}
                        width="15px"
                        // className={el?.planType !== "HSD" ? mb_img : ""}
                        data-testid={`plan_plannsec_boardbandfact_icon_2_${
                          Number(index) + 1 || 1
                        }`}
                      />
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontSize: "14px",
                          color: "var(--dark-charcoal-fade)",
                          ml: "15px",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            el?.planType === "HSD"
                              ? res2?.buy_data
                              : res2?.streaming.replace(
                                  /hot/g,
                                  hotspotData || ""
                                ),
                        }}
                        data-testid={`plan_plannsec_boardbandfact_text_2_${
                          Number(index) + 1 || 1
                        }`}
                      ></Typography>
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        pl: "25px",
                        my: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        alt={mb_data?.data?.attributes?.alternativeText}
                        src={mb_data?.data?.attributes?.url}
                        width="15px"
                        data-testid={`plan_plannsec_boardbandfact_icon_3_${
                          Number(index) + 1 || 1
                        }`}
                      />
                      <Typography
                        component={"span"}
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontSize: "14px",
                          color: "var(--dark-charcoal-fade)",
                          ml: "15px",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            el?.planType === "HSD"
                              ? res?.buy_data
                              : res?.streaming.replace(
                                  /plandata/g,
                                  el?.planData || ""
                                ),
                        }}
                        data-testid={`plan_plannsec_boardbandfact_text_3_${
                          Number(index) + 1 || 1
                        }`}
                      ></Typography>
                    </Typography>
                  </Box>
                  <Box
                    className={el.maxLines >= selectedLine ? " " : fade_out}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // my: "15px",
                      mt: "auto",
                    }}
                    data-testid={`plan_plannsec_button_parent_${
                      Number(index) + 1 || 1
                    }`}
                  >
                    {/* <ButtonCust
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    className={el.maxLines >= selectedLine ? " " : fade_out}
                    onClick={(e: any) => {
                      el.maxLines >= selectedLine
                        ? planSelectionHandler(el, btn_txt)
                        : "";
                    }}
                  >
                    {btn_txt}
                  </ButtonCust> */}
                    {getQuantity(el) === 0 ? (
                      <Box
                        className={el.maxLines >= selectedLine ? " " : fade_out}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          my: "15px",
                        }}
                      >
                        <ButtonCust
                          sx={{
                            width: "200px !important",
                            height: "45px !important",
                            color: "#ffffff",
                            fontSize: "14px !important",
                            fontFamily:
                              "var(--font_family_Semibold) !important",
                          }}
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          className={
                            el.maxLines >= selectedLine ? " " : fade_out
                          }
                          onClick={(e: any) => {
                            // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                            //   ? shareablePlans?.maxLines >= selectedLine
                            //     ? selectPlanHandler(e, "byTheGig")
                            //     : " "
                            //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                            selectPlanHandler(e, el, "add", true);
                          }}
                          data-testid={`plan_plannsec_button_${
                            Number(index) + 1 || 1
                          }`}
                          disabled={
                            selectedPlanDetail.length >=
                            configKeys?.MAX_LINES_GROUP
                          }
                        >
                          {btn_txt}
                        </ButtonCust>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          my: "15px",
                        }}
                        className={clsx(
                          configKeys?.MAX_LINES_GROUP >= selectedLine
                            ? " "
                            : fade_out
                        )}
                        data-testid={`plan_plannsec_post_button_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            backgroundColor: "var(--primary_color)",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            height: "50px",
                            borderRadius: "60px",
                            minWidth: "128px",
                            width: "128px",
                            margin: "auto",
                          }}
                          className={clsx(
                            configKeys?.MAX_LINES_GROUP >= selectedLine
                              ? " "
                              : fade_out
                          )}
                          data-testid={`plan_plannsec_post_button_child_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          <Typography
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "var(--text_color_12)",
                              textAlign: "center",
                              cursor: "pointer",
                              backgroundColor: "var(--white)",
                              border: " 1px solid var(--white)",
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              lineHeight: "20px",
                              padding: 0,
                              margin: 0,
                            }}
                            onClick={(e: any) => {
                              // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                              //   ? shareablePlans?.maxLines >= selectedLine
                              //     ? selectPlanHandler(e, "byTheGig")
                              //     : " "
                              //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                              selectPlanHandler(e, el, "remove");
                            }}
                            data-testid={`plan_plannsec_post_button_remove_${
                              Number(index) + 1 || 1
                            }`}
                          >
                            <span
                              data-testid={`plan_plannsec_post_button_remove_child_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              -
                            </span>
                          </Typography>
                          <Typography
                            sx={{
                              width: "20%",
                              color: "white",
                              textAlign: "center",
                            }}
                            data-testid={`plan_plannsec_post_button_number_${
                              Number(index) + 1 || 1
                            }`}
                          >
                            {getQuantity(el)}
                          </Typography>
                          <Typography
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "var(--text_color_12)",
                              textAlign: "center",
                              cursor: "pointer",
                              backgroundColor: "var(--white)",
                              border: " 1px solid var(--white)",
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              lineHeight: "20px",
                              padding: 0,
                              margin: 0,
                            }}
                            onClick={(e: any) => {
                              // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                              //   ? shareablePlans?.maxLines >= selectedLine
                              //     ? selectPlanHandler(e, "byTheGig")
                              //     : " "
                              //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                              selectPlanHandler(e, el, "add");
                            }}
                            data-testid={`plan_plannsec_post_button_add_${
                              Number(index) + 1 || 1
                            }`}
                          >
                            <span
                              data-testid={`plan_plannsec_post_button_add_child_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              +
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <NutritionLabel
                      NLContent={planContent}
                      plandata={el}
                      planCost={planCost}
                      index={index}
                      showAnswer={showAnswer}
                      handleAnswer={handleAnswer}
                      isheading={true}
                    />
                  </Box>
                </Card>
              );
            })
          ) : (
            <>
              {array.map((el: any, index: number) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      margin: "15px",
                      maxWidth: "250px",
                      backgroundColor: "var(--white)",
                      padding: "20px",
                      gap: "10px",
                    }}
                    className={clsx(home_page_plan_card, plan_page_plan_card)}
                    data-testid={`plan_plannsec_loader_${
                      Number(index) + 1 || 1
                    }`}
                  >
                    <Skeleton variant="text" width={30} height={20} />
                    <Skeleton variant="text" width={100} height={20} />
                    <Skeleton variant="text" width={150} height={20} />
                    <Skeleton variant="rounded" width={200} height={60} />
                    <Box sx={{ display: "flex" }}>
                      <Skeleton variant="rounded" width={20} height={30} />
                      <Skeleton
                        variant="text"
                        width={75}
                        height={20}
                        sx={{ ml: "10px", padding: "10px 0px 0px" }}
                      />
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Skeleton variant="rounded" width={20} height={30} />
                      <Skeleton
                        variant="text"
                        width={75}
                        height={20}
                        sx={{ ml: "10px", padding: "10px 0px 0px" }}
                      />
                    </Box>

                    <Skeleton variant="text" width={75} height={30} />
                    <Box sx={{ display: "flex", textAlign: "center" }}>
                      <Skeleton variant="rounded" width={150} height={10} />
                      <Skeleton variant="circular" width={5} height={5} />
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
          {isMobile && (
            <Typography
              component={"div"}
              style={{
                opacity: 0,
              }}
            >
              div
            </Typography>
          )}
          {/* {selectedPlanDetail.length > 0 ? (
            <Box
              sx={{
                backgroundColor: "var(--white)",
                width: "100%",
                padding: "10px 0px",
                position: "fixed",
                left: 0,
                bottom: "0px",
                height: { xs: "auto" },
                zIndex: "999",
                boxShadow: "0px 0px 4px 2px #00000026",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "space-around" },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--primary_color)",
                        fontWeight: "var(--font_weight_5)",
                        fontSize: { xs: "24px", md: "45px" },
                        fontFamily: "var(--font_family_Bold)",
                      }}
                      data-testid="plan_plannsec_total_amount"
                    >
                      $
                      {selectedPlanDetail
                        .reduce((tot: any, arr: any) => {
                          return (
                            tot +
                            caclulatePlanCostBasedOnEligibility(
                              arr?.plan?.baseLinePrice,
                              0
                            )
                          );
                        }, 0)
                        .toFixed(2)}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        color: "var(--title-color_1)",
                        fontSize: "18px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                        py: { xs: "10px", sm: "0px" },
                      }}
                      data-testid="plan_plannsec_total_line_selcted"
                    >
                      {selectedPlanDetail.length} {of_txt}{" "}
                      {configKeys?.MAX_LINES_GROUP} {lines_selected}
                    </Typography>
                  </Box>
                  <Divider
                    orientation={isMobile ? "horizontal" : "vertical"}
                    sx={{
                      display: "flex",
                      m: { xs: "0px", sm: "21px" },
                      height: { xs: "0px", sm: "60px" },
                      width: { xs: "90%", sm: "unset" },
                      borderWidth: "1px",
                      borderColor: "var(--primary_color) !important",
                      opacity: "30%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", sm: "flex-start" },
                      justifyContent: "center",
                      flexDirection: "column",
                      width: { xs: "100%", sm: "fit-content" },
                      // borderLeft: { xs: "none", md: "1px solid #004282" },
                    }}
                  >
                    {filteredPlans &&
                      Object.keys(filteredPlans).map(
                        (displayName: any, index: any) => {
                          return (
                            <Typography
                              sx={{
                                fontSize: { xs: "16px", md: "20px" },
                                fontWeight: "var(--font_weight_4)",
                                fontFamily: "var(--font_family_Bold)",
                                padding: "2px",
                                color: "var(--title-color_1)",
                                pt: { xs: "10px", sm: "0px" },
                              }}
                              key={displayName}
                              data-testid={`plan_plannsec_planname_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {filteredPlans[displayName]} - {displayName}
                            </Typography>
                          );
                        }
                      )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "center" },
                    marginTop: { xs: "20px", md: "0" },
                  }}
                >
                  <ButtonCust
                    sx={{
                      width: { xs: "250px", sm: "230px" },
                      marginBottom: { xs: "20px", sm: "0px" },
                    }}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    data-testid="selectPlanHandlerissimpaid"
                    onClick={(e: any) => {
                      navigate(RoutingURLs.checkout);
                      gtmTagManager({
                        event: gaScreenNames.checkout,
                      });
                    }}
                  >
                    {checkout_cta}
                  </ButtonCust>
                </Box>
              </Box>
            </Box>
          ) : null} */}
          {selectedPlanDetail.length > 0 ||
          deviceObject?.deviceTotalQuantity ? (
            <Box
              sx={{
                backgroundColor: "var(--white)",
                width: "100%",
                // padding: "10px 0px",
                position: "fixed",
                left: 0,
                bottom: "0px",
                // height: { xs: "auto" },
                zIndex: "999",
                boxShadow: "0px 0px 4px 2px #00000026",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "space-between" },
                  flexDirection: { xs: "column", sm: "row" },
                  padding: { xs: "1.25rem 1.25rem", sm: "1.75rem 1.25rem" },
                  maxWidth: "1680px",
                  margin: "0 auto",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: { xs: "flex-start", sm: "flex-start" },
                        justifyContent: "center",
                        flexDirection: "column",
                        width: { xs: "100%", sm: "fit-content" },
                        // borderLeft: { xs: "none", md: "1px solid #004282" },
                      }}
                    >
                      {filteredPlans &&
                        Object.keys(filteredPlans).length > 0 && (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                              fontWeight: "var(--font_weight_2)",
                              fontFamily: "var(--font_family_Bold) !important",
                              padding: "2px",
                              color: "var(--dark-charcoal-fade)",
                              paddingX: { xs: "0", sm: "2.5rem" },
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>{Object.keys(filteredPlans)[0]}</span>
                            {Object.keys(filteredPlans).length > 1 ? (
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "0.875rem",
                                  lineHeight: "1.25rem",
                                  fontWeight: "var(--font_weight_1)",
                                  fontFamily: "var(--font_family_Regular)",
                                  color: "var(--dark-charcoal-fade)",
                                  marginLeft: "4px",
                                }}
                              >
                                + {Object.keys(filteredPlans).length - 1}{" "}
                                {more_plan}
                                {Object.keys(filteredPlans).length - 1 > 1
                                  ? `${s}`
                                  : ""}{" "}
                                {added}
                              </Typography>
                            ) : (
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "0.875rem",
                                  lineHeight: "1.25rem",
                                  fontWeight: "var(--font_weight_1)",
                                  fontFamily: "var(--font_family_Regular)",
                                  color: "var(--dark-charcoal-fade)",
                                  marginLeft: "4px",
                                }}
                              >
                                {` plan ${added}`}
                              </Typography>
                            )}
                          </Typography>
                        )}
                    </Box>

                    {selectedPlanDetail.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                          alignSelf: "flex-start",
                          paddingX: { xs: "0", sm: "2.5rem" },
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            color: "var(--dark-charcoal-fade)",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_1)",
                          }}
                        >
                          {selectedPlanDetail.length} {of_txt}{" "}
                          {configKeys?.MAX_LINES_GROUP} {"lines"}
                          {Object.keys(filteredPlans).length > 1
                            ? ``
                            : Object.keys(filteredPlans).length === 1 &&
                              Number(
                                filteredPlans[Object.keys(filteredPlans)[0]]
                              ) > 1
                            ? ``
                            : ""}{" "}
                          {added}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {deviceObject?.deviceTotalQuantity ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: { xs: "flex-start", sm: "flex-start" },
                          justifyContent: "center",
                          flexDirection: "column",
                          width: { xs: "100%", sm: "fit-content" },
                          // borderLeft: { xs: "none", md: "1px solid #004282" },
                        }}
                      >
                        {/* {filteredPlans && Object.keys(filteredPlans).length > 0 && ( */}
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            fontWeight: "var(--font_weight_2)",
                            fontFamily: "var(--font_family_Bold) !important",
                            padding: "2px",
                            color: "var(--dark-charcoal-fade)",
                            pl: { xs: "0", sm: "0" },
                            pr: { xs: "0", sm: "2.5rem" },
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          <span>
                            {/* {filteredPlans[Object.keys(filteredPlans)[0]]}{" "}
                            {Object.keys(filteredPlans)[0]} */}
                            {"1"} {deviceObject?.topLabel}
                          </span>
                          {deviceObject?.deviceTotalQuantity - 1 > 0 ? (
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                fontWeight: "var(--font_weight_1)",
                                fontFamily: "var(--font_family_Regular)",
                                color: "var(--dark-charcoal-fade)",
                                marginLeft: "4px",
                              }}
                            >
                              + {deviceObject?.deviceTotalQuantity - 1}{" "}
                              {"more device"}
                              {deviceObject?.deviceTotalQuantity - 1 > 1
                                ? `${s}`
                                : ""}{" "}
                              {added}
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                fontWeight: "var(--font_weight_1)",
                                fontFamily: "var(--font_family_Regular)",
                                color: "var(--dark-charcoal-fade)",
                                marginLeft: "4px",
                              }}
                            >
                              {` device ${added}`}
                            </Typography>
                          )}
                        </Typography>
                        {/* )} */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                          alignSelf: "flex-start",
                          pl: { xs: "0", sm: "0" },
                          pr: { xs: "0", sm: "2.5rem" },
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            color: "var(--dark-charcoal-fade)",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_1)",
                          }}
                        >
                          {deviceObject?.deviceTotalQuantity} {of_txt}{" "}
                          {configKeys?.MAX_DEVICE ?? 3} {"devices"}
                          {deviceObject?.deviceTotalQuantity > 1 ? `` : ""}{" "}
                          {added}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "center" },
                    marginTop: { xs: "20px", md: "0" },
                  }}
                >
                  <ButtonCust
                    sx={{
                      width: { xs: "100%", sm: "250px" },
                      marginBottom: { xs: "0px", sm: "0px" },
                      marginRight: { xs: "0px", md: "30px" },
                    }}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    data-testid="selectPlanHandlerissimpaid"
                    onClick={(e: any) => {
                      handleNavigationToCart(true);
                      setCartHeader(true);
                      gtmTagManager({
                        event: gaScreenNames.checkout,
                      });
                    }}
                  >
                    {cart_cta}
                  </ButtonCust>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Stack>
    </>
  ) : (
    <Stack className={wrapper_div}>
      <Grid container className={clsx(cards_div, plans_div)}>
        {array.map((el: any, index: number) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "15px",
                maxWidth: "250px",
                backgroundColor: "var(--white)",
                padding: "20px",
                gap: "10px",
              }}
              className={clsx(home_page_plan_card, plan_page_plan_card)}
              data-testid={`plan_plannsec_loader_${Number(index) + 1 || 1}`}
            >
              <Skeleton variant="text" width={30} height={20} />
              <Skeleton variant="text" width={100} height={20} />
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="rounded" width={200} height={60} />
              <Box sx={{ display: "flex" }}>
                <Skeleton variant="rounded" width={20} height={30} />
                <Skeleton
                  variant="text"
                  width={75}
                  height={20}
                  sx={{ ml: "10px", padding: "10px 0px 0px" }}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Skeleton variant="rounded" width={20} height={30} />
                <Skeleton
                  variant="text"
                  width={75}
                  height={20}
                  sx={{ ml: "10px", padding: "10px 0px 0px" }}
                />
              </Box>

              <Skeleton variant="text" width={75} height={30} />
              <Box sx={{ display: "flex", textAlign: "center" }}>
                <Skeleton variant="rounded" width={150} height={10} />
                <Skeleton variant="circular" width={5} height={5} />
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default T13_PlanCard;
