import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Typography,
} from "@mui/material";
import { ButtonCust, CustToolTip } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import styles from "./S2_GlobalRoamingPack.module.scss";
import PaymentReviewCard from "../PaymentReviewCard/PaymentReviewCard";
import PaymentStatus from "../PaymentStatus/PaymentStatus";
import { PostActivationIRILDEnums } from "../../../../enums";
import { resetPurchasePack } from "../../../../features/services/servicesSlice";
import { getResolutionDetails } from "../../../../utils/commonFunctions";
import { useState } from "react";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { RoutingURLs } from "../../../../config/RoutingURLs";

function GlobalRoamingPack(props: any) {
  const { content, fullName } = props.props;
  const {
    back_btn,
    GR_pack,
    GR_icons,
    sms_icon,
    visa_card,
    calendarPen,
    calendar_data,
    carbon_edit,
    payment_review,
    success,
    failed,
    payment_status,
    back_arrow,
    american_express,
    discover,
    jcb,
    master_card,
  } = content;

  const {
    setIsGlobalRoamingPack,
    calanderOnChangeHandler,
    paymentFailedBack,
    confirmPurchaseHandler,
    calanderSubmitHandler,
    selectPackHandler,
    combinedSelectedPackReviewData,
    country,
    onChangeAutoRenewal,
    dateFormat,
    isCalendarModal,
    isPaymentReviewCard,
    purchasePackStatus,
    displayName,
    autoRenewal,
    selectedDate,
    setisCalendarModal,
  } = props;
  const { onClickSwitchTabs, setIsCountryList } = props.props;
  const [SMSInfo, setSMSInfo] = useState(false);
  const [MMSInfo, setMMSInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openInfo, setOpenInfo] = useState(false);

  const availableOfferingsData = useAppSelector(
    (state) => state.services?.availableOfferings
  );
  const { purchasePackError, purchasePackSuccess, purchasePackLoading } =
    useAppSelector((state) => state.services);
  const { IRTaxCharges, isLoadedIRTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { tooltip_style } = styles;

  const dispatch = useAppDispatch();

  const { mobile } = getResolutionDetails();

  const openSMSPopper = (data: any) => {
    setSMSInfo(data);
  };
  const openMMSPopper = (data: any) => {
    setMMSInfo(data);
  };
  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          openMMSPopper(false);
          openSMSPopper(false);
          setAnchorEl(null);
        }}
      >
        <Box
          component="a"
          //   href={tooltip_link}
          target="_blank"
          sx={{
            color: "var(--white)",
            fontSize: "14px",
            backgroundColor: "var(--navy)",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <>
      {purchasePackError && (
        <PaymentStatus
          icon={failed}
          title={payment_status.failedTitle}
          description={payment_status.failedDesc}
          button={payment_status.failedBtn}
          onClick={() => confirmPurchaseHandler()}
          variantType={Config.PRIMARY_BUTTON_TYPE}
        />
      )}

      {purchasePackLoading && (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}

      {purchasePackSuccess && (
        <PaymentStatus
          icon={success}
          title={payment_status.successTitle}
          description={`${payment_status.successDesc} ${dateFormat()}.`}
          button={payment_status.successBtn}
          onClick={() => {
            setIsCountryList(false);
            onClickSwitchTabs(PostActivationIRILDEnums.tab_right);
            dispatch(resetPurchasePack());
            localStorage.removeItem("selectedPack");
            localStorage.removeItem("postActivationDate");
            localStorage.removeItem("prRoamingCountry");
            localStorage.removeItem("SingleLineData");
          }}
          variantType={Config.PRIMARY_BUTTON_TYPE}
        />
      )}

      {!purchasePackStatus && (
        <Box
          m={{ xs: "auto", sm: "auto", md: "auto" }}
          width={{ xs: "auto" }}
          className={styles.main_container}
        >
          <CustomDateCalendar
            onClick={calanderSubmitHandler}
            onChange={calanderOnChangeHandler}
            isCalanderModal={isCalendarModal}
            onCloseModal={setisCalendarModal}
            title={calendar_data.title}
            desc={calendar_data.desc}
            button={calendar_data.btn_2}
            variant={Config.PRIMARY_BUTTON_TYPE}
            isDivider={false}
          />
          <Typography className={styles.top_heading}>
            <Typography
              className={styles.back_arrow}
              onClick={() => setIsGlobalRoamingPack("")}
            >
              <Box
                component="img"
                src={back_arrow?.data.attributes.url}
                alt={back_arrow?.data.attributes.alternativeText}
              />
              {!mobile && (
                <Box sx={{ textDecoration: "underline" }}>{back_btn}</Box>
              )}
            </Typography>
            <Box
              component="span"
              style={{ margin: "auto" }}
              className={styles.heading}
            >
              {GR_pack.title}
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "var(--gray_background)",
              border: "1px solid var(--text_color)",
              p: "20px",
              borderRadius: "8px",
              width: "fit-content",
              margin: "30px auto 40px",
              gap: "10px",
            }}
          >
            <Box className={styles.full_name}>
              {GR_pack.name}
              <Box
                component="span"
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {" "}
                {fullName.toLowerCase()}
              </Box>
            </Box>
            <Box>
              <Typography textAlign="center">
                <Box component="span" className={styles.country_label}>
                  {GR_pack.country}:{" "}
                </Box>
                <Box
                  component="span"
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                    textTransform: "capitalize",
                  }}
                  className={styles.name}
                >
                  {JSON.parse(country || "{}")?.name?.toLowerCase()}
                </Box>
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"flex-start"}
                sx={{
                  pt: "10px",
                }}
              >
                <Box component="span" className={styles.schedule}>
                  {GR_pack.scheduleFor}:{" "}
                </Box>
                <Box component="span" className={styles.selected_date} pl="5px">
                  {selectedDate ? selectedDate : dateFormat()}
                </Box>
                <Box
                  component="img"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setisCalendarModal(true)}
                  src={calendarPen.data.attributes.url}
                />
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"40px"}>
            {!isPaymentReviewCard ? (
              availableOfferingsData.length === 0 ? (
                <Box textAlign={"center"}>
                  <CircularProgress sx={{ color: "var(--primary_color)" }} />
                </Box>
              ) : (
                availableOfferingsData.map((data: any) => {
                  return (
                    <Box key={data.id}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        className={styles.packName}
                      >
                        <Typography>
                          <Box component="span" className={styles.display_name}>
                            {data?.displayName}
                          </Box>
                          <Box
                            component="span"
                            className={styles.display_name_service_unit}
                          >
                            {" "}
                            {data.data}
                            {data.dataUnit}
                          </Box>
                          -
                        </Typography>
                        <Typography className={styles.data_cost}>
                          ${data.price.toFixed(2)}
                        </Typography>
                      </Box>
                      <Box key={data.id} className={styles.card_sub_container}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            alt={GR_icons.data[0].attributes.alternativeText}
                            src={GR_icons.data[0].attributes.url}
                          />
                          <Box>
                            <Typography
                              className={styles.card_sub_container_title}
                            >
                              {GR_pack.data}{" "}
                            </Typography>
                            <Typography
                              className={styles.card_sub_container_value}
                            >
                              {data.data} {GR_pack.dataUnit}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            alt={GR_icons.data[1].attributes.alternativeText}
                            src={GR_icons.data[1].attributes.url}
                          />
                          <Box>
                            <Typography
                              className={styles.card_sub_container_title}
                            >
                              {GR_pack.voice}{" "}
                            </Typography>
                            <Typography
                              className={styles.card_sub_container_value}
                            >
                              {data.voice} {GR_pack.voiceUnit}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            alt={GR_icons.data[2].attributes.alternativeText}
                            src={GR_icons.data[2].attributes.url}
                          />
                          <Box>
                            <Typography
                              className={styles.card_sub_container_title}
                            >
                              {GR_pack.sms}{" "}
                              <CustToolTip
                                title={PoperOver(
                                  <>
                                    <Typography
                                      sx={{
                                        fontFamily:
                                          "var(--font_family_Bold) !important",
                                        margin: "0 0 15px",
                                      }}
                                    >
                                      {GR_pack.SMS_title}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontFamily:
                                          "var(--font_family_Medium) !important",
                                        margin: "0 0 15px",
                                      }}
                                    >
                                      {GR_pack.SMS_desc}
                                    </Typography>
                                  </>
                                )}
                                open={anchorEl === data.id ? true : false}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                placement="top-start"
                                className={tooltip_style}
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, 10],
                                      },
                                    },
                                  ],
                                }}
                              >
                                <Box
                                  component="img"
                                  alt={sms_icon.data.attributes.alternativeText}
                                  src={sms_icon.data.attributes.url}
                                  onMouseEnter={() => {
                                    openSMSPopper(true);
                                    setAnchorEl(data.id);
                                  }}
                                  onMouseLeave={() => {
                                    openSMSPopper(false);
                                    setAnchorEl(null);
                                  }}
                                ></Box>
                              </CustToolTip>
                            </Typography>
                            <Typography
                              className={styles.card_sub_container_value}
                            >
                              {data.sms}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            alt={GR_icons.data[3].attributes.alternativeText}
                            src={GR_icons.data[3].attributes.url}
                          />
                          <Box>
                            <Typography
                              className={styles.card_sub_container_title}
                            >
                              {GR_pack.mms}{" "}
                              <CustToolTip
                                title={PoperOver(
                                  <>
                                    <Typography
                                      sx={{
                                        fontFamily:
                                          "var(--font_family_Bold) !important",
                                        // fontSize: "14px",
                                        margin: "0 0 15px",
                                      }}
                                    >
                                      {GR_pack.MMS_title}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontFamily:
                                          "var(--font_family_Medium) !important",
                                        // fontSize: "14px",
                                        margin: "0 0 15px",
                                      }}
                                    >
                                      {GR_pack.MMS_desc}
                                    </Typography>
                                  </>
                                )}
                                open={
                                  anchorEl === data.displayName ? true : false
                                }
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                placement="bottom"
                                className={tooltip_style}
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, 10],
                                      },
                                    },
                                  ],
                                }}
                              >
                                <Box
                                  component="img"
                                  alt={sms_icon.data.attributes.alternativeText}
                                  src={sms_icon.data.attributes.url}
                                  onMouseEnter={() => {
                                    openMMSPopper(true);
                                    setAnchorEl(data.displayName);
                                  }}
                                  onMouseLeave={() => {
                                    openMMSPopper(false);
                                    setAnchorEl(null);
                                  }}
                                ></Box>
                              </CustToolTip>
                            </Typography>
                            <Typography
                              className={styles.card_sub_container_value}
                            >
                              {data.mms}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            alt={GR_icons.data[4].attributes.alternativeText}
                            src={GR_icons.data[4].attributes.url}
                          />
                          <Box>
                            <Typography
                              className={styles.card_sub_container_title}
                            >
                              {GR_pack.validity}{" "}
                            </Typography>
                            <Typography
                              className={styles.card_sub_container_value}
                            >
                              {data.validityDays}{" "}
                              {data.validityDays === 1 ? "day" : "days"}
                            </Typography>
                          </Box>
                        </Box>
                        <ButtonCust
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          onClick={() => {
                            selectPackHandler(data);
                            let event: any =
                              data?.displayName === "Daily pass"
                                ? globalVal?.gtmEvents
                                    ?.manageServiceSelectDailyPack
                                : globalVal?.gtmEvents
                                    ?.manageServiceSelectWeeklyPack;
                            gtmTagManager({
                              event: event,
                              click_section: "account",
                            });
                          }}
                          sx={{
                            minWidth: "152px !important",
                            width: "152px !important",
                            margin: { xs: "auto", sm: "initial" },
                            height: "50px !important",
                            "&:hover": {
                              backgroundColor:
                                "var(--button_hover_color) !important",
                              border:
                                "1px solid var(--button_hover_color) !important",
                            },
                          }}
                        >
                          {GR_pack.pack_btn}
                        </ButtonCust>
                      </Box>
                    </Box>
                  );
                })
              )
            ) : (
              <PaymentReviewCard
                onChangeAutoRenewal={onChangeAutoRenewal}
                autoRenewal={autoRenewal}
                isAutoRenewal={Config.ENABLE_IR_DATA_RENEWAL}
                firstRowTitle={`${displayName}`}
                firstRowValue={combinedSelectedPackReviewData.dataCost}
                secondRowTitle={combinedSelectedPackReviewData.secondRow}
                secondRowValue={IRTaxCharges}
                thirdRowTitle={combinedSelectedPackReviewData.thirdRow}
                mockCard={combinedSelectedPackReviewData.mockCard}
                loading={isLoadedIRTaxCharges}
                autoRenewalTitle={
                  combinedSelectedPackReviewData?.autoRenewal.title
                }
                visa_card={visa_card?.data?.attributes}
                american_express={american_express}
                discover={discover}
                jcb={jcb}
                master_card={master_card}
                data={combinedSelectedPackReviewData}
                grPack={GR_pack}
                displayName={displayName}
                toggleTrueText={
                  combinedSelectedPackReviewData?.autoRenewal.toggleTrue
                }
                toggleFalseText={
                  combinedSelectedPackReviewData?.autoRenewal.toggleFalse
                }
                onClick={confirmPurchaseHandler}
                confirmBtn={combinedSelectedPackReviewData.cnfrmBtn}
                changeCardText={combinedSelectedPackReviewData.ChangeCard}
              />
            )}
          </Box>
          {!isPaymentReviewCard && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "20px",
                flexDirection: { xs: "column", md: "row" },
                width: { xs: "90%", md: "100%" },
                gap: "10px",
                marginX: "auto",
              }}
            >
              <Typography className={styles.note}>{GR_pack.note}</Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography
                  className={styles.term_condition}
                  onClick={() => {
                    window.open("", "_blank");
                  }}
                >
                  {GR_pack.termsConditions}
                </Typography>
                <CustToolTip
                  title={PoperOver(
                    <Box>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {content?.tooltip_heading}
                      </Typography>
                      {content?.tooltip_items?.map((el: any) => (
                        <>
                          <Typography key={el.id}>{el.description}</Typography>
                          {el.data &&
                            el.data.map((item: any) => (
                              <Typography
                                sx={{ marginLeft: "15px" }}
                                key={item.id}
                              >
                                {item.subDesc}
                              </Typography>
                            ))}
                        </>
                      ))}
                    </Box>
                  )}
                  open={openInfo ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  placement="bottom"
                  className={styles.tooltip_style}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                >
                  <Box
                    component="img"
                    alt={sms_icon.data.attributes.alternativeText}
                    src={sms_icon.data.attributes.url}
                    onMouseEnter={() => {
                      setOpenInfo(true);
                    }}
                    onMouseLeave={() => {
                      setOpenInfo(false);
                    }}
                  ></Box>
                </CustToolTip>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default GlobalRoamingPack;
