import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import styles from "./S2_WalletCard.module.scss";
import { useAppSelector } from "../../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { formatPhoneNumber } from "../../../../../utils/commonFunctions";

const WalletCard = (props: any) => {
  const { lineDataForWallet: data } = props;

  const { getILDCreditsData } = useAppSelector(
    (state: any) => state?.services || {}
  );

  const {
    user_list_container,
    is_primary,
    user_name,
    user_num,
    plan_active,
    view_history_cta,
  } = styles;

  const { isPrimary, wallet_bal } = props.content;

  const fullName = data?.firstName;

  return (
    <Box className={user_list_container}>
      <Box
        p={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box>
          <Typography className={user_name} pb={1}>
            <Box>
              <Box component="span">{fullName?.toLowerCase()}</Box>{" "}
              <Box component="span" className={is_primary}>
                {data?.isPrimary ? `${isPrimary}` : ""} -{" "}
              </Box>
            </Box>
            {data.reachPlanDisplayName && (
              <Box component="span" className={plan_active}>
                {data?.reachPlanDisplayName}
              </Box>
            )}
          </Typography>
          <Typography className={user_num}>
            {data && data.reachNumber ? (
              <Box component="span">{formatPhoneNumber(data.reachNumber)}</Box>
            ) : data && data.mnpInfo?.oldNumber ? (
              <Box component="span">
                {formatPhoneNumber(data.mnpInfo?.oldNumber)}
              </Box>
            ) : null}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "var(--font_weight_0)" }}
          >
            <Box component="span" sx={{ color: "var(--navy)" }}>
              {wallet_bal}:{" "}
            </Box>
            <Box component="span">
              {GetCurrencyFixedToTwo(
                (getILDCreditsData &&
                  getILDCreditsData[data.id]?.availableCredit) ||
                  0
              )}
              /
              {GetCurrencyFixedToTwo(
                (getILDCreditsData &&
                  getILDCreditsData[data.id]?.purchaseCredit) ||
                  0
              )}
            </Box>{" "}
          </Typography>
        </Box>
      </Box>
      <Box px={3} pb={2} sx={{ textAlign: "center" }}>
        {props.children}
      </Box>
    </Box>
  );
};

export default WalletCard;
