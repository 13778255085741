import Cookies from "js-cookie";
import { SimTypes } from "../../features/checkout/Types";
export const shopWareRedirectionHandler = (
  navigationPath: string,
  endPoint: string
) => {
  let swContextToken: any = Cookies.get("swContextToken");
  let idTokenValue: any = localStorage.getItem("accessToken");
  let currentuser: any = localStorage.getItem("currentuser");
  let subDomainData: any = localStorage.getItem("subDomainData");
  subDomainData = JSON.parse(subDomainData);
  currentuser = JSON.parse(currentuser);
  if (idTokenValue) {
    swContextToken
      ? window.location.assign(
          `${navigationPath}/${endPoint}/?token=${swContextToken}&firebase_token=${idTokenValue}&logged_in=${
            currentuser && currentuser.email ? true : false
          }&accessKey=${subDomainData?.shopwareAccessKey}`
        )
      : window.location.assign(
          `${navigationPath}/${endPoint}/?firebase_token=${idTokenValue}&logged_in=${
            currentuser && currentuser.email ? true : false
          }&accessKey=${subDomainData?.shopwareAccessKey}`
        );
  } else {
    swContextToken
      ? window.location.assign(
          `${navigationPath}/${endPoint}/?token=${swContextToken}&logged_in=${
            currentuser && currentuser.email ? true : false
          }&accessKey=${subDomainData?.shopwareAccessKey}`
        )
      : window.location.assign(
          `${navigationPath}/${endPoint}/?logged_in=${
            currentuser && currentuser.email ? true : false
          }&accessKey=${subDomainData?.shopwareAccessKey}`
        );
  }
};

export const shopwareAuthentication = () => {
  let idTokenValue: any = localStorage.getItem("accessToken");
  let currentUser: any = localStorage.getItem("currentuser");
  let subDomainData: any = localStorage.getItem("subDomainData");
  subDomainData = JSON.parse(subDomainData);
  currentUser = JSON.parse(currentUser);
  let nameValues: any = currentUser?.displayName?.split(" ");
  let billingDetails: any = {
    salutation: "Mr",
    firstName: "Dummy123",
    lastName: "Dummy123",
    zipcode: "560098",
    city: "Bengaluru",
    company: "Dummy123",
    street: "Dummy123",
    department: "IT",
    title: "Dummy",
    phoneNumber: "9876543210",
    additionalAddressLine1: "Dummy123",
    additionalAddressLine2: "Dummy123",
    country: "India",
    countryState: "Karnataka",
  };
  let shippingDetails: any = {
    salutation: "Mr",
    firstName: "Dummy123",
    lastName: "Dummy123",
    zipcode: "560098",
    city: "Bengaluru",
    company: "Dummy123",
    street: "Dummy123",
    department: "IT",
    title: "Dummy",
    phoneNumber: "9876543210",
    additionalAddressLine1: "Dummy123",
    additionalAddressLine2: "Dummy123",
    country: "India",
    countryState: "Karnataka",
  };
  let payload: any = {
    data: {
      registerCustomer: true,
      email: currentUser?.email,
      customerDetails: {
        salutation: "Mr",
        firstName: nameValues && nameValues[0],
        lastName: nameValues && nameValues[1],
        billingAddress: billingDetails && billingDetails,
        shippingAddress: shippingDetails && shippingDetails,
        birthdayDay: 14,
        birthdayMonth: 8,
        birthdayYear: 1947,
        title: "",
      },
      brandUrl: subDomainData?.brandUrl
    },
  };
  if (idTokenValue) return payload;
};

export const updateCartPayload = (planDetails:any) => {
  let lineItemsInCart: any = localStorage.getItem("lineItems");
  lineItemsInCart = JSON.parse(lineItemsInCart);
  let planStoreData: any = planDetails;
  const linePreferenceData: any = {};
  planStoreData &&
    planStoreData.map((data: any, index: any) => {
      linePreferenceData["line" + (index + 1)] = {
        imei: data?.imei ? data.imei?.toString() : "",
        simPreference:
          data?.simPreference === SimTypes.eSim
            ? SimTypes.ESIM
            : SimTypes.phySim,
        plan: data?.plan,
        firstName: data?.firstName,
        lastName: data?.lastName,
        isPrimary: data?.isPrimary ? true : false,
        reachPlanId: data?.plan.name,
      };
    });
  let productNumberWithPlanSelection =
    lineItemsInCart &&
    lineItemsInCart.find((data: any) =>
      data?.payload?.productNumber.match("DATA-")
    );
  let payload: any;
  if (productNumberWithPlanSelection) {
    let line: any = localStorage.getItem("reachSelectedPlanLine");
    line = JSON.parse(line);
    payload = {
      items: [
        {
          id: productNumberWithPlanSelection.id,
          payload: {
            quantity: planStoreData && parseInt(planStoreData?.length),
            linePreferences: linePreferenceData,
          },
        },
      ],
    };
  }
  return payload;
};

export const checkUserExist = () => {
  let currentuser: any = localStorage.getItem("currentuser");
  currentuser = JSON.parse(currentuser);
  if (currentuser?.email) {
    return true;
  }
};
