export const updateThemeVariables = (variables: any) => {
    Object.keys(variables).forEach((key) => {
      document.documentElement.style.setProperty(`--${key}`, variables[key]);
    });
  };
  
  export const getSubdomain = () => {
    const { hostname } = window.location; // e.g., 'subdomain.example.com'
    const parts = hostname.split("."); // ['subdomain', 'example', 'com']
  
    // Assuming a typical domain structure with two main parts (example.com)
    if (parts.length > 1) {
      console.log(parts[0]);
      // Extract subdomain
      if(parts[0] === "www"){
        return parts[1]
      }else{
        parts[0]
      }
      return parts[0]; // 'subdomain'
    }
  
    return null; // No subdomain
  };
  
  export const ConvertHexToRgbA = (hex: string, opacity: number = 1) => {
    var c: any;
    if(/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
    }
    throw new Error('Bad Hex');
}