import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwitchTabs from "../SwitchTabs";
import { PostActivationIRILDEnums } from "../../../../enums";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import UsageHistory from "./UsageHistory/UsageHistory";
import { WalletHistory } from "./WalletHistory";
import { getResolutionDetails } from "../../../../utils/commonFunctions";

const T2_WalletHistory = (props: any) => {
  const { mobile } = getResolutionDetails();
  const {
    content,
    lineDataForWallet,
    setIsViewHistory,
    handleBillCycleChange,
    selectedDateKeyIndex,
  } = props;
  const { back_txt, back_arrow, title, usage_history, transaction_history } =
    props.content;
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const onClickSwitchTabs = (type: any) => {
    if (type === PostActivationIRILDEnums.tab_left) {
      setShowTransactionHistory(false);
    } else {
      setShowTransactionHistory(true);
    }
  };
  return (
    <>
      <Box
        sx={{
          margin: { xs: "auto", sm: "auto", md: "auto" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-between",
          width: { xs: "auto", sm: "auto", md: "56%" },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "30px",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& span": {
              fontWeight: "var(--font_weight_5)",
            },
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              left: { xs: "20px", md: "103px" },
              cursor: "pointer",
              fontWeight: "var(--font_weight_3)",
            }}
            onClick={() => setIsViewHistory(false)}
          >
            <Box
              component="img"
              src={back_arrow?.data.attributes.url}
              alt={back_arrow?.data.attributes.alternativeText}
            />
            {!mobile && (
              <Box
                component="span"
                sx={{
                  textDecoration: "underline",
                }}
              >
                {back_txt}
              </Box>
            )}
          </Box>
          <Box
            component="span"
            style={{ margin: "auto" }}
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              fontSize: { xs: "24px", md: "40px" },
            }}
          >
            {title}
          </Box>
        </Typography>
        <SwitchTabs
          title_left={usage_history}
          title_right={transaction_history}
          onClickSwitchTabs={onClickSwitchTabs}
          showPurchaseHistory={showTransactionHistory}
        />
        {showTransactionHistory ? (
          <TransactionHistory
            lineDataForWallet={lineDataForWallet}
            content={content}
            handleBillCycleChange={handleBillCycleChange}
            selectedDateKeyIndex={selectedDateKeyIndex}
          />
        ) : (
          <UsageHistory
            lineDataForWallet={lineDataForWallet}
            content={content}
            handleBillCycleChange={handleBillCycleChange}
            selectedDateKeyIndex={selectedDateKeyIndex}
          />
        )}
      </Box>
    </>
  );
};

export default T2_WalletHistory;
