import { Box, CircularProgress } from "@mui/material";
import { TextFieldCust } from "../../widgets";
import styles from "./MultiSimInHand.module.scss";
import EnvConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { RoutingURLs } from "../../../config/RoutingURLs";
import CloseIcon from "@mui/icons-material/Close";
import { getResolutionDetails } from "../../../utils";

type MultiSimInHandProps = {
  simId: any;
  onSubmitSimHandler: any;
  simInHandContentState: any;
  index?: any;
  error?: any;
  success?: any;
  pending?: any;
  handleClose?: any;
  onChangeSimId?: any;
  removeCloseIcon?: any;
  helperText?: any;
};

const MultiSimInHand = (props: MultiSimInHandProps) => {
  const {
    simId,
    onSubmitSimHandler,
    simInHandContentState,
    index,
    error,
    success,
    pending,
    handleClose,
    onChangeSimId,
    removeCloseIcon,
    helperText,
  } = props;

  const {
    sim_id_lngth,
    multi_s_msg,
    multi_e_msg,
    multi_s_h_ttl,
    multi_sim_ph,
    multi_btn_txt,
    multi_desc_txt,
    multi_try_txt,
    multi_success_icon,
    multi_error_icon,
    multi_sim_ph_mobile,
  } = simInHandContentState || {};

  const { mobile } = getResolutionDetails();

  return (
    <>
      <Box
        className={styles.container}
        key={index}
        data-testid={`multisim_parent_${Number(index) + 1 || 1}`}
      >
        <Box className={styles.inner_container}>
          {success ? (
            <>
              <Box className={styles.check_container}>
                <Box className={styles.sucess_loader}>
                  <Box
                    component={"img"}
                    width="35px"
                    alt={"success_icon"}
                    src={multi_success_icon?.data?.attributes?.url}
                    data-testid={`multisim_success_icon_${
                      Number(index) + 1 || 1
                    }`}
                  />
                </Box>
                <Box
                  className={styles.success_msg}
                  data-testid={`multisim_sucess_msg_${Number(index) + 1 || 1}`}
                >
                  {multi_s_msg}
                </Box>
                <Box
                  className={styles.close_btn}
                  data-testid={`multisim_sucess_close_icon_${
                    Number(index) + 1 || 1
                  }`}
                >
                  <CloseIcon
                    data-index={index}
                    onClick={handleClose}
                    sx={{ fontSize: "16px" }}
                  />
                </Box>
              </Box>
            </>
          ) : error && simId?.length === Number(sim_id_lngth ?? 20) ? (
            <>
              <Box className={styles.check_container}>
                <Box className={styles.sucess_loader}>
                  <Box
                    component={"img"}
                    width="40px"
                    alt={"error_icon"}
                    src={multi_error_icon?.data?.attributes?.url}
                    data-testid={`multisim_error_icon_${
                      Number(index) + 1 || 1
                    }`}
                  />
                </Box>
                <Box
                  className={styles.error_msg}
                  data-testid={`multisim_error_msg_${Number(index) + 1 || 1}`}
                >
                  {multi_e_msg}
                </Box>
                {!removeCloseIcon && (
                  <Box
                    className={styles.close_btn}
                    data-testid={`multisim_error_icon_${
                      Number(index) + 1 || 1
                    }`}
                  >
                    <CloseIcon
                      data-index={index}
                      onClick={handleClose}
                      sx={{ fontSize: "16px" }}
                    />
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                className={styles.header}
                data-testid={`multisim_title_${Number(index) + 1 || 1}`}
              >
                {BrandNameUpdate(multi_s_h_ttl)}
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    md: `${
                      location.pathname === RoutingURLs?.checkout ||
                      location.pathname === RoutingURLs?.postActivationHome ||
                      location.pathname === RoutingURLs?.myAccount
                        ? "70%"
                        : "100%"
                    }`,
                    background: "#fff",
                    borderRadius: "10px",
                  },
                }}
                className={styles.input_container}
              >
                <TextFieldCust
                  data-testid={`multisim_iccid_${Number(index) + 1 || 1}`}
                  value={simId}
                  name={index >= 0 ? `${index}.iccId` : "iccId"}
                  onChange={onChangeSimId}
                  placeholder={
                    mobile
                      ? multi_sim_ph_mobile ?? "Enter your SIM ICCID number"
                      : multi_sim_ph
                  }
                  maxlength={EnvConfig.MAX_ALLOWED_SIM_NUMBER_LENGTH}
                  sx={{
                    height: "50px",
                    "&.TextField .MuiFormHelperText-root": {
                      marginTop: "-2px",
                    },
                    "& ::placeholder": {
                      fontSize: "12px !important",
                      fontWeight: "400 !important",
                      color: "#5A5A5A !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px 0px 0px 10px",
                      borderColor: "unset",
                      "&:hover": {
                        borderRadius: "10px 0px 0px 10px",
                        borderColor: "unset",
                      },
                      "&.Mui-focused": {
                        borderRadius: "10px 0px 0px 10px",
                        borderColor: "unset",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px 0px 0px 10px",
                        borderRightColor: "#fff !important",
                        "&:hover": {
                          borderRadius: "10px 0px 0px 10px !important",
                          borderRightColor: "#fff !important",
                        },
                        "&.Mui-focused": {
                          borderRadius: "10px 0px 0px 10px !important",
                          borderRightColor: "#fff !important",
                        },
                      },
                    },
                  }}
                  error={error && simId?.length === Number(sim_id_lngth)}
                  helperText={helperText}
                />
                <Box
                  className={styles.simid_length}
                  data-testid={`multisim_sim_length_${Number(index) + 1 || 1}`}
                >
                  {`${simId?.length ? simId?.length : 0}/${sim_id_lngth}`}
                </Box>
                {pending ? (
                  <>
                    <Box
                      className={styles.simid_length1}
                      data-testid={`multisim_sim_length_1_${
                        Number(index) + 1 || 1
                      }`}
                    >
                      {`${simId?.length ? simId?.length : 0}/${sim_id_lngth}`}
                    </Box>
                    <Box className={styles.simid_loader}>
                      <CircularProgress
                        sx={{ color: "var(--white)" }}
                        data-testid={`multisim_loader_${
                          Number(index) + 1 || 1
                        }`}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      component="div"
                      data-index={index}
                      onClick={(e) =>
                        simId?.length === Number(sim_id_lngth)
                          ? onSubmitSimHandler(e)
                          : null
                      }
                      data-testid={`multisim_btn_${Number(index) + 1 || 1}`}
                      className={`${
                        simId?.length === Number(sim_id_lngth)
                          ? styles.button_iccid
                          : styles.custom_disabled_button
                      }`}
                    >
                      {multi_btn_txt}
                    </Box>
                  </>
                )}
              </Box>
              <Box
                component="div"
                data-index={index}
                onClick={(e) =>
                  simId?.length === Number(sim_id_lngth)
                    ? onSubmitSimHandler(e)
                    : null
                }
                data-testid={`multisim_btn_${Number(index) + 1 || 1}`}
                className={`${
                  simId?.length === Number(sim_id_lngth)
                    ? styles.button_iccid_mob
                    : styles.custom_disabled_button_mob
                }`}
              >
                {pending ? (
                  <CircularProgress
                    sx={{ color: "var(--white)", width: "25px !important", height: "25px !important" }}
                    data-testid={`multisim_loader_${Number(index) + 1 || 1}`}
                  />
                ) : (
                  multi_btn_txt
                )}
              </Box>
              <Box
                data-testid={`multisim_footer_txt_${Number(index) + 1 || 1}`}
                className={`${helperText ? styles.footerv2 : styles.footer}`}
              >
                {BrandNameUpdate(multi_desc_txt)}
              </Box>
            </>
          )}
        </Box>
      </Box>
      {removeCloseIcon &&
        error &&
        simId?.length === Number(sim_id_lngth ?? 20) && (
          <Box
            data-testid={`multisim_try_again_${Number(index) + 1 || 1}`}
            component={"div"}
            data-index={index}
            className={styles.try_again}
            onClick={handleClose}
          >
            {multi_try_txt}
          </Box>
        )}
    </>
  );
};

export default MultiSimInHand;
