import { Box, Typography } from "@mui/material";
import {
  ButtonCust,
  SearchBoxWithDropdown,
  TextFieldCust,
} from "../../../widgets";
import Config from "../../../../config/env.config.json";

import styles from "./S2_CountryList.module.scss";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import _ from "lodash";
import { getResolutionDetails } from "../../../../utils/commonFunctions";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { useAppSelector } from "../../../../app/hooks";

const CountryList = (props: any) => {
  const {
    country_list,
    user_data,
    activation_icon,
    country_icon,
    calendar_data,
    back_btn,
    back_arrow,
    no_option,
  } = props.content;

  const {
    fullName,
    setIsCountryList,
    calanderSubmitHandler,
    calanderOnChangeHandler,
    isCalanderModal,
    setIsCalanderModal,
    getCountryList,
    renderOption,
    setSelectedCountryHandler,
    continueSubmitHandler,
    selectedDate,
    selectedCountry,
    setSelectedCountry,
  } = props;
  const { mobile } = getResolutionDetails();
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const onTextChange = (e: any) => {
    if (e.target.value === "") {
      setSelectedCountry(undefined);
    }
  };

  return (
    <>
      <CustomDateCalendar
        onClick={calanderSubmitHandler}
        onChange={calanderOnChangeHandler}
        isCalanderModal={isCalanderModal}
        onCloseModal={setIsCalanderModal}
        title={calendar_data.title}
        desc={calendar_data.desc}
        button={calendar_data.btn_1}
        variant={Config.PRIMARY_BUTTON_TYPE}
        sx={{
          "&:hover": {
            backgroundColor: "var(--button_hover_color) !important",
            border: "1px solid var(--button_hover_color) !important",
          },
        }}
      />
      <Box
        className={styles.main_container}
        m={{ xs: "auto", sm: "auto", md: "auto" }}
        width={{ xs: "90%", sm: "auto", md: "60%" }}
      >
        <Box>
          <Typography
            className={styles.back_arrow}
            onClick={() => setIsCountryList(false)}
          >
            <Box
              component="img"
              src={back_arrow?.data?.attributes?.url}
              alt={back_arrow?.data?.attributes?.alternativeText}
            />
            {!mobile && (
              <Box
                component="span"
                sx={{
                  textDecoration: "underline",
                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {back_btn}
              </Box>
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography className={styles.heading}>
              {country_list.title}
            </Typography>
            <Typography className={styles.desc}>{country_list.desc}</Typography>
            <Typography className={styles.full_name}>
              {country_list?.name}
              <Box
                component="span"
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {" "}
                {fullName.toLowerCase()}
              </Box>
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              width: "500px",
              margin: "0 auto",
              pb: "5px",
              fontSize: "14px",
            }}
          >
            {country_list.country_label}
          </Box>
          <SearchBoxWithDropdown
            dropdownList={getCountryList}
            optionLabel={(option: any) =>
              option.name ? option.name : option.countryName
            }
            value={selectedCountry}
            onChange={setSelectedCountryHandler}
            placeholder={country_list.country_placeholder}
            onRequest={() => null}
            onCancel={() => setSelectedCountry("")}
            logo={country_icon}
            renderOption={renderOption}
            no_option={no_option}
            onTextChange={onTextChange}
            sx={{
              "& .css-cn7qke-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input":
                {
                  fontFamily: "var(--font_family_Medium) !important",
                  color: "var(--text-color) !important",
                  opacity: "0 !important",
                  padding: "7px 4px",
                },
              width: { xs: "90%", sm: "500px !important" },
            }}
            className={styles.flexCenter}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => setIsCalanderModal(true)}
            sx={{
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Box
              sx={{
                pb: "5px",
                fontSize: "14px",
              }}
            >
              {country_list.calendar_label}
            </Box>
            <TextFieldCust
              name="calander"
              value={selectedDate}
              readOnly={true}
              placeholder={country_list.pack_placeholder}
              endAdornment={
                <Box
                  component="img"
                  src={activation_icon?.data?.attributes?.url}
                  alt={activation_icon?.data?.alternativeText}
                />
              }
              sx={{
                width: { sm: "500px !important" },
              }}
              className={styles.textfield_cust}
            />
          </Box>
        </Box>
        <Box textAlign={"center"} mt={"15px"}>
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              continueSubmitHandler();
              gtmTagManager({
                event: globalVal?.gtmEvents?.manageServicePurchasePack,
                click_section: "account",
              });
            }}
            disabled={selectedCountry === "" || selectedDate === ""}
            sx={{
              "&:hover": {
                backgroundColor: "var(--button_hover_color) !important",
                border: "1px solid var(--button_hover_color) !important",
              },
            }}
          >
            {country_list.button}
          </ButtonCust>
        </Box>
      </Box>
    </>
  );
};

export default CountryList;
