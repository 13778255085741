import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/env.config.json";
import AddCredits from "./ILDAddCredit/AddCredits/AddCredits";
import TermsAndCondition from "../IRILDComponents/TermsAndCondition/TermsAndCondition";
import ViewRates from "../IRILDComponents/ViewRates/ViewRates";
import AddCreditPayment from "./ILDAddCredit/AddCreditPayment/AddCreditPayment";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import PaymentStatus from "../IRILDComponents/PaymentStatus/PaymentStatus";
import { purchase } from "../../../features/wallet/walletSlice";
import { getILDCredits } from "../../../features/services/servicesSlice";
import ILDUserList from "./ILDUserList/ILDUserList";
import { WalletHistory } from "../IRILDComponents/WalletHistory/WalletHistory";
import { getResolutionDetails } from "../../../utils/commonFunctions";
import { ButtonCust } from "../../widgets";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const T3_PAInternationalCalling = (props: any) => {
  const dispatch = useAppDispatch();
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const { ILDTaxCharges } = useAppSelector((state: any) => state.checkout);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const {
    content,
    setInternationalService,
    handleBillCycleChange,
    selectedDateKeyIndex,
  } = props;
  const {
    title,
    back_icon,
    terms,
    view_rates,
    back_txt,
    rates_note,
    terms_icon,
    right_arrow,
    success_icon,
    payment_status,
    failed_icon,
    callAnywhere,
    desc,
    terms_link,
  } = content;

  const { group } = useAppSelector((state: any) => state.account);
  const [openModal, setOpenModal] = useState(false);
  const [isViewRates, setIsViewRates] = useState(false);
  const [isViewHistory, setIsViewHistory] = useState(false);
  const [lineDataForWallet, setLineDataForWallet] = useState<any>(null);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [userLineData, setUserLineData] = useState<any>(null);
  const [isCreditPayment, setIsCreditPayment] = useState(false);
  const [isCreditPaymentSuccess, setIsCreditPaymentSuccess] = useState(false);
  const [isCreditPaymentFailed, setIsCreditPaymentFailed] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [creditAmount, setcreditAmount] = useState(
    Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
  );
  const { mobile } = getResolutionDetails();
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;

  const handleAddCreditCTA = (data: any) => {
    setUserLineData(data);
    setOpenCreditModal(true);
  };

  useEffect(() => {
    dispatch(getConfigKeys());
    dispatch(
      getILDCredits({
        groupId: currentdetails && currentdetails.groupId,
      })
    );
  }, []);

  useEffect(() => {
    setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
  }, [configKeys]);

  const handleConfirmCreditPayment = () => {
    let payload = {
      primaryId: currentdetails.id,
      customerCreditMap: {
        [userLineData.id]: Number(creditAmount),
      },
      ccUUID: getCCDetails[0].uuid,
      totalAmount: Number((creditAmount + ILDTaxCharges?.totalTax).toFixed(2)),
      taxAmount: ILDTaxCharges?.totalTax,
      creditType: "ILD",
      enableFeature: false,
      taxInfo: ILDTaxCharges,
    };
    setIsLoader(true);
    dispatch(purchase({ payload }))
      .then((data) => {
        setIsLoader(false);
        setIsCreditPayment(false);
        setcreditAmount(creditAmount);
        if (data.payload.status === 200) {
          setIsCreditPaymentSuccess(true);
          dispatch(
            getILDCredits({
              groupId: currentdetails && currentdetails.groupId,
            })
          );
        } else {
          setIsCreditPaymentFailed(true);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        setIsCreditPaymentFailed(true);
        setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
      });
  };
  return (
    <>
      {openModal && (
        <TermsAndCondition
          content={content}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}
      {isViewHistory ? (
        <WalletHistory
          setIsViewHistory={setIsViewHistory}
          lineDataForWallet={lineDataForWallet}
          handleBillCycleChange={handleBillCycleChange}
          selectedDateKeyIndex={selectedDateKeyIndex}
        />
      ) : isViewRates ? (
        <ViewRates setIsViewRates={setIsViewRates} content={content} />
      ) : isCreditPayment ? (
        <AddCreditPayment
          content={content}
          totalCost={creditAmount}
          userLineData={userLineData}
          setIsCreditPayment={setIsCreditPayment}
          isLoader={isLoader}
          onClick={handleConfirmCreditPayment}
        />
      ) : isCreditPaymentSuccess ? (
        <Box
          sx={{
            backgroundColor: "var(--white)",
            padding: "40px",
            borderRadius: "10px",
          }}
        >
          <PaymentStatus
            icon={success_icon}
            title={payment_status.successTitle}
            description={payment_status.successDesc}
            button={payment_status.successBtn}
            onClick={() => {
              setIsCreditPaymentSuccess(false);
            }}
            variantType={Config.QUATERNARY_BUTTON_TYPE}
            imgHeight="120"
            imgWidth="145"
            amount={creditAmount}
          />
        </Box>
      ) : isCreditPaymentFailed ? (
        <Box
          sx={{
            backgroundColor: "var(--white)",
            padding: "40px",
            borderRadius: "10px",
          }}
        >
          <PaymentStatus
            icon={failed_icon}
            title={payment_status.failedTitle}
            description={payment_status.failedDesc}
            button={payment_status.failedBtn}
            onClick={() => {
              setIsCreditPaymentFailed(false);
            }}
            isWidth={true}
            variantType={Config.QUATERNARY_BUTTON_TYPE}
          />
        </Box>
      ) : (
        <Box
          sx={{
            margin: { xs: "auto", sm: "auto", md: "auto" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: { xs: "90%", sm: "60%", md: "75%", lg: "56%" },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "30px",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& span": {
                fontWeight: "var(--font_weight_5)",
              },
            }}
          >
            <Box
              component="span"
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                left: { xs: "17px", md: "103px" },
                top: { xs: "105px", md: "auto" },
                cursor: "pointer",
                fontWeight: "var(--font_weight_3)",
              }}
              onClick={() => setInternationalService("")}
              data-testid="pa_int_back"
            >
              {!mobile ? (
                <ArrowBackIosIcon
                  sx={{ width: "18px", fontSize: "1.125rem" }}
                  data-testid="pa_int_back_icon"
                />
              ) : (
                <Box
                  component="img"
                  src={back_icon?.data.attributes.url}
                  alt={back_icon?.data.attributes.alternativeText}
                  data-testid="pa_int_back_icon"
                />
              )}
              {!mobile && (
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1) !important",
                    fontSize: "18px",
                    lineHeight: "30px",
                  }}
                  component="span"
                  data-testid="pa_int_back_txt"
                >
                  {back_txt}
                </Box>
              )}
            </Box>
            <Box
              component="span"
              style={{ margin: "auto" }}
              sx={{
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: { xs: "18px", sm: "24px" },
                lineHeight: { xs: "30px", sm: "36px" },
                color: "var(--black)",
                fontWeight: "var(--font_weight_4) !important",
              }}
              data-testid="pa_int_back_title"
            >
              {title}
            </Box>
          </Typography>

          {callAnywhere && (
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "14px", md: "18px" },
                fontWeight: "var(--font_weight_1)",
                fontFamily: "var(--font_family_Medium)",
                margin: "20px 0 0",
              }}
              data-testid="pa_int_call_any_Where"
            >
              {callAnywhere}
            </Typography>
          )}

          <ILDUserList
            group={group}
            content={content}
            handleAddCreditCTA={handleAddCreditCTA}
            setLineDataForWallet={setLineDataForWallet}
            setIsViewHistory={setIsViewHistory}
          />

          <Box>
            <Box
              sx={{
                mt: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                onClick={() => {
                  setIsViewRates(true);
                  gtmTagManager({
                    event: globalVal?.gtmEvents?.viewRatesILD,
                    click_section: "International Calling",
                  });
                }}
                sx={{
                  color: "var(--primary_color)",
                  cursor: "pointer",
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_2)",
                  fontSize: "16px",
                  lineHeight: "26px",
                }}
                data-testid="pa_int_view_rate"
              >
                {view_rates}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--primary_color)",
                lineHeight: "26px",
                fontWeight: "var(--font_weight_2)",
                cursor: "pointer",
                fontFamily: "var(--font_family_Semibold)",
                display: "flex",
                justifyContent: { xs: "center", md: "center" },
                mt: "15px",
              }}
              onClick={() => {
                window.open(terms_link, "_blank");
                gtmTagManager({
                  event: globalVal?.gtmEvents?.ILDTermsAndConditions,
                  click_section: "International Calling",
                });
              }}
              data-testid="pa_int_terms"
            >
              {terms}
            </Typography>
          </Box>
        </Box>
      )}
      <AddCredits
        content={content}
        userLineData={userLineData}
        setOpenCreditModal={setOpenCreditModal}
        openCreditModal={openCreditModal}
        setIsCreditPayment={setIsCreditPayment}
        creditAmount={creditAmount}
        setcreditAmount={setcreditAmount}
        configKeys={configKeys}
      />
    </>
  );
};

export default T3_PAInternationalCalling;
