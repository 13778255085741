import { Box, CircularProgress, Typography } from "@mui/material";
import { ButtonCust, SimpleModal, SwitchCust } from "../../../widgets";
import { useState } from "react";
import EnvConfig from "../../../../config/env.config.json";
import {
  getAllPacks,
  irAutoRenew,
} from "../../../../features/services/servicesSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNonInitialEffect } from "../../../../utils/commonFunctions";

const IRAutoRenewal = (props: any) => {
  const { renew_enabled, renew_disabled, renew_disable, renew_enable } =
    props.content;
  const [openModal, setOpenModal] = useState(false);
  const [autoRenewEnable, setAutoRenewEnable] = useState(false);

  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const { irAutoRenewData, irAutoRenewPending, irAutoRenewError } =
    useAppSelector((state: any) => state.services);
  const dispatch = useAppDispatch();
  const handleAutoRenewalModal = () => {
    setOpenModal(true);
    if (props.autoRenew) {
      setAutoRenewEnable(false);
    } else {
      setAutoRenewEnable(true);
    }
  };
  const handleAutoRenewal = () => {
    dispatch(
      irAutoRenew({
        packId: props.packId,
        data: { autoRenew: autoRenewEnable },
      })
    );
  };

  useNonInitialEffect(() => {
    if (
      irAutoRenewData &&
      irAutoRenewData.data &&
      irAutoRenewData.data.status === "SUCCESS"
    ) {
      setOpenModal(false);
      dispatch(getAllPacks({ groupId: customerDetails?.groupId }));
    } else if (irAutoRenewError) {
      setOpenModal(false);
    }
  }, [irAutoRenewData, irAutoRenewError]);
  return (
    <>
      {EnvConfig.ENABLE_IR_DATA_RENEWAL && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "25px",
          }}
          data-testid={`IR_auto_renewal_wrapper_${Number(props.packId) + 1 || 1}`}
        >
          <SwitchCust
            styles={{ mr: "0px" }}
            checked={props.autoRenew ? true : false}
            handleSwitchToggle={handleAutoRenewalModal}
            name="autoRenew"
            data-testid={`IR_auto_renewal_switch_${Number(props.packId) + 1 || 1}`}
          />
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_5)",
              fontSize: "14px",
              color: props.autoRenew
                ? "var(--primary_brand_color_dark)"
                : "var(--tab_color_2)",
              lineHeight: "14px",
            }}
            data-testid={`IR_auto_renewal_title_${Number(props.packId) + 1 || 1}`}
          >
            {props.autoRenew ? renew_enabled : renew_disabled}
          </Typography>
          <SimpleModal
            isShowModal={openModal ? true : false}
            onCloseModal={() => setOpenModal(false)}
            showClose={true}
            sx={{
              width: { xs: "90%", sm: "60%", md: "80%", lg: "45%", xl: "35%" },
              borderRadius: "6px",
            }}
          >
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography
                component="div"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                  fontSize: "22px",
                  color: "var(--tab_color_2)",
                  lineHeight: "22px",
                }}
                data-testid={`IR_auto_renewal_subtitle_${Number(props.packId) + 1 || 1}`}
              >
                {autoRenewEnable ? renew_enable?.title : renew_disable?.title}
              </Typography>
              <Box className={props.styles.line}></Box>
              <Box
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_5)",
                  fontSize: "14px",
                  color: "var(--tab_color_2)",
                  lineHeight: "24px",
                  margin: "30px 0 40px",
                }}
                data-testid={`IR_auto_renewal_text_1_${Number(props.packId) + 1 || 1}`}
              >
                {autoRenewEnable ? renew_enable?.text1 : renew_disable?.text1}
              </Box>
              <Box>
                <ButtonCust
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  sx={{
                    my: "10px",
                    width: {
                      xs: "95%",
                    },
                    height: "45px",
                  }}
                  onClick={handleAutoRenewal}
                  data-testid={`IR_auto_renewal_btn_${Number(props.packId) + 1 || 1}`}
                >
                  {irAutoRenewPending ? (
                    <CircularProgress />
                  ) : autoRenewEnable ? (
                    renew_enable?.button9
                  ) : (
                    renew_disable?.button9
                  )}
                </ButtonCust>
              </Box>
              <Box
                sx={{
                  mt: "30px",
                  color: "var(--primary_brand_color)",
                  cursor: "pointer",
                }}
                onClick={() => setOpenModal(false)}
                data-testid={`IR_auto_renewal_cncl_txt_${Number(props.packId) + 1 || 1}`}
              >
                {autoRenewEnable
                  ? renew_enable?.cancel_txt
                  : renew_disable?.cancel_txt}
              </Box>
            </Box>
          </SimpleModal>
        </Box>
      )}
    </>
  );
};

export default IRAutoRenewal;
