import { Box, Typography } from "@mui/material";
import {
  ButtonCust,
  SearchBoxWithDropdown,
  TextFieldCust,
} from "../../../widgets";
import Config from "../../../../config/env.config.json";

import styles from "./S1_CountryList.module.scss";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import _ from "lodash";

const CountryList = (props: any) => {
  const {
    country_list,
    user_data,
    activation_icon,
    country_icon,
    calendar_data,
    back_btn,
    back_arrow,
    no_option,
  } = props.content;

  const {
    fullName,
    setIsCountryList,
    calanderSubmitHandler,
    calanderOnChangeHandler,
    isCalanderModal,
    setIsCalanderModal,
    getCountryList,
    renderOption,
    setSelectedCountryHandler,
    continueSubmitHandler,
    selectedDate,
    selectedCountry,
    setSelectedCountry,
  } = props;

  return (
    <>
      <CustomDateCalendar
        onClick={calanderSubmitHandler}
        onChange={calanderOnChangeHandler}
        isCalanderModal={isCalanderModal}
        onCloseModal={setIsCalanderModal}
        title={calendar_data.title}
        desc={calendar_data.desc}
        button={calendar_data.btn_1}
        variant={Config.QUATERNARY_BUTTON_TYPE}
      />
      <Box
        className={styles.main_container}
        m={{ xs: "auto", sm: "auto", md: "auto" }}
        width={{ xs: "auto", sm: "auto", md: "60%" }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            className={styles.back_arrow}
            onClick={() => setIsCountryList(false)}
          >
            {/* Add your arrow icon here */}
            <img
              src={back_arrow?.data?.attributes?.url}
              alt={back_arrow?.data?.attributes?.alternativeText}
              width={17.495}
              height={14.586}
            />
            {back_btn}
          </Typography>
          <Box flex={1} textAlign={"initial"}>
            {/* Your title or heading */}
            <Typography className={styles.heading}>
              {country_list.title}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {/* Your name */}
            <Typography className={styles.full_name}>{fullName}</Typography>
          </Box>
        </Box>
        <SearchBoxWithDropdown
          dropdownList={getCountryList}
          optionLabel={(option: any) => option.name}
          value={selectedCountry}
          onChange={setSelectedCountryHandler}
          placeholder={country_list.country_placeholder}
          onRequest={() => null}
          onCancel={() => setSelectedCountry("")}
          logo={country_icon}
          renderOption={renderOption}
          no_option={no_option}
          sx={{
            "& .MuiFilledInput-input": {
              fontFamily: "var(--font_family_Medium) !important",
            },
          }}
        />
        <Box onClick={() => setIsCalanderModal(true)}>
          <TextFieldCust
            name="calander"
            value={selectedDate}
            readOnly={true}
            placeholder={country_list.pack_placeholder}
            endAdornment={
              <img
                src={activation_icon?.data?.attributes?.url}
                alt={activation_icon?.data?.alternativeText}
                width="18.5px"
                height="18.5px"
              />
            }
          />
        </Box>
        <Box textAlign={"center"} mt={"15px"}>
          <ButtonCust
            sx={{ width: "70%" }}
            variantType={Config.QUATERNARY_BUTTON_TYPE}
            onClick={() => continueSubmitHandler()}
          >
            {country_list.button}
          </ButtonCust>
        </Box>
      </Box>
    </>
  );
};

export default CountryList;
