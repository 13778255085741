import Config from "../../../../config/app.config.json";
import EnvConfig from "../../../../config/env.config.json";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { NotificationCust } from "../../../widgets";
import firebase from "../../../../adapters/firebase";
import { Store } from "react-notifications-component";
import {
  GetCustomer,
  getPlanTaxCharges,
  LandingPromo,
  updatePlanDetails,
} from "../../../../features/checkout/checkoutSlice";
// import PlansResponse from "../../../../config/data.json";
import { sortByKey } from "../../../../utils/commonFunctions/SortByKey";
import { useRef, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  ChangePlan,
  PlanChangeCustomerStatus,
  topUpCustomer,
  topUpCustomerStatus,
  topUpGroup,
  topUpPulseList,
  type4TaxCalculation,
} from "../../../../features/account/accountSlice";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import {
  cpbdpSectionContent,
  cppdSectionContent,
} from "../../../../features/strapi/accountSlice";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import FirebaseDBURLs from "../../../../config/FirebaseDBURLs";
import { reachPlanData } from "../../../../features/homepage/homepageSlice";

const CpbdpComponent = isTemplateExisits(Config.Account.ChangeBuyData)
  ? require(`./${Config.Account.ChangeBuyData.template}_Cpbdp`).default
  : null;

export type cpbdpProps = {
  content?: any;
  setCurrentSection: Function;
  topupPulseDetails: any;
  setUnlimited: any;
  unlimited: boolean;
  user: any;
  setUser: any;
  setOpen: any;
  setTopup: any;
  mainCard: any;
  showCardChangeModal: boolean;
  setShowCardChangeModal: any;
  isCardExpired: any;
  activeTab: number;
  setActiveTab: any;
  tabsContent: any;
  selectedLine: any;
  setSelectedLine: any;
};
export const additionalLinePriceCalculation = (plan: any, user: any) => {
  let additionalLinePrices: number = 0;
  const { addLineSplitPrice }: { addLineSplitPrice: object } = plan;
  for (let index = 0; index < user.totalActiveLines - 1; index++) {
    additionalLinePrices =
      additionalLinePrices + Object.values(addLineSplitPrice)[index];
  }
  return parseInt(additionalLinePrices.toFixed(2));
};
export const planPriceCalculationHanlder = (
  plan: any,
  additionalActivatedLines: number,
  user: any
) => {
  let Plancost: any;
  if (
    plan &&
    plan.addLineSplitPrice &&
    additionalActivatedLines > 0 &&
    additionalActivatedLines <= plan.maxLines &&
    plan.baseLinePrice
  ) {
    return (Plancost =
      plan.baseLinePrice + additionalLinePriceCalculation(plan, user));
  } else if (
    plan &&
    plan.additionalLinePrice &&
    plan.baseLinePrice &&
    additionalActivatedLines > 0 &&
    additionalActivatedLines <= plan.maxLines
  ) {
    return (Plancost =
      plan.baseLinePrice + plan.additionalLinePrice * additionalActivatedLines);
  } else if (
    additionalActivatedLines > 0 &&
    additionalActivatedLines > plan?.maxLines
  ) {
    return (Plancost = 0);
  } else {
    return (Plancost = plan?.baseLinePrice);
  }
};

export const Cpbdp = (props: cpbdpProps) => {
  const {
    setCurrentSection,
    topupPulseDetails,
    unlimited,
    user,
    setOpen,
    setTopup,
    mainCard,
    showCardChangeModal,
    setShowCardChangeModal,
    isCardExpired,
    tabsContent,
    activeTab,
    setActiveTab,
    setSelectedLine,
    selectedLine,
  } = props;
  const [open1, setOpen1] = useState(false);

  const { planData } = useAppSelector(
    (state: any) => state.homepage
  );
  useEffect(() => {
    if(!planData){
      dispatch(reachPlanData({}));
    }
  }, []);
  let plandata = planData?.data?.[0]
    ? sortByKey(planData && planData.data, "planData")
    : [];
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [currentPlan, setCurrentPlan] = useState<any>();
  const dispatch = useAppDispatch();
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const { planDetails, planTaxCharges, landingPromoPlan } = useAppSelector(
    (state: any) => state.checkout
  );
  const { cpbdpContent, cppdContent } = useAppSelector(
    (state: any) => state.strapi.account
  );

  const {
    type4Tax,
    tax4Loader,
    errorType4Tax,
    customerTopUp,
    errorCustomerTopUp,
    customerTopUpLoader,
    custTopUpStatus,
    errorCustTopUpStatus,
    groupTopUp,
    group,
    planChange,
    errorPlanChange,
    planChangeLoader,
    planChangeStatus,
    topUpPulse,
  } = useAppSelector((state: any) => state.account);
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const [countValue, setCountValue] = useState<number>(0.5);
  const [sharedPlanSelected, setSharedPlanSelected] = useState(false);
  const [buyDataDialog, setBuyDataDialog] = useState(false);
  const [dataPurchaseDialog, setdataPurchaseDialog] = useState(false);
  const [unlimitedPlanSelected, setUnlimitedPlanSelected] = useState(false);
  const [lowerPlanSelected, setLowerPlanSelected] = useState(false);
  const [isTopUpInitiated, setIsTopUpInitiated] = useState(false);
  const [isPlanChangeInitiated, setIsPlanChangeInitiated] = useState(false);
  const [isGetTopUpStatusClicked, setIsGetTopUpStatusClicked] = useState(false);
  const [isGetPlanChangeStatusClicked, setIsGetPlanChangeStatusClicked] =
    useState(false);
  const taxOnDiffAmount = useRef(false);
  const [viewPurchaseHistoryTopup, setViewPurchaseHistoryTopup] =
    useState(false);
  const [comparePlansDialog, setComparePlansDialog] = useState(false);
  const [comparePlansDialogLater, setComparePlansDialogLater] = useState(false);
  const [isOperationInitiated, setIsOperationInitiated] = useState(false);
  const [isUpdateLaterClicked, setIsUpdateLaterClicked] = useState(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [firstTaxCall, setFirstTaxCall] = useState<boolean>(true);
  const [secondTaxCall, setSecondTaxCall] = useState<boolean>(true);
  const [newPlanTax, setNewPlanTax] = useState<number>(0);
  const [currentPlanTax, setCurrentPlanTax] = useState<number>(0);
  const [newPlanCost, setNewPlanCost] = useState<number>(0);
  const [currentPlanCost, setCurrentPlanCost] = useState<number>(0);
  const [updateType, setUpdateType] = useState<string>("");
  const [cpbdpSecContent, setCpbdpSecContent] = useState<any>(null);
  const [cppdSecContent, setCppdSecContent] = useState<any>(null);
  const [dataPackPlan, setDataPackPlan] = useState<any>(null);
  const tabsHandler = (tabValue: any) => {
    setActiveTab(tabValue);
    setSelectedLine("");
  };
  useEffect(() => {
    let currentdetails: any = localStorage.getItem("customerDetail");
    currentdetails = JSON.parse(currentdetails);
    const activePlan = currentdetails?.reachPlanId;
    const lines = currentdetails?.totalActiveLines;
    if (currentdetails?.reachPlanId === EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME) {
      setCurrentPlan(landingPromoPlan);
    } else {
      const selectedCurrentPlan = planData?.data?.filter(
        (plan?: any) => plan.name === activePlan
      );
      setCurrentPlan(selectedCurrentPlan[0]);
    }
    getCache(
      "CPBDPSecAccount",
      cpbdpSectionContent,
      setCpbdpSecContent,
      dispatch
    );
    getCache("CPPDSecAccount", cppdSectionContent, setCppdSecContent, dispatch);
    removeAllTickers();
    if (currentdetails && currentdetails.isPrimary) {
      dispatch(
        topUpCustomerStatus({
          customerId: currentdetails && currentdetails.id,
        })
      );
      dispatch(
        PlanChangeCustomerStatus({
          customerId: currentdetails && currentdetails.id,
        })
      );
    }
    dispatch(
      updatePlanDetails({ numberOfLines: lines, selectedPlan: activePlan })
    );
    if (
      currentdetails &&
      currentdetails.billStartDate &&
      currentdetails.billEndDate
    ) {
      dispatch(
        topUpGroup({
          groupId: currentdetails && currentdetails.groupId,
          startDate:
            currentdetails &&
            currentdetails.billStartDate &&
            new Date(currentdetails.billStartDate).toISOString().slice(0, 10),
          endDate:
            currentdetails &&
            currentdetails.billEndDate &&
            new Date(currentdetails.billEndDate).toISOString().slice(0, 10),
        })
      );
    }
    let addCardAccount = localStorage.getItem("addCardAccount");
    if (addCardAccount) {
      let activePreviousTab: any = localStorage.getItem("activeTab");
      setActiveTab(Number(activePreviousTab));
    }
    dispatch(topUpPulseList());
    return () => {
      removeAllTickers();
    };
  }, [landingPromoPlan]);

  useNonInitialEffect(() => {
    addMultipleCacheData("CPBDPSecAccount", cpbdpContent);
  }, [cpbdpContent]);

  useNonInitialEffect(() => {
    addMultipleCacheData("CPPDSecAccount", cppdContent);
  }, [cppdContent]);

  useNonInitialEffect(() => {
    setDataPackPlan(topUpPulse?.data[0]);
  }, [topUpPulse]);
  useNonInitialEffect(() => {
    if (firstTaxCall && secondTaxCall) {
      setNewPlanTax(planTaxCharges);
      setFirstTaxCall(false);
      let billingAddress: any =
        user &&
        user.addresses &&
        user.addresses.find((el: any) => el.type === "billing");
      const { firstName, lastName } = user;
      let code: string = billingAddress && billingAddress.state;
      let currentUser: any = localStorage.getItem("customerDetail");
      let customerDetail: any = JSON.parse(currentUser);
      let PlanStorage: any = localStorage.getItem("amount");
      let PlanStorageDetails: any = JSON.parse(PlanStorage);
      billingAddress &&
        dispatch(
          getPlanTaxCharges({
            address: {
              name: `${firstName} ${lastName}`,
              address1: billingAddress && billingAddress.address1,
              city: billingAddress && billingAddress.city,
              country: billingAddress && billingAddress.country,
              state: code,
              type: "billing",
              zip: billingAddress && billingAddress.zip,
            },
            reachPlanId: customerDetail
              ? customerDetail.reachPlanId
              : currentPlan.name,
            amount: PlanStorageDetails
              ? PlanStorageDetails?.currentPlanCost
              : currentPlanCost,
            additionalLines: user.totalActiveLines - 1,
          })
        );
    } else if (!firstTaxCall && secondTaxCall) {
      setCurrentPlanTax(planTaxCharges);
      setFirstTaxCall(true);
    }
    setOpen1(false);
    if (isUpdateLaterClicked === false) {
      if (
        currentPlan?.planData < selectedPlan?.planData &&
        currentPlanCost < newPlanCost
      ) {
        if (
          (currentPlan.unlimited && !selectedPlan.unlimited) ||
          (!currentPlan.unlimited && selectedPlan.unlimited)
        ) {
          setComparePlansDialogLater(true);
        } else {
          setComparePlansDialog(true);
        }
      } else {
        setComparePlansDialogLater(true);
      }
    } else {
      setComparePlansDialog(false);
      setComparePlansDialogLater(true);
    }
  }, [planTaxCharges]);

  const upgradePlanNowOrLaterSuccess = () => {
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);
    dispatch(
      GetCustomer({
        username: encodeURIComponent(currentuser && currentuser.email),
      })
    );
    window.scroll(0, 0);
    if (updateType === "now") {
      let addCardAccount = localStorage.getItem("addCardAccount");
      if (!addCardAccount) {
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.update_success
            : cpbdpContent?.update_success,
          type: "info",
          showIcon: true,
          id: "accountPlanUpgradeNow",
        });
      }
    } else if (updateType === "later") {
      NotificationCust({
        message: cpbdpSecContent
          ? cpbdpSecContent?.update_later_success
          : cpbdpContent?.update_later_success,
        type: "info",
        showIcon: true,
        id: "accountPlanUpgradeLater",
      });
    }
    setCurrentSection("DataDetails");
  };
  useNonInitialEffect(() => {
    if (planChange && planChange.status === "SUCCESS") {
      setComparePlansDialog(false);
      setComparePlansDialogLater(false);
      if (updateType === "later") {
        upgradePlanNowOrLaterSuccess();
      } else {
        setIsOperationInitiated(true);
        setIsPlanChangeInitiated(true);
        updateOperationStatusToFirebase(user.id, true);
        localStorage.removeItem("addCardAccount");
        localStorage.removeItem("amount");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("selectedPlan");
        dispatch(
          PlanChangeCustomerStatus({
            customerId: user && user.id,
          })
        );
        showPlanChangeNotification();
      }
    }
  }, [planChange]);
  useNonInitialEffect(() => {
    if (isPlanChangeInitiated) {
      NotificationCust({
        message: cpbdpSecContent
          ? cpbdpSecContent?.plan_changes
          : cpbdpContent?.plan_changes,
        type: "info",
        showIcon: true,
        id: "planChangeSuccessToast",
      });
    }
  }, [isPlanChangeInitiated]);
  const showPlanChangeNotification = () => {
    window.scroll(0, 0);
    {
      cpbdpSecContent?.inProgressPlanChangeNote &&
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.inProPlanChangeNote
            : cpbdpContent?.inProPlanChangeNote,
          type: "info",
          showIcon: true,
          id: "planChangeSuccessToast",
        });
    }
  };

  useNonInitialEffect(() => {
    if (errorPlanChange && errorPlanChange.status === "FAILURE") {
      if (localStorage.getItem("activeTab")) {
        localStorage.removeItem("addCardAccount");
        localStorage.removeItem("amount");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("selectedPlan");
      }
      setComparePlansDialog(false);
      setComparePlansDialogLater(false);
      setIsOperationInitiated(false);
      if (errorPlanChange.resCode === 40122) {
        window.scroll(0, 0);
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.error_card_details
            : cpbdpContent?.error_card_details,
          type: "danger",
          showIcon: true,
          id: "accountPlanChangeCardFailure",
        });
      } else if (errorPlanChange.resCode === 40139) {
        window.scroll(0, 0);
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.error_billing_plan
            : cpbdpContent?.error_billing_plan,
          type: "info",
          duration: 3000,
          id: "accountPlanChangeBillingError",
        });
      } else {
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.cp_fail_msg
            : cpbdpContent?.cp_fail_msg,
          type: "danger",
          duration: 3000,
          id: "defaultMsgPlan",
        });
        if (updateType === "now") {
          setIsPlanChangeInitiated(false);
          updateOperationStatusToFirebase(user.id, true);
        }
      }
    }
  }, [errorPlanChange]);

  useNonInitialEffect(() => {
    if (
      planChangeStatus &&
      planChangeStatus.status === "SUCCESS" &&
      planChangeStatus.data
    ) {
      Store.removeNotification("accountPlanChangeCardFailure");
      Store.removeNotification("accountPlanChangeBillingError");
      let status = planChangeStatus.data.status;
      if (status.toLowerCase() === "successful") {
        let customer: any = localStorage.getItem("customerDetail");
        customer = JSON.parse(customer);
        let id: any = user?.id ? user?.id : customer?.id;
        firebase
          .database()
          .ref(FirebaseDBURLs.operationStatus + id)
          .once("value")
          .then(function (snapshot: any) {
            if (snapshot.val() && snapshot.val().progress) {
              Store.removeNotification("planChangeSuccessToast");
              upgradePlanNowOrLaterSuccess();
              setIsOperationInitiated(false);
              setIsPlanChangeInitiated(false);
              updateOperationStatusToFirebase(user.id, false);
              setIsGetPlanChangeStatusClicked(false);
              setSelectedPlan("");
            }
          });
      } else if (status.toLowerCase() === "refunded") {
        setIsPlanChangeInitiated(false);
        setIsOperationInitiated(false);
        Store.removeNotification("planChangeSuccessToast");
        updateOperationStatusToFirebase(user.id, false);
        setIsGetPlanChangeStatusClicked(false);
      } else if (status.toLowerCase() === "in_progress") {
        setIsPlanChangeInitiated(true);
        setIsGetPlanChangeStatusClicked(false);
        let isNotiEnable: any = localStorage.getItem("isPlanChangeNotiEnabled");
        if (isNotiEnable !== "yes") {
          showPlanChangeNotification();
          localStorage.setItem("isPlanChangeNotiEnabled", "yes");
        }
      }
    }
  }, [planChangeStatus]);

  const confirmPlanChangeHandler = (type: string) => {
    if (type === "later") {
      setUpdateType("later");
      let selectedStorePlan: any = localStorage.getItem("selectedPlan");
      let selectedLocalPlan: any = JSON.parse(selectedStorePlan);
      dispatch(
        ChangePlan({
          newReachPlanId: selectedLocalPlan
            ? selectedLocalPlan?.name
            : selectedPlan && selectedPlan.name,
          immediateEffect: false,
          customerId: user && user.id,
          currentPlanIdenifier: currentPlan && currentPlan?.uniqueIdentifier,
          newPlanIdentifier: selectedPlan && selectedPlan?.uniqueIdentifier,
        })
      );
    } else if (type === "now") {
      setUpdateType("now");
      let selectedStorePlan: any = localStorage.getItem("selectedPlan");
      let selectedLocalPlan: any = JSON.parse(selectedStorePlan);
      dispatch(
        ChangePlan({
          newReachPlanId: selectedLocalPlan
            ? selectedLocalPlan?.name
            : selectedPlan && selectedPlan.name,
          immediateEffect: true,
          customerId: user && user.id,
          currentPlanIdenifier: currentPlan && currentPlan?.uniqueIdentifier,
          newPlanIdentifier: selectedPlan && selectedPlan?.uniqueIdentifier,
        })
      );
    }
  };

  const SelectPlanAndProceedHandler = () => {
    if (
      plandata &&
      plandata.length > 0 &&
      selectedPlan &&
      user &&
      user.reachPlanId
    ) {
      if (currentPlan && selectedPlan) {
        setIsUpdateLaterClicked(false);
        setOpen1(true);
        if (currentPlan && selectedPlan) {
          if (
            currentPlan?.planData < selectedPlan?.planData &&
            currentPlanCost < newPlanCost
          ) {
            if (
              (currentPlan.unlimited && !selectedPlan.unlimited) ||
              (!currentPlan.unlimited && selectedPlan.unlimited)
            ) {
              taxOnDiffAmount.current = false;
            } else {
              taxOnDiffAmount.current = true;
            }
          } else {
            taxOnDiffAmount.current = false;
          }
        }
        if (selectedPlan.isUnlimited) {
          setLowerPlanSelected(false);
          setSharedPlanSelected(false);
          setUnlimitedPlanSelected(true);
        } else if (selectedPlan.planData <= currentPlan.planData) {
          setSharedPlanSelected(false);
          setUnlimitedPlanSelected(false);
          setLowerPlanSelected(true);
        } else if (selectedPlan.planData > currentPlan.planData) {
          setUnlimitedPlanSelected(false);
          setLowerPlanSelected(false);
          setSharedPlanSelected(true);
        }
      }
    }
  };
  const plans = plandata;
  const handlePlanSelection = (newPlan: any) => {
    setSelectedPlan(newPlan);
  };
  useNonInitialEffect(() => {
    if (
      plandata &&
      plandata.length > 0 &&
      selectedPlan &&
      user &&
      user.reachPlanId
    ) {
      let currentPlan: any = plandata.find(
        (item: any) => item.name === user.reachPlanId
      );
      if (user?.reachPlanId === EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME) {
        currentPlan = landingPromoPlan;
      }
      if (currentPlan) {
        const additionalActivatedLines = user.totalActiveLines - 1;
        let currentPlanPrice: any = planPriceCalculationHanlder(
          currentPlan,
          additionalActivatedLines,
          user
        );
        let newPlanPrice: any = planPriceCalculationHanlder(
          selectedPlan,
          additionalActivatedLines,
          user
        );
        setCurrentPlanCost(currentPlanPrice);
        setNewPlanCost(newPlanPrice);
      }
    }
  }, [selectedPlan]);

  const countHandler = (value: number) => {
    setCountValue(value);
  };
  const showDataTopUpNotification = () => {
    window.scroll(0, 0);
    NotificationCust({
      message: cpbdpSecContent
        ? cpbdpSecContent?.inProTopUpNote2
        : cpbdpContent?.inProTopUpNote2,
      type: "info",
      id: "topUpInProgressToast",
      duration: 0,
      showIcon: true,
    });
  };
  const BuyDataSecPulseDetails: any = EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED
    ? dataPackPlan
    : topupPulseDetails;
  const proceedButtonHandler = () => {
    localStorage.removeItem("addCardAccount");
    localStorage.removeItem("tax");
    localStorage.removeItem("activeTab");
    localStorage.setItem("countValue", JSON.stringify(countValue));
    let storedCountValue = localStorage.getItem("countValue");
    let payload = {
      amount:
        EnvConfig.FIXED_BUY_DATA_PACK ||
        EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED
          ? (BuyDataSecPulseDetails?.pulse *
              BuyDataSecPulseDetails?.pulseCost) /
            BuyDataSecPulseDetails?.pulse
          : (Number(storedCountValue) *
              (BuyDataSecPulseDetails && BuyDataSecPulseDetails?.pulseCost)) /
            (BuyDataSecPulseDetails && BuyDataSecPulseDetails?.pulse),
      customerId: user && user.id,
      type: 4,
      reachPlanId:
        EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED && dataPackPlan?.topupName,
    };
    dispatch(type4TaxCalculation(payload));
  };
  const updateLaterHandler = () => {
    setIsUpdateLaterClicked(true);
    if (EnvConfig?.SHOW_COMPARE_PLAN) {
      setOpen1(false);
      setComparePlansDialogLater(true);
    }
    taxOnDiffAmount.current = false;
    ChangePlanProceedHandler();
  };

  const ChangePlanProceedHandler = () => {
    let selectedStorePlan: any = localStorage.getItem("selectedPlan");
    let selectedLocalPlan: any = JSON.parse(selectedStorePlan);
    let amount: any = localStorage.getItem("amount");
    let NewPlanLocalCost: any = JSON.parse(amount);
    let billingAddress: any =
      user &&
      user.addresses &&
      user.addresses.find((el: any) => el.type === "billing");
    const { firstName, lastName } = user;
    let code: string = billingAddress && billingAddress.state;
    let differencePlanCost: any = newPlanCost - currentPlanCost;
    billingAddress &&
      dispatch(
        getPlanTaxCharges({
          address: {
            name: `${firstName} ${lastName}`,
            address1: billingAddress && billingAddress?.address1,
            city: billingAddress && billingAddress?.city,
            country: billingAddress && billingAddress?.country,
            state: code,
            type: "billing",
            zip: billingAddress && billingAddress.zip,
          },
          reachPlanId: selectedLocalPlan
            ? selectedLocalPlan?.name
            : selectedPlan?.name,
          amount: taxOnDiffAmount.current
            ? differencePlanCost
            : NewPlanLocalCost
            ? NewPlanLocalCost?.newPlanCost
            : newPlanCost,
          additionalLines: user?.totalActiveLines - 1,
        })
      );
  };

  const confirmPurchaseHandler = () => {
    let tax: any = localStorage.getItem("tax");
    let taxType4: any = JSON.parse(tax);
    let storedCountValue = localStorage.getItem("countValue");

    let payload = {
      customerId: unlimited && selectedLine ? selectedLine : user && user.id,
      totalData:
        EnvConfig.FIXED_BUY_DATA_PACK ||
        EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED
          ? BuyDataSecPulseDetails?.pulse
          : Number(storedCountValue),
      taxId: taxType4 ? taxType4?.taxId : type4Tax?.taxId,
      taxAmount: taxType4 ? taxType4?.totalTax : type4Tax?.totalTax,
      taxInfo: taxType4 ? taxType4 : type4Tax,
      ccUUID: selectedCard?.uuid ? selectedCard?.uuid : mainCard?.uuid,
    };
    dispatch(topUpCustomer(payload));
  };
  useNonInitialEffect(() => {
    if (type4Tax) {
      setBuyDataDialog(true);
    }
  }, [type4Tax]);

  useNonInitialEffect(() => {
    NotificationCust({
      message: cpbdpSecContent
        ? cpbdpSecContent?.default_error
        : cpbdpContent?.default_error,
      type: "danger",
      duration: 3000,
      id: "taxFailure",
      showIcon: true,
    });
  }, [errorType4Tax]);
  const refreshCustomer = () => {
    let currentdetails: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    dispatch(
      GetCustomer({
        username: encodeURIComponent(currentdetails?.emailId),
      })
    );
  };
  useEffect(() => {
    let timer: any;
    if (customerTopUp) {
      timer = setInterval(() => {
        refreshCustomer();
      }, 5000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [customerTopUp]);

  useNonInitialEffect(() => {
    if (customerTopUp) {
      if (!getCustomer?.operationsInProcess.includes("TOPUP")) {
        setBuyDataDialog(false);
        setIsTopUpInitiated(true);
        updateOperationStatusToFirebase(user.id, true);
        localStorage.setItem("isDataTopNotiEnabled", "yes");
        showDataTopUpNotification();
        setIsOperationInitiated(true);
        dispatch(
          topUpCustomerStatus({
            customerId: user && user.id,
          })
        );
        if (localStorage.getItem("tax")) {
          localStorage.removeItem("addCardAccount");
          localStorage.removeItem("tax");
          localStorage.removeItem("activeTab");
          localStorage.removeItem("selectedLine");
        }
        dispatch(
          topUpGroup({
            groupId: user && user.groupId,
            startDate:
              user &&
              user.billStartDate &&
              new Date(user.billStartDate).toISOString().slice(0, 10),
            endDate:
              user &&
              user.billEndDate &&
              new Date(user.billEndDate).toISOString().slice(0, 10),
          })
        );
      }
    }
  }, [customerTopUp]);

  useNonInitialEffect(() => {
    if (errorCustomerTopUp && errorCustomerTopUp?.status === "FAILURE") {
      if (localStorage.getItem("tax")) {
        localStorage.removeItem("addCardAccount");
        localStorage.removeItem("tax");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("selectedLine");
      }
      setBuyDataDialog(false);
      setIsTopUpInitiated(true);
      setIsOperationInitiated(false);
      if (errorCustomerTopUp?.resCode === 40122) {
        setIsTopUpInitiated(false);
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.error_card_details
            : cpbdpContent?.error_card_details,
          type: "danger",
          id: "cardErrorCustTopUp",
          showIcon: true,
        });
      } else if (errorCustomerTopUp?.resCode === 40138) {
        setIsTopUpInitiated(false);
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.error_billing
            : cpbdpContent?.error_billing,
          type: "danger",
          id: "billingErrorCustTopUp",
          showIcon: true,
        });
      } else {
        NotificationCust({
          message: cpbdpSecContent
            ? cpbdpSecContent?.topup_fail_msg
            : cpbdpContent?.topup_fail_msg,
          type: "danger",
          duration: 3000,
          id: "defaultMsgTopUp",
        });
        setIsTopUpInitiated(false);
        updateOperationStatusToFirebase(user.id, true);
      }
    }
  }, [errorCustomerTopUp]);

  const updateOperationStatusToFirebase = async (
    id: string,
    value: boolean
  ) => {
    await firebase
      .database()
      .ref(FirebaseDBURLs.operationStatus + id)
      .update({ progress: value }, (error) => {
        if (error) {
          console.log("Operation Status fail.");
        }
      });
  };

  const cardChangeHandler = () => {
    let activeLocalTab: any = localStorage.getItem("activeTab");
    let activeStoredTab = JSON.parse(activeLocalTab);
    GAAndFBEventsHandler(gaCategory.changePlan, "change");
    setShowCardChangeModal(true);
    if (activeStoredTab) {
      if (Number(activeStoredTab) === 2) {
        setBuyDataDialog(false);
      } else if (Number(activeStoredTab) === 1) {
        setComparePlansDialog(false);
      }
    } else {
      if (activeTab === 2) {
        setBuyDataDialog(false);
      } else if (activeTab === 1) {
        setComparePlansDialog(false);
      }
    }
  };
  let gbUpdated = localStorage.getItem("countValue");
  const dataPack =
    EnvConfig?.FIXED_BUY_DATA_PACK || EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED
      ? BuyDataSecPulseDetails?.pulse
      : gbUpdated;
  const noifierMessage = cpbdpSecContent
    ? cpbdpSecContent?.added_data_billing
    : cpbdpContent?.added_data_billing;
  useNonInitialEffect(() => {
    let addCardAccount = localStorage.getItem("addCardAccount");
    if (custTopUpStatus) {
      let status = custTopUpStatus?.status;
      if (status === "SUCCESSFUL") {
        let id: any = user && user.id;
        firebase
          .database()
          .ref(FirebaseDBURLs.operationStatus + id)
          .once("value")
          .then(
            function (snapshot: any) {
              if (snapshot.val() && snapshot.val().progress) {
                Store.removeNotification("topUpInProgressToast");
                window.scroll(0, 0);
                NotificationCust({
                  message: noifierMessage?.replace("{unit}", dataPack),
                  type: "info",
                  duration: 3000,
                  id: "accountDataAddedSuccess",
                  showIcon: true,
                });
                setIsTopUpInitiated(false);
                setIsGetTopUpStatusClicked(false);
                setIsOperationInitiated(false);
                !addCardAccount && setCurrentSection("DataDetails");
                updateOperationStatusToFirebase(user.id, false);
                setTimeout(() => {
                  if (user?.autoTopUpStatus === 2) {
                    setOpen(true);
                    setTopup(true);
                  } else {
                    if (!EnvConfig?.DISABLE_AUTO_RECCURING_TOP_UP) {
                      setOpen(true);
                      setTopup(true);
                    }
                  }
                }, 2000);
                dispatch(
                  GetCustomer({
                    username: encodeURIComponent(user && user.emailId),
                  })
                );
              }
            },
            function (error: any) {
              console.log(error);
            }
          );
      } else if (status === "REFUNDED") {
        setCurrentSection("DataDetails");
        setIsGetTopUpStatusClicked(false);
        updateOperationStatusToFirebase(user.id, false);
        setIsTopUpInitiated(false);
        setIsOperationInitiated(false);
        Store.removeNotification("topUpInProgressToast");
      } else if (status === "IN_PROGRESS") {
        setIsTopUpInitiated(true);
        setIsGetTopUpStatusClicked(false);
        let isNotiEnabled: any = localStorage.getItem("isDataTopNotiEnabled");
        if (isNotiEnabled !== "yes") {
          showDataTopUpNotification();
          localStorage.setItem("isDataTopNotiEnabled", "yes");
        }
      }
    }
  }, [custTopUpStatus]);

  useNonInitialEffect(() => {
    setIsGetTopUpStatusClicked(false);
  }, [errorCustTopUpStatus]);

  useNonInitialEffect(() => {
    if (
      groupTopUp &&
      groupTopUp?.PURCHASED &&
      groupTopUp?.PURCHASED.length > 0
    ) {
      setViewPurchaseHistoryTopup(true);
    }
  }, [groupTopUp]);

  const { amount, totalTax } = type4Tax || {};
  const getTopUpUpdatedStatus = () => {
    setIsGetTopUpStatusClicked(true);

    dispatch(
      topUpCustomerStatus({
        customerId: user && user.id,
      })
    );
  };

  const getPlanChangeUpdatedStatus = () => {
    setIsGetPlanChangeStatusClicked(true);

    dispatch(
      PlanChangeCustomerStatus({
        customerId: user && user.id,
      })
    );
  };
  const removeAllTickers = () => {
    Store.removeNotification("topUpInProgressToast");
    Store.removeNotification("billingErrorCustTopUp");
    Store.removeNotification("cardErrorCustTopUp");
    Store.removeNotification("taxFailure");
  };
  const payConfirmHandler = () => {
    if (activeTab === 1) {
      confirmPlanChangeHandler("now");
    } else if (activeTab === 2) {
      confirmPurchaseHandler();
    }
  };
  let tax: any = localStorage.getItem("tax");
  let tax4: any = JSON.parse(tax);
  return (
    (cppdSecContent || cppdContent) &&
    (cpbdpSecContent || cpbdpContent) && (
      <>
        <CpbdpComponent
          setCurrentSection={setCurrentSection}
          content={cpbdpSecContent ? cpbdpSecContent : cpbdpContent}
          cppdContent={cppdSecContent ? cppdSecContent : cppdContent}
          tabsHandler={tabsHandler}
          activeTab={activeTab}
          tabsContent={tabsContent}
          plans={plans}
          selectedPlan={selectedPlan}
          planDetails={planDetails}
          handlePlanSelection={handlePlanSelection}
          isTopUpInitiated={isTopUpInitiated}
          isPlanChangeInitiated={isPlanChangeInitiated}
          SelectPlanAndProceedHandler={SelectPlanAndProceedHandler}
          unlimited={unlimited}
          group={group}
          selectedLine={selectedLine}
          setSelectedLine={setSelectedLine}
          topupPulseDetails={BuyDataSecPulseDetails}
          countValue={countValue}
          countHandler={countHandler}
          proceedButtonHandler={proceedButtonHandler}
          tax4Loader={tax4Loader}
          isGetTopUpStatusClicked={isGetTopUpStatusClicked}
          isGetPlanChangeStatusClicked={isGetPlanChangeStatusClicked}
          getTopUpUpdatedStatus={getTopUpUpdatedStatus}
          getPlanChangeUpdatedStatus={getPlanChangeUpdatedStatus}
          viewPurchaseHistoryTopup={viewPurchaseHistoryTopup}
          setdataPurchaseDialog={setdataPurchaseDialog}
          open1={open1}
          setOpen1={setOpen1}
          lowerPlanSelected={lowerPlanSelected}
          sharedPlanSelected={sharedPlanSelected}
          unlimitedPlanSelected={unlimitedPlanSelected}
          buyDataDialog={buyDataDialog}
          setBuyDataDialog={setBuyDataDialog}
          confirmPurchaseHandler={confirmPurchaseHandler}
          totalTax={tax4 ? tax4?.totalTax : totalTax}
          amount={tax4 ? tax4?.amount : amount}
          customerTopUpLoader={customerTopUpLoader}
          mainCard={mainCard}
          comparePlansDialog={comparePlansDialog}
          setComparePlansDialog={setComparePlansDialog}
          comparePlansDialogLater={comparePlansDialogLater}
          setComparePlansDialogLater={setComparePlansDialogLater}
          dataPurchaseDialog={dataPurchaseDialog}
          groupTopUp={groupTopUp}
          isOperationInitiated={isOperationInitiated}
          setIsOperationInitiated={setIsOperationInitiated}
          ChangePlanProceedHandler={ChangePlanProceedHandler}
          user={user}
          currentPlan={currentPlan}
          newPlanCost={newPlanCost}
          currentPlanCost={currentPlanCost}
          newPlanTax={newPlanTax}
          currentPlanTax={currentPlanTax}
          updateLaterHandler={updateLaterHandler}
          confirmPlanChangeHandler={confirmPlanChangeHandler}
          planChangeLoader={planChangeLoader}
          updateType={updateType}
          cardChangeHandler={cardChangeHandler}
          showCardChangeModal={showCardChangeModal}
          setShowCardChangeModal={setShowCardChangeModal}
          setSelectedCard={setSelectedCard}
          ProceedPayment={payConfirmHandler}
          isCardExpired={isCardExpired}
          setDataPackPlan={setDataPackPlan}
          topUpPulse={topUpPulse?.data}
          dataPackPlan={dataPackPlan}
        />
      </>
    )
  );
};
