import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  countries: Array<any>;
  countriesATT: Array<any>;
  countriesRatesATT: any;
  errorInCountries: any;
  errorInCountriesATT: any;
  errorInCountriesRatesATT: any;
  isBanner1: boolean;
};
const initialState: InitialState = {
  countries: [],
  countriesATT: [],
  // countriesRatesATT: [],
  countriesRatesATT: null,
  errorInCountries: null,
  errorInCountriesATT: null,
  errorInCountriesRatesATT: null,
  isBanner1: true,
};

export const getCounriesList = createAsyncThunk(
  "internationalService/getCounriesList",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: ApiEndPoints.countryList,
    });
  }
);
export const getCounriesListATT = createAsyncThunk(
  "internationalService/getCounriesListATT",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: ApiEndPoints.countryListATT,
    });
  }
);

export const getCounriesRatesATT = createAsyncThunk(
  "internationalService/getCounriesRatesATT",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: ApiEndPoints.countryRatesATT,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const internationalService = createSlice({
  name: "internationalService",
  initialState,
  reducers: {
    bannerHandler: (state, action) => {
      state.isBanner1 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCounriesList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countries = action.payload.data.data.countriesList;
        state.errorInCountries = null;
      }
    );
    builder.addCase(getCounriesList.rejected, (state, action) => {
      state.countries = [];
      state.errorInCountries = action.error || "Something went wrong";
    });
    builder.addCase(
      getCounriesListATT.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countriesATT = action.payload.data.data.countriesList;
        state.errorInCountriesATT = null;
      }
    );
    builder.addCase(getCounriesListATT.rejected, (state, action) => {
      state.countriesATT = [];
      state.errorInCountriesATT = action.error || "Something went wrong";
    });
    builder.addCase(
      getCounriesRatesATT.pending,
      (state, action: PayloadAction<any>) => {
        state.countriesRatesATT = null;
        state.errorInCountriesRatesATT = null;
      }
    );
    builder.addCase(
      getCounriesRatesATT.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countriesRatesATT = action.payload.data.data;
        state.errorInCountriesRatesATT = null;
      }
    );
    builder.addCase(getCounriesRatesATT.rejected, (state, action) => {
      state.countriesRatesATT = [];
      state.errorInCountriesRatesATT = action.error || "Something went wrong";
    });
  },
});
export const { bannerHandler } = internationalService.actions;

export default internationalService.reducer;
