import { Box, Stack } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import { ManageCard } from "../../../widgets";

type cardProps = {
  showCardChangeModal: boolean;
  setShowCardChangeModal: any;
  isModalRequired: any;
  pageName?: string;
};
export const ActivationManageCard = (props: cardProps) => {
  const { showCardChangeModal, setShowCardChangeModal, isModalRequired, pageName } =
    props;
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});

  return (
    <Stack
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid="activation_step_manage_card_Wrapper"
    >
      <Box sx={{ width: { xs: "95%", sm: "55%", md: "40%", lg: "35%" } }}>
        <ManageCard
          cardData={getCCDetails}
          showCardChangeModal={showCardChangeModal}
          setShowCardChangeModal={setShowCardChangeModal}
          isModalRequired={isModalRequired}
          pageName={pageName}
          isDescription={false}
          payType={true}
        />
      </Box>
    </Stack>
  );
};
