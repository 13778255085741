import { useEffect, useState} from "react";
import { Card, Typography, Grid, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ButtonCust, Divider, NotificationCust } from "../../widgets";
// import PlanData from "../../../config/data.json";
import {
  GetCustomer,
  hsdAddApi,
  UpdateCustomerSource,
} from "../../../features/checkout/checkoutSlice";
import EnvConfig from "../../../config/env.config.json";
import { Store } from "react-notifications-component";
import {
  discountAppliedApi,
  reachPlanData,
  resetEligibilityCheckErr,
} from "../../../features/homepage/homepageSlice";
import HSDCheckFormModal from "./HSDCheckFormModal";
import HSDSuccessModal from "./HSDSuccessModal";
import HSDFailureModal from "./HSDFailureModal";
import HSDFailureMatchFoundModal from "./HSDFailureMatchFoundModal";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { useNonInitialEffect } from "../../../utils/commonFunctions";

type Props = {
  content: any;
  eligibilityCheckFromPromo: any;
  setEligibilityCheckFromPromo: any;
};
const HSD = (props: Props) => {
  const {
    success_modal,
    failure_modal,
    fail_match_found,
    check_form,
    acc_num_popover,
    eBtn1,
    eBtn2,
    hsdMsg1,
    hsdMsg2,
    hsdMsg3,
    notify1,
    notify2,
    notify3,
    notify4,
    hsd_ttl,
    verified_msg,
  } = props?.content;

  const {
    eligibilityCheck,
    eligibilityCheckError,
    discountApplied,
    discountAppliedError,
  } = useAppSelector((state: any) => state.homepage);
  const { getCustomer, updateCustomerSource, hsdAdd, hsdAddError } =
    useAppSelector((state: any) => state.checkout);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let currentuser: any = localStorage.getItem("currentuser");
  currentuser = JSON.parse(currentuser);
  let customerDetail: any = localStorage.getItem("customerDetail");
  let reachSelectedPlanDetail: any = localStorage.getItem(
    "reachSelectedPlanDetail"
  );
  customerDetail = JSON.parse(customerDetail);
  const [hsdCheckFormModal, setHsdCheckFormModal] = useState(
    props?.eligibilityCheckFromPromo
  );
  const [hsdSuccessModal, setHsdSuccessModal] = useState(false);
  const [hsdFailureModal, setHsdFailureModal] = useState(false);
  const [wowDataObject, setWowDataObject] = useState<any>(null);
  const [hsdFailureModalWithMatchFound, setHsdFailureModalWithMatchFound] =
    useState(false);
  const [eligibilityCheckCounter, setEligibilityCheckCounter] = useState(0);
  const initialWowCustomerData = {
    wowAccountNumber: "",
    wowAccountServiceZipcode: "",
    wowCustomerLastName: "",
  };
  const [wowCustomerData, setWowCustomerData] = useState(
    initialWowCustomerData
  );
  const [isCheckEligibilityClicked, setIsCheckEligibilityClicked] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [eligibilityButton, setEligibilityButton] = useState(eBtn1);
  const [tabVal, setTabVal] = useState<number>(1);
  const [isDiscountCheck, setIsDiscountCheck] = useState<any>(null);
  const [wowHsdMsg, setWowHsdMsg] = useState("");
  const [isDiscountEligible, setIsDiscountEligible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const { planData } = useAppSelector(
    (state: any) => state.homepage
  );
  useEffect(() => {
    if(!planData){
      dispatch(reachPlanData({}));
    }
  }, []);
  useEffect(() => {
    if (props?.eligibilityCheckFromPromo === false) {
      window.scroll(0, 0);
      if (currentuser?.isAnonymous || customerDetail?.simPaid === false) {
        navigate(RoutingURLs?.home);
      }
    }
    let planName = reachSelectedPlanDetail?.plan?.name;
    let plan = planData?.data.find((item?: any) => {
      return item.name === planName;
    });
    setSelectedPlan(plan);
    dispatch(GetCustomer({ username: encodeURIComponent(currentuser?.email) }));
    return () => {
      Store.removeAllNotifications();
      dispatch(resetEligibilityCheckErr());
    };
  }, []);
  useNonInitialEffect(() => {
    if (getCustomer) {
      let hsd_valid_text = BrandNameUpdate(hsdMsg2);
      let hsd_validate_text = BrandNameUpdate(hsdMsg3);
      if (!currentuser?.isAnonymous) {
        let lastName = getCustomer?.lastName;
        setWowCustomerData((prevProps) => ({
          ...prevProps,
          wowCustomerLastName: lastName,
        }));
      }
      if (getCustomer?.hasOwnProperty("wowHsdRequestedState")) {
        if (getCustomer.wowHsdRequestedState === "ADD_IN_PROGRESS") {
          setWowHsdMsg(hsdMsg1);
          setIsDiscountCheck(false);
        } else if (getCustomer.wowHsdRequestedState === "REMOVE_IN_PROGRESS") {
          setWowHsdMsg(hsd_valid_text);
          setIsDiscountCheck(false);
        }
      } else if (getCustomer?.hasOwnProperty("wowHsdState")) {
        if (getCustomer.wowHsdState === "APPLIED") {
          setWowHsdMsg(hsd_valid_text);
          setIsDiscountCheck(false);
        } else if (getCustomer.wowHsdState === "NOT_APPLICABLE") {
          setWowHsdMsg(hsd_validate_text);
          setIsDiscountCheck(true);
        } else if (getCustomer.wowHsdState === "REMOVED") {
          setWowHsdMsg(hsd_validate_text);
          setIsDiscountCheck(true);
        }
      } else {
        setIsDiscountCheck(true);
        setWowHsdMsg(hsd_validate_text);
      }
    }
  }, [getCustomer]);
  useNonInitialEffect(() => {
    if (updateCustomerSource) {
      let data = {
        wowAccountNum: wowCustomerData?.wowAccountNumber,
        wowZipCode: wowCustomerData?.wowAccountServiceZipcode,
        wowLastName: wowCustomerData?.wowCustomerLastName,
        operation: "ADD",
        customerId: customerDetail.id,
        skipMAMCall:
          getCustomer?.simPaid && getCustomer?.status === 0 ? true : false,
        wowHsdState: eligibilityCheck?.status === 206 ? "NOT_APPLICABLE" : null,
        wowHsdRequested:
          eligibilityCheck?.status === 200 ? "ADD_IN_PROGRESS" : null,
      };
      dispatch(hsdAddApi({ data }));
      setWowCustomerData((prevState) => ({
        ...prevState,
        wowAccountNumber: "",
        wowAccountServiceZipcode: "",
      }));
    }
  }, [updateCustomerSource]);
  useNonInitialEffect(() => {
    if (hsdAdd?.status === 200) {
      setIsCheckEligibilityClicked(false);
      if (eligibilityCheck?.status === 200) {
        setHsdSuccessModal(false);
        setIsDiscountEligible(true);
        notify1 &&
          NotificationCust({
            message: notify1,
            id: "EligibilitySuccess",
            duration: 5000,
            type: "info",
            showIcon: true,
          });
      } else if (eligibilityCheckError || eligibilityCheck?.status === 206) {
        setHsdFailureModal(false);
        setHsdFailureModalWithMatchFound(false);
        setEligibilityButton(eBtn2);
        setEligibilityCheckCounter(0);
        notify2 &&
          NotificationCust({
            message: notify2,
            id: "EligibilityError",
            duration: 3000,
            type: "danger",
            showIcon: true,
          });
      }
    }
  }, [hsdAdd]);
  useNonInitialEffect(() => {
    if (hsdAddError?.status === "FAILURE") {
      setIsCheckEligibilityClicked(false);
      notify4 &&
        NotificationCust({
          message: notify4,
          id: "EligibilityError2",
          duration: 2000,
          type: "danger",
          showIcon: true,
        });
      if (hsdAddError?.resCode === 100009 || hsdAddError?.resCode === 100011) {
        notify3 &&
          NotificationCust({
            message: notify3,
            id: "EligibilityError",
            duration: 0,
            type: "danger",
            showIcon: true,
          });

        setEligibilityButton(eBtn2);
      }
      if (eligibilityCheck?.status === 200) {
        setHsdSuccessModal(false);
      } else if (eligibilityCheckError || eligibilityCheck?.status === 206) {
        setHsdFailureModal(false);
        setHsdFailureModalWithMatchFound(false);
        setEligibilityButton(eBtn2);
        setEligibilityCheckCounter(0);
        notify2 &&
          NotificationCust({
            message: notify2,
            id: "EligibilityError",
            duration: 3000,
            type: "danger",
            showIcon: true,
          });
      }
    }
  }, [hsdAddError]);
  const failureWithMatchBtnHandler = () => {
    setIsCheckEligibilityClicked(true);
    dispatch(
      UpdateCustomerSource({
        customerId: customerDetail?.id,
        data: {
          wowCustomerId: eligibilityCheck?.data?.ecid,
          isWowHSDCustomer: true,
          wowHsdState: "NOT_APPLICABLE",
          wowAccountNum: wowCustomerData?.wowAccountNumber,
          wowZipCode: wowCustomerData?.wowAccountServiceZipcode,
          wowLastName: wowCustomerData?.wowCustomerLastName,
        },
      })
    );
  };
  const successBtnHandler = () => {
    setIsCheckEligibilityClicked(true);
    dispatch(
      UpdateCustomerSource({
        customerId: customerDetail?.id,
        data: {
          wowCustomerId: eligibilityCheck?.data?.ecid,
          isWowHSDCustomer: true,
          wowHsdState: null,
          wowHsdRequestedState: "ADD_IN_PROGRESS",
        },
      })
    );
  };
  const proceedToCheckoutHandler = (wowObject: any) => {
    setIsCheckEligibilityClicked(true);
    setWowDataObject(wowObject);
    let data = {
      wowCustomerId: wowObject.ecid,
    };
    dispatch(discountAppliedApi(data)).then((response) => {
      if (response?.payload?.status === 200) {
        navigate(RoutingURLs.checkout, { state: wowObject });
      }
    });
  };

  useNonInitialEffect(() => {
    if ((discountApplied && discountApplied.status) === "SUCCESS") {
      localStorage.setItem("isWowDiscountEligible", "true");
      localStorage.setItem(
        "wowUserDetails",
        JSON.stringify({
          userInfo: wowCustomerData,
          isUnlimitedPlan: selectedPlan?.isUnlimited,
        })
      );
      setIsCheckEligibilityClicked(false);
      setHsdSuccessModal(false);
      setHsdFailureModalWithMatchFound(false);
      navigate(RoutingURLs?.checkout, {
        state: { ...wowDataObject, discountApplied: true },
      });
    }
  }, [discountApplied]);

  useNonInitialEffect(() => {
    if (discountAppliedError) {
      localStorage.setItem("isWowDiscountEligible", "false");
      localStorage.setItem(
        "wowUserDetails",
        JSON.stringify({
          userInfo: wowCustomerData,
          isUnlimitedPlan: selectedPlan?.isUnlimited,
        })
      );
      setIsCheckEligibilityClicked(false);
      setHsdSuccessModal(false);
      setHsdFailureModalWithMatchFound(false);
      notify3 &&
        NotificationCust({
          message: notify3,
          id: "EligibilityError",
          duration: 0,
          type: "danger",
          showIcon: true,
        });
      navigate(RoutingURLs?.checkout, {
        state: { ...wowDataObject, discountApplied: false },
      });
    }
  }, [discountAppliedError]);
  useNonInitialEffect(() => {
    if (eligibilityCheck?.status === 200) {
      setIsCheckEligibilityClicked(false);
      setHsdCheckFormModal(false);
      setHsdSuccessModal(true);
    }
    if (eligibilityCheck?.status === 206) {
      setIsCheckEligibilityClicked(false);
      setHsdCheckFormModal(false);
      setHsdFailureModalWithMatchFound(true);
    }
  }, [eligibilityCheck]);
  useNonInitialEffect(() => {
    if (eligibilityCheckError?.error) {
      setIsCheckEligibilityClicked(false);
      setHsdCheckFormModal(false);
      setHsdFailureModal(true);
      setEligibilityCheckCounter(eligibilityCheckCounter + 1);
    } else {
      setIsCheckEligibilityClicked(false);
      if (!props?.eligibilityCheckFromPromo) {
        setHsdCheckFormModal(false);
      }
    }
  }, [eligibilityCheckError]);
  return (
    <>
      <Grid container display="flex" justifyContent="center" my="30px">
        {!props?.eligibilityCheckFromPromo && (
          <Card
            sx={{
              padding: "10px 60px 30px 60px",
              width: {
                xs: "55%",
                sm: "45%",
                md: "30%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mt: "24px",
                mb: "10px",
                fontSize: "26px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--h4_font_weight)",
                lineHeight: "1.235",
              }}
            >
              {hsd_ttl}
            </Typography>
            <Divider styles={{ mb: "15px" }} />
            {!isDiscountEligible && !wowHsdMsg ? (
              <CircularProgress />
            ) : (
              <Typography
                sx={{
                  mb: "15px",
                  textAlign: "center",
                  fontSize: "18px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--h4_font_weight)",
                  lineHeight: "1.5",
                }}
              >
                {`${
                  isDiscountEligible ? BrandNameUpdate(verified_msg) : wowHsdMsg
                }`}
              </Typography>
            )}
            {isDiscountEligible ? null : isDiscountCheck ? (
              <ButtonCust
                variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  Store.removeAllNotifications();
                  setHsdCheckFormModal(true);
                }}
              >
                {eligibilityButton}
              </ButtonCust>
            ) : null}
          </Card>
        )}
      </Grid>
      {hsdCheckFormModal && eligibilityCheckCounter <= 3 && (
        <HSDCheckFormModal
          content={check_form}
          popoverContent={acc_num_popover}
          openModal={hsdCheckFormModal}
          setHsdCheckFormModal={setHsdCheckFormModal}
          setWowCustomerData={setWowCustomerData}
          initialWowCustomerData={initialWowCustomerData}
          setIsCheckEligibilityClicked={setIsCheckEligibilityClicked}
          selectedPlan={selectedPlan}
          wowCustomerData={wowCustomerData}
          currentuser={currentuser}
          popoverId={popoverId}
          open={open}
          anchorEl={anchorEl}
          handleOpenPopover={handleOpenPopover}
          handleClosePopover={handleClosePopover}
          eligibilityCheckFromPromo={props?.eligibilityCheckFromPromo}
          setEligibilityCheckFromPromo={props?.setEligibilityCheckFromPromo}
        />
      )}
      {hsdSuccessModal && success_modal && (
        <HSDSuccessModal
          content={success_modal}
          openModal={hsdSuccessModal}
          onModalClose={() => {
            setHsdSuccessModal(false);
            props?.setEligibilityCheckFromPromo(false);
          }}
          eligibilityCheck={eligibilityCheck}
          btnHandler={() =>
            props?.eligibilityCheckFromPromo
              ? proceedToCheckoutHandler({
                  ecid: eligibilityCheck?.data?.ecid,
                  ...wowCustomerData,
                })
              : successBtnHandler()
          }
          isCheckEligibilityClicked={isCheckEligibilityClicked}
        />
      )}
      {hsdFailureModal && (
        <HSDFailureModal
          content={failure_modal}
          popoverContent={acc_num_popover}
          openModal={hsdFailureModal}
          onModalClose={() => {
            setHsdFailureModal(false);
            props?.setEligibilityCheckFromPromo(false);
            if (eligibilityCheckCounter === 3) {
              setEligibilityCheckCounter(0);
            }
            setWowCustomerData((prevState) => ({
              ...prevState,
              wowAccountNumber: "",
              wowAccountServiceZipcode: "",
            }));
          }}
          eligibilityCheckCounter={eligibilityCheckCounter}
          tryAgainHandler={() => {
            if (eligibilityCheckCounter !== 3) {
              setWowCustomerData((prevState) => ({
                ...prevState,
                wowAccountNumber: "",
                wowAccountServiceZipcode: "",
              }));
            }
            setHsdFailureModal(false);
            setHsdCheckFormModal(true);
          }}
          popoverId={popoverId}
          open={open}
          anchorEl={anchorEl}
          handleOpenPopover={handleOpenPopover}
          handleClosePopover={handleClosePopover}
          eligibilityCheckFromPromo={props?.eligibilityCheckFromPromo}
        />
      )}
      {hsdFailureModalWithMatchFound && (
        <HSDFailureMatchFoundModal
          content={fail_match_found}
          openModal={hsdFailureModalWithMatchFound}
          isCheckEligibilityClicked={isCheckEligibilityClicked}
          closeModal={() => {
            setHsdFailureModalWithMatchFound(false);
            props?.setEligibilityCheckFromPromo(false);
          }}
          btnHandler={failureWithMatchBtnHandler}
          openFormModal={() => {
            setHsdFailureModalWithMatchFound(false);
            setHsdCheckFormModal(true);
          }}
          eligibilityCheckFromPromo={props?.eligibilityCheckFromPromo}
        />
      )}
    </>
  );
};

export default HSD;
