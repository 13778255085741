import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import SwitchTabs from "../IRILDComponents/SwitchTabs";
import { PostActivationIRILDEnums } from "../../../enums";
import CountryList from "../IRILDComponents/CountryList/CountryList";
import IRPurchaseHistory from "./IRPurchaseHistory/IRPurchaseHistory";
import IRUserList from "./IRUserList/IRUserList";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import envConfig from "../../../config/env.config.json";
import { Link } from "react-router-dom";
import { resetPurchasePack } from "../../../features/services/servicesSlice";
import {
  getResolutionDetails,
  renderTextStyles,
} from "../../../utils/commonFunctions";
import _ from "lodash";
const T3_PAInternationalRoaming = (props: any) => {
  const { content, setInternationalService } = props;
  const {
    title,
    user_data,
    back_arrow,
    stayConnected,
    accessRoaming,
    clickHere,
    clickHereUrl,
  } = content;
  const { mobile } = getResolutionDetails();
  const { group } = useAppSelector((state: any) => state.account);
  const dispatch = useAppDispatch();

  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);
  const [isCountryList, setIsCountryList] = useState<boolean>(false);
  const [fullName, setFullName] = useState(false);
  const [isDataBoostJourney, setIsDataBoostJourney] = useState(false);
  const [rescheduleSuccess, setRescheduleSuccess] = useState(false);
  const [viewRates, setViewRates] = useState(false);

  const onClickSwitchTabs = (type: any) => {
    if (type === PostActivationIRILDEnums.tab_left) {
      setShowPurchaseHistory(false);
    } else {
      setShowPurchaseHistory(true);
    }
  };

  const countryListHandler = (data: any, name: any) => {
    dispatch(resetPurchasePack());
    localStorage.setItem("SingleLineData", JSON.stringify(data));
    setIsCountryList(true);
    setFullName(name);
  };

  const handleViewRates = () => {
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    setViewRates(true);
    setIsCountryList(true);
    let { firstName, lastName } = customerDetail ?? {};
    let name: any = firstName + " " + lastName;
    setFullName(name);
  };

  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);
  useEffect(() => {
    if (!isCountryList) {
      setViewRates(false);
    }
  }, [isCountryList]);

  return (
    <>
      {!isCountryList ? (
        <Box
          sx={{
            margin: { xs: "auto", sm: "auto", md: "auto" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: { xs: "100%", sm: "60%", md: "75%", lg: "56%" },
          }}
          data-testid="pa_int_wrapper"
        >
          {!isDataBoostJourney && !rescheduleSuccess ? (
            <>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "30px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& span": {
                    fontWeight: "var(--font_weight_0)",
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    left: { xs: "20px", md: "103px" },
                    top: { xs: "105px", md: "auto" },
                    cursor: "pointer",
                    fontWeight: "var(--font_weight_3)",
                  }}
                  onClick={() => setInternationalService("")}
                  data-testid="pa_int_back"
                >
                  {!mobile ? (
                    <ArrowBackIosIcon
                      sx={{ width: "18px", fontSize: "1.125rem" }}
                      data-testid="pa_int_back_icon"
                    />
                  ) : (
                    <Box
                      component="img"
                      src={back_arrow?.data.attributes.url}
                      alt={back_arrow?.data.attributes.alternativeText}
                      data-testid="pa_int_back_icon"
                    />
                  )}
                  {!mobile && (
                    <Box
                      component="span"
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1) !important",
                        fontSize: "18px",
                        lineHeight: "30px",
                      }}
                      data-testid="pa_int_back_text"
                    >
                      {user_data?.back}
                    </Box>
                  )}
                </Box>
                <Box
                  component="span"
                  style={{ margin: "auto" }}
                  sx={{
                    fontFamily: "var(--font_family_Bold) !important",
                    fontSize: { xs: "18px", sm: "24px" },
                    fontWeight: "var(--font_weight_4)",
                    lineHeight: "36px",
                    color: "var(--black)",
                  }}
                  data-testid="pa_int_title"
                >
                  {title}
                </Box>
              </Typography>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "16px", sm: "18px" },
                    lineHeight: "32px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1)",
                    mt: "30px",
                  }}
                  data-testid="pa_int_subtitle"
                >
                  {stayConnected}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    width: { xs: "100%", sm: "80%" },
                    margin: "auto",
                    marginTop: "15px",
                    fontSize: { xs: "14px", sm: "16px" },
                    lineHeight: "28px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_0)",
                  }}
                  data-testid="pa_int_desc"
                >
                  {accessRoaming}{" "}
                  {clickHere && (
                    <Link
                      to={clickHereUrl}
                      style={{
                        color: "var(--primary_color)",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      data-testid="pa_int_clk_link"
                    >
                      {clickHere}
                    </Link>
                  )}
                </Typography>
              </Box>
              <SwitchTabs
                title_left={user_data?.lines}
                title_right={user_data?.pur_history1}
                onClickSwitchTabs={onClickSwitchTabs}
                showPurchaseHistory={showPurchaseHistory}
                sx={{
                  border: "1px solid var(--primary_color) !important",
                  borderRadius: "10px",
                  background: "var(--white) !important"
                }}
                active={{
                  background: "var(--primary_brand_color_light) !important",
                  borderRadius: "10px !important",
                  color: "var(--primary_color) !important",
                  margin: '2px !important'
                }}
              />{" "}
            </>
          ) : null}

          {showPurchaseHistory ? (
            <IRPurchaseHistory
              isDataBoostJourney={isDataBoostJourney}
              setIsDataBoostJourney={setIsDataBoostJourney}
              onClickSwitchTabs={onClickSwitchTabs}
              rescheduleSuccess={rescheduleSuccess}
              setRescheduleSuccess={setRescheduleSuccess}
            />
          ) : (
            <>
              <IRUserList
                content={content}
                group={group}
                countryListHandler={countryListHandler}
              />
              {content?.user_data?.is_view_rates && (
                <Typography
                  sx={_.merge(
                    renderTextStyles(
                      2,
                      "16px",
                      "primary_color",
                      "26px",
                      "var(--font_weight_2)",
                      0
                    ),
                    {
                      mb: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                    }
                  )}
                  data-testid="Ir_list_view_rate"
                  onClick={handleViewRates}
                >
                  {content?.user_data?.view_rates}
                </Typography>
              )}
            </>
          )}
        </Box>
      ) : (
        <CountryList
          content={content}
          fullName={fullName}
          viewRates={viewRates}
          setIsCountryList={setIsCountryList}
          onClickSwitchTabs={onClickSwitchTabs}
        />
      )}
    </>
  );
};

export default T3_PAInternationalRoaming;
