import React from "react";
import { TextFieldCust, ButtonCust } from "../widgets";
import { SignUpProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { SwitchCust } from "../";
import { numberInStringPattern } from "../../utils/regexPatterns";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
import { RoutingURLs } from "../../config/RoutingURLs";
import { Store } from "react-notifications-component";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import { gtmTagManager } from "../../utils/commonFunctions/GtmTagManager";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Cursor = "pointer";

const SignUpT1 = (props: SignUpProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const {
    content,
    onAppleSignUp,
    onFacebookSignUp,
    onGoogleSignUp,
    isSignUpClicked,
    formik,
    handleFormikChange,
    showLogin,
    formSubmit,
    isCheckout,
  } = props;

  const {
    heading_logo,
    heading_signup,
    signup_logos,
    fname_label,
    lname_label,
    email_label,
    pwd_label,
    pass_info_main,
    pass_info_1,
    pass_info_2,
    btn_signup,
    terms,
    already_acc1,
    already_acc2,
    terms_url,
    note,
    note_txt,
  } = content || {};

  const passwordLengthCheck = () =>
    formik.values.password &&
    formik.values.password !== "" &&
    formik.values.password.length >= 8;

  const passwordPatternCheck = () =>
    formik.values.password &&
    formik.values.password !== "" &&
    numberInStringPattern.test(formik.values.password.toString());

  const getLogo = (index: number) => signup_logos?.data[index].attributes.url;
  const getAltText = (index: number) =>
    signup_logos?.data[index].attributes.alternativeText;
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.signup,
      category: category,
      buttonValue: value,
    });
  };
  const handleSignup = (category: any, value: any) => {
    Store.removeNotification("SignUpErrorNotification");
    GAAndFBEventsHandler(category, value);
    gtmTagManager({
      event: gaScreenNames.signup,
      signup_method: "Email",
    });
  };

  const handleTermsNavigation = () => {
    if (EnvConfig.IS_TERMS_EXTERNAL_LINK) {
      window.open(terms_url, "_blank");
    } else {
      window.open(RoutingURLs.terms, "_blank");
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const VisibilityOffIcon = content?.v_off_img?.data?.attributes?.url;
  const VisibilityOnIcon = content?.v_on_img?.data?.attributes?.url;
  const VisibilityOffAltText = BrandNameUpdate(
    content?.v_off_img?.data?.attributes?.alternativeText
  );
  const VisibilityOnAltText = BrandNameUpdate(
    content?.v_on_img?.data?.attributes?.alternativeText
  );
  return (
    <Box data-testid="signup_parent" sx={{ padding: isCheckout ? { xs: "0px", sm: "0px 30px" } : "" , boxSizing: "border-box" }}>
      <Typography
        component={"h1"}
        variant={"h1"}
        fontSize="18px"
        lineHeight={"36px"}
        fontFamily={"var(--font_family_Semibold)"}
        fontWeight={"var(--font_weight_2)"}
        // pt={{ xs: "25px", sm: 0 }}
        // pb={{ xs: "25px" }}
        // pl={{ xs: "10px", md: "unset" }}
        p={{ xs: "15px 20px 15px 20px", sm: "20px 0px 15px 0px" }}
        textAlign={{ xs: "left", sm: "unset" }}
        display={"flex"}
        color={"#313131"}
        alignItems={"center"}
        justifyContent={{ xs: "start", md: "start" }}
        data-testid="signup_heading"
      >
        {/* {heading_logo?.data?.attributes?.url && (
          <Box
            component="img"
            alt={heading_logo?.data?.attributes?.alternativeText}
            src={heading_logo?.data?.attributes?.url}
            width="26px"
            height="26px"
            mr="10px"
          />
        )} */}
        {heading_signup}
      </Typography>

      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: "1px solid var(--concrete-grey)",
            // margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>

      {EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          pt={{ xs: 0, sm: 3 }}
        >
          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(0)}
            src={getLogo(0)}
            width="162px"
            height="50px"
            onClick={onAppleSignUp}
            data-testid="signup_icon_apple"
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(1)}
            src={getLogo(1)}
            width="162px"
            height="50px"
            onClick={onFacebookSignUp}
            data-testid="signup_icon_fb"
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(2)}
            src={getLogo(2)}
            width="162px"
            height="50px"
            onClick={onGoogleSignUp}
            data-testid="signup_icon_google"
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(3)}
            src={getLogo(3)}
            width="fit-content"
            height="50px"
            onClick={onAppleSignUp}
            data-testid="signup_icon_ios"
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(4)}
            src={getLogo(4)}
            width="fit-content"
            height="50px"
            onClick={onFacebookSignUp}
            data-testid="signup_icon_fb_1"
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(5)}
            src={getLogo(5)}
            width="fit-content"
            height="50px"
            onClick={onGoogleSignUp}
            data-testid="signup_icon_google_1"
          />
        </Stack>
      )}

      <Container disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))} sx={{xs:{paddingLeft: "20px", paddingRight: "20px"},md:{paddingLeft: "0px", paddingRight: "0px"}}}>
        {EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA && (
          <Divider
            sx={{
              "&.MuiDivider-root": {
                "&::before, &::after": {
                  borderTop: `1px solid var(--concrete-grey)`,
                },
              },
              padding: "20px 0px 0px",
            }}
          >
            <Typography
              component={"div"}
              fontFamily={"var(--font_family_Bold)"}
              data-testid="signup_or_text"
            >
              or
            </Typography>
          </Divider>
        )}
        <form
          noValidate
          onKeyDown={(e) => {
            /* form will be submitted on press enter key  */
            if (e.key.toLocaleLowerCase() === "enter") formik.handleSubmit(e);
          }}
          className="signin-form-field"
          data-testid="location-input"
          onSubmit={(e) => formSubmit(e)}
        >
          <Box
            sx={{ p: { xs: "30px 0px 10px 0px", md: "30px 0px 10px 0px" } }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  disabled={isCheckout ? true : false}
                  value={formik.values.firstName}
                  label={fname_label}
                  name="firstName"
                  onChange={(e: any) => {
                    if (
                      /^[a-zA-Z ]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      handleFormikChange(e);
                    }
                  }}
                  error={
                    formik.touched?.firstName && formik?.errors?.firstName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.firstName && formik?.errors?.firstName
                  }
                  data-testid="signup_textfield_1"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  disabled={isCheckout ? true : false}
                  value={formik?.values?.lastName}
                  label={lname_label}
                  name="lastName"
                  onChange={(e: any) => {
                    if (
                      /^[a-zA-Z ]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      handleFormikChange(e);
                    }
                  }}
                  error={
                    formik?.touched?.lastName && formik?.errors?.lastName
                      ? true
                      : false
                  }
                  helperText={
                    formik?.touched?.lastName && formik?.errors?.lastName
                  }
                  data-testid="signup_textfield_2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik?.values?.email}
                  label={email_label}
                  name="email"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.email && formik?.errors?.email
                      ? true
                      : false
                  }
                  helperText={formik.touched?.email && formik.errors.email}
                  data-testid="signup_textfield_3"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  "& input": {
                    background: "var(--white) !important",
                  },
                }}
              >
                <TextFieldCust
                  value={formik.values.password}
                  label={pwd_label}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.password && formik.errors.password
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.password && formik.errors.password
                  }
                  endAdornment={
                    EnvConfig.ENABLE_PASSWORD_VISIBILITY &&
                    (VisibilityOffIcon || VisibilityOnIcon) && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Tooltip title={content?.helper_txt?.off}>
                              <img
                                src={VisibilityOffIcon}
                                alt={VisibilityOffAltText}
                                style={{ width: "22px", height: "18px" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={content?.helper_txt?.on}>
                              <img
                                src={VisibilityOnIcon}
                                alt={VisibilityOnAltText}
                                style={{ width: "22px", height: "18px" }}
                              />
                            </Tooltip>
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  data-testid="signup_textfield_4"
                />
              </Grid>
            </Grid>
            {pass_info_main && (
              <Stack
                display={"flex"}
                alignItems={{ xs: "unset", sm: "center" }}
                mt={"22px"}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Typography
                  component={"div"}
                  sx={{
                    height: "20px",
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    color: "var(--black)",
                  }}
                  data-testid="signup_pass_info_main"
                >
                  {pass_info_main}
                </Typography>
                <Typography
                  component={"div"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "14px",
                      ml: "5px",
                      color: `${
                        passwordLengthCheck()
                          ? "var(--primary_color)"
                          : "var(--account_create_criteria_un_match_txt_color)"
                          ? "var(--account_create_criteria_un_match_txt_color)"
                          : "var(--ford-grey-color)"
                      }`,
                    }}
                    data-testid="signup_length_check"
                  >
                    {passwordLengthCheck() ? "✓" : "✕"}
                  </Typography>
                  <Typography
                    component={"div"}
                    sx={{
                      marginLeft: "7px",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Regular)",
                      color: `${
                        passwordLengthCheck()
                          ? "var(--primary_color)"
                          : "var(--account_create_criteria_un_match_txt_color)"
                          ? "var(--account_create_criteria_un_match_txt_color)"
                          : "var(--ford-grey-color)"
                      }`,
                    }}
                    data-testid="signup_pass_info_1"
                  >
                    {pass_info_1}
                  </Typography>
                </Typography>
                <Typography
                  component={"div"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "14px",
                      ml: "5px",
                      color: passwordPatternCheck()
                        ? "var(--primary_color)"
                        : "var(--account_create_criteria_un_match_txt_color)"
                        ? "var(--account_create_criteria_un_match_txt_color)"
                        : "var(--ford-grey-color)",
                    }}
                    data-testid="signup_pattern_check"
                  >
                    {passwordPatternCheck() ? "✓" : "✕"}
                  </Typography>

                  <Typography
                    component={"div"}
                    sx={{
                      marginLeft: "7px",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Regular)",
                      color: `${
                        passwordPatternCheck()
                          ? "var(--primary_color)"
                          : "var(--account_create_criteria_un_match_txt_color)"
                          ? "var(--account_create_criteria_un_match_txt_color)"
                          : "var(--ford-grey-color)"
                      }`,
                    }}
                    data-testid="signup_pass_info_2"
                  >
                    {pass_info_2}
                  </Typography>
                </Typography>
              </Stack>
            )}
            {note && (
              <Typography
                component={"div"}
                sx={{
                  fontSize: "14px",
                  lineHeight: "25px",
                  fontWeight: "var(--font_weight_2)",
                  fontFamily: "var(--font_family_Semibold)",
                  marginTop: "20px",
                  color: "var(--dark-charcoal-fade)",
                }}
                data-testid="signup_note"
              >
                {note}
              </Typography>
            )}
            {note_txt && (
              <Typography
                component={"div"}
                sx={{
                  fontSize: "14px",
                  lineHeight: "25px",
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                  color: "var(--text_color)",
                }}
                data-testid="signup_note_txt"
              >
                {BrandNameUpdate(note_txt)}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "20px",
              }}
            >
              <SwitchCust
                data-testid="signup_switch"
                handleSwitchToggle={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  formik.setFieldValue("checked", e.target.checked);
                  formik.setTouched({
                    ...formik.touched,
                    ["checked"]: false,
                  });
                }}
                checked={formik.values.checked}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "16px",
                        color: "var(--text_color_13)",
                        fontFamily: "var(--font_family_Semibold)",
                        mr: "4px",
                      }}
                      data-testid="signup_helper_txt_agree_to"
                    >
                      {BrandNameUpdate(content?.helper_txt?.agree_to)}{" "}
                      {/* {content?.helper_txt?.agree_to}{" "} */}
                    </Typography>
                    <Typography
                      component={"span"}
                      onClick={handleTermsNavigation}
                      sx={{
                        cursor: Cursor,
                        fontSize: "16px",
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                      data-testid="signup_helper_txt_terms"
                    >
                      {BrandNameUpdate(terms)}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          {formik.touched?.checked && formik.errors.checked && (
            <Typography
              component={"div"}
              sx={{
                color: "var(--danger)",
                textAlign: "center",
                fontFamily: "var(--font_family_Bold)",
                fontSize: "0.75rem",
              }}
              data-testid="signup_error"
            >
              {formik.errors.checked}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
              },
            }}
          >
            <ButtonCust
              sx={{ my: "15px", width: { sm: "auto", xs: "100%" } }}
              loading={!isSignUpClicked}
              disabled={!formik.values.checked}
              type={"submit"}
              variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
              onClick={() => handleSignup(gaCategory.signup, btn_signup)}
              data-testid="signup_btn_1"
            >
              {btn_signup}
            </ButtonCust>
          </Box>
        </form>
        <Typography
          component={"div"}
          fontSize={"14px"}
          fontFamily={"var(--font_family_Medium)"}
          pb="30px"
          pt="15px"
          sx={{
            textAlign: {
              xs: "center",
            },
            color: "var(--text_color_14)",
          }}
          data-testid="signup_already_acc1"
        >
          {already_acc1}&nbsp;
          <Typography
            component={"span"}
            fontSize={"inherit"}
            color={"var(--primary_color)"}
            fontFamily={"inherit"}
            fontWeight={"var(--font_weight_2)"}
            sx={{ cursor: Cursor }}
            onClick={showLogin}
            data-testid="signup_already_acc2"
          >
            {already_acc2}
          </Typography>
        </Typography>
      </Container>
    </Box>
  );
};

export default SignUpT1;
