import AppConfig from "../../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../../utils/commonFunctions";

const AddCreditPaymentComp = isTemplateExisits(
  AppConfig.PostActivationServices.ILDAddCreditPayment
)
  ? require(`./${AppConfig.PostActivationServices.ILDAddCreditPayment.template}_AddCreditPayment`)
      .default
  : null;

function AddCreditPayment(props: any) {
  return (
    <>
      <AddCreditPaymentComp {...props} />
    </>
  );
}

export default AddCreditPayment;
