import WalletCard from "../HistoryCard/WalletCard";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../app/hooks";
import { format } from "date-fns";
import _ from "lodash";

const T2_UsageHistory = (props: any) => {
  const { country, usage, less_icon, greater_icon, no_usage } = props.content;
  const { walletuserData, getStartAndEndDateData, memoizedData } = props;
  const { getILDUsageHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  const { CustomerBillCycleDataUsage } = useAppSelector(
    (state) => state?.customer
  );
  return (
    <>
      {walletuserData ? (
        <WalletCard lineDataForWallet={walletuserData} content={props.content}>
          {getILDUsageHistoryPending && !CustomerBillCycleDataUsage ? (
            <Box textAlign={"center"}>
              <CircularProgress sx={{ color: "var(--primary_color)" }} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  borderTop: "1px solid var(--lightnavy)",
                  borderBottom: "1px solid var(--lightnavy)",
                  p: "10px 0",
                }}
              >
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      props.selectedDateKeyIndex > 0
                    ) {
                      props.handleBillCycleChange("decrement");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={less_icon.data.attributes.url}
                    alt={less_icon.data.attributes.alternativeText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_4)",
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {getStartAndEndDateData?.displayStartDate
                      ? format(
                          new Date(getStartAndEndDateData.displayStartDate),
                          "MMMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                  <span>-</span>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_4)",
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {getStartAndEndDateData?.displayEndDate
                      ? format(
                          new Date(getStartAndEndDateData.displayEndDate),
                          "MMMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      props.selectedDateKeyIndex !==
                        getStartAndEndDateData.totalNumberOfKeys - 1
                    ) {
                      props.handleBillCycleChange("increment");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={greater_icon.data.attributes.url}
                    alt={greater_icon.data.attributes.alternativeText}
                  />
                </Box>
              </Box>
              {memoizedData.current && memoizedData.current.length === 0 ? (
                <Box p={10}>
                  <Typography
                    sx={{
                      fontSize: "34px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      opacity: 0.4,
                    }}
                  >
                    {no_usage}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: { xs: "100%", md: "100%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_4)",
                    }}
                  >
                    {country}
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_4)",
                    }}
                  >
                    {usage}
                  </Typography>
                </Box>
              )}
              {memoizedData.current?.map((data: any, index: number) => (
                <Box
                  key={index}
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    width: { xs: "100%", md: "100%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      color: "var(--text_color)",
                    }}
                  >
                    {data.countryName}
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      color: "var(--text_color)",
                    }}
                  >
                    {data.voiceUsage}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </WalletCard>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
    </>
  );
};

export default T2_UsageHistory;
