import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import styles from "./S3_WalletCard.module.scss";
import { useAppSelector } from "../../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { formatPhoneNumber } from "../../../../../utils/commonFunctions";

const WalletCard = (props: any) => {
  const { lineDataForWallet: data } = props;

  const { getILDCreditsData } = useAppSelector(
    (state: any) => state?.services || {}
  );

  const {
    user_list_container,
    is_primary,
    user_name,
    user_num,
    plan_active,
    view_history_cta,
    sim_type,
  } = styles;

  const { isPrimary, wallet_bal, esim_txt, sim_txt } = props.content.wh_data;

  const fullName =
    (data?.firstName || "") + (data?.lastName ? ` ${data?.lastName} ` : "");

  return (
    <Box className={user_list_container}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          padding: "15px 25px",
        }}
        data-testid="wallet_card_wrapper"
      >
        <Box>
          <Typography className={user_name} pb={1}>
            <Box>
              <Box
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Regular)",
                  fontWeight: "var(--font_weight_0)",
                }}
                data-testid="wallet_card_fname"
              >
                {fullName?.toLowerCase()}
              </Box>
              <Box component="span" className={is_primary} data-testid="wallet_card_is_p">
                {data?.isPrimary ? `${isPrimary}` : ""}
              </Box>
            </Box>
          </Typography>
          <Typography className={user_num} data-testid="wallet_card_user_num">
            {data && data.reachNumber ? (
              <Box
                component="span"
                sx={{
                  color: "var(--text_color_18)",
                  fontWeight: "var(--font_weight_1)",
                }}
                data-testid="wallet_card_reach_numb"
              >
                {formatPhoneNumber(data.reachNumber)}
              </Box>
            ) : data && data.mnpInfo?.oldNumber ? (
              <Box
                component="span"
                sx={{
                  color: "var(--text_color_18)",
                  fontWeight: "var(--font_weight_1)",
                }}
                data-testid="wallet_card_old_num"
              >
                {formatPhoneNumber(data.mnpInfo?.oldNumber)}
              </Box>
            ) : null}
            <Box component="span" className={sim_type} data-testid="wallet_card_sim_type">
              {data.esim ? esim_txt : sim_txt}
            </Box>
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "var(--font_weight_0)",
              letterSpacing: "0px",
              lineHeight: "30px",
              paddingTop: { xs: "20px", sm: "0px" },
            }}
          >
            <Box
              component="span"
              sx={{ fontFamily: "var(--font_family_Regular)" }}
              data-testid="wallet_card_wall_bal"
            >
              {wallet_bal}:
            </Box>
            <Box
              component="span"
              sx={{
                color: "var(--primary_color)",
              }}
              data-testid="wallet_card_amount_bal"
            >
              {GetCurrencyFixedToTwo(
                (getILDCreditsData &&
                  getILDCreditsData[data.id]?.purchaseCredit -
                    getILDCreditsData[data.id]?.availableCredit) ||
                  0
              )}
              /
              {GetCurrencyFixedToTwo(
                (getILDCreditsData &&
                  getILDCreditsData[data.id]?.purchaseCredit) ||
                  0
              )}
            </Box>{" "}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box px={3} pb={2} sx={{ textAlign: "center" }} data-testid="wallet_card_children_wrapper">
        {props.children}
      </Box>
    </Box>
  );
};

export default WalletCard;
