import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCustomerPlan } from "../../../features/billing/billingSlice";
import _ from "lodash";
// import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  getLastCharacter,
  modelMapper,
} from "../../../utils/commonFunctions/BrandNameUpdate";
import firebase from "../../../adapters/firebase";
import {
  checkCompatibility,
  getInTouchApi,
} from "../../../features/homepage/homepageSlice";
import { useNavigate } from "react-router-dom";
import { addMultipleCacheData, getCache, gtmTagManager } from "../../../utils/commonFunctions";
import {
  shopWareRedirectionHandler,
  updateCartPayload,
} from "../../../utils/commonFunctions/shopWareFunctions";
import { EsimIMEIModalContent } from "../../../features/strapi/homePageSlice";
import { SimTypes } from "../../../features/checkout/Types";

import { RoutingURLs } from "../../../config/RoutingURLs";
import FirebaseDBURLs from "../../../config/FirebaseDBURLs";
import { CarrierTypes } from "../../../enums";
import {
  addCartItems,
  updateCartItems,
} from "../../../features/shopWare/shopWareSlice";
import { gaCategory, gaScreenNames } from "../../../Types/GoogleAnalytics";

const EsimIMEIComponent = isTemplateExisits(Config.Home.EsimImeiCheck)
  ? require(`./${Config.Home.EsimImeiCheck.template}_EsimIMEI`).default
  : null;

export type EsimIMEIProps = {
  showCheckIMEIModal: boolean;
  hideCheckIMEIModal: any;
  fromAddLine?: boolean;
  numOfNewLines?: number;
  showReviewModal?: any;
  simPreference?: any;
  changeSimSelectionHandler?: any;
  fromCheckoutPage?: boolean;
  fromHomePage?: boolean;
  increaseLine?: boolean;
  esimClickedIndex?: any;
  showStep2?: any;
  isAccountScreen?: boolean;
  changePreferenceHandler?: any;
  increaseHandle?: any;
  taxCalculationType2?: any;
  sameArr?: any;
  setShowReviewModal1?: any;
  setEsimTaxCall?: any;
  loader?: boolean;
  compatibility?: any;
  setErrorInCompat?: any;
  setRetry?: any;
  setIsCompatible?: any;
  setImeiNumber?: any;
  setInputDisabled?: any;
  modelCompat?: any;
  setModalCompat?: any;
  setShowLoader?: any;
  fromPostAct?: any;
  handleAddLine?: any;
  isEsimAvailabe?: any;
  osdata?: any;
  closeImeiModal?: any;
  displayImeiModal?: boolean;
  closeCompImeiModal?: any;
};
export interface EsimIMEIComponentProps extends EsimIMEIProps {
  closeCompatModal: any;
  handleEnter: any;
  isCompatible: boolean;
  eSimCompatiblity: boolean;
  retry: boolean;
  checkHandler: any;
  imeiNumbers: any;
  imeiHandler: any;
  checkClick: boolean;
  currentFieldIndex: number;
  isImeiUsed: boolean;
  inputDisabledIndex: any;
  imeiData: any;
  errorInCompat: boolean;
  multipleImeiCheck: any;
  imeiNumber: string;
  onChangeHandler: any;
  imeiErr: string;
  inputDisabled: boolean;
  haveTroubleHandler: any;
  moveToCheckoutPage: any;
  setDefaultSimPreference: any;
  closeTroubleHandler: any;
  target: any;
  haveTrouble: any;
  content: any;
  imeiCheckErrorMsg: any;
  isEsimAvailabe?: any;
  osdata?: any;
  closeImeiModal?: any;
  displayImeiModal?: boolean;
  closeCompImeiModal?: any;
  simType?: any;
  setSimType?: any;
}
const EsimIMEI = (props: EsimIMEIProps) => {
  const {
    hideCheckIMEIModal,
    fromAddLine,
    increaseLine,
    numOfNewLines,
    fromCheckoutPage,
    showReviewModal,
    fromHomePage,
    esimClickedIndex,
    showStep2,
    isAccountScreen,
    sameArr,
    setShowLoader,
    fromPostAct,
    handleAddLine,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [imeiNumbers, setImeiNumbers] = useState<any>([]);
  const [imeiNumber, setimeiNumber] = useState("");
  const [imeiErr, setimeiErr] = useState("");
  const [checkClick, setCheckClick] = useState(false);
  const [isCompatible, setisCompatible] = useState(false);
  const [retry, setRetry] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [eSimCompatiblity, setESimCompatiblity] = useState(false);
  const [isEsimAvailabe, setIsEsimAvailabe] = useState(true);
  const [displayImeiModal, setDisplayImeiModal] = useState(true);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [inputDisabledIndex, setInputDisabledIndex] = useState<number[]>([0]);
  const [imeiData, setImeiData] = useState<any>([]);
  const [osdata, setOsData] = useState<any>([]);
  const [currentIMEI, setCurrentIMEI] = useState<any>("");
  const [currentIndex, setCurrentIndex] = useState<any>("");
  const [isImeiUsed, setIsImeiUsed] = useState(false);
  const [haveTrouble, setHaveTrouble] = useState(false);
  const [target, setTarget] = useState(null);
  const [user, setUser] = useState<any>(null);
  const [plan, setPlan] = useState<any>(null);
  const [line, setLine] = useState<any>(null);
  const [increaseLineIMEI, setIncreaseLineIMEI] = useState<any>("");
  const [increaseLineESIM, setIncreaseLineESIM] = useState(false);
  const [increaseLineMake, setIncreaseLineMake] = useState<any>("");
  const [increaseLineModel, setIncreaseLineModel] = useState<any>("");
  const [errorInCompat, setErrorInCompat] = useState(false);
  const [modelCompat, setModalCompat] = useState(true);
  const [imeiCheckErrorMsg, setImeilCheckErrorMsg] = useState("");
  const [simType, setSimType] = useState(SimTypes.sim);
  const { customerPlanDetails } = useAppSelector((state: any) => state.billing);
  const [cacheEsimIMEI, setCacheEsimIMEI] = useState<any>(null);
  const { compatibility } = useAppSelector((state: any) => state.homepage);
  const { subDomainData, errorSubDomainData } = useAppSelector((state: any) => state.homepage);
  const { esimIMEIContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  useEffect(() => {
    let arr: any = [];
    let simDataPref: any = [];
    let reachSelectedPlanLine: any = localStorage.getItem(
      "reachSelectedPlanLine"
    );
    reachSelectedPlanLine = JSON.parse(reachSelectedPlanLine);
    props?.simPreference?.map((line: any) => {
      if (line.eSimOpted && !line.eSimCompatible) {
        arr.push({ imeiNum: "", imeiNumErr: "" });
      }
    });
    if (fromHomePage) {
      new Array(reachSelectedPlanLine).fill(0).forEach((_, index: number) =>
        simDataPref.push({
          id: index,
          eSimOpted: false,
          eSimCompatible: false,
          imei: "",
          value: "Sim",
          make: "",
          model: "",
          isPrimaryLine: index === 0 ? true : false,
        })
      );
      localStorage.setItem("simPreference", JSON.stringify(simDataPref));
    }
    if (fromAddLine || increaseLine) {
      new Array(numOfNewLines)
        .fill(0)
        .forEach(() => arr.push({ imeiNum: "", imeiNumErr: "" }));
    }
    if (fromAddLine && !increaseLine) {
      new Array(numOfNewLines).fill(0).forEach((_, index: number) =>
        simDataPref.push({
          id: index,
          eSimOpted: false,
          eSimCompatible: false,
          imei: "",
          value: "Sim",
          make: "",
          model: "",
          isPrimaryLine: index === 0 ? true : false,
        })
      );
      localStorage.setItem("simPreference", JSON.stringify(simDataPref));
    }
    setImeiNumbers([...imeiNumbers, ...arr]);
    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    if (user1) {
      setUser(user1);
    }
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    let line: any = localStorage.getItem("reachSelectedPlanLine");
    line = JSON.parse(line);
    planDetails = planDetails ? JSON.parse(planDetails) : null;
    if (planDetails) {
      setPlan(planDetails.plan);
    }
    if (line) {
      setLine(parseInt(line));
    }
    let currentUser: any = localStorage.getItem("customerDetail");
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      dispatch(getCustomerPlan({ customerId: currentUser?.id }));
    }
    getCache("EsimIMEIModal", EsimIMEIModalContent, setCacheEsimIMEI, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("EsimIMEIModal", esimIMEIContent?.attributes);
  }, [esimIMEIContent]);

  useNonInitialEffect(() => {
    if (
      customerPlanDetails &&
      customerPlanDetails.status === "SUCCESS" &&
      customerPlanDetails.data
    ) {
      let currentUser: any = localStorage.getItem("customerDetail");
      currentUser = JSON.parse(currentUser);
      if (fromAddLine) {
        let additionalLines: any =
          imeiNumbers.length + currentUser.additionalLines;
        let amount: any =
          additionalLines * customerPlanDetails?.data?.additionalLinePrice +
          customerPlanDetails?.data?.baseLinePrice;
        props.taxCalculationType2("billing", amount, additionalLines);
      }
    }
  }, [customerPlanDetails]);

  useNonInitialEffect(() => {
    if (
      compatibility?.data?.status === "SUCCESS" &&
      compatibility?.data?.data
    ) {
      setCheckClick(false);
      let modelN: string = getLastCharacter(
        compatibility?.data?.data?.model,
        5
      );
      if (
        EnvConfig.IS_IMEI_DEVICE_MODEL_NEG_CHECK_ENABLED &&
        modelN &&
        modelMapper[modelN]
      ) {
        setModalCompat(false);
        return;
      } else {
        setModalCompat(true);
        if (compatibility?.data?.data?.isValid) {
          setErrorInCompat(false);
        } else {
          dispatch(
            getInTouchApi({
              data: {
                emailId: user?.email,
                hubspotMap: {
                  email: user?.email,
                  is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
                  is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
                  website_imei_incompatibility_reason: "invalid",
                  brand_name: subDomainData?.data?.brandName,
                },
              },
            })
          );
          setErrorInCompat(true);
        }
        setRetry(true);
        let simPreference: any = localStorage.getItem("simPreference");
        simPreference = JSON.parse(simPreference) || [];
        if (
          !fromHomePage &&
          !isAccountScreen &&
          !increaseLine &&
          esimClickedIndex &&
          fromAddLine
        ) {
          let sameArrIndex: any = Object.keys(sameArr)[currentIndex];
          simPreference[sameArrIndex].eSimOpted = false;
          simPreference[sameArrIndex].eSimCompatible = compatibility?.data?.data
            ?.esimAvailable
            ? true
            : false;
          simPreference[sameArrIndex].imei = currentIMEI && currentIMEI;
          simPreference[sameArrIndex].make = compatibility?.data?.data?.make;
          simPreference[sameArrIndex].model = compatibility?.data?.data?.model;
          simPreference[sameArrIndex].value = "Sim";
          simPreference[sameArrIndex].isPrimaryLine =
            simPreference[sameArrIndex]?.id === 0 ? true : false;
          localStorage.setItem("simPreference", JSON.stringify(simPreference));
        } else if (
          !fromHomePage &&
          isAccountScreen &&
          !increaseLine &&
          !fromAddLine
        ) {
          let simData = {
            id: 0,
            eSimOpted: compatibility?.data?.data?.esimAvailable ? true : false,
            eSimCompatible: compatibility?.data?.data?.esimAvailable
              ? true
              : false,
            imei: currentIMEI && currentIMEI,
            make: compatibility?.data?.data?.make,
            model: compatibility?.data?.data?.model,
            value: "Sim",
            isPrimaryLine: false,
          };

          if (simPreference === null) {
            simPreference = [];

            simPreference.push({
              ...simData,
            });
          } else {
            simPreference.push({
              ...simData,
              id: simPreference.length,
            });
          }
          localStorage.setItem("simPreference", JSON.stringify(simPreference));
        } else if (increaseLine) {
          setIncreaseLineIMEI(currentIMEI);
          setIncreaseLineMake(compatibility?.data?.data?.make);
          setIncreaseLineModel(compatibility?.data?.data?.model);
          if (compatibility?.data?.data?.esimAvailable) {
            setIncreaseLineESIM(true);
          }
        } else {
          let simPreferenceStorage: any = localStorage.getItem("simPreference");
          simPreferenceStorage = JSON.parse(simPreferenceStorage);
          if (sameArr && simPreferenceStorage) {
            let sameArrIndex: any = Object.keys(sameArr)[currentIndex];
            if (fromCheckoutPage) {
              sameArrIndex = simPreferenceStorage.findIndex(
                (simPref: any) => simPref?.eSimOpted && !simPref?.eSimCompatible
              );
            }
            // To do verify sameArr, currentIndex from checkout
            simPreferenceStorage[sameArrIndex].eSimOpted = compatibility?.data
              ?.data?.esimAvailable
              ? true
              : false;
            simPreferenceStorage[sameArrIndex].eSimCompatible = compatibility
              ?.data?.data?.esimAvailable
              ? true
              : false;
            simPreferenceStorage[sameArrIndex].imei = currentIMEI;
            simPreferenceStorage[sameArrIndex].make =
              compatibility?.data?.data?.make;
            simPreferenceStorage[sameArrIndex].model =
              compatibility?.data?.data?.model;
            simPreferenceStorage[sameArrIndex].value = "Sim";
            simPreferenceStorage[sameArrIndex].isPrimaryLine =
              simPreferenceStorage[sameArrIndex]?.id === 0 ? true : false;
            localStorage.setItem(
              "simPreference",
              JSON.stringify(simPreferenceStorage)
            );
          } else if (!sameArr && simPreferenceStorage && fromAddLine) {
            simPreferenceStorage[currentIndex].eSimOpted = compatibility?.data
              ?.data?.esimAvailable
              ? true
              : false;
            simPreferenceStorage[currentIndex].eSimCompatible = compatibility
              ?.data?.data?.esimAvailable
              ? true
              : false;
            simPreferenceStorage[currentIndex].imei = currentIMEI;
            simPreferenceStorage[currentIndex].make =
              compatibility?.data?.data?.make;
            simPreferenceStorage[currentIndex].model =
              compatibility?.data?.data?.model;
            simPreferenceStorage[currentIndex].value = SimTypes.sim;
            simPreferenceStorage[currentIndex].isPrimaryLine =
              simPreferenceStorage[currentIndex]?.id === 0 ? true : false;
            localStorage.setItem(
              "simPreference",
              JSON.stringify(simPreferenceStorage)
            );
          } else {
            let updatedPrimarySIMPrefenceData = {
              id: 0,
              eSimOpted: false,
              eSimCompatible: compatibility?.data?.data.esimAvailable
                ? true
                : false,
              imei: currentIMEI,
              make: compatibility?.data?.data?.make,
              model: compatibility?.data?.data?.model,
              value: "Sim",
              isPrimaryLine: true,
            };
            simPreference[0] = updatedPrimarySIMPrefenceData;
            localStorage.setItem(
              "simPreference",
              JSON.stringify(simPreference)
            );
          }
        }

        if (inputDisabledIndex.length < imeiNumbers?.length) {
          setInputDisabledIndex([
            ...inputDisabledIndex,
            inputDisabledIndex[inputDisabledIndex.length - 1] + 1,
          ]);
        }
        [imeiData].forEach((d: any) => {
          if (d[currentFieldIndex]) {
            setImeiData((prevState: any) =>
              prevState.map((data: any) => {
                if (data?.id === currentFieldIndex) {
                  return {
                    ...data,
                    isValid: compatibility?.data?.data.isValid,
                    esimAvailable: compatibility?.data?.data.esimAvailable,
                    imei: imeiNumber,
                  };
                }
                return data;
              })
            );
          } else {
            setImeiData([
              ...imeiData,
              {
                id: currentFieldIndex,
                isValid: compatibility?.data?.data.isValid,
                esimAvailable: compatibility?.data?.data.esimAvailable,
                imei: imeiNumber,
              },
            ]);
          }
        });
        if (compatibility?.data?.data?.isValid) {
          setESimCompatiblity(compatibility?.data?.data?.esimAvailable);

          setisCompatible(true);
          setInputDisabled(true);
        }
        if (currentIndex === 0) {
          localStorage.setItem(
            "simSelection",
            JSON.stringify(
              compatibility?.data?.data?.esimAvailable ? true : false
            )
          );
        }
        setCurrentFieldIndex((prevState) => prevState + 1);
      }
    }
  }, [compatibility]);
  const closeImeiModal = () => {
    setDisplayImeiModal(false);
  };
  const closeCompImeiModal = () => {
    hideCheckIMEIModal(false);
  };
  const closeCompatModal = () => {
    hideCheckIMEIModal(false);
    setCheckClick(false);
    setimeiErr("");
    setimeiNumber("");
    setDefaultSimPreference();
    if (!fromAddLine) {
      props.changePreferenceHandler();
    } else {
      localStorage.removeItem("simPreference");
    }
  };
  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      checkHandler();
    }
  };
  const imeiHandler = (e: any, i: any) => {
    setErrorInCompat(false);
    setRetry(false);
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    let newArr: any = [];
    if (customerDetail?.linePreferences) {
      Object.values(customerDetail.linePreferences).map((el: any) => {
        if (el.imei) {
          newArr.push(el.imei);
        }
      });
    }
    let simPreference: any = localStorage.getItem("simPreference");
    if (simPreference) {
      simPreference = JSON.parse(simPreference);
      Object.values(simPreference).map((el: any) => {
        if (el.imei) {
          newArr.push(el.imei);
        }
      });
    }
    const checkDuplicateIMEI = newArr?.find((el: any) => {
      return el === e.target.value;
    });
    setIsImeiUsed(checkDuplicateIMEI ? true : false);
    setimeiNumber(e.target.value);
    let imeiNumber = [...imeiNumbers];
    imeiNumber[i][e.target.name] = e.target.value;
    setImeiNumbers(imeiNumber);
  };
  const onChangeHandler = (e: any) => {
    setRetry(false);
    let simPreference: any = localStorage.getItem("simPreference");
    simPreference = JSON.parse(simPreference);
    const checkDuplicateIMEI = simPreference?.find(
      (el: any) => el.imei === e.target.value && el.imei !== ""
    );
    setIsImeiUsed(checkDuplicateIMEI ? true : false);
    setimeiNumber(e.target.value);
    setimeiErr("");
  };

  const multipleImeiCheck = async (index: any) => {
    setCurrentIndex(index);
    setCurrentIMEI(imeiNumbers[index].imeiNum);
    if (
      imeiNumbers[index].imeiNum &&
      imeiErr === "" &&
      imeiNumbers[index].imeiNum.length === 15 &&
      /^\d+$/.test(imeiNumbers[index].imeiNum)
    ) {
      setCurrentFieldIndex(index);
      setCheckClick(true);
      dispatch(
        checkCompatibility({
          imeiNumber: imeiNumbers[index].imeiNum,
          // countryCode: 'usa',
        })
      )
        .then((resp) => {
          if (resp?.payload?.status !== 200) {
            setCheckClick(false);
            setImeilCheckErrorMsg(esimIMEIContent?.attributes?.bcked_fail_msg);
          }
        })
        .catch(() => {
          setCheckClick(false);
          setImeilCheckErrorMsg(esimIMEIContent?.attributes?.bcked_fail_msg);
        });
    } else {
      if (imeiNumbers[index].imeiNum === "") {
        let imeiNumError: any = [...imeiNumbers];
        imeiNumError[index] = esimIMEIContent?.attributes?.imei_err_3;
        setImeiNumbers(imeiNumbers);
      } else if (
        imeiNumbers[index].imeiNum !== "" &&
        imeiNumbers[index].imeiNum.length !== 15
      ) {
        let imeiNumError: any = [...imeiNumbers];
        imeiNumError[index] = esimIMEIContent?.attributes?.imei_err_2;
        setImeiNumbers(imeiNumbers);
      } else if (
        imeiNumbers[index].imeiNum !== "" &&
        !/^\d+$/.test(imeiNumbers[index].imeiNum)
      ) {
        let imeiNumError: any = [...imeiNumbers];
        imeiNumError[index] = esimIMEIContent?.attributes?.imei_err_1;
        setImeiNumbers(imeiNumbers);
      }
    }
  };

  const checkHandler = async () => {
    let planDetails: any = localStorage.getItem(
      "reachSelectedPlanDetail"
    );
    planDetails = JSON.parse(planDetails);
    gtmTagManager({
      event: gaScreenNames.imeiCompatibilityCheck,
      number_of_lines_selected: planDetails?.line, 
      plan_name: planDetails?.plan?.displayName,
       Plan_cost: planDetails?.plan?.baseLinePrice
    });
    if (
      imeiNumber &&
      imeiErr === "" &&
      imeiNumber.length === 15 &&
      /^\d+$/.test(imeiNumber)
    ) {
      setCurrentIndex(0);
      setCurrentIMEI(imeiNumber);
      await setCheckClick(true);
      dispatch(checkCompatibility({ imeiNumber: imeiNumber }))
        .then((resp) => {
          setIsEsimAvailabe(resp?.payload?.data?.data?.esimAvailable);
          setOsData(resp?.payload?.data?.data);
        })
        .catch((err) => {
          if (err) {
            setCheckClick(false);
          }
        });
    } else {
      if (imeiNumber === "") {
        setimeiErr(esimIMEIContent?.attributes?.imei_err_3);
      } else if (imeiNumber !== "" && imeiNumber.length !== 15) {
        setimeiErr(esimIMEIContent?.attributes?.imei_err_2);
      } else if (imeiNumber !== "" && !/^\d+$/.test(imeiNumber)) {
        setimeiErr(esimIMEIContent?.attributes?.imei_err_1);
      }
    }
  };
  const moveToCheckoutPage = (simPreference?: string) => {
    localStorage.setItem(
      "simSelection",
      JSON.stringify({
        esim: eSimCompatiblity,
      })
    );
    if (fromPostAct) {
      let simPref: any = localStorage.getItem("simPreference");
      simPref = JSON.parse(simPref);
      let newLine: any = {
        nickName: "",
        isPrimary: false,
        eSimOpted: false,
        eSimCompatible: false,
        imei: "",
        make: "",
        model: "",
        value: SimTypes.sim,
        simPreference: SimTypes.sim,
      };
      if (simPreference === "skip") {
        gtmTagManager({
          event: gaScreenNames.imeicheck,
        });
        handleAddLine("", newLine);
        setDefaultSimPreference();
      } else if (
        eSimCompatiblity ||
        EnvConfig.OPERATOR.toLowerCase() === CarrierTypes.ATT
      ) {
        newLine["simPreference"] = simType;
        newLine["eSimOpted"] = SimTypes?.eSim === simType ? true : false;
        newLine["eSimCompatible"] = simPref[0]?.eSimCompatible;
        newLine["value"] = simPref[0]?.value;
        newLine["imei"] = simPref[0]?.imei;
        newLine["make"] = simPref[0]?.make;
        newLine["model"] = simPref[0]?.model;
        handleAddLine("", newLine);
      }
      hideCheckIMEIModal(false);
    } else {
      if (fromAddLine) {
        let simPreference: any = localStorage.getItem("simPreference");
        simPreference = JSON.parse(simPreference);
        if (
          eSimCompatiblity ||
          (EnvConfig.OPERATOR.toLowerCase() === CarrierTypes.ATT &&
            simPreference[0]?.value === SimTypes.eSim)
        ) {
          showReviewModal();
        } else {
          let simPreference: any = localStorage.getItem("simPreference");
          simPreference = JSON.parse(simPreference);
          if (_.isEmpty(simPreference)) {
            simPreference = [];
            imeiNumbers.map(() => {
              simPreference.push({
                id: 0,
                eSimOpted: false,
                eSimCompatible: false,
                imei: "",
                make: "",
                model: "",
                value: "Sim",
                isPrimaryLine: false,
              });
            });
            localStorage.setItem(
              "simPreference",
              JSON.stringify(simPreference)
            );
          }
          showStep2();
        }
        if (!fromAddLine) hideCheckIMEIModal(false);
      } else {
        if (fromCheckoutPage) {
          setDefaultSimPreference();
          props.changeSimSelectionHandler();
        }
        if (simPreference === "SIM") {
          let simPreference: any = localStorage.getItem("simPreference");
          simPreference = JSON.parse(simPreference);
          if (simPreference) {
            simPreference[0].eSimOpted = false;
            simPreference[0].eSimCompatible = false;
            simPreference[0].value = "Sim";
            simPreference[0].imei = "";
            simPreference[0].make = "";
            simPreference[0].model = "";
            localStorage.setItem(
              "simPreference",
              JSON.stringify(simPreference)
            );
          }
        }
        if (!fromCheckoutPage) {
          if (EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
            setShowLoader(true);
            let planDetails: any = localStorage.getItem(
              "reachSelectedPlanDetail"
            );
            planDetails = JSON.parse(planDetails);
            let simPreferencePayload: any =
              localStorage.getItem("simPreference");
            simPreferencePayload = JSON.parse(simPreferencePayload);
            const linePreferenceData: any = {};
            simPreferencePayload &&
              simPreferencePayload.map((data: any, index: any) => {
                linePreferenceData["line" + (index + 1)] = {
                  eSimCompatible: data?.eSimCompatible,
                  value: data?.value,
                  eSimOpted: data?.value === SimTypes?.sim ? false : true,
                  isPrimaryLine: data?.isPrimaryLine,
                  imei: data?.imei?.toString(),
                  simPreference:
                    data?.value === "eSim" ? "ESIM" : "PHYSICAL_SIM",
                  make: data?.make,
                  model: data?.model,
                };
              });
            dispatch(
              addCartItems({
                data: {
                  planName: planDetails?.plan?.displayName,
                  reachPlanId: planDetails?.plan?.name,
                  quantity: parseInt(line),
                  price: planDetails?.plan?.baseLinePrice,
                  discounts: [
                    {
                      name: "",
                      percentage: "",
                      applicability: "",
                      eligibilityCriteria: [],
                      endPointToVerifyDiscount: "",
                      eligibilityStatus: "",
                    },
                  ],
                  linePreferences: linePreferenceData,
                  shippingOption: "usps_first_class_mail",
                },
              })
            )
              .then((resp) => {
                if (resp?.payload?.status === 200) {
                  setShowLoader(false);
                  const addCartResponse = resp?.payload?.data?.data;
                  let lineItemsInCart: any = addCartResponse.lineItems;
                  let allProductName =
                    lineItemsInCart &&
                    lineItemsInCart.map(
                      (data: any) => data?.payload?.productNumber
                    );
                  let productNumberWithData = allProductName?.filter(
                    (data: any) => data.includes("DATA")
                  );
                  if (
                    allProductName?.length === productNumberWithData?.length
                  ) {
                    navigate(RoutingURLs.checkout);
                  } else {
                    shopWareRedirectionHandler(EnvConfig?.SHOP_DOMAIN, "cart");
                  }
                }
              })
              .catch(() => {
                setShowLoader(false);
                navigate(RoutingURLs.checkout);
              });
          } else navigate(RoutingURLs.checkout);
        }
        hideCheckIMEIModal(false);
      }
    }
    gtmTagManager({
      event: gaScreenNames.checkout,
    });
  };

  //Set Default Sim Preference if skip checkout clicked
  const setDefaultSimPreference = () => {
    let simPreference: any = localStorage.getItem("simPreference");
    simPreference = JSON.parse(simPreference);
    if (_.isEmpty(simPreference)) {
      simPreference = [];
      simPreference.push({
        id: 0,
        eSimOpted: false,
        eSimCompatible: false,
        imei: "",
        make: "",
        model: "",
        value: "Sim",
        isPrimaryLine: true,
      });
      localStorage.setItem("simPreference", JSON.stringify(simPreference));
    } else {
      simPreference.map((data: any, idx: number) => {
        simPreference[idx].eSimOpted =
          data?.value === SimTypes?.eSim ? true : false;
        simPreference[idx].eSimCompatible = data?.eSimCompatible ? true : false;
        simPreference[idx].imei = data?.imei || "";
        simPreference[idx].make = data?.make || "";
        simPreference[idx].model = data?.model || "";
        simPreference[idx].value = "Sim";
        simPreference[idx].isPrimaryLine = data?.isPrimaryLine;
      });
      localStorage.setItem("simPreference", JSON.stringify(simPreference));
    }
  };
  const haveTroubleHandler = async (e: any) => {
    setHaveTrouble(true);
    setTarget(e.target);
    // pushTrackingEventsToGAAndFB({
    //   //   category: "Check Compatibility",
    //   //   actionType: "Having Trouble",
    //   // });
  };
  const closeTroubleHandler = () => {
    setTarget(null);
    setHaveTrouble(false);
  };
  const updateUserInFirebaseDatabase = async (type: string, value: any) => {
    await firebase
      .database()
      .ref(FirebaseDBURLs.userWebJourney + btoa(user?.email))
      .once("value")
      .then((snapshot: any) => {
        let userData = snapshot.val();
        userData[type] = value;
        if (type === "selectedPlan") {
          userData["extraLines"] = value && value.line - 1;
          userData["reachPlanId"] = value && value.plan && value.plan.name;
        }
        firebase
          .database()
          .ref(FirebaseDBURLs.userWebJourney)
          .child(btoa(user?.email))
          .update(userData);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const increaseHandle = () => {
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = planDetails ? JSON.parse(planDetails) : null;
    const numberOfLines = parseInt(planDetails?.line);
    const updatedNumberOfLines = numberOfLines + 1;
    if (planDetails) {
      setPlan(planDetails.plan);
    }
    /**
     * In checkout page, Without signed number of lines get changes this firebase will through error.
     * To handle this issue plan and line details are getting updated during customer creation call.
     */
    if (!fromCheckoutPage) {
      updateUserInFirebaseDatabase("selectedPlan", {
        line: updatedNumberOfLines,
        plan: planDetails && planDetails.plan,
      });
    }
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Add Lines",
    //   label: line + 1,
    // });
    localStorage.setItem(
      "reachSelectedPlanLine",
      JSON.stringify(updatedNumberOfLines)
    );
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    if (simPreferenceStorage) {
      simPreferenceStorage = JSON.parse(simPreferenceStorage);
      simPreferenceStorage.push({
        id: simPreferenceStorage.length,
        eSimOpted: increaseLineESIM ? true : false,
        eSimCompatible: increaseLineESIM ? true : false,
        value: increaseLineESIM ? SimTypes.eSim : SimTypes.sim,
        imei: increaseLineIMEI && increaseLineIMEI,
        make: increaseLineMake && increaseLineMake,
        model: increaseLineModel && increaseLineModel,
        isPrimaryLine: false,
      });
      localStorage.setItem(
        "simPreference",
        JSON.stringify(simPreferenceStorage)
      );
    }
    if (fromCheckoutPage) {
      if (EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
        let payload = updateCartPayload(simPreferenceStorage);
        props.increaseHandle(updatedNumberOfLines);
        dispatch(updateCartItems(payload));
      } else props.increaseHandle(updatedNumberOfLines);
    }
    setLine(updatedNumberOfLines);
    closeCompatModal();
  };

  return (
    <EsimIMEIComponent
      {...props}
      closeCompatModal={closeCompatModal}
      handleEnter={handleEnter}
      isCompatible={isCompatible}
      setIsCompatible={setisCompatible}
      eSimCompatiblity={eSimCompatiblity}
      retry={retry}
      setRetry={setRetry}
      checkHandler={checkHandler}
      imeiNumbers={imeiNumbers}
      imeiHandler={imeiHandler}
      checkClick={checkClick}
      currentFieldIndex={currentFieldIndex}
      isImeiUsed={isImeiUsed}
      inputDisabledIndex={inputDisabledIndex}
      imeiData={imeiData}
      errorInCompat={errorInCompat}
      setErrorInCompat={setErrorInCompat}
      multipleImeiCheck={multipleImeiCheck}
      imeiNumber={imeiNumber}
      setImeiNumber={setimeiNumber}
      onChangeHandler={onChangeHandler}
      imeiErr={imeiErr}
      inputDisabled={inputDisabled}
      setInputDisabled={setInputDisabled}
      haveTroubleHandler={haveTroubleHandler}
      increaseHandle={increaseHandle}
      moveToCheckoutPage={moveToCheckoutPage}
      setShowReviewModal1={props.setShowReviewModal1}
      closeTroubleHandler={closeTroubleHandler}
      target={target}
      haveTrouble={haveTrouble}
      imeiCheckErrorMsg={imeiCheckErrorMsg}
      content={cacheEsimIMEI ? cacheEsimIMEI : esimIMEIContent?.attributes}
      setDefaultSimPreference={setDefaultSimPreference}
      compatibility={compatibility}
      modelCompat={modelCompat}
      setModalCompat={setModalCompat}
      isEsimAvailabe={isEsimAvailabe}
      osdata={osdata}
      closeImeiModal={closeImeiModal}
      displayImeiModal={displayImeiModal}
      closeCompImeiModal={closeCompImeiModal}
      simType={simType}
      setSimType={setSimType}
    />
  );
};

export default EsimIMEI;
