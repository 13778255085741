import { Box, Typography } from "@mui/material";
import {
  ButtonCust,
  SearchBoxWithDropdown,
  TextFieldCust,
} from "../../../widgets";
import Config from "../../../../config/env.config.json";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S3_CountryList.module.scss";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import _ from "lodash";
import {
  capitalize,
  getResolutionDetails,
} from "../../../../utils/commonFunctions";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { useAppSelector } from "../../../../app/hooks";
import CountryDataModal from "../../../internationalservices/CountryDataModal";
import { useEffect, useState } from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const CountryList = (props: any) => {
  const {
    country_list,
    user_data,
    activation_icon,
    country_icon,
    calendar_data,
    back_btn,
    back_arrow,
    no_option,
  } = props.content;

  const {
    fullName,
    setIsCountryList,
    calanderSubmitHandler,
    calanderOnChangeHandler,
    isCalanderModal,
    setIsCalanderModal,
    getCountryList,
    renderOption,
    setSelectedCountryHandler,
    continueSubmitHandler,
    selectedDate,
    selectedCountry,
    setSelectedCountry,
  } = props;
  const { mobile } = getResolutionDetails();
  const [isViewRates, setIsViewRates] = useState(false);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  let countryDataModalContent = {
    tvl_out_us: country_list?.tvl_out_us,
    pck_info: props?.content?.pck_info,
  };
  const onTextChange = (e: any) => {
    if (e.target.value === "") {
      setSelectedCountry(undefined);
    }
  };
  const countryModalHandler = () => {
    setIsViewRates(false);
  };
  useEffect(() => {
    if (selectedCountry) {
      setIsViewRates(true);
    }
  }, [selectedCountry]);

  return (
    <>
      <CustomDateCalendar
        onClick={calanderSubmitHandler}
        onChange={calanderOnChangeHandler}
        isCalanderModal={isCalanderModal}
        onCloseModal={setIsCalanderModal}
        title={calendar_data.title}
        desc={calendar_data.desc}
        button={calendar_data.btn_1}
        variant={Config.PRIMARY_BUTTON_TYPE}
        isDivider={false}
        titleStyle={{
          fontWeight: "var(--font_weight_4)!important",
          fontFamily: "var(--font_family_Bold)!important",
          color: "var(--black)!important",
          lineHeight: "36px",
          fontSize: "24px !important",
        }}
        descStyle={{
          fontSize: "16px",
          lineHeight: "26px",
          color: "var(--text_color)",
          fontFamily: "var(--font_family_Regular) !important",
        }}
      />
      <Box
        className={styles.main_container}
        m={{ xs: "auto", sm: "auto", md: "auto" }}
        width={{ xs: "100%", sm: "auto", md: "60%" }}
        data-testid="country_list_wrapper"
      >
        <Box>
          <Box
            component="span"
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              left: { xs: "20px", md: "103px" },
              top: { xs: "105px", md: "auto" },
              cursor: "pointer",
              fontWeight: "var(--font_weight_0)",
            }}
            onClick={() => setIsCountryList(false)}
            data-testid="country_list_icon"
          >
            {!mobile ? (
              <ArrowBackIosIcon sx={{ width: "18px", fontSize: "1.125rem" }} data-testid="country_list_back_icon"/>
            ) : (
              <Box
                component="img"
                src={back_arrow?.data.attributes.url}
                alt={back_arrow?.data.attributes.alternativeText}
                data-testid="country_list_back_icon"
              />
            )}
            {!mobile && (
              <Box
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1)",
                  fontSize: "18px",
                  lineHeight: "30px",
                }}
                data-testid="country_list_back_text"
              >
                {user_data?.back}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: { xs: "-60px", sm: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "20px", sm: 0 },
              }}
            >
              <Typography className={styles.heading} data-testid="country_list_title">
                {country_list.title}
              </Typography>
              <Typography
                sx={{
                  backgroundColor: "var(--light_primary_color)",
                  color: "var(--primary_color)",
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_2)",
                  p: "5px",
                  ml: "10px",
                  border: "1px solid var(--primary_color)",
                }}
                data-testid="country_list_fname"
              >
                {fullName}{" "}
              </Typography>
            </Box>
            <Typography className={styles.desc} data-testid="country_list_desc">{country_list.desc}</Typography>
            {/* <Typography className={styles.full_name}>
              {country_list?.name}
              <Box
                component="span"
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {" "}
                {fullName.toLowerCase()}
              </Box>
            </Typography> */}
          </Box>
        </Box>

        <Box className={styles.search_box} data-testid="country_list_search_box">
          {/* <Box
            sx={{
              width: "500px",
              margin: "0 auto",
              pb: "5px",
              fontSize: "14px",
            }}
          >
            {country_list.country_label}
          </Box> */}
          <SearchBoxWithDropdown
            dropdownList={getCountryList?.map((country: any) => ({
              ...country,
              countryName: capitalize(country?.countryName),
            }))}
            optionLabel={(option: any) =>
              option.name ? option.name : option.countryName
            }
            value={selectedCountry}
            onChange={setSelectedCountryHandler}
            placeholder={country_list.country_placeholder}
            onRequest={() => null}
            onCancel={() => setSelectedCountry("")}
            logo={country_icon}
            renderOption={renderOption}
            no_option={no_option}
            onTextChange={onTextChange}
            data-testid="country_list_dropdown"
            sx={{
              "& .css-cn7qke-MuiAutocomplete-root .MuiFilledInput-root .MuiFilledInput-input":
                {
                  fontFamily: "var(--font_family_Medium) !important",
                  color: "var(--text-color) !important",
                  opacity: "0 !important",
                  padding: "7px 4px",
                  fontSize: "14px !important",
                },
              "& .MuiFilledInput-root .MuiFilledInput-input": {
                fontFamily: "var(--font_family_Medium) !important",
                color: "var(--text_color_18) !important",
                padding: "13.5px 4px",
                height: "24px !important",
                lineHeight: "24px !important",
                fontSize: "14px !important",
              },
              width: {
                xs: "90%",
                sm: "500px !important",
                md: "670px !important",
              },
            }}
            className={styles.flexCenter}
          />
        </Box>
        {!props?.viewRates ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              onClick={() => setIsCalanderModal(true)}
              sx={{
                width: { xs: "100%", sm: "auto" },
              }}
              data-testid="country_list_view_rate_wrapper"
            >
              {/* <Box
              sx={{
                pb: "5px",
                fontSize: "14px",
              }}
            >
              {country_list.calendar_label}
            </Box> */}
              <TextFieldCust
                name="calander"
                value={selectedDate}
                readOnly={true}
                placeholder={country_list.pack_placeholder}
                endAdornment={
                  // <Box
                  //   component="img"
                  //   src={activation_icon?.data?.attributes?.url}
                  //   alt={activation_icon?.data?.alternativeText}
                  // />
                  <CalendarTodayOutlinedIcon
                    data-testid="country_list_cl_icon"
                    sx={{ color: "var(--primary_color)" }}
                  />
                }
                sx={{
                  width: { sm: "500px !important", md: "670px !important" },
                  padding: "11px 0px 11px 14px",
                  ml: "-12px",
                  height: "63px",
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "15px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                className={styles.textfield_cust}
                data-testid="country_list_textfield"
              />
            </Box>
          </Box>
        ) : (
          <CountryDataModal
            isCountryModal={isViewRates}
            setIsCountryModal={countryModalHandler}
            selectedCountry={selectedCountry}
            ISContent={countryDataModalContent}
            hide_IR={true}
          />
        )}
        <Box
          sx={{ display: !props?.viewRates ? "block" : "none" }}
          textAlign={"center"}
          mt={"15px"}
        >
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            data-testid="country_list_btn"
            onClick={() => {
              continueSubmitHandler();
              gtmTagManager({
                event: globalVal?.gtmEvents?.manageServicePurchasePack,
                click_section: "account",
              });
            }}
            disabled={selectedCountry === "" || selectedDate === ""}
          >
            {country_list.button}
          </ButtonCust>
        </Box>
      </Box>
    </>
  );
};

export default CountryList;
