import { isTemplateExisits } from "../../../../../utils/commonFunctions";
import AppConfig from "../../../../../config/app.config.json";

const WalletCardComp = isTemplateExisits(
  AppConfig.PostActivationServices.WalletCard
)
  ? require(`./${AppConfig.PostActivationServices.WalletCard.template}_WalletCard`)
      .default
  : null;

const WalletCard = (props: any) => {
  return (
    <>
      <WalletCardComp {...props} />
    </>
  );
};

export default WalletCard;
