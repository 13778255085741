import { Box, ClickAwayListener, Grid, Paper, Typography } from "@mui/material";
import { ButtonCust, CustToolTip, TextFieldCust } from "../../../widgets";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S6_InitialActivation.module.scss";
import { ActivationStatus, CustomerStatus } from "../../../../enums";
import Config from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { InternalBanner } from "../../../common/InternalBanner";
import { gtmTagManager, updateSvgFillColor } from "../../../../utils";
import { gaScreenNames } from "../../../../Types/GoogleAnalytics";
import { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ImeiLookUpComponent from "./ImeiLookUpComponent";
const T6_InitialActivation = (props: any) => {
  const { handleStep, currentLine, content, updatingCustomer } = props;
  const { extraLines } = currentLine;
  const letsGoHandler = () => {
    gtmTagManager({
      event: gaScreenNames.act_sim_type,
    });
    if (currentLine?.esim) {
      updatingCustomer({
        activationJourneyStatus: ActivationStatus.IN_PROGRESS,
      });
    }
    handleStep(true);
  };
  const {
    intro_title,
    intro_title1,
    intro_desc1,
    intro_desc11,
    intro_desc12,
    intro_desc2,
    intro_desc21,
    intro_pnts,
    intro_btn,
    tick_icon,
    esim_points,
    each_icon,
    each_icon1,
    flight_icon,
    esim_points1,
    intro_pnts1,
    each_icon_svg,
  } = content || {};
  const noOfLines = extraLines + 1;
  const introPoints = currentLine?.esim ? esim_points1 : intro_pnts1;
  const disableBack =
    currentLine?.isSimInHand &&
    currentLine?.isPrimary &&
    !Config?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED;
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  let customer: any = localStorage.getItem("customerDetail");
  customer = customer ? JSON.parse(customer) : null;

  return (
    <>
      {/* <InternalBanner
        // key={key}
        pageName={"MyAccount"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <KeyboardBackspaceIcon
        onClick={() => (disableBack ? null : handleStep(false))}
        fontSize="large"
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          top: "91.5px",
          left: "20px",
          color: "#313131",
          fontSize: "1.5rem",
        }}
        data-testid="activation_step_1_wrapper"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // background: "var(--light_primary_color)",
          height: "fit-content",
          minHeight: "88vh",
          position: "relative",
        }}
        data-testid="intro_prim_wrapper"
      >
        <Box
          display={{ xs: "none", md: "block" }}
          sx={{
            cursor: disableBack ? "not-allowed !important" : "pointer",
            opacity: disableBack && "0.5",
            width: "24px",
            height: "24px",
            zIndex: "100",
            left: "0",
            position: "absolute",
            ml: { md: "30px", sm: "25px", xs: "25px" },
            mt: { md: "60px" },
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "18px",
              height: "18px",
              position: "absolute",
              left: "2.5rem",
            }}
            data-testid="intro_prim_back"
            onClick={() => (disableBack ? null : handleStep(false))}
          >
            <ArrowBackIosIcon sx={{ width: "18px" }} data-testid="intro_prim_back_icon" />
            <Box data-testid="intro_prim_back_text">Back</Box>
          </Box>
        </Box>
        <Paper
          sx={{
            // border: "1px solid rgba(0,0,0,.2)",
            width: { xs: "90%", sm: "724px" },
            // height: { xs: "450px", sm: "450px" },
            mt: { xs: "60px" },
            boxShadow: { xs: "unset !important" },
            marginBottom: "100px",
            height: "fit-content"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: { xs: "31px" },
              pb: { xs: "22px" },
            }}
          >
            {/* <Box
              component="img"
              alt={"circular_loader"}
              src={flight_icon?.data?.[0]?.attributes?.url}
              sx={{ width: { xs: "33px", paddingRight: "16px" } }}
            /> */}
            <Typography
              sx={{ fontSize: "24px", fontFamily: "var(--font_family_Semibold)" }}
              data-testid="intro_prim_title"
            >
              {intro_title1}
            </Typography>
          </Box>
          <hr style={{ border: "1px solid #F8F8F8", marginTop: "0px" }}></hr>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--dark-charcoal-fade)",
                mt: { xs: "18px" },
                mb: { xs: "21px" },
                fontWeight: "var(--font_weight_2)",
                textAlign: "left",
                margin: currentLine?.esim ? { md: "20px 70px", xs: "18px 10px 21px 30px" } : { md: "20px 175px", xs: "18px 10px 21px 30px" },
              }}
              data-testid="intro_prim_desc"
            >
              {intro_desc21}
            </Typography>
          </Box>
          <Box
            sx={{
              mx: currentLine?.esim ? "" : "auto",
              width: currentLine?.esim ? "auto" : { xs: "auto", md: "70%" },
            }}
            data-testid="intro_prim_points_wrapper"
          >
            {introPoints?.map((data: any, index: any) => {
              return (
                <Box
                  className={styles.activation_info}
                  key={data.id}
                  sx={{
                    px: { xs: "30px", md: "65px" },
                    boxSizing: "border-box",
                  }}
                >
                  {/* <img
                    className={styles.check_icon}
                    src={each_icon1?.data?.attributes?.url}
                    alt={each_icon1?.data?.attributes?.alternativeText}
                  /> */}
                  <Box
                    className={styles.check_icon}
                    component="div"
                    sx={{
                      marginRight: "10px",
                      display: "flex",
                      "& svg": {
                        width: "21px",
                        height: "22px",
                      },
                    }}
                    data-testid={`into_prim_icon_${Number(index) + 1 || 1}`}
                    dangerouslySetInnerHTML={{
                      __html: updateSvgFillColor(
                        each_icon_svg,
                        "var(--primary_color)"
                      ),
                    }}
                  />
                  <Typography
                    className={styles.activation_body_text}
                    sx={{
                      wordWrap: "break-word !important",
                      maxWidth: "100%",
                    }}
                    data-testid={`into_prim_desc_${Number(index) + 1 || 1}`}
                  >
                    {BrandNameUpdate(data.desc)}
                    {data?.here_how_find && (
                      <>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                          <CustToolTip
                            className={styles.customTooltip}
                            arrow
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            title={
                              <ImeiLookUpComponent
                                isTitle={true}
                                content={props?.content?.imei_look_up}
                              />
                            }
                          >
                            <Typography
                              sx={{
                                cursor: "pointer",
                                fontFamily: "var(--font_family_Medium)",
                                fontWeight: "500",
                                color: "var(--primary_color)",
                                marginTop: { xs: "0px", md: "2px" },
                                marginLeft: { xs: "-4px", md: "0px" },
                                paddingTop: { xs: "5px", md: "0px" },
                                display: "contents",
                                fontSize: "16px",
                                position: "relative",
                              }}
                              onMouseEnter={() => {
                                setOpen(true);
                              }}
                              onMouseLeave={() => {
                                setOpen(false);
                              }}
                              onClick={handleTooltipOpen}
                              data-testid={`into_prim_inner_text_${Number(index) + 1 || 1}`}
                            >
                              &nbsp;{content?.how_find_txt}
                            </Typography>
                          </CustToolTip>
                        </ClickAwayListener>
                      </>
                    )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ textAlign: "center", mt: "25px", mb: "36px" }}>
            <ButtonCust
              variantType={Config.PRIMARY_BUTTON_TYPE}
              onClick={() => letsGoHandler()}
              sx={{
                width: { xs: "250px", sm: "250px", md: "250px" },
                height: { md: "50px !important" },
              }}
              data-testid="intro_prim_btn"
            >
              {intro_btn}
            </ButtonCust>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default T6_InitialActivation;

// primaryCustomer.status === "active" && localStorage.getItem("secondaryCustomer") === null
