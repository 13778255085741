import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { privacyProps } from "./Privacy";
import styles from "./S2_privacy.module.scss";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";

const PrivacyComponent = (props: privacyProps) => {
  const { content } = props;
  return (
    <Box
      className={styles.privacy_main_container}
      data-testid="privacy_parent"
    >
      <Box className={styles.privacy_content}>
        <Typography variant="h1" className={styles.privacy_title} sx={{ letterSpacing: '0.00938em' }} data-testid="privacy_title">
          {content?.title}
        </Typography>
        <Box className={styles.privacy_line} />
        <Box
          className={clsx(styles.privacy_text, styles.privacy_top_para)}
          dangerouslySetInnerHTML={{
            __html: BrandNameUpdate(content?.description),
          }}
          data-testid="privacy_desc"
        />
        {content &&
          content?.privacy_content.length > 0 &&
          content?.privacy_content.map((items: any, index: string) => {
            return (
              <Box key={index} data-testid={`privacy_content_wrapper_${Number(index) + 1 || 1}`}>
                <Typography className={styles.privacy_heading} data-testid={`privacy_content_heading_${Number(index) + 1 || 1}`}>
                  {index + 1}. {BrandNameUpdate(items?.heading)}
                </Typography>
                <Typography className={styles.privacy_heading} data-testid={`privacy_content_sub_heading_${Number(index) + 1 || 1}`}>
                  {BrandNameUpdate(items?.sub_heading)}
                </Typography>
                <Box
                  className={styles.privacy_text}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(items?.description),
                  }}
                  data-testid={`privacy_content_desc_${Number(index) + 1 || 1}`}
                />
                <Typography className={styles.privacy_heading} data-testid={`privacy_content_sub_heading_1_${Number(index) + 1 || 1}`}>
                  {BrandNameUpdate(items?.sub_heading1)}
                </Typography>
                <Box
                  className={styles.privacy_text}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(items?.description1),
                  }}
                  data-testid={`privacy_content_desc_1_${Number(index) + 1 || 1}`}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default PrivacyComponent;
