import WalletCard from "../HistoryCard/WalletCard";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../app/hooks";
import { format } from "date-fns";
import DropDownMenu from "../../../../widgets/DropDownMenu/DropDownMenu";
import _ from "lodash";

const T1_UsageHistory = (props: any) => {
  const { country, usage, no_usage } = props.content.wh_data;

  const { less_icon, greater_icon, no_data_img } = props.content;
  const {
    userList,
    handleLineItemClick,
    handleLineClose,
    lineAnchorEl,
    handleClickLineItem,
    selectedLineIndex,
    lineOpen,
    walletuserData,
    getStartAndEndDateData,
    memoizedData,
  } = props;
  const { getILDUsageHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  const { CustomerBillCycleDataUsage } = useAppSelector(
    (state) => state?.customer
  );
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: { xs: "center", sm: "end" } }}
        data-testid="usage_hist_wrapper"
      >
        <DropDownMenu
          statuslist={userList}
          handleMenuItemClick={handleLineItemClick}
          handleClose={handleLineClose}
          menuAnchorEl={lineAnchorEl}
          handleClickListItem={handleClickLineItem}
          selectedIndex={selectedLineIndex}
          menuOpen={lineOpen}
          selectedIcon={props.content.select_img}
          uparrowIcon={props.content.up_arrow_img}
          downarrowIcon={props.content.down_arrow_img}
          styleSX={{
            backgroundColor: "transparent !important",
            padding: { xs: "0px", sm: "0px 8px" },
            "& .MuiListItemText-secondary": {
              color: "var(--text_color) !important",
              fontSize: "16px !important",
              linHeight: "28px !important",
              fontWeight: "var(--font_weight_1) !important",
              fontFamily: "var(--font_family_Medium) !important",
            },
            "& .MuiList-root": {
              padding: { xs: "0px", sm: "0px 8px" },
            },
          }}
        />
      </Box>
      {walletuserData ? (
        <WalletCard lineDataForWallet={walletuserData} content={props.content}>
          {getILDUsageHistoryPending && !CustomerBillCycleDataUsage ? (
            <Box textAlign={"center"}>
              <CircularProgress sx={{ color: "var(--primary_color)" }} data-testid="usage_hist_progress"/>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mt: "20px",
              }}
            >
              {memoizedData.current.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    onClick={() => {
                      if (
                        getStartAndEndDateData.totalNumberOfKeys > 0 &&
                        props.selectedDateKeyIndex > 0
                      ) {
                        props.handleBillCycleChange("decrement");
                      }
                    }}
                    data-testid="usage_hist_dec"
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={less_icon.data.attributes.url}
                      alt={less_icon.data.attributes.alternativeText}
                      data-testid="usage_hist_dec_img"
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_1)",
                      fontFamily: "var(--font_family_Medium)",
                    }}
                    data-testid="usage_hist_start_date"
                  >
                    {getStartAndEndDateData?.displayStartDate
                      ? format(
                          new Date(getStartAndEndDateData.displayStartDate),
                          "MMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                  <span>-</span>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_1)",
                      fontFamily: "var(--font_family_Medium)",
                    }}
                    data-testid="usage_hist_end_date"
                  >
                    {getStartAndEndDateData?.displayEndDate
                      ? format(
                          new Date(getStartAndEndDateData.displayEndDate),
                          "MMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                  <Box
                    onClick={() => {
                      if (
                        getStartAndEndDateData.totalNumberOfKeys > 0 &&
                        props.selectedDateKeyIndex !==
                          getStartAndEndDateData.totalNumberOfKeys - 1
                      ) {
                        props.handleBillCycleChange("increment");
                      }
                    }}
                    data-testid="usage_hist_inc"
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={greater_icon.data.attributes.url}
                      alt={greater_icon.data.attributes.alternativeText}
                      data-testid="usage_hist_inc_img"
                    />
                  </Box>
                </Box>
              )}
              {memoizedData.current && memoizedData.current.length === 0 ? (
                <Box sx={{ padding: { xs: "20px", sm: "80px" } }}>
                  <Box
                    component="img"
                    src={no_data_img.data.attributes?.url}
                    alt={no_data_img.data.attributes.alternativeText}
                    data-testid="usage_hist_no_data_img"
                  />
                  <Typography
                    sx={{
                      fontSize: "34px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      opacity: 0.4,
                    }}
                    data-testid="usage_hist_no_usage"
                  >
                    {no_usage}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: { xs: "40%", md: "40%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                    data-testid="usage_hist_country"
                  >
                    {country}
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                    data-testid="usage_hist_usage"
                  >
                    {usage}
                  </Typography>
                </Box>
              )}
              {memoizedData.current?.map((data: any, index: number) => (
                <Box
                  key={index}
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    width: { xs: "40%", md: "40%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                    }}
                    data-testid={`usage_hist_country_name_${Number(index) + 1 || 1}`}
                  >
                    {data.countryName}
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                    }}
                    data-testid={`usage_hist_voice_usage_${Number(index) + 1 || 1}`}
                  >
                    {data.voiceUsage}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </WalletCard>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} data-testid="usage_hist_progress" />
        </Box>
      )}
    </>
  );
};

export default T1_UsageHistory;
