import { useEffect, useState } from "react";
import AppConfig from "../../../../config/app.config.json";
import { useAppDispatch } from "../../../../app/hooks";
import { parseISO, format, parse } from "date-fns";
import { getIRTaxCharges } from "../../../../features/checkout/checkoutSlice";
import {
  purchasePack,
  resetPurchasePack,
} from "../../../../features/services/servicesSlice";
import { getCC } from "../../../../features/account/accountSlice";
import { isTemplateExisits } from "../../../../utils/commonFunctions";

const RoamingPackComp = isTemplateExisits(
  AppConfig.PostActivationServices.IRRoamingPack
)
  ? require(`./${AppConfig.PostActivationServices.IRRoamingPack.template}_GlobalRoamingPack`)
      .default
  : null;

function GlobalRoamingPack(props: any) {
  const { content } = props.props;
  const { payment_review } = content;

  const dispatch = useAppDispatch();
  const [isCalendarModal, setisCalendarModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [isPaymentReviewCard, setIsPaymentReviewCard] =
    useState<boolean>(false);
  const [autoRenewal, setAutoRenweal] = useState<boolean>(false);
  const [isTooltip, setIsTooltip] = useState<boolean>(false);
  const [selectedPack, setSelectedPack] = useState<any>(null);
  const [purchasePackStatus, setPurchasePackStatus] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<any>("");

  const customerDetailJSON = localStorage.getItem("customerDetail");
  const customerDetail = customerDetailJSON
    ? JSON.parse(customerDetailJSON)
    : null;

  const billingData = customerDetail.addresses.find(
    (item: any) => item.type === "billing"
  );

  const dateFormat: any = () => {
    const getLocalCountry: any = localStorage.getItem("postActivationDate");
    const parsedDate = parseISO(getLocalCountry); // Parse the ISO format date string
    const formattedDate = format(parsedDate, "MMMM d, yyyy");
    return formattedDate;
  };

  useEffect(() => {
    if (props?.selectedDateNew) {
      setSelectedDate(props?.selectedDateNew);
    }
  }, [props.selectedDateNew]);

  const onChangeAutoRenewal = () => {
    setAutoRenweal((prev) => !prev);
  };

  const country = localStorage.getItem("prRoamingCountry");
  const calanderOnChangeHandler = (e: any) => {
    const date = new Date(e);
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate: any = date.toLocaleDateString("en-US", options);
    setSelectedDate(formattedDate);
  };
  const formatDateForAPI = (e: any) => {
    if (e) {
      const parsedDate = parse(e, "MMMM dd, yyyy", new Date());
      const formatted = format(parsedDate, "yyyy-MM-dd");
      return formatted;
    }
    return;
  };
  const paymentFailedBack = () => {
    dispatch(resetPurchasePack());
  };

  const confirmPurchaseHandler = () => {
    setPurchasePackStatus(true);
    const customerId: any = localStorage.getItem("SingleLineData");
    const country: any = localStorage.getItem("prRoamingCountry");
    const payload = {
      offeringId: selectedPack.id,
      tentativeStartDate: localStorage.getItem("postActivationDate"),
      countries: [JSON.parse(country).code],
      autoRenew: autoRenewal,
      customerId: JSON.parse(customerId).id,
    };
    dispatch(purchasePack(payload));
  };

  const calanderSubmitHandler = () => {
    const formattedForApi: any = formatDateForAPI(selectedDate);
    localStorage.setItem("postActivationDate", formattedForApi);
    setIsPaymentReviewCard(false);
    setisCalendarModal(false);
  };

  const selectPackHandler = (data: any) => {
    const address = {
      name: `${customerDetail.firstName} ${customerDetail.lastName}`,
      address1: billingData?.address1,
      city: billingData?.city,
      country: billingData?.country,
      state: billingData?.state,
      type: "billing",
      zip: billingData?.zip,
    };

    const localData: any = {
      displayName: data.displayName,
      dataCost: data.price,
      dataUnit: data.dataUnit,
      data: data.data,
      id: data.id,
    };
    setSelectedPack(localData);
    setDisplayName(data.displayName);
    setIsPaymentReviewCard(true);
    dispatch(
      getIRTaxCharges({
        address,
        amount: data?.price,
        additionalLines: customerDetail?.totalActiveLines,
      })
    );
    dispatch(getCC({ customerId: customerDetail && customerDetail.id }));
  };

  const combinedSelectedPackReviewData = { ...selectedPack, ...payment_review };

  return (
    <>
      <RoamingPackComp
        {...props}
        calanderOnChangeHandler={calanderOnChangeHandler}
        paymentFailedBack={paymentFailedBack}
        confirmPurchaseHandler={confirmPurchaseHandler}
        calanderSubmitHandler={calanderSubmitHandler}
        selectPackHandler={selectPackHandler}
        combinedSelectedPackReviewData={combinedSelectedPackReviewData}
        country={country}
        onChangeAutoRenewal={onChangeAutoRenewal}
        dateFormat={dateFormat}
        isCalendarModal={isCalendarModal}
        isPaymentReviewCard={isPaymentReviewCard}
        isTooltip={isTooltip}
        setIsTooltip={setIsTooltip}
        purchasePackStatus={purchasePackStatus}
        displayName={displayName}
        autoRenewal={autoRenewal}
        selectedDate={selectedDate}
        setisCalendarModal={setisCalendarModal}
      />
    </>
  );
}

export default GlobalRoamingPack;
