import InfoIcon from "@mui/icons-material/Info";
import { ClickAwayListener, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { differenceInDays, isDate } from "date-fns";
import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import envConfig from "../../../../config/env.config.json";
import styles from "./AccountInfo.module.scss";
import { UpdateCustomerSource } from "../../../../features/checkout/checkoutSlice";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import {
  ButtonCust,
  CustToolTip,
  NotificationCust,
  SimpleModal,
  SwitchCustIOS,
} from "../../../widgets";
import AccountLockModal from "./AccountLockModal";

const T3_AccountInfo = (props: any) => {
  const { content: fccAccountContent } = props;
  const fccAccountImages = props?.fccAccountImages || {};
  let customerDetails: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const recoveryEmail = customerDetails?.recoveryEmail ?? undefined;
  const recoveryEmailVerified = customerDetails?.recEmailVerified ?? false;

  const { configInfo } = useAppSelector((state: any) => state.account);
  const { getCustomer } = useAppSelector((state: any) => state.checkout);

  const [showAccountLockModal, setShowAccountLockModal] = useState(false);
  const [addRecoveryEmailModal, setAddRecoveryEmailModal] = useState(false);
  const [profileUpdateLoading, setProfileUpdateLoading] = useState(false);
  const [updateRecoveryEmailModal, setUpdateRecoveryEmailModal] =
    useState(false);
  const [accountLockPoper, setAccountLockPoper] = useState(false);
  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setAccountLockPoper(false);
        }}
      >
        <Box component="a" target="_blank">
          {data}
        </Box>
      </ClickAwayListener>
    );
  };
  // const [hardNudgeModal, setHardNudgeModal] = useState(
  //   customerDetails &&
  //     (recoveryEmail === undefined || !recoveryEmailVerified) &&
  //     customerDetails.meta &&
  //     customerDetails.meta.recoveryEmail &&
  //     !customerDetails.meta.recoveryEmail.app_nudge_alert &&
  //     customerDetails.meta.recoveryEmail.app_alert_date &&
  //     isDate(new Date(customerDetails.meta.recoveryEmail.app_alert_date)) &&
  //     differenceInDays(
  //       new Date(),
  //       new Date(customerDetails.meta.recoveryEmail.app_alert_date)
  //     ) >
  //       (configInfo?.configs?.DAYS_TO_SHOW_HARD_NUDGE_FOR_RECOVERY_EMAIL ?? 15)
  // );

  const line = () => {
    return (
      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: " 1px solid var(--lightgrey_6)",
            margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>
    );
  };

  const dispatch = useAppDispatch();

  const updateCustomer = async (data: any) => {
    setProfileUpdateLoading(true);

    await dispatch(
      UpdateCustomerSource({
        customerId: customerDetails?.id,
        data: { ...data },
      })
    );
    Store.removeNotification("RecoveryEmailNotification");

    setProfileUpdateLoading(false);
  };

  // useEffect(() => {
  //   if (
  //     customerDetails &&
  //     (recoveryEmail === undefined || !recoveryEmailVerified) &&
  //     ((customerDetails.meta &&
  //       customerDetails.meta.recoveryEmail &&
  //       !customerDetails.meta.recoveryEmail.app_alert) ||
  //       !customerDetails.meta ||
  //       !customerDetails.meta.recoveryEmail)
  //   ) {
  //     NotificationCust({
  //       message: (
  //         <Box
  //           sx={{
  //             fontSize: "14px",
  //             fontFamily: "var(--font_family_Semibold)",
  //             display: "flex",
  //             gap: "20px",
  //             color: "black",
  //           }}
  //           onClick={(e) => e.stopPropagation()}
  //         >
  //           <WarningAmberOutlinedIcon />
  //           <Typography
  //             component="div"
  //             sx={{
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "flex-start",
  //             }}
  //           >
  //             <Typography
  //               sx={{
  //                 fontWeight: "var(--font_weight_2)",
  //                 fontSize: "16px",
  //               }}
  //             >
  //               {fccAccountContent?.header1}
  //             </Typography>
  //             <Typography
  //               style={{
  //                 lineHeight: "1.5",
  //                 textAlign: "left",
  //                 fontSize: "13px",
  //               }}
  //             >
  //               {BrandNameUpdate(fccAccountContent?.desc1)}
  //             </Typography>
  //           </Typography>
  //           <ButtonCust
  //             variantType={envConfig.PRIMARY_BUTTON_TYPE}
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               setAddRecoveryEmailModal(true);
  //               setShowAccountLockModal(true);
  //             }}
  //             sx={{
  //               width: "200px",
  //               height: "40px !important",
  //               alignSelf: "flex-end",
  //               "&:hover": {
  //                 backgroundColor: "var(--button_hover_color) !important",
  //                 border: "1px solid var(--button_hover_color) !important",
  //               },
  //             }}
  //           >
  //             {fccAccountContent?.action_add}
  //           </ButtonCust>
  //         </Box>
  //       ),
  //       type: "warning",
  //       duration: 0,
  //       id: "RecoveryEmailNotification",
  //       showIcon: true,
  //       onRemoval: (_id: any, _: any) => {
  //         const meta = {
  //           recoveryEmail: {
  //             app_alert: true,
  //             app_alert_date: new Date(),
  //           },
  //         };
  //         updateCustomer({ meta });
  //       },
  //     });
  //   } else {
  //     Store.removeNotification("RecoveryEmailNotification");
  //   }
  //   return () => Store.removeNotification("RecoveryEmailNotification");
  // }, [getCustomer]);

  const {
    fcc_container,
    account_info,
    lock_container,
    account_title,
    account_lock,
  } = styles ?? {};
  const accountLocked =
    customerDetails &&
    customerDetails.accAccessLock &&
    customerDetails.accAccessLock?.length > 0
      ? true
      : false;
  return (
    <>
      <Box className={fcc_container} data-testid="account_info_wrapper">
        <Box className={account_info}>
          <Box
            component="img"
            src={fccAccountImages?.img1?.data?.attributes?.url}
            alt={fccAccountImages?.img1?.data?.attributes?.alternativeText}
            data-testid="account_info_img"
          />
          <Typography className={account_title} data-testid="account_info_header_2">
            {fccAccountContent?.header2}
          </Typography>
        </Box>
        {line()}

        <Box className={lock_container}>
          <Typography className={account_lock} data-testid="account_info_account_lock">
            {fccAccountContent?.accountlock}{" "}
            <CustToolTip
              title={PoperOver(
                <Typography data-testid="account_info_tooltip_text">
                  {BrandNameUpdate(fccAccountContent?.tooltip1)}
                </Typography>
              )}
              open={accountLockPoper}
              disableFocusListener
              disableHoverListener
              arrow
              placement="bottom"
              sx={{
                padding: "2px",
                width: "290px",
                // boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
                borderRadius: "4px",
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    color: "var(--white) !important",
                    padding: "8px !important",
                    borderRadius: "3px !important",
                    border: "none !important",
                    backgroundColor: "var(--black) !important",
                    "& p": {
                      lineHeight: "21px !important",
                      fontWeight: "Var(--font_weight_0) !important",
                      fontFamily: "var(--font_family_Regular) !important",
                      fontSize: "14px !important",
                      color: "var(--white)",
                    },
                  },
                },
                arrow: {
                  sx: {
                    "::before": {
                      backgroundColor: "var(--black) !important",
                      border: "none !important",
                    },
                  },
                },
              }}
            >
              <Box
                component="img"
                src={fccAccountImages?.tooltip_icon?.data?.attributes?.url}
                alt={
                  fccAccountImages?.tooltip_icon?.data?.attributes
                    ?.alternativeText
                }
                data-testid="account_info_tooltip_icon"
                sx={{ ml: "5px" }}
                onMouseEnter={() => {
                  setAccountLockPoper(true);
                }}
                onClick={() => {
                  setAccountLockPoper(true);
                }}
                onMouseLeave={() => {
                  setAccountLockPoper(false);
                }}
              />
            </CustToolTip>
          </Typography>
          <SwitchCustIOS
            styles={{ mr: "0px" }}
            checked={accountLocked}
            handleSwitchToggle={(e: any) => {
              setShowAccountLockModal(true);
            }}
            data-testid="account_info_switch"
            name="accountLock"
          />
        </Box>
      </Box>

      {/* ROW 2 */}
      {/* {line()} */}
      {/* ROW 3 */}
      {/* <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
            paddingY: "6px",
          }}
        >
          {
            <Grid
              item
              sx={{
                "&.MuiGrid-root": {
                  "&.MuiGrid-item": {
                    pl: "0px",
                    pt: "0px",
                  },
                },
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "16px",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "10px" },
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    sm: "0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "var(--text_color_14)",
                    textAlign: "center",
                    fontFamily: "var(--font_family_Medium)",
                    fontSize: "16px",
                  }}
                >
                  {fccAccountContent?.sh2}{" "}
                  <CustToolTip
                    title={BrandNameUpdate(fccAccountContent?.tooltip1)}
                    disableFocusListener
                    arrow
                    placement="bottom"
                    sx={{
                      padding: "2px",
                      width: "290px",
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "var(--dark-navy) !important",
                          color: "var(--white) !important",
                          lineHeight: "20px !important",
                          fontSize: "14px !important",
                          padding: "8px !important",
                        },
                      },
                      arrow: {
                        sx: {
                          "::before": {
                            backgroundColor: "var(--dark-navy) !important",
                          },
                        },
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        fccAccountImages?.tooltip_icon?.data?.attributes?.url
                      }
                      alt={
                        fccAccountImages?.tooltip_icon?.data?.attributes
                          ?.alternativeText
                      }
                    />
                  </CustToolTip>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!recoveryEmail || !recoveryEmailVerified ? (
                  <Typography
                    sx={{
                      color: true
                        ? "var(--danger_notification)"
                        : "var(--text_color)",
                      textAlign: "center",
                      fontFamily: "var(--font_family_Semibold)",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAddRecoveryEmailModal(true);
                      setShowAccountLockModal(true);
                    }}
                  >
                    <ReportProblemOutlinedIcon
                      sx={{
                        marginRight: "3px",
                      }}
                    />{" "}
                    {fccAccountContent?.action_add}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: "var(--text_color)",
                        textAlign: "center",
                        fontFamily: "var(--font_family_Semibold)",
                        display: "flex",
                      }}
                    >
                      <TaskAltIcon
                        sx={{ marginRight: "3px", color: "var(--success)" }}
                      />{" "}
                      {recoveryEmail}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                        textDecoration: "underline",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setUpdateRecoveryEmailModal(true);
                        // setShowAccountLockModal(true);
                      }}
                    >
                      {fccAccountContent?.change}
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          }
        </Box>
        {line()} */}
      {/* ROW 4 */}
      {(showAccountLockModal || updateRecoveryEmailModal) && (
        <AccountLockModal
          display={showAccountLockModal || updateRecoveryEmailModal}
          closeHandler={() => {
            setAddRecoveryEmailModal(false);
            setShowAccountLockModal(false);
            setUpdateRecoveryEmailModal(false);
          }}
          primaryEmail={customerDetails?.email ?? ""}
          recoveryEmail={recoveryEmail}
          recoveryEmailVerified={recoveryEmailVerified}
          addRecoveryEmailOnly={addRecoveryEmailModal}
          updateRecoveryEmailModal={updateRecoveryEmailModal}
        />
      )}
      {/* {hardNudgeModal && (
        <SimpleModal
          isShowModal={hardNudgeModal}
          onCloseModal={() => {
            setHardNudgeModal(false);
            const meta = {
              recoveryEmail: {
                app_nudge_alert: true,
              },
            };
            updateCustomer({ meta });
          }}
          showClose
          sx={{ width: { xs: "95%", sm: "50%", md: "45%", lg: "33%" } }}
          style={{ borderRadius: "4px" }}
        >
          <>
            <Box display="flex" alignItems="center">
              {
                <Box
                  component="img"
                  src={fccAccountImages?.img1?.data?.attributes?.url}
                  alt={"account-lock"}
                />
              }
            </Box>
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m1h1}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "14px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {fccAccountContent?.m1d1}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={envConfig.PRIMARY_BUTTON_TYPE}
                onClick={async () => {
                  setHardNudgeModal(false);
                  const meta = {
                    recoveryEmail: {
                      ...customerDetails.meta.recoveryEmail,
                      app_nudge_alert: true,
                    },
                  };
                  updateCustomer({ meta });
                }}
                loading={profileUpdateLoading}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.m1a1}
              </ButtonCust>
            </Box>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "13px", sm: "16px" },
                color: "var(--primary_color)",
                mt: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setHardNudgeModal(false);
                setAddRecoveryEmailModal(true);
                setShowAccountLockModal(true);
              }}
            >
              {fccAccountContent?.action_add}
            </Typography>
          </>
        </SimpleModal>
      )} */}
    </>
  );
};
export default T3_AccountInfo;
