import { Grid, Typography, Box, Link } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AddressInfo } from "./AddressInfo";
import { ReviewPlanDetails } from "./ReviewPlansDetails";
import flatMap from "lodash/flatMap";
import {
  getInTouchApi,
  liveOpsMessagesApi,
  reachPlanData,
} from "../../features/homepage/homepageSlice";
import {
  CreateCustomer,
  UpdateCustomer,
  UpdateCustomerSource,
  CouponReserve,
  Coupon,
  GetCustomer,
  Eligibility,
  getCustomerCouponReserve,
  updateFormValues,
  updatePlanDetails,
  resetFormValues,
  resetCoupon,
  validateSIMNumber,
  hsdAddApi,
  LandingPromo,
  resetCheckoutstate,
  setSIMVerificationState,
  getZipcodes,
  resetBillingAddress,
  resetShippingAddress,
} from "../../features/checkout/checkoutSlice";
import firebase from "../../adapters/firebase";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { ButtonCust, NotificationCust } from "../widgets";
import { useFormik } from "formik";
import { object, string } from "yup";
import { ReachPlan } from "../../features/trackorder/trackorderSlice";
import styles from "./checkout.module.scss";
import { Buffer } from "buffer";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { addressType, SimTypes } from "../../features/checkout/Types";
import {
  getAddressInfoContent,
  getReviewContent,
  checkoutStepper,
  getAutoPayCContent,
  getSimInHandContent,
  loaderPageContent,
  getZipcodeContent,
} from "../../features/strapi/checkoutSlice";
import { namePattern, numberInStringPattern } from "../../utils/regexPatterns";
import { RoutingURLs } from "../../config/RoutingURLs";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import Config from "../../config/env.config.json";
// import PlanData from "../../config/data.json";
import { ActivationStatus } from "../../enums";
import { DeeperStepperCust } from "../widgets";
import ENVConfig from "../../config/env.config.json";
import CardSavingAlertDialog from "./CardSavingAlertDialog/CardSavingAlertDialog";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import { gaCategory } from "../../Types/GoogleAnalytics";
import { gtmTagManager } from "../../utils/commonFunctions/GtmTagManager";
import FirebaseDBURLs from "../../config/FirebaseDBURLs";
import { ZipcodeModal } from "./ZipcodeModal";
import {
  fetchCountries,
  getCartItems,
  getAddressesFromShopware,
  fetchStates,
  createAddressOnShopware,
  getCustomerFromShopware,
  updateAddressOnShopware,
  defaultShippingAddressOnShopware,
} from "../../features/shopWare/shopWareSlice";
import { usaState } from "../../config/USAStateList";
import { shopWareRedirectionHandler } from "../../utils/commonFunctions/shopWareFunctions";
import { OpsMsgTypes } from "../../features/homepage/OpsMsgTypes";

const UNLIMITED_PLAN_DISCOUNT_COUPON = ENVConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../config/discountCouponsUnlimited.json")
  : null;
const SHARABLE_PLAN_DISCOUNT_COUPON = ENVConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../config/discountCouponsRegular.json")
  : null;
export const Checkout = () => {
  const dispatch: any = useAppDispatch();
  const {
    createCustomer,
    errorCreateCustomer,
    updateCustomer,
    errorUpdateCustomer,
    getCustomer,
    errorGetCustomer,
    formValues,
    coupon,
    updateCustomerSource,
    eligibility,
    isLoaded,
    isLoadedPlanTaxCharges,
    isLoadedShippingTaxes,
    planDetails,
    oneTimeShippingTaxCharges,
    isSIMVerificationSuccess,
    landingPromoPlan,
  } = useAppSelector((state: any) => state.checkout);
  const { loadPage } = useAppSelector((state: any) => state.strapi.checkout);
  const {
    addressInfoContent,
    reviewContent,
    checkoutStepperContent,
    autoPayContent,
    simInHandContent,
    zipcodeContent,
  } = useAppSelector((state: any) => state.strapi.checkout);
  const {
    getCartItemsSuccess,
    getAddressFromShopwareSuccess,
    getCustomerFromShopwareSuccess,
    fetchStatesSuccess,
  } = useAppSelector((state: any) => state.shopWare);
  const {
    getInTouch,
    liveOpsMessagesSuccess,
    liveOpsMessagesError,
    compatibility,
  } = useAppSelector((state: any) => state.homepage);
  const { notification } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  const [cacheAddressInfo, setCacheAddressInfo] = useState();
  const [cachePlanReview, setCachePlanReview] = useState();
  const [cacheCheckoutStepper, setCacheCheckoutStepper] = useState();
  const [isAutoSelect, setIsAutoSelect] = useState(false);
  const [autoPayCacheContent, setAutoPayCacheContent] = useState();
  const [simInHandCacheContent, setSimInHandCacheContent] = useState();
  const [addressListOfShopware, setAddressListOfShopware] = useState([]);
  const [zipcodeCacheContent, setZipcodeCacheContent] = useState();
  const [zipcodeOpen, setZipcodeOpen] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const addressContentState = cacheAddressInfo
    ? cacheAddressInfo
    : addressInfoContent;
  const checkoutStepperContentState = cacheCheckoutStepper
    ? cacheCheckoutStepper
    : checkoutStepperContent;
  const reviewContentState = cachePlanReview ? cachePlanReview : reviewContent;
  const autoPayContentState = autoPayCacheContent
    ? autoPayCacheContent
    : autoPayContent;
  const simInHandContentState = simInHandCacheContent
    ? simInHandCacheContent
    : simInHandContent;

  const navigate = useNavigate();
  const [newUser, setNewUser] = useState(true);
  const [paymentClicked, setPaymentClicked] = useState(false);
  const [user, setuser] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location: any = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const [simPaid, setSimPaid] = useState(false);
  const [sendSimOptions, setSendSimOptions] = useState<any>([]);
  const [customerReservedCoupon, setCustomerReservedCoupon] = useState<any>();
  const [isAutoPay, setIsAutoPay] = useState(true);
  const [isSaveCard, setIsSaveCard] = useState(true);
  const [displaySaveCardAlertDialog, setDisplaySaveCardAlertDialog] =
    useState(false);
  const [simId, setSimId] = useState<string>("");
  const [simInHand, setSimInHand] = useState<boolean>(false);
  const [countryId, setCountryId] = useState<string>("");
  const [checkoutPageOpsMessages, setCheckoutPageOpsMessages] = useState([]);
  const [priorityOpsMessageId, setPriorityOpsMessageId] = useState("");
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;

  let activeStep: number = 1;
  let checkoutStepperContentdata =
    checkoutStepperContentState?.steps?.length > 0
      ? checkoutStepperContentState?.steps?.map((label: any, index: any) => {
          return {
            title: label.item,
            levels: 1,
            isFailing: false,
            currentLevel: activeStep,
          };
        })
      : [];
  let level1 = 1;
  let level2 = 0;
  const currentLevels = [level1, level2];
  const updatedCheckoutSteps = checkoutStepperContentdata.map(
    (obj: any, index: any) => {
      return {
        ...obj,
        currentLevel: currentLevels[index],
      };
    }
  );
  /**
   * Strapi content cache storage functions
   */
  useEffect(() => {
    getCache(
      "AddressInfo",
      getAddressInfoContent,
      setCacheAddressInfo,
      dispatch
    );
    getCache("PlanReview", getReviewContent, setCachePlanReview, dispatch);
    getCache(
      "CheckoutStepper",
      checkoutStepper,
      setCacheCheckoutStepper,
      dispatch
    );
    Config?.IS_AUTOPAY_ENABLED &&
      getCache(
        "autoPayContentCheckout",
        getAutoPayCContent,
        setAutoPayCacheContent,
        dispatch
      );
    Config?.IS_SIM_IN_HAND_ENABLED &&
      getCache(
        "simInHandContentCheckout",
        getSimInHandContent,
        setSimInHandCacheContent,
        dispatch
      );
      getCache(
        "zipcodeContent",
        getZipcodeContent,
        setZipcodeCacheContent,
        dispatch
      );
    if (ENVConfig?.IS_SHOPWARE_ENABLED) {
      let searchParams = new URLSearchParams(document.location.search);
      let logoutCheck: string | null = searchParams.get("logout");
      if (!logoutCheck) {
        dispatch(getCartItems());
        let currentuser: any = localStorage.getItem("currentuser");
        currentuser = JSON.parse(currentuser);
        if (currentuser && currentuser.email) {
          dispatch(getCustomerFromShopware());
        }
      }
    }
  }, []);
  useEffect(() => {
    ENVConfig?.IS_MSG_CENTER_ENABLED &&
      dispatch(
        liveOpsMessagesApi({
          limit: OpsMsgTypes.limit,
          partialMatch: true,
          query: "",
          status: [OpsMsgTypes.statusInProgress],
        })
      );
  }, []);
  useEffect(() => {
    dispatch(getZipcodes());
  }, []);
  useEffect(() => {
    // Live Ops Messages
    if (
      ENVConfig?.IS_MSG_CENTER_ENABLED &&
      liveOpsMessagesSuccess?.status === "SUCCESS" &&
      liveOpsMessagesSuccess?.data?.results
    ) {
      let checkoutPageOpsMessages: any = [];
      let priorityOpsMessage: any = undefined;
      liveOpsMessagesSuccess?.data?.results?.forEach((opsMessage: any) => {
        if (
          opsMessage?.inventory[
            "messaging_center.website.order_confirmation_page"
          ]
        ) {
          checkoutPageOpsMessages.push(opsMessage);
        }
        if (
          opsMessage?.inventory[
            "messaging_center.email.purchase_order_confirmation_email"
          ]
        ) {
          // Setting Priority ops message to send to payment generate API
          if (!priorityOpsMessage) {
            priorityOpsMessage = { ...opsMessage };
          } else if (
            new Date(opsMessage.endDate).getTime() >
            new Date(priorityOpsMessage?.endDate).getTime()
          ) {
            priorityOpsMessage = { ...opsMessage };
          }
        }
      });
      setCheckoutPageOpsMessages(checkoutPageOpsMessages);
      setPriorityOpsMessageId(priorityOpsMessage?.id ?? "");
    }
  }, [liveOpsMessagesSuccess]);
  useNonInitialEffect(() => {
    addMultipleCacheData("AddressInfo", addressInfoContent);
  }, [addressInfoContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("PlanReview", reviewContent);
  }, [reviewContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("CheckoutStepper", checkoutStepper);
  }, [checkoutStepper]);
  useNonInitialEffect(() => {
    addMultipleCacheData("autoPayContentCheckout", autoPayContent);
  }, [autoPayContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("simInHandContentCheckout", simInHandContent);
  }, [simInHandContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("zipcodeContent", zipcodeContent);
  }, [zipcodeContent]);
  const { planData } = useAppSelector(
    (state: any) => state.homepage
  );
  useEffect(() => {
    if(!planData){
      dispatch(reachPlanData({}));
    }
  }, []);
  useNonInitialEffect(() => {
    if (ENVConfig?.IS_SHOPWARE_ENABLED) {
      let currentuser: any = localStorage.getItem("currentuser");
      currentuser = JSON.parse(currentuser);
      let notifyType: any = localStorage.getItem("typeNotify");
      if (getCartItemsSuccess?.lineItems) {
        dispatch(fetchCountries()).then((res: any) => {
          if (res?.payload?.status === 200) {
            const countryId: any = res?.payload?.data?.elements[0].id;
            setCountryId(countryId);
            if (countryId) {
              fetchStatesFromShopware(countryId);
            }
            let finalData: any = getCartItemsSuccess?.lineItems?.find(
              (data: any) => data?.payload?.planName
            );
            if (finalData) {
              let result: any = planData?.data?.find(
                (val: any) => val.displayName === finalData.payload.planName
              );
              const plansToSet: any = {
                line: finalData.payload.quantity,
                plan: result,
              };
              localStorage.setItem(
                "reachSelectedPlanLine",
                finalData.payload.quantity
              );
              localStorage.setItem(
                "reachSelectedPlanDetail",
                JSON.stringify(plansToSet)
              );
              dispatch(
                updatePlanDetails({
                  numberOfLines: finalData.payload.quantity,
                  selectedPlan: result,
                })
              );
            }
            const mappedData: any = Object.keys(
              finalData?.payload?.linePreferences
            ).map((key, index) => {
              const data = finalData.payload.linePreferences[key];
              return {
                id: index,
                eSimOpted: data.eSimOpted,
                eSimCompatible: data?.eSimCompatible
                  ? data?.eSimCompatible
                  : data.simPreference === SimTypes.ESIM
                  ? true
                  : false,
                imei: data.imei || "",
                value:
                  data.simPreference === SimTypes.ESIM
                    ? SimTypes.eSim
                    : SimTypes.sim,
                make: data.make || "",
                model: data.model || "",
                isPrimaryLine: key === "line1" ? true : false,
              };
            });
            localStorage.setItem("simPreference", JSON.stringify(mappedData));
            setSimPreference(mappedData);
          }
        });
      }
      if (currentuser && currentuser.email) {
        dispatch(getAddressesFromShopware());
      }
      if (
        getCartItemsSuccess?.lineItems.length > 0 &&
        notifyType &&
        notifyType === "show"
      ) {
        let notificationMessage: any =
          notification?.pageNotification?.shopwareCart;
        notificationMessage = flatMap(
          notificationMessage?.split("{clickHere}"),
          function (part) {
            return [
              part,
              <Link
                key={part}
                underline="always"
                color="inherit"
                sx={{ fontWeight: "var(--font_weight_3)" }}
                onClick={cartHandler}
              >
                Click here
              </Link>,
            ];
          }
        );
        notificationMessage.pop();
        notification &&
          NotificationCust({
            message: (
              <Box
                sx={{
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {notificationMessage}
              </Box>
            ),
            duration: 0,
            id: "PlanNotification",
            showIcon: true,
          });
        //items in your cart might changed.
      }
    }
  }, [getCartItemsSuccess]);
  useNonInitialEffect(() => {
    if (getAddressFromShopwareSuccess) {
      removeDupliceAddress(getAddressFromShopwareSuccess?.elements);
    }
  }, [getAddressFromShopwareSuccess]);

  const getMessageFromCode = (responseCode: any) => {
    return loadPage?.res_code?.responseCode[responseCode] || "";
  };

  const removeDupliceAddress = (shopwareAddresses: any) => {
    if (shopwareAddresses && shopwareAddresses.length) {
      const filterAddressList = shopwareAddresses.filter(
        (item: any) =>
          item.additionalAddressLine1 != "Dummy123" &&
          item.additionalAddressLine2 != "Dummy123" &&
          item.firstName != "Dummy123" &&
          item.lastName != "Dummy123" &&
          item.city != "Dummy123" &&
          item.street != "Dummy123"
      );
      setAddressListOfShopware(filterAddressList);
    }
  };
  // validation
  const billingAndShippingSchema = object({
    firstName: string()
      .trim()
      .required(addressContentState?.fname_err)
      .matches(namePattern, addressContentState?.fname_err),
    lastName: string()
      .trim()
      .required(addressContentState?.lname_err)
      .matches(namePattern, addressContentState?.lname_err),
    phone: string()
      .trim()
      .required(addressContentState?.phone_err1)
      .min(10, addressContentState?.phone_err2),
    shipmentPlan: string().trim().required(addressContentState?.shipment_err),
    billingAddress: object({
      address1: string()
        .trim()
        .required(addressContentState?.billing?.address1_err),
      city: string().trim().required(addressContentState?.billing?.city_err),
      state: string().trim().required(addressContentState?.billing?.state_err),
      zip: string()
        .trim()
        .required(addressContentState?.billing?.zip_err1)
        .matches(/^[0-9]+$/, addressContentState?.billing?.zip_err2)
        .min(5, addressContentState?.billing?.zip_err3)
        .max(5, addressContentState?.billing?.zip_err3),
    }),
    shippingAddress: object({
      address1: string()
        .trim()
        .required(addressContentState?.shipping?.address1_err),
      city: string().trim().required(addressContentState?.shipping?.city_err),
      state: string().trim().required(addressContentState?.shipping?.state_err),
      zip: string()
        .trim()
        .required(addressContentState?.shipping?.zip_err1)
        .matches(/^[0-9]+$/, addressContentState?.shipping?.zip_err2)
        .min(5, addressContentState?.shipping?.zip_err3)
        .max(5, addressContentState?.shipping?.zip_err3),
    }),
  });
  const [simPreference, setSimPreference] = useState<any>([]);

  const inputRefs: any = {
    firstName: useRef(null),
    lastName: useRef(null),
    phone: useRef(null),
    billingAddress: {
      address1: useRef(null),
      city: useRef(null),
      state: useRef(null),
      zip: useRef(null),
    },
    shippingAddress: {
      address1: useRef(null),
      city: useRef(null),
      state: useRef(null),
      zip: useRef(null),
    },
  };
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: billingAndShippingSchema,
    onSubmit: () => {
      // form submit action
      gtmTagManager({
        event: gaCategory.purchaseInitiated,
      });
      if (simInHand && simId) {
        confirmProceedPayment();
      } else if (ENVConfig?.IS_AUTOPAY_ENABLED && (isAutoPay || isSaveCard)) {
        setDisplaySaveCardAlertDialog(true);
      } else {
        confirmProceedPayment();
      }
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    return () => {
      dispatch(resetCoupon());
      // dispatch(resetFormValues());
    };
  }, []);
  let customerStoreData: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  useEffect(() => {
    if (ENVConfig?.IS_SSO_LOGIN) {
      let ssoCustomerDetails: any = localStorage.getItem("ssoCustomerDetails");
      ssoCustomerDetails = JSON.parse(ssoCustomerDetails);
      if (ssoCustomerDetails) {
        setuser(ssoCustomerDetails);
        setIsLoggedIn(true);
      }
    } else {
      let user1: any = localStorage.getItem("currentuser");
      user1 = JSON.parse(user1);
      if (user1) {
        if (user1.isAnonymous) {
          setuser(user1);
          setIsLoggedIn(false);
        } else {
          setuser(user1);
          setIsLoggedIn(true);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (ENVConfig.SPECIAL_DISCOUNT_PLAN_NAME) {
      dispatch(LandingPromo());
    }
  }, []);

  useEffect(() => {
    let customerDetails: any = localStorage.getItem("customerDetail");
    customerDetails = JSON.parse(customerDetails);

    let simPrefStore: any = localStorage.getItem("simPreference");
    if (customerDetails?.extraLines >= 0) {
      let planData: any = {};
      if (
        Config.SPECIAL_DISCOUNT_PLAN_NAME &&
        customerDetails?.reachPlanId === Config.SPECIAL_DISCOUNT_PLAN_NAME &&
        landingPromoPlan
      ) {
        planData = landingPromoPlan;
      } else {
        if (customerDetails?.reachPlanId) {
          planData = planData?.data?.find((plan: any) => {
            return plan.name === customerDetails?.reachPlanId;
          });
        } else {
          planData = JSON.parse(
            localStorage.getItem("reachSelectedPlanDetail") || "null"
          )?.plan;
        }
      }
      const lines = parseInt(customerDetails.extraLines) + 1;
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({ numberOfLines: lines, plan: planData })
      );
      localStorage.setItem("reachSelectedPlanLine", String(lines));
      dispatch(
        updatePlanDetails({ numberOfLines: lines, selectedPlan: planData })
      );
    }
    if (Config.IS_ESIM_SUPPORTED) {
      if (!simPrefStore || customerDetails?.linePreferences) {
        const linePreference: any = [];
        const simDataPref: any = [];
        const linePrefObjKeys = customerDetails?.linePreferences
          ? Object.keys(customerDetails?.linePreferences)
          : [];
        linePrefObjKeys.forEach((key) =>
          linePreference.push({
            name: key,
            value: customerDetails?.linePreferences[key],
          })
        );
        linePreference.forEach((data: any, index: number) =>
          simDataPref.push({
            id: index,
            eSimOpted: data.value.simPreference === "ESIM" ? true : false,
            eSimCompatible: data.value.simPreference === "ESIM" ? true : false,
            imei: data?.value?.imei || "",
            value:
              data.value.simPreference === "ESIM"
                ? SimTypes.eSim
                : SimTypes.sim,
            make: data.value.make ? data.value.make : "",
            model: data.value.model ? data.value.model : "",
            isPrimaryLine: data.name === "line1" ? true : false,
          })
        );
        localStorage.setItem("simPreference", JSON.stringify(simDataPref));
        setSimPreference(simDataPref);
      } else {
        let simPreferenceStorage = JSON.parse(
          localStorage.getItem("simPreference") || "[]"
        );
        setSimPreference(simPreferenceStorage);
      }
    }
  }, [getCustomer, landingPromoPlan]);

  const setUserDetails = (user: any) => {
    setuser(user);
    setIsLoggedIn(true);
    if (ENVConfig?.IS_SHOPWARE_ENABLED) {
      let searchParams = new URLSearchParams(document.location.search);
      let logoutCheck: string | null = searchParams.get("logout");
      if (!logoutCheck) {
        let currentuser: any = localStorage.getItem("currentuser");
        currentuser = JSON.parse(currentuser);
        if (currentuser && currentuser.email) {
          dispatch(getCartItems());
        }
      }
    }
  };

  /**
   * Checking coupon code and updating customer data in hubspot
   */
  useEffect(() => {
    if (
      createCustomer &&
      createCustomer.status === "SUCCESS" &&
      createCustomer.data &&
      formik?.isSubmitting
    ) {
      if (
        planDetails?.selectedPlan?.name === Config.SPECIAL_DISCOUNT_PLAN_NAME
      ) {
        let wowCustomerData = location.state;
        let data = {
          wowAccountNum: wowCustomerData?.wowAccountNumber,
          wowZipCode: wowCustomerData?.wowAccountServiceZipcode,
          wowLastName: wowCustomerData?.wowCustomerLastName,
          operation: "ADD",
          customerId: createCustomer.data.id,
          skipMAMCall: true,
        };
        dispatch(
          hsdAddApi({
            data,
          })
        );
      }
      dispatch(
        UpdateCustomerSource({
          customerId: createCustomer.data && createCustomer.data.id,
          data: { source: ENVConfig.OPERATOR },
        })
      );
      let address =
        createCustomer.data &&
        createCustomer.data.addresses &&
        createCustomer.data.addresses[0];
      dispatch(
        getInTouchApi({
          data: {
            emailId: createCustomer.data && createCustomer.data.emailId,
            hubspotMap: {
              email: createCustomer.data && createCustomer.data.emailId,
              firstname: createCustomer.data && createCustomer.data.firstName,
              lastname: createCustomer.data && createCustomer.data.lastName,
              is_test: Config?.IS_HUBSPOT_TEST_ENV,
              is_qa: Config?.IS_HUBSPOT_QA_ENV,
              address: address && address.address1,
              city: address && address.city,
              state: address && address.state,
              zip: address && address.zip,
              country: address && address.country,
              website_journey_score: 7,
              web_address_validation_failed: "",
            },
          },
        })
      );
      if (
        coupon?.coupon &&
        planDetails?.selectedPlan?.name !== Config.SPECIAL_DISCOUNT_PLAN_NAME
      ) {
        dispatch(
          CouponReserve({
            couponId: coupon.coupon,
            customerId: createCustomer.data && createCustomer.data.id,
          })
        );
      }
      const checkCoverageStoreAddress = JSON.parse(
        localStorage.getItem("checkCoverageAddress") || "null"
      );
      if (checkCoverageStoreAddress) {
        localStorage.removeItem("checkCoverageAddress");
      }
    }
  }, [createCustomer]);

  const initialRender2 = useRef(true);
  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (
        updateCustomer &&
        updateCustomer.status === "SUCCESS" &&
        updateCustomer.data &&
        formik?.isSubmitting
      ) {
        if (
          coupon?.coupon &&
          planDetails?.selectedPlan?.name !== Config.SPECIAL_DISCOUNT_PLAN_NAME
        ) {
          dispatch(
            CouponReserve({
              couponId: coupon?.coupon,
              customerId: updateCustomer.data && updateCustomer.data.id,
            })
          );
        }
        let address =
          updateCustomer.data &&
          updateCustomer.data.addresses &&
          updateCustomer.data.addresses[0];

        dispatch(
          getInTouchApi({
            data: {
              emailId: updateCustomer.data && updateCustomer.data.emailId,
              hubspotMap: {
                email: updateCustomer.data && updateCustomer.data.emailId,
                firstname: updateCustomer.data && updateCustomer.data.firstName,
                lastname: updateCustomer.data && updateCustomer.data.lastName,
                is_test: Config?.IS_HUBSPOT_TEST_ENV,
                is_qa: Config?.IS_HUBSPOT_QA_ENV,
                address: address && address.address1,
                city: address && address.city,
                state: address && address.state,
                zip: address && address.zip,
                country: address && address.country,
                website_journey_score: 7,
                web_address_validation_failed: "",
              },
            },
          })
        );
        if (
          (formRef.current && !simInHand && !simId) ||
          (formRef.current && !simInHand) ||
          (formRef.current &&
            simInHand &&
            simId &&
            ENVConfig?.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND)
        ) {
          localStorage.setItem("userPaymentStatus", "yes");
          formRef.current.submit();
        } else {
          dispatch(resetCheckoutstate());
          formRef?.current?.reset();
          /**
           * For SIM in hand case, after customer creation user should redirect to Activation page
           */
          navigate(RoutingURLs.activation);
          setPaymentClicked(false);
        }
      }
    }
  }, [updateCustomer]);

  const initialRender3 = useRef(true);
  useEffect(() => {
    if (initialRender3.current) {
      initialRender3.current = false;
    } else {
      if (
        errorGetCustomer &&
        errorGetCustomer.status === "FAILURE" &&
        planDetails
      ) {
        setNewUser(true);
        if (!ENVConfig.IS_SSO_LOGIN) {
          let user1: any = localStorage.getItem("currentuser");

          user1 = JSON.parse(user1);
          let name: string = user1 && user1.displayName;
          let firstName: string =
            name && name.split(" ") && name.split(" ")[0]
              ? name.split(" ")[0]
              : "";
          let lastName: string =
            name && name.split(" ") && name.split(" ")[1]
              ? name.split(" ")[1]
              : "";
          let email: string = user1 && user1.email ? user1.email : "";
          getUserDetailsFromFirebase(email, firstName, lastName);
        }
      }
    }
  }, [errorGetCustomer, planDetails]);

  const createUserInFirebaseDatabase = (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    const user = firebase
      .database()
      .ref(FirebaseDBURLs.userWebJourney)
      .child(Buffer.from(email).toString("base64"));
    let sendOption: any = sendSimOptions?.find(
      (el: any) => (el && el.value) === formik.values.shipmentPlan
    );
    sendOption &&
      user.set({
        emailId: email,
        firstName: firstName,
        lastName: lastName,
        extraLines: planDetails.numberOfLines - 1,
        reachPlanId: planDetails?.selectedPlan?.name,
        selectedPlan: {
          line: planDetails.numberOfLines,
          plan: planDetails?.selectedPlan,
        },
        addresses: [],
        deliveryOption: formik.values.shipmentPlan,
        flowVersion: 1,
        isMNP: false,
        isPrimary: true,
        isSimInHand: false,
        primaryNumber: formik.values.phone
          ? formik.values.phone.match(numberInStringPattern).join("")
          : "",
        selectedShippingMethod: sendOption?.label,
        status: 0,
        x_description: null,
        zipcode: "",
      });
  };

  const getUserDetailsFromFirebase = (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    firebase
      .database()
      .ref(
        FirebaseDBURLs.userWebJourney + Buffer.from(email).toString("base64")
      )
      .once("value")
      .then(
        async function (snapshot) {
          if (!snapshot.val()) {
            createUserInFirebaseDatabase(email, firstName, lastName);
          } else {
            let userData = snapshot.val();
            setFieldUpdates(userData, userData?.deliveryOption);
          }
        },
        function (error) {
          console.log(error);
        }
      );
  };
  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
      if (
        updateCustomerSource &&
        updateCustomerSource.status === "SUCCESS" &&
        formik?.isSubmitting
      ) {
        localStorage.setItem("userPaymentStatus", "yes");
        if (
          (formRef.current && !simInHand && !simId) ||
          (formRef.current && !simInHand) ||
          (formRef.current &&
            simInHand &&
            simId &&
            ENVConfig?.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND)
        ) {
          localStorage.setItem("userPaymentStatus", "yes");
          formRef.current.submit();
        } else {
          /**
           * For SIM in hand case, after customer creation user should redirect to Activation page
           */
          dispatch(resetCheckoutstate());
          formRef?.current?.reset();
          navigate(RoutingURLs.activation);
          setPaymentClicked(false);
        }
      }
    }
  }, [updateCustomerSource]);

  useEffect(() => {
    if (errorCreateCustomer && errorCreateCustomer.status === "FAILURE") {
      if (
        errorCreateCustomer?.resCode === 40305 ||
        errorCreateCustomer?.resCode === 40020
      ) {
        NotificationCust({
          message: BrandNameUpdate(addressContentState?.user_cre_err),
          type: "danger",
          duration: 0,
          id: "errorCreateCustomerId",
          showIcon: true,
        });
      }
      setPaymentClicked(false);
    }
  }, [errorCreateCustomer]);

  useEffect(() => {
    if (errorUpdateCustomer && errorUpdateCustomer.status === "FAILURE") {
      setPaymentClicked(false);
    }
  }, [errorUpdateCustomer]);

  useEffect(() => {
    let planStoreData: any = localStorage.getItem("reachSelectedPlanDetail");
    planStoreData = JSON.parse(planStoreData);
    if (getCustomer) {
      if (ENVConfig.IS_OFFER_CODE_DISCOUNT_APPLICABLE) {
        dispatch(getCustomerCouponReserve(getCustomer.id)).then(
          (response: any) => {
            const couponId: string = response?.payload?.data?.data?.couponId;
            if (response?.payload?.status === 200 && couponId) {
              setCustomerReservedCoupon(response?.payload?.data?.data);
              dispatch(Coupon({ couponCode: couponId }));
            }
          }
        );
      }
      if (currentdetails) {
        if (currentdetails.simPaid && currentdetails.status == 0) {
          navigate(RoutingURLs.myAccount);
        } else if (!currentdetails.simPaid) {
          let shippingDetails = currentdetails.selectedShippingMethod
            .serviceCode
            ? currentdetails.selectedShippingMethod.serviceCode
            : sendSimOptions?.[0]?.value;
          formik.setFieldValue("shipmentPlan", shippingDetails);
          dispatch(
            updateFormValues({
              shipmentPlan: shippingDetails,
            })
          );
        } else if (
          currentdetails.simPaid &&
          (currentdetails.status === 1 ||
            currentdetails.status === 2 ||
            currentdetails.status === 4 ||
            currentdetails.status === 5 ||
            currentdetails.status === 8 ||
            currentdetails.status === 9 ||
            currentdetails.status === 10)
        ) {
          navigate(RoutingURLs.postActivationHome);
        }
        setSimPaid(currentdetails.simPaid ? currentdetails.simPaid : false);
      }
      window.scroll(0, 0);
      if (currentdetails?.reachPlanId) {
        dispatch(
          ReachPlan({
            plan: currentdetails.reachPlanId,
          })
        );
      }
      setFieldUpdates(
        getCustomer,
        getCustomer?.selectedShippingMethod?.serviceCode
      );
      setNewUser(false);
    } else {
      if (ENVConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE) {
        let offerPromoCode = planStoreData?.plan?.isUnlimited
          ? UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.coupon
          : SHARABLE_PLAN_DISCOUNT_COUPON?.data?.coupon;
        if (
          planStoreData?.plan?.name === ENVConfig.SPECIAL_DISCOUNT_PLAN_NAME
        ) {
          offerPromoCode = planStoreData?.plan?.wowCoupon;
        }
        if (offerPromoCode) {
          dispatch(
            Coupon({
              couponCode: offerPromoCode,
            })
          );
        }
      }
    }
    if (isEmpty(planStoreData)) {
      navigate(RoutingURLs.default);
    }
  }, [getCustomer, planDetails]);

  const setFieldUpdates = (data: any, shipmentPlan: any) => {
    // let data = snapshot.val();
    let formState: any = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.primaryNumber ? data?.primaryNumber : formik.values.phone,
    };
    formik.setFieldValue("firstName", data?.firstName);
    formik.setFieldValue("lastName", data?.lastName);
    if (shipmentPlan) {
      formState = {
        ...formState,
        shipmentPlan: formValues.shipmentPlan,
      };
      let shipmentMethod: any =
        currentdetails &&
        currentdetails?.selectedShippingMethod &&
        currentdetails?.selectedShippingMethod?.serviceCode
          ? currentdetails?.selectedShippingMethod?.serviceCode
          : formValues?.shipmentPlan;
      formik.setFieldValue("shipmentPlan", shipmentMethod);
    }
    formik.setFieldValue(
      "phone",
      data?.primaryNumber ? data?.primaryNumber : formik.values.phone
    );
    let billingAddressData =
      data?.addresses && data?.addresses[0]
        ? cloneDeep(data?.addresses[0])
        : null;
    let shippingAddressData =
      data?.addresses && data?.addresses[1]
        ? cloneDeep(data?.addresses[1])
        : null;
    if (billingAddressData && shippingAddressData) {
      delete billingAddressData.type;
      delete shippingAddressData.type;
      formik.setFieldValue("billingAddress", {
        ...billingAddressData,
        type: addressType.billing,
      });
      formik.setFieldValue("shippingAddress", {
        ...shippingAddressData,
        type: addressType.shipping,
      });
      formState = {
        ...formState,
        billingAddress: {
          ...billingAddressData,
          type: addressType.billing,
        },
        shippingAddress: {
          ...shippingAddressData,
          type: addressType.shipping,
        },
      };
      if (
        JSON.stringify(billingAddressData) ===
        JSON.stringify(shippingAddressData)
      ) {
        formik.setFieldValue("isShippingSameAsBilling", true);
        formState = {
          ...formState,
          isShippingSameAsBilling: true,
        };
      } else {
        formState = {
          ...formState,
          isShippingSameAsBilling: false,
        };
        formik.setFieldValue("isShippingSameAsBilling", false);
      }
    }
    if (data?.isSimInHand && data?.simId) {
      setSimInHand(data?.isSimInHand);
      setSimId(data?.simId);
      if (currentdetails?.extraLines + 1 === planDetails?.numberOfLines) {
        dispatch(
          setSIMVerificationState({
            error: false,
            isSuccess: true,
          })
        );
      }
    }
    dispatch(
      updateFormValues({
        ...formState,
      })
    );
  };

  const handlePaymentFailure = (user: any) => {
    if (location.state && location.state.code) {
      NotificationCust({
        message: getMessageFromCode(location.state.code),
        type: "danger",
        duration: 2000,
        id: "LoginNotification",
        showIcon: true,
      });

      dispatch(
        getInTouchApi({
          data: {
            emailId: user && user.email,
            hubspotMap: {
              email: user && user.email,
              is_test: Config?.IS_HUBSPOT_TEST_ENV,
              is_qa: Config?.IS_HUBSPOT_QA_ENV,
              website_purchase_failed: true,
            },
          },
        })
      );
    }
  };
  const getLoggedUser = () => {
    const auth = getAuth();
    // onAuthStateChanged(auth, (user: any) => {
    let user1: any = localStorage.getItem("currentuser");
    onAuthStateChanged(auth, (user: any) => {
      user1 = JSON.parse(user1);
      if (user1) {
        setuser(user1);
        if (user1?.displayName) {
          setIsLoggedIn(true);
          let name: string = user1 && user1.displayName;
          let firstName: string =
            name && name.split(" ") && name.split(" ")[0]
              ? name.split(" ")[0]
              : "";
          let lastName: string =
            name && name.split(" ") && name.split(" ")[1]
              ? name.split(" ")[1]
              : "";
          formik.setFieldValue("firstName", firstName);
          formik.setFieldValue("lastName", lastName);
          dispatch(
            updateFormValues({
              firstName,
              lastName,
            })
          );
          let email: string = user1 && user1.email ? user1.email : "";
          email &&
            dispatch(
              GetCustomer({
                username: encodeURIComponent(email),
              })
            );
          email &&
            ENVConfig.IS_OFFER_CODE_DISCOUNT_APPLICABLE &&
            dispatch(
              Eligibility({
                emailId: encodeURIComponent(email),
              })
            );
          handlePaymentFailure(user1);
        } else {
          setIsLoggedIn(false);
        }
        // }
      }
    });
  };
  const getSSOLoggedUser = () => {
    let ssoCustomerDetails: any = localStorage.getItem("ssoCustomerDetails");
    ssoCustomerDetails = JSON.parse(ssoCustomerDetails);
    if (ssoCustomerDetails?.firstName) {
      setIsLoggedIn(true);
      let firstName: string = ssoCustomerDetails?.firstName;
      let lastName: string = ssoCustomerDetails?.lastName;
      formik.setFieldValue("firstName", firstName);
      formik.setFieldValue("lastName", lastName);
      dispatch(
        updateFormValues({
          firstName,
          lastName,
        })
      );
      let email: string =
        ssoCustomerDetails && ssoCustomerDetails.email
          ? ssoCustomerDetails.email
          : ssoCustomerDetails.username;
      email &&
        dispatch(
          GetCustomer({
            username: encodeURIComponent(email),
          })
        );
      email &&
        ENVConfig.IS_OFFER_CODE_DISCOUNT_APPLICABLE &&
        dispatch(
          Eligibility({
            emailId: encodeURIComponent(email),
          })
        );
    }
  };
  useEffect(() => {
    fetchShipmentPlans();
  }, []);
  useEffect(() => {
    if (ENVConfig?.IS_SSO_LOGIN) {
      getSSOLoggedUser();
    } else {
      getLoggedUser();
    }
  }, [isLoggedIn]);
  useEffect(() => {
    setShipmentPlanDetails();
  }, [newUser, isLoggedIn, sendSimOptions, getCustomer]);
  useEffect(() => {
    if (
      (isSIMVerificationSuccess && simInHand && !currentdetails) ||
      (currentdetails?.simId &&
        currentdetails?.isSimInHand &&
        isSIMVerificationSuccess &&
        simInHand) ||
      (currentdetails &&
        simInHand &&
        isSIMVerificationSuccess &&
        !isAllowedMultipleLineSelectionForSIMInHand())
    ) {
      resetNumberSelection();
    }
  }, [isSIMVerificationSuccess, simInHand]);
  useEffect(() => {
    if (
      Config?.IS_SIM_IN_HAND_ENABLED &&
      Config?.IS_ESIM_SUPPORTED &&
      simInHand &&
      isSIMVerificationSuccess &&
      simPreference?.[0]?.eSimCompatible
    ) {
      setSimId("");
      setSimInHand(false);
      dispatch(
        setSIMVerificationState({
          error: "",
          isSuccess: false,
        })
      );
    }
  }, [simPreference]);
  const fetchShipmentPlans = async () => {
    const shippingRate: any = await firebase
      .database()
      .ref(FirebaseDBURLs.shippingRates);
    shippingRate.on("value", (snapshot: any) => {
      const data = snapshot.val();
      let shipmentPlansList: any = [];
      if (data.length > 0) {
        data.forEach((el: any) => {
          shipmentPlansList.push({
            label: `${el && el.name} - $${el && el.shippingAmount.toFixed(2)}`,
            value: el && el.serviceCode,
            cost: el && el.shippingAmount,
            ...el,
          });
        });
      }
      setSendSimOptions(shipmentPlansList);
    });
  };
  const setShipmentPlanDetails = () => {
    if (sendSimOptions) {
      const shippingMethod = formik.values.shipmentPlan
        ? formik.values.shipmentPlan
        : sendSimOptions?.[0]?.value;
      const checkCoverageStoreAddress = JSON.parse(
        localStorage.getItem("checkCoverageAddress") || "null"
      );
      if (shippingMethod && !checkCoverageStoreAddress) {
        formik.setFieldValue("shipmentPlan", shippingMethod);
        dispatch(updateFormValues({ shipmentPlan: shippingMethod }));
      } else {
        if (checkCoverageStoreAddress) {
          const billingAddressStoreData = {
            ...checkCoverageStoreAddress,
            type: addressType.billing,
          };
          const shippingAddressStoreData = {
            ...checkCoverageStoreAddress,
            type: addressType.shipping,
          };
          formik.setFieldValue("billingAddress", billingAddressStoreData);
          formik.setFieldValue("shippingAddress", shippingAddressStoreData);
          if (shippingMethod) {
            formik.setFieldValue("shipmentPlan", shippingMethod);
          }
          dispatch(
            updateFormValues({
              shipmentPlan: shippingMethod,
              billingAddress: billingAddressStoreData,
              shippingAddress: shippingAddressStoreData,
            })
          );
        }
      }
    }
  };
  const closeZipModal = () => {
    setZipcodeOpen(false);
    setShowBox(false);
  };
  const clickHandler = () => {
    formik.setFieldValue("billingAddress", {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    });
    formik.setFieldValue("shippingAddress", {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.shipping,
    });
    dispatch(resetBillingAddress());
    dispatch(resetShippingAddress());
    setZipcodeOpen(false);
  };

  /**
   * Dispatching coupon code when only customer is eligeble
   */
  useEffect(() => {
    if (eligibility && eligibility.status === "SUCCESS" && eligibility.data) {
      if (eligibility.data.coupon && !eligibility.data.expired) {
        dispatch(
          Coupon({
            couponCode: eligibility.data.coupon,
          })
        );
      }
    }
  }, [eligibility]);

  if (formik?.isSubmitting) {
    const firstErrorField: any = Object.keys(formik?.touched)?.find((field) => {
      return formik?.touched[field] && formik?.errors[field];
    });
    if (firstErrorField) {
      if (firstErrorField === "billingAddress") {
        inputRefs?.billingAddress?.address1?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "end",
        });
      }
      if (firstErrorField === "shippingAddress") {
        inputRefs?.shippingAddress?.address1?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      inputRefs[firstErrorField]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }
  const onRequestConfirmPayment = () => {
    setDisplaySaveCardAlertDialog(false);
    confirmProceedPayment();
  };
  const confirmProceedPayment = () => {
      proceedToPaymentHandler(formik.values);
      firebaseNameUpdateHandler();
      updateUserInFirebaseDatabase();
  };
  const onCloseAlertSaveCardDialog = () => {
    setDisplaySaveCardAlertDialog(false);
  };
  const updateUserInFirebaseDatabase = async () => {
    await firebase
      .database()
      .ref(FirebaseDBURLs.userWebJourney + window.btoa(user && user.email))
      .once("value")
      .then(() => {
        let shipmentPlanObj: any = sendSimOptions?.find(
          (el: any) => (el && el.value) === formik.values.shipmentPlan
        );
        const updatedNewUserData = {
          extraLines: planDetails?.numberOfLines - 1,
          reachPlanId: planDetails?.selectedPlan?.name,
          selectedShippingMethod: shipmentPlanObj,
          selectedPlan: {
            line: planDetails.numberOfLines,
            plan: planDetails?.selectedPlan,
          },
          addresses: [
            formik.values.billingAddress,
            formik.values.shippingAddress,
          ],
        };
        firebase
          .database()
          .ref(FirebaseDBURLs.userWebJourney)
          .child(window.btoa(user && user.email))
          .update(updatedNewUserData);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const proceedToPaymentHandler = (data: any) => {
    let sendOption: any = sendSimOptions?.find(
      (el: any) => (el && el.value) === formik.values.shipmentPlan
    );
    let address: any = [
      {
        name: `${data.firstName} ${data.lastName}`,
        address1: data.billingAddress.address1,
        address2: data.billingAddress.address2,
        city: data.billingAddress.city,
        country: "USA",
        phone: data.phone.match(/\d/g).join(""),
        state: data.billingAddress.state,
        type: data.billingAddress.type,
        zip: data.billingAddress.zip,
      },
      {
        address1: data.isShippingSameAsBilling
          ? data.billingAddress.address1
          : data.shippingAddress.address1,
        address2: data.isShippingSameAsBilling
          ? data.billingAddress.address2
          : data.shippingAddress.address2,
        city: data.isShippingSameAsBilling
          ? data.billingAddress.city
          : data.shippingAddress.city,
        country: data.billingAddress.country,
        name: `${data.firstName} ${data.lastName}`,
        phone: data.phone.match(/\d/g).join(""),
        state: data.isShippingSameAsBilling
          ? data.billingAddress.state
          : data.shippingAddress.state,
        type: data.shippingAddress.type,
        zip: data.isShippingSameAsBilling
          ? data.billingAddress.zip
          : data.shippingAddress.zip,
      },
    ];
    let selectedShippingMethod: any = {
      carrierCode: sendOption && sendOption.carrierCode,
      deliveryDays: sendOption && sendOption.deliveryDays,
      name: sendOption && sendOption.name,
      reachCarrierId: sendOption && sendOption.reachCarrierId,
      serviceCode: sendOption && sendOption.serviceCode,
      serviceType: sendOption && sendOption.serviceType,
      shippingAmount: sendOption && sendOption.shippingAmount,
      taxAmount: oneTimeShippingTaxCharges?.totalTax,
    };
    let simPreferenceStoreData: any = [];
    let linePreferenceData: any = {};
    let numOfEsimOrdered = 0;
    let numOfPsimOrdered = 0;
    if (Config.IS_ESIM_SUPPORTED) {
      simPreferenceStoreData = JSON.parse(
        localStorage.getItem("simPreference") || "[]"
      );
      linePreferenceData = {};
      simPreferenceStoreData.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei?.toString(),
          simPreference:
            data?.value === SimTypes.eSim ? "ESIM" : "PHYSICAL_SIM",
          make: data?.make,
          model: data?.model,
          customerCreated: index === 0,
        };
      });
      numOfEsimOrdered = simPreferenceStoreData?.filter(
        (data: any) => data?.value === SimTypes?.eSim
      ).length;
      numOfPsimOrdered = simPreferenceStoreData?.filter(
        (data: any) => data?.value === SimTypes?.sim
      ).length;
    }
    let customerData: any = {
      countryCode: data.billingAddress.country,
      firstName: data.firstName,
      lastName: data.lastName,
      isMNP: false,
      isPrimary: true,
      primaryNumber: data.phone.match(/\d/g).join(""),
      addresses: address,
      flowVersion: 1,
      status: 0,
      emailId: (user && user.email) || user?.username,
      reachPlanId: planDetails?.selectedPlan?.name,
      isSimInHand: false,
      extraLines: planDetails.numberOfLines - 1,
      selectedShippingMethod: selectedShippingMethod,
      zipcode: data.billingAddress.zip,
    };
    if (Config.IS_ESIM_SUPPORTED) {
      customerData = {
        ...customerData,
        esim:
          simPreferenceStoreData[0]?.value === SimTypes?.eSim ? true : false,
        activationJourneyStatus: ActivationStatus.NOT_STARTED,
        physicalSimsOrdered: numOfPsimOrdered,
        eSimsOrdered: numOfEsimOrdered,
        linePreferences: linePreferenceData,
        imei: simPreferenceStoreData[0]?.imei,
        make: simPreferenceStoreData[0]?.make,
        model: simPreferenceStoreData[0]?.model,
      };
    }
    if (Config.IS_APN_SETTINGS_ENABLED) {
      customerData = {
        ...customerData,
        imei:
          compatibility?.data?.data?.imei || simPreferenceStoreData[0]?.imei,
        make:
          compatibility?.data?.data?.make || simPreferenceStoreData[0]?.make,
        model:
          compatibility?.data?.data?.model || simPreferenceStoreData[0]?.model,
        osVer: compatibility?.data?.data?.osVer || "",
        os: compatibility?.data?.data?.os || "",
      };
    }
    if (Config.IS_PROMO_CODE_DISCOUNT_SEPARATE && newUser) {
      customerData = {
        ...customerData,
        isWowHSDCustomer:
          planDetails?.selectedPlan?.name !== Config.SPECIAL_DISCOUNT_PLAN_NAME
            ? false
            : true,
        wowCustomerId:
          planDetails?.selectedPlan?.name !== Config.SPECIAL_DISCOUNT_PLAN_NAME
            ? null
            : location.state?.ecid,
        wowHsdState:
          planDetails?.selectedPlan?.name !== Config.SPECIAL_DISCOUNT_PLAN_NAME
            ? location.state?.wowHsdState
            : null,
      };
    }
    if (newUser) {
      dispatch(
        CreateCustomer({
          ...customerData,
          isSimInHand:
            isSIMVerificationSuccess &&
            simInHand &&
            simPreferenceStoreData?.[0]?.value !== SimTypes?.eSim
              ? true
              : false,
          simId:
            simInHand &&
            simId &&
            simPreferenceStoreData?.[0]?.value !== SimTypes?.eSim
              ? simId
              : "",
        })
      );
      if (ENVConfig?.IS_SHOPWARE_ENABLED) shopwareCreateAddress(data);
    } else {
      let userData: any = getCustomer ? cloneDeep(getCustomer) : null;
      const updatedUserData = {
        ...userData,
        ...customerData,
        isSimInHand: simInHand ? true : false,
        simId: simInHand && simId ? simId : "",
        onlyShopDevices: false,
      };
      if (updatedUserData) {
        if (ENVConfig?.IS_SHOPWARE_ENABLED)
          shopwareUpdateAddress(updatedUserData, data);
        dispatch(UpdateCustomer(updatedUserData)).then((response: any) => {
          if (
            response?.payload?.status === 200 &&
            coupon &&
            customerReservedCoupon &&
            customerReservedCoupon.couponId !== coupon.coupon &&
            planDetails?.selectedPlan?.name !==
              Config.SPECIAL_DISCOUNT_PLAN_NAME
          ) {
            dispatch(
              CouponReserve({
                couponId: coupon.coupon,
                customerId: userData.id,
              })
            );
          }
        });
      }
    }
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Proceed To Payment",
    //   label: couponCode,
    // });
    localStorage.removeItem("isWowDiscountEligible");
    setPaymentClicked(true);
  };

  const getSelectedStateId = (stateCode: any) => {
    let stateName: any = findStateByCode(stateCode);
    let stateSelected: any = fetchStatesSuccess?.find(
      (ele: any) => ele.attributes.name === stateName
    );
    return stateSelected;
  };
  const findStateByCode = (code: string) => {
    let state = usaState?.find(
      (el: any) => el.value.toLowerCase() === code.toLowerCase()
    );
    return state && state.label;
  };
  const shopwareCreateAddress = async (data: any) => {
    let shopwareCustomer: any = getCustomerFromShopwareSuccess;
    let selectedState: any = await getSelectedStateId(
      data?.billingAddress?.state
    );
    let payload: any = {
      customerId: shopwareCustomer?.id,
      countryId: countryId,
      countryStateId: selectedState?.id,
      salutationId: shopwareCustomer?.salutationId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      zipcode: data?.billingAddress?.zip,
      street: data?.billingAddress?.address1,
      title: "",
      city: data?.billingAddress?.city,
      phoneNumber: data.phone,
      company: "",
      department: "",
      additionalAddressLine2: "",
      additionalAddressLine1: data?.billingAddress?.address2 ?? "",
    };
    if (data?.isShippingSameAsBilling) {
      dispatch(createAddressOnShopware(payload)).then((res: any) => {
        if (res) {
          dispatch(defaultShippingAddressOnShopware(res?.payload?.data?.id));
        }
      });
    } else {
      createBillingAndShippingOnShopware(data, payload);
    }
  };

  const createBillingAndShippingOnShopware = async (
    data: any,
    billingPayload: any
  ) => {
    let shopwareCustomer: any = getCustomerFromShopwareSuccess;
    let selectedShippingState: any = await getSelectedStateId(
      data?.billingAddress?.state
    );
    let ShippingPayload: any = {
      customerId: shopwareCustomer?.id,
      countryId: countryId,
      countryStateId: selectedShippingState?.id,
      salutationId: shopwareCustomer?.salutationId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      zipcode: data?.shippingAddress?.zip,
      street: data?.shippingAddress?.address1,
      title: "",
      city: data?.shippingAddress?.city,
      phoneNumber: data.phone,
      company: "",
      department: "",
      additionalAddressLine2: "",
      additionalAddressLine1: data?.shippingAddress?.address2 ?? "",
    };
    dispatch(createAddressOnShopware(billingPayload));
    dispatch(createAddressOnShopware(ShippingPayload)).then((res: any) => {
      if (res) {
        dispatch(defaultShippingAddressOnShopware(res.payload.data.id));
      }
    });
  };
  const shopwareUpdateAddress = async (data: any, formikValues: any) => {
    let shopwareCustomer: any = getCustomerFromShopwareSuccess;
    let selectedStateId: any = await getSelectedStateId(
      data?.addresses?.[0]?.state
    );
    let addressId: any = await findAdressIdBilling(data, selectedStateId);
    const payload: any = {
      customerId: shopwareCustomer?.id,
      countryId: countryId,
      countryStateId: selectedStateId?.id,
      salutationId: shopwareCustomer?.salutationId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      zipcode: data?.addresses?.[0]?.zip,
      street: data?.addresses?.[0]?.address1,
      title: "",
      city: data?.addresses?.[0]?.city,
      phoneNumber: data.primaryNumber,
      company: "",
      department: "",
      additionalAddressLine2: "",
      additionalAddressLine1: data?.addresses?.[0]?.address2 ?? "",
      addressId: addressId ? addressId : "",
    };
    if (formikValues?.isShippingSameAsBilling) {
      dispatch(updateAddressOnShopware(payload)).then((res: any) => {
        if (res) {
          dispatch(defaultShippingAddressOnShopware(res?.payload?.data?.id));
        }
      });
    } else {
      updateBillingAndShippingOnShopware(data, payload);
    }
  };

  const findAdressIdBilling = async (data: any, selectedStateId: any) => {
    const billingAddress: any = {
      countryId: countryId,
      countryStateId: selectedStateId?.id,
      zipcode: data?.addresses?.[0]?.zip,
      street: data?.addresses?.[0]?.address1,
      city: data?.addresses?.[0]?.city,
    };
    let selectedBillingAddressId: any;
    if (addressListOfShopware?.length) {
      const customerBillingAddress: any = addressListOfShopware?.find(
        (address: any) => {
          return Object.keys(billingAddress).every(
            (field) =>
              billingAddress[field] === address[field] ||
              (address[field] === null && billingAddress[field] === "")
          );
        }
      );
      selectedBillingAddressId = customerBillingAddress.id;
    }
    return selectedBillingAddressId;
  };
  const updateBillingAndShippingOnShopware = async (
    data: any,
    billingPayload: any
  ) => {
    let shopwareCustomer: any = getCustomerFromShopwareSuccess;
    let selectedShippingState: any = await getSelectedStateId(
      data?.addresses?.[1]?.state
    );
    let ShippingPayload: any = {
      customerId: shopwareCustomer?.id,
      countryId: countryId,
      countryStateId: selectedShippingState?.id,
      salutationId: shopwareCustomer?.salutationId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      zipcode: data?.addresses?.[1]?.zip,
      street: data?.addresses?.[1]?.address1,
      title: "",
      city: data?.addresses?.[1]?.city,
      phoneNumber: data.primaryNumber,
      company: "",
      department: "",
      additionalAddressLine2: "",
      additionalAddressLine1: data?.addresses?.[1]?.address2 ?? "",
    };
    dispatch(updateAddressOnShopware(billingPayload));
    dispatch(updateAddressOnShopware(ShippingPayload)).then((res: any) => {
      if (res) {
        dispatch(defaultShippingAddressOnShopware(res.payload.data.id));
      }
    });
  };
  const firebaseNameUpdateHandler = () => {
    const user: any = firebase.auth().currentUser;
    user
      .updateProfile({
        displayName: `${formik.values.firstName} ${formik.values.lastName}`,
        phoneNumber: formik.values.phone.replace(/^\D+/g, ""),
      })
      .then(async () => {
        let user1: any = firebase.auth().currentUser;
        if (!user1?.isAnonymous) {
          let details = JSON.stringify(user1);
          localStorage.setItem("currentuser", details);
          details = JSON.parse(details);
          setuser(details);
        }
      })
      .catch((error: any) => {
        NotificationCust({
          message: error && error.message,
          type: "danger",
          duration: 2000,
          id: "UserUpdateInFirebase",
          showIcon: true,
        });
      });
  };
  const onChangeAutoPayment = (e: any) => {
    setIsAutoPay(e.target.checked);
    if (e.target.checked) {
      setIsSaveCard(true);
    }
  };
  const onChangeSaveCard = (e: any) => {
    if (!isAutoPay) {
      setIsSaveCard(e.target.checked);
    }
  };
  const onSubmitSimHandler = () => {
    dispatch(
      validateSIMNumber({
        iccId: simId,
        mvne: ENVConfig.OPERATOR,
      })
    );
  };
  const resetNumberSelection = () => {
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: 1,
        plan: planDetails.selectedPlan,
      })
    );
    localStorage.setItem("reachSelectedPlanLine", JSON.stringify(1));
    dispatch(updatePlanDetails({ numberOfLines: 1 }));
    if (Config?.IS_ESIM_SUPPORTED) {
      let simPreferenceStore: any = JSON.parse(
        localStorage.getItem("simPreference") || "[]"
      );
      const resetedSimPreferenceData = [
        {
          id: 0,
          eSimOpted: false,
          eSimCompatible: false,
          imei: simPreferenceStore?.[0]?.imei
            ? simPreferenceStore?.[0]?.imei
            : "",
          make: simPreferenceStore?.[0]?.make
            ? simPreferenceStore?.[0]?.make
            : "",
          model: simPreferenceStore?.[0]?.model
            ? simPreferenceStore?.[0]?.model
            : "",
          value: SimTypes.sim,
          isPrimaryLine: true,
        },
      ];
      localStorage.setItem(
        "simPreference",
        JSON.stringify(resetedSimPreferenceData)
      );
      setSimPreference(resetedSimPreferenceData);
    }
  };

  const isAllowedMultipleLineSelectionForSIMInHand = () => {
    let simPreferenceStoreData: any = JSON.parse(
      localStorage.getItem("simPreference") || "[]"
    );
    if (
      Config?.IS_SIM_IN_HAND_ENABLED &&
      simInHand &&
      simPreferenceStoreData?.[0]?.eSimOpted &&
      planDetails?.selectedPlan?.maxLines > 1
    ) {
      return true;
    } else if (
      Config?.IS_SIM_IN_HAND_ENABLED &&
      isSIMVerificationSuccess &&
      simInHand &&
      !currentdetails
    ) {
      return false;
    } else if (
      simInHand &&
      currentdetails?.simId &&
      currentdetails?.isSimInHand &&
      currentdetails?.extraLines + 1 === planDetails?.numberOfLines
    ) {
      return false;
    } else if (
      currentdetails &&
      simInHand &&
      isSIMVerificationSuccess &&
      planDetails?.selectedPlan?.maxLines > 1
    ) {
      return false;
    } else {
      return true;
    }
  };
  const fetchStatesFromShopware = (countryId: any) => {
    dispatch(fetchStates(countryId)).then((res: any) => {
      if (res) {
      }
    });
  };
  const cartHandler = () => {
    localStorage.removeItem("typeNotify");
    shopWareRedirectionHandler(ENVConfig?.SHOP_DOMAIN, "cart");
  };
  const simInHandCheck: any =
    (Config?.IS_SIM_IN_HAND_ENABLED && simInHand && isSIMVerificationSuccess) ||
    (simInHand && currentdetails?.simId && currentdetails?.isSimInHand);
  return addressContentState && reviewContentState ? (
    <>
      {ENVConfig?.IS_SHOPWARE_ENABLED && (
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              color: "#000",
              backgroundColor: "var(--primary_color)",
              height: "66px",
              width: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontFamily: "var(--font_family_Bold)",
                color: "#fff",
                textAlign: "center",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginRight: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "20px",
                },
              }}
            >
              {reviewContentState?.res?.device_updrade_text}
            </Typography>
            <Box>
              <ButtonCust
                sx={{
                  fontFamily: "var(--font_family_Bold) !important",
                  width: "130px !important",
                  height: "40px !important",
                  border: "2px solid #fff !important",
                  backgroundColor: "var(--primary_color) !important",
                  borderRadius: "22px",
                  color: "#fff !important",
                }}
                onClick={() =>
                  shopWareRedirectionHandler(ENVConfig?.SHOP_DOMAIN, "home")
                }
              >
                {reviewContentState?.res?.visit_our_store_btn}
              </ButtonCust>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        container
        sx={{
          width: "100%",
          mx: "auto",
          p: { xs: "25px 2%", sm: "30px 5%" },
          background: { xs: "var(--white)", sm: "var(--lightgrey)" },
        }}
      >
        <form
          ref={formRef}
          method="post"
          name="paymentForm"
          id="paymentForm"
          action={Config.CHECKOUT_PAYMENT}
        >
          <input
            type="hidden"
            name="emailId"
            value={
              user?.email ? user.email : user?.username ? user.username : ""
            }
          />
          <input
            type="hidden"
            name="redirectUrl"
            value={`${window.location.origin}/loader`}
          />
          {ENVConfig?.TENANT_ID ? (
            <input type="hidden" name="tenant" value={ENVConfig?.TENANT_ID} />
          ) : null}
          {ENVConfig?.IS_AUTOPAY_ENABLED ? (
            <>
              <input type="hidden" name="saveCard" value={String(isSaveCard)} />
              <input type="hidden" name="autoPay" value={String(isAutoPay)} />
              <input
                type="hidden"
                name="defaultCard"
                value={String(isAutoPay)}
              />
            </>
          ) : null}
          {ENVConfig?.IS_MSG_CENTER_ENABLED ? (
            <input
              type="hidden"
              name="opsMessageId"
              value={priorityOpsMessageId ? priorityOpsMessageId : ""}
            />
          ) : null}
        </form>
        {isLoaded || isLoadedPlanTaxCharges || isLoadedShippingTaxes ? (
          <div className={styles.loader_checkOut}>
            <div>{addressContentState?.loader_txt}</div>
          </div>
        ) : null}
        {ENVConfig.IS_DISPLAY_STEPPER_IN_CHECKOUT && (
          <Grid
            item
            xs={12}
            mb={"45px"}
            pt={"48px"}
            mt={isLoggedIn ? "20px" : 0}
          >
            <DeeperStepperCust
              steps={updatedCheckoutSteps}
              stepperBarClassName={styles.checkoutStepper}
              firstName={formik?.values.firstName}
              avatar={isLoggedIn ? true : false}
              currentLevel={activeStep}
            />
          </Grid>
        )}
        {/* {addressContentState?.back_icon?.data?.attributes?.url &&
        addressContentState?.back_btn_link_txt ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              position: "sticky",
              top: 72,
              zIndex:
                isLoaded || isLoadedPlanTaxCharges || isLoadedShippingTaxes
                  ? 1000
                  : 500,
              opacity:
                isLoaded || isLoadedPlanTaxCharges || isLoadedShippingTaxes
                  ? 0.5
                  : 1,
              // height: "15px",
              paddingY: "18px",
              alignItems: "center",
              background: "var(--lightgrey)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
                width: "fit-content",
                marginLeft: "15px",
              }}
              onClick={() => navigate(RoutingURLs.home)}
            >
              <Box
                component="img"
                alt={
                  addressContentState?.back_icon?.data?.attributes
                    ?.alternativeText
                }
                src={addressContentState?.back_icon?.data?.attributes?.url}
                width="18px"
                height="15px"
              />
              &nbsp;
              <Box>{addressContentState?.back_btn_link_txt}</Box>
            </Box>
          </Grid>
        ) : null} */}
        <Grid
          item
          xs={12}
          lg={7}
          order={{ md: 1, xs: 1 }}
          sx={{
            height: "calc(100%-19px)",
            overflowY: "auto",
            pr: { sm: "10px", xs: "0px" },
            pb: { sm: "0px", xs: "10px" },
          }}
        >
          <Box sx={{ background: "var(--white)", borderRadius: "20px" }}>
            <AddressInfo
              formik={formik}
              inputRefs={inputRefs}
              sendSimOptions={sendSimOptions}
              paymentClicked={paymentClicked}
              proceedToPaymentHandler={proceedToPaymentHandler}
              navigate={navigate}
              user={user}
              isLoggedIn={isLoggedIn}
              plan={planDetails?.selectedPlan}
              line={planDetails?.numberOfLines}
              setUserDetails={setUserDetails}
              content={addressContentState}
              simPreference={simPreference}
              setSimPreference={setSimPreference}
              isAutoPay={isAutoPay}
              isSaveCard={isSaveCard}
              onChangeAutoPayment={onChangeAutoPayment}
              onChangeSaveCard={onChangeSaveCard}
              autoPayContentState={autoPayContentState}
              simId={simId}
              simInHand={simInHand}
              setSimInHand={setSimInHand}
              setSimId={setSimId}
              onSubmitSimHandler={onSubmitSimHandler}
              simInHandContentState={simInHandContentState}
              setIsAutoSelect={setIsAutoSelect}
              hideBackButton={
                customerStoreData?.reachPlanId ===
                  Config.SPECIAL_DISCOUNT_PLAN_NAME ||
                planDetails?.selectedPlan?.name ===
                  Config.SPECIAL_DISCOUNT_PLAN_NAME
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          order={{ md: 2, xs: 1 }}
          sx={{ pl: { sm: "10px", xs: "0px" }, pb: { sm: "0px", xs: "10px" } }}
        >
          <Box sx={{ background: "var(--white)", borderRadius: "20px" }}>
            <ReviewPlanDetails
            setIsAutoSelect={setIsAutoSelect}
            isAutoSelect={isAutoSelect}
              content={reviewContentState}
              formik={formik}
              shipmentPlans={sendSimOptions}
              simPreference={simPreference}
              setSimPreference={setSimPreference}
              simInHandCheck={simInHandCheck}
              simId={simId}
              isAllowedMultipleLineSelectionForSIMInHand={
                isAllowedMultipleLineSelectionForSIMInHand
              }
              {...(ENVConfig?.IS_MSG_CENTER_ENABLED && {
                checkoutPageOpsMessages: checkoutPageOpsMessages,
              })}
            />
          </Box>
        </Grid>
      </Grid>
      <CardSavingAlertDialog
        display={displaySaveCardAlertDialog}
        onClose={onCloseAlertSaveCardDialog}
        content={autoPayContentState}
        onConfirmProceed={onRequestConfirmPayment}
        isAutoPayAndSaveCardEnabled={isAutoPay && isSaveCard}
      />
    </>
  ) : null;
};
