import { FC, useEffect, useState } from "react";
import {
  HomeBanner,
  CheckCoverage,
  HmBestFit,
  LeadGeneration,
  BrandFeatures,
  PlanFeatures,
  ExplorePlans,
  Byod,
  AppSection,
  HelmetCust,
  PlanSection,
  FAQ,
  Support,
  NotificationCust,
} from "../../components";
import Config from "../../config/app.config.json";
import { BestFitTp } from "../../components/bestfit/BestFitTP";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  GetCustomer,
  getMessageDetails,
} from "../../features/checkout/checkoutSlice";
import { useNavigate } from "react-router-dom";
// import Plans from "../../config/data.json";
import { mapTemplatesToPage } from "../../utils/commonFunctions/mapTemplatesToPage";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
import { gaEventTypes, gaScreenNames } from "../../Types/GoogleAnalytics";
import { RoutingURLs } from "../../config/RoutingURLs";
import { CustomerStatus } from "../../enums";
import firebase from "../../adapters/firebase";
import { CustomerService } from "../../components/home/CustomerService";
import { getCartItems } from "../../features/shopWare/shopWareSlice";
import { reachPlanData } from "../../features/homepage/homepageSlice";
import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../utils";

const getComponent = (key: string) => {
  switch (key) {
    case "Banner":
      return (
        <HomeBanner
          key={key}
          pageName={"Home"}
          apiEndPoint={"homePageBanner"}
        />
      );
    case "CheckCoverage":
      return <CheckCoverage key={key} />;
    case "BestFitSection":
      return <HmBestFit key={key} />;
    case "LeadGeneration":
      return <LeadGeneration key={key} />;
    case "Plans":
      return <PlanSection key={key} isSelected={false} />;
    case "AppSection":
      return <AppSection key={key} />;
    case "PlanFeatures":
      return <PlanFeatures key={key} />;
    case "BrandFeatures":
      return EnvConfig.HIDE_BRAND_FEATURE ? null : <BrandFeatures key={key} />;
    case "BYOD":
      return <Byod key={key} />;
    case "ExplorePlans":
      return <ExplorePlans key={key} />;
    case "TrustPilot":
      return <BestFitTp key={key} isTitleRequired={true} />;
    case "CustomerService":
      return <CustomerService key={key} />;
    case "FAQ":
      return <FAQ key={key} />;
    case "Support":
      return <Support key={key} />;
    default:
      return null;
  }
};

export const queryContent = {
  partialMatch: true,
  limit: 20,
  query: "",
  status: ["IN_PROGRESS"],
};

export const Home: FC = () => {
  const { getCustomer, messageDetails } = useAppSelector(
    (state: any) => state.checkout
  );
  let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
  planDetails = planDetails ? JSON.parse(planDetails) : null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { planData, subDomainData } = useAppSelector((state: any) => state.homepage);
  const [displayMessageDetails, setDisplayMessageDetails] = useState([]);
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  useEffect(() => {
    if (messageDetails && messageDetails?.length > 0) {
      let arr: any = [];
      {
        messageDetails?.length > 0 &&
          messageDetails?.map((item: any, index: number) => {
            if (
              item?.inventory["messaging_center.website.homepage_toast_header"]
            ) {
              arr.push(item);
            }
          });
      }
      setDisplayMessageDetails(arr);
    }
  }, [messageDetails]);

  useEffect(() => {
    if (displayMessageDetails && displayMessageDetails?.length > 0) {
      NotificationCust({
        message: (
          <Box>
            {displayMessageDetails &&
              displayMessageDetails.map((content: any) => {
                return (
                <Typography
                  sx={{ fontFamily: 'var(--font_family_medium)' }}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(content?.message ?? ''),
                  }}
                ></Typography>
              )
              })}
          </Box>
        ),
        type: "info",
        duration: 10000,
        id: "messageUpdate",
        showIcon: true,
      });
    }
  }, [displayMessageDetails]);
  useEffect(() => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.page_view, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
    });
  }, []);
  useNonInitialEffect(() => {
    if (getCustomer) {
      const { simPaid, status } = getCustomer || {};
      if (
        ![
          CustomerStatus.ACTIVE,
          CustomerStatus.SUSPENDED,
          CustomerStatus.DISCONNECTED,
        ].includes(getCustomer.status) &&
        !getCustomer?.isPrimary
      ) {
        navigate(RoutingURLs.secondarylogin);
      } else if (status === 0 && simPaid) {
        if (getCustomer?.esim) {
          navigate(RoutingURLs.activateEsim);
        } else if (getCustomer?.isSimInHand) {
          if (EnvConfig?.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
            navigate(RoutingURLs.deviceConfirmCheck);
          } else {
            navigate(RoutingURLs.activation);
          }
        } else {
          navigate(RoutingURLs.myAccount, { state: getCustomer });
        }
      } else if (status === 0 && !simPaid) {
        let plandata;
        let customerPlan;
        if (getCustomer?.reachPlanId) {
          customerPlan = planData?.data?.find(
            (plan?: any) =>
              plan?.name?.toLowerCase() ===
              getCustomer?.reachPlanId?.toLowerCase()
          );
        } else {
          customerPlan = JSON.parse(
            localStorage.getItem("reachSelectedPlanDetail") || "null"
          )?.plan;
        }
        plandata = JSON.stringify({
          line: Number(getCustomer.extraLines) + 1,
          plan: customerPlan,
        });
        let localPlanData = JSON.parse(
          localStorage.getItem("reachSelectedPlanDetail") || "null"
        );
        // localStorage.setItem("reachSelectedPlanDetail", plandata);
        if (getCustomer?.linePreferences && !localPlanData) {
          let newArr: any = [];
          Object.values(getCustomer?.linePreferences).map((el: any) => {
            newArr.push(el);
          });
          localStorage.setItem(
            "reachSelectedPlanDetail",
            JSON.stringify(newArr)
          );
        }
        if (!EnvConfig?.IS_SHOPWARE_ENABLED && !subDomainData?.data?.shopwareAccessKey) {
          navigate(RoutingURLs.checkout, { state: getCustomer });
        }
      } else if (
        status == 1 ||
        status == 2 ||
        status == 3 ||
        status == 4 ||
        status == 5 ||
        status == 8 ||
        status == 9 ||
        status == 10
      ) {
        navigate(RoutingURLs.postActivationHome);
      } else if (status == 11) {
        navigate(RoutingURLs.myAccount);
      }
    }
  }, [getCustomer]);

  useEffect(() => {
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);
    let email: string =
      currentuser && currentuser.email ? currentuser.email : "";
    let searchParams = new URLSearchParams(document.location.search);
    let logoutCheck: string | null = searchParams.get("logout");
    if (EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
      if (!logoutCheck) {
        dispatch(getCartItems());
        email &&
          dispatch(
            GetCustomer({
              username: encodeURIComponent(email),
            })
          );
      }
    } else {
      email &&
        dispatch(
          GetCustomer({
            username: encodeURIComponent(email),
          })
        );
    }
    dispatch(reachPlanData({}));
    if(EnvConfig?.MESSAGE_CENTER){
      dispatch(getMessageDetails(queryContent));
    }
  }, []);

  const enteries: any = mapTemplatesToPage(Config.Home);
  return <>{enteries.map((item: any) => getComponent(item.component))}</>;
};
