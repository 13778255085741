import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { useEffect, useState } from "react";
import { notificationsContent } from "./features/strapi/componentSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { globalValContent } from "./features/strapi/globalValuesSlice";
import TagManager from "react-gtm-module";
import firebase from "./adapters/firebase";
import EnvConfig from "./config/env.config.json";
import {
  getCartItems,
  getCustomerFromShopware,
  shopwareAuth,
} from "./features/shopWare/shopWareSlice";
import { shopwareAuthentication } from "./utils/commonFunctions/shopWareFunctions";
import {
  ConvertHexToRgbA,
  getSubdomain,
  updateThemeVariables,
} from "./global/themeutils";
import { Coupon } from "./features/checkout/checkoutSlice";
import { getSubDomainDetails } from "./features/homepage/homepageSlice";
import { PasswordAuthenticator } from "./components";
function App() {
  const { subDomainData, errorSubDomainData } = useAppSelector(
    (state: any) => state.homepage
  );
  const [isPasswordProtect, setIsPasswordProtect] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [brandPassword, setBrandPassword] = useState("");
  const dispatch = useAppDispatch();
  useEffect(() => {
    const brand = getSubdomain();  
    // const brand = "tejas-new-brand-qa";
    // const brand = "aishmobile-dev";
    // const brand = "aravindfivemobile-qa";
    // const brand = "shobhit-test-fifteen-qa";
    // const brand = "flight-mobile-qa";
    // const brand = "bmw-mobile-five-qa";  
    // const brand = "mgmobile-qa-test";
    // const brand = "skoda-qa-test";    
    // const brand = "reach-nctc-flight-dev";
    // const brand = "promo-test-qa"; 
    // const brand = "stratusiq-qa";
    // const brand = "nametetx-dev";
    console.log(brand); 
    if (brand) {
      dispatch(getSubDomainDetails({ subDomain: brand }));
    }
    if (
      !(
        localStorage.getItem("accessToken") &&
        localStorage.getItem("currentuser")
      )
    ) {
      firebase
        .auth()
        .signInAnonymously()
        .then(async (user) => {
          await localStorage.setItem(
            "currentuser",
            JSON.stringify(user && user.user)
          );
          let user1: any = await localStorage.getItem("currentuser");
          user1 = JSON.parse(user1);
          let token =
            user1 &&
            user1.uid &&
            user1.stsTokenManager &&
            user1.stsTokenManager.accessToken;
          await localStorage.setItem("accessToken", token);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    dispatch(globalValContent());
    dispatch(notificationsContent());
    // const tagManagerArgs = {
    //   gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY || "",
    // };
    // TagManager.initialize(tagManagerArgs);
  }, []);
  useEffect(() => {
    if (subDomainData?.status === "SUCCESS" && subDomainData?.data) {
      localStorage.setItem(
        "subDomainData",
        JSON.stringify(subDomainData?.data)
      );
      const contentImg = document.getElementById(
        "cookie_managemnet_icon"
      ) as HTMLImageElement;
      if (contentImg) {
        contentImg.src = subDomainData?.data?.cookieIconUrl;
      }
      // const contentDiv = document.getElementById("hs_show_banner_button");
      // if (contentDiv && subDomainData?.data?.cookieSettings) {
      //   contentDiv.style.display = "flex";
      // }
      const brandPassword = subDomainData?.data?.brandPassword;
      setIsPasswordProtect(brandPassword && brandPassword !== " ");
      setBrandPassword(brandPassword);
      // dispatch(
      //   Coupon({ couponCode: subDomainData?.data?.promoCode?.toUpperCase() })
      // );
      const noscript = document.createElement("noscript");
      const iframe = document.createElement("iframe");
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${subDomainData?.data?.gtmTag}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";

      noscript.appendChild(iframe);
      document.body.appendChild(noscript);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
              j.async = true;
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "${subDomainData?.data?.gtmTag}");
          `;
      document.head.appendChild(script);

      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${subDomainData?.data?.ga4Id}`;
      document.head.appendChild(script1);

      // Initialize Google Analytics after the script loads
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(p0: string, p1: Date) {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", subDomainData?.data?.ga4Id);
      };

      const script2 = document.createElement("script");
          script2.async = true;
          script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "${subDomainData?.data.ga4Id}");
            gtag("consent", "default", {
              ad_storage: "denied",
              ad_user_data: "denied",
              ad_personalization: "denied",
              analytics_storage: "denied",
            });
          `;
          document.head.appendChild(script2);

      const tagManagerArgs = {
        gtmId:
          subDomainData?.data?.gtmTag ||
          process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY ||
          "",
      };
      TagManager.initialize(tagManagerArgs);
      let themeVariables = {};
      themeVariables = {
        primary_color: ConvertHexToRgbA(subDomainData?.data?.colorCode),
        primary_brand_color: ConvertHexToRgbA(subDomainData?.data?.colorCode),
        success_notification: ConvertHexToRgbA(subDomainData?.data?.colorCode),
        info_notification: ConvertHexToRgbA(subDomainData?.data?.colorCode),
        default_notification: ConvertHexToRgbA(subDomainData?.data?.colorCode),
        app_section_bg_color: ConvertHexToRgbA(
          subDomainData?.data?.colorCode,
          0.1
        ),
        lead_generation_bg_color: ConvertHexToRgbA(
          subDomainData?.data?.colorCode,
          0.2
        ),
        // plan_section_bg_color: ConvertHexToRgbA(
        //   subDomainData?.data?.colorCode,
        //   0.1
        // ),
        faq_background: ConvertHexToRgbA(subDomainData?.data?.colorCode, 0.1),
        login_signup_color: ConvertHexToRgbA(
          subDomainData?.data?.colorCode,
          0.1
        ),
        faq_answer_bg: ConvertHexToRgbA(subDomainData?.data?.colorCode, 0.1),
        primary_brand_color_light: ConvertHexToRgbA(
          subDomainData?.data?.colorCode,
          0.3
        ),
        switch_border_color: ConvertHexToRgbA(
          subDomainData?.data?.colorCode,
          0.1
        ),
        light_primary_color: ConvertHexToRgbA(
          subDomainData?.data?.colorCode,
          0.05
        ),
      };
      updateThemeVariables(themeVariables);
      if (EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
        let currentuser: any = localStorage.getItem("currentuser");
        currentuser = JSON.parse(currentuser);
        let searchParams = new URLSearchParams(document.location.search);
        let logoutCheck: string | null = searchParams.get("logout");
        // if (!logoutCheck) {
          dispatch(getCartItems());
          if (currentuser && currentuser.email) {
            fetchShopwareUser();
          }
        // }
      }
    }
  }, [subDomainData]);
  const fetchShopwareUser = () => {
    dispatch(getCustomerFromShopware())
      .then((_res: any) => {})
      .catch((_err: any) => {
        handleLoginShopware();
      });
  };
  const handleLoginShopware = () => {
    let payloadData: any = shopwareAuthentication();
    if (payloadData) {
      dispatch(shopwareAuth(payloadData));
    }
  };
  let websiteAuth: any = localStorage.getItem("website_auth");
  return (
    <>
      {isPasswordProtect ? (
        isVerified || websiteAuth ? (
          <AppRoutes />
        ) : (
          <PasswordAuthenticator
            setIsVerified={setIsVerified}
            brandPassword={brandPassword}
          />
        )
      ) : (
        <AppRoutes />
      )}
    </>
  );
}

export default App;
