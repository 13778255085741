import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwitchTabs from "../SwitchTabs";
import { PostActivationIRILDEnums } from "../../../../enums";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import UsageHistory from "./UsageHistory/UsageHistory";
import { WalletHistory } from "./WalletHistory";
import { getResolutionDetails } from "../../../../utils/commonFunctions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const T3_WalletHistory = (props: any) => {
  const { mobile } = getResolutionDetails();
  const {
    content,
    lineDataForWallet,
    setIsViewHistory,
    handleBillCycleChange,
    selectedDateKeyIndex,
  } = props;
  const { back_txt, title, desc, usage_history, transaction_history } =
    props.content.wh_data;
  const { back_arrow } = props.content;
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const onClickSwitchTabs = (type: any) => {
    if (type === PostActivationIRILDEnums.tab_left) {
      setShowTransactionHistory(false);
    } else {
      setShowTransactionHistory(true);
    }
  };
  return (
    <>
      <Box
        sx={{
          margin: { xs: "auto", sm: "auto", md: "auto" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-between",
          width: { xs: "100%", sm: "auto", md: "56%" },
        }}
        data-testid="wallet_hist_wrapper"
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "30px",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& span": {
              fontWeight: "var(--font_weight_5)",
            },
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              left: { xs: "17px", md: "103px" },
              top: { xs: "105px", md: "auto" },
              cursor: "pointer",
              fontWeight: "var(--font_weight_3)",
            }}
            onClick={() => setIsViewHistory(false)}
             data-testid="wallet_hist_back"
          >
            {!mobile ? (
              <ArrowBackIosIcon sx={{ width: "18px", fontSize: "1.125rem" }}  data-testid="wallet_hist_back_icon"/>
            ) : (
              <Box
                component="img"
                src={back_arrow?.data.attributes.url}
                alt={back_arrow?.data.attributes.alternativeText}
                 data-testid="wallet_hist_back_icon"
              />
            )}
            {!mobile && (
              <Box
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1) !important",
                  fontSize: "18px",
                  lineHeight: "30px",
                }}
                component="span"
                 data-testid="wallet_hist_back_txt"
              >
                {back_txt}
              </Box>
            )}
          </Box>
          <Box
            component="span"
            style={{ margin: "auto" }}
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              fontWeight: "var(--font_weight_4) !important",
              fontSize: { xs: "24px", md: "24px" },
              color: "var(--black) !important",
            }}
             data-testid="wallet_hist_title"
          >
            {title}
          </Box>
        </Typography>
        <Typography
          sx={{
            fontFamily: "var(--font_family_Medium) !important",
            fontSize: { xs: "14px", md: "16px" },
            display: "flex",
            justifyContent: "center",
          }}
           data-testid="wallet_hist_desc"
        >
          {desc}
        </Typography>
        <SwitchTabs
          title_left={usage_history}
          title_right={transaction_history}
          onClickSwitchTabs={onClickSwitchTabs}
          showPurchaseHistory={showTransactionHistory}
          sx={{
            border: "1px solid var(--primary_color) !important",
            borderRadius: "10px",
            background: "var(--white) !important",
            margin: { xs: "0px", sm: "30px 0px 0px" }
          }}
          active={{
            background: "var(--primary_brand_color_light) !important",
            borderRadius: "10px !important",
            color: "var(--primary_color) !important",
            margin: '2px !important'
          }}
        />
        {showTransactionHistory ? (
          <TransactionHistory
            lineDataForWallet={lineDataForWallet}
            content={content}
            handleBillCycleChange={handleBillCycleChange}
            selectedDateKeyIndex={selectedDateKeyIndex}
          />
        ) : (
          <UsageHistory
            lineDataForWallet={lineDataForWallet}
            content={content}
            handleBillCycleChange={handleBillCycleChange}
            selectedDateKeyIndex={selectedDateKeyIndex}
          />
        )}
      </Box>
    </>
  );
};

export default T3_WalletHistory;
