import { Box, Stack, Typography } from "@mui/material";
import styles from "./S1_FAQ.module.scss";
import { FAQTemplateProps } from "./FAQ";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const FAQComponent = (props: FAQTemplateProps) => {
  const { FAQ_title, QandA, img1, img2 } = props.content || {};
  const [showAnswer, setShowAnswer] = useState<any>();

  const handleAnswer = (i: any) => {
    if (showAnswer === i) return setShowAnswer(null);
    return setShowAnswer(i);
  };

  return (
    <Stack
      sx={{
        // backgroundColor: "var(--light_primary_color)",
        flexWrap: "wrap",
        alignItems: "center",
        py: "32px",
      }}
      data-testid="faq_parent"
    >
      <Box
        sx={{
          width: { xs: "80%", md: "60%" },
        }}
      >
        <Typography
          sx={{
            color: "var(--dark-charcoal-fade)",
            fontSize: { xs: "20px", md: "24px" },
            fontFamily: "var(--font_family_Bold)",
            textAlign: "center",
            // py: "24px",
            paddingBottom: "18px"
          }}
          data-testid="faq_title"
        >
          {FAQ_title}
        </Typography>
        {QandA?.map((item: any, i: any) => {
          return (
            <Box key={item.Q} sx={{ minHeight: "65px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleAnswer(i);
                }}
              >
                <Typography
                  sx={{
                    color: "var(--dark-charcoal-fade)",
                    fontFamily: "var(--font_family_Medium)",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  data-testid={`faq_q_${Number(i) + 1 || 1}`}
                >
                  {item.Q}
                </Typography>
                {i !== showAnswer && (
                  <Box
                  component="img"
                  alt={
                    img1?.data?.attributes?.alternativeText ||
                    ""
                  }
                  src={img1?.data?.attributes?.url || ""}
                  width={"18px"}
                  height={"18px"}
                    sx={{
                      color: "var(--black)",
                      // width: "40px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                    data-testid={`faq_arrow_down_${Number(i) + 1 || 1}`}
                  />
                )}
                {i === showAnswer && (
                  <Box
                  component="img"
                  alt={
                    img2?.data?.attributes?.alternativeText ||
                    ""
                  }
                  src={img2?.data?.attributes?.url || ""}
                  width={"18px"}
                  height={"18px"}
                    sx={{
                      color: "var(--black)",
                      // width: "40px",
                      // height: "40px",
                      cursor: "pointer",
                    }}
                    data-testid={`faq_arrow_up_${Number(i) + 1 || 1}`}
                  />
                )}
              </Box>
              {i === showAnswer && (
                <Box
                  sx={{
                    background: "var(--light_primary_color)",
                    p: { xs: "10px", sm: "45px" },
                    my: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--dark-charcoal-fade)",
                      fontFamily: "var(--font_family_Regular) !important",
                      fontWeight: "var(--font_weight_0) !important",
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.A,
                    }}
                    data-testid={`faq_a_${Number(i) + 1 || 1}`}
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};

export default FAQComponent;
