import styles from "./S4_Services.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCust, NotificationCust } from "../widgets";
import EnvConfig from "../../config/env.config.json";
import { useEffect, useState } from "react";
import { customerGroup } from "../../features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { PAInternationalRoaming } from "./PAInternationalRoaming/PAInternationalRoaming";
import {
  LastDisconnectedReason,
  PostActivationIRILDEnums,
  CustomerStatus as CS,
} from "../../enums";
import { PAInternationalCalling } from "./PAInternationalCalling/PAInternationalCalling";
import {
  getCustomerBillCycleDataUsage,
  getCustomerDataUsageById,
} from "../../features/customer/customerSlice";
import { addDays, format, parseISO, subDays, subMonths } from "date-fns";
import { getUTCDate, sortObjectByDates } from "../../utils/commonFunctions";
import { GetCustomer } from "../../features/checkout/checkoutSlice";
import { Store } from "react-notifications-component";
import { gtmTagManager } from "../../utils/commonFunctions/GtmTagManager";

type Props = {
  content: any;
};

const ServiceComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const [internationalService, setInternationalService] = useState("");
  const { content } = props;
  const {
    services_card,
    services_icon,
    pageTitle,
    signInTxt,
    signInLink,
    signIn_url,
    primary_nonpay,
    disconnect_icon,
    notify_nonPay,
    notify_disconnect,
    suspend_icon,
    notify_suspend,
    primary_disconnect_userReq,
    primary_suspended,
    disclaimer,
  } = content;

  const [displayStartDate, setDisplayStartDate] = useState<any>();
  const [displayEndDate, setDisplayEndDate] = useState<any>();
  const [consolidatedBillCycyle, setConsolidatedBillCycle] = useState<any>();
  const [totalNumberOfKeys, setTotalNumberOfKeys] = useState<number>(0);
  const [selectedDateKeyIndex, setSelectedDateKeyIndex] = useState<number>(0);
  const [dataUsageInfo, setDataUsageInfo] = useState<any>();
  const [isPrimaryDisconnected, setIsPrimaryDisconnected] = useState(false);
  const [isPrimarySuspended, setIsPrimarySuspended] = useState(false);
  const { customerUsageData, customerBillCycleDataUsage } = useAppSelector(
    (state: any) => state.customer
  );
  const { group } = useAppSelector((state: any) => state?.account || {});
  const isPrimaryLine =
    group &&
    group.data.length > 0 &&
    group.data.filter((data: any) => data.isPrimary === true)[0];
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );

  const { service_main, service_header_text, service_Desc_text } = styles;
  let customerStoreData: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const customerId = customerStoreData?.isPrimary
    ? customerStoreData?.id
    : customerStoreData?.parentId;

  const handleBillCycleChange = (type: string) => {
    let newSelectedKey: number = selectedDateKeyIndex;
    if (type === "decrement") {
      newSelectedKey = selectedDateKeyIndex - 1;
    } else {
      newSelectedKey = selectedDateKeyIndex + 1;
    }
    setSelectedDateKeyIndex(newSelectedKey);
    handleDateKeyChange(newSelectedKey);
  };

  const handleDateKeyChange = (newSelectedKey: number) => {
    let newEndDate: any = Object.keys(consolidatedBillCycyle)[newSelectedKey];
    let newStartDate = null;
    if (!EnvConfig.FIXED_DAYS_BILLING) {
      newStartDate = addDays(subMonths(parseISO(newEndDate), 1), 1);
    } else {
      newStartDate = addDays(
        parseISO(newEndDate),
        EnvConfig.NUMBER_OF_BILLING_DAYS_FOR_BILL_CYCLE
      ); // config need to be tested for plum
    }
    if (newSelectedKey === totalNumberOfKeys - 1) {
      // For handling current details bill cycle dates
      setDisplayStartDate(parseISO(customerStoreData?.billStartDate));
    } else {
      setDisplayStartDate(newStartDate);
    }
    setDisplayStartDate(newStartDate);
    setDisplayEndDate(parseISO(newEndDate));
    setDataUsageInfo(consolidatedBillCycyle[newEndDate]);
  };

  function formatDateToMMDDYYYY(date: any) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  }

  localStorage.setItem(
    "StartEndDate",
    JSON.stringify({ displayStartDate, displayEndDate, totalNumberOfKeys })
  );

  const isValidBillCyclePayload = () => {
    return (
      customerStoreData?.id &&
      customerStoreData.nextBillingDate &&
      customerStoreData?.activationDate
    );
  };

  const requestOverallBillCycleDataUsage = (cdrCurrentDataUsageData: any) => {
    if (isValidBillCyclePayload()) {
      const startDate = parseISO(customerStoreData?.activationDate);
      const endDate = parseISO(customerStoreData?.billStartDate);
      fetchBillCycleDataUsage(
        getUTCDate(startDate),
        getUTCDate(endDate),
        cdrCurrentDataUsageData
      );
    }
  };

  const fetchBillCycleDataUsage = (
    startDate: string,
    endDate: string,
    cdrCurrentDataUsageData: any
  ) => {
    const customerId = customerStoreData?.isPrimary
      ? customerStoreData?.id
      : customerStoreData?.parentId;
    const payload = {
      userId: customerId,
      startDate,
      endDate,
    };
    dispatch(getCustomerBillCycleDataUsage(payload)).then(
      (cdrOverallResponse) => {
        if (cdrOverallResponse?.payload?.status === 200) {
          fetchDataUsageDetails(
            cdrCurrentDataUsageData,
            cdrOverallResponse?.payload?.data?.data
          );
        }
      }
    );
  };

  const fetchDataUsageDetails = (
    customerCurrentUsageData: any,
    customerOverallDataUsage: any
  ) => {
    const initialDisplayStartDate = parseISO(customerStoreData?.billStartDate);
    let initialDisplayEndDate = parseISO(customerStoreData?.nextBillingDate);
    initialDisplayEndDate = subDays(initialDisplayEndDate, 1);
    setDisplayStartDate(formatDateToMMDDYYYY(initialDisplayStartDate));
    setDisplayEndDate(formatDateToMMDDYYYY(initialDisplayEndDate));
    const formatedEndDate = getUTCDate(
      parseISO(customerStoreData?.billEndDate)
    );
    let latestRecordInCurrentDataUsage = {
      [formatedEndDate]: customerCurrentUsageData?.[formatedEndDate],
    };
    if (!customerCurrentUsageData?.[formatedEndDate]) {
      latestRecordInCurrentDataUsage = {};
    }
    const completeBillCycleDataUsage = {
      ...customerOverallDataUsage,
      ...latestRecordInCurrentDataUsage,
    };
    const sortedDataUsage: any = sortObjectByDates(completeBillCycleDataUsage);
    setConsolidatedBillCycle(sortedDataUsage);
    setTotalNumberOfKeys(Object.keys(sortedDataUsage).length);
    setSelectedDateKeyIndex(Object.keys(sortedDataUsage).length - 1);
    const endDate = subDays(parseISO(customerStoreData.nextBillingDate), 1);
    const uDataInfo = sortedDataUsage[getUTCDate(endDate)];
    setDataUsageInfo(uDataInfo);
  };

  useEffect(() => {
    dispatch(getCustomerDataUsageById(customerId)).then(
      (cdrCurrentResponse: any) => {
        // setLoadingData(false);
        fetchDataUsageDetails(customerUsageData, customerBillCycleDataUsage);
        requestOverallBillCycleDataUsage(
          cdrCurrentResponse?.payload?.data?.data
        );
      }
    );
  }, [customerId]);

  useEffect(() => {
    dispatch(
      GetCustomer({ username: encodeURIComponent(customerStoreData?.emailId) })
    );
    dispatch(
      customerGroup({
        groupId: customerStoreData && customerStoreData.groupId,
      })
    );
  }, []);

  useEffect(() => {
    if (isPrimaryLine?.status === CS.DISCONNECTED) {
      setIsPrimaryDisconnected(true);
    } else if (isPrimaryLine?.status === CS.SUSPENDED)
      setIsPrimarySuspended(true);
  }, [isPrimaryLine]);

  useEffect(() => {
    if (isPrimaryDisconnected) {
      if (
        isPrimaryLine.status === LastDisconnectedReason.REQUESTED_BY_CS ||
        isPrimaryLine.status === LastDisconnectedReason.TRIGGER_BY_USER ||
        isPrimaryLine?.status === LastDisconnectedReason.ZERO_USAGE ||
        isPrimaryLine.status === LastDisconnectedReason.NON_PAY_DISCONNECT ||
        isPrimaryLine.status === LastDisconnectedReason.BILL_DUE
      ) {
        NotificationCust({
          message: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                alt={disconnect_icon?.data?.attributes?.alternativeText || ""}
                src={disconnect_icon?.data?.attributes?.url || ""}
                mr="10px"
                sx={{
                  width: "20px",
                  height: "20px",
                  filter: "brightness(3)",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "16px",
                }}
              >
                {isPrimaryLine.status ===
                  LastDisconnectedReason.REQUESTED_BY_CS ||
                isPrimaryLine.status ===
                  LastDisconnectedReason.TRIGGER_BY_USER ||
                isPrimaryLine?.status === LastDisconnectedReason.ZERO_USAGE
                  ? notify_disconnect
                  : isPrimaryLine.status ===
                      LastDisconnectedReason.NON_PAY_DISCONNECT ||
                    isPrimaryLine.status === LastDisconnectedReason.BILL_DUE
                  ? notify_nonPay
                  : ""}
              </Typography>
            </Box>
          ),
          type: "danger",
          duration: 5000,
          id: "UserDisconnected",
          showIcon: true,
        });
      }
    }
  }, [isPrimaryDisconnected]);

  useEffect(() => {
    if (isPrimarySuspended) {
      NotificationCust({
        message: (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              alt={disconnect_icon?.data?.attributes?.alternativeText || ""}
              src={disconnect_icon?.data?.attributes?.url || ""}
              mr="10px"
              sx={{
                width: "20px",
                height: "20px",
                filter: "brightness(3)",
              }}
            />
            <Typography
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontSize: "16px",
              }}
            >
              {notify_suspend}
            </Typography>
          </Box>
        ),
        type: "danger",
        duration: 5000,
        id: "UserSuspended",
        showIcon: true,
      });
    }
  }, [isPrimarySuspended]);

  useEffect(() => {
    return () => {
      Store.removeNotification("UserDisconnected");
      Store.removeNotification("UserSuspended");
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        p: { xs: "85px 0px 150px", md: "100px 0px" },
      }}
    >
      {!internationalService ? (
        <>
          <Box
            sx={{
              fontSize: { xs: "24px", md: "40px" },
              fontFamily: "var(--font_family_Bold) !important",
              fontWeight: "var(--font_weight_4)",
            }}
          >
            {pageTitle}
          </Box>

          {/* Primary Suspended/Disconnected section */}
          {isPrimaryDisconnected || isPrimarySuspended ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: { xs: "90%", sm: "90%", md: "45%" },
                margin: " 30px auto 30px",
                textAlign: "center",
              }}
            >
              {isPrimaryDisconnected ? (
                <Box
                  component="img"
                  alt={disconnect_icon?.data?.attributes?.alternativeText || ""}
                  src={disconnect_icon?.data?.attributes?.url || ""}
                  mr="10px"
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              ) : isPrimarySuspended ? (
                <Box
                  component="img"
                  alt={suspend_icon?.data?.attributes?.alternativeText || ""}
                  src={suspend_icon?.data?.attributes?.url || ""}
                  mr="10px"
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              ) : null}
              <Typography
                sx={{
                  fontFamily: "var(--font_family_Bold)",
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                {isPrimaryDisconnected
                  ? isPrimaryLine.status ===
                      LastDisconnectedReason.NON_PAY_DISCONNECT ||
                    isPrimaryLine.status === LastDisconnectedReason.BILL_DUE
                    ? primary_nonpay
                    : isPrimaryLine.status ===
                        LastDisconnectedReason.REQUESTED_BY_CS ||
                      isPrimaryLine.status ===
                        LastDisconnectedReason.TRIGGER_BY_USER ||
                      isPrimaryLine?.status ===
                        LastDisconnectedReason.ZERO_USAGE
                    ? primary_disconnect_userReq
                    : null
                  : isPrimarySuspended
                  ? primary_suspended
                  : null}
              </Typography>
            </Box>
          ) : null}
          {/* Primary Suspended/Disconnected section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "30px",
              margin: "40px 0px 50px",
            }}
          >
            {services_card.title.map((title: any, idx: number) => {
              if (
                (!EnvConfig.ENABLE_IR_SERVICES &&
                  services_card.title[0] === title) ||
                (!EnvConfig.ENABLE_ILD_SERVICES &&
                  services_card.title[1] === title)
              )
                return;
              else
                return (
                  <Box className={service_main} key={services_card.title + idx}>
                    {/* <Box
                      component="img"
                      // src={services_icon?.data[idx]?.attributes?.url}
                      // alt={services_icon?.data[idx]?.attributes?.alternativeText}
                      sx={{
                        mb: "30px",
                        width: "100%",
                        height: "190px",
                        background: "var(--lightgrey_7)", // placeholder remove later
                      }}
                    /> */}

                    <Box
                      className={service_header_text}
                      textAlign={{ xs: "center" }}
                    >
                      {title}
                      <Box sx={{ pt: { xs: 1 } }}>
                        <Typography className={service_Desc_text}>
                          {services_card.desc[idx]}
                        </Typography>
                      </Box>
                    </Box>
                    <ButtonCust
                      variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                      sx={{
                        fontSize: "16px",
                        padding: "11px 20px",
                        width: "260px",
                        height: "50px !important",
                        "&:hover": {
                          backgroundColor:
                            "var(--button_hover_color) !important",
                          border:
                            "1px solid var(--button_hover_color) !important",
                        },
                      }}
                      disabled={isPrimaryDisconnected || isPrimarySuspended}
                      onClick={() => {
                        setInternationalService(
                          idx === 0
                            ? PostActivationIRILDEnums.roaming
                            : PostActivationIRILDEnums.calling
                        );
                        let event: any =
                          idx === 0
                            ? globalVal?.gtmEvents?.manageRoamingService
                            : globalVal?.gtmEvents?.manageCallingService;
                        gtmTagManager({
                          event: event,
                          click_section: "services",
                        });
                      }}
                    >
                      {services_card.btn[idx]}
                    </ButtonCust>
                  </Box>
                );
            })}
          </Box>
          <Box sx={{ textAlign: "center", marginBottom: "50px" }}>
            <Typography
              sx={{
                fontWeight: "var(--font_weight_0)",
                fontFamily: "var(--font_family_Medium)",
                fontSize: "14px",
                lineHeight: "28px",
                cursor: "pointer",
                a: {
                  color: "var(--text_color)",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: disclaimer,
              }}
            ></Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "var(--font_weight_0)",
                lineHeight: "30px",
                fontFamily: "var(--font_family_Medium)",
              }}
            >
              {signInTxt}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                textDecoration: "underline",
                fontWeight: "var(--font_weight_4)",
                cursor: "pointer",
              }}
              onClick={() => window.open(EnvConfig?.BZL_SIGN_IN_URL, "_blank")}
            >
              {signInLink}
            </Typography>
          </Box>
        </>
      ) : internationalService === PostActivationIRILDEnums.roaming ? (
        <PAInternationalRoaming
          setInternationalService={setInternationalService}
        />
      ) : internationalService === PostActivationIRILDEnums.calling ? (
        <PAInternationalCalling
          setInternationalService={setInternationalService}
          handleBillCycleChange={handleBillCycleChange}
          selectedDateKeyIndex={selectedDateKeyIndex}
        />
      ) : null}
    </Box>
  );
};
export default ServiceComponent;
