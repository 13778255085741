import { Box, Typography } from "@mui/material";
import styles from "./S1_Terms.module.scss";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  content: any;
};
const TermsComponent = (props: Props) => {
  const { content } = props;
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.terms_page_content}>
        <Typography
          variant="h3"
          className={styles.terms_title}
          mt={{ xs: "50px" }}
        >
          {content?.title}
        </Typography>
        <Box className={styles.terms_line}></Box>
        <Box className={styles.content}>
          {content &&
            content.Terms_content.length > 0 &&
            content.Terms_content.map((items: any, index: number) => {
              return (
                <Box key={index}>
                  <Typography className={styles.terms_headings} variant="h5">
                    {index + 1}. {BrandNameUpdate(items.heading)}
                  </Typography>
                  <Box
                    className={styles.terms_para}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(items.description),
                    }}
                  ></Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default TermsComponent;
