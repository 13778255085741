import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Typography,
} from "@mui/material";
import { ButtonCust, CustToolTip } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import styles from "./S3_GlobalRoamingPack.module.scss";
import PaymentReviewCard from "../PaymentReviewCard/PaymentReviewCard";
import PaymentStatus from "../PaymentStatus/PaymentStatus";
import { PostActivationIRILDEnums } from "../../../../enums";
import { resetPurchasePack } from "../../../../features/services/servicesSlice";
import {
  getResolutionDetails,
  updateSvgFillColor,
} from "../../../../utils/commonFunctions";
import { useState } from "react";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import SmsIcon from "@mui/icons-material/Sms";
import MmsIcon from "@mui/icons-material/Mms";
import VerifiedIcon from "@mui/icons-material/Verified";

function GlobalRoamingPack(props: any) {
  const { content, fullName } = props.props;
  const {
    back_btn,
    GR_pack,
    GR_icons,
    sms_icon,
    mms_icon,
    visa_card,
    calendarPen,
    calendar_data,
    carbon_edit,
    payment_review,
    success,
    failed,
    payment_status,
    back_arrow,
    american_express,
    discover,
    jcb,
    master_card,
    calender_svg,
  } = content;

  const {
    setIsGlobalRoamingPack,
    calanderOnChangeHandler,
    paymentFailedBack,
    confirmPurchaseHandler,
    calanderSubmitHandler,
    selectPackHandler,
    combinedSelectedPackReviewData,
    country,
    onChangeAutoRenewal,
    dateFormat,
    isCalendarModal,
    isPaymentReviewCard,
    purchasePackStatus,
    displayName,
    autoRenewal,
    selectedDate,
    setisCalendarModal,
  } = props;
  const { onClickSwitchTabs, setIsCountryList } = props.props;
  const [SMSInfo, setSMSInfo] = useState(false);
  const [MMSInfo, setMMSInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openInfo, setOpenInfo] = useState(false);

  const availableOfferingsData = useAppSelector(
    (state) => state.services?.availableOfferings
  );
  const { purchasePackError, purchasePackSuccess, purchasePackLoading } =
    useAppSelector((state) => state.services);
  const { IRTaxCharges, isLoadedIRTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { tooltip_style } = styles;

  const dispatch = useAppDispatch();

  const { mobile } = getResolutionDetails();

  const openSMSPopper = (data: any) => {
    setSMSInfo(data);
  };
  const openMMSPopper = (data: any) => {
    setMMSInfo(data);
  };
  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          openMMSPopper(false);
          openSMSPopper(false);
          setAnchorEl(null);
        }}
      >
        <Box
          component="a"
          //   href={tooltip_link}
          target="_blank"
          sx={{
            color: "var(--white)",
            fontSize: "14px",
            backgroundColor: "var(--navy)",
          }}
          data-testid="global_roam_data"
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <>
      {purchasePackError && (
        <PaymentStatus
          icon={failed}
          title={payment_status.failedTitle}
          description={payment_status.failedDesc}
          button={payment_status.failedBtn}
          onClick={() => confirmPurchaseHandler()}
          variantType={Config.PRIMARY_BUTTON_TYPE}
        />
      )}

      {purchasePackLoading && (
        <Box textAlign={"center"}>
          <CircularProgress
            sx={{ color: "var(--primary_color)" }}
            data-testid="global_raom_progress"
          />
        </Box>
      )}

      {purchasePackSuccess && (
        <PaymentStatus
          imgHeight={"150px"}
          imgWidth={"150px"}
          icon={success}
          title={payment_status.successTitle}
          description={`${payment_status.successDesc} ${dateFormat()}.`}
          button={payment_status.successBtn}
          onClick={() => {
            setIsCountryList(false);
            onClickSwitchTabs(PostActivationIRILDEnums.tab_right);
            dispatch(resetPurchasePack());
            localStorage.removeItem("selectedPack");
            localStorage.removeItem("postActivationDate");
            localStorage.removeItem("prRoamingCountry");
            localStorage.removeItem("SingleLineData");
          }}
          variantType={Config.PRIMARY_BUTTON_TYPE}
        />
      )}

      {!purchasePackStatus && (
        <Box
          m={{ xs: "auto", sm: "auto", md: "auto" }}
          width={{ xs: "auto" }}
          className={styles.main_container}
          data-testid="global_raom_cl_wrapper"
        >
          <CustomDateCalendar
            onClick={calanderSubmitHandler}
            onChange={calanderOnChangeHandler}
            isCalanderModal={isCalendarModal}
            onCloseModal={setisCalendarModal}
            title={calendar_data.title}
            desc={calendar_data.desc}
            button={calendar_data.btn_2}
            variant={Config.PRIMARY_BUTTON_TYPE}
            isDivider={false}
            descStyle={{
              fontSize: "16px",
              lineHeight: "26px",
              color: "var(--text_color)",
              fontFamily: "var(--font_family_Regular) !important",
            }}
          />
          <Typography className={styles.top_heading}>
            <Box
              component="span"
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                left: { xs: "20px", md: "103px" },
                top: { xs: "105px", md: "auto" },
                cursor: "pointer",
                fontWeight: "var(--font_weight_3)",
              }}
              onClick={() => setIsGlobalRoamingPack("")}
              data-testid="global_raom_back"
            >
              {!mobile ? (
                <ArrowBackIosIcon
                  sx={{ width: "18px", fontSize: "1.125rem" }}
                  data-testid="global_raom_back_icon"
                />
              ) : (
                <Box
                  component="img"
                  src={back_arrow?.data.attributes.url}
                  alt={back_arrow?.data.attributes.alternativeText}
                  data-testid="global_raom_back_icon"
                />
              )}
              {!mobile && (
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1)",
                    fontSize: "18px",
                    lineHeight: "30px",
                  }}
                  component="span"
                  data-testid="global_raom_back_txt"
                >
                  {back_btn}
                </Box>
              )}
            </Box>
            <Box
              component="span"
              style={{ margin: "auto" }}
              className={styles.heading}
              data-testid="global_raom_title"
            >
              {GR_pack.title}
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: { xs: "flex-start", sm: "center" },
              background: "var(--carrot_orange_color)",
              p: "20px",
              borderRadius: "8px",
              width: { xs: "90%", sm: "fit-content" },
              margin: "30px auto 40px",
              gap: "10px",
              backgroundColor: "var(--primary_brand_color_light)",
              border: "1px solid var(--primary_color)",
            }}
          >
            {/* <Box className={styles.full_name}>
              {GR_pack.name}
              <Box
                component="span"
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {" "}
                {fullName.toLowerCase()}
              </Box>
            </Box> */}
            <Box sx={{ width: "100%", sm: "auto" }}>
              <Typography textAlign="left">
                <Box
                  component="span"
                  className={styles.country_label}
                  data-testid="global_raom_country"
                >
                  {GR_pack.country}:{" "}
                </Box>
                <Box
                  component="span"
                  sx={{
                    fontSize: { xs: "14px !important", md: "16px" },
                    fontWeight: "var(--font_weight_0)",
                    textTransform: "capitalize",
                  }}
                  className={styles.name}
                  data-testid="global_raom_country_name"
                >
                  {JSON.parse(country || "{}")?.name?.toLowerCase()}
                </Box>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: { xs: "flex-start", md: "space-between" },
                  alignItems: { xs: "flex-start", md: "baseline" },
                  mt: { xs: "10px", md: "0px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", md: "row" },
                    justifyContent: { xs: "space-between", md: "flex-start" },
                    width: { xs: "100%", md: "auto" },
                    alignItems: "center",
                    pt: { xs: "0px", md: "10px" },
                  }}
                >
                  <Box>
                    <Box
                      component="span"
                      className={styles.schedule}
                      data-testid="global_raom_sch"
                    >
                      {GR_pack.scheduleFor}:{" "}
                    </Box>
                    <Box
                      component="span"
                      className={styles.selected_date}
                      pl="5px"
                      data-testid="global_raom_sch_date"
                    >
                      {selectedDate ? selectedDate : dateFormat()}
                    </Box>
                  </Box>
                  {/* <Box
                    component="img"
                    sx={{ ml: { xs: "20px", sm: "10px" } }}
                    onClick={() => setisCalendarModal(true)}
                    src={calendarPen.data.attributes.url}
                  /> */}
                  {/* <EditCalendarOutlinedIcon
                    sx={{
                      ml: { xs: "20px", sm: "10px" },
                      color: "var(--primary_color)",
                    }}
                    onClick={() => setisCalendarModal(true)}
                  /> */}
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      ml: { xs: "20px", sm: "10px" },
                    }}
                    onClick={() => setisCalendarModal(true)}
                    dangerouslySetInnerHTML={{
                      __html: updateSvgFillColor(
                        calender_svg,
                        "var(--primary_color)"
                      ),
                    }}
                    data-testid="global_raom_cld_icon"
                  />
                </Box>
                <Typography
                  sx={{
                    backgroundColor: "var(--light_primary_color)",
                    color: "var(--primary_color)",
                    fontSize: "16px !important",
                    fontFamily: "var(--font_family_Semibold)",
                    p: "5px",
                    ml: { xs: "0px", md: "50px" },
                    mt: { xs: "15px", md: "0px" },
                    border: "1px solid var(--primary_color)",
                    width: { xs: "fit-content", md: "auto" },
                    textAlign: { xs: "center", md: "left" },
                    px: { xs: "15px" },
                    borderRadius: "2px",
                  }}
                  data-testid="global_raom_fname"
                >
                  {fullName}{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"40px"}>
            {!isPaymentReviewCard ? (
              availableOfferingsData.length === 0 ? (
                <Box textAlign={"center"}>
                  <CircularProgress
                    sx={{ color: "var(--primary_color)" }}
                    data-testid="global_raom_progress"
                  />
                </Box>
              ) : (
                availableOfferingsData.map((data: any, index: any) => {
                  return (
                    <Box
                      key={data.id}
                      data-testid={`global_raom_wrapper_${
                        Number(index) + 1 || 1
                      }`}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-around"}
                        className={styles.packName}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography>
                            <Box
                              component="span"
                              className={styles.display_name}
                              data-testid={`global_raom_dp_name_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {data?.displayName}
                            </Box>
                            <Box
                              component="span"
                              className={styles.display_name_service_unit}
                              data-testid={`global_raom_unit_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {" "}
                              {data.data}
                              {data.dataUnit}
                            </Box>
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            className={styles.data_cost}
                            data-testid={`global_raom_data_price_${
                              Number(index) + 1 || 1
                            }`}
                          >
                            ${data.price.toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        key={data.id}
                        className={styles.card_sub_container}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                        }}
                        data-testid={`global_raom_card_sub_cont_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: { xs: "center", sm: "flex-start" },
                              width: "100%",
                              flexDirection: { xs: "row", md: "column" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                className={styles.card_sub_container_title}
                                data-testid={`global_raom_pack_data_${
                                  Number(index) + 1 || 1
                                }`}
                              >
                                {GR_pack.data}{" "}
                              </Typography>
                              {/* <Box
                                component="img"
                                alt={
                                  GR_icons.data[0].attributes.alternativeText
                                }
                                src={GR_icons.data[0].attributes.url}
                              /> */}
                              <ImportExportIcon
                                sx={{
                                  color: "var(--primary_color)",
                                  fontSize: "14px",
                                }}
                                data-testid={`global_raom_imp_exp_icon_${
                                  Number(index) + 1 || 1
                                }`}
                              />
                            </Box>
                            <Typography
                              className={styles.card_sub_container_value}
                              sx={{ textAlign: { xs: "right", sm: "left" } }}
                              data-testid={`global_raom_data_unit_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {data.data}
                              {GR_pack.dataUnit}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: { xs: "center", sm: "flex-start" },
                              width: "100%",
                              flexDirection: { xs: "row", md: "column" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                className={styles.card_sub_container_title}
                                data-testid={`global_raom_voice_${
                                  Number(index) + 1 || 1
                                }`}
                              >
                                {GR_pack.voice}{" "}
                              </Typography>
                              {/* <Box
                                component="img"
                                alt={
                                  GR_icons.data[1].attributes.alternativeText
                                }
                                src={GR_icons.data[1].attributes.url}
                              /> */}
                              <PhoneForwardedIcon
                                sx={{
                                  color: "var(--primary_color)",
                                  fontSize: "14px",
                                }}
                                data-testid={`global_raom_phone_icon_${
                                  Number(index) + 1 || 1
                                }`}
                              />
                            </Box>
                            <Typography
                              className={styles.card_sub_container_value}
                              sx={{ textAlign: { xs: "right", sm: "left" } }}
                              data-testid={`global_raom_voice_data_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {data.voice} {GR_pack.voiceUnit}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: { xs: "center", sm: "flex-start" },
                              width: "100%",
                              flexDirection: { xs: "row", md: "column" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                className={styles.card_sub_container_title}
                                data-testid={`global_raom_sms_${
                                  Number(index) + 1 || 1
                                }`}
                              >
                                {GR_pack.sms}{" "}
                                <CustToolTip
                                  title={PoperOver(
                                    <>
                                      <Typography
                                        sx={{
                                          fontFamily:
                                            "var(--font_family_Bold) !important",
                                          margin: "0 0 15px",
                                        }}
                                        data-testid={`global_raom_sms_tooltip_title_${
                                          Number(index) + 1 || 1
                                        }`}
                                      >
                                        {GR_pack.SMS_title}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily:
                                            "var(--font_family_Medium) !important",
                                          margin: "0 0 15px",
                                        }}
                                        data-testid={`global_raom_sms_tooltip_desc_${
                                          Number(index) + 1 || 1
                                        }`}
                                      >
                                        {GR_pack.SMS_desc}
                                      </Typography>
                                    </>
                                  )}
                                  open={anchorEl === data.id ? true : false}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  arrow
                                  placement="top-start"
                                  className={tooltip_style}
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, 10],
                                        },
                                      },
                                    ],
                                  }}
                                >
                                  {/* <Box
                                    component="img"
                                    alt={
                                      sms_icon.data.attributes.alternativeText
                                    }
                                    src={sms_icon.data.attributes.url}
                                    onMouseEnter={() => {
                                      openSMSPopper(true);
                                      setAnchorEl(data.id);
                                    }}
                                    onMouseLeave={() => {
                                      openSMSPopper(false);
                                      setAnchorEl(null);
                                    }}
                                  ></Box> */}
                                  <SmsIcon
                                    onMouseEnter={() => {
                                      openSMSPopper(true);
                                      setAnchorEl(data.id);
                                    }}
                                    onMouseLeave={() => {
                                      openSMSPopper(false);
                                      setAnchorEl(null);
                                    }}
                                    sx={{
                                      color: "var(--primary_color)",
                                      fontSize: "14px",
                                    }}
                                    data-testid={`global_raom_sms_icon_${
                                      Number(index) + 1 || 1
                                    }`}
                                  />
                                </CustToolTip>
                              </Typography>
                            </Box>
                            <Typography
                              className={styles.card_sub_container_value}
                              sx={{ textAlign: { xs: "right", sm: "left" } }}
                              data-testid={`global_raom_sms_data_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {data.sms}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: { xs: "center", sm: "flex-start" },
                              width: "100%",
                              flexDirection: { xs: "row", md: "column" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                className={styles.card_sub_container_title}
                                data-testid={`global_raom_mms_${
                                  Number(index) + 1 || 1
                                }`}
                              >
                                {GR_pack.mms}{" "}
                                <CustToolTip
                                  title={PoperOver(
                                    <>
                                      <Typography
                                        sx={{
                                          fontFamily:
                                            "var(--font_family_Bold) !important",
                                          margin: "0 0 15px",
                                        }}
                                        data-testid={`global_raom_mms_tooltip_title_${
                                          Number(index) + 1 || 1
                                        }`}
                                      >
                                        {GR_pack.MMS_title}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily:
                                            "var(--font_family_Medium) !important",
                                          margin: "0 0 15px",
                                        }}
                                        data-testid={`global_raom_mms_tooltip_desc_${
                                          Number(index) + 1 || 1
                                        }`}
                                      >
                                        {GR_pack.MMS_desc}
                                      </Typography>
                                    </>
                                  )}
                                  open={
                                    anchorEl === data.displayName ? true : false
                                  }
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  arrow
                                  placement="bottom"
                                  className={tooltip_style}
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, 10],
                                        },
                                      },
                                    ],
                                  }}
                                >
                                  {/* <Box
                                    component="img"
                                    alt={
                                      mms_icon.data.attributes.alternativeText
                                    }
                                    src={mms_icon.data.attributes.url}
                                    onMouseEnter={() => {
                                      openMMSPopper(true);
                                      setAnchorEl(data.displayName);
                                    }}
                                    onMouseLeave={() => {
                                      openMMSPopper(false);
                                      setAnchorEl(null);
                                    }}
                                  ></Box> */}
                                  <MmsIcon
                                    onMouseEnter={() => {
                                      openMMSPopper(true);
                                      setAnchorEl(data.displayName);
                                    }}
                                    onMouseLeave={() => {
                                      openMMSPopper(false);
                                      setAnchorEl(null);
                                    }}
                                    sx={{
                                      color: "var(--primary_color)",
                                      fontSize: "14px",
                                    }}
                                    data-testid={`global_raom_mms_icon${
                                      Number(index) + 1 || 1
                                    }`}
                                  />
                                </CustToolTip>
                              </Typography>
                            </Box>
                            <Typography
                              className={styles.card_sub_container_value}
                              sx={{ textAlign: { xs: "right", sm: "left" } }}
                              data-testid={`global_raom_data_mms_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {data.mms}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: { xs: "center", sm: "flex-start" },
                              width: "100%",
                              flexDirection: { xs: "row", md: "column" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                className={styles.card_sub_container_title}
                                data-testid={`global_raom_validity_${
                                  Number(index) + 1 || 1
                                }`}
                              >
                                {GR_pack.validity}{" "}
                              </Typography>
                              {/* <Box
                                component="img"
                                alt={
                                  GR_icons.data[4].attributes.alternativeText
                                }
                                src={GR_icons.data[4].attributes.url}
                              /> */}
                              <VerifiedIcon
                                sx={{
                                  color: "var(--primary_color)",
                                  fontSize: "14px",
                                }}
                                data-testid={`global_raom_validity_icon_${
                                  Number(index) + 1 || 1
                                }`}
                              />
                            </Box>
                            <Typography
                              className={styles.card_sub_container_value}
                              sx={{ textAlign: { xs: "right", sm: "left" } }}
                              data-testid={`global_raom_validity_data_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {data.validityDays}{" "}
                              {data.validityDays === 1 ? "day" : "days"}
                            </Typography>
                          </Box>
                        </Box>

                        <ButtonCust
                          data-testid={`global_raom_btn_${
                            Number(index) + 1 || 1
                          }`}
                          variantType={Config.PRIMARY_BUTTON_TYPE}
                          onClick={() => {
                            selectPackHandler(data);
                            let event: any =
                              data?.displayName === "Daily pack"
                                ? globalVal?.gtmEvents
                                    ?.manageServiceSelectDailyPack
                                : globalVal?.gtmEvents
                                    ?.manageServiceSelectWeeklyPack;
                            gtmTagManager({
                              event: event,
                              click_section: "account",
                            });
                          }}
                          sx={{
                            minWidth: {
                              xs: "250px !important",
                              sm: "152px !important",
                            },
                            width: {
                              xs: "250px !important",
                              sm: "152px !important",
                            },
                            margin: { xs: "auto", sm: "initial" },
                            height: "50px !important",
                            // "&:hover": {
                            //   backgroundColor:
                            //     "var(--button_hover_color) !important",
                            //   border:
                            //     "1px solid var(--button_hover_color) !important",
                            // },
                          }}
                        >
                          {GR_pack.pack_btn}
                        </ButtonCust>
                      </Box>
                    </Box>
                  );
                })
              )
            ) : (
              <PaymentReviewCard
                onChangeAutoRenewal={onChangeAutoRenewal}
                autoRenewal={autoRenewal}
                isAutoRenewal={Config.ENABLE_IR_DATA_RENEWAL}
                firstRowTitle={`${displayName}`}
                firstRowValue={combinedSelectedPackReviewData.dataCost}
                secondRowTitle={combinedSelectedPackReviewData.secondRow}
                secondRowValue={IRTaxCharges}
                thirdRowTitle={combinedSelectedPackReviewData.thirdRow}
                mockCard={combinedSelectedPackReviewData.mockCard}
                loading={isLoadedIRTaxCharges}
                autoRenewalTitle={
                  combinedSelectedPackReviewData?.autoRenewal.title
                }
                visa_card={visa_card?.data?.attributes}
                american_express={american_express}
                discover={discover}
                jcb={jcb}
                master_card={master_card}
                data={combinedSelectedPackReviewData}
                grPack={GR_pack}
                displayName={displayName}
                toggleTrueText={
                  combinedSelectedPackReviewData?.autoRenewal.toggleTrue
                }
                toggleFalseText={
                  combinedSelectedPackReviewData?.autoRenewal.toggleFalse
                }
                onClick={confirmPurchaseHandler}
                confirmBtn={combinedSelectedPackReviewData.cnfrmBtn}
                changeCardText={combinedSelectedPackReviewData.ChangeCard}
                otherProps={content}
              />
            )}
          </Box>
          {!isPaymentReviewCard && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "20px",
                flexDirection: { xs: "column" },
                width: { xs: "100%", md: "100%" },
                gap: "10px",
                marginX: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ textAlign: "left !important" }}
                dangerouslySetInnerHTML={{ __html: GR_pack.note }}
                className={styles.note}
                data-testid="global_raom_note"
              />

              <Box sx={{ display: "flex", gap: "10px", margin: "0 auto" }}>
                <Typography
                  onClick={() => {
                    window.open(GR_pack?.termsLink, "_blank");
                  }}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "var(--primary_color)",
                    fontWeight: {
                      xs: "var(--font_weight_4)",
                      sm: "var(--font_weight_2)",
                    },
                    fontSize: { xs: "18px", sm: "16px" },
                    lineHeight: "26px",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                  data-testid="global_raom_terms"
                >
                  {GR_pack.termsConditions}
                </Typography>
                {/* <CustToolTip
                  title={PoperOver(
                    <Box>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {content?.tooltip_heading}
                      </Typography>
                      {content?.tooltip_items?.map((el: any) => (
                        <>
                          <Typography key={el.id}>{el.description}</Typography>
                          {el.data &&
                            el.data.map((item: any) => (
                              <Typography
                                sx={{ marginLeft: "15px" }}
                                key={item.id}
                              >
                                {item.subDesc}
                              </Typography>
                            ))}
                        </>
                      ))}
                    </Box>
                  )}
                  open={openInfo ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  placement="bottom"
                  className={styles.tooltip_style}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                >
                  <Box
                    component="img"
                    alt={sms_icon.data.attributes.alternativeText}
                    src={sms_icon.data.attributes.url}
                    onMouseEnter={() => {
                      setOpenInfo(true);
                    }}
                    onMouseLeave={() => {
                      setOpenInfo(false);
                    }}
                  ></Box>
                </CustToolTip> */}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default GlobalRoamingPack;
