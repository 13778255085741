import { addMinutes, format } from "date-fns";

export function getUTCDate(
  date: Date,
  dateFormat: string = "yyyy-MM-dd"
): string {
  const dateInUTC = addMinutes(date, date.getTimezoneOffset());

  return format(dateInUTC, dateFormat);
}

export function getUTCDateIR(date: Date) {
  return addMinutes(date, date.getTimezoneOffset());
}

export function gmtToEst(dateObj: Date) {
  const date = new Date(dateObj).toLocaleString("en-US", {
    timeZone: "America/New_York",
  });

  return format(getUTCDateIR(new Date(date)), "MMM dd, yyyy, hh:mmaaa");
}
