import { isTemplateExisits } from "../../../../utils/commonFunctions";
import AppConfig from "../../../../config/app.config.json";

const CountryDataModalTemplate = isTemplateExisits(
  AppConfig.PostActivationServices.CountryDataModal
)
  ? require(`./${AppConfig.PostActivationServices.CountryDataModal.template}_CountryDataModal`)
      .default
  : null;

const CountryDataModal = (props: any) => {
  return <CountryDataModalTemplate {...props} />;
};

export default CountryDataModal;
