import { useState } from "react";
import AppConfig from "../../../../config/app.config.json";
import { useAppSelector } from "../../../../app/hooks";
import { isTemplateExisits } from "../../../../utils/commonFunctions";

export type paymentProps = {
  visa_card: any;
  firstRowTitle?: any;
  firstRowValue?: any;
  secondRowTitle?: any;
  secondRowValue?: any;
  loading?: any;
  isAutoRenewal?: any;
  thirdRowTitle?: any;
  autoRenewalTitle?: any;
  autoRenewal?: any;
  toggleTrueText?: any;
  toggleFalseText?: any;
  confirmBtn: any;
  carbon_edit?: any;
  changeCardText: any;
  btnLoading?: any;
  dataCap?: any;
  onChangeAutoRenewal?: any;
  mockCard?: any;
  onClick?: any;
  data?: any;
  grPack?: any;
  displayName?: any;
  totalCost?: any;
  getCCDetails?: any;
  purchase_addOn?: any;
  availableOfferingsData?: any;
  defaultCard?: any;
  isChangeCard?: any;
  setIsChangeCard?: any;
  american_express?: any;
  discover?: any;
  jcb?: any;
  master_card?: any;
  otherProps?: any;
};
const IRPaymentReviewComp = isTemplateExisits(
  AppConfig.PostActivationServices.IRPaymentReview
)
  ? require(`./${AppConfig.PostActivationServices.IRPaymentReview.template}_PaymentReviewCard`)
      .default
  : null;

function PaymentReviewCard(props: paymentProps) {
  const { getCCDetails } = useAppSelector((state: any) => state?.account);
  const { availableOfferings } = useAppSelector((state) => state.services);
  const [isChangeCard, setIsChangeCard] = useState(false);
  const defaultCard = getCCDetails?.filter((data: any) => data.isDefault)[0];
  const availableOfferingsData = availableOfferings?.data?.addOnServices?.data;

  return (
    <>
      <IRPaymentReviewComp
        {...props}
        availableOfferingsData={availableOfferingsData}
        defaultCard={defaultCard}
        getCCDetails={getCCDetails}
        isChangeCard={isChangeCard}
        setIsChangeCard={setIsChangeCard}
      />
    </>
  );
}

export default PaymentReviewCard;
