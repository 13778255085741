import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import styles from "./HeaderItem.module.scss";
import { useAppSelector } from "../../../app/hooks";
interface IProps {
  itemName: string;
  isToggeledItem: any;
  onRequestOpenMenu: any;
  menuItems: Array<any>;
  closeMenu: any;
  buttonId?: string;
  menuId?: string;
  anchorEl: null | HTMLElement;
  showArrowIcon?: any;
  headerStyle?: any;
  showActiveIndicator?: boolean;
}
export const HeaderToggleMenuItem = (props: IProps) => {
  const {
    itemName,
    isToggeledItem,
    onRequestOpenMenu,
    menuItems,
    closeMenu,
    buttonId,
    menuId,
    anchorEl,
    showArrowIcon,
    headerStyle,
    showActiveIndicator,
  } = props;
  const { subDomainData } = useAppSelector((state: any) => state.homepage);
  return (
    <>
      <div
        className={
          showActiveIndicator &&
          menuItems
            ?.map((item) => item.navigationPath)
            .includes(window.location.pathname)
            ? `${styles.item_container} ${styles.active_header_item}`
            : styles.item_container
        }
        data-testid="header_profile_parent"
      >
        <Button
          className={
            anchorEl && isToggeledItem
              ? `${styles.active_toggle_button} ${styles.header_btn_txt}`
              : styles.header_btn_txt
          }
          onClick={onRequestOpenMenu}
          id={buttonId}
          aria-controls={isToggeledItem ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={isToggeledItem ? "true" : undefined}
          style={headerStyle}
          data-testid={buttonId}
        >
          {itemName}
          {showArrowIcon ? (
            isToggeledItem ? (
              <KeyboardArrowUp
                className={styles.arrow_up_key}
                data-testid="header_profile_arrow_up"
              />
            ) : (
              <KeyboardArrowDown data-testid="header_profile_arrow_down" />
            )
          ) : null}
        </Button>
        <Menu
          id={menuId}
          className={styles.header_menu}
          onClose={closeMenu}
          anchorEl={anchorEl}
          open={Boolean(isToggeledItem)}
          keepMounted
          MenuListProps={{
            "aria-labelledby": buttonId,
          }}
          disablePortal={true}
          data-testid={menuId}
        >
          {menuItems?.length > 0 &&
            menuItems?.map((item: any, index: number) => {
              if (
                !subDomainData?.data?.shopwareAccessKey &&
                (item?.label?.toLocaleLowerCase() === "wishlist" ||
                  item?.label?.toLocaleLowerCase() === "notifications" ||
                  item?.label?.toLocaleLowerCase() === "my orders")
              ) {
                return;
              }
              return (
                <MenuItem
                  key={index}
                  className={styles.menu_item}
                  onClick={item.action}
                  data-testid={`${menuId}_${Number(index) + 1 || 1}`}
                >
                  {item.label}
                </MenuItem>
              );
            })}
        </Menu>
      </div>
    </>
  );
};
HeaderToggleMenuItem.defaultProps = {
  showArrowIcon: true,
};
