import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getILDUsageHistory } from "../../../../../features/services/servicesSlice";
import _ from "lodash";
import { isTemplateExisits } from "../../../../../utils/commonFunctions";
import AppConfig from "../../../../../config/app.config.json";

const UsageHistoryComp = isTemplateExisits(
  AppConfig.PostActivationServices.UsageHistory
)
  ? require(`./${AppConfig.PostActivationServices.UsageHistory.template}_UsageHistory`)
      .default
  : null;

const UsageHistory = (props: any) => {
  const dispatch = useAppDispatch();

  const { getILDUsageHistoryData, getILDUsageHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  const { CustomerBillCycleDataUsage } = useAppSelector(
    (state) => state?.customer
  );
  const { group } = useAppSelector((state) => state.account);

  const [filteredData, setFilteredData] = useState([]);
  const memoizedData = useRef<any>([]);

  const [selectedLineValue, setSelectedLineValue] = useState({
    name: null,
    status: "",
  });
  const [walletuserData, setWalletUserData] = useState<any>({});
  const [userList, setUserList] = useState([
    {
      status: null,
      name: "All Lines",
    },
  ]);

  const [lineAnchorEl, setLineAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);
  const lineOpen = Boolean(lineAnchorEl);

  const customerDetailsJSON: any = localStorage.getItem("customerDetail");
  const customerDetail = JSON.parse(customerDetailsJSON);

  const getStartAndEndDateJSON: any = localStorage.getItem("StartEndDate");
  const getStartAndEndDateData = JSON.parse(getStartAndEndDateJSON);

  const handleLineClose = () => {
    setLineAnchorEl(null);
  };

  const handleLineItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: any
  ) => {
    setSelectedLineIndex(index);
    setSelectedLineValue(option);
    setLineAnchorEl(null);
  };
  const handleClickLineItem = (event: React.MouseEvent<HTMLElement>) => {
    setLineAnchorEl(event.currentTarget);
  };

  function convertDateFormat(inputDate: any) {
    // Create a Date object by parsing the original date string
    const dateObject = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObject.getFullYear();
    // Months are 0-based in JavaScript, so we add 1 to get the correct month
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  const startDate = convertDateFormat(getStartAndEndDateData.displayStartDate);
  const endDate = convertDateFormat(getStartAndEndDateData.displayEndDate);
  useEffect(() => {
    if (getILDUsageHistoryData) {
      const filteredDatas: any = Object.keys(getILDUsageHistoryData)
        .filter((item: any) => {
          return item === endDate;
        })
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: getILDUsageHistoryData[key],
          });
        }, {});
      setFilteredData(filteredDatas);
    } else {
      // Handle the case where getILDUsageHistoryData is null or empty
      // console.log("No data available.");
    }
  }, [endDate, selectedLineValue]);

  const extractCountryVoiceUsages = (data: any) => {
    const countryVoiceUsages = [];

    for (const dateKey in filteredData) {
      if (dateKey === endDate) {
        const usageDetail = data[dateKey]?.usageDetail;

        for (const id in usageDetail) {
          if (id == walletuserData?.id) {
            const countryUsageMap = usageDetail[id].countryUsageMap;

            if (countryUsageMap) {
              for (const countryName in countryUsageMap) {
                const voiceUsage = countryUsageMap[countryName].voiceUsage;
                countryVoiceUsages.push({ countryName, voiceUsage });
              }
            }
          }
        }
      }
    }

    return countryVoiceUsages;
  };

  memoizedData.current = extractCountryVoiceUsages(getILDUsageHistoryData);

  const getUserList = () => {
    let newuserArr = group.data
      .filter((user: any) => user.status == 2)
      .sort(
        (a: any, b: any) =>
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
      )
      .map((item: any) => ({
        name: item.firstName + " " + item.lastName,
        status: item.id,
      }));

    return _.uniqBy(newuserArr, function (e: any) {
      return e.status;
    });
  };
  useEffect(() => {
    if (getILDUsageHistoryData) {
      let filterUser = userList.filter(
        (x: any) => x.status === props?.lineDataForWallet.id
      );
      const indexUser = userList.findIndex((object: any) => {
        return object.status === props?.lineDataForWallet.id;
      });

      setUserList(getUserList());

      let selectedVal: any = {
        ...filterUser[0],
      };
      setSelectedLineValue(selectedVal);
      setSelectedLineIndex(indexUser);
    }
  }, [getILDUsageHistoryData]);

  useEffect(() => {
    let tempUser = group.data.filter(
      (lineData: any) => lineData.id === selectedLineValue.status
    );
    setWalletUserData(tempUser[0]);
    memoizedData.current = extractCountryVoiceUsages(getILDUsageHistoryData);
  }, [selectedLineValue]);

  useEffect(() => {
    dispatch(
      getILDUsageHistory({
        packId: customerDetail?.groupId,
      })
    );

    setWalletUserData(props?.lineDataForWallet);
  }, []);
  return (
    <>
      <UsageHistoryComp
        {...props}
        userList={userList}
        handleLineItemClick={handleLineItemClick}
        handleLineClose={handleLineClose}
        lineAnchorEl={lineAnchorEl}
        handleClickLineItem={handleClickLineItem}
        selectedLineIndex={selectedLineIndex}
        lineOpen={lineOpen}
        walletuserData={walletuserData}
        getStartAndEndDateData={getStartAndEndDateData}
        memoizedData={memoizedData}
      />
    </>
  );
};

export default UsageHistory;
