import WalletCard from "../HistoryCard/WalletCard";
import { useAppSelector } from "../../../../../app/hooks";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { format } from "date-fns";
import _ from "lodash";
import { CardTypes } from "../../../../../enums";

const T2_TransactionHistory = (props: any) => {
  const {
    date,
    amount,
    card,
    card_type,
    less_icon,
    greater_icon,
    no_transaction,
  } = props.content;

  const {
    toggleButtonText,
    handleViewMoreClick,
    walletuserData,
    getStartAndEndDateData,
    newFilteredData,
    itemsToShow,
    formatDate,
  } = props;
  const { getTransactionHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  return (
    <>
      {walletuserData ? (
        <WalletCard lineDataForWallet={walletuserData} content={props.content}>
          {!getTransactionHistoryPending ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  borderTop: "1px solid var(--lightnavy)",
                  borderBottom: "1px solid var(--lightnavy)",
                  p: "10px 0",
                }}
              >
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      props.selectedDateKeyIndex > 0
                    ) {
                      props.handleBillCycleChange("decrement");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={less_icon.data.attributes.url}
                    alt={less_icon.data.attributes.alternativeText}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_4)",
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {getStartAndEndDateData?.displayStartDate
                      ? format(
                          new Date(getStartAndEndDateData.displayStartDate),
                          "MMMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                  <span>-</span>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "var(--font_weight_4)",
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {getStartAndEndDateData?.displayEndDate
                      ? format(
                          new Date(getStartAndEndDateData.displayEndDate),
                          "MMMM dd, yyyy"
                        )
                      : null}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      props.selectedDateKeyIndex !==
                        getStartAndEndDateData.totalNumberOfKeys - 1
                    ) {
                      props.handleBillCycleChange("increment");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={greater_icon.data.attributes.url}
                    alt={greater_icon.data.attributes.alternativeText}
                  />
                </Box>
              </Box>
              {newFilteredData?.length === 0 && (
                <Box p={10}>
                  <Typography
                    sx={{
                      fontSize: "34px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      opacity: 0.4,
                    }}
                  >
                    {no_transaction}
                  </Typography>
                </Box>
              )}
              {newFilteredData?.length !== 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: { xs: "100%", md: "100%" },
                  }}
                >
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_4)",
                    }}
                  >
                    {date}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_4)",
                    }}
                  >
                    {amount}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_4)",
                    }}
                  >
                    {card}
                  </Typography>
                </Box>
              ) : null}
              {_.orderBy(newFilteredData, ["purchaseDate"], ["desc"])
                ?.slice(0, itemsToShow)
                .map((data: any) => (
                  <Box
                    key={data.uuid}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: { xs: "100%", md: "100%" },
                    }}
                  >
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "16px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_0)",
                        color: "var(--text_color)",
                      }}
                    >
                      {formatDate(data.purchaseDate)}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "16px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_0)",
                        color: "var(--text_color)",
                      }}
                    >
                      {GetCurrencyFixedToTwo(data.purchasedCredit)}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "16px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_0)",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--text_color)",
                      }}
                    >
                      <Box
                        component="img"
                        src={
                          data.cardType?.toUpperCase() === CardTypes?.VISA
                            ? card_type?.data[0]?.attributes?.url
                            : data.cardType?.toUpperCase() ===
                              CardTypes?.DISCOVER
                            ? card_type?.data[1]?.attributes?.url
                            : data.cardType?.toUpperCase() ===
                              CardTypes?.AMERICANEXPRESS
                            ? card_type?.data[2]?.attributes?.url
                            : data.cardType?.type?.toUpperCase() ===
                              CardTypes?.MASTERCARD
                            ? card_type?.data[3]?.attributes?.url
                            : card_type?.data[0]?.attributes?.url
                        }
                        alt={card_type.data[0].attributes.alternativeText}
                        width={26}
                        height={16}
                      />
                      xx
                      {data.ccNumberMask}
                    </Typography>
                  </Box>
                ))}
              <Divider />
              {newFilteredData?.length > 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    onClick={handleViewMoreClick}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      cursor: "pointer",
                      marginBottom: "5px",
                      textDecoration: "underline",
                    }}
                  >
                    {toggleButtonText()}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box textAlign={"center"}>
              <CircularProgress sx={{ color: "var(--primary_color)" }} />
            </Box>
          )}
        </WalletCard>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
    </>
  );
};

export default T2_TransactionHistory;
