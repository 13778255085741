import { Box, CircularProgress, Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import { useState } from "react";
import EnvConfig from "../../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  deleteFuturePack,
  getAllPacks,
  rescheduleFuturePack,
} from "../../../../features/services/servicesSlice";
import {
  getUTCDateIR,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import CustomDateCalendar from "../../../common/DateCalander/CustomDateCalendar";
import Config from "../../../../config/env.config.json";
import { format } from "date-fns";

const IRCancelReschedulePack = (props: any) => {
  const {
    cancel_pack,
    reschedule,
    ir_cancel,
    ir_pack_cancelled,
    reschedule_data,
  } = props.content;

  const {
    cancel_txt,
    reschedule_txt,
    reschedule_btn,
    ir_cancel_title,
    ir_cancel_text,
    ir_cancel_date,
    ir_cancel_suc_title,
    ir_cancel_suc_text,
    btn_style,
  } = props.styles;
  const dispatch = useAppDispatch();
  const {
    deleteFuturePackData,
    deleteFuturePackPending,
    rescheduleFuturePackData,
    rescheduleFuturePackPending,
  } = useAppSelector((state) => state.services);
  const [openModal, setOpenModal] = useState(false);
  const [isPackCancelled, setIsPackCancelled] = useState(false);
  const [isCalanderModal, setIsCalanderModal] = useState(false);
  let customerDetails: any = localStorage.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  const handleCancelPack = () => {
    dispatch(deleteFuturePack({ packId: props.packId }));
  };

  const handlePackCancelSuccess = () => {
    dispatch(getAllPacks({ groupId: customerDetails?.groupId }));
    setOpenModal(false);
  };

  useNonInitialEffect(() => {
    if (
      deleteFuturePackData &&
      deleteFuturePackData.data &&
      deleteFuturePackData.data.status === "SUCCESS"
    ) {
      setIsPackCancelled(true);
    }
  }, [deleteFuturePackData]);

  useNonInitialEffect(() => {
    if (
      rescheduleFuturePackData &&
      rescheduleFuturePackData.data &&
      rescheduleFuturePackData.data.status === "SUCCESS"
    ) {
      props.setRescheduleSuccess(true);
    }
  }, [rescheduleFuturePackData]);

  const calanderSubmitHandler = () => {
    dispatch(
      rescheduleFuturePack({
        packId: props.packId,
        data: { tentativeStartDate: props.selectedDate },
      })
    );
  };

  const calanderOnChangeHandler = (e: any) => {
    const formattedForUi = formatDateForUI(e);
    props.setSelectedDate(formattedForUi);
  };

  const formatDateForUI = (e: any) => {
    const date = new Date(e);
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  return (
    <Box component="div" data-testid={`IR_cancl_rech_wrapper_${Number(props.packId) + 1 || 1}`}>
      <Box className={reschedule_btn}>
        {/* <Box className={cancel_txt} onClick={() => setOpenModal(true)}>
          {cancel_pack}
        </Box>
        <Box
          className={reschedule_txt}
          onClick={() => setIsCalanderModal(true)}
          sx={{
            "&:hover": {
              backgroundColor: "var(--button_hover_color) !important",
              border: "1px solid var(--button_hover_color) !important",
            },
          }}
        >
          {reschedule}
        </Box> */}
        <ButtonCust
          data-testid={`IR_cancl_rech_btn_${Number(props.packId) + 1 || 1}`}
          variantType={Config.TERTIARY_BUTTON_TYPE}
          sx={{
            minWidth: "250px !important",
            width: "250px !important",
            color: "var(--text_color) !important",
            // "&:hover": {
            //   border: "1px solid var(--danger) !important",
            // },
          }}
          className={cancel_txt}
          onClick={() => setOpenModal(true)}
        >
          {cancel_pack}
        </ButtonCust>
        <ButtonCust
          data-testid={`IR_cancl_rech_btn2_${Number(props.packId) + 1 || 1}`}
          variantType={Config.PRIMARY_BUTTON_TYPE}
          sx={{
            minWidth: "250px  !important",
            width: "250px  !important",
            // "&:hover": {
            //   backgroundColor: "var(--button_hover_color) !important",
            //   border: "1px solid var(--button_hover_color) !important",
            // },
          }}
          className={reschedule_txt}
          onClick={() => setIsCalanderModal(true)}
        >
          {reschedule}
        </ButtonCust>
      </Box>
      <SimpleModal
        isShowModal={openModal ? true : false}
        onCloseModal={() => setOpenModal(false)}
        showClose={true}
        sx={{
          width: { xs: "90%", sm: "60%", md: "80%", lg: "45%", xl: "35%" },
          borderRadius: "6px",
        }}
      >
        {!isPackCancelled ? (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Box className={ir_cancel_title} data-testid={`IR_cancl_rech_title_${Number(props.packId) + 1 || 1}`}>{ir_cancel?.title}</Box>
            {props.isDivider && <Box className={props.styles.line}></Box>}
            <Box my={props?.dataInline ? "20px" : "0px"}>
              <Box
                className={ir_cancel_text}
                component={props?.dataInline ? "span" : "div"}
                data-testid={`IR_cancl_rech_cnl_txt_${Number(props.packId) + 1 || 1}`}
              >
                {ir_cancel?.text1?.replace(
                  /passType/g,
                  props?.data?.offeringDisplayName
                )}
              </Box>
              {props.scheduledDate && (
                <Box
                  className={ir_cancel_date}
                  component={props?.dataInline ? "span" : "div"}
                  data-testid={`IR_cancl_rech_date_${Number(props.packId) + 1 || 1}`}
                >
                  {format(
                    getUTCDateIR(new Date(props.scheduledDate)),
                    "hh:mmaaa"
                  )}{" "}
                  {ir_cancel?.text2}{" "}
                  {format(
                    getUTCDateIR(new Date(props.scheduledDate)),
                    "MMMM dd, yyyy."
                  )}
                </Box>
              )}
            </Box>
            <Box>
              <ButtonCust
                data-testid={`IR_cancl_rech_btn_3_${Number(props.packId) + 1 || 1}`}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                sx={{
                  my: "10px",
                  width: {
                    xs: "250px",
                  },
                  height: "45px",
                  // "&:hover": {
                  //   backgroundColor: "var(--button_hover_color) !important",
                  //   border: "1px solid var(--button_hover_color) !important",
                  // },
                }}
                onClick={handleCancelPack}
              >
                {deleteFuturePackPending ? (
                  <CircularProgress />
                ) : (
                  ir_cancel?.button9
                )}
              </ButtonCust>
            </Box>
            {ir_cancel?.cancel_txt && (
              <Box
                sx={{
                  my: "10px",
                  textDecoration: "underline",
                  fontWeight: "var(--font_weight_4)",
                  cursor: "pointer",
                }}
                data-testid={`IR_cancl_rech_cncl_txt_${Number(props.packId) + 1 || 1}`}
                onClick={() => setOpenModal(false)}
              >
                {ir_cancel?.cancel_txt}
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {ir_pack_cancelled.addOn_status && (
              <Box
                component="img"
                alt={
                  ir_pack_cancelled.addOn_status?.data?.attributes
                    ?.alternativeText || ""
                }
                src={
                  ir_pack_cancelled.addOn_status?.data?.attributes?.url || ""
                }
                data-testid={`IR_cancl_rech_img_${Number(props.packId) + 1 || 1}`}
              />
            )}
            <Box className={ir_cancel_suc_title} data-testid={`IR_cancl_rech_suc_title_${Number(props.packId) + 1 || 1}`}>
              {ir_pack_cancelled.title?.replace(
                /passType/g,
                props?.data?.offeringDisplayName
              )}
            </Box>
            <Box className={ir_cancel_suc_text} data-testid={`IR_cancl_rech_suc_text_${Number(props.packId) + 1 || 1}`}>
              {ir_pack_cancelled?.desc?.replace(
                "{_totalCost}",
                props?.data?.totalCost
              )}
              {/* {ir_pack_cancelled?.desc} */}
            </Box>
            <Box>
              <ButtonCust
              data-testid={`IR_cancl_rech_btn_4_${Number(props.packId) + 1 || 1}`}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                sx={{
                  my: "10px",
                  width: {
                    xs: "45%",
                  },
                  height: "45px",
                  // "&:hover": {
                  //   backgroundColor: "var(--button_hover_color) !important",
                  //   border: "1px solid var(--button_hover_color) !important",
                  // },
                }}
                onClick={handlePackCancelSuccess}
              >
                {ir_pack_cancelled?.button}
              </ButtonCust>
            </Box>
          </Box>
        )}
      </SimpleModal>
      <CustomDateCalendar
        onClick={calanderSubmitHandler}
        onChange={calanderOnChangeHandler}
        isCalanderModal={isCalanderModal}
        onCloseModal={setIsCalanderModal}
        title={reschedule_data.title}
        desc={reschedule_data.desc}
        button={reschedule_data.btn_2}
        variant={Config.QUATERNARY_BUTTON_TYPE}
        rescheduleFuturePackPending={rescheduleFuturePackPending}
        titleStyle={{ color: "var(--text_color)" }}
        descStyle={{
          fontSize: "16px",
          lineHeight: "26px",
          color: "var(--text_color)",
          fontFamily: "var(--font_family_Regular) !important",
        }}
      />
    </Box>
  );
};

export default IRCancelReschedulePack;
