import Config from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
const IRUserListComp = isTemplateExisits(
  Config.PostActivationServices.IRUserList
)
  ? require(`./${Config.PostActivationServices.IRUserList.template}_IRUserList`)
      .default
  : null;

const IRUserList = (props: any) => {
  return (
    <>
      <IRUserListComp {...props} />
    </>
  );
};

export default IRUserList;
