import { useState, useEffect } from "react";
import styles from "./S6_ReviewPlanDetails.module.scss";
import { Typography, Grid, Box, Checkbox, Skeleton } from "@mui/material";
// import data from "../../../config/data.json";
import { sortByKey } from "../../../utils/commonFunctions/SortByKey";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAdditionalLineCharges,
  getLineCharges,
  getPlanTaxableAmount,
} from "./BillService";
import firebase from "../../../adapters/firebase";
import {
  getActivationTax,
  getMessageDetails,
  getOneTimeShippingTaxCharges,
  getPlanTaxCharges,
  resetCoupon,
  resetOneTimeShippingTaxCharges,
  resetOneTimeSimTaxCharges,
  resetPlanTaxCharges,
  updateCouponDetails,
} from "../../../features/checkout/checkoutSlice";
import {
  addressType,
  serviceCodes,
  SimTypes,
  TaxTypes,
} from "../../../features/checkout/Types";
import {
  isInValidAddressInfo,
  isInvalid,
  setCartHeader,
  updateSvgFillColor,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NotificationCust } from "../../widgets/NotificationCust/NotificationCust";
import EnvConfig from "../../../config/env.config.json";
import { CouponTypes } from "../../../features/checkout/CouponTypes";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { ButtonCust } from "../../widgets";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import {
  BrandNameUpdate,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import { priceCalculationHandler } from "../../home/PlanCard/PlanCardsComponent/T13_PlanCard";
import { PaymentButtons } from "../PaymentButtons/PaymentButtons";
import unlimitedPlanDiscount from "../../../config/discountCouponMNM.json";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import { reachPlanData } from "../../../features/homepage/homepageSlice";
import { OfferCodeCmp } from "../../common";
import { queryContent } from "../../../pages/Home/Home";

const UNLIMITED_PLAN_DISCOUNT_COUPON = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../config/discountCouponsUnlimited.json")
  : null;
const SHARABLE_PLAN_DISCOUNT_COUPON = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../config/discountCouponsRegular.json")
  : null;
type Props = {
  formik: any;
  shipmentPlans: any;
  content: any;
  simPrefenceContent?: any;
  simPreference: any;
  setSimPreference: any;
  simInHandCheck: any;
  formikForPlanCard?: any;
  step?: any;
  handleNextStep?: any;
  paymentClicked?: any;
  navigate?: any;
  primaryLineError?: any;
  multiSimInHandCheck?: any;
  checkoutPageOpsMessages?: any;
  oneTimeTotalTax?: any;
  setOneTimeTotalTax?: any;
  ShipAndHandlingAmount?: any;
  setShipAndHandlingAmount?: any;
  addToCartApiWithOfferCode?: any;
};

export const costCalculation = (
  data: any = null,
  planDetails: any,
  contents: any
) => {
  let couponData: any = data ? data : null;
  let coupontype: any = CouponTypes;
  let plan: any = planDetails?.selectedPlan;
  let line: number = planDetails.numberOfLines;
  let basePrice: number = plan && plan.baseLinePrice;
  let additionalPrice: number = getAdditionalLineCharges(planDetails);
  let totalPrice: number = basePrice + additionalPrice;
  let discount: number = 0;
  let primaryDiscountAmount = 0;
  let secondaryDiscountAmount = 0;
  /**
   * The brand which is having fixed discounts for unlimited and shared plans.
   * */
  if (couponData && EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE && plan?.name) {
    if (plan?.name === EnvConfig.SPECIAL_DISCOUNT_PLAN_NAME) {
      primaryDiscountAmount = couponData?.discountInDollar || 0;
      if (line > 1) {
        secondaryDiscountAmount =
          (line - 1) * couponData?.secondaryDiscountInDollar || 0;
      }
    } else {
      primaryDiscountAmount = plan?.isUnlimited
        ? UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.discountInDollar
        : SHARABLE_PLAN_DISCOUNT_COUPON?.data?.discountInDollar;
      secondaryDiscountAmount = plan?.isUnlimited
        ? (line - 1) *
          UNLIMITED_PLAN_DISCOUNT_COUPON?.data?.secondaryDiscountInDollar
        : (line - 1) *
          SHARABLE_PLAN_DISCOUNT_COUPON?.data?.secondaryDiscountInDollar;
    }
  }
  if (couponData && !EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE) {
    if (couponData && couponData.expired) {
      NotificationCust({
        message: contents?.invalid_coupon_err,
        type: "danger",
        duration: 5000,
        id: "CouponExpired",
        showIcon: true,
      });
    } else {
      localStorage.setItem("couponDetails", JSON.stringify(couponData));
      if (coupontype[couponData && couponData.type]?.type === "PROMO") {
        //promo
        if (couponData && couponData.discountInDollar) {
          if (totalPrice > couponData.discountInDollar) {
            discount = couponData.discountInDollar;
            primaryDiscountAmount = couponData.discountInDollar;
            secondaryDiscountAmount = couponData.secondaryDiscountInDollar;
          } else {
            discount = totalPrice;
            primaryDiscountAmount = totalPrice;
            secondaryDiscountAmount = couponData.secondaryDiscountInDollar;
          }
        }
        if (couponData && couponData.planDiscount) {
          discount += (couponData.planDiscount * basePrice) / 100;
          if (couponData && couponData.secondaryDiscount) {
            discount += (couponData.secondaryDiscount * additionalPrice) / 100;
          }
          if (
            couponData.maxDiscountInDollar &&
            discount > couponData.maxDiscountInDollar
          ) {
            discount = couponData.maxDiscountInDollar;
          }
        }
      } else if (
        coupontype[couponData && couponData.type]?.type === "REFERRAL"
      ) {
        //referal
        let coupon1: any = { couponCode: couponData.coupon, type: 3 };
        let coupon2: any = { couponCode: couponData.coupon };

        localStorage.setItem("reachPurhcaseReferralUserCoupon", coupon1);
        localStorage.setItem("reachPurhcaseUserCoupon", coupon2);
        if (couponData && couponData.refereeDiscInDollar) {
          discount = couponData.refereeDiscInDollar;
        } else {
          if (couponData && couponData.refereeDiscount) {
            discount = (couponData.refereeDiscount * basePrice) / 100;
          }
          // if (couponData && couponData.maxDiscountInDollar) {
          //   discount = Math.min(couponData.maxDiscountInDollar, discount);
          // }
          if (
            couponData.maxDiscountInDollar &&
            discount > couponData.maxDiscountInDollar
          ) {
            discount = couponData.maxDiscountInDollar;
          }
        }
      } else {
        if (couponData && couponData?.planDiscount) {
          discount += (couponData.planDiscount * basePrice) / 100;
          if (couponData && couponData.secondaryDiscount) {
            discount += (couponData.secondaryDiscount * additionalPrice) / 100;
          }
        }
      }
    }
  }

  return {
    discountAmount: discount,
    totalCost: totalPrice,
    basePrice: basePrice,
    additionalPrice: additionalPrice,
    primaryDiscount: primaryDiscountAmount,
    secondaryDiscount: secondaryDiscountAmount,
    totalWithDiscount: EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
      ? totalPrice - primaryDiscountAmount - secondaryDiscountAmount
      : totalPrice - discount,
  };
};

const T7_ReviewPlan = (props: Props) => {
  const {
    formik,
    shipmentPlans,
    content,
    simPrefenceContent,
    simPreference,
    setSimPreference,
    simInHandCheck,
    formikForPlanCard,
    step,
    handleNextStep,
    paymentClicked,
    navigate,
    primaryLineError,
    multiSimInHandCheck,
    checkoutPageOpsMessages,
    oneTimeTotalTax,
    setOneTimeTotalTax,
    ShipAndHandlingAmount,
    setShipAndHandlingAmount,
    addToCartApiWithOfferCode,
  } = props;
  const { planData } = useAppSelector((state: any) => state.homepage);
  useEffect(() => {
    if (!planData) {
      dispatch(reachPlanData({}));
    }
  }, []);
  let plandata =
    planData && planData.data && planData.data.length > 0
      ? sortByKey(planData && planData.data, "planData")
      : [];
  const [simCharges, setSimCharges] = useState(0);
  const [physicalSimPrefCharges, setPhysicalSimPrefCharges] = useState(0);
  const dispatch = useAppDispatch();
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const {
    planDetails,
    planTaxCharges,
    oneTimeSIMTaxCharges,
    oneTimeShippingTaxCharges,
    errorgetOneTimeSimTaxCharges,
    isLoadedShippingTaxes,
    errorOneTimeShippingTaxCharges,
    coupon,
    errorCoupon,
    errorUpdateCustomerSource,
    activationTax,
    isLoadedPlanTaxCharges,
    isLoadedActivationTax,
    errorPlanTaxCharges,
    messageDetails,
  } = useAppSelector((state: any) => state.checkout);
  const [planTaxAmount, setPlanTaxAmount] = useState(0);
  let location: any = new URLSearchParams(document.location.search);
  const [appliedCouponCharges, setAppliedCouponCharges] = useState<number>(0);
  const [primaryDiscount, setPrimaryDiscount] = useState<number>(0);
  const [secondaryDiscount, setSecondaryDiscount] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [showSimChangePrefernceDialog, setShowSimChangePrefernceDialog] =
    useState(false);
  const [eSimKitBasePrice, setESimKitBasePrice] = useState(0);
  const [eSimSecondarySimPrice, setESimSecondarySimPrice] = useState(0);
  const [welcomeKitCost, setWelcomeKitCost] = useState(0);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [activationFee, setActivationFee] = useState<number>(0);
  const [activationTaxes, setActivationTaxes] = useState<number>(0);
  const [totalTax, setTotalTax] = useState<any>(0);
  const [planTaxes, setPlanTaxes] = useState<any>([]);
  const [termsErrorMsg, setTermsErrorMsg] = useState<string>("");
  const [displayMessageDetails, setDisplayMessageDetails] = useState([]);
  const {
    activation_fee,
    due_today,
    esim,
    monthly_charges,
    monthly_service,
    next,
    note,
    note_desc1,
    note_desc2,
    note_desc3,
    proceed_to_pay,
    shipping_charges,
    esim_charges,
    sim,
    sim_type,
    taxes,
    your_plan,
    nickname,
    oneTime_charges,
    primary_line_error,
    termsError,
    linename,
    text_color,
    simInHand,
    taxes1,
    due_today1,
    total_one_time_Charge,
  } = content?.reviewPlanData;

  const { TandC } = content;

  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { checkoutCompatabilityState, multiSimVerificationState } =
    useAppSelector((state: any) => state.checkout);
  useEffect(() => {
    getSIMCharges();
  }, [planDetails.numberOfLines, simPreference]);

  const getShipmentPlanByLabel = (label: string) => {
    return shipmentPlans.find((sPlan: any) => sPlan.value === label);
  };
  const shipmentType =
    getShipmentPlanByLabel(formik.values.shipmentPlan)?.name || "";
  const selectedShipmentMethod =
    getShipmentPlanByLabel(formik.values.shipmentPlan) || "";
  let shipmentCharges = getShipmentPlanByLabel(
    formik.values.shipmentPlan
  )?.shippingAmount;
  let taxableAmount = getPlanTaxableAmount(planDetails);
  let simPreferenceStorage: any = JSON.parse(
    localStorage.getItem("simPreference") || "[]"
  );
  const isEsimSelected = formikForPlanCard?.values?.some(
    (line: any, i: number) => {
      return line.simPreference === SimTypes.eSim;
    }
  );
  const isPhysicalSelected = formikForPlanCard?.values?.some(
    (line: any, i: number) => {
      return line.simPreference === SimTypes.phySim;
    }
  );

  const numOfEsims = formikForPlanCard?.values?.filter(
    (data: any) => data.simPreference === SimTypes.eSim
  ).length;

  const numOfPsims = formikForPlanCard?.values?.filter(
    (data: any) => data.simPreference === SimTypes.phySim && !data.simInHand
  ).length;
  const totalSimLines = simPreferenceStorage?.filter(
    (data: any) => !data?.eSimCompatible
  ).length;

  const totalEsimLines = simPreferenceStorage?.filter(
    (data: any) => data?.eSimCompatible
  ).length;
  /**
   * To fetch plan tax charges need billing address and customer fname and lname is mandatory
   */
  useEffect(() => {
    fetchBillingCalculations();
  }, [planDetails, appliedCouponCharges, taxableAmount, coupon]);

  useEffect(() => {
    setPlanTaxes([]);
  }, [formik.values.billingAddress]);

  useEffect(() => {
    if (
      formik?.values?.billingAddress &&
      formik?.values?.billingAddress["zip"]
    ) {
      fetchBillingCalculations();
    }
  }, [planTaxes]);

  useEffect(() => {
    let newTotalTax = 0;
    let totalShipAndHandlingAmount = 0;
    if (numOfPsims > 0) {
      let primaryPsimAmount = 0;
      primaryPsimAmount =
        shipmentCharges + selectedShipmentMethod?.additionalLineCharges?.cost;
      totalShipAndHandlingAmount += primaryPsimAmount;
      let pSimTaxAmount = 0;
      if (numOfPsims > 1) {
        for (let i = 0; i < numOfPsims - 1; i++) {
          pSimTaxAmount += selectedShipmentMethod
            ? selectedShipmentMethod?.additionalLineCharges?.cost +
              selectedShipmentMethod?.additionalLineCharges?.shippingAmount
            : 0;
        }
        totalShipAndHandlingAmount += pSimTaxAmount;
      }
    }
    if (numOfEsims > 0) {
      let eSimTaxAmount = 0;
      for (let i = 0; i < numOfEsims; i++) {
        eSimTaxAmount += selectedShipmentMethod
          ? selectedShipmentMethod?.additionalLineCharges?.eSimCost
          : 0;
      }
      // eSimTaxAmount = eSimTaxAmount * numOfEsims
      totalShipAndHandlingAmount += eSimTaxAmount;
      // setOneTimeTotalTax(newTotalTax);
    }
    setShipAndHandlingAmount(totalShipAndHandlingAmount);
  }, [formik.values, formikForPlanCard?.values, numOfPsims, numOfEsims]);

  useEffect(() => {
    if (messageDetails && messageDetails?.length > 0) {
      let arr: any = [];
      {
        messageDetails?.length > 0 &&
          messageDetails?.map((item: any, index: number) => {
            if (
              item?.inventory[
                "messaging_center.website.order_confirmation_page"
              ]
            ) {
              arr.push(item);
            }
          });
      }
      setDisplayMessageDetails(arr);
    }
  }, [messageDetails]);

  const fetchBillingCalculations = async () => {
    if (
      appliedCouponCharges >= 0 ||
      primaryDiscount >= 0 ||
      secondaryDiscount >= 0
    ) {
      taxableAmount = getPlanTaxableAmount(planDetails);
      const primaryPlan =
        formikForPlanCard?.values?.find((item: any) => item.isPrimary)?.plan ||
        {};
      taxableAmount = getPlanTaxableAmount({
        numberOfLines: formikForPlanCard?.values?.length > 1,
        selectedPlan: primaryPlan,
      });
      if (coupon) {
        const costCalculationData: any = costCalculation(
          coupon,
          {
            numberOfLines: formikForPlanCard?.values?.length > 1,
            selectedPlan: primaryPlan,
          },
          content
        );
        taxableAmount = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
          ? taxableAmount -
            costCalculationData.primaryDiscount -
            costCalculationData.secondaryDiscount
          : taxableAmount - (costCalculationData?.discountAmount || 0);
        setAppliedCouponCharges(costCalculationData?.discountAmount);
        setPrimaryDiscount(costCalculationData?.primaryDiscount);
        setSecondaryDiscount(costCalculationData?.secondaryDiscount);
        getPlanTaxInfo(taxableAmount);
      } else {
        getPlanTaxInfo(taxableAmount);
      }
    }
  };

  const getTotalTax = async (address: any) => {
    let newTotalTax = 0;
    let totalShipAndHandlingAmount = 0;

    if (numOfPsims > 0) {
      let primaryPsimAmount =
        shipmentCharges + selectedShipmentMethod?.additionalLineCharges?.cost;
      totalShipAndHandlingAmount += primaryPsimAmount;

      // Await dispatch and update tax
      const primaryTaxRes = await dispatch(
        getOneTimeShippingTaxCharges({
          address: {
            ...address,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: primaryPsimAmount || 0,
          type:
            formik.values.shipmentPlan === serviceCodes.first_calss
              ? TaxTypes.STANDARD_SIM_SHIPPMENT
              : TaxTypes.EXPRESS_SIM_SHIPPMENT,
        })
      );
      if (primaryTaxRes?.payload?.data?.status === "SUCCESS") {
        newTotalTax += primaryTaxRes?.payload?.data?.data?.totalTax;
      }

      let pSimTaxAmount = 0;
      if (numOfPsims > 1) {
        for (let i = 0; i < numOfPsims - 1; i++) {
          pSimTaxAmount += selectedShipmentMethod
            ? selectedShipmentMethod?.additionalLineCharges?.cost +
              selectedShipmentMethod?.additionalLineCharges?.shippingAmount
            : 0;
        }
        totalShipAndHandlingAmount += pSimTaxAmount;

        // Await dispatch
        const additionalTaxRes = await dispatch(
          getOneTimeShippingTaxCharges({
            address: {
              ...address,
              name: `${formik.values.firstName} ${formik.values.lastName}`,
            },
            amount: pSimTaxAmount,
            additionalLines: formikForPlanCard?.values.length - 1,
            type: TaxTypes.ADDITIONAL_LINE_SIM_SHIPPMENT,
          })
        );
        if (additionalTaxRes?.payload?.data?.status === "SUCCESS") {
          newTotalTax += additionalTaxRes?.payload?.data?.data?.totalTax;
        }
      }
    }

    if (numOfEsims > 0) {
      let eSimTaxAmount = 0;
      for (let i = 0; i < numOfEsims; i++) {
        eSimTaxAmount += selectedShipmentMethod
          ? selectedShipmentMethod?.additionalLineCharges?.eSimCost
          : 0;
      }
      totalShipAndHandlingAmount += eSimTaxAmount;

      // Await dispatch
      const eSimTaxRes = await dispatch(
        getOneTimeShippingTaxCharges({
          address: {
            ...address,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: eSimTaxAmount,
          type: TaxTypes.E_SIM,
          additionalLines: formikForPlanCard?.values.length - 1,
        })
      );
      if (eSimTaxRes?.payload?.data?.status === "SUCCESS") {
        newTotalTax += eSimTaxRes?.payload?.data?.data?.totalTax;
      }
    }
    setShipAndHandlingAmount(totalShipAndHandlingAmount);
    setOneTimeTotalTax(newTotalTax);
  };

  const checkIfIMEICheckInProgress = (collection: any, key: string) =>
    collection.every((obj: any) => !obj[key]);
  const resetPlanTaxInfo = () => {
    setTimeout(() => {
      setPlanTaxAmount(0);
      dispatch(resetPlanTaxCharges());
    }, 1000);
    setPlanTaxAmount(0);
    dispatch(resetPlanTaxCharges());
  };

  const checkIfSimInHandCheckInProgress = (collection: any, key: string) =>
    collection.every((obj: any) => !obj[key]);

  const handlePaymentSubmit = () => {
    // if (isChecked) {
    //   setTermsErrorMsg("");
    formik.handleSubmit();
    // } else {
    //   setTermsErrorMsg(termsError);
    // }
  };

  const getPlanTaxInfo = async (taxableAmountValue: number) => {
    plandata?.map(async (plan: any, i: any) => {
      if (
        !isInValidAddressInfo(formik.values.billingAddress) &&
        plan.baseLinePrice > 0
      ) {
        try {
          const response = await dispatch(
            getPlanTaxCharges({
              address: {
                ...formik.values.billingAddress,
                name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
              },
              reachPlanId: plan.name,
              amount: plan.baseLinePrice,
              // discountMap: {
              //   DISCOUNT: unlimitedPlanDiscount.data[0].discountInDollar,
              // },
            })
          );
          if (response?.payload?.status === 200) {
            let tax = response?.payload?.data?.data?.totalTax;

            if (!planTaxes?.find((obj: any) => obj[plan.name])?.[plan.name]) {
              let taxes = planTaxes;
              taxes?.push({ [plan.name]: tax });
              setPlanTaxes(taxes);
            }
          }
          return response;
        } catch (error) {
          console.log(error);
        }
      } else {
        resetPlanTaxInfo();
      }
    });
  };

  const getTotalPlanTaxInfo = async (taxableAmountValue: number) => {
    if (
      !isInValidAddressInfo(formik.values.billingAddress) &&
      taxableAmountValue > 0
    ) {
      try {
        const response = await dispatch(
          getPlanTaxCharges({
            address: {
              ...formik.values.billingAddress,
              name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
            },
            reachPlanId: plandata[0].name,
            amount: taxableAmountValue,
          })
        );
        if (response?.payload?.status === 200) {
          let tax = response?.payload?.data?.data?.totalTax;
          setTotalTax(tax);
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (coupon?.coupon) {
      let totalMonthlyCharges: number = 0;
      formikForPlanCard?.values?.map((line: any, i: any) => {
        totalMonthlyCharges =
          totalMonthlyCharges + priceCalculationHandler(line.plan);
      });
      if (totalMonthlyCharges > 0) {
        getTotalPlanTaxInfo(totalMonthlyCharges - totalDiscount);
      }
    } else {
      setTotalTax(0);
    }
  }, [
    totalDiscount,
    appliedCouponCharges,
    planDetails,
    appliedCouponCharges,
    taxableAmount,
    coupon,
    formik.values.billingAddress,
  ]);

  useEffect(() => {
    let shippingAddressInfo = formik.values.billingAddress;
    if (formik.values.isShippingSameAsBilling) {
      shippingAddressInfo = {
        ...formik.values.billingAddress,
        // type: addressType.shipping,
      };
    }

    if (!isInValidAddressInfo(shippingAddressInfo)) {
      // fetchOneTimeTaxCharges(shippingAddressInfo);
      configKeys?.SIM_COST_DESCRIPENCY_ENABLED
        ? getTotalTax(shippingAddressInfo)
        : fetchOneTimeTaxCharges(shippingAddressInfo);
    }
  }, [
    formik.values.shipmentPlan,
    formik.values.isShippingSameAsBilling,
    formik.values.billingAddress,
    formik.values.shippingAddress,
    simCharges,
    shipmentType,
  ]);

  const calculateActivationFee = () => {
    let totalCost = 0;
    for (let i = 1; i < formikForPlanCard.values.length + 1; i++) {
      totalCost += configKeys?.ACTIVATION_FEE_LINE[i];
    }
    return totalCost;
  };

  const fetchOneTimeTaxCharges = (address: any) => {
    let shipmentAmountCost = EnvConfig.INSTANT_DELIVERY_VIA_EMAIL_CHARGES;
    if (EnvConfig.IS_ESIM_SUPPORTED && isEsimSelected) {
      // to do shipment charges when all selected sims are eSim type
    }
    if (shipmentCharges > 0) {
      dispatch(
        getOneTimeShippingTaxCharges({
          address: {
            ...address,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: shipmentCharges || 0,
        })
      );
    } else {
      dispatch(resetOneTimeShippingTaxCharges());
    }
  };
  /**
   * Error handling for one-time tax calculation get api failure
   */
  useEffect(() => {
    if (
      (errorgetOneTimeSimTaxCharges &&
        errorgetOneTimeSimTaxCharges?.status === "FAILURE") ||
      (errorOneTimeShippingTaxCharges &&
        errorOneTimeShippingTaxCharges?.status === "FAILURE")
    ) {
      if (errorgetOneTimeSimTaxCharges?.resCode === 100001) {
        NotificationCust({
          message: content?.ot_tax_add_err,
          type: "danger",
          duration: 0,
          id: "addressFailure",
          showIcon: true,
        });
      }
    }
  }, [errorgetOneTimeSimTaxCharges, errorOneTimeShippingTaxCharges]);

  const getSIMCharges = async () => {
    const simCostDataBaseURLAddress = EnvConfig?.TENANT_ID
      ? `${EnvConfig.TENANT_ID}/perSimCost`
      : "/perSimCost";
    const perCost: any = await firebase
      .database()
      .ref(simCostDataBaseURLAddress);
    let kitBasePrice = 0;
    let secondarySimPrice = 0;
    perCost.on("value", (snapshot: any) => {
      const data = snapshot.val();
      // physical sim cost calculations
      kitBasePrice =
        data &&
        data.USA &&
        ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
      secondarySimPrice = kitBasePrice;
      if (data && data.USA && data.USA.subsequentCost) {
        secondarySimPrice =
          ((100 - data.USA.discountPctg) * data.USA.subsequentCost) / 100;
      }
      let simPref: any = JSON.parse(
        localStorage.getItem("simPreference") || "null"
      );

      let totalCost =
        kitBasePrice + secondarySimPrice * (planDetails.numberOfLines - 1);
      if (EnvConfig.IS_ESIM_SUPPORTED && simPref) {
        let numberOfPhysicalSIMs = simPref.filter((simItem: any) => {
          return simItem.value === SimTypes.sim;
        }).length;
        let numberOfPreferncePhysicalSIMs = simPreference.filter(
          (simItem: any) => {
            return simItem.value === SimTypes.sim;
          }
        ).length;
        const totalCostOfPreferedPhysicalSIMs =
          kitBasePrice +
          Number(secondarySimPrice * (numberOfPreferncePhysicalSIMs - 1));
        setPhysicalSimPrefCharges(Number(totalCostOfPreferedPhysicalSIMs));
        totalCost =
          kitBasePrice + secondarySimPrice * (numberOfPhysicalSIMs - 1);
      }
      setSimCharges(totalCost);
      // eSim cost calculations
      if (EnvConfig.IS_ESIM_SUPPORTED) {
        let eSimKitBasePrice = 0;
        let eSimSecondarySimPrice = 0;
        eSimKitBasePrice =
          data &&
          data.USA &&
          ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimCost) / 100;
        eSimSecondarySimPrice = eSimKitBasePrice;
        if (data && data.USA && data.USA.eSimSubsequentCost) {
          eSimSecondarySimPrice =
            ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimSubsequentCost) /
            100;
        }
        setESimKitBasePrice(eSimKitBasePrice);
        setESimSecondarySimPrice(eSimSecondarySimPrice);
        setWelcomeKitCost(totalCost);
      }
    });
  };
  useEffect(() => {
    if (coupon) {
      const costCalculationData = costCalculation(coupon, planDetails, content);
      setAppliedCouponCharges(costCalculationData?.discountAmount);
      setPrimaryDiscount(costCalculationData?.primaryDiscount);
      setSecondaryDiscount(costCalculationData?.secondaryDiscount);
    }
  }, [coupon, planDetails]);

  useEffect(() => {
    let couponDetails = localStorage.getItem("couponDetails");
    if (couponDetails && !coupon) {
      couponDetails = JSON.parse(couponDetails);
      dispatch(updateCouponDetails(couponDetails));
    }
  }, []);

  useEffect(() => {
    dispatch(getConfigKeys());
    if (!messageDetails && EnvConfig?.MESSAGE_CENTER) {
      dispatch(getMessageDetails(queryContent));
    }
  }, []);

  useNonInitialEffect(() => {
    if (configKeys?.IS_ACTIVATION_FEE_PER_LINE) {
      const fee = calculateActivationFee();
      setActivationFee(fee);

      dispatch(
        getActivationTax({
          address: {
            ...formik.values.billingAddress,
            name: `${formik.values.firstName} ${formik.values.lastName}`,
          },
          amount: fee ? fee : 0,
        })
      )
        .then((res: any) => {
          if (res?.payload?.data?.status === "SUCCESS") {
            setActivationTaxes(res?.payload?.data?.data?.totalTax);
          }
        })
        .catch((err: any) => console.log(err));
    }
  }, [
    configKeys,
    formikForPlanCard.values.length,
    formik?.values?.billingAddress?.zip,
  ]);
  /**
   * Error notifications handlers
   */
  // useEffect(() => {
  //   if (errorCoupon) {
  //     NotificationCust({
  //       message: errorCoupon,
  //       type: "danger",
  //       duration: 5000,
  //       id: "CouponFailureNotifier",
  //       showIcon: true,
  //     });
  //   }
  // }, [errorCoupon]);

  useEffect(() => {
    if (location?.message && location?.code) {
      NotificationCust({
        message: location?.message,
        type: "danger",
        duration: 2000,
        id: "PaymentGatewayFailure",
        showIcon: true,
      });
    }
  }, [location]);

  useEffect(() => {
    if (errorUpdateCustomerSource) {
      NotificationCust({
        message: content.user_upd_err,
        type: "danger",
        duration: 2000,
        id: "UserUpdateFailure",
        showIcon: true,
      });
    }
  }, [errorUpdateCustomerSource]);

  const planChargesTaxAmount = planTaxAmount || 0;

  const estimatedMonthlyBillAmount = EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE
    ? taxableAmount + planChargesTaxAmount - primaryDiscount - secondaryDiscount
    : taxableAmount + planChargesTaxAmount - appliedCouponCharges;

  let totalDueAmount: number = 0;

  const oneTimeTotalTaxChargesAmount: number =
    isPhysicalSelected && !multiSimInHandCheck
      ? activationTaxes + (oneTimeShippingTaxCharges?.totalTax || 0)
      : activationTaxes;

  if (EnvConfig.IS_ESIM_SUPPORTED) {
    totalDueAmount = isPhysicalSelected
      ? configKeys?.SIM_COST_DESCRIPENCY_ENABLED
        ? parseFloat(
            (oneTimeTotalTax + activationFee + ShipAndHandlingAmount).toFixed(2)
          )
        : parseFloat(
            (
              oneTimeTotalTaxChargesAmount +
              activationFee +
              shipmentCharges
            ).toFixed(2)
          )
      : configKeys?.SIM_COST_DESCRIPENCY_ENABLED
      ? parseFloat(
          (oneTimeTotalTax + activationFee + ShipAndHandlingAmount).toFixed(2)
        )
      : activationFee + activationTaxes;
  } else {
    totalDueAmount = configKeys?.SIM_COST_DESCRIPENCY_ENABLED
      ? activationFee + ShipAndHandlingAmount + oneTimeTotalTax
      : activationFee + shipmentCharges + oneTimeTotalTaxChargesAmount;
  }
  const handleCouponRemove = () => {
    setAppliedCouponCharges(0);
    setPrimaryDiscount(0);
    setSecondaryDiscount(0);
    localStorage.removeItem("couponDetails");
    dispatch(resetCoupon());
  };
  const personIcon = content?.person_ico?.data?.attributes?.url;
  const personIcon_alt = content?.person_ico?.data?.attributes?.alternativeText;
  const giftBoxIcon = content?.gift_box_ico?.data?.attributes?.url;
  const giftBoxIcon_alt =
    content?.gift_box_ico?.data?.attributes?.alternativeText;

  useEffect(() => {
    if (formikForPlanCard?.values?.length >= 1) {
      let discount = 0;
      let primaryDiscount = appliedCouponCharges;
      if (coupon) {
        formikForPlanCard?.values?.forEach((item: any) => {
          // if (!item.isPrimary && item.plan?.baseLinePrice) {
          //   discount +=
          //     (item?.plan?.baseLinePrice * coupon?.planDiscount) / 100;
          // } else {
          //   primaryDiscount =
          //     (item?.plan?.baseLinePrice * coupon?.planDiscount) / 100;
          // }
          const couponDiscountInDollar =
            coupon?.subType === 2
              ? item?.isPrimary
                ? coupon?.discountInDollar
                : coupon?.secondaryDiscountInDollar
              : item?.isPrimary
              ? (coupon?.planDiscount / 100) * item.plan?.baseLinePrice
              : (coupon?.secondaryDiscount / 100) * item.plan?.baseLinePrice;
          discount += couponDiscountInDollar;
        });
        setPrimaryDiscount(primaryDiscount);
        // setAppliedCouponCharges(primaryDiscount);
      } else {
        discount = 0;
      }
      setTotalDiscount(discount ?? 0);
    }
  }, [formikForPlanCard, coupon, appliedCouponCharges]);

  const getPrimaryUserSimType = () => {
    return simPreferenceStorage[0]?.eSimCompatible
      ? `(${getLabel(simPrefenceContent?.esim_txt)})`
      : `(${getLabel(simPrefenceContent?.sim_txt)})`;
  };
  const getLabel = (text: string, value: number = 0) => {
    return text + (value > 1 ? "'s" : "");
  };
  // To show banner at offer info
  // const textBannerImg = "https://strapi-config-images.s3.amazonaws.com/singleWebsite-dev/rectangle_d43e16f69d.webp"// content?.text_banner?.data?.attributes?.url;
  const getQuantity = (p: any) => {
    let quantity = 0;
    formikForPlanCard?.values.forEach((line: any) => {
      return line?.plan?.displayName === p?.displayName && quantity++;
    });
    return quantity;
  };

  const calcPlanTaxes = () => {
    let pTax = 0;
    if (planTaxes?.length > 0) {
      formikForPlanCard.values?.map((line: any, index: number) => {
        planTaxes?.map((plan: any, i: number) => {
          // let q: number = getQuantity(line.plan);
          const planName = Object.keys(planTaxes[i]);
          const tax: any = Object.values(plan);
          if (line?.plan?.name == planName) {
            pTax = pTax + 1 * tax;
          }
        });
      });
    }
    return pTax;
  };

  let totalMonthlyCharges: number = 0;
  formikForPlanCard?.values?.map((line: any, i: any) => {
    totalMonthlyCharges =
      totalMonthlyCharges + priceCalculationHandler(line.plan);
  });
  totalMonthlyCharges =
    totalMonthlyCharges + (coupon?.coupon ? totalTax : calcPlanTaxes());
  const line = () => {
    return (
      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: "1px solid var(--lightgrey_7)",
            margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>
    );
  };

  const separator = () => {
    return (
      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: "1px solid var(--concrete-grey)",
            margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>
    );
  };
  const separator1 = () => {
    return (
      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: "1px dotted var(--concrete-grey)",
            margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>
    );
  };

  return (
    <Grid
      container
      className={`${styles.Review_plan} checkout_review_plan_card`}
      sx={{
        padding: { xs: "20px 20px", md: "24px 24px" },
        borderRadius: "10px",
        background: "var(--white)",
        // padding: "30px",
        mb: "20px",
        mt: { xs: "40px", md: "72px" },
        opacity:
          errorOneTimeShippingTaxCharges || errorPlanTaxCharges ? "10%" : "",
      }}
      data-testid="review_plan_parent"
    >
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: { md: "14px", sm: "15px", xs: "10px" },
            px: { xs: 0, md: 0 },
            fontSize: { xs: "18px !important", md: "18px !important" },
            color: {
              xs: "var(--black) !important",
              sm: text_color ? `${text_color} !important` : "",
            },
            lineHeight: { xs: "27px", sm: "26px" },
            fontFamily: "var(--font_family_Semibold) !important",
            fontWeight: "var(--font_weight_2) !important",
          }}
          className={styles.review_header}
          data-testid="review_plan_your_plan"
        >
          {your_plan}
        </Typography>
        {separator()}
      </Grid>

      <Grid
        item
        container
        display="flex"
        sx={{
          justifyContent: { sm: "space-between", xs: "initial", ms: "initial" },
          px: { xs: 0, md: 0 },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          sx={{ pr: { sm: "0px", md: "0px", xs: "0px" } }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              sx={{
                mb: { md: "12px", xs: "10px" },
                fontWeight: "var(--font_weight_2) !important",
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade) !important",
              }}
              className={styles.review_sub_header}
              data-testid="review_plan_montly_service"
            >
              {monthly_service}
            </Typography>
          </Grid>
          {/* Plan Details */}
          <Grid item container xs={12} className={styles.your_line}>
            {formikForPlanCard?.values?.map((line: any, i: any) => {
              const planPerLinesCost = priceCalculationHandler(line.plan);

              return (
                // <Box key={i}>
                <>
                  <Grid item xs={9} textAlign="left">
                    <Box>
                      <Typography
                        className={styles.your_line}
                        sx={{
                          fontFamily: "var(--font_family_Regular) !important",
                          fontWeight: "var(--font_weight_0) !important",
                          fontSize: "14px !important",
                          color: text_color ? `${text_color} !important` : "",
                          width: { xs: "90%", sm: "100%" },
                          // mt: "4px",
                        }}
                      >
                        {
                          line?.firstName
                            ? line?.firstName + " " + line?.lastName
                            : linename + " " + (i + 1)
                          // Removed below code as per Hani's request
                          // .toString().padStart(2, "0")
                        }
                        {line?.isPrimary && " - Primary"}
                        {"  "}
                        {line?.simPreference ? (
                          <Typography
                            component={"span"}
                            sx={{
                              backgroundColor: "var(--light_primary_color)",

                              color: "var(--primary_color) !important",
                              fontFamily:
                                "var(--font_family_Regular) !important",
                              borderRadius: "2px",
                              padding: "5px 12px !important",
                              fontSize: "12px !important",
                              fontWeight: "var(--font_weight_2) !important",
                            }}
                            className={styles.your_line}
                          >
                            {line?.simPreference === SimTypes.phySim &&
                            line?.simInHand
                              ? simInHand
                              : line?.simPreference === SimTypes.eSim ||
                                line?.simPreference === SimTypes.ESIM
                              ? esim
                              : line?.simPreference === SimTypes.phySim
                              ? sim
                              : ""}
                          </Typography>
                        ) : null}
                      </Typography>
                      <Typography
                        className={styles.your_line}
                        sx={{
                          fontSize: "14px !important",
                          fontFamily: "var(--font_family_Regular) !important",
                          fontWeight: "var(--font_weight_0) !important",
                          color: text_color ? `${text_color} !important` : "",
                          // mb: "10px",
                        }}
                      >
                        {line?.plan?.displayNameWeb}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    textAlign="right"
                    sx={{
                      fontWeight: "var(--font_weight_0) !important",
                      fontFamily: "var(--font_family_Regular) !important",
                      color: "var(--button_secondary_color)",
                    }}
                    data-testid={`review_plan_amount_${Number(i) + 1 || 1}`}
                  >
                    {GetCurrencyFixedToTwo(line?.plan?.baseLinePrice)}
                  </Grid>
                  {coupon &&
                  appliedCouponCharges > 0 &&
                  !EnvConfig.IS_PROMO_CODE_DISCOUNT_SEPARATE ? (
                    <Grid item container xs={12} className={styles.tax_and_fee}>
                      <Grid
                        item
                        xs={8}
                        textAlign="left"
                        style={{ color: "var(--primary_brand_color)" }}
                        data-testid={`review_plan_name_coupon_${
                          Number(i) + 1 || 1
                        }`}
                      >
                        {/* {CouponTypes[coupon?.type].message} */}
                        {coupon?.name}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        textAlign="right"
                        style={{
                          color: "var(--primary_brand_color)",
                          fontSize: "14px",
                        }}
                        data-testid={`review_plan_coupon_cost_${
                          Number(i) + 1 || 1
                        }`}
                      >
                        {line?.isPrimary
                          ? appliedCouponCharges !== 0
                            ? coupon?.planDiscount
                              ? `(-)${GetCurrencyFixedToTwo(
                                  (line?.plan?.baseLinePrice *
                                    coupon?.planDiscount) /
                                    100
                                )}`
                              : `(-)${GetCurrencyFixedToTwo(
                                  appliedCouponCharges
                                )}`
                            : "(-)"
                          : secondaryDiscount !== 0
                          ? `(-)${GetCurrencyFixedToTwo(secondaryDiscount)}`
                          : coupon?.planDiscount
                          ? `(-)${GetCurrencyFixedToTwo(
                              (line?.plan?.baseLinePrice *
                                coupon?.secondaryDiscount) /
                                100
                            )}`
                          : "(-)"}
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} textAlign="right">
                    <Typography
                      sx={{
                        // borderBottom: " 1px solid var(--navy)",
                        // opacity: 0.1,
                        margin: "10px 0px",
                      }}
                    ></Typography>
                  </Grid>
                </>
                // {/* </Box> */}
              );
            })}
          </Grid>

          {/* Tax */}
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid
              item
              xs={8}
              textAlign="left"
              sx={{
                fontWeight: "var(--font_weight_0) !important",
                fontFamily: "var(--font_family_Regular) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade)",
              }}
              data-testid="review_plan_tax"
            >
              {taxes}
            </Grid>
            {formik.values.billingAddress?.zip?.length > 5 ? (
              <Grid
                item
                xs={4}
                textAlign="right"
                data-testid="review_plan_tax_amount"
              >
                {GetCurrencyFixedToTwo(
                  coupon?.coupon ? totalTax : calcPlanTaxes()
                )}
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={4}
                  sx={{
                    fontFamily: "var(--font_family_Regular) !important",
                    fontWeight: "var(--font_weight_0) !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    color: "var(--button_secondary_color)",
                    fontSize: "14px",
                  }}
                  data-testid="review_plan_tax_amount"
                >
                  {!isLoadedPlanTaxCharges ? (
                    GetCurrencyFixedToTwo(
                      coupon?.coupon ? totalTax : calcPlanTaxes()
                    )
                  ) : (
                    <Skeleton variant="rectangular" width={60} height={24} />
                  )}
                </Grid>
              </>
            )}
            {/* {line()} */}
          </Grid>
          {/* <Grid
            item
            container
            xs={12}
            sx={{ mb: { md: "10px", sm: "11px", xs: "16px" } }}
          >
            <Grid
              item
              xs={8}
              textAlign="left"
              className={styles.estimated_bill}
            >
              {content?.due_l4}
            </Grid>
            <Grid
              item
              xs={4}
              textAlign="right"
              className={styles.estimated_bill}
            >
              {GetCurrencyFixedToTwo(estimatedMonthlyBillAmount)}
            </Grid>
          </Grid> */}
          {/* {(content?.p_p1 || content?.p_p2) && (
            <Grid
              item
              container
              xs={12}
              className={styles.charge_per_person}
              sx={{ mb: { md: "14px", xs: "15px" } }}
            >
              <Box display="flex" justifyContent="left" alignItems="center">
                <Box
                  component={"img"}
                  alt={personIcon_alt}
                  width="20px"
                  height="20px"
                  sx={{ mr: "11px" }}
                  src={personIcon}
                />
                <Box>
                  {content?.p_p1} $
                  {Math.ceil(
                    estimatedMonthlyBillAmount / planDetails.numberOfLines
                  )}{" "}
                  {content?.p_p2}
                </Box>
              </Box>
            </Grid>
          )} */}
          {EnvConfig.IS_OFFER_CODE_DISCOUNT_APPLICABLE ? (
            <Grid
              item
              xs={12}
              className={styles.offer_code_section}
              sx={{ mt: "14px", mb: "15px" }}
            >
              {EnvConfig?.IS_FIXED_PROMO_DISCOUNT ? (
                <Box className={styles.promo_txt_container}>
                  <Box
                    className={styles.referal_text}
                    data-testid="review_plan_tax_saving"
                  >
                    {content?.savings_txt}
                  </Box>
                  <Box
                    className={styles.referal_text_light}
                    data-testid="review_plan_coupon_msg"
                  >
                    {CouponTypes[coupon && coupon?.type]?.message}{" "}
                    {coupon && coupon?.name} {content?.applied_txt}
                  </Box>
                </Box>
              ) : (
                <OfferCodeCmp
                  content={content}
                  removeCouponCallback={handleCouponRemove}
                  appliedCouponAmount={appliedCouponCharges}
                  addToCartApiWithOfferCode={addToCartApiWithOfferCode}
                />
              )}
            </Grid>
          ) : null}
          <Grid item container xs={12} sx={{ mb: "15px" }}></Grid>
          {/* Monthly Charges */}
          <Grid item container xs={12} sx={{ mb: "15px" }}>
            <Grid
              item
              xs={8}
              textAlign="left"
              className={styles.review_sub_header}
              sx={{
                fontWeight: "var(--font_weight_2) !important",
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade) !important",
              }}
              data-testid="review_plan_monthly_charge"
            >
              {monthly_charges}
            </Grid>
            <Grid
              item
              xs={4}
              textAlign="right"
              className={styles.review_sub_header}
              sx={{
                fontWeight: "var(--font_weight_2) !important",
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade) !important",
              }}
              data-testid="review_plan_total_amount_1"
            >
              {GetCurrencyFixedToTwo(totalMonthlyCharges - totalDiscount) ||
                "-"}
            </Grid>
            {/* {line()} */}
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            alignContent={"center"}
            mt={{ sm: "1px", xs: "9px" }}
            gap={"16px"}
          >
            <Grid
              item
              xs={0.5}
              textAlign="center"
              mt={"9px"}
              // className={styles.review_sub_header}
              // sx={{
              //   fontSize: "14px !important",
              //   color: "var(--dark-charcoal-fade) !important",
              // }}
              sx={{ width: "10%" }}
            >
              <Box
                component="div"
                sx={{
                  // marginRight: "5px",
                  // minWidth: "35px",
                  "& svg": {
                    width: "21px",
                    height: "18px",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: updateSvgFillColor(
                    content?.credit_card_svg,
                    "var(--primary_color)"
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              textAlign="left"
              // className={styles.review_sub_header}
              sx={{
                fontWeight: "var(--font_weight_0) !important",
                fontFamily: "var(--font_family_Regular) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade)",
                fontStyle: "italic",
                // marginLeft: "5px",
                width: "90%",
              }}
            >
              {content?.note_text_1}
            </Grid>
            {/* {separator()} */}
          </Grid>
          {separator1()}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          sx={{
            pl: { sm: "0px", md: "0px", xs: "0px" },
            fontWeight: "var(--font_weight_2) !important",
            fontFamily: "var(--font_family_Bold) !important",
            fontSize: "14px !important",
            color: "var(--dark-charcoal-fade) !important",
          }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{
              mb: "15px",
              fontSize: "14px !important",
              color: "var(--black) !important",
            }}
            className={styles.review_sub_header}
            data-testid="review_plan_one_time_text"
          >
            {oneTime_charges}
          </Typography>

          {/* Activation Charges */}
          {configKeys?.IS_ACTIVATION_FEE_PER_LINE ? (
            <Grid item container xs={12} className={styles.tax_and_fee}>
              <Grid
                sx={{
                  fontFamily: "var(--font_family_Regular)",
                  fontWeight: "var(--font_weight_0)",
                }}
                item
                xs={9.5}
                textAlign="left"
                data-testid="review_plan_activation_fee"
              >
                {activation_fee?.replace(
                  /feeAmount/g,
                  configKeys?.ACTIVATION_FEE_LINE[1] || ""
                )}
              </Grid>
              <Grid
                item
                xs={2.5}
                textAlign="right"
                sx={{
                  fontSize: { xs: "14px", sm: "16px" },
                  lineHeight: "28px",
                  color: {
                    xs: "var(--text_color)",
                    sm: "var(--button_secondary_color)",
                  },
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                }}
                data-testid="review_plan_fee_amount"
              >
                {GetCurrencyFixedToTwo(activationFee)}
              </Grid>
              {line()}
            </Grid>
          ) : null}
          {/* Shipping Charges */}

          <Grid item container xs={12} className={styles.shipping_charge}>
            {formikForPlanCard?.values?.some((line: any, i: number) => {
              return (
                line.simPreference === SimTypes.phySim && !multiSimInHandCheck
              );
            }) ? (
              <Grid
                item
                xs={9.5}
                textAlign="left"
                sx={{
                  fontSize: "14px !important",
                  color: "var(--dark-charcoal-fade) !important",
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                }}
                data-testid="review_plan_shipping_charges"
              >
                {shipping_charges}
              </Grid>
            ) : (
              <Grid
                item
                xs={9.5}
                textAlign="left"
                sx={{
                  fontSize: "14px !important",
                  color: "var(--dark-charcoal-fade) !important",
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                }}
                data-testid="review_plan_shipping_charges"
              >
                {esim_charges}
              </Grid>
            )}
            <Grid
              item
              xs={2.5}
              textAlign="right"
              sx={{
                fontSize: "14px",
                lineHeight: "28px",
                color: {
                  xs: "var(--text_color)",
                  sm: "var(--button_secondary_color)",
                },
                fontWeight: "var(--font_weight_0)",
                fontFamily: "var(--font_family_Regular)",
              }}
              data-testid="review_plan_shipment_charges"
            >
              {configKeys?.SIM_COST_DESCRIPENCY_ENABLED
                ? GetCurrencyFixedToTwo(ShipAndHandlingAmount)
                : simInHandCheck || multiSimInHandCheck
                ? GetCurrencyFixedToTwo(0)
                : shipmentCharges
                ? GetCurrencyFixedToTwo(shipmentCharges)
                : GetCurrencyFixedToTwo(0)}
            </Grid>
            {line()}
          </Grid>
          {/* Taxes */}
          <Grid item container xs={12} className={styles.tax_and_fee}>
            <Grid
              item
              xs={9.5}
              textAlign="left"
              sx={{
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade) !important",
                fontWeight: "var(--font_weight_0)",
                fontFamily: "var(--font_family_Regular)",
              }}
              data-testid="review_plan_taxes"
            >
              {formikForPlanCard?.values?.some((line: any, i: number) => {
                return (
                  line.simPreference === SimTypes.phySim && !multiSimInHandCheck
                );
              })
                ? `${taxes1}`
                : `${taxes}`}
            </Grid>
            <Grid
              item
              xs={2.5}
              sx={{
                fontSize: "14px",
                lineHeight: "28px",
                color: {
                  xs: "var(--text_color)",
                  sm: "var(--button_secondary_color)",
                },
                fontWeight: "var(--font_weight_0)",
                fontFamily: "var(--font_family_Regular)",
                display: "flex",
                justifyContent: "end",
              }}
              data-testid="review_plan_tax_total"
            >
              {isLoadedShippingTaxes || isLoadedActivationTax ? (
                <Skeleton variant="rectangular" width={60} height={24} />
              ) : configKeys?.SIM_COST_DESCRIPENCY_ENABLED ? (
                oneTimeTotalTax > 0 ? (
                  GetCurrencyFixedToTwo(oneTimeTotalTax)
                ) : (
                  GetCurrencyFixedToTwo(0)
                )
              ) : oneTimeTotalTaxChargesAmount > 0 ? (
                GetCurrencyFixedToTwo(oneTimeTotalTaxChargesAmount)
              ) : (
                GetCurrencyFixedToTwo(0)
              )}
            </Grid>
            {line()}
          </Grid>
          {/* Payment due today */}
          <Grid item container xs={12} sx={{ marginBottom: "0px" }}>
            <Grid
              item
              xs={8}
              textAlign="left"
              className={styles.review_sub_header}
              sx={{
                fontWeight: "var(--font_weight_2) !important",
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade) !important",
              }}
              data-testid="review_plan_due_today"
            >
              {total_one_time_Charge}
            </Grid>
            <Grid
              item
              xs={4}
              className={styles.review_sub_header}
              sx={{
                fontWeight: "var(--font_weight_2) !important",
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: "14px !important",
                color: "var(--dark-charcoal-fade) !important",
                display: "flex",
                justifyContent: "end",
              }}
              data-testid="review_plan_due_today_amount"
            >
              {isLoadedShippingTaxes || isLoadedActivationTax ? (
                <Skeleton variant="rectangular" width={60} height={24} />
              ) : simInHandCheck || multiSimInHandCheck ? (
                GetCurrencyFixedToTwo(totalDueAmount)
              ) : totalDueAmount >= 0 ? (
                GetCurrencyFixedToTwo(totalDueAmount)
              ) : (
                "-"
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems={"center"}
              alignContent={"center"}
              mt={{ sm: "15px", xs: "9px" }}
              gap={"16px"}
            >
              <Grid
                item
                xs={0.5}
                textAlign="center"
                mt={"7px"}
                // className={styles.review_sub_header}
                // sx={{
                //   fontSize: "14px !important",
                //   color: "var(--dark-charcoal-fade) !important",
                // }}
                sx={{ width: "10%" }}
              >
                <Box
                  component="div"
                  sx={{
                    // marginRight: "5px",
                    // minWidth: "35px",
                    "& svg": {
                      width: "21px",
                      height: "18px",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: updateSvgFillColor(
                      content?.taxes_svg,
                      "var(--primary_color)"
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                textAlign="left"
                // className={styles.review_sub_header}
                sx={{
                  fontWeight: "var(--font_weight_0) !important",
                  fontFamily: "var(--font_family_Regular) !important",
                  fontSize: "14px !important",
                  color: "var(--dark-charcoal-fade)",
                  fontStyle: "italic",
                  // marginLeft: "5px",
                  width: { xs: "90%", md: "91%" },
                }}
              >
                {content?.note_text_2}
              </Grid>
              {/* {separator()} */}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                width: "100%",
                padding: "15px",
                background: "var(--light_primary_color)",
                borderRadius: "5px",
                border: "1px solid var(--primary_color)",
                marginBottom: "25px",
                marginTop: "20px",
              }}
            >
              <Grid
                item
                xs={8}
                textAlign="left"
                className={styles.review_sub_header}
                sx={{
                  fontSize: "16px !important",
                  color: "var(--black) !important",
                }}
                data-testid="review_plan_due_today"
              >
                {due_today1}
              </Grid>
              <Grid
                item
                xs={4}
                className={styles.review_sub_header}
                sx={{
                  fontSize: "16px !important",
                  color: {
                    xs: "var(--black) !important",
                    sm: "var(--dark-charcoal-fade) !important",
                  },
                  display: "flex",
                  justifyContent: "end",
                }}
                data-testid="review_plan_due_today_amount"
              >
                {isLoadedShippingTaxes || isLoadedActivationTax ? (
                  <Skeleton variant="rectangular" width={60} height={24} />
                ) : simInHandCheck || multiSimInHandCheck ? (
                  GetCurrencyFixedToTwo(totalDueAmount)
                ) : totalDueAmount >= 0 ? (
                  GetCurrencyFixedToTwo(totalDueAmount)
                ) : (
                  "-"
                )}
              </Grid>
            </Grid>
            {/* {separator()} */}
          </Grid>
          {/* Terms and Conditions */}
          {/* <Box
            display={"flex"}
            sx={{ justifyContent: "start", alignItems: "center" }}
            mb={3}
          >
            <Checkbox
              value={isChecked}
              sx={{ color: "var(--primary_color)", padding: "9px 9px 9px 0px" }}
              onChange={() => {
                setIsChecked((prev) => !prev);
              }}
            />
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                color: "var(--text_color_12)",
                fontFamily: "var(--font_family_Bold) !important",
                width: { xs: "100%", sm: "70%" },
                a: {
                  color: "var(--text_color_12)",
                  fontFamily: "var(--font_family_Bold) !important",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: TandC,
              }}
              onClick={() => {
                gtmTagManager({
                  event: globalVal?.gtmEvents?.checkoutTerms,
                  click_section: "checkout",
                });
              }}
            ></Typography>
          </Box> */}
        </Grid>
        {/* <Box>
          <Typography
            className={styles.your_line}
            sx={{
              fontFamily: "var(--font_family_Medium) !important",
              fontSize: "14px !important",
            }}
          >
            {note}
          </Typography>
          <ol>
            <li style={{ color: "var(--text_color_12)" }}>
              <Typography
                className={styles.your_line}
                sx={{
                  fontSize: "14px !important",
                  fontWeight: "var(--font_weight_0) !important",
                  fontFamily: "var(--font_family_Regular) !important",
                }}
              >
                {note_desc1}
              </Typography>
            </li>
            <li style={{ color: "var(--text_color_12)" }}>
              <Typography
                sx={{
                  "& a": {
                    color: "var(--primary_color)",
                    fontWeight: "var(--font_weight_1) !important",
                    fontFamily: "var(--font_family_Medium) !important",
                    textDecoration: "none",
                  },
                  fontSize: "14px !important",
                  fontWeight: "var(--font_weight_0) !important",
                  fontFamily: "var(--font_family_Regular) !important",
                }}
                className={styles.your_line}
                dangerouslySetInnerHTML={{
                  __html: note_desc2,
                }}
              />
            </li>
          </ol>
        </Box> */}
        {step === 1 ? ( //TODO: Add condition to check NEXT button click
          <>
            {primaryLineError && (
              <Typography
                sx={{
                  m: "15px 0px 0px 10px",
                  fontSize: "18px",
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "var(--font_weight_4)",
                  lineHeight: "20px",
                  color: "#F40E1B",
                  marginBottom: "20px",
                }}
                data-testid="review_plan_primary_error"
              >
                {primary_line_error || ""}
              </Typography>
            )}
            <ButtonCust
              style={{
                width: "100%",
                marginTop: "30px",
                height: "50px",
                minHeight: "50px",
                margin: "0 auto",
              }}
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              loading={
                !checkIfIMEICheckInProgress(
                  checkoutCompatabilityState,
                  "compatibilityLoading"
                ) ||
                !checkIfSimInHandCheckInProgress(
                  multiSimVerificationState,
                  "pending"
                )
              }
              onClick={() => {
                handleNextStep();
                setCartHeader(false);
                gtmTagManager({
                  event: globalVal?.gtmEvents?.page1Next,
                  click_section: "checkout",
                });
              }}
              data-testid="review_plan_btn"
            >
              {next}
            </ButtonCust>
          </>
        ) : (
          <></>
        )}
        {(content?.otc_note_txt || content?.otc_note_desc) && (
          <Grid container xs={12}>
            {content?.otc_note_txt && (
              <Typography
                component="p"
                sx={{
                  mt: "15px",
                  mb: "10px",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "14px",
                  color: "var(--text_color)",
                }}
                data-testid="review_plan_otc_txt"
              >
                {content?.otc_note_txt}
              </Typography>
            )}
            {content?.otc_note_desc && (
              <Typography component={"ol"} style={{ paddingLeft: "20px" }}>
                <Typography
                  component="li"
                  className={styles.note_description}
                  sx={{
                    mb: "5px",
                    lineHeight: "25px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(content?.otc_note_desc),
                  }}
                  data-testid="review_plan_otc_desc"
                />
              </Typography>
            )}
            {EnvConfig?.IS_MSG_CENTER_ENABLED && (
              <>
                {checkoutPageOpsMessages.map((opsMessage: any, index: any) => {
                  return (
                    <Typography key={opsMessage.id} component={"div"}>
                      <Typography
                        variant="body2"
                        component="li"
                        sx={{
                          mb: "5px",
                          fontSize: "14px",
                          color: "var(--dark-charcoal-fade)",
                        }}
                        data-testid={`review_plan_msg_wrapper_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            mb: "5px",
                            fontFamily: "var(--font_family_Medium)",
                          }}
                          data-testid={`review_plan_msg_type_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          {opsMessage.messageType}:{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            mb: "5px",
                            fontFamily: "var(--font_family_Medium)",
                          }}
                          data-testid={`review_plan_msg_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          {opsMessage.message}
                        </Typography>
                      </Typography>
                    </Typography>
                  );
                })}
              </>
            )}
          </Grid>
        )}
        {(content?.otc_descl || content?.otc_dscl_d) && (
          <Grid item container xs={12} className={styles.total_due}>
            {content?.otc_descl && (
              <Grid item xs={12} textAlign="left">
                <Typography
                  variant="body2"
                  component="li"
                  className={styles.note_header}
                  data-testid="review_plan_oct_desc_1"
                >
                  {content?.otc_descl}
                </Typography>
              </Grid>
            )}
            {content?.otc_dscl_d && (
              <Grid item xs={12} textAlign="left">
                <ol start={2} style={{ paddingLeft: "20px", margin: "0px" }}>
                  <Typography
                    variant="body2"
                    component="li"
                    className={styles.note_description}
                    sx={{ mb: "5px", lineHeight: "25px" }}
                    data-testid="review_plan_oct_desc_d"
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(content?.otc_dscl_d),
                    }}
                  />
                </ol>
              </Grid>
            )}
            {content?.otc_dscl_f && (
              <Grid item xs={12} textAlign="left">
                <ol start={3} style={{ paddingLeft: "20px" }}>
                  <Typography
                    data-testid="review_plan_oct_desc_f"
                    variant="body2"
                    component="li"
                    className={styles.note_description}
                    sx={{ mb: "5px", lineHeight: "25px" }}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(content?.otc_dscl_f),
                    }}
                  />
                </ol>
              </Grid>
            )}
            {displayMessageDetails?.length > 0 &&
              displayMessageDetails?.map((content: any, index: number) => {
                return (
                  <Grid item xs={12} textAlign="left">
                    <ol
                      start={content?.otc_dscl_f ? index + 4 : index + 3}
                      style={{ paddingLeft: "20px", margin: "0px" }}
                    >
                      <Typography
                        variant="body2"
                        component="li"
                        className={styles.note_description}
                        sx={{ mb: "5px", lineHeight: "25px" }}
                        dangerouslySetInnerHTML={{
                          __html: BrandNameUpdate(content?.message ?? ""),
                        }}
                        data-testid={`review_plan_msg_center_title_${
                          Number(index) + 1 || 1
                        }`}
                      />
                    </ol>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default T7_ReviewPlan;
