import { Button } from "@mui/material";
import styles from "./HeaderItem.module.scss";
import Config from "../../../config/env.config.json";
import { useAppSelector } from "../../../app/hooks";

interface IHeaderNavigation {
  children: any;
  onClickItem: any;
  showActiveIndicator?: boolean;
  index?: any;
}
export const HeaderNavigateItem = (props: IHeaderNavigation) => {
  const { children, onClickItem, showActiveIndicator, index } = props;
  const { subDomainData } = useAppSelector(
    (state: any) => state.homepage
  );

  if((children === "Phones" || children === "CART") && !subDomainData?.data?.shopwareAccessKey){
    return <></>;
  }

  return (
    <div
      className={
        showActiveIndicator
          ? `${styles.item_container} ${styles.active_header_item}`
          : styles.item_container
      }
      data-testid={`head_section_${Number(index) + 1 || 1}`}
    >
      <Button
        aria-controls="simple-menu"
        className={styles.header_btn_txt}
        aria-haspopup="true"
        onClick={onClickItem}
        data-testid={`head_section_1_${Number(index) + 1 || 1}`}
        sx={{ textTransform: "unset" }}
      >
        {children}
      </Button>
    </div>
  );
};
