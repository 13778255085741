import Styles from "./S2_IRUserList.module.scss";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import { formatPhoneNumber } from "../../../../utils/commonFunctions";
import { toTitleCase } from "../../../../utils/commonFunctions/titleCase";
import {
  CustomerStatus as CS,
  LastDisconnectedReason,
} from "../../../../enums";
import CircleIcon from "@mui/icons-material/Circle";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";

const T2_IRUserList = (props: any) => {
  const { group, countryListHandler } = props;
  const {
    user_list_container,
    plan_active,
    is_primary,
    user_name,
    user_num,
    pack_active,
  } = Styles;
  const {
    user_data,
    active_icon,
    disconnected_txt_desc,
    failed,
    disconnected,
  } = props.content;
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const [sortedGroupData, setSortedGroupData] = useState([]);

  useEffect(() => {
    if (group && group?.data?.length > 0) {
      group?.data?.map((data: any, index: any) => {
        /***
         * *Sorting to display Primary line first and then sorting based on createdDate
         * */
        let sortedGroup = group?.data.filter((data: any) => data.isPrimary);
        sortedGroup = [
          ...sortedGroup,
          ...group?.data?.slice().sort((a: any, b: any) => {
            return (
              new Date(a.createdDate).getTime() -
              new Date(b.createdDate).getTime()
            );
          }),
        ];
        let i = sortedGroup.findLastIndex((data: any) => data.isPrimary); //removing duplicate primary line entry
        sortedGroup?.splice(i, 1);
        setSortedGroupData(sortedGroup);
      });
    }
  }, [group]);

  return (
    <>
      {group?.data?.length > 0 ? (
        sortedGroupData
          .filter(
            (data: any) =>
              data.status === CS.ACTIVE || data.status === CS.DISCONNECTED
          )
          .map((data: any) => {
            const fullName =
              (data.firstName ? data.firstName : "") +
              (data.lastName ? " " + data.lastName : "");
            return !data?.isPrimary &&
              data.status === CS.DISCONNECTED &&
              (data?.lastDisconnectedReason ===
                LastDisconnectedReason?.TRIGGER_BY_USER ||
                data?.lastDisconnectedReason ===
                  LastDisconnectedReason?.REQUESTED_BY_CS) ? (
              <Box
                className={user_list_container}
                key={data.id}
                sx={{
                  mt: "40px",
                  flexDirection: "column",
                  alignItems: "unset",
                }}
              >
                <Box>
                  <Typography className={user_name} pb={1}>
                    <Box>
                      <Box
                        component="span"
                        sx={{
                          textTransform: "capitalize !importanr",
                        }}
                      >
                        {(data.firstName ? data.firstName : "") +
                          (data.lastName ? " " + data.lastName : "")}
                      </Box>{" "}
                      <Box component="span" className={is_primary}>
                        {data?.isPrimary ? `${user_data?.primary}` : ""}{" "}
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "5px !important",
                          margin: "0 8px !important",
                        }}
                      />
                      <Typography
                        component="span"
                        sx={{
                          color: "var(--red-color_3)",
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "16px",
                          padding: "1px 10px 1px 0px",
                          borderRadius: "2px",
                          background: "var(--account_suspended_color)",
                        }}
                      >
                        {disconnected}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    borderBottom: " 2px solid var(--navy)",
                    opacity: 0.1,
                    margin: "15px 0px 15px",
                    width: "100%",
                  }}
                ></Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={failed?.data?.attributes?.url}
                  />

                  <Box
                    sx={{
                      fontSize: "16px",
                      color: "var(--subtext_color)",
                      py: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: {
                        xs: "0px 0px 10px 20px",
                        sm: "0px",
                        textAlign: "center",
                      },
                    }}
                  >
                    {`${fullName}${disconnected_txt_desc}`}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                className={user_list_container}
                key={data.id}
                sx={{
                  mt: "40px",
                }}
              >
                <Box>
                  <Typography className={user_name} pb={1}>
                    <Box>
                      <Box
                        component="span"
                        sx={{
                          textTransform: "capitalize !importanr",
                        }}
                      >
                        {toTitleCase(data.firstName ? data.firstName : "") +
                          toTitleCase(data.lastName ? " " + data.lastName : "")}
                      </Box>{" "}
                      <Box component="span" className={is_primary}>
                        {data?.isPrimary ? `${user_data?.primary}` : ""} -{" "}
                      </Box>
                    </Box>
                    {data.reachPlanDisplayName && (
                      <Box component="span" className={plan_active}>
                        {data?.reachPlanDisplayName}
                      </Box>
                    )}
                    {data.isRoaming && (
                      <Box component="span" className={pack_active}>
                        {user_data?.pack_active}
                        <Box
                          component="img"
                          src={active_icon?.data.attributes.url}
                          alt={active_icon?.data.attributes.alternativeText}
                        />
                      </Box>
                    )}
                  </Typography>
                  <Typography className={user_num}>
                    {data && data.reachNumber ? (
                      <Box component="span">
                        {formatPhoneNumber(data.reachNumber)}
                      </Box>
                    ) : data && data.mnpInfo?.oldNumber ? (
                      <Box component="span">
                        {formatPhoneNumber(data.mnpInfo?.oldNumber)}
                      </Box>
                    ) : null}
                  </Typography>
                </Box>
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    countryListHandler(data, fullName);
                    gtmTagManager({
                      event: globalVal?.gtmEvents?.manageServicePurchasePack,
                      click_section: "account",
                    });
                  }}
                  sx={{
                    width: "201px",
                    p: "11px 20px",
                    height: "50px !important",
                    "&:hover": {
                      backgroundColor: "var(--button_hover_color) !important",
                      border: "1px solid var(--button_hover_color) !important",
                    },
                  }}
                >
                  {user_data?.pur_pack}
                </ButtonCust>
              </Box>
            );
          })
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default T2_IRUserList;
