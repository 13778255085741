import { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import styles from "./S6_ActivationStep3.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  LinearProgressBarCust,
  TextFieldCust,
  ButtonCust,
  PopperCust,
  CustToolTip,
} from "../../../widgets";
import { useAppDispatch } from "../../../../app/hooks";
import ActivationPayNow from "./PayNow";
import { PayFailureModal } from "./PayFailureModal";
import { ActivationManageCard } from "./ManageCard";
import { PhoneNoPattern } from "../../../../utils/regexPatterns";
import { gaCategory } from "../../../../Types/GoogleAnalytics";
import { ActivationFailedModal } from "../ActivationStep4/ActivationFailedModal";
import { HelpSupportModal } from "../ActivationStep2/SupportModal";
import EsimPaymentSuccess from "./EsimPaymentSuccess";
import Config from "../../../../config/env.config.json";
import { CarrierTypes } from "../../../../enums";
import { InternalBanner } from "../../../common";
import { CustToolTip_T2 } from "../../../widgets/TooltipCust_T2";
import { BrandNameUpdate } from "../../../../utils";

const T6_ActivationStep3 = (props: any) => {
  const {
    currentLine,
    content,
    modalContent,
    step4Content,
    GAAndFBEventsHandler,
    formik,
    displayContent,
    loader,
    payNowConfirm,
    payLoader,
    showActModal,
    payNowFailModal,
    payNowFailedModal,
    handleBack,
    openEsimPaySuccess,
    onCloseEsimPaySuccess,
    activateUser,
    actBtnLoader,
  } = props;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const dispatch = useAppDispatch();
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const { sp3_title, step_txt, sim_check, msgs, simId_length, sim_instr } =
    content;
  const [iccidTargetAnchorEl, setIccidTargetAnchorEl] = useState(null);
  const [openICCIDInstructionPopper, setOpenICCIDInstructionPopper] =
    useState<boolean>(false);
  useEffect(() => {
    if (showActModal) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [showActModal]);

  const manageCardFg = () => {
    setShowCardChangeModal(true);
  };

  const showCardChangeModalHandle = () => {
    setShowCardChangeModal(false);
  };
  const closeActivationFailModal = () => {
    setShowModal(false);
  };
  const openSupportModal = () => {
    setShowModal(false);
    setOpenSupportDialog(true);
  };
  const onCloseSupportDialog = () => {
    setOpenSupportDialog(false);
    if (!openEsimPaySuccess) {
      setShowModal(true);
    }
  };
  const onClickICCIDInfoIcon = async (e: any) => {
    setOpenICCIDInstructionPopper(true);
    setIccidTargetAnchorEl(e.target);
  };
  const onCloseInfoDlg = () => {
    setOpenICCIDInstructionPopper(false);
    setIccidTargetAnchorEl(null);
  };
  return (
    <>
      {/* <InternalBanner
        pageName={"MyAccount"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <Box
        sx={{
          cursor: "pointer",
          width: "18px",
          height: "18px",
          zIndex: "100",
          left: "0",
          position: "absolute",
          ml: { md: "30px", sm: "25px", xs: "25px" },
          mt: { md: "60px" },
          display: { xs: "none", sm: "inline-block" },
        }}
        data-testid="activation_step_3_wrapper"
      >
        <Box
          sx={{
            display: "flex",
            width: "18px",
            height: "18px",
            position: "absolute",
            left: "3rem",
          }}
          onClick={handleBack}
          data-testid="activation_step_3_back"
        >
          <ArrowBackIosIcon sx={{ width: "18px" }} data-testid="activation_step_3_back_icon" />
          <Box data-testid="activation_step_3_back_text">Back</Box>
        </Box>
      </Box>
      <KeyboardBackspaceIcon
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          left: "1.4rem",
          top: "6.1rem",
          color: "#313131",
          cursor: "pointer",
        }}
        onClick={handleBack}
        data-testid="activation_step_3_back_icon"
      />
      <Box className={styles.step3_main}>
        {displayContent === "main" && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "100%", sm: "724px" },
                mx: "auto",
              }}
            >
              <Paper
                sx={{
                  // border: "1px solid rgba(0,0,0,.2)",
                  width: { xs: "90%", sm: "724px" },
                  // height: "600px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: { xs: "30px", sm: "60px" },
                  boxShadow: { xs: "unset !important" },
                  marginBottom: "45px",
                  // px: { xs: "12px", md: "unset" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: { xs: "25px", sm: "31px" },
                    pb: { xs: "16px", sm: "22px" },
                  }}
                >
                  {/* <Box
                    component="img"
                    alt={"circular_loader"}
                    src={content?.icc_id_icon?.data?.attributes?.url}
                    sx={{ width: { xs: "33px", paddingRight: "16px" } }}
                  /> */}
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "24px" },
                      fontFamily: "var(--font_family_Bold)",
                      display: { xs: "none", sm: "block" },
                    }}
                    data-testid="activation_step_3_title"
                  >
                    {sp3_title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontFamily: "var(--font_family_Bold)",
                      display: { xs: "block", sm: "none" },
                    }}
                    data-testid="activation_step_3_simText"
                  >
                    {sim_check?.res?.simText_mweb}
                  </Typography>
                </Box>
                <hr style={{ border: "1px solid #F8F8F8", width: "100%" }}></hr>
                <Typography
                  className={styles.stepper_txt}
                  sx={{ fontFamily: "var(--font_family_Semibold)", my: "18px" }}
                  data-testid="activation_step_3_step_txt"
                >
                  {BrandNameUpdate(step_txt)}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Regular)",
                      mb: { xs: "21px" },
                      display: { xs: "none", sm: "block" },
                    }}
                    data-testid="activation_step_3_desc"
                  >
                    {BrandNameUpdate(sim_check?.desc)}
                  </Typography>
                </Box>
                <Box
                  width={{ xs: "90%", sm: "80%", md: "75%" }}
                  sx={{ mx: { xs: "12px" }, mb: "30px" }}
                  data-testid="activation_step_3_textfield_wrapper"
                >
                  <TextFieldCust
                    value={formik.values.simId}
                    name="simId"
                    onChange={(e: any) => {
                      if (
                        PhoneNoPattern.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.setFieldValue(e.target.name, e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    type={window.screen.width <= 600 ? "number" : "text"}
                    label={sim_check?.sim_ph}
                    maxlength={
                      Config.OPERATOR.toLowerCase() === CarrierTypes.ATT
                        ? 20
                        : 19
                    }
                    error={
                      formik.touched?.simId && formik.errors?.simId
                        ? true
                        : false
                    }
                    helperText={formik.touched?.simId && formik.errors?.simId}
                    onKeyUp={(e: any) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                    data-testid="activation_step_3_textfield"
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginY={"8px"}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginRight: "4px",
                          fontFamily: "var(--font_family_Regular)",
                          display: { xs: "none", sm: "block" },
                        }}
                        className={styles.step3_info}
                        data-testid="activation_step_3_find_text"
                      >
                        {content?.where_to_find_txt}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginRight: "4px",
                          fontFamily: "var(--font_family_Regular)",
                          display: { xs: "block", sm: "none" },
                        }}
                        className={styles.step3_info}
                        data-testid="activation_step_3_find_txt_mweb"
                      >
                        {sim_check?.res?.where_to_find_txt_mweb}
                      </Typography>
                      <CustToolTip_T2
                        title={
                          <Typography
                            sx={{
                              // color: "var(--subtext_color)",
                              fontSize: "13px",
                              lineHeight: "18px",
                              padding: "15px",
                              fontWeight: "var(--font_weight_1)",
                              textAlign: "center",
                              color: "var(--text_color)",
                            }}
                            data-testid="activation_step_3_tooltip_title"
                          >
                            <Box
                              component="img"
                              src={
                                content?.sim_check?.sim_instr?.data?.attributes
                                  ?.url
                              }
                              alt={
                                content?.sim_check?.sim_instr?.data?.attributes
                                  ?.alternativeText
                              }
                              width="250px"
                              height="150px"
                              data-testid="activation_step_3_tooltip_img"
                            />
                            {content?.help_txt}
                          </Typography>
                        }
                        placement="bottom-start"
                        arrow
                      >
                        <Box
                          component="img"
                          src={content?.info_icon?.data?.attributes?.url}
                          alt={
                            content?.info_icon?.data?.attributes
                              ?.alternativeText
                          }
                          sx={{
                            width: "14px",
                            height: "14px",
                          }}
                          data-testid="activation_step_3_info_icon"
                        />
                      </CustToolTip_T2>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Regular) !important",
                        fontSize: "12px !important",
                      }}
                      className={styles.step3_limit_div}
                      data-testid="activation_step_3_sim_length"
                    >
                      {formik.values.simId.length}
                      {simId_length}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "40px" }}>
                  <Box className={styles.step3_btn}>
                    <ButtonCust
                      data-testid="activation_step_3_btn"
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      disabled={
                        formik.values?.simId?.length === 0
                          ? true
                          : !formik?.isValid
                      }
                      onClick={() => {
                        formik.handleSubmit();
                        GAAndFBEventsHandler(
                          gaCategory.activation,
                          sim_check?.check_btn
                        );
                      }}
                      loading={loader}
                    >
                      {sim_check?.check_btn}
                    </ButtonCust>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </>
        )}
        {displayContent === "pay" && (
          <ActivationPayNow
            payNowConfirm={payNowConfirm}
            payBtnLoader={payLoader}
            manageCardFg={manageCardFg}
            content={content}
            {...props}
            displayContent={displayContent}
          />
        )}
        <ActivationManageCard
          showCardChangeModal={showCardChangeModal}
          setShowCardChangeModal={showCardChangeModalHandle}
          isModalRequired={true}
          pageName={"activation"}
        />
        {payNowFailModal && (
          <PayFailureModal
            modalFg={payNowFailModal}
            closeHandler={() => payNowFailedModal(false)}
            content={content}
          />
        )}
        {showModal && (
          <ActivationFailedModal
            showModal={showModal}
            openSupportModal={openSupportModal}
            closeActivationFailModal={closeActivationFailModal}
            content={step4Content}
            currentLine={currentLine}
          />
        )}
        <HelpSupportModal
          open={openSupportDialog}
          onClose={() => onCloseSupportDialog()}
          title={step4Content?.c_sup_title}
          showClose={false}
          showBackBtn={true}
          content={modalContent}
        />
        <EsimPaymentSuccess
          open={openEsimPaySuccess}
          onClose={() => onCloseEsimPaySuccess()}
          activateUser={activateUser}
          openSupportModal={openSupportModal}
          actBtnLoader={actBtnLoader}
          content={content}
        />
      </Box>
    </>
  );
};
export default T6_ActivationStep3;
