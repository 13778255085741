import { useEffect, useState } from "react";
import Config from "../../../../../config/app.config.json";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../../../utils/commonFunctions";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { shipAddSectionContent } from "../../../../../features/strapi/accountSlice";
const ShippingAddress =
  require(`./${Config.Account.shipAdd.template}_ShippingAddress`).default;

export type ShippingComponentProps = {
  shippingAddress: any;
  sendSimOptions: any;
  shipPlan: any;
  setShipPlan: any;
  updateAddress: any;
  secondaryLineAddress?: any;
  checkHandler?: any;
  loader?: boolean;
  handleLinesTab?: any;
  content?: any;
  handleBackBtn?: any;
  setTaxAmount?: any;
  taxAmount?: any;
  setIsAddLinePayment?: any;
  currentCard?: any;
  changeCard?: any;
  confirmPurchaseHandler?: any;
  pageName?: any;
  btnLoader?: any;
  formik?: any;
  isBacknavigation?: any;
  setIsBackNavigation?: any;
  setPayClicked?: any;
  isAddCardDone?: any;
  setIsAddCardDone?: any;
  oneTimeTotalTax?: any;
  setOneTimeTotalTax?: any;
  ShipAndHandlingAmount?: any;
  setShipAndHandlingAmount?: any;
};

export const ShippingComponent = (props: ShippingComponentProps) => {
  const dispatch = useAppDispatch();
  const { shipAddContent } = useAppSelector(
    (state: any) => state.strapi.account
  );
  const [shipCacheContent, setShipCacheContent] = useState<any>(null);
  useEffect(() => {
    getCache(
      "ShippingAdd",
      shipAddSectionContent,
      setShipCacheContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("ShippingAdd", shipAddContent);
  }, [shipAddContent]);
  return (
    (shipCacheContent || shipAddContent) && (
      <ShippingAddress
        content={shipCacheContent ? shipCacheContent : shipAddContent}
        {...props}
      />
    )
  );
};
