import Styles from "./S3_IRUserList.module.scss";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import { formatPhoneNumber } from "../../../../utils/commonFunctions";
import { toTitleCase } from "../../../../utils/commonFunctions/titleCase";
import {
  CustomerStatus as CS,
  LastDisconnectedReason,
} from "../../../../enums";
import CircleIcon from "@mui/icons-material/Circle";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";

const T3_IRUserList = (props: any) => {
  const { group, countryListHandler } = props;
  const {
    user_list_container,
    plan_active,
    is_primary,
    user_name,
    user_num,
    pack_active,
  } = Styles;
  const {
    user_data,
    active_icon,
    disconnected_txt_desc,
    failed,
    disconnected,
    esim,
    psim,
  } = props.content;
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const [sortedGroupData, setSortedGroupData] = useState([]);

  useEffect(() => {
    if (group && group?.data?.length > 0) {
      group?.data?.map((data: any, index: any) => {
        /***
         * *Sorting to display Primary line first and then sorting based on createdDate
         * */
        let sortedGroup = group?.data.filter((data: any) => data.isPrimary);
        sortedGroup = [
          ...sortedGroup,
          ...group?.data?.slice().sort((a: any, b: any) => {
            return (
              new Date(a.createdDate).getTime() -
              new Date(b.createdDate).getTime()
            );
          }),
        ];
        let i = sortedGroup.findLastIndex((data: any) => data.isPrimary); //removing duplicate primary line entry
        sortedGroup?.splice(i, 1);
        setSortedGroupData(sortedGroup);
      });
    }
  }, [group]);

  return (
    <>
      {group?.data?.length > 0 ? (
        sortedGroupData
          .filter(
            (data: any) =>
              data.status === CS.ACTIVE || data.status === CS.DISCONNECTED
          )
          .map((data: any, index: any) => {
            const fullName =
              (data.firstName ? data.firstName : "") +
              (data.lastName ? " " + data.lastName : "");
            return !data?.isPrimary &&
              data.status === CS.DISCONNECTED &&
              (data?.lastDisconnectedReason ===
                LastDisconnectedReason?.TRIGGER_BY_USER ||
                data?.lastDisconnectedReason ===
                  LastDisconnectedReason?.REQUESTED_BY_CS) ? (
              null
            ) : (
              <Box
                className={user_list_container}
                key={data.id}
                sx={{
                  mb: "20px",
                }}
                data-testid={`IR_user_list_wrapper_${Number(index) + 1 || 1}`}
              >
                <Box>
                  <Typography className={user_name} pb={1}>
                    <Box>
                      <Box
                        component="span"
                        sx={{
                          textTransform: "capitalize !important",
                          fontFamily: "var(--font_family_Regular)",
                        }}
                        data-testid={`IR_user_list_user_name_${Number(index) + 1 || 1}`}
                      >
                        {toTitleCase(data.firstName ? data.firstName : "") +
                          toTitleCase(data.lastName ? " " + data.lastName : "")}
                      </Box>{" "}
                      <Box component="span" className={is_primary} data-testid={`IR_user_list_is_p_${Number(index) + 1 || 1}`}>
                        {data?.isPrimary ? `${user_data?.primary}` : ""}
                      </Box>
                    </Box>
                    {data.isRoaming && (
                      <>
                        -
                        <Box component="span" className={pack_active} data-testid={`IR_user_list_pack_active_${Number(index) + 1 || 1}`}>
                          &nbsp;
                          {user_data?.pack_active}
                        </Box>
                      </>
                    )}
                  </Typography>
                  <Typography className={user_num} data-testid={`IR_user_list_user_num_${Number(index) + 1 || 1}`}>
                    {data && data.reachNumber ? (
                      <Box component="span" data-testid={`IR_user_list_reach_num_${Number(index) + 1 || 1}`}>
                        {formatPhoneNumber(data.reachNumber)}
                      </Box>
                    ) : data && data.mnpInfo?.oldNumber ? (
                      <Box component="span" data-testid={`IR_user_list_old_num_${Number(index) + 1 || 1}`}>
                        {formatPhoneNumber(data.mnpInfo?.oldNumber)}
                      </Box>
                    ) : null}
                    <Box
                      component="span"
                      sx={{
                        backgroundColor: "var(--light_primary_color)",
                        color: "var(--primary_color) !important",
                        fontSize: "14px",
                        lineHeight: "17px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_2)",
                        p: "5px",
                      }}
                      data-testid={`IR_user_list_sim_text_${Number(index) + 1 || 1}`}
                    >
                      {data?.esim ? esim : !data?.esim && data?.isSimInHand ? "SIM in Hand" : psim}
                    </Box>
                  </Typography>
                </Box>
                <ButtonCust
                  data-testid={`IR_user_list_btn_${Number(index) + 1 || 1}`}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    countryListHandler(data, fullName);
                    gtmTagManager({
                      event: globalVal?.gtmEvents?.manageServicePurchasePack,
                      click_section: "account",
                    });
                  }}
                  sx={{
                    width: "201px",
                    p: "11px 20px",
                    height: "50px !important",
                  }}
                >
                  {user_data?.pur_pack}
                </ButtonCust>
              </Box>
            );
          })
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
            data-testid="IR_user_list_progress"
          />
        </Box>
      )}
    </>
  );
};

export default T3_IRUserList;
