
import Config from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
const ILDUserListComp = isTemplateExisits(
  Config.PostActivationServices.ILDUserList
)
  ? require(`./${Config.PostActivationServices.ILDUserList.template}_ILDUserList`)
      .default
  : null;

const ILDUserList = (props: any) => {

  return (
    <>
      <ILDUserListComp {...props}/>
    </>
  );
};

export default ILDUserList;
