import { Box, Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./S2_CountryDataModal.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const CountryDataModal = (props: any) => {
  const { countryName, ildVoiceOut } = props.selectedCountry;

  const { view_Rates } = props;
  return (
    <SimpleModal
      isShowModal={props.isCountryModal}
      onCloseModal={props.setIsCountryModal}
      showClose={true}
      sx={{
        top: " 20%",
        borderRadius: "10px",
        width: "100%",
        p: "30px 5px 0px",
      }}
    >
      <Box className={styles.modal_main_container}>
        <Box className={styles.modal_country_name}>
          {countryName?.toLowerCase()}
        </Box>
        <Box className={styles.section}>
          <Typography className={styles.modal_sub_title}>
            {view_Rates?.country_modal.first_subHead}
          </Typography>

          <Typography className={styles.modal_data_text}>
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={view_Rates?.call_filled?.data?.attributes.url}
                alt={view_Rates?.call_filled?.data?.attributes.alternativeText}
                pr="15px"
              />
              {view_Rates.country_modal.voice}{" "}
            </Box>
            <Box
              component="span"
              style={{ fontWeight: "var(--font_weight_2)" }}
            >
              ${ildVoiceOut && ildVoiceOut}/min
            </Box>
          </Typography>
          <Typography className={styles.footer}>
            {view_Rates.country_modal.footer_txt}
          </Typography>
        </Box>
        <Box></Box>
      </Box>
    </SimpleModal>
  );
};

export default CountryDataModal;
