import { Box, Typography } from "@mui/material";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { paymentLandingProps } from "./PaymentLandingComponent";
import { useNavigate, useLocation } from "react-router-dom";
import EnvConfig from "../../../config/env.config.json";
import _ from "lodash";
import { ButtonCust } from "../../widgets";
import { useEffect, useRef } from "react";
import ENVConfig from "../../../config/env.config.json";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import styles from "./S2_PaymentLanding.module.scss";
import {
  deleteCartItems,
  getCartItems,
} from "../../../features/shopWare/shopWareSlice";
import { SimTypes } from "../../../features/checkout/Types";
const PaymentLanding = (props: paymentLandingProps) => {
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { content, loader } = props;
  const {
    tick_icon,
    heading,
    description,
    btn_txt,
    btn_txt2,
    fail_icon,
    fail_heading,
    fail_desc,
    correct_cc_desc,
    fail_btn_text,
    help_txt,
  } = content;
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  const handleContinueToDashBoard = () => {
    if (location?.state?.redirectionURL) {
      navigate(location?.state?.redirectionURL);
    } else {
      navigate(RoutingURLs.myAccount);
    }
    gtmTagManager({
      event: globalVal?.gtmEvents?.continueToDashboard,
      click_section: "checkout",
    });
  };
  const retryPayment = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const { subDomainData } = useAppSelector((state: any) => state.homepage);
  const { group } = useAppSelector((state: any) => state.account);
  const { getCustomer } = useAppSelector((state: any) => state?.checkout || {});
  const { shipmentOrderNewSuccess } = useAppSelector(
    (state: any) => state?.trackorder
  );
  console.log(shipmentOrderNewSuccess)

  const sortedGroupData = group?.data
    ? group?.data
        .slice()
        .sort(
          (a: any, b: any) =>
            new Date(a.createdDate).getTime() -
            new Date(b.createdDate).getTime()
        )
    : null;
  const simType = shipmentOrderNewSuccess
    ? shipmentOrderNewSuccess?.data?.eSimQuantity >= 1 || shipmentOrderNewSuccess?.data?.simInHandQuantity >= 1
    : getCustomer?.linePreferences?.line1?.simPreference ===
        SimTypes.ESIM ||
        getCustomer?.linePreferences?.line1?.iccId !== "";

  useEffect(() => {
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      deleteCart();
    }
  }, []);
  const deleteCart = () => {
    let lineItemsInCart: any = localStorage.getItem("lineItems");
    lineItemsInCart = JSON.parse(lineItemsInCart);
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = JSON.parse(planDetails);
    let deletionId =
      lineItemsInCart &&
      lineItemsInCart.find((data: any) =>
        data?.payload?.productNumber.match("DATA-")
      );
    if (deletionId) {
      dispatch(deleteCartItems(deletionId?.id)).then((res: any) => {
        if (res) {
          dispatch(getCartItems());
        }
      });
    }
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.innerHTML = `
  //   (function(){var g=function(e,h,f,g){

  //       this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};

  //       this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};

  //       this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};

  //       this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};

  //       this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};

  //       try{(new g(100,"r","QSI_S_ZN_bOXrJMlkEamEJjE","https://znboxrjmlkeamejje-cogeco.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_bOXrJMlkEamEJjE")).start()}catch(i){}})();
  //   `;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
      <Box sx={{ mt: "0px", paddingTop: "1px" }}>
        <Box
          sx={{
            width: { xs: "75%", sm: "90%", md: "60%", lg: "35%" },
            margin: "0px auto 100px auto",
            textAlign: "center",
            padding: "30px",
            mt: "100px",
            borderRadius: "10px",
            background: "var(--white)",
            // boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          {location?.state?.emailId || location?.state?.success ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                data-testid="payment_comp_wrapper"
              >
                <Box
                  component={"img"}
                  alt={tick_icon?.data?.attributes?.alternativeText}
                  src={content?.tick_icon?.data?.attributes?.url}
                  width={"92px"}
                  data-testid="payment_comp_img"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "20px", sm: "24px" },
                    fontFamily: "var(--font_family_Bold)",
                    lineHeight: "30px",
                    marginLeft: "5px",
                    fontWeight: 700,
                    color: "var(--title-color_1)",
                  }}
                  data-testid="payment_comp_title"
                >
                  {simType ? heading.split(".")[1] : heading.split(".")[0]}
                </Typography>
              </Box>
              <Box sx={{ mt: "18px", mb: "10px" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontFamily: "var(--font_family_Regular)",
                    lineHeight: "21px",
                    width: { xs: "100%", sm: "100%", md: "85%" },
                    color: "var(--text_color_12)",
                    mx: "auto",
                  }}
                  data-testid="payment_comp_desc"
                >
                  {location?.state?.description
                    ? location?.state?.description
                    : description}
                </Typography>
              </Box>
              <Box>
                <ButtonCust
                  sx={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontFamily: "var(--font_family_Medium)",
                    mt: "20px",
                    width: "300px",
                  }}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={handleContinueToDashBoard}
                  data-testid="payment_comp_btn"
                >
                  {location?.state?.redirectionURL ? btn_txt2 : btn_txt}
                </ButtonCust>

                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "14px" },
                      fontFamily: "var(--font_family_Bold)",
                      lineHeight: "21px",
                      width: { xs: "100%", sm: "100%", md: "95%" },
                      margin: "30px auto 0 auto",
                    }}
                    data-testid="payment_comp_help"
                  >
                    {help_txt}
                  </Typography>
                  <div
                    className={styles.helplink}
                    id="ZN_bOXrJMlkEamEJjE"
                    data-testid="payment_comp_help_link"
                  ></div>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <form
                ref={formRef}
                method="post"
                name="paymentForm"
                id="paymentForm"
                action={ENVConfig.CHECKOUT_PAYMENT}
              >
                <input
                  type="hidden"
                  name="emailId"
                  value={
                    (currentdetails && currentdetails?.emailId) ||
                    currentdetails?.username ||
                    currentdetails?.email_address ||
                    currentdetails?.email
                  }
                />
                <input
                  type="hidden"
                  name="redirectUrl"
                  value={`${window.location.origin}/loader`}
                />
                {ENVConfig?.IS_AUTOPAY_ENABLED && ENVConfig?.TENANT_ID ? (
                  <>
                    <input
                      type="hidden"
                      name="tenant"
                      value={ENVConfig?.TENANT_ID}
                    />
                    <input type="hidden" name="saveCard" value={String(true)} />
                    <input type="hidden" name="autoPay" value={String(true)} />
                    <input
                      type="hidden"
                      name="defaultCard"
                      value={String(true)}
                    />
                  </>
                ) : null}
              </form>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  alt={fail_icon?.data?.attributes?.alternativeText}
                  src={fail_icon?.data?.attributes?.url}
                  data-testid="payment_comp_fail_icon"
                />
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontFamily: "var(--font_family_Bold)",
                    lineHeight: "30px",
                    marginLeft: "5px",
                    fontWeight: 700,
                    color: "var(--text_color_12)",
                  }}
                  data-testid="payment_comp_fail_title"
                >
                  {fail_heading}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: 400,
                    lineHeight: "21px",
                    mt: "38.6px",
                  }}
                  data-testid="payment_comp_fail_dec"
                >
                  {fail_desc}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: 400,
                    lineHeight: "21px",
                    mt: "30px",
                  }}
                  data-testid="payment_comp_correct_cc_desc"
                >
                  {correct_cc_desc}
                </Typography>
              </Box>
              <Box>
                <ButtonCust
                  sx={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontFamily: "var(--font_family_Medium)",
                    mt: "35px",
                    width: "100%",
                  }}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  onClick={retryPayment}
                  data-testid="payment_comp_fail_btn"
                >
                  {fail_btn_text}
                </ButtonCust>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PaymentLanding;
