import { Box } from "@mui/material";
import { usaState, countryList } from "../../../config/USAStateList";
import { AutoComplete, TextFieldCust, SelectCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import styles from "./AddressField.module.scss";

type Props = {
  handleSelect?: any;
  formik: any;
  handleAddressChange?: any;
  addressObjName: string;
  content?: any;
  onBlurCallback?: any;
  inputRefs?: any;
  change_address?: any;
  showLabels?: boolean;
  isCustomIcon?: any;
};

export const AddressField = (props: Props) => {
  const {
    formik,
    addressObjName,
    handleSelect,
    handleAddressChange,
    content,
    onBlurCallback,
    inputRefs,
  } = props;
  const {
    billing_name_div,
    text_fields_main,
    change_address,
    text_fields_div,
    address_label,
  } = styles;
  return (
    <Box sx={{ padding: { xs: "10px", sm: "10px 35px" } }}>
      <Box
        className={text_fields_main}
        // sx={{ marginBottom: "-15px" }}
      >
        <Box className={text_fields_div}>
          <AutoComplete
            value={formik.values[addressObjName].address1}
            onChange={(e: any) => {
              handleAddressChange(e, "address1");
            }}
            onSelect={(e: any, placeId: string) => {
              handleSelect(placeId);
            }}
            autoComplete={"off"}
            placeholder={content?.address1_ph}
            helperText={
              formik.touched[addressObjName]?.address1 &&
              formik.errors[addressObjName]?.address1
            }
            inputRef={inputRefs?.address1}
            error={
              formik.touched[addressObjName]?.address1 &&
              formik.errors[addressObjName]?.address1
                ? true
                : false
            }
            name="address1"
            data-testid="address_field_address_1"
          />
        </Box>
        <Box className={text_fields_div}>
          <TextFieldCust
            name="address2"
            value={formik.values[addressObjName].address2}
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            label={content?.address2_ph}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "address2");
            }}
            data-testid="address_field_address_2"
          />
        </Box>
      </Box>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            name="city"
            inputRef={inputRefs?.city}
            value={formik.values[addressObjName].city}
            label={content?.city_ph}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "city");
            }}
            disabled={EnvConfig.DISABLE_ADDRESS_FIELDS_CHECKOUT}
            helperText={
              formik.touched[addressObjName]?.city &&
              formik.errors[addressObjName]?.city
            }
            error={
              formik.touched[addressObjName]?.city &&
              formik.errors[addressObjName]?.city
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            data-testid="address_field_city"
          />
        </Box>
        <Box className={text_fields_div}>
          <SelectCust
            name="state"
            inputRef={inputRefs?.state}
            value={formik.values[addressObjName].state}
            options={usaState}
            placeholder={content?.state_ph}
            disabled={EnvConfig.DISABLE_ADDRESS_FIELDS_CHECKOUT}
            onChange={(e: any) => {
              handleAddressChange(e.target.value, "state");
            }}
            helperText={
              formik.touched[addressObjName]?.state &&
              formik.errors[addressObjName]?.state
            }
            error={
              formik.touched[addressObjName]?.state &&
              formik.errors[addressObjName]?.state
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            data-testid="address_field_state"
          />
        </Box>
      </Box>
      <Box className={text_fields_main}>
        <Box className={text_fields_div}>
          <TextFieldCust
            value={formik.values[addressObjName].zip}
            name="zip"
            inputRef={inputRefs?.zip}
            label={content?.zip_ph}
            disabled={EnvConfig.DISABLE_ADDRESS_FIELDS_CHECKOUT}
            maxlength={5}
            onChange={(e: any) => {
              if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
                handleAddressChange(e.target.value, "zip");
              }
            }}
            helperText={
              formik.touched[addressObjName]?.zip &&
              formik.errors[addressObjName]?.zip
            }
            error={
              formik.touched[addressObjName]?.zip &&
              formik.errors[addressObjName]?.zip
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            data-testid="address_field_zip"
          />
        </Box>
        <Box className={text_fields_div}>
          <SelectCust
            disabled
            value={formik.values[addressObjName].country}
            options={countryList}
            placeholder={content?.country_ph}
            onChange={() => null}
            helperText={
              formik.touched[addressObjName]?.country &&
              formik.errors[addressObjName]?.country
            }
            error={
              formik.touched[addressObjName]?.country &&
              formik.errors[addressObjName]?.country
                ? true
                : false
            }
            onBlur={(e) => onBlurCallback(e, addressObjName)}
            data-testid="address_field_country"
          />
        </Box>
      </Box>
    </Box>
  );
};
