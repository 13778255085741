import React, { useState, useEffect } from "react";
import { ButtonCust, TextFieldCust } from "../../../widgets";
import { Box, CircularProgress, Typography } from "@mui/material";
import config from "../../../../config/env.config.json";
import { useFormik } from "formik";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { string, object } from "yup";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { emailPattern, namePattern } from "../../../../utils/regexPatterns";
import {
  CreateCustomer,
  GetCustomer,
  UpdateCustomerSource,
  updateSecondaryUser,
} from "../../../../features/checkout/checkoutSlice";
import { customerGroup } from "../../../../features/account/accountSlice";
import { InternalBanner } from "../../../common";
import { SimTypes, addressType } from "../../../../features/checkout/Types";
import { useNonInitialEffect } from "../../../../utils";
interface AuthUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  checked: boolean;
}
const SecondaryActivationData = (props: any) => {
  const dispatch = useAppDispatch();
  const { healthCheckData, errHealthCheckData, createCustomer, getCustomer } =
    useAppSelector((state: any) => state.checkout);
  const { updateCustomer, updateCustomerSource, errorUpdateCustomerSource } =
    useAppSelector((state: any) => state.checkout);
  const primayUser = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const [updatePreferences, setUpdatePreferences] = useState(
    primayUser?.linePreferences
  );
  const [user, setUser] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(true);
  const [sendSimOptions, setSendSimOptions] = useState<any>([]);
  let currentLine: any = JSON.parse(
    localStorage.getItem("secondaryCustomerDetail") || "null"
  );

  useEffect(() => {
    setUpdatePreferences((prev: any) => {
      const updatedPreferences = { ...prev };
      if (updatedPreferences[`line${currentLine?.lineNumber}`]) {
        updatedPreferences[`line${currentLine?.lineNumber}`].customerCreated =
          true;
      }
      return updatedPreferences;
    });
  }, []);
  const initialAuthUserData: AuthUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    checked: false,
  };
  const { content, handleStep } = props;
  const {
    // emptyEmail,
    // emptyFname,
    // emptyLname,
    invalidEmail,
    invalidFname,
    invalidLname,
  } = content?.validation_errors || {};
  const signUpSchema = object({
    firstName: string()
      .trim()
      .min(2, invalidFname)
      .max(24, invalidFname)
      // .required(emptyFname)
      .matches(namePattern, invalidFname),
    lastName: string()
      .trim()
      .min(2, invalidLname)
      .max(24, invalidLname)
      // .required(emptyLname)
      .matches(namePattern, invalidLname),
    email: string()
      .trim()
      // .required(emptyEmail)
      .matches(emailPattern, invalidEmail),
  });
  const formik = useFormik({
    initialValues: initialAuthUserData,
    validationSchema: signUpSchema,
    onSubmit: async (values: AuthUser) => {
      const { firstName, lastName, email } = values;
      if (currentLine?.id) {
        updateCustomerHandler(currentLine?.id);
      } else {
        createCustomerHandler();
      }
      setDisable(false);
    },
  });
  const handleFormikChange = (e: any) => {
    formik.handleChange(e);
    formik.setTouched({
      ...formik.touched,
      [e.target.name]: false,
    });
  };
  const updateCustomerHandler = (id: any) => {
    setLoader(true);
    dispatch(
      UpdateCustomerSource({
        customerId: id,
        data: {
          firstName: formik?.values?.firstName,
          lastName: formik?.values?.lastName,
          // emailId: formik?.values?.email,
          // activationJourneyStatus: "IN_PROGRESS",
        },
      })
    );
  };
  useNonInitialEffect(() => {
    let currentLine: any = JSON.parse(
      localStorage.getItem("secondaryCustomerDetail") || "null"
    );
    if (
      updateCustomerSource &&
      updateCustomerSource.status === "SUCCESS" &&
      updateCustomerSource.data
    ) {
      localStorage.setItem(
        "secondaryCustomerDetail",
        JSON.stringify(updateCustomerSource.data)
      );
      localStorage.setItem(
        "secondaryUserId",
        JSON.stringify(updateCustomerSource?.data?.id)
      );
      dispatch(customerGroup({ groupId: currentLine && currentLine.groupId }));
      setLoader(false);
    }
  }, [updateCustomerSource]);
  useEffect(() => {
    setLoader(false);
  }, [errorUpdateCustomerSource]);
  useEffect(() => {
    let currentdetails: any = localStorage.getItem("customerDetail");
    currentdetails = JSON.parse(currentdetails);
    dispatch(
      customerGroup({
        groupId: currentdetails && currentdetails.groupId,
      })
    );
    setUser(currentdetails);
  }, []);
  useNonInitialEffect(() => {
    if (
      createCustomer &&
      createCustomer.status === "SUCCESS" &&
      createCustomer.data
    ) {
      localStorage.setItem(
        "secondaryCustomerDetail",
        JSON.stringify(createCustomer.data)
      );
      localStorage.setItem(
        "secondaryUserId",
        JSON.stringify(createCustomer?.data?.id)
      );
      dispatch(updateSecondaryUser(createCustomer.data));
      // dispatch(
      //   customerGroup({
      //     groupId: createCustomer && createCustomer?.data?.groupId,
      //   })
      // );
      setLoader(false);
    }
  }, [createCustomer]);
  useEffect(() => {
    setLoader(false);
  }, [errorUpdateCustomerSource]);
  const createCustomerHandler = () => {
    const numOfEsimOrdered =
      currentLine?.simPreference === SimTypes.ESIM ? 1 : 0;
    const numOfPsimOrdered =
      currentLine?.simPreference !== SimTypes.ESIM ? 0 : 1;
    try {
      setLoader(true);
      dispatch(
        CreateCustomer({
          countryCode: "USA",
          zipcode: primayUser?.zipcode,
          parentId: primayUser?.id,
          isMNP: false,
          isPrimary: false,
          primaryNumber: primayUser.billingNumber,
          addresses: primayUser?.addresses,
          status: 0,
          reachPlanId: primayUser.reachPlanId,
          isSimInHand: false,
          extraLines: 0,
          selectedShippingMethod: primayUser.selectedShippingMethod,
          esim: currentLine?.simPreference === SimTypes.ESIM ? true : false,
          activationJourneyStatus: "NOT_STARTED",
          physicalSimsOrdered: numOfPsimOrdered,
          eSimsOrdered: numOfEsimOrdered,
          flowVersion: 1,
          imei: currentLine?.imei || "",
          make: currentLine?.make || "",
          model: currentLine?.model || "",
          firstName: formik?.values?.firstName,
          lastName: formik?.values?.lastName,
          // emailId:  formik?.values?.email,
          groupId: primayUser?.groupId,
          leadSource: "Web",
          simPaid: true,
        })
      ).then(() => {
        dispatch(
          UpdateCustomerSource({
            customerId: primayUser?.id,
            data: {
              linePreferences: updatePreferences,
            },
          })
        );
      });
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <Box
        sx={{
          width: { xs: "90%", md: "45%" },
          margin: "0 auto",
          background: "var(--white)",
          marginTop: "60px",
          marginBottom: { xs: "100px", md: "325px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "18px",
            height: "18px",
            position: "absolute",
            left: "3rem",
            top: "21.5rem",
            cursor: "pointer",
          }}
          onClick={() => handleStep(false)}
        >
          <ArrowBackIosIcon sx={{ width: "18px" }} />
          <Box>{content?.back_txt}</Box>
        </Box>
        <KeyboardBackspaceIcon
          sx={{
            display: { xs: "block", sm: "none" },
            position: "absolute",
            left: "1.4rem",
            top: "6.1rem",
            color: "var(#313131)",
            cursor: "pointer",
          }}
          onClick={() => handleStep(false)}
        />
        <Box textAlign={{ xs: "center" }} sx={{ p: "30px" }}>
          <Box>
            <Typography
              sx={{
                fontSize: "24px",
                marginTop: "37px",
                marginBottom: "30px",
                fontFamily: "var(--font_family_Bold)",
                color: "var(--title-color_1)",
              }}
            >
              {content?.user_info_txt}
            </Typography>
            {/* Here the second or third line text will be dynamic based on cta click in the my account page. */}
            <Typography
              sx={{
                fontSize: "16px",
                marginBottom: "30px",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {content?.user_info_sub_txt}
            </Typography>
          </Box>
          <Box
            marginBottom="25px"
            paddingLeft={{ md: "30px" }}
            paddingRight={{ md: "30px" }}
          >
            <TextFieldCust
              value={formik.values.firstName}
              label={content?.fname_label}
              name="firstName"
              onChange={(e: any) => handleFormikChange(e)}
              error={
                formik.touched?.firstName && formik?.errors?.firstName
                  ? true
                  : false
              }
              helperText={formik?.errors?.firstName}
            />
          </Box>
          <Box
            marginBottom="25px"
            paddingLeft={{ md: "30px" }}
            paddingRight={{ md: "30px" }}
          >
            <TextFieldCust
              value={formik.values.lastName}
              label={content?.lname_label}
              name="lastName"
              onChange={(e: any) => handleFormikChange(e)}
              error={
                formik.touched?.lastName && formik?.errors?.lastName
                  ? true
                  : false
              }
              helperText={formik?.errors?.lastName}
            />
          </Box>
          {/* <Box
            marginBottom="25px"
            paddingLeft={{ md: "30px" }}
            paddingRight={{ md: "30px" }}
          >
            <TextFieldCust
              value={formik.values.email}
              label={content?.email_label}
              name="email"
              onChange={(e: any) => handleFormikChange(e)}
              error={
                formik.touched?.email && formik?.errors?.email ? true : false
              }
              helperText={formik?.errors?.email}
            />
          </Box> */}
          <Box marginBottom="25px">
            <ButtonCust
              variantType={config.PRIMARY_BUTTON_TYPE}
              onClick={() => formik.handleSubmit()}
              sx={{
                width: { xs: "250px", sm: "250px", md: "250px" },
                height: { md: "50px !important" },
              }}
              disabled={!formik?.values?.firstName || !formik?.values?.lastName}
            >
              {loader ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                content?.continue_txt
              )}
            </ButtonCust>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default SecondaryActivationData;
