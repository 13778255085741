import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import Styles from "./S1_IRPurchaseHistory.module.scss";

const T1_IRDataUsage = (props: any) => {
  const {
    usage_title,
    data_usage,
    voice_usage,
    sms_usage,
    mms_usage,
    slash,
    show_details,
  } = props.content;
  const { data, packId, handleDataUsage } = props;
  const { horizontal_line, vertical_line } = Styles;
  const { getPackDataUsage, getPackDataUsageError, getPackDataUsagePending } =
    useAppSelector((state) => state.services);

  return (
    <Box>
      <Box className={horizontal_line}></Box>
      <Typography
        component="div"
        sx={{
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--font_weight_5)",
          fontSize: "18px",
          color: "var(--tab_color_2)",
          textAlign: "center",
          mb: "5px",
        }}
      >
        {usage_title}
      </Typography>
      <Typography
        component="div"
        sx={{
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--font_weight_5)",
          fontSize: "14px",
          color: "var(--primary_brand_color)",
          textAlign: "center",
          mb: "30px",
          cursor: "pointer",
        }}
        onClick={() => handleDataUsage(data)}
      >
        {show_details}
      </Typography>

      {data.id === packId && (
        <>
          {!getPackDataUsagePending ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "30px",
              }}
            >
              <Typography
                component="div"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1)",
                  fontSize: "14px",
                  color: "var(--tab_color_2)",
                }}
              >
                {data_usage}{" "}
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {getPackDataUsage?.dataUsage?.international}
                  {slash}
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {data.totalDataGB}
                </Box>
              </Typography>
              <Box className={vertical_line}></Box>
              <Typography
                component="div"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1)",
                  fontSize: "14px",
                  color: "var(--tab_color_2)",
                }}
              >
                {voice_usage}{" "}
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {getPackDataUsage?.voiceMinutes?.international}
                  {slash}
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {data.voiceMins}
                </Box>
              </Typography>
              <Box className={vertical_line}></Box>
              <Typography
                component="div"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1)",
                  fontSize: "14px",
                  color: "var(--tab_color_2)",
                }}
              >
                {sms_usage}{" "}
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {getPackDataUsage?.sms?.international}
                  {slash}
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {data.sms}
                </Box>
              </Typography>
              <Box className={vertical_line}></Box>
              <Typography
                component="div"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1)",
                  fontSize: "14px",
                  color: "var(--tab_color_2)",
                }}
              >
                {mms_usage}{" "}
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {getPackDataUsage?.mms?.international}
                  {slash}
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "var(--primary_color)",
                    fontWeight: "var(--font_weight_5)",
                  }}
                >
                  {data.mms}
                </Box>
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                mb: "30px",
              }}
            >
              <CircularProgress
                sx={{
                  color: "var(--primary_brand_color_dark)",
                }}
                style={{
                  width: "25px",
                  height: "25px",
                }}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default T1_IRDataUsage;
