export const CouponTypes: any = {
  1: {
    type: "EMPLOYEE",
    message: "Employee discount",
  },
  2: {
    type: "EARLYBIRD",
    message: "Early access discount",
  },
  3: {
    type: "REFERRAL",
    message: "Referral code",
  },
  4: {
    type: "BETA",
    message: "Beta tester discount",
  },
  5: {
    type: "PROMO",
    message: "Coupon code",
  },
};
