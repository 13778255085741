import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { getILDContent } from "../../../features/strapi/serviceSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
const PAInternationalCallingComp = isTemplateExisits(
  Config.PostActivationServices.PAInternationalCalling
)
  ? require(`./${Config.PostActivationServices.PAInternationalCalling.template}_PAInternationalCalling`)
      .default
  : null;

type Props = {
  setInternationalService: any;
  handleBillCycleChange: any;
  selectedDateKeyIndex: any;
};
export const PAInternationalCalling = (props: Props) => {
  const {
    setInternationalService,
    handleBillCycleChange,
    selectedDateKeyIndex,
  } = props;
  const dispatch = useAppDispatch();
  const { ILDContent } = useAppSelector((state) => state.strapi.services);
  const [cacheDataCalling, setCacheDataCalling] = useState<any>(null);

  useEffect(() => {
    getCache("PostActCalling", getILDContent, setCacheDataCalling, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("PostActCalling", ILDContent);
  }, [ILDContent]);

  return cacheDataCalling || ILDContent ? (
    <PAInternationalCallingComp
      content={cacheDataCalling ? cacheDataCalling : ILDContent}
      setInternationalService={setInternationalService}
      handleBillCycleChange={handleBillCycleChange}
      selectedDateKeyIndex={selectedDateKeyIndex}
    />
  ) : null;
};
