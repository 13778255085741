import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getTransactionHistory } from "../../../../../features/services/servicesSlice";
import _ from "lodash";
import {
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../../../utils/commonFunctions";
import AppConfig from "../../../../../config/app.config.json";

const TransactionHistoryComp = isTemplateExisits(
  AppConfig.PostActivationServices.TransactionHistory
)
  ? require(`./${AppConfig.PostActivationServices.TransactionHistory.template}_TransactionHistory`)
      .default
  : null;

const TransactionHistory = (props: any) => {
  const dispatch = useAppDispatch();
  const { view_more, view_less } = props.content.wh_data
    ? props.content.wh_data
    : props.content;

  const { getTransactionHistoryData, getTransactionHistoryPending } =
    useAppSelector((state) => state?.services);
  const { group } = useAppSelector((state) => state.account);

  const [selectedLineValue, setSelectedLineValue] = useState({
    name: "",
    status: "",
  });
  const [walletuserData, setWalletUserData] = useState<any>({});

  const [userList, setUserList] = useState<any>([]);

  const [lineAnchorEl, setLineAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);
  const lineOpen = Boolean(lineAnchorEl);
  const initialItemsToShow = 3;
  const getStartAndEndDateJSON: any = localStorage.getItem("StartEndDate");
  const getStartAndEndDateData = JSON.parse(getStartAndEndDateJSON);

  const handleLineClose = () => {
    setLineAnchorEl(null);
  };

  const handleLineItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: any
  ) => {
    setSelectedLineIndex(index);
    setSelectedLineValue(option);
    setLineAnchorEl(null);
  };
  const handleClickLineItem = (event: React.MouseEvent<HTMLElement>) => {
    setLineAnchorEl(event.currentTarget);
  };

  function convertDateFormat(inputDate: any) {
    // Create a Date object by parsing the original date string
    const dateObject = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObject.getFullYear();
    // Months are 0-based in JavaScript, so we add 1 to get the correct month
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const startDate = convertDateFormat(getStartAndEndDateData.displayStartDate);
  const endDate = convertDateFormat(getStartAndEndDateData.displayEndDate);

  const newFilteredData = getTransactionHistoryData.filter(
    (item: any) => item.billMonth >= startDate && item.billMonth <= endDate
  );

  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  const formatDate = (PurchaseDate: any) => {
    const date = new Date(PurchaseDate);
    const options: any = { year: "numeric", month: "short", day: "2-digit" };

    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const handleViewMoreClick = () => {
    if (itemsToShow === initialItemsToShow) {
      setItemsToShow(getTransactionHistoryData?.length); // Show all items
    } else {
      setItemsToShow(initialItemsToShow); // Show limited items
    }
  };

  const toggleButtonText = () => {
    if (itemsToShow === initialItemsToShow) {
      return view_more;
    } else {
      return view_less;
    }
  };

  const getUserList = () => {
    let newuserArr = group.data
      .filter((user: any) => user.status == 2)
      .sort(
        (a: any, b: any) =>
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
      )
      .map((item: any) => ({
        name: item.firstName + " " + item.lastName,
        status: item.id,
      }));

    return _.uniqBy(newuserArr, function (e: any) {
      return e.status;
    });
  };

  useNonInitialEffect(() => {
    let tempUser = group.data.filter(
      (lineData: any) => lineData.id === selectedLineValue.status
    );

    setWalletUserData(tempUser[0]);
  }, [selectedLineValue]);

  useNonInitialEffect(() => {
    const payload = {
      customerID: selectedLineValue?.status,
      params: { creditType: "ILD" },
    };
    dispatch(getTransactionHistory(payload));
  }, [walletuserData]);

  useEffect(() => {
    setUserList(getUserList());
  }, []);

  useEffect(() => {
    if (userList.length > 0) {
      const payload = {
        customerID: props.lineDataForWallet.id,
        params: { creditType: "ILD" },
      };
      dispatch(getTransactionHistory(payload));
      setWalletUserData(props?.lineDataForWallet);

      let fiterUser = userList.filter(
        (x: any) => x.status === props?.lineDataForWallet.id
      );
      const index = userList.findIndex((object: any) => {
        return object.status === props?.lineDataForWallet.id;
      });

      let selectedVal: any = {
        ...fiterUser[0],
      };
      setSelectedLineValue(selectedVal);
      setSelectedLineIndex(index);
    }
  }, [userList]);
  return (
    <>
      <TransactionHistoryComp
        {...props}
        userList={userList}
        handleLineItemClick={handleLineItemClick}
        handleLineClose={handleLineClose}
        lineAnchorEl={lineAnchorEl}
        handleClickLineItem={handleClickLineItem}
        selectedLineIndex={selectedLineIndex}
        lineOpen={lineOpen}
        toggleButtonText={toggleButtonText}
        handleViewMoreClick={handleViewMoreClick}
        walletuserData={walletuserData}
        getStartAndEndDateData={getStartAndEndDateData}
        newFilteredData={newFilteredData}
        itemsToShow={itemsToShow}
        formatDate={formatDate}
      />
    </>
  );
};

export default TransactionHistory;
