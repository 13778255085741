import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonCust, ManageCard, SwitchCust } from "../../../widgets";
import styles from "./S3_PaymentReviewCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { paymentProps } from "./PaymentReviewCard";
import { CardTypes } from "../../../../enums";
import { renderTextStyles } from "../../../../utils";

function T3_PaymentReviewCard(props: paymentProps) {
  const {
    visa_card,
    firstRowTitle,
    firstRowValue,
    secondRowTitle,
    secondRowValue,
    loading,
    thirdRowTitle,
    confirmBtn,
    changeCardText,
    btnLoading,
    dataCap,
    availableOfferingsData,
    defaultCard,
    isChangeCard,
    setIsChangeCard,
    getCCDetails,
    american_express,
    discover,
    jcb,
    master_card,
    data,
    otherProps,
  } = props;

  const { credit_icon, credit_payment } = otherProps;
  return (
    <>
      {!loading ? (
        <>
          <Box className={styles.main_container} data-testid="pay_review_card_wrapper">
            <Box
              sx={{
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: { xs: "24px", md: "24px" },
                padding: "20px",
                display: "flex",
                justifyContent: " center",
                alignItems: "center",
                borderBottom: "2px solid var(--lightnavy)",
              }}
            >
              <Box
                component="img"
                src={credit_icon?.data?.attributes?.url || ""}
                alt={credit_icon?.data?.attributes?.alternativeText || ""}
                data-testid="pay_review_card_img"
              />
              <Box
                component="span"
                sx={{
                  marginLeft: "10px",
                  fontFamily: "var(--font_family_Bold) !important",
                  fontSize: { xs: "18px", md: "24px" },
                  color: "var(--black)",
                }}
                data-testid="pay_review_card_title"
              >
                {credit_payment?.creditTitle}
              </Box>
            </Box>
            <Box sx={{ padding: { xs: "30px 0", sm: "30px 30px" } }}>
              <Box className={styles.rows} sx={{ mb: 1 }}>
                <Typography className={styles.displayPack} data-testid="pay_review_card_first_row_title">
                  {firstRowTitle}
                  {dataCap
                    ? dataCap / availableOfferingsData.servicePulse
                    : null}
                  {data?.data ? " " + data?.data + data?.dataUnit : null}
                </Typography>
                <Typography className={styles.displayPack} data-testid="pay_review_card_daily_pack">
                  {GetCurrencyFixedToTwo(firstRowValue)}
                </Typography>
              </Box>
              <Box className={styles.rows} sx={{ mb: 1 }}>
                <Typography className={styles.displayPack} data-testid="pay_review_card_second_row_title">
                  {secondRowTitle}
                </Typography>
                <Typography className={styles.displayPack} data-testid="pay_review_card_daily_pack">
                  {GetCurrencyFixedToTwo(secondRowValue)}
                </Typography>
              </Box>
              <Box className={styles.rows} sx={{ mb: 1 }}>
                <Typography
                  sx={renderTextStyles(
                    2,
                    "18px",
                    "text_color",
                    "28px",
                    "--font_weight_2"
                  )}
                  data-testid="pay_review_card_third_row_title"
                >
                  {thirdRowTitle}
                </Typography>
                <Typography
                  sx={renderTextStyles(
                    2,
                    "18px",
                    "text_color",
                    "28px",
                    "--font_weight_2"
                  )}
                  data-testid="pay_review_card_value"
                >
                  {GetCurrencyFixedToTwo(firstRowValue + secondRowValue)}
                </Typography>
              </Box>
              <Box className={styles.main_card}>
                <Box className={styles.card}>
                  <Box
                    className={styles.displayPack}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                  >
                    <img
                      src={
                        defaultCard?.type?.toUpperCase() === CardTypes?.VISA
                          ? visa_card.url
                          : defaultCard?.type?.toUpperCase() ===
                            CardTypes?.MASTERCARD
                          ? master_card?.data?.attributes?.url
                          : defaultCard?.type?.toUpperCase() === CardTypes?.JCB
                          ? jcb?.data?.attributes?.url
                          : defaultCard?.type?.toUpperCase() ===
                              CardTypes?.AMERICANEXPRESS ||
                            defaultCard?.type?.toUpperCase() === CardTypes?.AMEX
                          ? american_express?.data?.attributes?.url
                          : discover?.data?.attributes?.url
                      }
                      data-testid="pay_review_card_card_img"
                      alt={
                        defaultCard && defaultCard.type === CardTypes.VISA
                          ? visa_card?.data?.attributes?.alternativeText
                          : american_express?.data?.attributes?.alternativeText
                      }
                    />
                    <Typography
                      className={styles.displayPack}
                      sx={{
                        fontSize: {
                          xs: "12px !important",
                          sm: "14px !important",
                        },
                      }}
                      data-testid="pay_review_card_number"
                    >
                      {`${props?.mockCard}${defaultCard?.ccNumberMask}`}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "12px", sm: "16px" },
                    }}
                    onClick={() => setIsChangeCard(true)}
                    className={styles.changeCard}
                    data-testid="pay_review_card_change_card_text"
                  >
                    {changeCardText}
                  </Typography>
                </Box>
              </Box>
              <ButtonCust
                data-testid="pay_review_card_btn"
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={props.onClick}
                sx={{
                  width: "250px",
                  display: "flex",
                  margin: "33px auto 13px",
                  // "&:hover": {
                  //   backgroundColor: "var(--button_hover_color) !important",
                  //   border: "1px solid var(--button_hover_color) !important",
                  // },
                }}
              >
                {btnLoading ? <CircularProgress /> : confirmBtn}
              </ButtonCust>
            </Box>
          </Box>
        </>
      ) : (
        <Box textAlign={"center"} data-testid="pay_review_card_progress">
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
      <ManageCard
        cardData={getCCDetails}
        showCardChangeModal={isChangeCard ? true : false}
        setShowCardChangeModal={() => setIsChangeCard(false)}
        isModalRequired={true}
        pageName={"services"}
        isDescription={true}
        payType={true}
      />
    </>
  );
}

export default T3_PaymentReviewCard;
