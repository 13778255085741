import { InternalBannerProps } from "./InternalBanner";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";

const InternalBannerT1 = (props: InternalBannerProps) => {
  const { heading, banner_web, banner_ipad, sub_heading } =
    props?.bannerContent || {};

  const background_box = {
    display: { xs: "block", sm: "none" },
    width: "100%",
    minHeight: "60px",
    backgroundColor: "var(--primary_brand_color)",
    opacity: 0.2,
  };

  const grid_wrapper = {
    position: "absolute",
    textAlign: { xs: "center", sm: "left", md: "center" },
    color: { xs: "var(--primary_brand_color)", sm: "inherit" },
    transform: {
      xs: "translate(-50%, -50%)",
      sm: "translateY(-50%)",
      md: "translate(-50%, -50%)",
    },
    top: "50%",
    left: { xs: "50%", sm: "40px", md: "50%" },
  };

  const { largeDesktop, desktop, ipad } = getResolutionDetails();
  return (
    props?.bannerContent && (
      <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
        <Paper sx={{ padding: 0, boxShadow: "unset" }} data-testid="banner_internal_image_1">
          {(largeDesktop || desktop) &&
            generateImageContainer({
              data: banner_web,
              attrs: {
                width: "100%",
                height: "auto",
              },
            })}
          {ipad &&
            generateImageContainer({
              data: banner_ipad,
              attrs: {
                width: "100%",
                height: "auto",
              },
            })}
        </Paper>
        <Box sx={background_box} />
        <Grid sx={grid_wrapper}>
          <Grid item>
            <Box>
              {heading && (
                <Typography
                  component="h1"
                  variant="h3"
                  gutterBottom
                  sx={{
                    margin: { xs: 0 },
                    width: { xs: "80vw", sm: "50vw", lg: "55vw" },
                    color: "var(--title-color_1)",
                  }}
                  className="internal_banner_title"
                  dangerouslySetInnerHTML={{
                    __html: heading,
                  }}
                  data-testid="banner_internal_heading_1"
                ></Typography>
              )}
              {sub_heading && (
                <Typography
                  variant="h4"
                  component="h4"
                  className={"main_banner_sub_title"}
                  sx={{
                    margin: "14px 0px 25px 0px",
                    display: { xs: "none", sm: "block" },
                    width: { xs: "80vw", sm: "50vw", lg: "55vw" },
                  }}
                  data-testid="banner_internal_sub_heading_1"
                >
                  {sub_heading}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default InternalBannerT1;
