import { useEffect } from "react";
import { DetailsCoverageProps } from "./DetailsCoverage";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import { Grid, Typography, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S5_DetailsCoverage.module.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function DetailsCoverageCmp(props: DetailsCoverageProps) {
  let navigate = useNavigate();
  const { coverageCheckContent } = props;
  const { heading, back, iframeLink, iframeTitle, back_nav, sub_heading } =
    coverageCheckContent;
  const { details_coverage, iframeMapId } = styles;
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  useEffect(() => {
    window.scroll(0, 0);
    let container = document.getElementById(iframeMapId);
    window.addEventListener(
      "message",
      function (e) {
        let message = e.data;
        if (message.map && message.height && container) {
          container.style.height = message.height + 350 + "px";
        }
      },
      false
    );
  }, []);
  return (
    <Grid container className={details_coverage} mt={{ xs: 6.8 }} data-testid="details_coverage_parent">
      <Grid container direction="row" px={"40px"} py={"20px"}>
        {/* <Grid
          item
          onClick={() => navigate(back_nav)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          data-testid="details_coverage_back_icon"
        >
          <ArrowBackIosIcon />
          &nbsp;
          {back}
        </Grid> */}
        <Box onClick={() => navigate(back_nav)} height={"1px"}>
        <KeyboardBackspaceIcon
          fontSize="medium"
          sx={{
            display: { xs: "block", sm: "none" },
            position: "absolute",
            top: "81.5px",
            left: "25px",
            color: "var(--internal_back_arrow_color)",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap="2px"
        sx={{
          ml: { lg: "0px", sm: "25px", xs: "25px" },
          mt: { md: "0px", sm: "10px", xs: "20px" },
          pt: "22px",
          mb: "20px",
          width: "fit-content",
          display: { xs: "none", sm: "flex" },
          cursor: "pointer",
        }}
        className={styles.arrow_icon}
        onClick={() => navigate(back_nav)}
        data-testid="checkout_back_icon"
      >
        <ArrowBackIosIcon
          sx={{ fontSize: "1.125rem", color: "var(--dark-charcoal-fade)" }}
          data-testid="checkout_back_icon_img"
        />
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "var(--font_weight_1)",
            fontSize: "18px",
            lineHeight: "30px",
            color: "var(--dark-charcoal-fade)",
          }}
          data-testid="checkout_back_icon_text"
        >
          {back}
        </Box>
      </Box>
        <Grid item sx={{ mx: "auto" }}>
          <Typography
            variant="h1"
            lineHeight={1.1}
            sx={{
              textAlign: "center",
              fontSize: "35px !important",
              fontWeight: "var(--h4_font_weight)!important",
              fontFamily: "var(--h4_font_family)!important",
              letterSpacing: "0.00735em",
              margin: { xs: "20px 0px 30px", md: "30px 0px" },
              color: "#313131",
            }}
            data-testid="details_coverage_heading"
          >
            {heading}
            {/* Mobile coverage map */}
          </Typography>
          <Typography
            lineHeight={1.6}
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Medium)",
              textAlign: "center",
              color: "#313131",
            }}
            data-testid="details_coverage_sub_heading"
          >
            {!configKeys?.INTERNATIONAL_CALLING_ENABLED ?  sub_heading.split(",")[0] : sub_heading.split(",")[1]}
            {/* Mexico/Canada coverage and International Calling/Roaming are
            currently unavailable. */}
          </Typography>
        </Grid>
      </Grid>

      <Grid container textAlign="center" mb={2.5} data-testid="details_coverage_iframe_parent">
        <iframe
          id={iframeMapId}
          src={iframeLink}
          title={iframeTitle}
          loading="lazy"
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default DetailsCoverageCmp;
