import { useEffect, useState } from "react";
import { Box, Checkbox, CircularProgress, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S4_ShippingAddress.module.scss";
import { ButtonCust, ManageCard, SelectCust } from "../../../../widgets";
import Config from "../../../../../config/env.config.json";
import { ShippingComponentProps } from "./ShippingAddress";
import { AddShippingAddress } from "../AddShippingAddressDialog/AddShippingAddress";
import { getOneTimeShippingTaxCharges } from "../../../../../features/checkout/checkoutSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { CardTypes } from "../../../../../enums";
import { getResolutionDetails } from "../../../../../utils/commonFunctions";
import { SimTypes } from "../../../../../features/checkout/Types";
import { gtmTagManager } from "../../../../../utils/commonFunctions/GtmTagManager";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ShippingComponent = (props: ShippingComponentProps) => {
  const {
    shippingAddress,
    sendSimOptions,
    setShipPlan,
    shipPlan,
    updateAddress,
    content,
    handleBackBtn,
    taxAmount,
    setTaxAmount,
    setIsAddLinePayment,
    currentCard,
    confirmPurchaseHandler,
    pageName,
    formik,
    isBacknavigation,
    setIsBackNavigation,
    loader,
    oneTimeTotalTax,
    setOneTimeTotalTax,
    ShipAndHandlingAmount,
    setShipAndHandlingAmount,
  } = props;

  const {
    title,
    title_1,
    subtitle,
    change_add,
    cnfrm_ship_add,
    continue_btn,
    change_card,
    select_ship_txt,
    num_mask,
    back_txt,
    review_title,
    review_subtitle,
    one_time_txt,
    shipping_txt,
    tax_txt,
    total_txt,
    pay_btn,
    shipping_logo,
  } = content;
  const [tick, setTick] = useState<any>(false);
  const [taxLoader, setTaxLoader] = useState<any>(false);
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const [showReviewDetails, setShowReviewDetails] = useState<boolean>(false);
  const [shippingAmount, setShippingAmount] = useState<number>(0);
  const [showCardChangeModal, setShowCardChangeModal] = useState(false);
  const dispatch = useAppDispatch();
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { getCCDetails } = useAppSelector((state: any) => state.account);
  const { mobile } = getResolutionDetails();

  const handleChangeSimOptions = (e: any) => {
    let event: any;
    const shippingData = sendSimOptions.find((el: any, index: number) => {
      event =
        index === 0
          ? globalVal?.gtmEvents?.shipping1Act
          : index === 1
          ? globalVal?.gtmEvents?.shipping2Act
          : globalVal?.gtmEvents?.shipping3Act;
      return el.value === e.target.value;
    });
    setTaxAmount(0);
    setShipPlan(shippingData);
    // getShippingTaxAmount(shippingData);
    localStorage.setItem("selectedShipment", JSON.stringify(shippingData));
    gtmTagManager({
      event: globalVal?.gtmEvents?.newNumEditPlan,
      click_section: "activation",
    });
  };

  const isOnlyEsimSelected = () => {
    let isOnlyEsimAdded = formik?.values?.every(
      (simItem: any) => simItem.simPreference === SimTypes.eSim
    );
    return isOnlyEsimAdded;
  };
  const getShippingTaxAmount = (shippingData: any) => {
    if (shippingData?.shippingAmount > 0) {
      setShippingAmount(shippingData?.shippingAmount);
      setTaxLoader(true);
      dispatch(
        getOneTimeShippingTaxCharges({
          address: {
            ...shippingAddress,
          },
          amount: shippingData?.shippingAmount,
        })
      ).then((e: any) => {
        const res = e?.payload?.data;
        if (res?.status === "SUCCESS") {
          const taxAmount = res.data?.totalTax;
          setTaxAmount(taxAmount);
        }
        setTaxLoader(false);
      });
    }
  };

  const handleShippingDetails = () => {
    if (pageName === "activation") {
      confirmPurchaseHandler();
    } else if (pageName === "addline") {
      setIsAddLinePayment(true);
    }
    gtmTagManager({
      event: globalVal?.gtmEvents?.addALineReviewDetailsConfirm,
      click_section: "activation",
    });
  };

  const checkPrioritySIMStatus = () => {
    const array = formik?.values;
    const hasSimInHandTruePhysicalSIM = array.some((item: any) => item.simInHand === true && item.simPreference === SimTypes.phySim);
    const hasSimInHandFalsePhysicalSIM = array.some((item: any) => item.simInHand === false && item.simPreference === SimTypes.phySim);
    const hasSimInHandFalseEsim = array.some((item: any) => item.simInHand === false && item.simPreference === SimTypes.eSim);
    if (hasSimInHandTruePhysicalSIM && hasSimInHandFalsePhysicalSIM) {
      return false;
    } else if (hasSimInHandFalsePhysicalSIM) {
      return false;
    } else if (hasSimInHandTruePhysicalSIM && !hasSimInHandFalsePhysicalSIM) {
      return true;
    } else if (hasSimInHandTruePhysicalSIM && hasSimInHandFalseEsim) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isOnlyEsimSelected() && isBacknavigation) {
      handleBackBtn();
    }
    if (checkPrioritySIMStatus() && isBacknavigation && Config.IS_MULTI_SIM_IN_HAND_ENABLED) {
      handleBackBtn();
      return
    }
  }, []);

  // useEffect(() => {
  //   if (!shipPlan) {
  //     const shippingData = sendSimOptions?.[0];
  //     setShipPlan(shippingData);
  //     getShippingTaxAmount(shippingData);
  //   } else {
  //     getShippingTaxAmount(shipPlan);
  //   }
  // }, [shippingAddress]);

  return (
    content &&
    (!showReviewDetails ? (
      <Box
        sx={{
          margin: { xs: "10px 0 150px", sm: "30px 0 100px" },
          width: { md: "100%" },
        }}
        data-testid="shipping_address_wrapper"
      >
        <Box
          className={styles.backCTA}
          onClick={() => {
            if (isChangeAddress) {
              setIsChangeAddress(false);
            } else {
              handleBackBtn();
            }
          }}
          data-testid="shipping_address_back"
          sx={{
            display: { xs: "none", sm: "flex" },
            mt: "-50px",
            alignItems: "center",
            width: "fit-content",
            position: "absolute",
            top: "20rem",
            left: "1rem",
          }}
        >
          <ArrowBackIosIcon
            sx={{
              ml: { md: "30px", sm: "25px", xs: "4%" },
              width: "15px",
              height: "15px",
            }}
            data-testid="shipping_address_back_icon"
          />
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_1)",
                fontSize: "18px",
                lineHeight: "30px",
              },
            }}
            data-testid="shipping_address_back_text"
          >
            {back_txt}
          </Box>
        </Box>
        <KeyboardBackspaceIcon
        onClick={handleBackBtn}
        fontSize="large"
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          top: "21.5px",
          left: "20px",
          color: "#313131",
          fontSize: "22px",
        }}
        data-testid="add_line_summ_back_icon"
      />
        {isChangeAddress ? (
          <AddShippingAddress
            content={content}
            updateAddress={(e: any) => {
              updateAddress(e);
              setIsChangeAddress(false);
            }}
            showBackArrow={false}
            showLabels={true}
          />
        ) : (
          <Box
            sx={{
              width: { xs: "90%", sm: "40%" },
              margin: "50px auto",
              background: "var(--white)",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderBottom: "2px solid var(--lightgrey_6)",
                p: "31px 0",
              }}
            >
              {/* <Box
                component="img"
                alt={title_1}
                src={shipping_logo?.data?.attributes?.url || ""}
                mr="10px"
                className={styles.profile_img}
                width="51px"
                height="35px"
              ></Box> */}
              <Box
                sx={{
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "var(--font_weight_4)",
                  fontSize: "24px",
                  lineHeight: "40px",
                  color: "var(--black)",
                }}
                data-testid="shipping_address_title"
              >
                {pageName === "addline" ? title_1 : title}
              </Box>
            </Box>
            {pageName !== "addline" && (
              <Box className={styles.subtitle} data-testid="shipping_address_subtitle">{subtitle}</Box>
            )}
            <Box className={styles.boxContainer}>
              <Box
                display="flex"
                justifyContent="space-between"
                className={styles.selectShipDetails}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  // mt: "30px",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "70%", md: "100%" },
                  }}
                  className={styles.shipOptions}
                  data-testid="shipping_address_ship_option_wrapper"
                >
                  {/* <Box marginBottom={"5px"}>{select_ship_txt}</Box> */}
                  <SelectCust
                    value={shipPlan?.value}
                    name="shipmentPlan"
                    width="100%"
                    className={styles.selectCust}
                    options={sendSimOptions}
                    onChange={(e: any) => handleChangeSimOptions(e)}
                    data-testid="shipping_address_select"
                  />
                </Box>
              </Box>

              <Box
                className={styles.change_add}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #BABABA",
                  p: "20px 0",
                }}
              >
                <Box className={styles.shipping_text} data-testid="shipping_address_checkbox_wrapper">
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      setTick(!tick);
                    }}
                    checked={tick}
                    data-testid="shipping_address_checkbox"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px 20px 10px 20px",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      fontSize: "16px",
                      lineHeight: "28px",
                    }}
                    data-testid="shipping_address_cnfm_ship_add"
                  >
                    {cnfrm_ship_add}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_1)",
                      fontSize: "16px",
                      lineHeight: "28px",
                      textDecoration: "underline",
                    }}
                    mb={0.5}
                    className={styles.address_text}
                    data-testid="shipping_address_address_txt"
                  >
                    {shippingAddress?.address1}
                    {", "}
                    {shippingAddress?.city}
                    {", "}
                    {shippingAddress?.state}
                    {", "}
                    {shippingAddress?.zip}
                  </Box>
                </Box>
              </Box>
              <Box
                marginTop={"6px"}
                className={styles.changeCTA}
                onClick={() => {
                  if (!tick) {
                    setIsChangeAddress(true);
                    gtmTagManager({
                      event: globalVal?.gtmEvents?.changeShipAddChangeToPSIM,
                      click_section: "activation",
                    });
                  }
                }}
                data-testid="shipping_address_add_change"
                sx={{
                  cursor: !tick ? "pointer" : "not-allowed",
                  opacity: !tick ? 1 : 0.5,
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                {change_add}
              </Box>
              {currentCard && shipPlan?.shippingAmount > 0 && (
                <Box className={styles.change_card}>
                  <Box display="flex" alignItems="center">
                    <Box
                      component={"img"}
                      src={
                        currentCard?.[0]?.type?.toUpperCase() ===
                        CardTypes?.VISA
                          ? content?.visa?.data?.attributes?.url
                          : currentCard?.[0]?.type?.toUpperCase() ===
                            CardTypes?.MASTERCARD
                          ? content?.master_card?.data?.attributes?.url
                          : currentCard?.[0]?.type?.toUpperCase() ===
                            CardTypes?.DISCOVER
                          ? content?.discover?.data?.attributes?.url
                          : currentCard?.[0]?.type?.toUpperCase() ===
                              CardTypes?.AMERICANEXPRESS ||
                            currentCard?.[0]?.type?.toUpperCase() ===
                              CardTypes?.AMEX
                          ? content?.american_ex?.data?.attributes?.url
                          : ""
                      }
                      data-testid="shipping_address_card_img"
                      alt={
                        currentCard?.[0]?.type?.toUpperCase() ===
                        CardTypes?.VISA
                          ? content?.visa?.data?.attributes?.alternativeText
                          : currentCard?.[0]?.type?.toUpperCase() ===
                            CardTypes?.MASTERCARD
                          ? content?.master_card?.data?.attributes
                              ?.alternativeText
                          : currentCard?.[0]?.type?.toUpperCase() ===
                            CardTypes?.DISCOVER
                          ? content?.discover?.data?.attributes?.alternativeText
                          : currentCard?.[0]?.type?.toUpperCase() ===
                            CardTypes?.AMEX
                          ? content?.american_ex?.data?.attributes
                              ?.alternativeText
                          : ""
                      }
                      style={{ width: "45px", height: "30px" }}
                    />
                    <Typography
                      pl="10px"
                      fontFamily="var(--font_family_Semibold)"
                      fontSize="14px"
                      data-testid="shipping_address_card_num"
                    >
                      {num_mask}
                      {currentCard?.[0]?.ccNumberMask}
                    </Typography>
                  </Box>
                  <Typography
                    className={styles.changeCTA}
                    onClick={() => {
                      setShowCardChangeModal(true);
                      gtmTagManager({
                        event: globalVal?.gtmEvents?.changeCardChangeToPSIM,
                        click_section: "activation",
                      });
                    }}
                    data-testid="shipping_address_change_card"
                  >
                    {change_card}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                textAlign: "center",
                pb: "40px",
              }}
            >
              <ButtonCust
                sx={{
                  height: "50px !important",
                  minWidth: { xs: "250px" },
                }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={!loader ? handleShippingDetails : () => {}}
                disabled={!tick || taxLoader}
                loading={pageName === "activation" && props.btnLoader}
                data-testid="shipping_address_btn"
              >
                {loader ? <CircularProgress /> : continue_btn}
              </ButtonCust>
            </Box>
            <ManageCard
              cardData={getCCDetails}
              showCardChangeModal={showCardChangeModal}
              setShowCardChangeModal={setShowCardChangeModal}
              isModalRequired={true}
              pageName={"activation"}
              isDescription={true}
              payType={true}
            />
          </Box>
        )}
      </Box>
    ) : (
      <Box
        className={styles.reviewDetails}
        sx={{
          margin: { xs: "10px 0 150px", sm: "30px 0 170px" },
          width: { md: "100%" },
        }}
      >
        <Box
          className={styles.backCTA}
          onClick={() => setShowReviewDetails(false)}
          data-testid="shipping_address_back"
        >
          <ArrowBackIosIcon sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }} data-testid="shipping_address_back_icon" />
          {!mobile && (
            <Box
              sx={{
                textDecoration: "underline",
                fontFamily: "var(--font_family_Bold)",
              }}
              data-testid="shipping_address_back_txt"
            >
              {back_txt}
            </Box>
          )}
        </Box>
        <Box className={styles.pageTitle} data-testid="shipping_address_review_title">{review_title}</Box>
        <Box className={styles.subtitle} data-testid="shipping_address_review_subtitle">{review_subtitle}</Box>
        <Box className={styles.reviewBoxContainer}>
          <Box className={styles.container}>
            <Box className={styles.oneTimeTxt} data-testid="shipping_address_one_time_txt">{one_time_txt}</Box>
            <Box className={styles.charges}>
              <Box className={styles.charge}>
                <Box data-testid="shipping_address_shipping_txt">{shipping_txt}</Box>
                <Box data-testid="shipping_address_shipping_amount">${shippingAmount?.toFixed(2)}</Box>
              </Box>
              <Box className={styles.charge}>
                <Box data-testid="shipping_address_tax_txt">{tax_txt}</Box>
                <Box data-testid="shipping_address_tax_amount">${taxAmount?.toFixed(2)}</Box>
              </Box>
              <Box className={styles.charge}>
                <Box fontWeight={`var(--font_weight_2)`} data-testid="shipping_address_total_txt">{total_txt}</Box>
                <Box fontWeight={`var(--font_weight_2)`} data-testid="shipping_address_total_amount">
                  ${(shippingAmount + taxAmount)?.toFixed(2)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <ButtonCust
            data-testid="shipping_address_pay_btn"
            sx={{ marginTop: "30px" }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={!loader ? handleShippingDetails : () => {}}
            loading={pageName === "activation" && props.btnLoader}
          >
            {loader ? <CircularProgress /> : pay_btn}
          </ButtonCust>
        </Box>
      </Box>
    ))
  );
};

export default ShippingComponent;
