import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import styles from "./S2_Terms.module.scss";
import { Box, Typography } from "@mui/material";

type Props = {
  content: any;
};
const TermsComponent = (props: Props) => {
  const {content} = props;
  return (
    <Box className={styles.terms_page_content} data-testid="terms_parent">
      <Typography variant="h1" className={styles.terms_title} data-testid="terms_title">
        {content?.title}
      </Typography>
      {/* <Box className={styles.terms_line}></Box> */}
      <Box className={styles.content} data-testid="terms_content_parent">
        {content &&
          content?.Terms_content?.length > 0 &&
          content?.Terms_content?.map((items: any, index: number) => {
            return (
              <Box key={index} data-testid={`terms_content_wrapper_${Number(index) + 1 || 1}`}>
                <Typography className={styles.terms_headings} data-testid={`terms_content_heading_${Number(index) + 1 || 1}`}>
                  {index + 1}. {BrandNameUpdate(items?.heading)}
                </Typography>
                <Box
                  className={styles.terms_para}
                  dangerouslySetInnerHTML={{
                    __html: BrandNameUpdate(items?.description),
                  }}
                  data-testid={`terms_content_desc_${Number(index) + 1 || 1}`}
                ></Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
export default TermsComponent;
