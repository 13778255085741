import Styles from "./S2_ILDUserList.module.scss";
import { Box, Typography, CircularProgress } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import Config from "../../../../config/env.config.json";
import { useAppSelector } from "../../../../app/hooks";
import { formatPhoneNumber } from "../../../../utils/commonFunctions";
import {
  CustomerStatus as CS,
  LastDisconnectedReason,
} from "../../../../enums";
import CircleIcon from "@mui/icons-material/Circle";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { useEffect, useState } from "react";

const T2_ILDUserList = (props: any) => {
  const { group, handleAddCreditCTA, setIsViewHistory, setLineDataForWallet } =
    props;
  const {
    user_list_container,
    user_name,
    is_primary,
    user_num,
    plan_active,
    view_history_cta,
  } = Styles;
  const {
    amt_spent,
    amt_remaining,
    view_history,
    add_credit,
    primary_txt,
    failed_icon,
    disconnected,
    disconnected_txt_desc,
  } = props.content;
  const [sortedGroupData, setSortedGroupData] = useState([]);

  const { getILDCreditsData } = useAppSelector(
    (state: any) => state?.services || {}
  );
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );

  useEffect(() => {
    if (group && group?.data?.length > 0) {
      group?.data?.map((data: any, index: any) => {
        /***
         * *Sorting to display Primary line first and then sorting based on createdDate
         * */
        let sortedGroup = group?.data.filter((data: any) => data.isPrimary);
        sortedGroup = [
          ...sortedGroup,
          ...group?.data?.slice().sort((a: any, b: any) => {
            return (
              new Date(a.createdDate).getTime() -
              new Date(b.createdDate).getTime()
            );
          }),
        ];
        let i = sortedGroup.findLastIndex((data: any) => data.isPrimary); //removing duplicate primary line entry
        sortedGroup?.splice(i, 1);
        setSortedGroupData(sortedGroup);
      });
    }
  }, [group]);

  return (
    <>
      {group?.data?.length > 0 ? (
        sortedGroupData
          .filter(
            (data: any) =>
              data.status === CS.ACTIVE || data.status === CS.DISCONNECTED
          )
          .map((data: any) => {
            const fullName = data.firstName
              ? data.firstName + (data.lastName ? " " + data.lastName : "")
              : "";
            return !data?.isPrimary &&
              data.status === CS.DISCONNECTED &&
              (data?.lastDisconnectedReason ===
                LastDisconnectedReason?.TRIGGER_BY_USER ||
                data?.lastDisconnectedReason ===
                  LastDisconnectedReason?.REQUESTED_BY_CS) ? (
              <Box
                className={user_list_container}
                key={data.id}
                sx={{
                  mt: "40px",
                  flexDirection: "column",
                  alignItems: "unset",
                  padding: "25px",
                }}
              >
                <Box>
                  <Typography className={user_name} pb={1}>
                    <Box>
                      <Box
                        component="span"
                        sx={{
                          textTransform: "capitalize !importanr",
                        }}
                      >
                        {(data.firstName ? data.firstName : "") +
                          (data.lastName ? " " + data.lastName : "")}
                      </Box>{" "}
                      <Box component="span" className={is_primary}>
                        {data?.isPrimary ? `${primary_txt}` : ""}{" "}
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "5px !important",
                          margin: "0 8px !important",
                        }}
                      />
                      <Typography
                        component="span"
                        sx={{
                          color: "var(--red-color_3)",
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "16px",
                          padding: "1px 10px 1px 0px",
                          borderRadius: "2px",
                          background: "var(--account_suspended_color)",
                        }}
                      >
                        {disconnected}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    borderBottom: " 2px solid var(--navy)",
                    opacity: 0.1,
                    margin: "15px 0px 15px",
                    width: "100%",
                  }}
                ></Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={failed_icon?.data?.attributes?.url}
                  />

                  <Box
                    sx={{
                      fontSize: "16px",
                      color: "var(--subtext_color)",
                      py: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: {
                        xs: "0px 0px 10px 20px",
                        sm: "0px",
                        textAlign: "center",
                      },
                    }}
                  >
                    {`${fullName}${disconnected_txt_desc}`}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                className={user_list_container}
                key={data.id}
                sx={{
                  mt: "40px",
                }}
              >
                <Box
                  sx={{
                    padding: "15px 15px 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Box>
                      <Typography className={user_name} pb={1}>
                        <Box>
                          <Box component="span">{fullName?.toLowerCase()}</Box>{" "}
                          <Box component="span" className={is_primary}>
                            {data?.isPrimary ? `${primary_txt}` : ""} -{" "}
                          </Box>
                        </Box>
                        {data.reachPlanDisplayName && (
                          <Box component="span" className={plan_active}>
                            {data?.reachPlanDisplayName}
                          </Box>
                        )}
                      </Typography>
                      <Typography className={user_num}>
                        {data && data.reachNumber ? (
                          <Box component="span">
                            {formatPhoneNumber(data.reachNumber)}
                          </Box>
                        ) : data && data.mnpInfo?.oldNumber ? (
                          <Box component="span">
                            {formatPhoneNumber(data.mnpInfo?.oldNumber)}
                          </Box>
                        ) : null}
                      </Typography>
                    </Box>
                    <ButtonCust
                      variantType={Config.QUATERNARY_BUTTON_TYPE}
                      onClick={() => {
                        handleAddCreditCTA(data);
                        gtmTagManager({
                          event: globalVal?.gtmEvents?.manageILDAddCredits,
                          click_section: "International Calling",
                        });
                      }}
                      sx={{
                        mt: { xs: "10px", md: 0 },
                        width: "201px",
                        height: "50px !important",
                        p: "11px 20px",
                        "&:hover": {
                          backgroundColor:
                            "var(--button_hover_color) !important",
                          border:
                            "1px solid var(--button_hover_color) !important",
                        },
                      }}
                    >
                      {add_credit}
                    </ButtonCust>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: { xs: "column", md: "row" },
                      gap: "5px",
                      margin: "20px 0",
                      py: "20px",
                      color: "var(--text_color_8)",
                      fontSize: "14px",
                      fontWeight: "var(--font_weight_0)",
                      borderTop: "2px solid var(--lightnavy)",
                      borderBottom: "2px solid var(--lightnavy)",
                    }}
                  >
                    <Typography
                      sx={{
                        width: { xs: "100%", md: "fit-content" },
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        {amt_remaining}
                      </Box>{" "}
                      <Box
                        component="span"
                        sx={{
                          fontWeight: "var(--font_weight_2)",
                          ml: "5px",
                          fontSize: "14px",
                        }}
                      >
                        {GetCurrencyFixedToTwo(
                          (getILDCreditsData &&
                            getILDCreditsData[data.id]?.availableCredit) ||
                            0
                        )}
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        width: { xs: "100%", md: "fit-content" },
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        {amt_spent}
                      </Box>{" "}
                      <Box
                        component="span"
                        sx={{
                          fontWeight: "var(--font_weight_2)",
                          ml: "5px",
                          fontSize: "14px",
                        }}
                      >
                        {GetCurrencyFixedToTwo(
                          (getILDCreditsData &&
                            getILDCreditsData[data.id]?.purchaseCredit) ||
                            0
                        )}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  onClick={() => {
                    setIsViewHistory(true);
                    setLineDataForWallet(data);
                    gtmTagManager({
                      event: globalVal?.gtmEvents?.viewUsageHistoryILD,
                      click_section: "International Calling",
                    });
                  }}
                  className={view_history_cta}
                >
                  {view_history}
                </Box>
              </Box>
            );
          })
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default T2_ILDUserList;
