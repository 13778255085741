import styles from "./SecondaryInfoCard.module.scss";
import { ButtonCust, LazyLoadImageCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { Box, Grid } from "@mui/material";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { useAppSelector } from "../../../app/hooks";

type initialProps = {
  is_right?: boolean;
  image: any;
  info: string;
  title: string;
  onclick?: any;
  btnTxt?: string;
  btnTxt1?: string;
  maxImgWidth?: string;
  maxImgHeight?: string;
  btnWidth?: string;
  btn1Width?: string;
  subInfo?: string;
  onClick2?: any;
  alt?: string;
  className?: string;
  isSecondSecondary?: boolean;
};

export const SecondaryInfoCard = (props: initialProps) => {
  const { subDomainData } = useAppSelector((state: any) => state?.homepage);
  return (
    <Grid
      container
      sx={{
        px: { xs: 4, md: 8 },
        // my: { xs: 0.1, md: 10 },
        // py: { sm: 4 },
        display: "flex",
        flexDirection: {
          xs: "row",
          md: props.is_right ? "row-reverse" : "row",
        },
        // background: "var(--light_primary_color) !important",
      }}
      className={props.className ? props.className : ""}
      gap={{ xs: 3, md: 3, lg: 0 }}
      data-testid="secinfo_parent"
    >
      <Grid
        item
        container
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid
          item
          container
          xs={12}
          lg={11}
          sx={{
            px: { xs: 0, sm: "35px" },
            // py: { xs: 1, md: 2 },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            flexDirection: "column",
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Box
            className={styles.infocard_detail_title}
            sx={{ textAlign: { xs: "center", md: "left" } }}
            data-testid="secinfo_heading"
          >
            {/* {props.title} */}
            {subDomainData?.data?.networkMainTag
              ? subDomainData?.data?.networkMainTag
              : BrandNameUpdate(props?.title)}
          </Box>
          <Box
            sx={{ textAlign: { xs: "center", md: "start" } }}
            className={styles.infocard_detail_data}
            data-testid="secinfo_sub_heading"
          >
            {subDomainData?.data?.networkSubTag
              ? subDomainData?.data?.networkSubTag
              : BrandNameUpdate(props?.info)}
          </Box>
          {props.subInfo && (
            <Box
              className={styles.infocard_detail_subdata}
              data-testid="secinfo_sub_ifo"
            >
              {props.subInfo}
              {subDomainData?.data?.networkSubTag
                ? subDomainData?.data?.networkSubTag
                : BrandNameUpdate(props.subInfo)}
            </Box>
          )}

          <Box>
            <Box>
              {props.btnTxt && (
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onclick}
                  sx={{
                    width: "250px",
                    height: "45px !important",
                    color: "#ffffff",
                    fontSize: "14px !important",
                    fontFamily: "var(--font_family_Semibold) !important",
                  }}
                  data-testid="secinfo_button_1"
                >
                  {props.btnTxt}
                </ButtonCust>
              )}
            </Box>
            <Box>
              {props.btnTxt1 && (
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onClick2}
                  sx={{
                    width: { xs: "250px !important" },
                    height: "45px !important",
                    color: "#ffffff",
                    fontSize: "14px !important",
                    fontFamily: "var(--font_family_Semibold) !important",
                  }}
                  data-testid="secinfo_button_2"
                >
                  {props.btnTxt1}
                </ButtonCust>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: { xs: "none", sm: "flex" },
          justifyContent: "center",
          width: "100%",
        }}
        data-testid="secinfo_banner_parent_img"
      >
        {/* <img
          src={
            subDomainData?.data?.bestNetworkImageUrl
              ? subDomainData?.data?.bestNetworkImageUrl
              : props.image
          }
          style={{
            maxHeight: props.maxImgHeight ? props.maxImgHeight : "",
            maxWidth: props.maxImgWidth ? props.maxImgWidth : "",
          }}
          className={styles.img_container}
          alt={props.alt ? BrandNameUpdate(props.alt) : ""}
          data-testid="secinfo_banner_img"
          width={"530px"}
          height={"352px"}
        /> */}
        <LazyLoadImageCust
          src={
            subDomainData?.data?.bestNetworkImageUrl
              ? subDomainData?.data?.bestNetworkImageUrl
              : props.image
          }
          alt={props.alt ? BrandNameUpdate(props.alt) : ""}
          data-testid="secinfo_banner_img"
          width={"530px"}
          height={"352px"}
          style={{
            borderRadius: "10px",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "flex", sm: "none" },
          justifyContent: "center",
          pt: { xs: "15px", md: "unset" },
        }}
        data-testid="byod_img_2"
      >
        <LazyLoadImageCust
          src={
            subDomainData?.data?.bestNetworkImageUrl
              ? subDomainData?.data?.bestNetworkImageUrl
              : props.image
          }
          alt={props.alt ? BrandNameUpdate(props.alt) : ""}
          data-testid="secinfo_banner_img"
          style={{
            borderRadius: "10px",
            width: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};
