import styles from "./S1_Services.module.scss";
import { Box, Grid } from "@mui/material";
import { Divider, MobileStoreButton } from "../widgets";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import EnvConfig from "../../config/env.config.json";
type Props = {
  content: any;
};

const ServiceComponent = (props: Props) => {
  const { content } = props;
  const {
    bm_desc1,
    is_desc1,
    is_desc2,
    is_desc3,
    sub_head1,
    sub_head2,
    bm_desc2,
    androidMobileStore,
    iosMobileStore,
    ios,
    android,
  } = content;

  const {
    service_content_main,
    service_main,
    service_header_text,
    service_header,
    is_paragraph,
    mobile_button_store_main,
    mobile_button_ios,
    no_bullet
  } = styles;

  return (
    <Grid
      container
      px={3}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      m="30px 0px"
    >
      <Grid
        item
        sm={6}
        xs={12}
        className={service_content_main}
        sx={{ mb: { xs: "20px", sm: 0 } }}
        p="30px 3%"
      >
        <Box className={service_header} textAlign={{ xs: "center" }}>
          {sub_head1}
          <Box sx={{ pt: { lg: 2, sm: 3, xs: 3 } }}>
            <Divider />
          </Box>
        </Box>
        <ul>
          <li>{is_desc1}</li>
          <li>{is_desc2}</li>
          <li className={no_bullet}>{is_desc3}</li>
        </ul>
        <Grid
          container
          item
          display="flex"
          justifyContent={"center"}
          paddingTop={2}
          className={mobile_button_store_main}
        >
          {androidMobileStore && (
            <Grid item>
              <MobileStoreButton
                width={100}
                height={35}
                store={androidMobileStore.storeName}
                url={EnvConfig.ANDROID_APP_URL}
                linkprops={{ title: androidMobileStore.title }}
                imagelink={android?.data?.attributes?.url}
              />
            </Grid>
          )}
          {iosMobileStore && (
            <Grid
              item
              paddingLeft={{ xs: 4, sm: 2, md: 2, lg: 3 }}
              className={mobile_button_ios}
            >
              <MobileStoreButton
                width={100}
                height={35}
                store={iosMobileStore.storeName}
                url={EnvConfig.IOS_APP_URL}
                linkprops={{ title: iosMobileStore.title }}
                imagelink={ios?.data?.attributes?.url}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sm={5.8} xs={12} className={service_main} p={5}>
        <Box
          className={service_header_text}
          pb={0.6}
          textAlign={{ xs: "center" }}
        >
          {sub_head2}
          <Box sx={{ pt: { lg: 2, sm: 3, xs: 3 } }}>
            <Divider />
          </Box>
        </Box>
        <Box className={is_paragraph} textAlign="center">
          {bm_desc1}
        </Box>
        <Box className={is_paragraph} textAlign="center">
          {BrandNameUpdate(bm_desc2)}
        </Box>
      </Grid>
    </Grid>
  );
};
export default ServiceComponent;
