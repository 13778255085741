import { Box, CircularProgress, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import Config from "../../../../config/env.config.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentReviewCard from "../../IRILDComponents/PaymentReviewCard/PaymentReviewCard";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getAvailableOfferingsDataBoost,
  irDataAddOnPurchase,
  resetAddOnPurchase,
} from "../../../../features/services/servicesSlice";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { getPlanTaxCharges } from "../../../../features/checkout/checkoutSlice";
import PaymentStatus from "../../IRILDComponents/PaymentStatus/PaymentStatus";
import { getCC } from "../../../../features/account/accountSlice";

const IRDataBoost = (props: any) => {
  const dispatch = useAppDispatch();
  const {
    purchaseAddOnError,
    availableOfferings,
    purchaseAddOnSuccess,
    purchaseAddOnLoading,
    availableOfferingsLoading,
  } = useAppSelector((state) => state.services);
  const { planTaxCharges, isLoadedPlanTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const { configKeys } = useAppSelector((state: any) => state?.activation);

  const availableOfferingsData = availableOfferings?.data?.addOnServices?.data;

  const {
    data_boost_details,
    dec_inc_icon,
    visa_card,
    change_card,
    addOn_success,
    addOn_failed,
    purchase_addOn,
    mockCard,
  } = props.content;

  const data = props.dataBoostLine;
  const [dataCap, setDataCap] = useState<any>(null);
  const [dataBoostPayment, setDataBoostPayment] = useState<Boolean>(false);
  const [totalCost, setTotalCost] = useState<number | null>(null);
  const [purchasePackStatus, setPurchasePackStatus] = useState<boolean>(false);
  const {
    data_boost_container,
    data_proceed_btn,
    user_name_db,
    data_boost_wrapper,
  } = props.styles;

  const customerDetailJSON = localStorage.getItem("customerDetail");
  const customerDetail = customerDetailJSON
    ? JSON.parse(customerDetailJSON)
    : null;

  const billingData = customerDetail.addresses.find(
    (item: any) => item.type === "billing"
  );

  const { getCCDetails } = useAppSelector((state: any) => state.account);

  useEffect(() => {
    if (!purchaseAddOnLoading && availableOfferingsData) {
      setTotalCost(availableOfferingsData?.cost);
      setDataCap(availableOfferingsData?.servicePulse);
    }
  }, [purchaseAddOnLoading, availableOfferingsData]);

  const userName = props.getUserName(props?.packIndex);

  const confirmPurchaseHandler = () => {
    setPurchasePackStatus(true);
    const payload = {
      addOnServices: {
        data: {
          serviceAmount: dataCap,
          serviceAmountUnit: availableOfferingsData?.servicePulseUnit,
        },
      },
    };
    dispatch(irDataAddOnPurchase({ payload, packId: data.id }));
  };

  const dataBoostPaymentHandler = () => {
    const address = {
      name: `${customerDetail.firstName} ${customerDetail.lastName}`,
      address1: billingData?.address1,
      city: billingData?.city,
      country: billingData?.country,
      state: billingData?.state,
      type: "billing",
      zip: billingData?.zip,
    };
    setDataBoostPayment(true);
    dispatch(
      getPlanTaxCharges({
        address,
        amount: totalCost,
        additionalLines: customerDetail?.totalActiveLines,
      })
    );
    dispatch(getCC({ customerId: customerDetail && customerDetail.id }));
  };

  useEffect(() => {
    dispatch(getAvailableOfferingsDataBoost({ payload: data.offeringId }));
  }, [data]);

  useEffect(() => {
    setDataCap(Number(availableOfferingsData?.servicePulse));
  }, []);

  const dataBoostIncDecHandler = (type: string) => {
    if (type === "increase") {
      setTotalCost((prev: any) => prev + availableOfferingsData.cost);
      setDataCap((prev: number) => prev + availableOfferingsData?.servicePulse);
    } else if (
      type === "decrease" &&
      dataCap > availableOfferingsData?.servicePulse
    ) {
      setDataCap((prev: number) => prev - availableOfferingsData?.servicePulse);
      setTotalCost((prev: any) => prev - availableOfferingsData?.cost);
    }
  };

  return (
    <>
      <Box
        component="span"
        style={{
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          position: "absolute",
          left: "103px",
          cursor: "pointer",
          marginTop: "10px",
        }}
        onClick={() => props.setIsDataBoostJourney(false)}
        data-testid={`IR_data_boost_${Number(props?.packIndex) + 1 || 1}`}
      >
        <ArrowBackIcon />
        {data_boost_details?.back}
      </Box>

      <Box component="div" className={data_boost_wrapper}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "25px",
          }}
        >
          <Typography
            component="div"
            sx={{
              fontSize: "30px",
              color: "var(--tab_color_2)",
              fontWeight: "var(--font_weight_5)",
              fontFamily: "var(--font_family_Medium)",
            }}
            data-testid={`IR_data_boost_title_${Number(props?.packIndex) + 1 || 1}`}
          >
            {data_boost_details.title}
          </Typography>
          <Typography component="div" className={user_name_db} data-testid={`IR_data_boost_user_name_${Number(props?.packIndex) + 1 || 1}`}>
            {userName}
          </Typography>
        </Box>

        {!availableOfferingsLoading ? (
          <>
            {" "}
            {!dataBoostPayment ? (
              <Box className={data_boost_container}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "40px",
                  }}
                >
                  <Box
                    onClick={() =>
                      dataCap !==
                        Number(availableOfferingsData?.servicePulse) &&
                      dataBoostIncDecHandler("decrease")
                    }
                    component="img"
                    alt={
                      dec_inc_icon?.data[0].attributes?.alternativeText || ""
                    }
                    data-testid={`IR_data_boost_img_dec${Number(props?.packIndex) + 1 || 1}`}
                    height="37px"
                    width="auto"
                    src={
                      dataCap == Number(availableOfferingsData?.servicePulse)
                        ? dec_inc_icon?.data[0].attributes?.url
                        : dec_inc_icon?.data[1].attributes?.url || ""
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "24px",
                    }}
                    data-testid={`IR_data_boost_data_cap_${Number(props?.packIndex) + 1 || 1}`}
                  >
                    {dataCap}
                    {availableOfferingsData?.servicePulseUnit}
                  </Typography>
                  <Box
                    component="img"
                    alt={
                      dec_inc_icon?.data[2].attributes?.alternativeText || ""
                    }
                    height="37px"
                    width="auto"
                    src={dec_inc_icon?.data[2].attributes?.url || ""}
                    sx={{
                      cursor: "pointer",
                    }}
                    data-testid={`IR_data_boost_img_inc_${Number(props?.packIndex) + 1 || 1}`}
                    onClick={() => dataBoostIncDecHandler("increase")}
                  />
                </Box>
                <Typography
                  component="div"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "var(--font_weight_0)",
                    color: "var(--tab_colo_2)",
                    mt: "50px",
                  }}
                >
                  <Box component="span">{data_boost_details?.data_cost}</Box>{" "}
                  <Box
                    component="span"
                    sx={{
                      color: "var(--primary_color)",
                    }}
                    data-testid={`IR_data_boost_total_cost_${Number(props?.packIndex) + 1 || 1}`}
                  >
                    {GetCurrencyFixedToTwo(totalCost)}
                  </Box>{" "}
                  <Box component="span" data-testid={`IR_data_boost_cost_{Number(props?.packIndex) + 1 || 1}`}>
                    {`(${GetCurrencyFixedToTwo(availableOfferingsData?.cost)}/${
                      availableOfferingsData?.servicePulse
                    }${availableOfferingsData?.servicePulseUnit})`}
                  </Box>
                </Typography>
                <ButtonCust
                  data-testid={`IR_data_boost_btn_${Number(props?.packIndex) + 1 || 1}`}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  className={data_proceed_btn}
                  onClick={() => dataBoostPaymentHandler()}
                >
                  {data_boost_details.btn_txt}
                </ButtonCust>
                <Typography
                  component="div"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "var(--font_weight_0)",
                    textAlign: "left",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: "16px",
                      color: "var(--primary_color)",
                      fontWeight: "var(--font_weight_1)",
                    }}
                    data-testid={`IR_data_boost_note1_${Number(props?.packIndex) + 1 || 1}`}
                  >
                    {data_boost_details?.note1}
                  </Box>{" "}
                  <Box component="span" data-testid={`IR_data_boost_note2_${Number(props?.packIndex) + 1 || 1}`}>{data_boost_details?.note2}</Box>
                </Typography>
              </Box>
            ) : (
              <Box>
                {!purchasePackStatus ? (
                  <PaymentReviewCard
                    firstRowTitle={purchase_addOn?.DataBoost}
                    firstRowValue={totalCost}
                    secondRowTitle={purchase_addOn?.TaxesFees}
                    secondRowValue={planTaxCharges}
                    thirdRowTitle={purchase_addOn?.TotalDue}
                    mockCard={purchase_addOn.mockCard}
                    visa_card={visa_card?.data.attributes}
                    confirmBtn={purchase_addOn?.ConfirmPurchase}
                    carbon_edit={change_card?.data?.attributes}
                    totalCost={totalCost}
                    changeCardText={purchase_addOn?.ChangeCard}
                    onClick={confirmPurchaseHandler}
                    purchase_addOn={purchase_addOn}
                    loading={isLoadedPlanTaxCharges}
                    dataCap={dataCap}
                  />
                ) : (
                  <>
                    {purchaseAddOnError && (
                      <PaymentStatus
                        icon={addOn_failed?.addOn_status}
                        title={addOn_failed?.title}
                        description={addOn_failed?.desc}
                        button={addOn_failed?.button}
                        onClick={() => confirmPurchaseHandler()}
                        variantType={Config.QUATERNARY_BUTTON_TYPE}
                      />
                    )}
                    {purchaseAddOnLoading && (
                      <Box textAlign={"center"} data-testid={`IR_data_boost_progress_${Number(props?.packIndex) + 1 || 1}`}>
                        <CircularProgress
                          sx={{ color: "var(--primary_color)" }}
                        />
                      </Box>
                    )}
                    {purchaseAddOnSuccess && (
                      <PaymentStatus
                        icon={addOn_success?.addOn_status}
                        title={addOn_success?.title}
                        description={addOn_success?.desc}
                        button={addOn_success?.button}
                        onClick={() => {
                          props.setIsDataBoostJourney(false);
                          dispatch(resetAddOnPurchase());
                        }}
                        variantType={Config.QUATERNARY_BUTTON_TYPE}
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <CircularProgress
              sx={{
                color: "var(--primary_brand_color_dark)",
              }}
              data-testid={`IR_data_boost_progress_${Number(props?.packIndex) + 1 || 1}`}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default IRDataBoost;
