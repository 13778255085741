const internalBanner =
  "?populate=banner.banner_web,banner.banner_ipad,banner.banner_mweb";
const StrapiApiEndPoints: any = {
  networkCoverage: "-coverage?populate=*",
  header:
    "-header?&populate=*&populate=brand_logo&populate=public_primary_items.subMenu&populate=public_secondary_items.subMenu&populate=nav_link1&populate=mobile_logo&populate=cart_logo&populate=web_logo&populate=tab_logo&populate=login_item.subMenu&populate=pl_primary_items.subMenu&populate=pl_secondary_items.subMenu&populate=c_login_item.subMenu&populate=w_logout_item.subMenu&populate=activation_secondary_items&populate=activation_primary_items.subMenu&populate=activation_logout_item.subMenu&populate=s_u_a_s_i&populate=s_u_a_p_i.subMenu&populate=s_u_a_l_i.subMenu&populate=s_u_t_l_i.subMenu&populate=s_u_t_s_i.subMenu&populate=s_u_t_p_i.subMenu&populate=profile_logo",
  headerCustomer:
    "-header-cust?&populate=*&populate=pra_primary_items.subMenu&populate=pra_secondary_items.subMenu&populate=poa_primary_items.subMenu&populate=poa_secondary_items.subMenu&populate=pra_login_item.subMenu&populate=poa_login_item.subMenu&populate=af_pf_primary_items.subMenu&populate=af_pf_secondary_items.subMenu&populate=af_pf_login_item.subMenu&populate=s_u_p_h_i.subMenu&populate=s_u_s_h_i.subMenu&populate=s_u_login_i.subMenu&populate=s_u_f_p_h_i.subMenu&populate=s_u_f_s_h_i.subMenu&populate=s_u_f_login_i.subMenu&populate=to_primary_items.subMenu&populate=to_secondary_items.subMenu&populate=poad_primary_items.subMenu&populate=to_login_item.subMenu&populate=poad_login_item.subMenu",
  t1_footer:
    "-footer?populate=footerList1&populate=footerList2&populate=footerList3&populate=footerList4&populate=logo_desktop&populate=logo_mobile&populate=logo_tab&populate=assuredCard&populate=socialMediaIons.icon&populate=mobileStore.img&populate=iosMobileStore.img&populate=androidMobileStore.img&populate=iosMobileStore.img,&populate=androidMobileStore.img",
  t2_footer: "-footer?populate=*",
  t4_footer:
    "-footer?populate=*&populate=socialMediaIons.icon&populate=footerListDetails&populate=logo_tab,&populate=logo_mobile,&populate=logo_desktop,&populate=logo,&populate=iosMobileStore.img,&populate=androidMobileStore.img",
  t9_footer:
    "-footer?populate=footerList1&populate=footerList2&populate=footerList3&populate=footerList4&populate=logo_desktop&populate=logo_mobile&populate=logo_tab&populate=assuredCard&populate=socialMediaIons.icon&populate=mobileStore.img&populate=iosMobileStore.img&populate=androidMobileStore.img",
  hmBestfit: "-hm-bestfit?populate[0]=*",
  leadGeneration: "-lead-generation?populate=*",
  planCard:
    "-plancard?populate=*&populate[0]=lines_tab_component.image&populate[1]=shareable_img&populate[2]=add_line_box&populate[3]=icon_offers.image&populate[5]=non_shareable_img&populate[6]=my_acc_desc&populate[7]=more_icon_offers.image&populate[8]=s_plan_img&populate[9]=u_plan_img&populate[10]=hide_feature_item,order_pending,order_pending.info_icon,planCards,close_icon,mbl_img,mb_data,hotspot_img,video_img,offers.logo,tick_icon",
  planFeatures: "-plan-feature?populate[0]=*&populate[1]=plan_feature.image",
  // byod: "-byod?populate[0]=*&populate[1]=image",
  byod: "-byod?populate=*",
  FAQ: "-faq?populate=*",
  homeSupport: "-home-support?populate=*",
  billCalculatorBanner: "-billcalculator-banner?populate=*",
  appSection:
    "-app-section?populate[0]=image_web,image_tab,image_mob,image&populate[1]=steps.image",
  bestFitFeatures:
    "-bestfit-feature?populate[0]=*&populate[1]=bestfit_features.image",
  bestFitSavings: "-bestfit-bsaving?populate[0]=*&populate[1]=image",
  bestFitPotentialSavings:
    "-bestfit-psaving?populate[0]=*&populate[1]=image_1,image_2",
  tpBestFit: "-tp-bestfit?populate[0]=*",
  tpHome: "-tp-home?populate[0]=*",
  explorePlans: "-explore-plan?populate[0]=*",
  brandFeature:
    "-brand-feature?populate=featureDetails.featureImg&populate=featureDetails.featureHoverImg&populate=featureDetails.tabMobileIcon",
  missionList: "-missions-list?populate[0]=*&populate[1]=image_content.image",
  brandList: "-brands-list?populate=*",
  reviews: "-review?populate[0]=*",
  ourPartners: "-ptr?populate[0]=*&populate[1]=ptr_list.logo",
  helpSupport:
    "-help-support?populate=help_center&populate=help_center.hc_button&populate=support&populate=mail_icon&populate=logo&populate=phone_icon&populate=chat_icon&populate=address_details&populate=hs_helmet&populate[1]=support.img,call_center_pic",
  helpIframe: "-help-iframe",
  purchaseFlow: "purchase-journey?populate=*",
  privacy: "-privacy?populate[0]=*&populate[1]=privacy_content",
  ceoLetter:
    "-ceo-letter?populate[0]=*&populate[1]=image_desktop,image_ipad,image_mobile",
  teamIntro: "-team-intro?populate=*",
  teamFeedBack: "-team-feed-back?populate=feedback_content.profile_icon",
  compatibilityCheck: "-imei-compatibility?populate=*",
  howItWorks: "-how-it-work?populate=*",
  terms:
    "-terms-list?populate[0]=*&populate[1]=Terms_content&populate[2]=terms.terms_block&populate[3]=terms.terms_cont",
  services: "-service?populate=*",
  postActRoaming: "-pa-roaming?populate=*",
  postActCalling:
    "-pa-calling?populate=*,view_Rates.call_filled,view_Rates.search_icon,view_Rates.icons,back_icon,right_arrow,terms_icon,dec_inc_icon,visa_card,change_card,success_icon,failed_icon",
  postActServices:
    "-service?&populate=*&populate=r_type1.roaming_steps&populate=r_type2.roaming_steps",
  primaryValues: "-pr-va?populate=*",
  tpGiveBack: "-tp-giveback?populate=*",
  internationalServices:
    "-international-service?&populate=*&populate=alphabets&populate=r_type1.roaming_steps&populate=r_type2.roaming_steps,search_icon,pck_info.img,bck_btn",
  detailCoverage: "-details-coverage?populate=*",
  meetTheRecipients: "-meet-recipient?populate=v_card.v_img",
  login:
    "-login?populate=*,v_off_img,v_on_img,heading_logo,login_logos,forgot_pwd_logo,heading_fp_logo",
  signup:
    "-sign-up?populate=*,v_off_img,v_on_img,heading_logo,signup_logos,bg_web,bg_ipad,bg_ipad_pro,bg_mobile",
  signupLogin: "-signup-login?populate=*",
  compareGraph: "-bill-graph?populate[0]=*&populate[1]=months",
  negResultTop:
    "-bill-n-res-tp?populate[0]=image&populate[1]=bill_res_comp.image&populate[2]=bill_res_comp.video_img",
  discoverContent: "-discover?populate=*",
  donations: "-donation?populate=*",
  stories:
    "-story?populate=pre_i,nxt_i&populate=stry.img&populate=stry.mdl&populate=stry.mdl.img,stry.mdl.shr_i,stry.mdl.fb_i,stry.mdl.tw_i,stry.mdl.ma_i",
  posResultBottom:
    "-bill-p-res-bt?populate[0]=plan_image&populate[1]=plan_image2&populate[2]=bill_res_comp.image&populate[3]=bill_res_comp.video_img",
  netResultsBottom:
    "-bill-n-res-bt?populate[0]=plan_image&populate[1]=plan_image2",
  comparePlans: "cmp-pln?populate=*",
  help: "-help?populate=help_center&populate=help_center.hc_button&populate=support&populate=mail_icon&populate=phone_icon&populate=chat_icon",
  profileDetails:
    "-profile?populate[0]=profile_img&populate[1]=edit_icon&populate[2]=prf_err&populate[3]=notifications",
  settingsHsd:
    "-settings-hsd?&populate=*&populate=success_modal.icon&populate=failure_modal.icon&populate=fail_match_found.icon&populate=check_form&populate=acc_num_popover",
  reccuringTopUpFail: "rec-topup-fail?populate=*",
  deviceConfirmCheck: "-device-confirm-check?populate=*",
  paymentLanding: "-payment-landing?populate=*",
  //--------------------------------activation-------------------------------------
  activationFlow: "-activation?populate=*",
  activationImages: "-acimg?populate=*",
  postActivationModals:
    "act-st?populate=newNumSuc.title,newNumSuc.video_img,portSuccess.video_img,zipCode,verizonPin,pinNum,accNum,acivationFailCC.m_img,portingFailCC.m_img,portReject1.m_img,portReject2,smsReceived,smsNotReceived",
  activationIntro:
    "-actintr?populate=*&populate=intro_pnts.icon,act_img,tick_icon,arrowRt_icon,infoData,dataObject,esim_points,each_icon,flight_icon,each_icon1,intro_pnts1.icon,esim_points1.icon,imei_look_up.iemiContent.img,iemiConten",
  activationStep2:
    "-act-stp2?populate[0]=*&populate[1]=extra_info.points,sup_md.chat_img,sup_md.call_img,sup_md.mail_img,num_pref,zp_md,c_num.chat_img,act_img,numberpre_icon,carrier_info_icon",
  activationStep3:
    "-actst3?populate=*&populate=pay_now.promo_img,pay_now.visa,pay_now.american_ex,pay_now.discover,pay_now.jcb,pay_now.master_card,sim_check,pay_fail_md.pay_fail_img,act_img,pay_in_prg,pay_success,pay_fail,pay_success_icon, pay_fail_icon,pay_prg_m,pay_success_m,pay_fail_m,esim_pay_dlg.fail_icon,sim_check.sim_h_ico,sim_check.sim_instr,payment_modal_icon,icc_id_icon,info_icon",
  activationStep1:
    "-actstp1?populate=*,imei_form,imei_fail_md,imei_view.dev_img,imei_view.dev_id_img,Iemi_con.iemiContent.img,tm_chgs.visa,tm_chgs.american_exp,tm_chgs.master,tm_chgs.discover,act_img,add_address,mobile_icon_main,mobile_icon_imei,mobile_icon_device,mobile_icon_simtype,imei_comp_headIcon,swap_psim_icon,swap_shipping_icon,swap_shipping_charge_icon,suc_tick_icon,danger_info,imei_modal_mod.icon,sim_info_icon1",
  activationStep4: "-actst4?populate=*",
  activationSteps:
    "-actstep?populate=*,ios_img1,ios_img2,and_img1,and_img2,eSimIOSStep3.steps,eSimAndroidStep3.steps,send_modal,unsure_stps.ios,unsure_stps.android,unsure_stps.ios_images,unsure_stps.ios_images_mweb,unsure_stps.android_images,unsure_stps.android_images_mweb",
  //-------------------------------------------------------------------------------

  trackOrder:
    "-track-order?populate=*,cancel_img,track_img,image,to_esim.img,received_status_icon,Inprogress_status_icon,shipped_status_icon,delivered_status_icon,additional_line_stp1_icon,additional_line_stp2_icon,additional_line_stp3_icon,line_text",
  billingDetails: "-billing?populate=*",
  preSimBanner: "-presim-banner?populate=*",
  postSimBanner: "-postsim-banner?populate=*",
  phoneUnlockModal: `ph-unlock-modal`,
  addressInfo: "-address-info?populate=*",
  reviewPlan: "-review-plan?populate=*",
  mabf: "-mabf?populate=*",
  giveBack: "-my-acc-gp?populate=*",
  bwm: "-gmmwr?populate=*&populate[1]=comp.img",
  dataUsage: "-data-usage?populate=*&populate[1]=Usage_table.amount3",
  PADataUsage: "-data-usg?populate=*",
  autoPayment: "-auto-pay?populate=*",
  paymentHistory: "-payment-history?populate=*",
  manageCard: "-manage-card?populate=*",
  currentBill: "-current-bill?populate=*",
  currentPlan: "-current-plan?populate=*",
  BillingBestFit: "-best-fit?populate=*",
  manageLines: "-mng-line?populate=*",
  outOfData: "-out-of-data?populate=*",
  invoiceDetails: "-inv-detail?populate=*",
  totalCreditsCard: "-tc-card",
  referralCreditsCards: "-referral-credit",
  pendingCreditCard: "-pc-card?populate=*",
  dataDetails: "-data-detail?populate=*",
  cpbdp: "-cpbdp?populate=*",
  cppd: "cppd?populate=*",
  accountPrimaryCard: "-ac-primary-card?populate=*",
  esimCoverageModal: "-esim-coverage-modal?populate=*",
  esimIMEIModal:
    "-esim-imei-check?populate=*,close_img,tick_img,info_img,network_settings.icon,res,popover_img1,popover_img2,retry_img,esim_fail",
  simPreference: "-esim-review-plan?populate=*",
  accountLandingPage: "-acc-land?&populate=*",
  secondaryUserLogin: "-sl",
  settingsCards:
    "-settings-card?populate=*,card,lan_mdl,pswrd_mdl,upd_adrs.adrs_flds,dta_saver_mdl,enbl_mdl.adrs_flds,dis_wifi,dis_dta_sav,wifi_upd_scs",
  shipAdd: "-ship-addline?populate=*",
  reviewAdd: "-review-add?populate=*",
  estBillAdd: "-est-bill-add?populate=*",
  addLineComp: "-add-line-comp?populate=*",
  seoData: "-sdata?populate=*",
  notifications: "-notification",
  selectCardDialog: "sec-card-dlg?populate=*",
  checkoutStepper: "checkout-step?populate=*",
  wallet:
    "-wallet?populate=*,review_plan.visa_icon,review_plan.master_crd,review_plan.ae_card_img,review_plan.jcb_crd_img,review_plan.discover,success_modal.tick_icon,enbl_int_rmng,dsb_int_rmng,wlt_img,amt_ldr,amt_ldr_stc,mng_aut_pay,Auto_pay_cnst,cst_cr_link,cst_cr_txt,no_cards,no_cards_dsc",
  autoPayC: "auto-pay-ch?populate=*",
  loaderPage: "loader-page?populate=*",
  globalValues: "global-value?populate=*, error_comp_icon",
  simInHand: `sim-in-hand-ch?populate=*,multi_success_icon, multi_error_icon`,
  broadbandFacts: `broadband-facts-ch?populate=*, nl_mb_data, nl_hotspot_img, nl_mbl_img,dwn_icon`,
  networkManagement: `network-management-ch?populate=*`,
  landingPagePlanSec: `landing-plan-sec?populate=*,lines_tab.image,features_1.image,features_2.image,speed_icon,plan_details.image`,
  ulPromoTerms: `-ul-promo-term?populate=*`,
  dataUsageFailure: `data-usg-failure?populate=*`,
  customerService: "-customer-service?populate=*",
  accountPlanDetails: `-account-plan-detail?populate=*,pay_now.promo_img,pay_now.visa,pay_now.american_ex,pay_now.discover,pay_now.jcb,pay_now.master_card,change_card_img,up_arrow,down_arrow,left_arrow,right_arrow,plan_schd_img,plan_now_img,get_current_usage_img,info,cross_img,change_later_info_img,signup,tooltip_icon,user_image,tick_icon,title_icon`,
  addDataComp:
    "-add-data-cmp?populate=*,selected_icon,purchase_details.visa, purchase_details.american_ex,purchase_details.master_card,purchase_details.discover,cart_icon,topup_fail_icon",
  purchaseHistory: "-purchase-history?populate=*",
  lineCard:
    "-linecard?populate=*,populate=linecardData.loading_img,linecardData.tooltip_icon,linecardData.delete_icon,linecardData.info_icon, linecardData.invalid_imei,linecardData.loading_img,device_store_data.img_svg",
  addLine_ChangePlan: "-add-line-change-plan?populate=*",
  fccAccountLock: "-fcc-account?populate=*",
  apnSetting: `-apn-setting?populate=*`,
  //-------------------------------Internal Banners-------------------------------------//
  accountActivity:
    "-account-activity?populate=*&populate=addline_suc.pay_fail_img,simkit_act.visa,simkit_act.discover,simkit_act.american_ex,simkit_act.jcb,simkit_act.master_card,simkit_act.sim_kit_logo,timer_icon,refresh_icon2,refresh_icon,add_icon,act_fail_icon,down_icon,help_icon,info,info2,loading_img,sim_icon,addIcon,signup,suspend_icon,info_icon,act_tip_icon,reduced_speed_icon,i_icon,nl_sucess_tick,nl_mbl_img,nl_hotspot_img,nl_mb_data,nl_bf_icon,bf_icon_c,info_icon1",
  accountLineSummary: "-addline-summary?populate=*&populate=title_logo",
  bestFitBanner: `-best-fit-banner${internalBanner}`,
  giveBackBanner: `-giveback-banner${internalBanner}`,
  compatibilityCheckBanner: `-imei-compatibility-banner${internalBanner}`,
  ISBanner: `-is-banner${internalBanner}`,
  ISBanner2: `-isbnr2${internalBanner}`,
  billCalBanner: `-billcal-banner${internalBanner}`,
  discoverBanner: `-discover-banner${internalBanner}`,
  servicesBanner: `-service-banner${internalBanner}`,
  billingBanner: `-billing-banner${internalBanner}`,
  accountBanner: `-account-banner${internalBanner}`,
  helpBanner: `-help-ib${internalBanner}`,
  profileBanner: `-prf-banner${internalBanner}`,
  activationBanner: `act-bn${internalBanner}`,
  settingsBanner: `-settings-banner${internalBanner}`,
  helpIframeBanner: `-help-banner${internalBanner}`,
  contactUsBanner: `-contact-banner${internalBanner}`,
  myAccBanner: `-myacc-banner${internalBanner}`,
  postActHomeBanner: `-post-act-home-banner${internalBanner}`,
  safeConnectionBanner: `-safe-connection-banner${internalBanner}`,
  //-------------------------------Home Banners----------------------------------------//
  homePageBanner:
    "-home-banner?populate[0]=*&populate=list_items.image&populate=banner_web&populate=banner_ipad&populate=banner_ipad_pro&populate=banner_mobile",
  plansPageBanner: "-plans-banner?populate=*",
  paHomeBanner: "-pa-h-b?populate=*",
  ulPromoBanner: `-ul-promo-bnr?populate=*`,
  irPurchaseHistory: `-ir-purchase-history?populate=*,addOn_success.addOn_status,addOn_failed.addOn_status,dec_inc_icon,visa_card,change_card,details,ir_pack_cancelled.addOn_status,ir_cancel,no_order_img,active,renew_disable,renew_enable,select_img,up_arrow_img,down_arrow_img,reschedule_success.addOn_status,act_loader`,
  walletHistory: `-wallet-history?populate=*`,
  safecon: "-safe-connection?populate=*",
  zipcode_validation: "zipcode-validation-modal?populate=*",
};

export default StrapiApiEndPoints;
