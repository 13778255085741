import { ByodProps } from "./Byod";
import { Box, Grid, Popover, Stack, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import EnvConfig from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { LazyLoadImageCust, SimpleModal, TextFieldCust } from "../../widgets";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { useState } from "react";
import { checkCompatibility } from "../../../features/homepage/homepageSlice";
import {
  PhoneNoPattern,
  digitsOnlyPattern,
} from "../../../utils/regexPatterns";
import { useNonInitialEffect } from "../../../utils/commonFunctions";
import { scroller } from "react-scroll";
import { Close } from "@mui/icons-material";
import ApnNote from "../../apnSettings/Apn_Note";
import { CustToolTip_T2 } from "../../widgets/TooltipCust_T2";
import Styles from "./S1_Byod.module.scss";

const ByodComponent = (props: ByodProps) => {
  const {
    tick_icon,
    title,
    desc,
    btn_1,
    img_web,
    img_mweb,
    imei_label,
    limit_imei,
    imei_err,
    heading,
    sub_heading,
    success_msg,
    btn_2,
    byod_fail_title,
    byod_fail_subtitle,
    byod_fail_btn,
    web_img_left,
    res,
    popover_img1,
    popover_img2,
  } = props?.byodContent || {};
  const dispatch = useAppDispatch();
  const {
    compatibility,
    errorCompatibility,
    compatibilityLoading,
    subDomainData,
  } = useAppSelector((state: any) => state.homepage);

  const [showCheckIMEIModal, setShowCheckIMEIModal] = useState(false);
  const [imeiNumber, setimeiNumber] = useState("");
  const [imeiErr, setImeiErr] = useState("");
  const [isCompatible, setisCompatible] = useState(false);
  const [errorInCompat, setErrorInCompat] = useState(false);
  const [haveTrouble, setHaveTrouble] = useState(false);
  const [target, setTarget] = useState(null);
  const [displayImeiModal, setDisplayImeiModal] = useState(true);
  const [osdata, setOsData] = useState<any>([]);

  useNonInitialEffect(() => {
    if (
      compatibility?.data?.status === "SUCCESS" &&
      compatibility?.data?.data
    ) {
      if (compatibility?.data?.data?.isValid) {
        setErrorInCompat(false);
        setisCompatible(true);
      } else {
        setErrorInCompat(true);
      }
    }
  }, [compatibility]);

  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.plan,
      category: category,
      buttonValue: value,
    });
  };

  const ImeiCheckHandler = async () => {
    if (
      imeiNumber &&
      imeiErr === "" &&
      imeiNumber.length === 15 &&
      /^\d+$/.test(imeiNumber)
    ) {
      dispatch(checkCompatibility({ imeiNumber: imeiNumber })).then((resp) => {
        setOsData(resp?.payload?.data?.data);
      });
    } else {
      if (imeiNumber === "") {
        setImeiErr(imei_err.err_3);
      } else if (imeiNumber !== "" && imeiNumber.length !== 15) {
        setImeiErr(imei_err.err_2);
      } else if (imeiNumber !== "" && !/^\d+$/.test(imeiNumber)) {
        setImeiErr(imei_err.err_1);
      }
    }
  };

  const onChangeHandler = (e: any) => {
    setimeiNumber(e.target.value);
    setImeiErr("");
  };

  const seePlansHandler = () => {
    closeModalHandler();
    scroller.scrollTo("bestCellPhonePlan", {
      duration: 1000,
      delay: 0,
      offset: -100,
      smooth: true,
    });
  };

  const closeModalHandler = () => {
    setimeiNumber("");
    setisCompatible(false);
    setErrorInCompat(false);
    setShowCheckIMEIModal(false);
    setDisplayImeiModal(true);
  };
  const closeImeiModal = () => {
    setDisplayImeiModal(false);
  };

  const tryAgainHandler = () => {
    setimeiNumber("");
    setErrorInCompat(false);
    setShowCheckIMEIModal(true);
  };

  const handleCheckPhone = () => {
    setisCompatible(false);
  };

  const haveTroubleHandler = async (e: any) => {
    setHaveTrouble(true);
    setTarget(e.target);
  };

  const closeTroubleHandler = () => {
    setTarget(null);
    setHaveTrouble(false);
  };

  return (
    props?.byodContent && (
      <>
        <Grid
          container
          display="flex"
          flexDirection={web_img_left ? "row-reverse" : "unset"}
          alignItems={"center"}
          sx={{ px: { xs: 4, md: 8 }, paddingTop: "32px"}}
          data-testid="byod_parent"
        >
          <Grid
            item
            md={6}
            sx={{
              m: "20px 0",
              px: { xs: 0, sm: "35px" },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                textAlign: { xs: "center", md: "left" },
                color: "var(--dark-charcoal-fade)",
                lineHeight: { xs: "30px", sm: "40px" },
                fontSize: { xs: "20px", md: "24px" },
                fontFamily: "var(--font_family_Bold)",
                mb: { xs: "18px", md: "18px" },
              }}
              data-testid="byod_heading"
            >
              {subDomainData?.data?.byodMainTag
                ? subDomainData?.data?.byodMainTag
                : BrandNameUpdate(title)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: { xs: "center", md: "left" },
                color: "var(--dark-charcoal-fade)",
                fontSize: { xs: "16px" },
                lineHeight: { xs: "28px" },
                fontFamily: "var(--font_family_Regular)",
                mb: "18px",
              }}
              data-testid="byod_sub_heading"
            >
              {subDomainData?.data?.byodSubTag
                ? subDomainData?.data?.byodSubTag
                : BrandNameUpdate(desc)}
            </Typography>
            <ButtonCust
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              onClick={() => {
                setShowCheckIMEIModal(true);
                GAAndFBEventsHandler(gaCategory.networkCompatible, btn_1);
              }}
              sx={{
                width: "250px",
                display: "block",
                margin: { xs: "auto", md: "0" },
                height: "45px !important",
                color: "#ffffff",
                fontSize: "14px !important",
                fontFamily: "var(--font_family_Semibold) !important",
              }}
              data-testid="byod_button"
            >
              {btn_1}
            </ButtonCust>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              width: "100%",
            }}
            data-testid="byod_image_1"
          >
            <LazyLoadImageCust
              src={
                subDomainData?.data?.byodImageUrl
                  ? subDomainData?.data?.byodImageUrl
                  : img_mweb?.data?.attributes?.url
              }
              alt={img_web?.data?.attributes?.alternativeText}
              width={"530px"}
              classNames = {Styles.byod_image}
              height={"352px"}
              style={{
                borderRadius: "10px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
              pt: { xs: "15px", md: "unset" },
            }}
            data-testid="byod_img_2"
          >
            <LazyLoadImageCust
              src={
                subDomainData?.data?.byodImageUrl
                  ? subDomainData?.data?.byodImageUrl
                  : img_web?.data?.attributes?.url
              }
              alt={img_mweb?.data?.attributes?.alternativeText}
              style={{
                borderRadius: "10px",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <SimpleModal
          isShowModal={showCheckIMEIModal}
          onCloseModal={() => closeModalHandler()}
          showClose={true}
          sx={{
            width: {
              xs: "95%",
              sm: "70%",
              md: "80%",
              lg: "45%",
              xl: "30%",
            },
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "4px",
            padding: { xs: "30px 15px !important", sm: "30px !important" },
          }}
          style={{ display: displayImeiModal ? "inline" : "none" }}
        >
          {!isCompatible && !errorInCompat ? (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                  fontSize: { xs: "22px", sm: "24px" },
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "700",
                  mb: "20px",
                  color: "var(--dark-charcoal-fade)",
                  textAlign: "center",
                }}
                data-testid="byod_dialog_heading"
              >
                {BrandNameUpdate(heading)}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Regular)",
                  color: "#313131",
                  textAlign: "center",
                  width: { xs: "100%", sm: "70%" },
                  marginBottom: "20px",
                }}
                data-testid="byod_dialog_sub_heading"
              >
                {BrandNameUpdate(sub_heading)}
              </Typography>
              <Box
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    ImeiCheckHandler();
                  }
                }}
                sx={{ width: "100%", maxWidth: "430px", position: "relative" }}
              >
                <TextFieldCust
                  value={imeiNumber}
                  type={window.screen.width <= 600 ? "number" : "text"}
                  label={imei_label}
                  onChange={(e: any) => {
                    if (
                      (PhoneNoPattern.test(e.target.value) &&
                        e.target.value.length <= 15) ||
                      e.target.value === ""
                    ) {
                      onChangeHandler(e);
                    }
                  }}
                  data-testid="byod_dialog_textfield"
                  error={
                    imeiErr !== "" ||
                    (imeiNumber !== "" &&
                      !digitsOnlyPattern.test(imeiNumber)) ||
                    (imeiNumber !== "" && imeiNumber.length !== 15) ||
                    (compatibilityLoading && imeiNumber === "")
                  }
                  helperText={
                    imeiErr
                      ? imeiErr
                      : imeiNumber !== "" && !digitsOnlyPattern.test(imeiNumber)
                      ? imei_err.err_1
                      : imeiNumber !== "" && imeiNumber.length !== 15
                      ? imei_err.err_2
                      : compatibilityLoading && imeiNumber === ""
                      ? imei_err.err_3
                      : ""
                  }
                  maxlength={15}
                />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                    color: "var(--dark-charcoal-fade)",
                    display: "flex",
                    justifyContent: "end",
                    position: "absolute",
                    right: "0",
                    top: "63px",
                  }}
                  data-testid="byod_dialog_text_length"
                >
                  {imeiNumber && imeiNumber.length ? imeiNumber.length : "0"}
                  {limit_imei}
                </Typography>
              </Box>
              <Stack sx={{ mt: { xs: "15px", sm: "25px" } }}>
                <ButtonCust
                  sx={{
                    width: { xs: "250px", sm: "250px" },
                    height: "45px !important",
                    color: "#ffffff",
                    fontSize: "14px !important",
                    fontFamily: "var(--font_family_Semibold) !important",
                    fontWeight: "var(--font_weight_2)",
                  }}
                  onClick={() => ImeiCheckHandler()}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  loading={compatibilityLoading}
                  data-testid="byod_dialog_button"
                >
                  {btn_1}
                </ButtonCust>
              </Stack>
              <CustToolTip_T2
                title={
                  <Box
                    sx={{
                      color: "var(--white)",
                      fontSize: "13px",
                      lineHeight: "18px",
                      padding: "25px",
                      fontWeight: "var(--font_weight_1)",
                      fontFamily: "var(--font_family_Medium)",
                      background: "var(--black)",
                      width: { xs: "250px", sm: "400px", md: "450px" },
                      borderRadius: "10px",
                    }}
                    data-testid="byod_dialog_tooltip_content"
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(
                        props?.byodContent?.what_is_imei_tooltip
                      ),
                    }}
                  ></Box>
                }
                placement="top-start"
                arrow
                className={Styles.customTooltip}
                sx={{
                  "& .tooltipClasses.arrow": {
                    "&::before": {
                      backgroundColor: "var(--black) !important",
                      boxShadow: "rgba(0,0,0,0.8)",
                      fontSize: "17px",
                      borderRadius: "none",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    fontSize: "16px",
                    color: "var(--primary_color)",
                    mt: "15px",
                    fontWeight: "var(--font_weight_2)",
                  }}
                  data-testid="byod_dialog_tooltip_text"
                >
                  {props?.byodContent?.res?.whats_imei}
                </Box>
              </CustToolTip_T2>
            </Stack>
          ) : null}
          {errorInCompat ? (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                component="h3"
                sx={{
                  fontSize: { xs: "18px", sm: "28px" },
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "var(--font_weight_custom2)",
                  mb: "20px",
                  color: "var(--dark-charcoal-fade)",
                  textAlign: "center",
                }}
                data-testid="byod_dialog_error_title"
              >
                {byod_fail_title}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: { xs: "13px", sm: "18px" },
                  fontFamily: "var(--font_family_Semibold)",
                  color: "var(--text_color)",
                  fontWeight: "var(--font_weight_1)",
                  textAlign: "center",
                  width: "80%",
                  marginBottom: "25px",
                }}
                data-testid="byod_dialog_error_sub_title"
              >
                {byod_fail_subtitle}
              </Typography>
              <ButtonCust
                sx={{
                  width: { xs: "200px", sm: "250px" },
                  height: "45px !important",
                  color: "#ffffff",
                  fontSize: "14px !important",
                  fontFamily: "var(--font_family_Semibold) !important",
                  fontWeight: "var(--font_weight_2)",
                  my: "10px",
                }}
                onClick={() => tryAgainHandler()}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                data-testid="byod_dialog_error_btn"
              >
                {byod_fail_btn}
              </ButtonCust>
            </Stack>
          ) : null}
          {isCompatible ? (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                alt={tick_icon?.data?.attributes?.alternativeText}
                src={tick_icon?.data?.attributes?.url}
                sx={{
                  my: "20px",
                }}
              />
              <Typography
                component="h3"
                sx={{
                  fontSize: { xs: "18px", sm: "28px" },
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "var(--font_weight_custom2)",
                  mb: "20px",
                  color: "var(--title-color_1)",
                  textAlign: "center",
                }}
                data-testid="byod_dialog_success_text"
              >
                {BrandNameUpdate(success_msg)}
              </Typography>
              {EnvConfig?.IS_CHECK_COMPATIBILITY_APPLE_SETTINGS ? (
                <>
                  <Typography
                    component="h3"
                    sx={{
                      fontSize: "16px !important",
                      lineHeight: { xs: "18px", sm: "24px" },
                      fontFamily: "var(--font_family_Bold) !important",
                      fontWeight: "var(--font_weight_2) !important",
                      mb: "20px",
                      color: "var(--primary_color)",
                      cursor: "pointer",
                    }}
                    onClick={handleCheckPhone}
                    data-testid="byod_dialog_ch_ph"
                  >
                    {BrandNameUpdate(props?.byodContent?.ck_an_ph)}
                  </Typography>
                  {/* <Box
                    sx={{
                      marginX: { xs: "0", md: "10%" },
                      padding: "20px",
                      mb: "30px",
                      borderRadius: "4px",
                      backgroundColor: "var(--lite_background)",
                      border: "1px solid var(--primary_brand_color)",
                    }}
                  >
                    <Typography
                      component="h3"
                      sx={{
                        fontSize: "16px !important",
                        lineHeight: { xs: "24px", sm: "29px" },
                        fontFamily: "var(--font_family_Medium)  !important",
                        fontWeight: "var(--font_weight_1)  !important",
                        pb: "20px",
                        color: "var(--black)",
                        textAlign: "left",
                      }}
                    >
                      {BrandNameUpdate(
                        compatibility?.data?.data?.make.toLowerCase() !==
                          "apple"
                          ? props?.byodContent?.im_cr_an
                          : props?.byodContent?.im_cr_ip
                      )}
                    </Typography>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html:
                          compatibility?.data?.data?.make.toLowerCase() !==
                          "apple"
                            ? props?.byodContent?.a_nxt_lnk
                            : props?.byodContent?.acc_nxt_lnk,
                      }}
                      sx={{
                        fontSize: "14px !important",
                        lineHeight: { xs: "18px", sm: "25px" },
                        fontFamily: "var(--font_family_Regular)  !important",
                        fontWeight: "var(--font_weight_0)  !important",
                        color: "var(--text_color)",
                        "& a": {
                          fontFamily: "var(--font_family_Medium)  !important",
                          fontWeight: "var(--font_weight_1)  !important",
                          color: "var(--primary_color)",
                        },
                      }}
                    />
                  </Box> */}
                  <Box
                    sx={{
                      // display: "flex",
                      // justifyContent: { xs: "center", sm: "start" },
                      marginTop: { xs: "10px", sm: "0px" },
                    }}
                    data-testid="byod_dialog_apn_note_parent"
                  >
                    {EnvConfig?.IS_APN_SETTINGS_ENABLED && osdata?.os && (
                      <ApnNote
                        imeiData={{
                          os: osdata?.os ?? "",
                          osVer: osdata?.osVer ?? 0,
                        }}
                        isShowAsPill={false}
                        currentLine={osdata}
                        closeCompatModal={closeImeiModal}
                        closeCompImeiModal={closeModalHandler}
                      />
                    )}
                  </Box>
                </>
              ) : (
                <ButtonCust
                  sx={{
                    width: { xs: "200px", sm: "250px" },
                    height: "45px !important",
                    color: "#ffffff",
                    fontSize: "14px !important",
                    fontFamily: "var(--font_family_Semibold) !important",
                    fontWeight: "var(--font_weight_2)",
                    my: "10px",
                  }}
                  onClick={() => seePlansHandler()}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                  data-testid="byod_dialog_button_2"
                >
                  {btn_2}
                </ButtonCust>
              )}
              {/* <ButtonCust
                sx={{
                  width: { xs: "200px", sm: "250px" },
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "18px",
                  fontWeight: "var(--font_weight_2)",
                  my: "10px",
                }}
                onClick={() => seePlansHandler()}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              >
                {btn_2}
              </ButtonCust> */}
            </Stack>
          ) : null}
        </SimpleModal>
      </>
    )
  );
};
export default ByodComponent;
