import {
  Box,
  CircularProgress,
  ClickAwayListener,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  ButtonCust,
  CustToolTip,
  NotificationCust,
  SwitchCust,
} from "../../../widgets";
import Styles from "./S3_IRPurchaseHistory.module.scss";
import Config from "../../../../config/env.config.json";
import IRAutoRenewal from "./IRAutoRenewal";
import IRNoOrderHistory from "./IRNoOrderHistory";
import IRDataUsage from "./IRDataUsage";
import { addMinutes, differenceInMilliseconds, format } from "date-fns";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import IRDataBoost from "./IRDataBoost";
import DropDownMenu from "../../../widgets/DropDownMenu/DropDownMenu";
import IRCancelReschedulePack from "./IRCancelReschedulePack";
import _ from "lodash";
import {
  BrandNameUpdate,
  getUTCDateIR,
  gmtToEst,
  updateSvgFillColor,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import PaymentStatus from "../../IRILDComponents/PaymentStatus/PaymentStatus";
import { IRPackStatus, PostActivationIRILDEnums } from "../../../../enums";
import { getPackUsageHistory } from "../../../../features/services/servicesSlice";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { toTitleCase } from "../../../../utils/commonFunctions/titleCase";
import styled from "@emotion/styled";
import { bg } from "date-fns/locale";

const T3_IRPurchaseHistory = (props: any) => {
  const {
    content,
    allPacksData,
    isDataBoostJourney,
    setIsDataBoostJourney,
    onClickSwitchTabs,
    rescheduleSuccess,
    setRescheduleSuccess,
    callAllPacksApi,
  } = props;
  const dispatch = useAppDispatch();
  const { allPacksPending } = useAppSelector((state) => state.services);
  const { group } = useAppSelector((state) => state.account);
  const {
    pack_status,
    data_boost,
    ordered_for,
    order_details,
    data_txt,
    amt_txt,
    ordered_on,
    activates_on,
    expired_on,
    unit,
    details,
    active,
    expires_on,
    statusList,
    select_img,
    up_arrow_img,
    down_arrow_img,
    refund_txt,
    cancelled_on,
    refund_note,
    // buy_pack_again,
    reschedule_success,
    act_loader,
    failed_data,
    failed_icon,
    snackBar,
    timezone_note,
    note,
    active_svg,
    details_svg,
  } = content;

  const {
    all_pack_container,
    dot,
    horizontal_line,
    vertical_line,
    pack_details,
    country_name,
    tooltip_style,
  } = Styles;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataBoostLine, setDataBoostLine] = useState(null);
  const [packId, setPackId] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [packIndex, setPackIndex] = useState<number | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState({
    name: null,
    status: "",
  });
  const [selectedLineValue, setSelectedLineValue] = useState({
    status: null,
    name: "All lines",
  });
  const [userList, setUserList] = useState([
    {
      status: null,
      name: "All lines",
    },
  ]);
  const menuOpen = Boolean(menuAnchorEl);
  const [lineAnchorEl, setLineAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);
  const lineOpen = Boolean(lineAnchorEl);
  const [filterData, setFilterData] = useState([]);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (filterData && filterData?.length > 0) {
      const hasInProgressStatus = filterData.some(
        (item: any) =>
          item.status === IRPackStatus?.CREATION_IN_PROGRESS ||
          item.status === IRPackStatus?.PROVISIONING_IN_PROGRESS
      );
      if (hasInProgressStatus) {
        setTimeout(() => {
          callAllPacksApi();
        }, 3 * 1000);
      }
    }
  }, [filterData]);

  const processingPackId: any = localStorage.getItem("SpecificPurchasePackId");

  const sortingPurchaseHistoryData = (data: any) => {
    let sortedBasedOnStatus: any = [];
    let currentList: any = [];
    // Handling sorting For CREATION_IN_PROGRESS and  PROVISIONING_IN_PROGRESS Items
    data.map((item: any) => {
      if (
        item?.status === IRPackStatus.CREATION_IN_PROGRESS ||
        item?.status === IRPackStatus.PROVISIONING_IN_PROGRESS
      ) {
        currentList.push(item);
      }
    });
    currentList.sort((a: any, b: any) =>
      differenceInMilliseconds(new Date(a.createdDate), new Date(b.createdDate))
    );

    currentList.map((item: any) => {
      sortedBasedOnStatus.push(item);
    });
    currentList = [];

    // Handling sorting For Active Items
    data.map((item: any) => {
      if (item?.status === IRPackStatus.ACTIVE) {
        currentList.push(item);
      }
    });
    currentList.sort((a: any, b: any) =>
      differenceInMilliseconds(new Date(b.createdDate), new Date(a.createdDate))
    );

    currentList.map((item: any) => {
      sortedBasedOnStatus.push(item);
    });
    currentList = [];

    // Handling sorting For Schduled Items
    data.map((item: any) => {
      if (item?.status === IRPackStatus.SCHEDULED) {
        currentList.push(item);
      }
    });
    currentList.sort((a: any, b: any) =>
      differenceInMilliseconds(new Date(a.startDate), new Date(b.startDate))
    );
    currentList.map((item: any) => {
      sortedBasedOnStatus.push(item);
    });

    currentList = [];

    // Handling sorting For EXPIRED Items
    data.map((item: any) => {
      if (item?.status === IRPackStatus.EXPIRED) {
        currentList.push(item);
      }
    });
    currentList.sort((a: any, b: any) =>
      differenceInMilliseconds(new Date(b.createdDate), new Date(a.createdDate))
    );

    currentList.map((item: any) => {
      sortedBasedOnStatus.push(item);
    });
    currentList = [];

    // Handling sorting For CANCELLED Items
    data.map((item: any) => {
      if (item?.status === IRPackStatus.CANCELLED) {
        currentList.push(item);
      }
    });
    currentList.sort((a: any, b: any) =>
      differenceInMilliseconds(new Date(b.createdDate), new Date(a.createdDate))
    );

    currentList.map((item: any) => {
      sortedBasedOnStatus.push(item);
    });
    currentList = [];

    // Handling sorting For FAILED Items
    data.map((item: any) => {
      if (item?.status === IRPackStatus.FAILED) {
        currentList.push(item);
      }
    });
    currentList.sort((a: any, b: any) =>
      differenceInMilliseconds(new Date(b.createdDate), new Date(a.createdDate))
    );

    currentList.map((item: any) => {
      sortedBasedOnStatus.push(item);
    });
    currentList = [];
    return sortedBasedOnStatus;
  };
  const filteredPaymentData: any = filterData.filter(
    (pack: any) => pack.id === processingPackId
  );

  useEffect(() => {
    const dateString = new Date(
      filteredPaymentData[0]?.createdDate
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    if (filteredPaymentData[0]?.status === 1) {
      NotificationCust({
        message: (
          <Box
            sx={{
              fontSize: "14px",
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_5)",
            }}
          >
            {` ${snackBar.starting}
              ${dateString}
              ${snackBar.ending}`}
          </Box>
        ),
        type: "info",
        duration: 10000,
        id: "successActivationMessage",
        showIcon: true,
      });

      localStorage.removeItem("SpecificPurchasePackId");
    }
  }, [filteredPaymentData]);

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: any
  ) => {
    setSelectedIndex(index);
    setSelectedValue(option);
    setMenuAnchorEl(null);
  };

  const handleClickLineItem = (event: React.MouseEvent<HTMLElement>) => {
    setLineAnchorEl(event.currentTarget);
  };

  const handleLineClose = () => {
    setLineAnchorEl(null);
  };

  const handleLineItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: any
  ) => {
    setSelectedLineIndex(index);
    setSelectedLineValue(option);
    setLineAnchorEl(null);
  };
  const [selectedDate, setSelectedDate] = useState<any>("");

  const onClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const PoperOver = (data: any, index?: any) => {
    return (
      <ClickAwayListener
        onClickAway={onClose}
        data-testid={`IR_purchase_hist_popover_wrapper${
          Number(index) + 1 || 1
        }`}
      >
        <List
          sx={{
            listStyleType: "disc",
            pl: 3,
            "& .MuiListItem-root": {
              display: "list-item",
            },
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "var(--font_weight_5)",
            fontSize: "14px",
            color: "var(--white)",
            lineHeight: "18px",
            backgroundColor: "var(--primary_color)",
          }}
          data-testid={`IR_purchase_hist_list_${Number(index) + 1 || 1}`}
        >
          <ListItem
            disablePadding
            sx={{
              padding: "10px 10px 10px 5px",
            }}
            data-testid={`IR_purchase_hist_list_item_date_${
              Number(index) + 1 || 1
            }`}
          >
            {ordered_on}{" "}
            {data.status === IRPackStatus.SCHEDULED
              ? format(
                  getUTCDateIR(new Date(data?.tentativeStartDate ?? "--")),
                  "MMM dd, yyyy hh:mmaaa"
                )
              : format(
                  getUTCDateIR(new Date(data?.createdDate ?? "--")),
                  "MMM dd, yyyy hh:mmaaa"
                )}
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              padding: "10px 10px 10px 5px",
            }}
            data-testid={`IR_purchase_hist_list_status_${
              Number(index) + 1 || 1
            }`}
          >
            {data.status === IRPackStatus.SCHEDULED ? activates_on : expires_on}{" "}
            {format(
              getUTCDateIR(
                new Date(
                  data.status === IRPackStatus.SCHEDULED
                    ? data?.tentativeStartDate
                    : data?.tentativeEndDate ?? "--"
                )
              ),
              "MMM dd, yyyy hh:mmaaa"
            )}
          </ListItem>
        </List>
      </ClickAwayListener>
    );
  };

  const handleDataBoostCta = (data: any, index: any) => {
    setIsDataBoostJourney(true);
    setDataBoostLine(data);
    setPackIndex(index);
  };

  const handleDataUsage = (data: any) => {
    setPackId(data.id);
    dispatch(
      getPackUsageHistory({
        packId: data.id,
      })
    );
  };

  const getUserName = (packData: any, index: any) => {
    let userData = group?.data.find(
      (i: any) => i.id === packData[index].customerId
    );
    const { firstName, lastName } = userData;
    const userName = lastName ? `${firstName} ${lastName}` : firstName;
    return toTitleCase(userName);
  };

  const getUserList = () => {
    let newuserArr = allPacksData
      ?.filter((el: any) =>
        group?.data.find((f: any) => f.id === el.customerId)
      )
      ?.map((item: any) => ({
        name:
          group?.data.find((f: any) => f.id === item.customerId).firstName +
          " " +
          group?.data.find((f: any) => f.id === item.customerId).lastName,
        status: group?.data.find((f: any) => f.id === item.customerId).id,
      }));

    return _.uniqBy(newuserArr, function (e: any) {
      return e.status;
    });
  };

  useEffect(() => {
    if (allPacksData) {
      let test2: any = [
        {
          status: null,
          name: "All Lines",
        },
      ];

      test2.push(...getUserList());

      setUserList(test2);
      setSelectedValue(statusList[0]);
      setSelectedLineValue(test2[0]);
    }
  }, [allPacksData]);

  useNonInitialEffect(() => {
    if (allPacksData) {
      const oldPacks = [...allPacksData];
      const filteredAllPersons: any = oldPacks
        .filter((pack) => {
          if (String(selectedValue?.status) === "0") {
            return pack.status === 0 || pack.status === 3;
          } else {
            if (selectedValue?.status == null) return pack;
            else {
              return selectedValue?.status === pack.status;
            }
          }
        })
        .filter((pack) => {
          if (selectedLineValue?.status === pack.customerId) return true;
          if (selectedLineValue?.status == null) return pack;
        });
      const sortedPackData = sortingPurchaseHistoryData(filteredAllPersons);
      setFilterData(sortedPackData);
    }
  }, [selectedValue, selectedLineValue]);
  const dateFormat: any = (e: any) => {
    const date = new Date(e);
    const formattedDate = format(date, "MMMM d, yyyy");
    return formattedDate;
  };

  return (
    <>
      {!isDataBoostJourney &&
        !rescheduleSuccess &&
        Config.SHOW_IR_FILTER &&
        filterData.length !== 0 && (
          <Box
            sx={{ display: "flex", justifyContent: "end" }}
            data-testid="IR_purchase_wrapper"
          >
            <DropDownMenu
              statuslist={userList}
              handleMenuItemClick={handleLineItemClick}
              handleClose={handleLineClose}
              menuAnchorEl={lineAnchorEl}
              handleClickListItem={handleClickLineItem}
              selectedIndex={selectedLineIndex}
              menuOpen={lineOpen}
              selectedIcon={select_img}
              uparrowIcon={up_arrow_img}
              downarrowIcon={down_arrow_img}
              labelStyles={{
                color: "rgba(86, 88, 94, 1)!important",
                "& .MuiListItemText-secondary": {
                  color: "var(--text_color) !important",
                  fontSize: "14px !important",
                  linHeight: "21px !important",
                  fontWeight: "var(--font_weight_1) !important",
                  fontFamily: "var(--font_family_Medium) !important",
                },
              }}
              styleSX={{ bgcolor: "transparent" }}
            />
            <DropDownMenu
              statuslist={statusList}
              handleMenuItemClick={handleMenuItemClick}
              handleClose={handleClose}
              menuAnchorEl={menuAnchorEl}
              handleClickListItem={handleClickListItem}
              selectedIndex={selectedIndex}
              menuOpen={menuOpen}
              selectedIcon={select_img}
              uparrowIcon={up_arrow_img}
              downarrowIcon={down_arrow_img}
              labelStyles={{
                color: "rgba(86, 88, 94, 1)!important",
                "& .MuiListItemText-secondary": {
                  color: "var(--text_color) !important",
                  fontSize: "14px !important",
                  linHeight: "21px !important",
                  fontWeight: "var(--font_weight_1) !important",
                  fontFamily: "var(--font_family_Medium) !important",
                },
              }}
              styleSX={{ bgcolor: "transparent" }}
            />
          </Box>
        )}
      {filterData &&
      filterData.length !== 0 &&
      !isDataBoostJourney &&
      !rescheduleSuccess ? (
        filterData.map((data: any, index: any) => (
          <Box
            className={all_pack_container}
            key={data.id}
            sx={{
              mb: index === filterData.length - 1 ? "15px" : "40px",
              width: "100%",
            }}
            data-testid={`IR_purchase_hist_card_${Number(index) + 1 || 1}`}
          >
            <Box className={pack_details}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  minWidth: { xs: "100%", md: "100%" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    gap: { xs: "10px", md: "0" },
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: { xs: "25px", md: "18px" },
                      fontWeight: {
                        xs: "var(--font_weight_4)",
                        md: "var(--font_weight_1)",
                      },
                      lineHeight: "32px",
                      color: "var(--text_color)",
                    }}
                    data-testid={`IR_purchase_hist_dp_name_${
                      Number(index) + 1 || 1
                    }`}
                  >
                    {data.offeringDisplayName}
                  </Typography>
                  {data.countriesMap && (
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_5)",
                        color: "var(--tab_color_2)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        className={dot}
                        sx={{ fontSize: "18px !important" }}
                      ></Box>
                      <Box
                        component="span"
                        className={country_name}
                        sx={{ fontSize: "18px !important" }}
                        data-testid={`IR_purchase_hist_country_name_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        {(data.countriesMap[
                          data.countriesList[0]
                        ]?.countryName).toLowerCase()}
                      </Box>
                    </Typography>
                  )}

                  <Typography
                    component="div"
                    sx={{
                      fontFamily:
                        data.status === IRPackStatus.SCHEDULED ||
                        data.status === IRPackStatus.ACTIVE
                          ? "var(--font_family_Semibold)"
                          : "var(--font_family_Medium)",
                      fontWeight:
                        data.status === IRPackStatus.SCHEDULED ||
                        data.status === IRPackStatus.ACTIVE
                          ? "var(--font_weight_2)"
                          : data.status === IRPackStatus.ACTIVE
                          ? "var(--font_weight_4)"
                          : "var(--font_weight_0)",
                      color:
                        data.status === IRPackStatus.SCHEDULED
                          ? "var(--primary_color)"
                          : data.status === IRPackStatus.EXPIRED ||
                            data.status === IRPackStatus.CANCELLED ||
                            data.status === IRPackStatus.FAILED
                          ? "var(--danger)"
                          : data.status === IRPackStatus.ACTIVE
                          ? "var(--primary_color)"
                          : "var(--text_color)",
                      display: "flex",
                      alignItems: "center",
                      fontSize: { xs: "18px", md: "18px" },
                    }}
                    data-testid={`IR_purchase_hist_status_ongoing__${
                      Number(index) + 1 || 1
                    }`}
                  >
                    <Box component="span" className={dot}></Box>
                    {data.status === IRPackStatus.FAILED
                      ? failed_data?.failed
                      : pack_status.status[data.status]}
                    {data.status === IRPackStatus.ACTIVE && (
                      // <Box
                      //   component="img"
                      //   alt={active?.data.attributes?.alternativeText || ""}
                      //   src={active?.data.attributes?.url || ""}
                      //   ml="3px"
                      //   position="relative"
                      //   top="2px"
                      // />
                      <Box
                        component="div"
                        sx={{
                          marginLeft: "3px",
                          position: "relative",
                          display: "flex",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: updateSvgFillColor(
                            active_svg,
                            "var(--primary_color)"
                          ),
                        }}
                        data-testid={`IR_purchase_hist_active_svg_${
                          Number(index) + 1 || 1
                        }`}
                      />
                    )}
                    {data.status === IRPackStatus.CREATION_IN_PROGRESS && (
                      <Box
                        component="img"
                        alt={
                          act_loader?.data?.attributes?.alternativeText || ""
                        }
                        src={act_loader?.data?.attributes?.url || ""}
                        ml="3px"
                        position="relative"
                        top="2px"
                        width="15px"
                        height="15px"
                        data-testid={`IR_purchase_hist_act_loader_${
                          Number(index) + 1 || 1
                        }`}
                      />
                    )}
                  </Typography>
                </Box>
                {data.status === IRPackStatus.CANCELLED ? (
                  <Typography
                    component="div"
                    sx={{
                      fontFamily: "var(--font_family_Bold)",
                      fontWeight: "var(--font_weight_5)",
                      color: "var(--lite_background2)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="span"
                      className={dot}
                      sx={{
                        backgroundColor: "var(--primary_color) !important",
                      }}
                    ></Box>
                    <Box
                      component="span"
                      sx={{
                        fontFamily: "var(--font_family_Bold)",
                        color: "var(--primary_color)",
                        mt: { xs: "10px", md: "0" },
                      }}
                      data-testid={`IR_purchase_hist_refund_txt_${
                        Number(index) + 1 || 1
                      }`}
                    >
                      {refund_txt}
                    </Box>
                  </Typography>
                ) : data.status === IRPackStatus.ACTIVE &&
                  Config.ENABLE_IR_DATA_BOOST ? (
                  <ButtonCust
                    data-testid={`IR_purchase_hist_boost_btn_${
                      Number(index) + 1 || 1
                    }`}
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    onClick={() => handleDataBoostCta(data, index)}
                  >
                    {data_boost}
                  </ButtonCust>
                ) : null}
              </Box>
              <Box className={horizontal_line}></Box>
              {
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { xs: "center", sm: "flex-start" },
                    flexDirection: { xs: "column", md: "row" },
                    mb: "25px",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      fontSize: "16px",
                      color: "var(--text_color)",
                      lineHeight: "18px",
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        color: "var(--text_color)",
                        fontFamily: "var(--font_family_Regular)",
                        fontWeight: "var(--font_weight_0)",
                        fontsize: "16px",
                      }}
                      data-testid={`IR_purchase_hist_ord_text_${
                        Number(index) + 1 || 1
                      }`}
                    >
                      {ordered_for}{" "}
                      <Typography
                        component="span"
                        sx={{
                          color: "var(--text_color)",
                          fontFamily: "var(--font_family_Semibold)",
                          fontWeight: "var(--font_weight_2)",
                          textTransform: "capitalize",
                          fontsize: "16px",
                        }}
                        data-testid={`IR_purchase_hist_user_name_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        {getUserName(filterData, index)}
                      </Typography>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: "25px",
                        mb: { xs: "25px", md: "0" },
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_0)",
                          fontSize: "16px",
                          color: "var(--text_color)",
                          lineHeight: "18px",
                        }}
                        data-testid={`IR_purchase_hist_data_txt_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        {data_txt}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Bold)",
                          fontWeight: "var(--font_weight_4)",
                          fontSize: "16px",
                          color: "var(--text_color)",
                        }}
                        pl="5px"
                        data-testid={`IR_purchase_hist_total_data_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        {data.totalDataGB + unit}
                      </Typography>
                      <Box
                        sx={{
                          width: " 2.5px",
                          height: "24px",
                          margin: " 0 15px",
                          backgroundColor: " var(--primary_color);",
                        }}
                      ></Box>
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_0)",
                          fontSize: "16px",
                          color: "var(--text_color)",
                          lineHeight: "18px",
                        }}
                        data-testid={`IR_purchase_hist_$amt_txt_{Number(index) + 1 || 1}`}
                      >
                        {amt_txt}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Bold)",
                          fontWeight: "var(--font_weight_4)",
                          fontSize: "16px",
                          color: "var(--text_color)",
                        }}
                        pl="5px"
                        data-testid={`IR_purchase_hist_total_cst_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        {GetCurrencyFixedToTwo(data.totalCost)}
                      </Typography>
                    </Box>
                  </Box>
                  {data.status === IRPackStatus.ACTIVE ||
                  data.status === IRPackStatus.CREATION_IN_PROGRESS ? (
                    <>
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_1)",
                          fontSize: "16px",
                          color: "var(--text_color)",
                          lineHeight: "18px",
                          cursor: "pointer",
                          height: "fit-content",
                          display: "flex",
                          flexDirection: { xs: "column-reverse", sm: "column" },
                          alignItems: { xs: "center", sm: "flex-end" },
                          gap: "5px",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          {order_details}
                          <CustToolTip
                            title={PoperOver(data, index)}
                            open={
                              open
                                ? anchorEl === data.id
                                  ? true
                                  : false
                                : false
                            }
                            data-testid={`IR_purchase_hist_tooltip_${
                              Number(index) + 1 || 1
                            }`}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                            placement="bottom-end"
                            onMouseOver={() => {
                              setAnchorEl(data.id);
                              setOpen(true);
                            }}
                            className={tooltip_style}
                          >
                            {/* <Box
                              component="img"
                              alt={
                                details?.data.attributes?.alternativeText || ""
                              }
                              src={details?.data.attributes?.url || ""}
                              ml="2px"
                              position="relative"
                              top="3px"
                            /> */}
                            <Box
                              component="div"
                              sx={{
                                marginLeft: "3px",
                                top: "3px",
                                position: "relative",
                                display: "flex",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: updateSvgFillColor(
                                  details_svg,
                                  "var(--primary_color)"
                                ),
                              }}
                              data-testid={`IR_purchase_hist_dt_svg_${
                                Number(index) + 1 || 1
                              }`}
                            />
                          </CustToolTip>
                        </Box>
                        {data?.tentativeStartDate &&
                          data.status === IRPackStatus.ACTIVE && (
                            <Typography
                              sx={{
                                fontFamily: "var(--font_family_Medium)",
                                fontWeight: "var(--font_family_1)",
                                fontSize: "16px",
                                lineHeight: "28px",
                                color: "var(--text_color)",
                                p: { xs: "0px 0px 9px", sm: "24px 0px 0px" },
                              }}
                              data-testid={`IR_purchase_hist_act_txt_${
                                Number(index) + 1 || 1
                              }`}
                            >
                              {activates_on +
                                " " +
                                format(
                                  getUTCDateIR(
                                    new Date(data?.tentativeStartDate)
                                  ),
                                  "MMM dd, yyyy, hh:mmaaa"
                                )}
                            </Typography>
                          )}
                      </Typography>
                    </>
                  ) : (
                    <Box>
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_0)",
                          fontSize: "16px",
                          color: "var(--text_color)",
                          lineHeight: "18px",
                          mb: { xs: "17px", sm: "25px" },
                        }}
                        data-testid={`IR_purchase_hist_ord_txt_${
                          Number(index) + 1 || 1
                        }`}
                      >
                        {ordered_on}{" "}
                        {data?.createdDate
                          ? format(
                              getUTCDateIR(new Date(data?.createdDate)),
                              "MMM dd, yyyy, hh:mmaaa"
                            )
                          : "--"}
                      </Typography>
                      {data.status !== IRPackStatus.FAILED && (
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_0)",
                            fontSize: "16px",
                            color:
                              data.status === IRPackStatus.EXPIRED ||
                              data.status === IRPackStatus.CANCELLED
                                ? "var(--danger)"
                                : "var(--text_color)",
                            lineHeight: "18px",
                          }}
                          data-testid={`IR_purchase_hist_exp_status_${
                            Number(index) + 1 || 1
                          }`}
                        >
                          {data.status === IRPackStatus.EXPIRED
                            ? expired_on +
                              " " +
                              format(
                                getUTCDateIR(new Date(data?.tentativeEndDate)),
                                "MMM dd, yyyy, hh:mmaaa"
                              )
                            : data.status === IRPackStatus.CANCELLED &&
                              data.cancelledDate
                            ? cancelled_on +
                              " " +
                              format(
                                getUTCDateIR(new Date(data?.cancelledDate)),
                                "MMM dd, yyyy, hh:mmaaa"
                              )
                            : activates_on +
                              " " +
                              format(
                                getUTCDateIR(
                                  new Date(data?.tentativeStartDate)
                                ),
                                "MMM dd, yyyy, hh:mmaaa"
                              )}{" "}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              }
              {data.status === IRPackStatus.FAILED && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    paddingBottom: "30px",
                  }}
                >
                  <Box
                    component="img"
                    alt={failed_icon?.data.attributes?.alternativeText || ""}
                    src={failed_icon?.data.attributes?.url || ""}
                    ml="3px"
                    position="relative"
                    top="2px"
                    height="30px"
                    data-testid={`IR_purchase_hist_fail_icon_${
                      Number(index) + 1 || 1
                    }`}
                  />
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      color: "var(--text_color)",
                      fontWeight: "var(--font_weight_0)",
                      m: "10px 0 30px",
                    }}
                    data-testid={`IR_purchase_hist_fail_data_desc_${
                      Number(index) + 1 || 1
                    }`}
                  >
                    {BrandNameUpdate(failed_data.desc)}
                  </Typography>
                </Box>
              )}
              {data.status === IRPackStatus.CANCELLED && (
                <Box
                  component="div"
                  sx={{
                    color: "var(--text_color)",
                    fontSize: "16px",
                    fontFamily: "var(--font_family_Medium)",
                    marginBottom: "30px",
                  }}
                  data-testid={`IR_purchase_hist_refund_note_${
                    Number(index) + 1 || 1
                  }`}
                >
                  {refund_note}
                </Box>
              )}
              {data.status === IRPackStatus.ACTIVE &&
                Config.ENABLE_IR_DATA_RENEWAL && (
                  <IRAutoRenewal
                    content={content}
                    autoRenew={data.autoRenew}
                    packId={data.id}
                    styles={Styles}
                  />
                )}
            </Box>
            {/* {data.status === 5 && (
                <Box
                  component="div"
                  sx={{
                    color: "var(--white)",
                    fontSize: "16px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_5)",
                    backgroundColor: "var(--primary_color)",
                    padding: "24px 0",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {buy_pack_again}
                </Box>
              )} */}
            {data.status === IRPackStatus.SCHEDULED && (
              <Box sx={{ p: "0 25px" }}>
                <Box className={horizontal_line}></Box>
                <IRCancelReschedulePack
                  content={content}
                  packId={data.id}
                  rescheduleSuccess={rescheduleSuccess}
                  setRescheduleSuccess={setRescheduleSuccess}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                  styles={Styles}
                  dataInline={true}
                  scheduledDate={data?.tentativeStartDate}
                  data={data}
                />
              </Box>
            )}
            {data.status === IRPackStatus.ACTIVE && (
              <IRDataUsage
                content={content}
                data={data}
                handleDataUsage={handleDataUsage}
                packId={packId}
              />
            )}
          </Box>
        ))
      ) : allPacksPending ? (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
            data-testid={`IR_purchase_hist_progress${Number(packId) + 1 || 1}`}
          />
        </Box>
      ) : isDataBoostJourney ? (
        <IRDataBoost
          content={content}
          getUserName={() => getUserName(filterData, selectedLineIndex)}
          packIndex={packIndex}
          dataBoostLine={dataBoostLine}
          setIsDataBoostJourney={setIsDataBoostJourney}
          styles={Styles}
        />
      ) : rescheduleSuccess ? (
        <PaymentStatus
          icon={reschedule_success?.addOn_status}
          title={reschedule_success?.title}
          description={`${reschedule_success?.desc} ${dateFormat(
            selectedDate
          )}.`}
          button={reschedule_success?.button}
          onClick={() => {
            callAllPacksApi();
            setRescheduleSuccess(false);
            onClickSwitchTabs(PostActivationIRILDEnums.tab_right);
          }}
          variantType={Config.QUATERNARY_BUTTON_TYPE}
        />
      ) : (
        <Box
          sx={{
            margin: { xs: "70px", sm: "40px 120px" },
          }}
        >
          <IRNoOrderHistory content={content} style={Styles} />
        </Box>
      )}
      {filterData && filterData.length !== 0 && !rescheduleSuccess && (
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            pt: "5px",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontFamily: "var(--font_family_Bold)",
              fontSize: "14px",

              color: "var(--text_color)",
            }}
            data-testid="IR_purchase_hist_note"
          >
            {note}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontSize: "14px",
              fontWeight: "var(--font_weight_0)",
              color: "var(--text_color)",
            }}
            data-testid="IR_purchase_hist_time_zone"
          >
            {timezone_note}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default T3_IRPurchaseHistory;
