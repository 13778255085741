import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import styles from "./PlanCard.module.scss";
import ENVConfig from "../../../config/env.config.json";
import unlimitedPlanDiscount from "../../../config/discountCouponsUnlimited.json";
import normalPlanDiscount from "../../../config/discountCouponsRegular.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { useNonInitialEffect } from "../../../utils/commonFunctions";
import { handleCheckout } from "../../../features/billCalculator/billCalculatorSlice";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";

export const BillCalculatorPlanCard = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  const { plan, content, PurchaseJourneyCheck } = props;
  const dispatch = useAppDispatch();
  const { planData, selectedLines, isCheckout } = useAppSelector(
    (state: any) => state.billCalculator
  );

  useNonInitialEffect(() => {
    if (isCheckout) {
      const reachSelectedPlanDetail = [{
        line: selectedLines,
        plan: planData,
      }];
      localStorage.setItem(
        "reachSelectedPlanLine",
        JSON.stringify(selectedLines)
      );
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify(reachSelectedPlanDetail)
      );
      PurchaseJourneyCheck();
      dispatch(handleCheckout({ isCheckout: false }));
    }
  }, [isCheckout]);

  const getStarted = () => {
    dispatch(handleCheckout({ isCheckout: true }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Card
        sx={{ textAlign: { xs: "center" } }}
        raised
        elevation={isMobile ? 0 : 8}
        className={styles.plan_card}
      >
        <Typography
          component={"h5"}
          sx={{
            fontFamily: "var(--font_family_Semibold)",
            fontSize: { xs: "20px", md: "20px" },
            color: "var(--text_color_12)",
            fontWeight: "600",
            my: "6px",
            textAlign: "center",
          }}
          data-testid={"plan_card_text_" + plan?.displayName}
        >
          {plan?.displayName}
        </Typography>
        {content?.shareable_text &&
          content?.plan_image2?.data?.attributes?.url && (
            <Box className={styles.planData}>
              {ENVConfig.BILLCALCULATOR_PLAN_CARD_SHARABLE_ICON_DATA_REQD && (
                <Box
                  component="img"
                  src={
                    plan?.isUnlimited
                      ? content?.plan_image2?.data?.attributes?.url
                      : content?.plan_image?.data?.attributes?.url
                  }
                  alt={content?.plan_image?.data?.attributes?.alternativeText}
                  className={styles.planImg}
                  data-testid={"plan_card_plan_img_" + plan?.displayName}
                />
              )}
              <Typography
                component={"div"}
                sx={{
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: { xs: "20px", md: "20px" },
                  color: "var(--text_color_12)",
                  fontWeight: "600",
                  my: "6px",
                  textAlign: "center",
                }}
                data-testid={"plan_card_plan_data_" + plan?.displayName}
              >
                {plan?.planData + plan?.dataUnit}
              </Typography>

              <Box sx={{ textAlign: "center" }}>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "32px",
                    fontFamily: "var(--font_family_Bold)",
                    color: "var(--primary_color)",
                  }}
                  data-testid={"plan_card_amount_" + plan?.displayName}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "28px",
                      fontFamily: "var(--font_family_Semibold)",
                      color: "inherit",
                    }}
                  >
                    $
                    {/* {GetCurrencyFixedToTwo(
                      planCost ? planCost : 0,
                      el.currency ? el.currency : "usd",
                      true
                    )} */}
                    {ENVConfig?.IS_DISCOUNT_NEEDED
                      ? GetCurrencyFixedToTwo(
                          plan?.isUnlimited
                            ? plan?.baseLinePrice -
                                unlimitedPlanDiscount?.data?.discountInDollar
                            : plan?.baseLinePrice -
                                normalPlanDiscount?.data?.discountInDollar,
                          plan?.currency,
                          true
                        )
                      : GetCurrencyFixedToTwo(
                          plan?.baseLinePrice,
                          plan?.currency,
                          true
                        )}
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      fontSize: "10px",
                      color: "var(--text_color_12)",
                    }}
                  >
                    {content?.per_month}
                  </Typography>
                  <Typography
                    component={"div"}
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "12px",
                      color: "var(--black)",
                      my: "10px",
                    }}
                    data-testid={"plan_card_tax_fees_" + plan?.displayName}
                  >
                    {content?.tax_fees}
                  </Typography>
                </Typography>
              </Box>

              {!currentdetails?.simPaid && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                  }}
                >
                  <ButtonCust
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    onClick={() => getStarted()}
                    data-testid={"plan_card_btn_txt_" + plan?.displayName}
                  >
                    {content?.btn_text}
                  </ButtonCust>
                </Box>
              )}

              {/* <Box className={styles.planText}>
                {ENVConfig.BILLCALCULATOR_PLAN_CARD_SHARABLE_ICON_DATA_REQD &&
                  `${" "} - ${" "}`}
                {plan?.isUnlimited
                  ? content?.non_shareable_text
                    ? content?.non_shareable_text
                    : ""
                  : content?.shareable_text}{" "}
              </Box> */}
            </Box>
          )}
        {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            textAlign={{ xs: "center" }}
            className={styles.separator}
            style={{ background: "var(--divider_color_2)" }}
          ></Box> */}
        {content?.plan_desc && (
          <Typography
            textAlign="center"
            fontFamily="var(--font_family_Medium)"
            fontSize="12px"
            px="16px"
            my="8px"
            sx={{ opacity: 0.6 }}
            data-testid={"plan_card_plan_desc_" + plan?.displayName}
          >
            {BrandNameUpdate(content?.plan_desc)}
          </Typography>
        )}
        {/* <Box
          sx={{
            fontFamily: "var(--font_family_Semibold)",
            color: "var(--text_color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
          className={styles.planCostData}
        >
          <Box
            sx={{
              fontFamily: "var(--font_family_Bold)",
              color: "var(--text_color)",
              fontSize: "24px",
              fontWeight: "var(--font_weight_4)",
            }}
            component="span"
          >
            {ENVConfig?.IS_DISCOUNT_NEEDED
              ? GetCurrencyFixedToTwo(
                  plan?.isUnlimited
                    ? plan?.baseLinePrice -
                        unlimitedPlanDiscount?.data?.discountInDollar
                    : plan?.baseLinePrice -
                        normalPlanDiscount?.data?.discountInDollar,
                  plan?.currency
                )
              : GetCurrencyFixedToTwo(plan?.baseLinePrice, plan?.currency)}
            <Box
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--text_color)",
                fontSize: "14px",
                fontWeight: "var(--font_weight_5)",
              }}
              component="span"
            >
              {content?.per_month}&nbsp;{content?.tax_fees}
            </Box>
          </Box>
        </Box> */}
        {content?.addition_ln && (
          <Box
            px="16px"
            fontFamily="var(--font_family_Medium)"
            color="var(--primary_color)"
            fontSize="14px"
            lineHeight="1.5"
            letterSpacing="0.00938em"
            data-testid={"plan_card_line_max_" + plan?.displayName}
          >
            {plan?.isUnlimited
              ? content?.unl_plan_desc
              : `${content?.addition_ln}: ${getSymbolFromCurrency(
                  plan?.currency
                )}${
                  plan?.isUnlimited
                    ? plan?.additionalLinePrice -
                      unlimitedPlanDiscount?.data?.secondaryDiscountInDollar
                    : plan?.additionalLinePrice -
                      normalPlanDiscount?.data?.secondaryDiscountInDollar
                } ${content?.upto} ${plan?.maxLines - 1} ${content?.line}`}
          </Box>
        )}
      </Card>
    </Box>
  );
};
