import { ButtonCust, SimpleModal } from "../../../../widgets";
import { Box, Typography } from "@mui/material";
import EnvConfig from "../../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { gtmTagManager } from "../../../../../utils/commonFunctions/GtmTagManager";
import { useAppSelector } from "../../../../../app/hooks";
import { updateSvgFillColor } from "../../../../../utils";

const T2_AddCredits = (props: any) => {
  const { add_credit_details, primary_txt, dec_inc_icon, dec_inc_icon_icon_1, dec_inc_icon_icon_2, dec_inc_icon_icon_3, dec_inc_icon_icon_4 } = props.content;
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const {
    openCreditModal,
    setOpenCreditModal,
    userLineData,
    creditAmount,
    setcreditAmount,
    configKeys,
    handleConfirmPurchase,
    addCreditIncDecHandler,
  } = props;

  return (
    <SimpleModal
      isShowModal={openCreditModal ? true : false}
      onCloseModal={() => {
        setOpenCreditModal(false);
        setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
      }}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "30%", lg: "30%", xl: "30%" },
        borderRadius: "12px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          fontSize: { xs: "18px", md: "24px" },
          fontWeight: "var(--font_weight_4)",
          color: "var(--title-color_1)",
          fontFamily: "var(--font_family_Bold)",
        }}
        data-testid="add_cred_title"
      >
        {add_credit_details.title}
      </Box>
      <Typography
        component={"div"}
        sx={{
          margin: "20px",
        }}
      >
        <Box
          component="span"
          sx={{
            fontSize: "16px",
            fontFamily: "var(--font_family_Regular)",
            color: "var(--text_color)",
            fontWeight: "var(--font_weight_0)",
          }}
          data-testid="add_cred_line_txt"
        >
          {add_credit_details.lineTxt}
        </Box>{" "}
        <Box
          component="span"
          sx={{
            fontSize: "16px",
            fontFamily: "var(--font_family_Regular)",
            color: "var(--text_color)",
            fontWeight: "var(--font_weight_0)",
            textTransform: "capitalize",
          }}
          data-testid="add_cred_fname"
        >
          {userLineData?.firstName?.toLowerCase()}{" "}
          {userLineData?.lastName?.toLowerCase()}
        </Box>{" "}
      </Typography>
      <Box
        sx={{
          fontSize: "16px",
          fontFamily: "var(--font_family_Regular)",
          color: "var(--text_color)",
          fontWeight: "var(--font_weight_0)",
        }}
        data-testid="add_cred_desc"
      >
        {add_credit_details.desc}
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "18px",
          margin: "30px 0 15px",
        }}
      >
        {/* <Box
          component="img"
          alt={dec_inc_icon?.data[0].attributes?.alternativeText || ""}
          src={
            creditAmount === Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
              ? dec_inc_icon?.data[0].attributes?.url
              : dec_inc_icon?.data[1].attributes?.url || ""
          }
          sx={{
            cursor: "pointer",
          }}
          onClick={() => addCreditIncDecHandler("dec")}
        /> */}
        <Box
          component="div"
          onClick={() => addCreditIncDecHandler("dec")}
          sx={{
            cursor: "pointer",
            display: 'flex',
            alignItems: 'center',
          }}
          dangerouslySetInnerHTML={{
            __html: updateSvgFillColor(
              creditAmount === Number(configKeys?.ILD_INCR_CREDIT_AMOUNT) ? dec_inc_icon_icon_1 : dec_inc_icon_icon_2,
              "var(--primary_color)"),
          }}
          data-testid="add_cred_cred_icon_inc"
        />
        <Typography
          component="div"
          sx={{
            fontSize: { xs: "24px", md: "40px" },
            fontWeight: "var(--font_weight_4)",
            color: "var(--primary_color)",
            fontFamily: "var(--font_family_Bold)",
          }}
          data-testid="add_cred_amount"
        >
          {GetCurrencyFixedToTwo(creditAmount)}
        </Typography>
        {/* <Box
          component="img"
          alt={dec_inc_icon?.data[2].attributes?.alternativeText || ""}
          src={dec_inc_icon?.data[2].attributes?.url || ""}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => addCreditIncDecHandler("inc")}
        /> */}
        <Box
          component="div"
          onClick={() => addCreditIncDecHandler("inc")}
          sx={{
            cursor: "pointer",
            display: 'flex',
            alignItems: 'center',
          }}
          dangerouslySetInnerHTML={{
            __html: updateSvgFillColor(
              creditAmount === Number(configKeys?.ILD_MAX_CREDIT_AMOUNT) ? dec_inc_icon_icon_4 : dec_inc_icon_icon_3,
              "var(--primary_color)"),
          }}
          data-testid="add_cred_cred_icon_dec"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "2px",
          background: "var(--lightnavy)",
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          color: "var(--text_color)",
          fontFamily: "var(--font_family_Medium)",
          mt: "20px",
        }}
      >
        <Box sx={{ fontWeight: "var(--font_weight_2)" }} data-testid="add_cred_per_cred">
          {add_credit_details.pricePerCredit}
        </Box>
        <Box sx={{ fontWeight: "var(--font_weight_0)" }} data-testid="add_cred_per_val">
          {add_credit_details.pricePerCreditval}
        </Box>
      </Box>
      <ButtonCust
        data-testid="add_cred_btn"
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        sx={{
          width: "300px",
          mt: "25px",
          height: "50px !important",
          // "&:hover": {
          //   backgroundColor: "var(--button_hover_color) !important",
          //   border: "1px solid var(--button_hover_color) !important",
          // },
        }}
        onClick={() => {
          handleConfirmPurchase();
          gtmTagManager({
            event: globalVal?.gtmEvents?.ILDContinuePurchase,
            click_section: "International Calling",
          });
        }}
      >
        {add_credit_details.btnTxt}
      </ButtonCust>
    </SimpleModal>
  );
};

export default T2_AddCredits;
