import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import { InitialState, TaxTypes, addressType } from "./Types";
import EnvConfig from "../../config/env.config.json";

const getLocalStorage = (flag: number) => {
  let userDetails: any;
  if (flag === 1) {
    userDetails = localStorage.getItem("customerDetail");
  } else {
    userDetails = localStorage.getItem("secondaryCustomerDetail");
  }
  return JSON.parse(userDetails) || null;
};
const setLocalStorage = (action: any) => {
  const user2: any = localStorage.getItem("secondaryCustomerDetail") || null;
  //stops update if secondary line exists
  if (user2 === null) {
    localStorage.setItem(
      "customerDetail",
      JSON.stringify(action.payload?.data?.data)
    );
  }
  if (action.payload?.data?.data?.isPrimary) {
    localStorage.setItem(
      "customerDetail",
      JSON.stringify(action.payload?.data?.data)
    );
  }
  if (!action.payload?.data?.data?.isPrimary) {
    localStorage.setItem(
      "secondaryCustomerDetail",
      JSON.stringify(action.payload?.data?.data)
    );
  }
};
const initialState: InitialState = {
  loginNumberSuccess: null,
  errorLoginNumber: null,
  getInTouch: null,
  userData: {},
  resetPasswordData: null,
  errorResetPasswordData: null,
  stateCityByZip: null,
  errorStateCityByZipcode: null,
  getCustomer: null,
  errorGetCustomer: null,
  createCustomer: null,
  errorCreateCustomer: null,
  updateCustomer: null,
  errorUpdateCustomer: null,
  errorPlanTaxCharges: null,
  errorOneTimeSimTaxCharges: null,
  errorOneTimeShippingTaxCharges: null,
  updateCustomerSource: null,
  errorUpdateCustomerSource: null,
  coupon: null,
  errorCoupon: null,
  couponReserve: null,
  errorCouponReserve: null,
  eligibility: null,
  errorEligibility: null,
  isLoaded: false,
  isLoadedShippingTaxes: false,
  isLoadedPlanTaxCharges: false,
  formValues: {
    firstName: "",
    lastName: "",
    phone: "",
    shipmentPlan: "",
    isShippingSameAsBilling: true,
    billingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    },
    shippingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.shipping,
    },
  },
  planDetails: {
    numberOfLines: null,
    selectedPlan: null,
  },
  oneTimeSIMTaxCharges: null,
  oneTimeShippingTaxCharges: null,
  planTaxCharges: null,
  referrelCoupon: null,
  buttonActionLoader: false,
  isRenderLogin: false,
  primaryUser: getLocalStorage(1),
  secondaryUser: getLocalStorage(2),
  errorGetCouponReserve: null,
  isPrimaryLineEsim: false,
  hsdAdd: null,
  hsdAddError: null,
  landingPromoPlan: null,
  errorLandingPromoPlan: null,
  isPendingSIMVerification: false,
  errorInSIMVerification: false,
  isSIMVerificationSuccess: false,
  getAppData: null,
  getAppDataError: null,
  checkoutCompatabilityState: [],
  psimActivationFee: null,
  esimActivationFee: null,
  navigateToHome: false,
  whitelistedZipcodes: null,
  errorWhitelistedZipcodes: null,
  multiSimVerificationState: [],
  IRTaxCharges: null,
  ILDTaxCharges: null,
  errorIRTaxCharges: null,
  errorILDTaxCharges: null,
  isLoadedIRTaxes: false,
  isLoadedILDTaxes: false,
  messageDetails: null,
  errorMessageDetails: null,
};

export const LandingPromo = createAsyncThunk(
  "checkout/LandingPromo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.landingPromo}${EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPlanTaxCharges = createAsyncThunk(
  "checkout/getPlanTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.plan },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPlanDiffTaxCharges = createAsyncThunk(
  "checkout/getPlanDiffTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.planDiff },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOneTimeSimTaxCharges = createAsyncThunk(
  "checkout/getOneTimeSimTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.sim },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOneTimeShippingTaxCharges = createAsyncThunk(
  "checkout/getOneTimeShippingTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response: any = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: action.type ? action.type : TaxTypes.shipment },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const UpdateCustomer = createAsyncThunk(
  "checkout/UpdateCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateCustomer}${action.id}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const CreateCustomer = createAsyncThunk(
  "checkout/CreateCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.createCustomer}${action.countryCode}/customers`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginWithNumber = createAsyncThunk(
  "checkout/loginWithNumber",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.loginWithNumber}${action.loginNumber}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "checkout/resetPassword",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.resetPassword}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetCustomer = createAsyncThunk(
  "checkout/GetCustomer",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.customerDetails}${action.username}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const UpdateCustomerSource = createAsyncThunk(
  "checkout/UpdateCustomerSource",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "put",
        url: `${ApiEndPoints.updateCustomerSource}${action.customerId}`,
        body: action.data,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const Coupon = createAsyncThunk(
  "checkout/Coupon",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.coupon}${action.couponCode}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const CouponReserve = createAsyncThunk(
  "checkout/CouponReserve",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.couponReserve}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getCustomerCouponReserve = createAsyncThunk(
  "checkout/getCustomerCouponReserve",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.couponReserve}/${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const Eligibility = createAsyncThunk(
  "checkout/Eligibility",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.eligibility}${action.emailId}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const hsdAddApi = createAsyncThunk(
  "checkout/hsdAddApi",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.hsdAddApi}`,
        body: action.data,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const validateSIMNumber = createAsyncThunk(
  "checkout/validateSIMNumber",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: ApiEndPoints.SIMIDValiate,
        body: payload,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetCustomerInfo = createAsyncThunk(
  "checkout/resetCustomerInfo",
  async (_, { rejectWithValue }) => {
    try {
      return true;
    } catch (err: any) {
      return rejectWithValue(false);
    }
  }
);

export const getAppData = createAsyncThunk("homepage/getAppData", async () => {
  const response = await apiJunction.makeRequest({
    method: "get",
    url: ApiEndPoints.appDataApi,
  });
  return response;
});
export const getZipcodes = createAsyncThunk(
  "homepage/getZipcodes",
  async () => {
    const response = await apiJunction.makeRequest({
        method: "get",
        url: ApiEndPoints.zipcodes,
      });
      return response;
  }
);
export const getActivationTax = createAsyncThunk(
  "checkout/getActivationTax",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.activation },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPsimActivationTax = createAsyncThunk(
  "checkout/getPsimActivationTax",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.activation },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkCompatibility = createAsyncThunk(
  "checkout/checkCompatibility",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.checkCompatibility}${action.imeiNumber}`,
      body: action,
      other1: EnvConfig?.OPERATOR,
    });
  }
);

export const getEsimActivationTax = createAsyncThunk(
  "checkout/getEsimActivationTax",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.activation },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
//healthCheck
export const healthCheck = createAsyncThunk(
  "checkout/healthcheck",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.healthCheck}`,
      other1: EnvConfig?.OPERATOR,
    });
  }
);
export const getILDTaxCharges = createAsyncThunk(
  "checkout/getILDTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.ILD },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getIRTaxCharges = createAsyncThunk(
  "checkout/getIRTaxCharges",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.taxCalculation}${action.address.country}`,
        body: { ...action, type: TaxTypes.IR },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getMessageDetails = createAsyncThunk(
  "checkout/getMessageDetails",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.messageDetails}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    storeLoginData: (state, action) => {
      state.userData = action.payload;
    },
    resetPlanTaxCharges: (state) => {
      state.planTaxCharges = null;
    },
    resetOneTimeShippingTaxCharges: (state) => {
      state.oneTimeShippingTaxCharges = null;
    },
    resetOneTimeSimTaxCharges: (state) => {
      state.oneTimeSIMTaxCharges = null;
    },
    updatePlanDetails: (state, action) => {
      state.planDetails = Object.assign({}, state.planDetails, action.payload);
    },
    updateFormValues: (state, action) => {
      state.formValues = Object.assign({}, state.formValues, action.payload);
    },
    resetFormValues: (state) => {
      state.formValues = Object.assign({}, initialState.formValues);
    },
    resetBillingAddress: (state) => {
      state.formValues.billingAddress = Object.assign({}, initialState.formValues.billingAddress);
    },
    resetShippingAddress: (state) => {
      state.formValues.shippingAddress = Object.assign({}, initialState.formValues.shippingAddress);
    },
    updateCouponDetails: (state, action) => {
      state.coupon = Object.assign({}, action.payload);
    },
    resetCoupon: (state) => {
      state.coupon = null;
    },
    setcheckoutIMEIState: (state, action) => {
      state.checkoutCompatabilityState = action.payload;
    },
    setMultiSIMVerificationState: (state, action) => {
      state.multiSimVerificationState = action.payload;
    },
    setPsimActivationFee: (state, action) => {
      state.psimActivationFee = action.payload;
    },
    setEsimActivationFee: (state, action) => {
      state.esimActivationFee = action.payload;
    },
    setLoginStatus: (state) => {
      state.isRenderLogin = true;
    },
    updateSecondaryUser: (state, action) => {
      state.secondaryUser = action.payload;
    },
    updatePrimaryLineType: (state, action) => {
      state.isPrimaryLineEsim = action.payload;
    },
    resetDataGetCustomer: (state) => {
      localStorage.removeItem("customerDetail");
      state.getCustomer = null;
    },
    resetGetCustomerInRedux: (state) => {
      state.getCustomer = null;
    },
    setSIMVerificationState: (state, action) => {
      state.errorInSIMVerification = action.payload?.error;
      state.isSIMVerificationSuccess = action.payload?.isSuccess;
    },
    resetCheckoutstate: (state) => {
      state.updateCustomer = null;
      state.updateCustomerSource = null;
    },
    resetTaxErrors: (state)=>{
      state.errorOneTimeShippingTaxCharges = null
      state.errorPlanTaxCharges = null
    },
    updateCheckOutIMEIState: (state: any, action) => {
      state.checkoutCompatabilityState[action.payload.index][
        action.payload.type
      ] = action.payload.value;
    },
    updateMultiSIMVerificationState: (state: any, action) => {
      state.multiSimVerificationState[action.payload.index][
        action.payload.type
      ] = action.payload.value;
    },
    updateNavigateToHome: (state, action) => {
      state.navigateToHome = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resetCustomerInfo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getCustomer = null;
        state.createCustomer = null;
        state.errorGetCustomer = null;
        state.errorEligibility = null;
        state.primaryUser = null;
        state.isSIMVerificationSuccess = false;
        localStorage.removeItem("customerDetail");
      }
    );
    builder.addCase(
      loginWithNumber.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loginNumberSuccess = action.payload.data;
        state.errorLoginNumber = null;
      }
    );
    builder.addCase(loginWithNumber.rejected, (state, action) => {
      state.loginNumberSuccess = null;
      state.errorLoginNumber = action.payload || "Something went wrong";
    });

    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resetPasswordData = action.payload.data;
        state.errorResetPasswordData = null;
      }
    );
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPasswordData = null;
      state.errorResetPasswordData = action.payload;
    });
    builder.addCase(
      GetCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getCustomer = action.payload?.data?.data || null;
        state.errorGetCustomer = null;
        setLocalStorage(action);
        state.primaryUser = action.payload?.data?.data?.isPrimary
          ? action.payload?.data?.data
          : null;
      }
    );
    builder.addCase(GetCustomer.rejected, (state, action) => {
      state.getCustomer = null;
      state.errorGetCustomer = action.payload || "Something went wrong";
    });
    builder.addCase(
      CreateCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createCustomer = action.payload.data;
        state.errorCreateCustomer = null;
      }
    );
    builder.addCase(CreateCustomer.rejected, (state, action) => {
      state.createCustomer = null;
      state.errorCreateCustomer = action.payload;
    });
    builder.addCase(getMessageDetails.rejected, (state, action) => {
      state.messageDetails = null;
      state.errorMessageDetails = action.payload || "Something went wrong";
    });
    builder.addCase(
      getMessageDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.messageDetails = action?.payload?.data?.data?.results;
        state.errorMessageDetails = null;
      }
    );
    builder.addCase(
      UpdateCustomer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCustomer = action.payload.data;
        state.errorUpdateCustomer = null;
      }
    );
    builder.addCase(UpdateCustomer.rejected, (state, action) => {
      state.updateCustomer = null;
      state.errorUpdateCustomer = action.payload;
    });
    builder.addCase(getPlanTaxCharges.pending, (state) => {
      state.isLoadedPlanTaxCharges = true;
    });
    builder.addCase(
      getPlanTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.planTaxCharges = action.payload.data.data.totalTax;
        state.errorPlanTaxCharges = null;
        state.isLoadedPlanTaxCharges = false;
      }
    );
    builder.addCase(getPlanTaxCharges.rejected, (state, action) => {
      state.planTaxCharges = null;
      state.errorPlanTaxCharges = action.payload;
      state.isLoadedPlanTaxCharges = false;
    });
    builder.addCase(getPlanDiffTaxCharges.pending, (state) => {
      state.isLoadedPlanTaxCharges = true;
    });
    builder.addCase(
      getPlanDiffTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.planTaxCharges = action.payload.data.data.totalTax;
        state.errorPlanTaxCharges = null;
        state.isLoadedPlanTaxCharges = false;
      }
    );
    builder.addCase(getPlanDiffTaxCharges.rejected, (state, action) => {
      state.planTaxCharges = null;
      state.errorPlanTaxCharges = action.payload;
      state.isLoadedPlanTaxCharges = false;
    });
    builder.addCase(getOneTimeSimTaxCharges.pending, (state) => {
      state.oneTimeSIMTaxCharges = null;
      state.errorOneTimeSimTaxCharges = "";
      state.isLoaded = true;
    });
    builder.addCase(
      getOneTimeSimTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.oneTimeSIMTaxCharges = action.payload.data.data;
        state.errorOneTimeSimTaxCharges = null;
        state.isLoaded = false;
      }
    );
    builder.addCase(getOneTimeSimTaxCharges.rejected, (state, action) => {
      state.oneTimeSIMTaxCharges = null;
      state.errorOneTimeSimTaxCharges = action.payload;
      state.isLoaded = false;
    });
    builder.addCase(getOneTimeShippingTaxCharges.pending, (state) => {
      state.oneTimeShippingTaxCharges = null;
      state.errorOneTimeShippingTaxCharges = "";
      state.isLoadedShippingTaxes = true;
    });
    builder.addCase(
      getOneTimeShippingTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.oneTimeShippingTaxCharges = action.payload.data.data;
        state.errorOneTimeShippingTaxCharges = null;
        state.isLoadedShippingTaxes = false;
      }
    );
    builder.addCase(getOneTimeShippingTaxCharges.rejected, (state, action) => {
      state.oneTimeShippingTaxCharges = null;
      state.errorOneTimeShippingTaxCharges = action.payload;
      state.isLoadedShippingTaxes = false;
    });
    builder.addCase(
      UpdateCustomerSource.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCustomerSource = action.payload.data;
        state.errorUpdateCustomerSource = null;
        setLocalStorage(action);
        state.primaryUser = action.payload?.data?.data?.isPrimary
          ? action.payload?.data?.data
          : null;
        state.secondaryUser = action.payload?.data?.data?.isPrimary
          ? null
          : action.payload?.data?.data;
      }
    );
    builder.addCase(UpdateCustomerSource.rejected, (state, action) => {
      state.updateCustomerSource = null;
      state.errorUpdateCustomerSource = action.payload;
    });
    builder.addCase(Coupon.fulfilled, (state, action: PayloadAction<any>) => {
      state.coupon = action.payload.data.data;
      state.errorCoupon = null;
      state.isLoaded = false;
    });
    builder.addCase(Coupon.pending, (state) => {
      state.coupon = null;
      state.errorCoupon = "";
      state.isLoaded = true;
    });
    builder.addCase(Coupon.rejected, (state, action: any) => {
      state.coupon = null;
      state.errorCoupon = action.payload?.message.replace(/{|}/g, "");
      state.isLoaded = false;
    });
    builder.addCase(
      CouponReserve.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.couponReserve = action.payload.data;
        state.errorCouponReserve = null;
      }
    );
    builder.addCase(CouponReserve.rejected, (state, action) => {
      state.couponReserve = null;
      state.errorCouponReserve = action.payload;
    });
    builder.addCase(
      hsdAddApi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.hsdAdd = action.payload;
        state.hsdAddError = null;
      }
    );
    builder.addCase(hsdAddApi.rejected, (state, action) => {
      state.hsdAdd = null;
      state.hsdAddError = action.payload;
    });
    builder.addCase(
      validateSIMNumber.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action?.payload?.data?.data?.isValid) {
          state.isSIMVerificationSuccess = true;
        } else {
          state.isSIMVerificationSuccess = false;
          state.errorInSIMVerification = true;
        }
        state.isPendingSIMVerification = false;
      }
    );
    builder.addCase(validateSIMNumber.pending, (state) => {
      state.isPendingSIMVerification = true;
      state.errorInSIMVerification = false;
      state.isSIMVerificationSuccess = false;
    });
    builder.addCase(validateSIMNumber.rejected, (state, action) => {
      state.errorInSIMVerification = true;
      state.isPendingSIMVerification = false;
      state.isSIMVerificationSuccess = false;
    });

    builder.addCase(
      Eligibility.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.eligibility = action.payload.data;
        state.errorEligibility = null;
      }
    );
    builder.addCase(Eligibility.rejected, (state, action) => {
      state.eligibility = null;
      state.errorEligibility = action.payload;
    });
    builder.addCase(
      LandingPromo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.landingPromoPlan = action.payload?.data?.data;
        state.errorLandingPromoPlan = null;
      }
    );
    builder.addCase(LandingPromo.rejected, (state, action) => {
      state.landingPromoPlan = null;
      state.errorLandingPromoPlan = action.payload;
    });
    builder.addCase(
      getAppData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAppData = action.payload;
        state.getAppDataError = null;
      }
    );
    builder.addCase(getAppData.rejected, (state, action) => {
      state.getAppData = {};
      state.getAppDataError = action.payload || "Something went wrong";
    });
    builder.addCase(
      getZipcodes.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.whitelistedZipcodes = action.payload.data.data;
        state.errorWhitelistedZipcodes = null;
      }
    );
    builder.addCase(getZipcodes.rejected, (state, action) => {
      state.whitelistedZipcodes = {};
      state.errorWhitelistedZipcodes = action.payload || "Something went wrong";
    });
    builder.addCase(getIRTaxCharges.pending, (state) => {
      state.isLoadedIRTaxes = true;
    });
    builder.addCase(
      getIRTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.IRTaxCharges = action.payload.data.data.totalTax;
        state.errorIRTaxCharges = null;
        state.isLoadedIRTaxes = false;
      }
    );
    builder.addCase(getIRTaxCharges.rejected, (state, action) => {
      state.IRTaxCharges = null;
      state.errorIRTaxCharges = action.payload;
      state.isLoadedIRTaxes = false;
    });
    builder.addCase(getILDTaxCharges.pending, (state) => {
      state.isLoadedILDTaxes = true;
    });
    builder.addCase(
      getILDTaxCharges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.ILDTaxCharges = action.payload.data.data;
        state.errorILDTaxCharges = null;
        state.isLoadedILDTaxes = false;
      }
    );
    builder.addCase(getILDTaxCharges.rejected, (state, action) => {
      state.ILDTaxCharges = null;
      state.errorILDTaxCharges = action.payload;
      state.isLoadedILDTaxes = false;
    });
  },
});
export const {
  storeLoginData,
  updatePlanDetails,
  updateCouponDetails,
  resetCoupon,
  setLoginStatus,
  updateSecondaryUser,
  updateFormValues,
  updatePrimaryLineType,
  resetDataGetCustomer,
  resetGetCustomerInRedux,
  resetFormValues,
  setcheckoutIMEIState,
  setSIMVerificationState,
  resetCheckoutstate,
  resetTaxErrors,
  resetPlanTaxCharges,
  setPsimActivationFee,
  setEsimActivationFee,
  resetOneTimeShippingTaxCharges,
  updateNavigateToHome,
  updateCheckOutIMEIState,
  resetOneTimeSimTaxCharges,
  resetShippingAddress,
  resetBillingAddress,
  setMultiSIMVerificationState,
  updateMultiSIMVerificationState,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
