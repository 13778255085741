import { Box, Typography } from "@mui/material";
import { ButtonCust, SimpleModal } from "../../../widgets";
import styles from "./S3_CountryDataModal.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const CountryDataModal = (props: any) => {
  const { countryName, ildVoiceOut, ildSmsOut } = props.selectedCountry;

  const { view_Rates } = props;
  return (
    <SimpleModal
      isShowModal={props.isCountryModal}
      onCloseModal={props.setIsCountryModal}
      showClose={true}
      sx={{
        top: " 20%",
        borderRadius: "10px",
        width: "100%",
        p: "30px 5px 0px",
      }}
    >
      <Box className={styles.modal_main_container} data-testid="country_data_modal_wrapper">
        <Box className={styles.modal_country_name} data-testid="country_data_modal_country_name">
          {countryName?.toLowerCase()}
        </Box>
        <Box className={styles.section}>
          <Typography className={styles.modal_sub_title} data-testid="country_data_modal_first_sub_head">
            {view_Rates?.country_modal.first_subHead}
          </Typography>

          <Typography className={styles.modal_data_text}>
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              data-testid="country_data_modal_voice_text"
            >
              <Box
                component="img"
                src={view_Rates?.icons?.data[1].attributes.url}
                alt={view_Rates?.icons?.data[1]?.attributes.alternativeText}
                pr="15px"
                data-testid="country_data_modal_img"
              />
              {view_Rates.country_modal.voice}{" "}
            </Box>
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              -
            </Box>
            <Box
              component="span"
              style={{
                fontWeight: "var(--font_weight_1)",
                fontFamily: "var(--font_family_Medium)",
                fontSize: "18px",
                lineHeight: "26px",
                color: "var(--text_color)",
                width: "90px",
              }}
              data-testid="country_data_modal_text"
            >
              ${ildVoiceOut && ildVoiceOut}/min
            </Box>
          </Typography>

          <Typography className={styles.modal_data_text}>
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "74px",
              }}
              data-testid="country_data_modal_sms_text"
            >
              <Box
                component="img"
                src={view_Rates?.icons?.data[0].attributes.url}
                alt={view_Rates?.icons?.data[0]?.attributes.alternativeText}
                pr="15px"
                data-testid="country_data_modal_view_rate_img"
              />
              {view_Rates.country_modal.sms}{" "}
            </Box>
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              -
            </Box>
            <Box
              component="span"
              style={{
                fontWeight: "var(--font_weight_1)",
                fontFamily: "var(--font_family_Medium)",
                fontSize: "18px",
                lineHeight: "26px",
                color: "var(--text_color)",
                width: "90px",
                textAlign: "right",
              }}
              data-testid="country_data_modal_sms_out"
            >
              {GetCurrencyFixedToTwo(ildSmsOut)}
            </Box>
          </Typography>
        </Box>
        <Box></Box>
      </Box>
    </SimpleModal>
  );
};

export default CountryDataModal;
