import { Box, Link } from "@mui/material";
import { SelectCust, TextFieldCust } from "../../widgets";
import styles from "./S1_AddressInfo.module.scss";
import { AddressField } from "../AddressField/AddressField";
import { PaymentButtons } from "../PaymentButtons/PaymentButtons";
import { AddressFieldProps } from "./AddressInfo";
import { SwitchCust } from "../..";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { SimTypes, addressType } from "../../../features/checkout/Types";
import {
  displayPhoneNumberFormat,
  updateSvgFillColor,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { PhoneNoPattern } from "../../../utils/regexPatterns";
import { SignUpAndLogin } from "../SignUpAndLogin";
import { countryList } from "../../../config/USAStateList";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getZipcodes,
  resetBillingAddress,
  resetShippingAddress,
  updateFormValues,
} from "../../../features/checkout/checkoutSlice";
import Config from "../../../config/env.config.json";
import AutoPayCard from "../AutoPayCard/AutoPayCard";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import SimInHand from "../SimInHandCard/SimInHand";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { ZipcodeModal } from "../ZipcodeModal";
import { getZipcodeContent } from "../../../features/strapi/checkoutSlice";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../utils";

const T7_AddressInfo = (props: AddressFieldProps) => {
  const {
    formik,
    isLoggedIn,
    plan,
    line,
    setUserDetails,
    content,
    simPreference,
    setSimPreference,
    inputRefs,
    hideBackButton,
  } = props;
  const dispatch = useAppDispatch();
  const { firstName, lastName, phone, isShippingSameAsBilling } = formik.values;
  const {
    isSIMVerificationSuccess,
    isLoadedPlanTaxCharges,
    errorOneTimeShippingTaxCharges,
    errorPlanTaxCharges,
  } = useAppSelector((state: any) => state.checkout);
  const currentUser = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const { zipcodeContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );
  const [zipcodeOpen, setZipcodeOpen] = useState(false);
  const [zipcodeCacheContent, setZipcodeCacheContent] = useState();
  const [showBox, setShowBox] = useState(false);
  useEffect(() => {
    getCache(
      "zipcodeContent",
      getZipcodeContent,
      setZipcodeCacheContent,
      dispatch
    );
  }, []);
  useEffect(() => {
    dispatch(getZipcodes());
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("zipcodeContent", zipcodeContent);
  }, [zipcodeContent]);
  const closeZipModal = () => {
    setZipcodeOpen(false);
    setShowBox(false);
  };
  const clickHandler = () => {
    formik.setFieldValue("billingAddress", {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    });
    formik.setFieldValue("shippingAddress", {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.shipping,
    });
    dispatch(resetBillingAddress());
    dispatch(resetShippingAddress());
    setZipcodeOpen(false);
  };

  const handleShippingAddress = (value: any, name: string) => {
    formik.setFieldValue(`shippingAddress.${name}`, value);
  };
  const handleBillingAddress = (value: any, name: string) => {
    if (name === "address2") {
      formik.setFieldValue(`billingAddress.address2`, value);
      let billingAddress = cloneDeep(formik.values["billingAddress"]);
      billingAddress["address2"] = value;
      // formik.setFieldValue("billingAddress", {
      //   ...billingAddress,
      //   type: addressType.billing,
      // });
      let addresses = {
        billingAddress: {
          ...billingAddress,
          type: addressType.billing,
        },
      };
      dispatch(
        updateFormValues({
          ...formik.values,
          ...addresses,
        })
      );
    } else {
      if (name === "address1" && value === "") {
        formik.setFieldValue(`billingAddress.city`, "");
        formik.setFieldValue(`billingAddress.state`, "");
        formik.setFieldValue(`billingAddress.zip`, "");
        formik.setFieldValue(`billingAddress.country`, "");
      }
      formik.setFieldValue(`billingAddress.address1`, value);
      const addressData = {
        address1: "",
        address2: formik.values["billingAddress"].address2 || "",
        city: "",
        state: "",
        zip: "",
        country: "",
      };
      let addresses = {
        billingAddress: {
          ...addressData,
          type: addressType.billing,
        },
      };
      dispatch(
        updateFormValues({
          ...formik.values,
          ...addresses,
        })
      );
    }
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "shippingAddress" | "billingAddress"
  ) => {
    const results = await geocodeByPlaceId(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : el.types.includes("neighborhood")
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    const addressData = {
      address1: `${streetNumber} ${route}`,
      address2: `${formik.values["billingAddress"].address2 ?? ""}`,
      city: city,
      state: state,
      zip: zip,
      country: countryList?.[0]?.value,
    };
    if (country === "US") {
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(
        `${addressObj}.address2`,
        `${formik.values["billingAddress"].address2 ?? " "}}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
      formik.setTouched({
        ...formik.touched,
        [addressObj]: {
          ...formik.touched[addressObj],
          city: true,
          state: true,
          zip: true,
          country: true,
        },
      });
      if (formik.values.isShippingSameAsBilling) {
        formik.setFieldValue("shippingAddress", {
          ...addressData,
          type: addressType.shipping,
        });
        formik.setTouched({
          ...formik.touched,
          shippingAddress: {
            ...formik.touched["shippingAddress"],
            city: true,
            state: true,
            zip: true,
            country: true,
          },
        });
      }
    }
    let addresses = {
      [addressObj]: {
        ...addressData,
        type:
          addressObj === "billingAddress"
            ? addressType.billing
            : addressType.shipping,
      },
    };
    if (formik.values.isShippingSameAsBilling) {
      addresses = {
        billingAddress: {
          ...addressData,
          type: addressType.billing,
        },
        shippingAddress: {
          ...addressData,
          type: addressType.shipping,
        },
      };
    }

    dispatch(
      updateFormValues({
        ...addresses,
      })
    );
  };

  const updateFieldsState = (
    e: any,
    addressObjName: "shippingAddress" | "billingAddress"
  ) => {
    dispatch(
      updateFormValues({
        [addressObjName]: {
          ...formik.values[addressObjName],
          [e.target.name]: e.target.value,
        },
      })
    );
  };
  const {
    left_sec_heading,
    email_id_text,
    line_checkout,
    text,
    same_billing_address,
    radio_option_note,
    shipping_options,
    note_head,
    note_div,
    checkout_left,
    bill_content_subtext,
    billing_content,
    bill_content_title,
    text_fields_main,
    text_fields_div,
    account_labels,
    zipcode_div,
    bill_content_titlee,
    bill_content_title1,
    bill_content_wrapper,
  } = styles;
  let simPreferenceStore = JSON.parse(
    localStorage.getItem("reachSelectedPlanDetail") || "[]"
  );
  const isSiminHand: boolean = simPreferenceStore.some(
    (item: any) =>
      item.simPreference === SimTypes.phySim && item.simInHand === false
  );
  const alleSimCompatible: boolean = simPreferenceStore.every(
    (sim: any) => sim?.simPreference === "eSim"
  );
  const totalNumberOfPhysicalSims: number = simPreferenceStore.filter(
    (sim: any) => !sim?.eSimCompatible
  ).length;
  return (
    <Box className={checkout_left}>
      {!isLoggedIn ? (
        <SignUpAndLogin
          plan={plan}
          line={line}
          setUserDetails={setUserDetails}
        />
      ) : (
        <>
          {Config.IS_SHOW_EMAIL_IN_CHECKOUT ? (
            <>
              <Box
                className={left_sec_heading}
                data-testid="address_info_parent"
              >
                {/* {content?.GreenMan_img?.data?.attributes?.url ? (
                  <Box
                    component={"img"}
                    height="34px"
                    width="34px"
                    alt={
                      content?.GreenMan_img?.data?.attributes?.alternativeText
                    }
                    src={content?.GreenMan_img?.data?.attributes?.url}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingRight: "10px",
                      "& svg": {
                        width: "34px",
                        height: "34px",
                      },
                    }}
                    data-testid="address_info_icon"
                    dangerouslySetInnerHTML={{
                      __html: updateSvgFillColor(
                        content?.email_svg_icon,
                        "var(--primary_color)"
                      ),
                    }}
                  />
                ) : null} */}
                <Box
                  component={"span"}
                  className={email_id_text}
                  sx={{
                    color: "var(--black)",
                    fontSize: { xs: "18px", sm: "18px" },
                    lineHeight: { xs: "30px", sm: "36px" },
                    fontFamily: {
                      xs: "var(--font_family_Semibold)",
                      sm: "var(--font_family_Semibold",
                    },
                    fontWeight: {
                      xs: "var(--font_weight_3)",
                      sm: "var(--font_weight_3)",
                    },
                  }}
                  data-testid="address_info_text"
                >
                  {props.user && props.user.user && props.user.user.email
                    ? props.user.user.email
                    : props.user && props.user.email
                    ? props.user.email
                    : props.user && props.user.username
                    ? props.user.username
                    : ""}
                </Box>
              </Box>
              <Box
                className={line_checkout}
                sx={{ margin: "0px 30px 10px 30px !important" }}
              ></Box>
            </>
          ) : null}
          {/* {content?.title && (
            <Box className={left_sec_heading}>
              <Box
                component={"img"}
                height="26px"
                width="21px"
                alt={content?.Location_img?.data?.attributes?.alternativeText}
                src={content?.Location_img?.data?.attributes?.url}
              />
              <Box>{content?.title}</Box>
            </Box>
          )} */}
          {/* {content?.title && <Box className={line_checkout}></Box>} */}
          <Box className={billing_content}>
            {content?.n_label && (
              <Box
                className={bill_content_title}
                sx={{
                  fontSize: "18px !important",
                  paddingTop: "0px",
                  marginTop: { xs: "15px !important", lg: "0px !important" },
                }}
                data-testid="address_info_n_label"
              >
                {content?.n_label}
              </Box>
            )}
            <Box>
              <Box className={text_fields_main}>
                <Box className={text_fields_div}>
                  {/* {content?.fn_label && (
                    <Box className={account_labels}>{content?.fn_label}</Box>
                  )} */}
                  <TextFieldCust
                    value={firstName}
                    maxlength={32}
                    name="firstName"
                    inputRef={inputRefs?.firstName}
                    label={content.fn_ph}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z ]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.firstName && formik.errors?.firstName
                    }
                    error={
                      formik.touched?.firstName && formik.errors?.firstName
                        ? true
                        : false
                    }
                    onBlur={(e) =>
                      dispatch(updateFormValues({ firstName: e.target.value }))
                    }
                    data-testid="address_info_textfield_firstname"
                  />
                </Box>
                <Box className={text_fields_div}>
                  {/* {content?.lname_label && (
                    <Box className={account_labels}>{content?.lname_label}</Box>
                  )} */}
                  <TextFieldCust
                    value={lastName}
                    maxlength={32}
                    name="lastName"
                    inputRef={inputRefs?.lastName}
                    label={content?.ln_ph}
                    onChange={(e: any) => {
                      if (
                        /^[a-zA-Z ]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    helperText={
                      formik.touched?.lastName && formik.errors?.lastName
                    }
                    error={
                      formik.touched?.lastName && formik.errors?.lastName
                        ? true
                        : false
                    }
                    onBlur={(e) =>
                      dispatch(updateFormValues({ lastName: e.target.value }))
                    }
                    data-testid="address_info_textfield_lastname"
                  />
                </Box>
              </Box>
              <Box className={text_fields_main}>
                <Box className={text_fields_div}>
                  {/* {content?.phone_label && (
                    <Box className={account_labels}>{content?.phone_label}</Box>
                  )} */}
                  <TextFieldCust
                    data-testid="address_info_textfield_phone_number"
                    value={phone ? displayPhoneNumberFormat(phone) : ""}
                    name="phone"
                    inputRef={inputRefs?.phone}
                    maxlength={10}
                    label={content?.phone_ph}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (
                        (PhoneNoPattern.test(value) && Number(value) >= 0) ||
                        value === ""
                      ) {
                        if (value.length === 10) {
                          e.target.value = value
                            .replace(/\D+/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                        }
                        formik.handleChange(e);
                      }
                      if (value.length > 10) {
                        const contactNumber: string = String(
                          value.match(/\d/g)?.join("") || ""
                        );
                        e.target.value = contactNumber;
                        formik.handleChange(e);
                      }
                    }}
                    helperText={formik.touched?.phone && formik.errors?.phone}
                    error={
                      formik.touched?.phone && formik.errors?.phone
                        ? true
                        : false
                    }
                    onBlur={(e) =>
                      dispatch(updateFormValues({ phone: e.target.value }))
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className={bill_content_titlee}
            sx={{
              fontSize: "18px !important",
              padding: {xs: "8px 20px 0px", md: "8px 35px 0px"},
              marginTop: "-12px !important",
              fontFamily: "var(--font_family_Semibold)",
              fontWeight: "var(--font_weight_2)",
              color: "var(--title-color_1)",
            }}
            data-testid="address_info_billing_title"
          >
            {content?.add1_title}&nbsp;
            {isShippingSameAsBilling && (
              <span
                className={bill_content_subtext}
                data-testid="address_info_billing_sub_text"
              >
                {content?.add1_hint}
              </span>
            )}
          </Box>
          <Box className={bill_content_wrapper}>
            <AddressField
              content={content?.billing}
              formik={formik}
              inputRefs={inputRefs?.billingAddress}
              handleSelect={(e: any) =>
                handleAddressSearchSelection(e, "billingAddress")
              }
              handleAddressChange={handleBillingAddress}
              addressObjName="billingAddress"
              onBlurCallback={updateFieldsState}
            />
          </Box>
          {errorOneTimeShippingTaxCharges || errorPlanTaxCharges ? (
            <Box className={zipcode_div}>
              <span data-testid="address_info_zip_err">{content?.zip_err}</span>
              <Link
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "600",
                  fontFamily: "var(--font_family_Bold)",
                  color: "var(--zip_text)",
                  marginLeft: "3px",
                }}
                onClick={() => {
                  setZipcodeOpen(true);
                }}
                data-testid="address_info_cl_here"
              >
                {content?.here}
              </Link>
            </Box>
          ) : null}
          {Config.IS_ESIM_SUPPORTED &&
          (alleSimCompatible || !isSiminHand) ? null : (
            <>
              {content?.note_txt ? (
                <Box className={note_div} data-testid="address_info_note_txt">
                  <Box
                    component={"span"}
                    className={note_head}
                    data-testid="address_info_note_label"
                  >
                    {content?.note_label}
                  </Box>{" "}
                  {content?.note_txt}
                </Box>
              ) : null}
              <Box
                className={
                  (Config?.IS_SIM_IN_HAND_ENABLED &&
                    props.simInHand &&
                    isSIMVerificationSuccess) ||
                  (props.simInHand &&
                    currentUser?.simId &&
                    currentUser?.isSimInHand)
                    ? styles.disable_section
                    : ""
                }
                sx={{ padding: "0px 35px" }}
              >
                <Box className={shipping_options}>
                  {content?.shipment_lbl && (
                    <Box
                      className={bill_content_title}
                      sx={{
                        fontSize: "18px !important",
                        fontFamily: "var(--font_family_Medium) !important",
                        fontWeight: "var(--font_weight_1) !important",
                        marginTop: "-20px !important",
                      }}
                      data-testid="address_info_shipment_lbl"
                    >
                      {content?.shipment_lbl}
                    </Box>
                  )}
                  <SelectCust
                    value={formik.values.shipmentPlan}
                    name="shipmentPlan"
                    options={props.sendSimOptions}
                    placeholder={content?.shipment_pl}
                    formStyles={{
                      width: { xs: "100%", sm: "55%" },
                    }}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        updateFormValues({ shipmentPlan: e.target.value })
                      );
                      localStorage.setItem("shipmentPlan", e.target.value);
                    }}
                    data-testid="address_info_shipment_switch"
                  />
                </Box>
              </Box>
              {content?.shipping_n_txt ? (
                <Box className={radio_option_note}>
                  <Box
                    className={note_div}
                    data-testid="address_info_note_n_txt"
                  >
                    <Box
                      component={"span"}
                      className={note_head}
                      data-testid="address_info_note_head"
                    >
                      {content?.shipping_n_l}
                    </Box>{" "}
                    {BrandNameUpdate(content?.shipping_n_txt)}
                  </Box>
                </Box>
              ) : null}
              <Box
                className={
                  (Config?.IS_SIM_IN_HAND_ENABLED &&
                    props.simInHand &&
                    isSIMVerificationSuccess) ||
                  (props.simInHand &&
                    currentUser?.simId &&
                    currentUser?.isSimInHand)
                    ? styles.disable_section
                    : ""
                }
              >
                <Box
                  className={same_billing_address}
                  sx={{ padding: "0px 35px" }}
                >
                  <SwitchCust
                    data-testid="address_info_switch"
                    styles={{ mr: "0px" }}
                    sx={{
                      "& .MuiSwitch-colorPrimary .MuiSwitch-thumb": {
                        backgroundColor: "var(--text_color_16) !important",
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "var(--text_color_17) !important",
                      },
                      "& .Mui-checked .MuiSwitch-thumb": {
                        backgroundColor:
                          "var(--primary_brand_color) !important",
                      },
                      "& .Mui-checked + .MuiSwitch-track": {
                        backgroundColor:
                          "var(--primary_brand_color) !important",
                        opacity: "0.3957 !important",
                      },
                    }}
                    checked={isShippingSameAsBilling}
                    handleSwitchToggle={(e: any) => {
                      let updatedAddressData: any = {
                        billingAddress: formik.values.billingAddress,
                        isShippingSameAsBilling: e.target.checked,
                      };
                      formik.setFieldValue(
                        "isShippingSameAsBilling",
                        e.target.checked
                      );
                      if (e.target.checked) {
                        updatedAddressData = {
                          ...updatedAddressData,
                          shippingAddress: {
                            ...formik.values.billingAddress,
                            type: addressType.shipping,
                          },
                        };
                        formik.setFieldValue("shippingAddress", {
                          ...formik.values.billingAddress,
                          type: addressType.shipping,
                        });
                      }
                      dispatch(
                        updateFormValues({
                          ...formik.values,
                          ...updatedAddressData,
                        })
                      );
                    }}
                    name="isShippingSameAsBilling"
                  />
                  <Box
                    className={text}
                    sx={{ lineHeight: { xs: "21px", sm: "24px" } }}
                    data-testid="address_info_add_check_l"
                  >
                    {content?.add_check_l}
                  </Box>
                </Box>
                {!isShippingSameAsBilling && (
                  <Box>
                    <Box
                      className={bill_content_title1}
                      sx={{ padding: "0px 35px" }}
                      data-testid="address_info_add2_title"
                    >
                      {content?.add2_title}{" "}
                      <span
                        className={bill_content_subtext}
                        data-testid="address_info_add2_hint"
                      >
                        {content?.add2_hint}
                      </span>
                    </Box>
                    <Box className={bill_content_wrapper}>
                      <AddressField
                        content={content?.shipping}
                        formik={formik}
                        handleSelect={(e: any) =>
                          handleAddressSearchSelection(e, "shippingAddress")
                        }
                        handleAddressChange={handleShippingAddress}
                        addressObjName="shippingAddress"
                        onBlurCallback={updateFieldsState}
                        inputRefs={inputRefs?.shippingAddress}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
          {Config.IS_ESIM_SUPPORTED &&
          totalNumberOfPhysicalSims > 0 &&
          content?.note_list_i?.length > 0 ? (
            <Box className={note_div}>
              <Box
                component={"span"}
                sx={{ color: "var(--note_danger)", marginBottom: "0.5rem" }}
                data-testid="address_info_note_lbl_1"
              >
                {content?.note_label}
              </Box>
              <br />
              {content?.note_list_i.map((el: any) => (
                <Box
                  component={"span"}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                  key={el?.id}
                  data-testid={"address_info_note_list_" + el?.id}
                >
                  {el?.item}
                </Box>
              ))}
            </Box>
          ) : null}
          {Config?.IS_SIM_IN_HAND_ENABLED &&
          !simPreferenceStore?.[0]?.eSimCompatible ? (
            <>
              <Box
                className={line_checkout}
                data-testid="address_info_line_checkout"
              ></Box>
              <SimInHand
                simId={props.simId}
                simInHand={props.simInHand}
                setSimInHand={props.setSimInHand}
                setSimId={props.setSimId}
                onSubmitSimHandler={props.onSubmitSimHandler}
                simInHandContentState={props.simInHandContentState}
              />
            </>
          ) : null}
          {Config?.IS_AUTOPAY_ENABLED ? (
            <Box
              className={
                (Config?.IS_SIM_IN_HAND_ENABLED &&
                  props.simInHand &&
                  isSIMVerificationSuccess) ||
                (props.simInHand &&
                  currentUser?.simId &&
                  currentUser?.isSimInHand)
                  ? styles.disable_section
                  : ""
              }
              data-testid="address_info_auto_pay"
            >
              <AutoPayCard
                isAutoPay={props.isAutoPay}
                isSaveCard={props.isSaveCard}
                onChangeAutoPayment={props.onChangeAutoPayment}
                onChangeSaveCard={props.onChangeSaveCard}
                autoPayContentState={props.autoPayContentState}
                containerBorder={true}
                note={true}
              />
            </Box>
          ) : null}
          <PaymentButtons
            data-testid="address_info_pay_btn"
            paymentClicked={props.paymentClicked}
            formik={formik.handleSubmit}
            navigate={props.navigate}
            hideBackButton={hideBackButton}
            content={{
              ...content,
              submit_btn:
                Config.IS_ESIM_SUPPORTED && alleSimCompatible
                  ? content?.add_card_btn
                  : (Config?.IS_SIM_IN_HAND_ENABLED &&
                      props.simInHand &&
                      isSIMVerificationSuccess &&
                      !Config.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND) ||
                    (props.simInHand &&
                      currentUser?.simId &&
                      currentUser?.isSimInHand &&
                      !Config.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND)
                  ? content?.activate_btn
                  : content?.submit_btn,
            }}
            disabled={
              isLoadedPlanTaxCharges
                ? isLoadedPlanTaxCharges
                : props.paymentClicked ||
                  (Config?.IS_SIM_IN_HAND_ENABLED &&
                    props.simInHand &&
                    !currentUser?.isSimInHand &&
                    !isSIMVerificationSuccess) ||
                  errorOneTimeShippingTaxCharges ||
                  errorPlanTaxCharges
            }
          />
        </>
      )}
      <ZipcodeModal
        modalFg={zipcodeOpen}
        content={zipcodeContent ? zipcodeContent : zipcodeCacheContent}
        updateFg={true}
        closeHandler={closeZipModal}
        address={formik?.values?.billingAddress}
        showBox={showBox}
        setShowBox={setShowBox}
        clickHandler={clickHandler}
        showcheckcontent={false}
      />
    </Box>
  );
};

export default T7_AddressInfo;
