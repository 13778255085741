import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { updateSvgFillColor } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";

type Props = {
  content: any;
};
const ForSupport = (props: Props) => {
  const { content } = props;
  const { support, support_timing, chat_svg, phone_svg, email_svg } = content;
  const { subDomainData, errorSubDomainData } = useAppSelector(
      (state: any) => state.homepage
    );

  const formattedTime = `${subDomainData?.data?.customerSupport?.csChatStartTime.replace(":00", "").replace("am", " am").replace("pm", " pm")}`;
  const formattedEndTime = `${subDomainData?.data?.customerSupport?.csChatEndTime.replace(":00", "").replace("am", " am").replace("pm", " pm")}`;
  const formattedAvailability = `${subDomainData?.data?.customerSupport?.csChatStartDay} to ${subDomainData?.data?.customerSupport?.csChatEndDay} from ${formattedTime} - ${formattedEndTime} ${subDomainData?.data?.customerSupport?.timeZone}`;

  const onClickHandler = (path: any) => {
    if (path === RoutingURLs.chatbot) {
      window.open(RoutingURLs.chatbot, "_self");
      setTimeout(() => {
        window.history.replaceState(null, "", location.pathname);
      }, 1000);
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        background: "var(--light_primary_color) !important",
        width: "100%",
      }}
      data-testid="for_support_parent"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: { xs: "30px auto", md: "100px auto" },
          width: { xs: "94%", md: "70%" },
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {support?.map((element: any, index: any) => {
          const { img, path, text, id, timing } = element;
          return (
            <Box
              component={"a"}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "center" },
                alignItems: "center",
                gap: "15px",
                textDecoration: "none",
                padding: "10px",
              }}
              key={id}
              href={path === RoutingURLs.chatbot ? undefined : BrandNameUpdate(path)}
              data-testid={`for_support_path_${Number(index) + 1 || 1}`}
            >
              {/* <Box
                component="img"
                src={img?.data?.attributes?.url}
                alt={img?.data?.attributes?.alternativeText}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => onClickHandler(path)}
              /> */}
              <Box
                onClick={() => onClickHandler(path)}
                component="div"
                sx={{
                  cursor: "pointer",
                }}
                data-testid={`for_support_icon_${Number(index) + 1 || 1}`}
                dangerouslySetInnerHTML={{
                  __html: updateSvgFillColor(
                    index === 0
                      ? chat_svg
                      : index === 1
                      ? phone_svg
                      : email_svg,
                    "var(--primary_color)"
                  ),
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "var(--black)",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={() => onClickHandler(path)}
                  data-testid={`for_support_text_timing_${Number(index) + 1 || 1}`}
                >
                  {BrandNameUpdate(id !== support.length ? text : timing)}
                </Typography>
                {id !== support.length && subDomainData?.data?.customerSupport?.csChatStartTime &&  element.subText === "timing" ?(
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "12px",
                      lineHeight: "24px",
                      color: "var(--text_color)",
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                    onClick={() => onClickHandler(path)}
                    // dangerouslySetInnerHTML={{
                    //   __html: BrandNameUpdate(timing),
                    // }}
                    data-testid={`for_support_timing_${Number(index) + 1 || 1}`}
                  >
                    {formattedAvailability}
                    {/* {timing} */}
                  </Typography>
                ):(
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "12px",
                      lineHeight: "24px",
                      color: "var(--text_color)",
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                    onClick={() => onClickHandler(path)}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(timing),
                    }}
                    data-testid={`for_support_timing_${Number(index) + 1 || 1}`}
                  >
                    {/* {BrandNameUpdate(timing)} */}
                    {/* {timing} */}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ForSupport;
