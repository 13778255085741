import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ButtonCust } from "../../../widgets";
import envConfig from "../../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  BrandNameUpdate,
  displayPhoneNumberFormat,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import EnvConfig from "../../../../config/env.config.json";
import {
  activateLine,
  activationStatusCheck,
} from "../../../../features/activation/activationSlice";
import { customerGroup } from "../../../../features/account/accountSlice";
import Config from "../../../../config/env.config.json";
import { CustomerStatus } from "../../../../enums";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";

const T6_ActivationStep4 = (props: any) => {
  const { currentLine, content } = props;
  const {
    tick_icon,
    pay_success_title,
    pay_success_title1,
    pay_success_desc1,
    pay_success_desc,
    btn_txt,
    pay_success_desc2,
    loader,
    des1,
    des2,
    des3,
    des4,
    des5,
    des6,
    des7,
    des8,
    des9,
    success,
  } = content;
  const [paySuccess, setPaySuccess] = useState(true);
  const [actBtnLoader, setActBtnLoader] = useState(false);
  const [pageStatus, setPageStatus] = useState("default");
  const [newNumber, setNewNumber] = useState<any>(0);
  const { activateLineStatus, activateLineError } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const [ activationProgress, setActivationProgress ] = useState(false);

  const numOfLines: any = localStorage.getItem("numOfLines") || null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const simID = localStorage.getItem("simId");
  const { id, reachPlanId, imei, make, model, isMNP, mnpInfo, simId, esim } =
    currentLine || {};
  const { operator, oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);

  const activateLineHandler = () => {
    const skipHoldVal =
      EnvConfig.IS_ACT_SKIP_HOLD_VALUE && isMNP ? true : false;
    const newNumPy: any = {
      esim: esim,
      customerId: id,
      reachPlanId: reachPlanId,
      iccId: simID || simId,
      imei: imei,
      make: make,
      model: model,
      skipHold: true,
    };
    const mnpPy = {
      ...newNumPy,
      skipHold: skipHoldVal,
      isPort: true,
      numberToPort: oldNumber,
      oldCarrier: operator,
      oldCarrierAccountNumber: acctNum,
      password: oldPassword,
      oldZip: oldZip,
      portUpdate: false,
    };
    dispatch(activateLine({ payload: isMNP ? mnpPy : newNumPy }));
  };

  const continueHandler = () => {
    localStorage.removeItem("simId");
    setActBtnLoader(true);
    activateLineHandler();
  };
  const navigateToHome = () => {
    navigate(RoutingURLs.postActivationHome);
  };
  const navigationDecider = () => {
    setTimeout(() => {
      // console.log("Executed after 30 seconds");
      dispatch(
        activationStatusCheck({
          payload: { customerId: id },
        })
      ).then((res: any) => {
        const actStatusData = res?.payload?.data?.data || null;
        if (res?.payload?.status === 200) {
          if (
            res?.payload?.data?.data?.status ===
            CustomerStatus?.ACTIVATION_IN_PROGRESS
          ) {
            setNewNumber(actStatusData?.mobileNo);
            setPageStatus("activation in progress deley");
            setActivationProgress(true);
          } else if (
            res?.payload?.data?.data?.status === CustomerStatus?.ACTIVE
          ) {
            setPageStatus("success");
            setNewNumber(actStatusData?.mobileNo);
          } else {
            navigate(RoutingURLs.postActivationHome);
          }
        }
      });
    }, 30000);
  };

  useNonInitialEffect(() => {
    if(activationProgress){
      setTimeout(() => {
      setActivationProgress(false);
      dispatch(
        GetCustomer({
          username: encodeURIComponent(currentLine?.emailId),
        })
      ).then((res: any) => {
        if (res?.payload?.status === 200) {
          if (
            res?.payload?.data?.data?.status ===
            CustomerStatus?.ACTIVATION_IN_PROGRESS
          ) {
            setPageStatus("activation in progress deley");
            setActivationProgress(true);
          } else if (
            res?.payload?.data?.data?.status === CustomerStatus?.ACTIVE
          ) {
            setPageStatus("success");
          } else {
            navigate(RoutingURLs.postActivationHome);
          }
        }
      });
    }, 30000);
    }
  }, [activationProgress])

  useNonInitialEffect(() => {
    setPaySuccess(false);
    setActBtnLoader(false);
    if (customerDetails && customerDetails?.groupId) {
      dispatch(
        customerGroup({
          groupId: customerDetails.groupId,
        })
      );
    }
    if (activateLineError) {
      navigate(RoutingURLs.postActivationHome);
    } else {
      setPageStatus("activation in progress");
      navigationDecider();
    }
    // navigate(RoutingURLs.postActivationHome);
  }, [activateLineStatus, activateLineError]);

  return (
    <Box
      sx={{ mt: "-40px", paddingTop: "1px" }}
      data-testid="activation_step_4_main_wrapper"
    >
      {pageStatus === "default" && (
        <Box
          sx={{
            width: { xs: "75%", sm: "90%", md: "60%", lg: "45%" },
            margin: "0px auto 100px auto",
            textAlign: "center",
            padding: "30px",
            mt: "100px",
            borderRadius: "10px",
            background: "var(--white)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-testid="activation_step_4_wrapper"
          >
            <Box
              component={"img"}
              src={tick_icon?.data?.attributes?.url}
              alt={tick_icon?.data?.attributes?.alternativeText}
              width={"92px"}
              data-testid="activation_step_4_img"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px" },
                fontFamily: "var(--font_family_Bold)",
                lineHeight: "30px",
                marginLeft: "5px",
                fontWeight: 700,
                color: "var(--title-color_1)",
              }}
              data-testid="activation_step_4_title"
            >
              {pay_success_title1}
            </Typography>
          </Box>
          <Box sx={{ mt: "18px", mb: "10px" }}>
            <Typography
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                fontFamily: "var(--font_family_Regular)",
                lineHeight: "21px",
                width: { xs: "100%", sm: "100%", md: "90%" },
                color: "var(--text_color_12)",
                mx: "auto",
              }}
              data-testid="activation_step_4_desc"
              dangerouslySetInnerHTML={{
                __html: BrandNameUpdate(pay_success_desc2),
              }}
            ></Typography>
          </Box>
          <Box>
            <ButtonCust
              sx={{
                fontSize: "16px",
                lineHeight: "28px",
                fontFamily: "var(--font_family_Medium)",
                mt: "20px",
                width: "300px",
              }}
              variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              onClick={() => continueHandler()}
              loading={actBtnLoader}
              data-testid="activation_step_4_btn"
            >
              {btn_txt}
            </ButtonCust>
          </Box>
        </Box>
      )}
      {pageStatus === "activation in progress" && (
        <Box
          sx={{
            width: { xs: "75%", sm: "90%", md: "60%", lg: "35%" },
            margin: "0px auto 100px auto",
            textAlign: "center",
            padding: "30px",
            mt: "100px",
            borderRadius: "10px",
            background: "var(--white)",
          }}
        >
          <Box
            component="img"
            alt={loader?.data?.attributes?.alternativeText}
            src={loader?.data?.attributes?.url || ""}
            width="150px"
            height="80px"
          />
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "24px" },
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "30px",
              // marginLeft: "5px",
              fontWeight: 700,
              color: "var(--title-color_1)",
            }}
          >
            {des1}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              fontFamily: "var(--font_family_Regular)",
              lineHeight: "21px",
              // width: { xs: "100%", sm: "100%", md: "90%" },
              color: "var(--text_color_12)",
              // mx: "auto",
              marginTop: "10px",
            }}
          >
            {des2}
          </Typography>
        </Box>
      )}
      {pageStatus === "activation in progress deley" && (
        <Box
          sx={{
            width: { xs: "75%", sm: "90%", md: "60%", lg: "45%" },
            margin: "0px auto 100px auto",
            textAlign: "center",
            padding: "30px",
            mt: "100px",
            borderRadius: "10px",
            background: "var(--white)",
          }}
        >
          <Box
            component="img"
            alt={loader?.data?.attributes?.alternativeText}
            src={loader?.data?.attributes?.url || ""}
            width="150px"
            height="80px"
          />
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "24px" },
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "30px",
              // marginLeft: "5px",
              fontWeight: 700,
              color: "var(--title-color_1)",
            }}
          >
            {des6}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              fontFamily: "var(--font_family_Regular)",
              lineHeight: "21px",
              // width: { xs: "100%", sm: "100%", md: "90%" },
              color: "var(--text_color_12)",
              // mx: "auto",
              marginTop: "15px",
              marginBottom: "20px",
            }}
            dangerouslySetInnerHTML={{
              __html: des8.replace(
                /mobile/g,
                displayPhoneNumberFormat(newNumber) || ""
              ),
            }}
          ></Typography>
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => navigateToHome()}
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              fontFamily: "var(--font_family_Medium)",
              // mt: "20px",
              width: "300px",
            }}
          >
            {des7}
          </ButtonCust>
        </Box>
      )}
      {pageStatus === "success" && (
        <Box
          sx={{
            width: { xs: "75%", sm: "90%", md: "60%", lg: "45%" },
            margin: "0px auto 100px auto",
            textAlign: "center",
            padding: "30px",
            mt: "100px",
            borderRadius: "10px",
            background: "var(--white)",
          }}
        >
          <Box
            component="img"
            alt={success?.data?.attributes?.alternativeText}
            src={success?.data?.attributes?.url || ""}
            width="50px"
            height="50px"
          />
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "24px" },
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "30px",
              // marginLeft: "5px",
              fontWeight: 700,
              color: "var(--title-color_1)",
              marginTop: "10px",
            }}
          >
            {des3}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              fontFamily: "var(--font_family_Regular)",
              lineHeight: "21px",
              // width: { xs: "100%", sm: "100%", md: "90%" },
              color: "var(--text_color_12)",
              // mx: "auto",
              marginTop: "15px",
              marginBottom: "20px",
            }}
            dangerouslySetInnerHTML={{
              __html: des4.replace(
                /mobile/g,
                displayPhoneNumberFormat(newNumber) || ""
              ),
            }}
          ></Typography>
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => navigateToHome()}
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              fontFamily: "var(--font_family_Medium)",
              // mt: "20px",
              width: "300px",
            }}
          >
            {des5}
          </ButtonCust>
        </Box>
      )}
    </Box>
  );
};

export default T6_ActivationStep4;
