import AppConfig from "../../../../../config/app.config.json";
import { useAppDispatch } from "../../../../../app/hooks";
import { getILDTaxCharges } from "../../../../../features/checkout/checkoutSlice";
import { getCC } from "../../../../../features/account/accountSlice";
import { isTemplateExisits } from "../../../../../utils/commonFunctions";

const AddCreditsComp = isTemplateExisits(
  AppConfig.PostActivationServices.ILDAddCreditModal
)
  ? require(`./${AppConfig.PostActivationServices.ILDAddCreditModal.template}_AddCredits`)
      .default
  : null;

const AddCredits = (props: any) => {
  const {
    setOpenCreditModal,
    userLineData,
    setIsCreditPayment,
    creditAmount,
    setcreditAmount,
    configKeys,
  } = props;
  const dispatch = useAppDispatch();

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;

  const addCreditIncDecHandler = (type: string) => {
    if (
      type === "inc" &&
      creditAmount < Number(configKeys?.ILD_MAX_CREDIT_AMOUNT)
    ) {
      setcreditAmount(
        (prevState: any) =>
          prevState + Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
      );
    } else if (
      type === "dec" &&
      creditAmount > Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
    ) {
      setcreditAmount(
        (prevState: any) =>
          prevState - Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
      );
    }
  };
  const handleConfirmPurchase = () => {
    const address = {
      name: `${userLineData.firstName} ${userLineData.lastName}`,
      address1: userLineData?.addresses[0]?.address1,
      city: userLineData?.addresses[0]?.city,
      country: userLineData?.addresses[0]?.country,
      state: userLineData?.addresses[0]?.state,
      type: "billing",
      zip: userLineData?.addresses[0]?.zip,
    };
    dispatch(
      getCC({
        customerId: currentdetails && currentdetails.id,
      })
    );
    dispatch(
      getILDTaxCharges({
        address,
        amount: creditAmount,
        additionalLines: userLineData?.totalActiveLines,
      })
    );
    setOpenCreditModal(false);
    setIsCreditPayment(true);
  };
  return (
    <>
      <AddCreditsComp
        {...props}
        handleConfirmPurchase={handleConfirmPurchase}
        addCreditIncDecHandler={addCreditIncDecHandler}
      />
    </>
  );
};

export default AddCredits;
