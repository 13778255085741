import { Box, Typography, Link } from "@mui/material";
import styles from "./ActivationFailedModal.module.scss";
import { ButtonCust, SimpleModal } from "../../../widgets";
import config from "../../../../config/env.config.json";
import { logout } from "../../../../services/Firebase";
import { brandNames } from "../../../../enums";
import Config from "../../../../config/env.config.json";

type Props = {
  showModal: any;
  openSupportModal?: any;
  content: any;
  currentLine?: any;
  closeActivationFailModal?: any;
};

export const ActivationFailedModal = (props: Props) => {
  const {
    showModal,
    openSupportModal,
    content,
    currentLine,
    closeActivationFailModal,
  } = props;

  const { btn, customer_txt, desc_text, title, m_img } = content?.act_fail;
   let userName:string = currentLine?.firstName + currentLine?.lastName
  return (
    <SimpleModal
      onCloseModal={currentLine.isPrimary ? false : closeActivationFailModal}
      showClose={false}
      isShowModal={showModal}
      sx={{ width: { xs: "95%", sm: "60%", md: "45%", lg: "45%", xl: "45%", 
      "&.MuiGrid-root": {
        borderRadius: "30px !important"
    }, } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        pt={0.5}
        alignItems="center"
        data-testid="activation_step_act_fail_wrapper"
      >
          <Box display="flex" alignItems="center">
          <Box
            component="img"
            className={styles.activation_fail_icon}
            src={m_img?.data?.attributes?.url}
            alt={m_img?.data?.attributes?.alternativeText}
            data-testid="activation_step_act_fail_img"
          />
        </Box>
        <Box
        component="h3"
          sx={{
            fontSize: "30px",
            color: "#000000",
            fontFamily: "var(--font_family_bold)",
            my:"26px"
            // fontWeight: "var(--font_weight_4)",
          }}
          data-testid="activation_step_act_fail_title"
        >
          {currentLine?.isMNP && Config.DOMAIN === brandNames.WOW
            ? "Porting failed!"
            : title}
        </Box>
        <Box
          sx={{
            fontSize: "16px",
            color: "var(--text_color)",
            textAlign: "center",
            fontFamily:"var(--font_family_Medium)",
            mb:"26px",
            px:"10%"
          }}
          data-testid="activation_step_act_fail_desc"
        >
          {desc_text.replace("userName", userName)}
        </Box>
          <Box component="span" sx={{display:"flex"}} data-testid="activation_step_act_fail_link">
          <img
            src={content?.phone_Icon_img?.data?.[0]?.attributes?.url}
            alt={content?.phone_Icon_img?.data?.[0]?.attributes?.alternativeText}
            style={{ width: "20px", height: "20px", paddingRight: "10px", marginTop:"3px" }}
            data-testid="activation_step_act_fail_phone_icon"
          />
           <Link href={`tel:${content?.res.phone_details}`} sx={{color: "var(--text_color)", fontSize:"18px", textAlign:"center", fontFamily:"var(--font_family_Semibold)", textDecoration:"none"}} data-testid="activation_step_act_fail_link_text">
          {customer_txt}
          </Link>
          </Box>
          {currentLine.isPrimary && (
            <ButtonCust data-testid="activation_step_act_fail_btn" variantType={config.PRIMARY_BUTTON_TYPE} sx={{ width: { md: "250px" }, mb:"20px", mt:"26px" }} onClick={() => logout()}>
              {btn}
            </ButtonCust>
           )}
      </Box>
    </SimpleModal>
  );
};
