import { Grid, Modal, Box, Typography } from "@mui/material";
import {
  AccountCircle,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import styles from "./HeaderModal.module.scss";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { resetDataGetCustomer } from "../../../features/checkout/checkoutSlice";
import { useAppDispatch } from "../../../app/hooks";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import { toTitleCase } from "../../../utils/commonFunctions/titleCase";
import EnvConfig from "../../../config/env.config.json";
interface ModalProps {
  display: boolean;
  onClose: any;
  headerContent: any;
  handleNavigation: any;
  authUser?: any;
  isCheckoutHeader?: boolean;
  primaryHeaders?: Array<any>;
  secondaryHeaders?: Array<any>;
  loginItem: any;
  logoutHandler: any;
  setAuthUser?: any;
  isHomePageHeader?: boolean;
  isInternationService?: boolean;
  iscoverageMap?: boolean;
  isBillCalculator?: boolean;
  extraHeaders?: any;
  mediaHeaders?: any;
  footerHeaders?: any;
}
interface LinkItemI {
  children: any;
  onClickItem: any;
  showActiveIndicator: boolean;
  addClass?: any;
  style5?: any;
  content?: any;
  showExtraArrow?: boolean;
  isBorder?: boolean;
}
const {
  link_item,
  active_link_item,
  header_modal,
  dialog_container,
  header_container,
  secondary_header,
  secondary_header_text_style,
  primary_header_text_style,
} = styles;

const LinkItem = (props: LinkItemI) => {
  const {
    children,
    onClickItem,
    showActiveIndicator,
    addClass,
    content,
    showExtraArrow,
    isBorder,
  } = props;
  console.log(content, "content");

  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const { subDomainData } = useAppSelector((state: any) => state.homepage);
  return (
    <>
      {children?.toLowerCase() === "cart" ||
      (children?.toLowerCase() === "international services" &&
        !configKeys?.INTERNATIONAL_CALLING_ENABLED) ||
      (!subDomainData?.data?.shopwareAccessKey &&
        (children?.toLowerCase() === "wishlist" ||
          children?.toLowerCase() === "my orders" ||
          children?.toLowerCase() === "phones")) ? null : (
        <Box
          className={
            showActiveIndicator
              ? `${link_item} ${active_link_item} ${addClass}`
              : `${link_item} ${addClass} `
          }
          sx={{
            py: "10px",
            borderBottom:
              showExtraArrow && isBorder
                ? "2px solid var(--lightgrey_6)"
                : "none",
            display: "flex",
          }}
          onClick={() => {
            onClickItem();
          }}
        >
          <Box>{children}</Box>
          {showExtraArrow && (
            <Box
              component="img"
              alt={content?.right_icon?.data?.attributes?.alternativeText}
              src={content?.right_icon?.data?.attributes?.url || ""}
            ></Box>
          )}
        </Box>
      )}
    </>
  );
};
const ExpandableLinkItem = (props: any) => {
  const {
    handleToggleItem,
    itemName,
    menuItems,
    isToggeledItem,
    closeMenu,
    content,
    addClass,
  } = props;

  return (
    <>
      <Box
        className={
          menuItems
            ?.map((item: any) => item.navigationPath)
            .includes(window.location.pathname)
            ? `${link_item} ${active_link_item} ${addClass}`
            : `${link_item} ${addClass}`
        }
        onClick={handleToggleItem}
        sx={{ py: "10px" }}
      >
        {itemName}
        {isToggeledItem ? (
          <KeyboardArrowUp onClick={closeMenu} />
        ) : (
          <KeyboardArrowDown />
        )}
      </Box>
      {isToggeledItem && (
        <Box>
          {menuItems?.length > 0 &&
            menuItems?.map((item: any, index: number) => {
              return (
                <Box
                  key={index}
                  className={
                    window.location.pathname === item.navigationPath
                      ? `${link_item} ${active_link_item}`
                      : link_item
                  }
                  onClick={item.action}
                  sx={{ py: "10px" }}
                >
                  {item.label}
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};
export const HeaderModal = (props: ModalProps) => {
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  const { subDomainData } = useAppSelector((state: any) => state.homepage);
  const {
    display,
    onClose,
    headerContent = {},
    handleNavigation,
    authUser,
    isCheckoutHeader,
    primaryHeaders,
    secondaryHeaders,
    loginItem,
    logoutHandler,
    setAuthUser,
    isHomePageHeader,
    isInternationService,
    iscoverageMap,
    isBillCalculator,
    mediaHeaders,
    extraHeaders,
    footerHeaders,
  } = props;

  const [selectedItem, setSelectedItem] = useState<string | null>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );

  const getUsername = () => {
    let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerInfo");
    ssoCustomerInfo = JSON.parse(ssoCustomerInfo || "{}");
    let customerDetails: any = localStorage.getItem("customerDetail");
    customerDetails = JSON.parse(customerDetails || "{}");
    if (authUser) {
      return authUser.displayName || authUser.email || "Guest";
    } else {
      return ssoCustomerInfo?.firstName || customerDetails?.name || "Guest";
    }
  };

  const renderHeaderItemContent = (
    item: any,
    index: number,
    showActiveIndicator: boolean = true,
    addClass?: any,
    showArrow?: boolean,
    isBorder?: boolean
  ) => {
    if (item.subMenu && item.subMenu.length > 0) {
      const menuActionItems = item?.subMenu?.map((subItem: any) => ({
        label: toTitleCase(subItem.itemName),
        action: () => {
          subItem?.is_gaEventReqd &&
            gtmTagManager({
              event: subItem?.ga_tagName,
              click_section: "header",
            });
          setSelectedItem(null);
          handleNavigation(subItem);
        },
        navigationPath: subItem.navigationPath,
      }));
      return (
        <ExpandableLinkItem
          key={`header-item-${index}`}
          itemName={item.itemName}
          isToggeledItem={item.itemName === selectedItem}
          handleToggleItem={() => {
            if (selectedItem) {
              setSelectedItem(null);
            } else {
              setSelectedItem(item.itemName);
            }
          }}
          handleNavigation={handleNavigation}
          menuItems={menuActionItems}
          closeMenu={() => {
            setSelectedItem(null);
          }}
          buttonId={`btn-key${index}`}
          menuId={`menu-key${index}`}
          content={headerContent}
          showExtraArrow={showArrow}
          addClass={addClass}
        />
      );
    } else {
      return (
        <LinkItem
          onClickItem={() => {
            item?.is_gaEventReqd &&
              gtmTagManager({
                event: item?.ga_tagName,
                click_section: "header",
              });
            handleNavigation(item);
          }}
          showActiveIndicator={
            showActiveIndicator &&
            window.location.pathname === item.navigationPath
          }
          key={`header-item-${index}`}
          addClass={addClass}
          content={headerContent}
          showExtraArrow={showArrow}
          isBorder={isBorder}
        >
          {toTitleCase(item.itemName)}
        </LinkItem>
      );
    }
  };
  return (
    <Modal
      open={display}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={header_modal}
      sx={{
        ouline: "none",
        display: { xs: "block", md: "none" },
        overflow: "scroll",
        ".MuiBackdrop-root": {
          backgroundColor: "transparent !important",
        },
      }}
      disableAutoFocus
    >
      <>
        <Grid
          display="flex"
          flexDirection="column"
          className={header_container}
          // justifyContent="space-between"
          sx={{
            // width: { xs: "200px !important", sm: "300px !important" },
            backgroundColor: "var(--white) !important",
            float: "right",
            margin: "0px",
            boxShadow: "var(--header_container_box_shadow) !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 20px 0px 30px",
                flexDirection: "row",
              }}
            >
              {/* {authUser ? (
                <Box
                  sx={{ display: "flex", pt: "12px" }}
                  onClick={() => navigate(RoutingURLs?.profile)}
                >
                  <Box
                    component={"img"}
                    alt={
                      headerContent?.mweb_prof_ic?.data?.attributes
                        ?.alternativeText
                    }
                    src={
                      headerContent?.mweb_prof_ic?.data?.attributes?.url || ""
                    }
                  />
                  <Box className={styles.label}>
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                        fontSize: "16px",
                        lineHeight: "30px",
                        color:
                          window.location.pathname === RoutingURLs?.profile
                            ? "var(--primary_color)"
                            : "var(--black)",
                        ml: "10px",
                      }}
                    >
                      {getUsername()}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{ display: "flex", pt: "10px" }}
                    onClick={() => {
                      handleNavigation(headerContent?.nav_link1);
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontSize: "16px",
                        lineHeight: "28px",
                        fontWeight: "var(--font_weight_1)",
                        color: "var(--black)",
                        pr: "10px",
                      }}
                    >
                      {headerContent?.nav_link1?.itemName}
                    </Typography>
                    <Box
                      component="img"
                      alt={
                        headerContent?.right_icon?.data?.attributes
                          ?.alternativeText
                      }
                      src={
                        headerContent?.right_icon?.data?.attributes?.url || ""
                      }
                    />
                  </Box>
                </>
              )} */}
              {authUser ? (
                <Box
                  sx={{ display: "flex", pt: "12px" }}
                  onClick={() => navigate(RoutingURLs?.profile)}
                >
                  <Box
                    component="img"
                    alt={
                      headerContent?.profile_logo?.data?.attributes
                        ?.alternativeText || ""
                    }
                    src={
                      headerContent?.profile_logo?.data?.attributes?.url || ""
                    }
                    width={"20px"}
                    height={"20px"}
                    marginTop={"5px"}
                  />
                  <Box className={styles.label}>
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                        fontSize: "14px",
                        lineHeight: "30px",
                        color:
                          window.location.pathname === RoutingURLs?.profile
                            ? "var(--primary_color)"
                            : "var(--dark-charcoal-fade)",
                        ml: "10px",
                      }}
                    >
                      {getUsername()}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{ display: "flex", pt: "10px" }}
                    onClick={() => {
                      handleNavigation(headerContent?.nav_link1);
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontSize: "14px",
                        lineHeight: "28px",
                        fontWeight: "var(--font_weight_1)",
                        color: "var(--dark-charcoal-fade)",
                        pr: "10px",
                      }}
                    >
                      {headerContent?.nav_link1?.itemName}
                    </Typography>
                    {/* <Box
                      component="img"
                      alt={
                        headerContent?.right_icon?.data?.attributes
                          ?.alternativeText || "Icon"
                      }
                      src={
                        headerContent?.right_icon?.data?.attributes?.url || ""
                      }
                    /> */}
                    <Box
                      component="img"
                      alt={
                        headerContent?.profile_logo?.data?.attributes
                          ?.alternativeText || ""
                      }
                      src={
                        headerContent?.profile_logo?.data?.attributes?.url || ""
                      }
                      width={"20px"}
                      height={"20px"}
                      marginTop={"5px"}
                    />
                  </Box>
                </>
              )}

              <Close
                sx={{
                  float: "right",
                  height: "30px",
                  width: "30px",
                  cursor: "pointer",
                  marginTop: "14px",
                }}
                onClick={onClose}
              />
            </Grid>

            <Grid
              container
              display="flex"
              flexDirection="column"
              className={dialog_container}
              sx={{
                paddingBottom: "0px !important",
              }}
            >
              <Grid item xs={12} textAlign="right">
                <Typography
                  sx={{
                    borderBottom: " 2px solid var(--navy)",
                    opacity: 0.1,
                    margin: "12px 0px 3px",
                  }}
                ></Typography>
              </Grid>

              <Grid item sx={{ padding: "0px 30px" }}>
                {primaryHeaders?.map((headerItem: any, index: number) => {
                  return renderHeaderItemContent(
                    headerItem,
                    index,
                    true,
                    primary_header_text_style,
                    true,
                    primaryHeaders?.length > index + 1 ? true : false
                  );
                })}
              </Grid>
              <Grid
                item
                sx={{ padding: "0px 30px" }}
                className={secondary_header}
              >
                {secondaryHeaders?.map((headerItem: any, index: number) =>
                  renderHeaderItemContent(
                    headerItem,
                    index,
                    false,
                    secondary_header_text_style,
                    false,
                    secondaryHeaders?.length > index + 1 ? true : false
                  )
                ) || null}
                {/* {isCheckoutHeader ? (
              <>
                <AccountCircle style={{ color: "var(--text_color)" }} />
                <Box className={styles.label} pt="20px">
                  {authUser?.name?.split(" ")[0]}
                </Box>
                <LinkItem
                  showActiveIndicator={false}
                  addClass={secondary_header_text_style}
                  onClickItem={() => navigate(RoutingURLs.home)}
                >
                  {BrandNameUpdate(seoData?.brandName)}
                </LinkItem>
              </>
            ) : null} */}
                {isCheckoutHeader &&
                  headerContent?.support_details?.length > 0 &&
                  headerContent?.support_details?.map((item: any) => {
                    return (
                      <>
                        <Box sx={{ display: "flex" }}>
                          <a
                            href={`tel:${item?.label}`}
                            style={{
                              display: "flex",
                              color: "var(--white)",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{ mr: "5px" }}
                              component="img"
                              alt={item?.description}
                              className="modalImage"
                              src={item?.logo?.data?.attributes?.url}
                            />
                            <Typography
                              sx={{
                                color: "var(--dark-charcoal-fade)",
                                fontSize: "14px",
                                fontWeight: "var(--font_weight_0)",
                                lineHeight: "28px",
                                fontFamily: "var(--font_family_Medium)",
                              }}
                            >
                              {item?.label}
                            </Typography>
                          </a>
                        </Box>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>

          {authUser ? (
            <Grid mb={"auto"} sx={{ padding: "0px 30px" }}>
              <Grid item xs={12} textAlign="right">
                <Typography
                  sx={{
                    borderBottom: " 2px solid var(--navy)",
                    opacity: 0.1,
                    margin: "14px 0px",
                  }}
                ></Typography>
              </Grid>
              <Grid>
                {loginItem?.subMenu?.map((sItem: any) => (
                  <Box
                    onClick={() => {
                      handleNavigation(sItem);
                      if (sItem.itemName.toLowerCase() === "logout") {
                        localStorage.clear();
                        localStorage.setItem(
                          "globalValues",
                          JSON.stringify(globalVal)
                        );
                        localStorage.setItem(
                          "subDomainData",
                          JSON.stringify(subDomainData?.data)
                        );
                        logoutHandler(RoutingURLs.home);
                        setAuthUser(null);
                        dispatch(resetDataGetCustomer());
                      }
                    }}
                    key={`sub-header-item-${sItem.id}`}
                  >
                    {sItem.itemName.toLowerCase() === "logout" && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontSize: "14px",
                            lineHeight: "28px",
                            fontWeight: "var(--font_weight_1)",
                            color: "var(--dark-charcoal-fade)",
                            pr: "10px",
                          }}
                        >
                          {sItem?.itemName}
                        </Typography>
                        <Box
                          component={"img"}
                          alt={
                            headerContent?.logout_icon?.data?.attributes
                              ?.alternativeText
                          }
                          src={
                            headerContent?.logout_icon?.data?.attributes?.url ||
                            ""
                          }
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </>
    </Modal>
  );
};
