import { isTemplateExisits } from "../../../../utils/commonFunctions";
import AppConfig from "../../../../config/app.config.json";

const IRDataUsageTemplate = isTemplateExisits(
  AppConfig.PostActivationServices.IRDataUsage
)
  ? require(`./${AppConfig.PostActivationServices.IRDataUsage.template}_IRDataUsage`)
      .default
  : null;

const IRDataUsage = (props: any) => {
  return <IRDataUsageTemplate {...props} />;
};

export default IRDataUsage;
