import { CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonCust } from "../../../../widgets";
import styles from "./S3_AddCreditPayment.module.scss";
import { useAppSelector } from "../../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import PaymentReviewCard from "../../../IRILDComponents/PaymentReviewCard/PaymentReviewCard";
import { getResolutionDetails } from "../../../../../utils/commonFunctions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function AddCreditPayment(props: any) {
  const { ILDTaxCharges, isLoadedILDTaxes } = useAppSelector(
    (state: any) => state.checkout
  );

  const { content, totalCost, userLineData, setIsCreditPayment, isLoader } =
    props;

  const {
    visa_card,
    change_card,
    back_icon,
    back_txt,
    title,
    credit_payment,
    american_express,
    discover,
    jcb,
    master_card,
  } = content;

  const {
    taxAndFees,
    nameTxt,
    totalDue,
    btnTxt,
    mockCard,
    changeCardTxt,
    creditTitle,
    creditsFor,
    ild_title,
    ild_desc,
  } = credit_payment;

  const { fullName } = styles;
  const { mobile } = getResolutionDetails();

  return (
    <>
      {!isLoadedILDTaxes ? (
        <Box width={{ xs: "100%", sm: "60%", md: "35%", lg: "35%" }} data-testid="add_cred_pay_wrapper">
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "30px",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& span": {
                fontWeight: "var(--font_weight_5)",
              },
            }}
          >
            <Box
              component="span"
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                left: { xs: "17px", md: "103px" },
                top: { xs: "105px", md: "auto" },
                cursor: "pointer",
                fontWeight: "var(--font_weight_3)",
              }}
              onClick={() => setIsCreditPayment(false)}
              data-testid="add_cred_pay_back"
            >
              {!mobile ? (
                <ArrowBackIosIcon
                  sx={{ width: "18px", fontSize: "1.125rem" }}
                  data-testid="add_cred_pay_back_icon"
                />
              ) : (
                <Box
                  component="img"
                  src={back_icon?.data.attributes.url}
                  alt={back_icon?.data.attributes.alternativeText}
                  data-testid="add_cred_pay_back_icon"
                />
              )}
              {!mobile && (
                <Box
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1) !important",
                    fontSize: "18px",
                    lineHeight: "30px",
                  }}
                  component="span"
                  data-testid="add_cred_pay_back_txt"
                >
                  {back_txt}
                </Box>
              )}
            </Box>
            <Box
              component="span"
              style={{ margin: "auto" }}
              sx={{
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: { xs: "24px", md: "24px" },
                color: "var(--title-color_1)",
              }}
              data-testid="add_cred_pay_ild_title"
            >
              {ild_title}
            </Box>
          </Typography>
          <Box
            sx={{
              justifyContent: "center",
              display: " flex",
              m: { xs: "16px  0px", sm: "16px 0px 10px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "var(--font_family_Medium) !important",
                fontSize: { xs: "14px", md: "18px" },
                color: "var(--text_color)",
              }}
              data-testid="add_cred_pay_ild_dec"
            >
              {ild_desc}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: "35px",
              mt: { xs: "20px", sm: "18px" },
            }}
          >
            <Typography className={fullName} data-testid="add_cred_pay_cred_for">
              {creditsFor}
              <Box
                component="span"
                sx={{
                  textTransform: "capitalize",
                  pl: "5px",
                  fontFamily: "var(--font_family_Bold)",
                  color: "var(--primary_color)",
                  fontSize: "16px",
                  background: "var(--switch_tab_bg_color)",
                  border: "1px solid var(--primary_color)",
                  margin: " 0px 10px",
                  padding: " 0px 5px",
                }}
                data-testid="add_cred_pay_fname"
              >
                {userLineData?.firstName?.toLowerCase()}{" "}
                {userLineData?.lastName?.toLowerCase()}
              </Box>
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"40px"}>
            <PaymentReviewCard
              firstRowTitle={nameTxt}
              firstRowValue={totalCost}
              secondRowTitle={taxAndFees}
              secondRowValue={ILDTaxCharges?.totalTax}
              thirdRowTitle={totalDue}
              mockCard={mockCard}
              visa_card={visa_card?.data.attributes}
              confirmBtn={btnTxt}
              carbon_edit={change_card?.data?.attributes}
              totalCost={totalCost}
              changeCardText={changeCardTxt}
              onClick={props.onClick}
              loading={isLoadedILDTaxes}
              btnLoading={isLoader}
              otherProps={content}
              american_express={american_express}
              discover={discover}
              jcb={jcb}
              master_card={master_card}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
            data-testid="add_cred_pay_progress"
          />
        </Box>
      )}
    </>
  );
}

export default AddCreditPayment;
