import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { PlansContainer } from "./PlansContainer";
// import PlansResponse from "../../config/data.json";
import { sortByKey } from "../../utils/commonFunctions/SortByKey";
import classes from "./PlanChangeDialog.module.scss";
import Config from "../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  updatePlanDetails,
  updatePrimaryLineType,
} from "../../features/checkout/checkoutSlice";
import {
  PopperCust,
  IncDecButtonCmp,
  ButtonCust,
  NotificationCust,
} from "../widgets";
import { ChangeLinePreferenceDialog } from "../ChangeLinePreferenceDialog/ChangeLinePreferenceDialog";
import { SimTypes, planModalType } from "../../features/checkout/Types";
import EsimIMEI from "../common/EsimIMEI/EsimIMEI";
import { ChangeSimPreferenceDialog } from "../ChangeSimPreferenceDialog/ChangeSimPreferenceDialog";
import firebase from "../../adapters/firebase";
import EnvConfig from "../../config/env.config.json";
import { CarrierTypes } from "../../enums";
import {
  addCartItems,
  updateCartItems,
} from "../../features/shopWare/shopWareSlice";
import { updateCartPayload } from "../../utils/commonFunctions";
import AddLine_ChangePlan from "../common/AddLine_ChangePlan/AddLine_ChangePlan";
import { reachPlanData } from "../../features/homepage/homepageSlice";

interface IPlanChangeDialog {
  content: any;
  simPrefenceContent?: any;
  showSimChangePrefernceDialog?: boolean;
  onCloseSimChangePrefernceDialog?: any;
  simPreference: any;
  setSimPreference: any;
  simCharges: number;
  isAllowedMultipleLineSelectionForSIMInHand?: any;
}
const incDecCustomStyles = {
  inputStyles: {
    color: "var(--primary_color)",
    fontWeight: "var(--font_weight_2)",
    fontFaamily: "var(--font_family_Semibold",
    padding: "0 8px",
  },
  buttonStyles: {
    fontFamily: "var(--font_family_Semibold)",
    fontWeight: "var(--font_weight_2)",
    fontSize: "17px",
    height: "24px",
    paddingTop: 0,
  },
};
export const PlanChangeDialog = ({
  content,
  simPrefenceContent,
  showSimChangePrefernceDialog,
  onCloseSimChangePrefernceDialog,
  simPreference,
  setSimPreference,
  simCharges,
  isAllowedMultipleLineSelectionForSIMInHand,
}: IPlanChangeDialog) => {
  const { planData, subDomainData } = useAppSelector(
    (state: any) => state.homepage
  );
  useEffect(() => {
    if(!planData){
      dispatch(reachPlanData({}));
    }
  }, []);
  let plandata =
    planData && planData.data && planData.data.length > 0
      ? sortByKey(planData && planData.data, "planData")
      : [];
  const dispatch = useAppDispatch();
  const [display, setDisplay] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const { planDetails } = useAppSelector((state) => state.checkout);
  const [popperTarget, setPopperTarget] = useState(null);
  const [showLineChangePrefernceDialog, setShowLineChangePrefernceDialog] =
    useState(false);
  const [numberOfLinesToBeUpdated, setNumberOfLinesToBeUpdated] = useState(0);
  const [displayImeiCheck, setDisplayImeiCheck] = useState(false);
  const [simType, setSimType] = useState<SimTypes>(SimTypes.sim);
  //For change plan
  const [esimClickedIndex, setEsimClickedIndex] = useState<any>([]);
  const [sameArr, setSameArr] = useState<any>({});
  const [increaseLine, setIncreaseLine] = useState(false);
  const [eSimClicked, setESimClicked] = useState<boolean>(false);
  const [simClicked, setSimClicked] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [modalType, setModalType] = useState(planModalType.changePlan);
  const handlePopOverOpen = (e: any) => {
    setPopperTarget(e.currentTarget);
  };
  const handlePopOverClose = () => {
    setPopperTarget(null);
  };
  useEffect(() => {
    let customerDetails: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    if (!customerDetails) {
      let planData: any = localStorage.getItem("reachSelectedPlanDetail");
      const activePlan = JSON.parse(planData)?.plan;
      const lines = JSON.parse(planData)?.line;
      dispatch(
        updatePlanDetails({ numberOfLines: lines, selectedPlan: activePlan })
      );
    }
  }, []);
  const checkCompatibility = () => {
    localStorage.setItem("simPreference", JSON.stringify(simPreference));
    let id: any = [];
    simPreference.map((el: any) => {
      if (el?.value === SimTypes.eSim && !el?.isPrimaryLine) {
        id.push(el?.id);
      }
    });
    setEsimClickedIndex(id);
    setDisplayImeiCheck(true);
    onCloseSimChangePrefernceDialog();
  };
  const openDialog = () => {
    setDisplay(true);
  };
  const closeDialog = () => {
    setDisplay(false);
    setSelectedPlan(undefined);
  };
  const plans = plandata;
  const handlePlanSelection = (newPlan: any) => {
    setSelectedPlan(newPlan);
  };
  const updatePlan = () => {
    // nned to add
    if (selectedPlan) {
      let numberOfLines = planDetails.numberOfLines;
      if (numberOfLines > selectedPlan.maxLines) {
        numberOfLines = selectedPlan.maxLines;
      }
      dispatch(
        updatePlanDetails({
          selectedPlan: selectedPlan,
          numberOfLines: numberOfLines,
        })
      );

      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          line: numberOfLines,
          plan: selectedPlan,
        })
      );
      localStorage.setItem(
        "reachSelectedPlanLine",
        JSON.stringify(numberOfLines)
      );
      if (Config?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
        addCartItemsToShopware();
      }
      closeDialog();
    }
  };

  const addCartItemsToShopware = () => {
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = JSON.parse(planDetails);
    let simPreferencePayload: any = localStorage.getItem("simPreference");
    simPreferencePayload = JSON.parse(simPreferencePayload);
    const linePreferenceData: any = {};
    simPreferencePayload &&
      simPreferencePayload.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei?.toString(),
          simPreference:
            data?.value === SimTypes.eSim ? SimTypes.ESIM : SimTypes.phySim,
          make: data?.make,
          model: data?.model,
        };
      });
    dispatch(
      addCartItems({
        data: {
          planName: planDetails?.plan?.name,
          reachPlanId: planDetails?.plan?.name,
          quantity: planDetails?.line,
          price: planDetails?.plan?.baseLinePrice,
          discounts: [
            {
              name: "",
              percentage: "",
              applicability: "",
              eligibilityCriteria: [],
              endPointToVerifyDiscount: "",
              eligibilityStatus: "",
            },
          ],
          linePreferences: linePreferenceData,
          shippingOption: "usps_first_class_mail",
        },
      })
    ).then((response) => {
      if (response?.payload?.status === 200) {
        NotificationCust({
          message: (
            <Box
              sx={{
                fontSize: "14px",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {"Your plan has been changed successfully."}
            </Box>
          ),
          duration: 3000,
          id: "PlanNotification",
          showIcon: true,
        });
      }
    });
  };

  const updareCartToShopware = () => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    let payload = updateCartPayload(simPreferenceStorage);
    dispatch(updateCartItems(payload));
  };
  const handleLineChange = (numberOfLines: any) => {
    if (numberOfLines > planDetails.numberOfLines && Config.IS_ESIM_SUPPORTED) {
      setShowLineChangePrefernceDialog(true);
      setNumberOfLinesToBeUpdated(numberOfLines);
      setIncreaseLine(true);
    } else {
      if (
        numberOfLines < planDetails.numberOfLines &&
        Config.IS_ESIM_SUPPORTED
      ) {
        let simPreferenceStore: any = JSON.parse(
          localStorage.getItem("simPreference") || "[]"
        );
        simPreferenceStore.pop();
        setSimPreference(simPreferenceStore);
        localStorage.setItem(
          "simPreference",
          JSON.stringify(simPreferenceStore)
        );
      }
      updateLatestNumberOfLines(numberOfLines);
    }
  };
  const confirmUpdateLines = () => {
    if (simType === SimTypes.eSim) {
      if (EnvConfig.OPERATOR.toLowerCase() === CarrierTypes.ATT) {
        if (Config.IS_ESIM_SUPPORTED) {
          let simPreferenceStore: any = JSON.parse(
            localStorage.getItem("simPreference") || "[]"
          );
          simPreferenceStore.push({
            id: numberOfLinesToBeUpdated - 1, // For updating unique id
            eSimOpted: true,
            eSimCompatible: true,
            imei: "",
            make: "",
            model: "",
            value: SimTypes.eSim,
            isPrimaryLine: false,
          });
          localStorage.setItem(
            "simPreference",
            JSON.stringify(simPreferenceStore)
          );
          setSimPreference(simPreferenceStore);
        }
        updateLatestNumberOfLines(numberOfLinesToBeUpdated);
      } else {
        setDisplayImeiCheck(true);
      }
    } else {
      if (Config.IS_ESIM_SUPPORTED) {
        let simPreferenceStore: any = JSON.parse(
          localStorage.getItem("simPreference") || "[]"
        );
        simPreferenceStore.push({
          id: numberOfLinesToBeUpdated - 1, // For updating unique id
          eSimOpted: false,
          eSimCompatible: true,
          imei: "",
          make: "",
          model: "",
          value: SimTypes.sim,
          isPrimaryLine: false,
        });
        localStorage.setItem(
          "simPreference",
          JSON.stringify(simPreferenceStore)
        );
        setSimPreference(simPreferenceStore);
      }
      updateLatestNumberOfLines(numberOfLinesToBeUpdated);
    }
    if (Config?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey) {
      updareCartToShopware();
    }
    onCloseLineChangePrefernceDialog();
  };
  const updateLatestNumberOfLines = (numberOfLines: number) => {
    setIncreaseLine(false);
    dispatch(updatePlanDetails({ numberOfLines: numberOfLines }));
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: numberOfLines,
        plan: planDetails.selectedPlan,
      })
    );
    localStorage.setItem(
      "reachSelectedPlanLine",
      JSON.stringify(numberOfLines)
    );
    setDisplayImeiCheck(false);
  };
  const onCloseLineChangePrefernceDialog = () => {
    setShowLineChangePrefernceDialog(false);
    setSimType(SimTypes.sim); // setting defualt sim type to physical sim
    if (simType === SimTypes.sim) setIncreaseLine(false);
  };
  const callbackImeiVerification = () => {
    updateSimPreference();
    setDisplayImeiCheck(false);
  };
  const updateSimPreference = () => {
    let simPreferenceStorage: any = JSON.parse(
      localStorage.getItem("simPreference") || "[]"
    );
    setSimPreference(simPreferenceStorage);
  };
  useEffect(() => {
    const isEsimSelected = simPreference?.some(
      (data: any) => data.value === SimTypes.eSim
    );
    const isSimSelected = simPreference?.some(
      (data: any) => data.value === SimTypes.sim
    );
    setESimClicked(isEsimSelected);
    setSimClicked(isSimSelected);
  }, [simPreference]);
  const changeSimSelectionHandler = () => {
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    if (simPreferenceStorage && simPreferenceStorage[0]?.eSimCompatible) {
      dispatch(updatePrimaryLineType(true));
    }
    setSimPreference(simPreferenceStorage);
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          container
          xs={6}
          md={8}
          alignItems={"center"}
          sx={{ mb: { md: "10px" } }}
        >
          <Typography
            variant="body1"
            component="span"
            onMouseEnter={handlePopOverOpen}
            ref={popperTarget}
            onMouseLeave={handlePopOverClose}
            sx={{
              fontFamily: "var(--font_family_Regular)",
              fontSize: "14px",
            }}
          >
            {planDetails?.selectedPlan?.displayName?.replace("The", "")}{" "}
            {content?.pl_name_label}
          </Typography>
          {planDetails?.selectedPlan?.maxLines > 1 &&
          !EnvConfig?.HIDE_LINE_SELECTION_IN_PURCHASE_JOURNEY &&
          isAllowedMultipleLineSelectionForSIMInHand() ? (
            <>
              &nbsp;
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Regular)",
                  fontSize: "14px",
                }}
              >
                {content?.c_plan_for}
              </Typography>
              &ensp;
              <IncDecButtonCmp
                unit={content?.line}
                step={1}
                maxValue={planDetails?.selectedPlan?.maxLines}
                minValue={1}
                value={planDetails?.numberOfLines}
                countHandler={handleLineChange}
                customStyles={incDecCustomStyles}
                isUnitPlural={planDetails?.numberOfLines > 1}
                updateCartItems={updareCartToShopware}
              />
            </>
          ) : null}
          {/* <Box className={classes.review_popper}>
            <PopperCust
              open={Boolean(popperTarget)}
              anchorEl={popperTarget}
              placement="bottom"
            >
              <Paper
                elevation={0}
                style={{
                  border: "1px solid rgba(0,0,0,.2)",
                  backgroundColor: "var(--black)",
                  color: "var(--white)",
                  padding: "10px",
                  fontSize: "14px",
                }}
              >
                {planDetails && (
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontWeight: "var(--font_family_Semibold)" }}
                  >
                    {planDetails?.selectedPlan?.planData}
                    {planDetails?.selectedPlan?.dataUnit} {content?.speed_type}
                  </Typography>
                )}
                {content?.tooltip_options &&
                content?.hide_feature_item?.length > 0
                  ? content?.tooltip_options.map((el: any) => {
                      const hideItem: any = content?.hide_feature_item?.find(
                        (sItem: any) =>
                          sItem?.name?.toLowerCase() === el?.item?.toLowerCase()
                      );
                      return !hideItem?.planNames?.includes(
                        planDetails?.selectedPlan?.name
                      ) ||
                        !hideItem?.name?.toLowerCase() ===
                          el?.item?.toLowerCase() ? (
                        <Typography variant="body1" component="p" key={el?.id}>
                          {el?.item}
                        </Typography>
                      ) : null;
                    })
                  : content?.tooltip_options
                  ? content?.tooltip_options.map((el: any) => (
                      <Typography variant="body1" component="p" key={el?.id}>
                        {el?.item}
                      </Typography>
                    ))
                  : null}
              </Paper>
            </PopperCust>
          </Box> */}
        </Grid>
        {planDetails?.selectedPlan?.name !==
        Config.SPECIAL_DISCOUNT_PLAN_NAME ? (
          <Grid item xs={6} md={4}>
            <Box className={classes.toggle_item} onClick={openDialog}>
              {content?.c_plan_btn}
            </Box>
          </Grid>
        ) : null}
      </Grid>
      <AddLine_ChangePlan
        isShowModal={display}
        modalType={modalType}
        setOpenModal={setDisplay}
        isAcount={false}
        // index={index}
        // formik={formik}
        // handleChangePlan={handleChangePlan}
        // handleAddLine={handleAddLine}
        // currentPlan={formik.values[index].plan}
        // initialCount={initialCount}
      />
      {Config.IS_ESIM_SUPPORTED ? (
        <>
          <ChangeLinePreferenceDialog
            open={showLineChangePrefernceDialog}
            onClose={onCloseLineChangePrefernceDialog}
            content={simPrefenceContent}
            onConfirm={confirmUpdateLines}
            simType={simType}
            setSimType={setSimType}
            isEsimAvailable={true}
          />
          <ChangeSimPreferenceDialog
            open={showSimChangePrefernceDialog}
            onClose={onCloseSimChangePrefernceDialog}
            content={simPrefenceContent}
            changeSimSelectionHandler={changeSimSelectionHandler}
            simPreference={simPreference}
            setSimPreference={setSimPreference}
            eSimClicked={eSimClicked}
            simClicked={simClicked}
            sameArr={sameArr}
            setSameArr={setSameArr}
            checkCompatibility={checkCompatibility}
            simCharges={simCharges}
          />
          {displayImeiCheck ? (
            <EsimIMEI
              showCheckIMEIModal={displayImeiCheck}
              hideCheckIMEIModal={() => {
                setIncreaseLine(false);
                setDisplayImeiCheck(false);
              }}
              fromCheckoutPage={true}
              changeSimSelectionHandler={callbackImeiVerification}
              simPreference={simPreference}
              esimClickedIndex={esimClickedIndex}
              isAccountScreen={false}
              changePreferenceHandler={updateSimPreference}
              increaseLine={increaseLine}
              increaseHandle={updateLatestNumberOfLines}
              sameArr={sameArr}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};
