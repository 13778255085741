import { Box, Divider, Grid, Link } from "@mui/material";
import { LazyLoadImageCust, MobileStoreButton } from "../../widgets";
import { TrustPilot, TrustPilotType } from "../../common";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import styles from "./S1_Footer.module.scss";
import EnvConfig from "../../../config/env.config.json";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import Cookies from "js-cookie";
import { getConfigKeys } from "../../../features/activation/activationSlice";

type Props = {
  isPostActive: boolean;
  handleAssuranceCard: () => {};
  footerContent: any;
  onClickHandler: any;
};
const {
  footer_t1,
  header_text,
  sub_links,
  line,
  copyright_style,
  sublink_hightlight,
  mobile_button_store_main,
  mobile_button_ios,
  trustpilot_footer,
  version_indicator,
  logo_mobile_apps,
  header_text_center,
} = styles;
const DividerComp = () => {
  return (
    <Divider className={line} sx={{ borderTop: { xs: "1px", sm: "2px" } }} />
  );
};
const T1_FooterComponent = (props: Props) => {
  const { isPostActive, handleAssuranceCard, footerContent, onClickHandler } =
    props;
  const {
    section1,
    section2,
    section3,
    section4,
    logo_desktop,
    logo_tab,
    logo_mobile,
    assuredCard,
    footerList1,
    footerList2,
    footerList3,
    footerList4,
    copyright,
    socialMediaIons,
    iosMobileStore,
    androidMobileStore,
    showAssuredCard,
    showTrustPilot,
    showVersion,
    versionNumber,
    newText,
  } = footerContent || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const { subDomainData } = useAppSelector((state: any) => state?.homepage);
  const [socialMediaIcons, setSocialMediaIcons] = useState(socialMediaIons);
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!configKeys) {
      dispatch(getConfigKeys());
    }
  }, []);

  const updateSocialMediaIons = (
    socialMediaIons: any[],
    subdomaindata: {
      data: {
        facebookUrl: any;
        instagramUrl: any;
        twitterUrl: any;
        youtubeUrl: any;
      };
    }
  ) => {
    if (!subdomaindata?.data) return socialMediaIons;

    return socialMediaIons.map(
      (item: { name: string; navigationPath: string }) => {
        const updatedItem = { ...item };
        const nameLower = item.name.toLowerCase();

        if (nameLower === "facebook") {
          updatedItem.navigationPath = subdomaindata.data.facebookUrl || "";
        } else if (nameLower === "instagram") {
          updatedItem.navigationPath = subdomaindata.data.instagramUrl || "";
        } else if (nameLower === "x") {
          updatedItem.navigationPath = subdomaindata.data.twitterUrl || "";
        } else if (nameLower === "youtube") {
          updatedItem.navigationPath = subdomaindata.data.youtubeUrl || "";
        }

        return updatedItem;
      }
    );
  };

  useEffect(() => {
    const updatedIcons = updateSocialMediaIons(socialMediaIons, subDomainData);
    setSocialMediaIcons(updatedIcons);
  }, [subDomainData]);

  // const footerListFuntn = (val: any) => {
  //   return (
  //     <Box
  //       mt={2}
  //       key={val.keyProp}
  //       className={
  //         footerContent?.is_btm_cen ? styles.sub_links_centered : sub_links
  //       }
  //       sx={{
  //         fontFamily: "var(--font_family_Regular))",
  //         fontSize: "14px",
  //         color: "var(--text_color)",
  //         cursor: "pointer",
  //       }}
  //       onClick={() =>
  //         onClickHandler(
  //           BrandNameUpdate(val.navigationPath),
  //           val.redirectionPage,
  //           val.gaName,
  //           val.is_gaEventReqd
  //         )
  //       }
  //     >
  //       {val.name}
  //       {val.isNew && (
  //         <Box
  //           component="span"
  //           className={sublink_hightlight}
  //           borderRadius={1}
  //           px={0.5}
  //           mx={0.5}
  //         >
  //           {newText}
  //         </Box>
  //       )}
  //     </Box>
  //   );
  // };

  const footerListFuntn = (val: any, index: number) => {
    const returnSec = (
      <Box
        className={sub_links}
        mt={{ xs: "10px", lg: "0px" }}
        key={index}
        role="button"
        data-testid={`footer_link_text_wrapper_${Number(index) + 1 || 1}`}
      >
        {val.name}
        {val.isNew && (
          <Box
            component="span"
            className={sublink_hightlight}
            borderRadius={1}
            px={0.5}
            mx={0.5}
          >
            {newText}
          </Box>
        )}
      </Box>
    );
    const resultSet = (
      <>
        {val.isLink ? (
          <Link
            onClick={() =>
              onClickHandler(
                BrandNameUpdate(val.navigationPath),
                val.redirectionPage,
                val.gaName,
                val.is_gaEventReqd
              )
            }
            underline="none"
          >
            {returnSec}
          </Link>
        ) : (
          <Link
            onClick={() => {
              if (val?.keyProp === 1) {
                onClickHandler(
                  BrandNameUpdate(val.navigationPath),
                  val?.redirectionPage,
                  val?.gaName,
                  val?.is_gaEventReqd
                );
                setTimeout(function () {
                  scroller.scrollTo("bestCellPhonePlan", {
                    duration: 1000,
                    delay: 0,
                    offset: -100,
                    smooth: true,
                  });
                }, 1000);
              } else {
                if (
                  val &&
                  val.name &&
                  typeof val.name === "string" &&
                  val.name.toLowerCase() === "hearing aid compatibility policy"
                ) {
                  shopwareHandler(
                    EnvConfig?.SHOP_DOMAIN,
                    "hearing-aid-compatibility"
                  );
                } else if (
                  val &&
                  val.name &&
                  typeof val.name === "string" &&
                  val.name.toLowerCase() ===
                    "return and refund policy for new device"
                ) {
                  shopwareHandler(
                    EnvConfig?.SHOP_DOMAIN,
                    "warranty-returns-policy"
                  );
                } else if (
                  val &&
                  val.name &&
                  typeof val.name === "string" &&
                  val.name.toLowerCase() ===
                    "return and refund policy for refurbished device"
                ) {
                  shopwareHandler(
                    EnvConfig?.SHOP_DOMAIN,
                    "warranty-returns-refurbished"
                  );
                } else {
                  onClickHandler(
                    BrandNameUpdate(val.navigationPath),
                    val.redirectionPage,
                    val.gaName,
                    val?.is_gaEventReqd
                  );
                }
              }
            }}
            rel="noreferrer"
            underline="none"
          >
            {returnSec}
          </Link>
        )}
      </>
    );
    const shopwareHandler = (navigationPath: string, endPoint: string) => {
      let swContextToken: any = Cookies.get("swContextToken");
      let idTokenValue: any = localStorage.getItem("accessToken");
      let currentuser: any = localStorage.getItem("currentuser");
      currentuser = JSON.parse(currentuser);
      let SubDomainDetails: any = localStorage.getItem("subDomainData");
      SubDomainDetails = JSON.parse(SubDomainDetails);
      window.open(
        `${navigationPath}/${endPoint}/?token=${swContextToken}&firebase_token=${idTokenValue}&logged_in=${
          currentuser && currentuser.email ? true : false
        }&accessKey=${SubDomainDetails?.shopwareAccessKey}`,
        "_blank"
      );
    };
    return (
      <>
        <Box
          // className={link_item}
          sx={{ padding: { md: "0px 0px 24px 48px" } }}
          key={val?.keyProp}
        >
          {resultSet}
        </Box>
      </>
    );
  };

  const assuredCardSection = () => {
    return (
      <Box
        sx={{
          my: { xs: 2, sm: "0px" },
          cursor: "pointer",
        }}
        onClick={() => handleAssuranceCard()}
      >
        <Box>
          <LazyLoadImageCust
            src={assuredCard?.data?.attributes?.url || ""}
            alt={assuredCard?.data?.attributes?.alternativeText || ""}
            width={"145px"}
            height={"36px"}
          />
        </Box>
      </Box>
    );
  };
  const displayVersion = () => {
    return (
      showVersion && <Box className={version_indicator}>{versionNumber}</Box>
    );
  };
  const handleImageClick = (path: string | URL | undefined) => () => {
    window.open(path, "_blank");
  };
  const currentYear = new Date().getFullYear();
  return (
    <Box sx={{ width: "100%", background: "var(--footer_background_color)" }}>
    <Grid
      container
      display={{ xs: "flex" }}
      flexDirection={{ xs: "column" }}
      position="static"
      className={footer_t1}
    >
      <Grid
        item
        pt={{ xs: 1, md: 0 }}
        pb={{ xs: footerContent?.is_btm_cen ? "20px" : "30px", sm: 2 }}
        display={{
          sm: "none",
          xs: "block",
          md: "block",
        }}
      >
        {footerContent?.is_btm_cen && mobile ? null : DividerComp()}
      </Grid>
      <Grid
        container
        display={{ sm: "flex" }}
        flexDirection={{ xs: "row" }}
        rowSpacing={1}
        justifyContent="space-between"
        marginTop={{ xs: 0, md: 1 }}
        p={{
          xs: "0px 10px 10px 10px",
          sm: "0px 35px 10px 38px",
          md: "0px 20px 0px 20px",
        }}
      >
        {/* Images- logo, google play, ios app store */}
        <Grid
          container
          item
          // pr={2}
          lg={2.2}
          md={2}
          xs={12}
          display="flex"
          flexDirection={{ sm: "row" }}
          sx={{
            flexFlow: { xs: "row", md: "column" },
            justifyContent: {
              md: "space-between",
              xs: footerContent?.is_btm_cen ? "center" : "flex-start",
            },
            margin: {
              xs: footerContent?.is_btm_cen ? "0px 0px 2px 0px" : "0 12px",
              md: 0,
            },
          }}
          className={logo_mobile_apps}
        >
          {/* Logo */}
          <Grid item>
            <Grid
              item
              sx={{
                textAlign: { xs: "center", md: "left" },
              }}
            >
              {(largeDesktop || desktop) &&
                generateImageContainer({
                  data: subDomainData?.data?.logoImageUrl
                    ? subDomainData?.data?.logoImageUrl
                    : logo_desktop,
                  overrides: {
                    sx: {
                      maxWidth: "120px",
                      maxHeight: "45px",
                      marginLeft: "30px",
                    },
                  },
                })}
              {ipad &&
                generateImageContainer({
                  data: subDomainData?.data?.logoImageUrl
                    ? subDomainData?.data?.logoImageUrl
                    : logo_tab,
                  overrides: { sx: { maxWidth: "120px", maxHeight: "45px" } },
                })}
              {mobile &&
                generateImageContainer({
                  data: subDomainData?.data?.logoImageUrl
                    ? subDomainData?.data?.logoImageUrl
                    : logo_mobile,
                  overrides: {
                    sx: {
                      maxWidth: "120px",
                      maxHeight: "45px",
                      // marginLeft: "15px",
                      textAlign: "center",
                    },
                  },
                })}
            </Grid>
          </Grid>
          {/* google play, ios app store */}
          {/* <Grid
            item
            display="flex"
            paddingLeft={{ sm: "1.5rem", md: "0px", xs: "0.7rem" }}
            className={mobile_button_store_main}
          >
            {androidMobileStore && (
              <Grid item>
                <MobileStoreButton
                  width={100}
                  height={35}
                  store={androidMobileStore.storeName}
                  url={EnvConfig.ANDROID_APP_URL}
                  linkprops={{ title: androidMobileStore.title }}
                  imagelink={
                    androidMobileStore?.img?.data?.attributes?.url || ""
                  }
                />
              </Grid>
            )}
            {iosMobileStore && (
              <Grid item paddingLeft={{ xs: 2 }} className={mobile_button_ios}>
                <MobileStoreButton
                  width={100}
                  height={35}
                  store={iosMobileStore.storeName}
                  url={EnvConfig.IOS_APP_URL}
                  linkprops={{ title: iosMobileStore.title }}
                  imagelink={iosMobileStore?.img?.data?.attributes?.url || ""}
                />
              </Grid>
            )}
          </Grid> */}
        </Grid>
        {/* Footer List Section */}
        <Grid
          container
          item
          md={10}
          sm={12}
          lg={9.8}
          sx={{ margin: { xs: "0 12px", md: "0px" }, paddingTop: "0px !important", }}
        >
          <Grid container item sm={EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey ? 5 : 7} xs={12}>
            <Grid
              item
              sm={7}
              xs={12}
              sx={{
                margin: {
                  xs: footerContent?.is_btm_cen ? "2px 0px 0px" : "0px",
                  md: "0px",
                },
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              {/* <Box
                className={
                  footerContent?.is_btm_cen ? header_text_center : header_text
                }
              >
                {section1}
              </Box> */}
              {footerList1?.map((val: any, index: any) => {
                if (val.keyProp === 1) {
                  return !isPostActive && val.name !== "International Services" || configKeys?.INTERNATIONAL_CALLING_ENABLED ? footerListFuntn(val, index) : null;
                } else return val.name !== "International Services" || configKeys?.INTERNATIONAL_CALLING_ENABLED ? footerListFuntn(val, index) : null;
              })}
            </Grid>
            <Grid
              sx={{
                margin: {
                  xs: footerContent?.is_btm_cen ? "0 0px 0px" : "0px",
                  md: "0px",
                },
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
              item
              sm={5}
              xs={12}
            >
              {/* <Box
                className={
                  footerContent?.is_btm_cen ? header_text_center : header_text
                }
              >
                {section2}
              </Box> */}
              {footerList2?.map((val: any, index: any) => {
                return footerListFuntn(val, index);
              })}
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey ? 7 : 5}
            xs={12}
            mt={{ xs: footerContent?.is_btm_cen ? "0" : "20px", sm: "0px" }}
          >
            {EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey &&
            <Grid
              item
              sm={7.5}
              xs={12}
              lg={7}
              xl={6}
              sx={{
                margin: {
                  xs: footerContent?.is_btm_cen ? "0 0px 0px" : "0px",
                  md: "0px",
                },
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              {/* <Box
                className={
                  footerContent?.is_btm_cen ? header_text_center : header_text
                }
              >
                {BrandNameUpdate(section3)}
              </Box> */}
              {footerList3?.map((val: any, index: any) => {
                return footerListFuntn(val, index);
              })}
            </Grid>
            }
            <Grid
              item
              sm={EnvConfig?.IS_SHOPWARE_ENABLED && subDomainData?.data?.shopwareAccessKey ? 4.5 : 6}
              xs={12}
              lg={5}
              xl={6}
              sx={{
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              {/* <Box
                className={
                  footerContent?.is_btm_cen ? header_text_center : header_text
                }
              >
                {section4}
              </Box> */}
              {footerList4?.map((val: any, index: any) => {
                return footerListFuntn(val, index);
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item py={{ xs: 1, sm: 1, lg: 1 }}>
        {DividerComp()}
      </Grid>
      <Grid
        container
        item
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        justifyContent={{ md: "space-between" }}
        sm={12}
        md={12}
        p={{
          xs: "0px 10px 10px 10px",
          sm: "0px 35px 10px 38px",
          md: "0px 30px 8px 52px",
        }}
        className="footer_copyright_outer"
      >
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          sx={{
            margin: {
              xs: footerContent?.is_btm_cen ? "0px 0px 0px 0px" : "0 12px",
              md: "0px",
            },
            justifyContent: {
              xs: footerContent?.is_btm_cen ? "center" : "space-between",
            },
            alignItems: {
              xs: footerContent?.is_btm_cen ? "center" : "unset",
            },
            flexDirection: {
              xs: footerContent?.is_btm_cen
                ? "column-reverse !important"
                : "row",
            },
            display: { md: "none", xs: "flex" },
          }}
        >
          {/* <Grid
            item
            display={{ xs: "block", md: "none" }}
            paddingTop={{ xs: "0.2rem" }}
          >
            {displayVersion()}
          </Grid> */}
          <Grid
            item
            sx={{
              display: { md: "none", xs: "block" },
            }}
            paddingTop={{ xs: "0.2rem" }}
          >
            <Box
              className={
                footerContent?.is_btm_cen
                  ? styles?.copyright_style_center
                  : copyright_style
              }
            >
              {BrandNameUpdate(copyright)?.replace(
                "{year}",
                String(currentYear)
              )}
            </Box>
          </Grid>
        </Grid>
        {/* <Grid
          item
          display={{ xs: "none", md: "block" }}
          paddingTop={{ md: "0.5rem" }}
        >
          {displayVersion()}
        </Grid> */}
        <Grid
          item
          display="flex"
          flexDirection={{
            md: "row",
            xs: "column-reverse",
            sm: "row-reverse",
          }}
          justifyContent={{
            sm: "space-between",
            md: "flex-end",
            xs: "flex-start",
          }}
          sx={{
            padding: { sm: "1.5rem 0rem 0rem", md: "0px" },
            margin: { xs: "0 12px", md: "0px" },
            display: { md: "flex", xs: "none" },
          }}
        >
          {showTrustPilot && (
            <Box
              className={trustpilot_footer}
              sx={{ display: { sm: "block", xs: "none" } }}
              paddingTop={{ md: "0.5rem", sm: "0.6rem" }}
            >
              <TrustPilot type={TrustPilotType.FOOTER} />
            </Box>
          )}
          {showAssuredCard && assuredCardSection()}
          <Box
            className={
              footerContent?.is_btm_cen
                ? styles?.copyright_style_center
                : copyright_style
            }
            sx={{ display: { md: "flex", xs: "none" }, alignItems: "center" }}
            paddingTop={{ sm: "0.5rem" }}
          >
            {BrandNameUpdate(copyright)?.replace("{year}", String(currentYear))}
          </Box>
        </Grid>
        <Grid
          item
          className={trustpilot_footer}
          sx={{ display: { sm: "none", xs: "flex" } }}
          alignSelf={"flex-start"}
        >
          {showTrustPilot && <TrustPilot type={TrustPilotType.FOOTER} />}
        </Grid>
        <Grid
          item
          display="flex"
          sx={{
            marginTop: { xs: footerContent?.is_btm_cen ? "12px" : "0px" },
            justifyContent: "center",
          }}
        >
          {socialMediaIcons?.map((val: any) => {
            if (val.navigationPath === "") return;
            return (
              <Box
                mr={1}
                key={val.keyProp}
                onClick={() =>
                  onClickHandler(
                    val.navigationPath,
                    val.redirectionPage,
                    val.name
                  )
                }
                sx={{ cursor: "pointer" }}
              >
                <Box>
                  <LazyLoadImageCust
                    src={val?.icon?.data?.attributes?.url || ""}
                    alt={val?.icon?.data?.attributes?.alternativeText || ""}
                    width={val?.name === "YouTube" ? "30px" : "20px"}
                    height={"20px"}
                  />
                </Box>
              </Box>
            );
          })}
        </Grid>
        <Box
          sx={{
            marginTop: { xs: "15px", md: "6px" },
            marginRight: "20px",
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            alt={
              androidMobileStore?.img?.data?.attributes?.alternativeText || ""
            }
            height="34px"
            // width="100px"
            src={androidMobileStore?.img?.data?.attributes?.url || ""}
            onClick={handleImageClick(
              configKeys?.COMMON_MVNO_PLAY_STORE_URL ??
                androidMobileStore?.navigationPath
            )}
            sx={{
              cursor: "pointer",
              marginRight: "10px",
            }}
            data-testid="footer_andriod_image"
          />
          <Box
            component="img"
            alt={iosMobileStore?.img?.data?.attributes?.alternativeText || ""}
            height="34px"
            // width="100px"
            src={iosMobileStore?.img?.data?.attributes?.url || ""}
            onClick={handleImageClick(
              configKeys?.COMMON_MVNO_APP_STORE_URL ??
                iosMobileStore?.navigationPath
            )}
            sx={{
              cursor: "pointer",
            }}
            data-testid="footer_ios_image"
          />
        </Box>
        {/* <Grid
            item
            display="flex"
            paddingLeft={{ sm: "1.5rem", md: "0px", xs: "0.7rem" }}
            className={mobile_button_store_main}
          >
            {androidMobileStore && (
              <Grid item>
                <MobileStoreButton
                  width={100}
                  height={35}
                  store={androidMobileStore.storeName}
                  url={EnvConfig.ANDROID_APP_URL}
                  linkprops={{ title: androidMobileStore.title }}
                  imagelink={
                    androidMobileStore?.img?.data?.attributes?.url || ""
                  }
                />
              </Grid>
            )}
            {iosMobileStore && (
              <Grid item paddingLeft={{ xs: 2 }} className={mobile_button_ios}>
                <MobileStoreButton
                  width={100}
                  height={35}
                  store={iosMobileStore.storeName}
                  url={EnvConfig.IOS_APP_URL}
                  linkprops={{ title: iosMobileStore.title }}
                  imagelink={iosMobileStore?.img?.data?.attributes?.url || ""}
                />
              </Grid>
            )}
          </Grid> */}
      </Grid>
    </Grid>
    </Box>
  );
};

export default T1_FooterComponent;
