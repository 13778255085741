import React, { useEffect, useState } from "react";
import { NLProps } from "./NutritionLabel";
import { Box, Divider, Link, Tab, Tabs, Typography } from "@mui/material";
import {
  BrandNameUpdate,
  getCache,
  getResolutionDetails,
} from "../../../../utils/commonFunctions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SwitchCustIOS } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import { NLCard } from "../../../common/NLCard/NLCard";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import FirebaseDBURLs from "../../../../config/FirebaseDBURLs";
import firebase from "../../../../adapters/firebase";
import { PlanSectionContent } from "../../../../features/strapi/homePageSlice";

const T2_NutritionLabel = (props: NLProps) => {
  const {
    plandata,
    selectedLine,
    priceCalculationHandler,
    tabValue,
    maxShipvalue,
    minShipvalue,
    showNutritionLabels,
    setShowNutritionLabels,
    setTabValue,
    NLContent,
    planCost,
    handleAnswer,
    showAnswer,
    index,
    isheading,
  } = props;
  const { mobile } = getResolutionDetails();
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const [minvalue, setMinvalue] = useState(0);
  const [maxvalue, setMaxvalue] = useState(0);
  const [NLTemplate, setNLTemplate] = useState<any>(null);
  const { nutritionLabelData } = useAppSelector((state: any) => state.planpage);

  useEffect(() => {
    fetchShipmentPlans();
  }, []);
  const fetchShipmentPlans = async () => {
    const shippingRate: any = await firebase
      .database()
      .ref(FirebaseDBURLs.shippingRates);
    shippingRate.on("value", (snapshot: any) => {
      const data = snapshot.val();
      let shipmentPlansList: any = [];
      if (data.length > 0) {
        data.forEach((el: any) => {
          shipmentPlansList.push({
            label: `${el && el.name} - $${el && el.shippingAmount.toFixed(2)}`,
            value: el && el.serviceCode,
            cost: el && el.shippingAmount,
            ...el,
          });
        });
      }
      if (shipmentPlansList) {
        let minValue = Math.min(
          ...shipmentPlansList.map((obj: any) => obj.shippingAmount)
        );
        let maxValue = Math.max(
          ...shipmentPlansList.map((obj: any) => obj.shippingAmount)
        );
        setMinvalue(minValue);
        setMaxvalue(maxValue);
      }
      // setSendSimOptions(shipmentPlansList);
    });
  };

  useEffect(() => {
    if (Object.keys(nutritionLabelData).length > 0) {
      const result = nutritionLabelData?.data?.find(
        (item: any) => item.labelIdentifier === plandata?.uniqueIdentifier
      );
      if (result) {
        setNLTemplate(result?.template);
      }
    }
  }, [nutritionLabelData]);

  return EnvConfig.IS_NUTRITION_LABEL_REQUIRED && NLTemplate ? (
    <Box>
      <Box
        onClick={() => {
          handleAnswer(index);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--primary_color)",
          fontSize: "14px",
          fontWeight: "600",
          cursor: "pointer",
        }}
        data-testid={"plan_plannsec_boardbandfact_parent_" + index}
      >
        {isheading && (
          <Typography
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              fontWeight: "var(--font_weight_2)",
              fontSize: "14px",
            }}
            data-testid={`plan_plannsec_boardbandfact_text_${
              Number(index) + 1 || 1
            }`}
          >
            {NLContent?.broadband ?? "Broadband Facts"}
          </Typography>
        )}
        {showAnswer && isheading ? (
          <KeyboardArrowUpIcon
            sx={{
              color: "var(--primary_color)",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            data-testid={`plan_plannsec_boardbandfact_arrow_up_${
              Number(index) + 1 || 1
            }`}
          />
        ) : (
          isheading && (
            <KeyboardArrowDownIcon
              sx={{
                color: "var(--primary_color)",
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
              data-testid={`plan_plannsec_boardbandfact_arrow_down_${
                Number(index) + 1 || 1
              }`}
            />
          )
        )}
      </Box>
      {showAnswer ? (
        // <Box
        //   sx={
        //     isheading
        //       ? {
        //           pl: "15px",
        //           pt: "15px",
        //           pr: "15px",
        //           color: "var(--black)",
        //         }
        //       : {
        //           pl: "5px",
        //           pt: "15px",
        //           pr: "5px",
        //           color: "var(--black)",
        //         }
        //   }
        // >
        //   <Typography
        //     sx={{
        //       fontSize: "24px",
        //       fontWeight: "700",
        //       fontFamily: "var(--font_family_Bold)",
        //     }}
        //   >
        //     {isheading && NLContent?.broadband}
        //   </Typography>
        //   <Divider sx={{ my: 1, border: "1.5px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "500",
        //       lineHeight: "18px",
        //       fontFamily: "var(--font_family_Medium)",
        //     }}
        //   >
        //     {BrandNameUpdate(NLContent?.br_nm)}
        //   </Typography>
        //   <Typography
        //     sx={{
        //       fontSize: "14px",
        //       fontWeight: "700",
        //       lineHeight: "21px",
        //     }}
        //   >
        //     {plandata.displayName}
        //   </Typography>
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "400",
        //       lineHeight: "18px",
        //     }}
        //   >
        //     {NLContent?.br_dec}
        //   </Typography>
        //   <Divider sx={{ my: 1, border: "3px solid var(--black)" }} />
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "14px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.mt_price}
        //     </Typography>
        //     <Typography
        //       sx={{
        //         fontSize: "15px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {GetCurrencyFixedToTwo(plandata.baseLinePrice)}
        //     </Typography>
        //   </Box>
        //   <Divider sx={{ my: 1, border: "1px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "10px",
        //       fontWeight: "400",
        //     }}
        //     dangerouslySetInnerHTML={{
        //       __html: NLContent?.mt_pri_ec.replace(
        //         /monthPrice/g,
        //         GetCurrencyFixedToTwo(plandata.baseLinePrice) || ""
        //       ),
        //     }}
        //   ></Typography>
        //   <Divider sx={{ my: 1, border: "0.5px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "700",
        //       pb: "10px",
        //     }}
        //   >
        //     {NLContent?.ad_chrg}
        //   </Typography>
        //   {/* <Box
        //     sx={{
        //       pt: "8px",
        //       pl: "10px",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.mt_fee}
        //     </Typography>
        //     <Box
        //       sx={{
        //         pl: "10px",
        //         display: "flex",
        //         justifyContent: "space-between",
        //       }}
        //     >
        //       <Typography
        //         sx={{
        //           fontSize: "12px",
        //           fontWeight: "400",
        //         }}
        //       >
        //         {NLContent?.ec_fee}
        //       </Typography>
        //       <Typography
        //         sx={{
        //           fontSize: "12px",
        //           fontWeight: "700",
        //         }}
        //       >
        //         {GetCurrencyFixedToTwo(0)}
        //       </Typography>
        //     </Box>
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "700",
        //         pt: "10px",
        //       }}
        //     >
        //       {NLContent?.one_fee}
        //     </Typography>
        //     <Box
        //       sx={{
        //         pl: "10px",
        //         display: "flex",
        //         justifyContent: "space-between",
        //       }}
        //     >
        //       <Typography
        //         sx={{
        //           fontSize: "12px",
        //           fontWeight: "400",
        //         }}
        //       >
        //         {NLContent?.ec_fee}
        //       </Typography>
        //       <Typography
        //         sx={{
        //           fontSize: "12px",
        //           fontWeight: "700",
        //         }}
        //       >
        //         {GetCurrencyFixedToTwo(0)}
        //       </Typography>
        //     </Box>
        //   </Box> */}
        //   <Typography
        //     sx={{
        //       fontSize: "10px",
        //       fontWeight: "700",
        //       pl: "5px",
        //     }}
        //   >
        //     {NLContent?.mt_fee}
        //   </Typography>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <Box sx={{ marginLeft: "8px", paddingTop: "5px" }}>
        //       <Typography
        //         sx={{
        //           fontSize: "11.2px",

        //           fontFamily: "var(--font_family_Medium)",
        //         }}
        //       >
        //         {NLContent?.ec_fee}
        //       </Typography>
        //     </Box>
        //     <Box>
        //       <Typography
        //         sx={{
        //           fontSize: "11.2px",
        //           fontFamily: "var(--font_family_Medium)",
        //           paddingTop: "5px",
        //         }}
        //       >
        //         {NLContent?.cost_rec_value}
        //       </Typography>
        //     </Box>
        //   </Box>
        //   <Box>
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontFamily: "var(--font_family_Medium)",
        //         fontWeight: "var(--font_weight_2)",
        //         pl: "5px",
        //         pt: "5px",
        //         pb: "5px",
        //       }}
        //     >
        //       {NLContent?.one_fee}
        //     </Typography>
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //       marginLeft: "10px",
        //     }}
        //   >
        //     <Box>
        //       <Typography
        //         sx={{
        //           fontSize: "11.2px",

        //           fontFamily: "var(--font_family_Medium)",
        //         }}
        //       >
        //         {NLContent?.act_fee}
        //       </Typography>
        //     </Box>
        //     <Box>
        //       <Typography
        //         sx={{
        //           fontSize: "11.2px",
        //           fontFamily: "var(--font_family_Medium)",
        //         }}
        //       >
        //         {GetCurrencyFixedToTwo(configKeys?.ACTIVATION_FEE)}{" "}
        //         {NLContent?.act_fee_value}
        //       </Typography>
        //     </Box>
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //       marginLeft: "10px",
        //     }}
        //   >
        //     <Box>
        //       <Typography
        //         sx={{
        //           fontSize: "11.2px",

        //           fontFamily: "var(--font_family_Medium)",
        //         }}
        //       >
        //         {NLContent?.sim_chrg}
        //       </Typography>
        //     </Box>
        //     <Box>
        //       <Typography
        //         sx={{
        //           fontSize: "11.2px",
        //           fontFamily: "var(--font_family_Medium)",
        //         }}
        //       >
        //         {GetCurrencyFixedToTwo(minvalue)} –
        //         {GetCurrencyFixedToTwo(maxvalue)}
        //       </Typography>
        //     </Box>
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //       pt: "10px",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.er_ter_fee}
        //     </Typography>
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.erly_term_fee}
        //     </Typography>
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //       pt: "10px",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.gov_tax}
        //     </Typography>
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.var_loc}
        //     </Typography>
        //   </Box>
        //   <Divider sx={{ my: 1, border: "1.5px solid var(--black)" }} />
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "baseline",
        //       justifyContent: "space-between",
        //       pt: "10px",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.dis_head}
        //     </Typography>
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "400",
        //         pl: "10px",
        //       }}
        //     >
        //       {NLContent?.dis_dec}
        //     </Typography>
        //   </Box>
        //   <Divider sx={{ my: 1, border: "1.5px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "700",
        //     }}
        //   >
        //     {NLContent?.aff_pro}
        //   </Typography>
        //   <Box
        //     sx={{
        //       fontSize: "11px",
        //       fontWeight: "400",
        //       paddingLeft: "10px",
        //     }}
        //   >
        //     <span>{NLContent?.aff_pro_dec}</span>
        //     <span>
        //       <Link
        //         sx={{
        //           color: "var(--black)",
        //           textDecorationColor: "none",
        //           fontSize: "12px",
        //           fontFamily: "var(--font_family_Medium)",
        //           cursor: "pointer",
        //         }}
        //         onClick={() => {
        //           window.open(NLContent?.genl_url, "_blank");
        //         }}
        //       >
        //         {NLContent?.m_pri_dec}
        //       </Link>
        //     </span>
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //       pt: "10px",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "10px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.par_head}
        //     </Typography>
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.par_dec}
        //     </Typography>
        //   </Box>
        //   <Divider sx={{ my: 1, border: "1.5px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "700",
        //     }}
        //   >
        //     {NLContent?.spee_head}
        //   </Typography>
        //   {plandata?.labelInfo?.speedInfos?.map(
        //     (speedData: any, index: any) => (
        //       <div key={index}>
        //         <Typography
        //           sx={{
        //             fontSize: "12px",
        //             fontWeight: "700",
        //             pt: "5px",
        //           }}
        //         >
        //           {speedData.band}
        //         </Typography>
        //         <Box
        //           sx={{
        //             display: "flex",
        //             justifyContent: "space-between",
        //             pl: "10px",
        //           }}
        //         >
        //           <Typography
        //             sx={{
        //               fontSize: "12px",
        //               fontWeight: "500",
        //             }}
        //           >
        //             {NLContent?.down_spe}
        //           </Typography>
        //           <Typography
        //             sx={{
        //               fontSize: "12px",
        //               fontWeight: "700",
        //             }}
        //           >
        //             {speedData.dlSpeed}
        //           </Typography>
        //         </Box>
        //         <Box
        //           sx={{
        //             display: "flex",
        //             justifyContent: "space-between",
        //             pl: "10px",
        //           }}
        //         >
        //           <Typography
        //             sx={{
        //               fontSize: "12px",
        //               fontWeight: "500",
        //             }}
        //           >
        //             {NLContent?.up_spee}
        //           </Typography>
        //           <Typography
        //             sx={{
        //               fontSize: "12px",
        //               fontWeight: "700",
        //             }}
        //           >
        //             {speedData.ulSpeed}
        //           </Typography>
        //         </Box>
        //         <Box
        //           sx={{
        //             display: "flex",
        //             justifyContent: "space-between",
        //             pl: "10px",
        //           }}
        //         >
        //           <Typography
        //             sx={{
        //               fontSize: "12px",
        //               fontWeight: "500",
        //             }}
        //           >
        //             {NLContent?.latency}
        //           </Typography>
        //           <Typography
        //             sx={{
        //               fontSize: "12px",
        //               fontWeight: "700",
        //             }}
        //           >
        //             {speedData.latency}
        //           </Typography>
        //         </Box>
        //       </div>
        //     )
        //   )}
        //   <Divider sx={{ my: 1, border: "1.5px solid var(--black)" }} />
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //       dangerouslySetInnerHTML={{
        //         __html: NLContent?.data_head,
        //       }}
        //     ></Typography>
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {plandata.planData + plandata.dataUnit}
        //       {plandata.planType === "LSD" ? "+" : ""}
        //     </Typography>
        //   </Box>
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "500",
        //       pl: "10px",
        //     }}
        //   >
        //     {NLContent?.data_head_dec}
        //   </Typography>
        //   <Box
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "700",
        //       pl: "10px",
        //       pb: "10px",
        //     }}
        //   >
        //     <span
        //       dangerouslySetInnerHTML={{
        //         __html: NLContent?.top_pri.replace(
        //           /value/g,
        //           configKeys?.AUTO_TOP_UP_AMOUNT || ""
        //         ),
        //       }}
        //     >
        //       {/* {configKeys?.AUTO_TOP_UP_SIZE+ plandata.dataUnit} */}
        //     </span>
        //     <span>{configKeys?.AUTO_TOP_UP_SIZE + plandata.dataUnit}</span>
        //   </Box>
        //   <Divider sx={{ my: 1, border: "1.5px solid var(--black)" }} />
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //       dangerouslySetInnerHTML={{
        //         __html: NLContent?.net_head,
        //       }}
        //     ></Typography>
        //     {configKeys?.NETWORK_MANAGEMENT_LABEL_URL ? (
        //       <Link
        //         sx={{
        //           color: "var(--black)",
        //           textDecoration: "underline",
        //           fontSize: "12px",
        //           fontFamily: "var(--font_family_Bold)",
        //           cursor: "pointer",
        //         }}
        //         onClick={() => {
        //           window.open(
        //             configKeys?.NETWORK_MANAGEMENT_LABEL_URL,
        //             "_blank"
        //           );
        //         }}
        //       >
        //         {NLContent?.read_po}
        //       </Link>
        //     ) : (
        //       <Link
        //         sx={{
        //           color: "var(--black)",
        //           textDecoration: "underline",
        //           fontSize: "12px",
        //           fontFamily: "var(--font_family_Bold)",
        //           cursor: "pointer",
        //         }}
        //       >
        //         {NLContent?.read_po}
        //       </Link>
        //     )}
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //         marginTop: "6px",
        //       }}
        //       dangerouslySetInnerHTML={{
        //         __html: NLContent?.privacy,
        //       }}
        //     ></Typography>
        //     <Link
        //       sx={{
        //         color: "var(--black)",
        //         textDecoration: "underline",
        //         fontSize: "12px",
        //         fontFamily: "var(--font_family_Bold)",
        //         cursor: "pointer",
        //       }}
        //       onClick={() => {
        //         window.open(configKeys?.PRIVACY_LABEL_URL, "_blank");
        //       }}
        //     >
        //       {NLContent?.read_po}
        //     </Link>
        //   </Box>
        //   <Divider sx={{ my: 1, border: "2px solid var(--black)" }} />
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-between",
        //       pt: "7px",
        //     }}
        //   >
        //     <Typography
        //       sx={{
        //         fontSize: "12px",
        //         fontWeight: "700",
        //       }}
        //     >
        //       {NLContent?.cus_sup}
        //     </Typography>
        //   </Box>
        //   <Typography
        //     sx={{
        //       fontSize: "11.2px",
        //       fontFamily: "var(--font_family_Medium)",
        //       marginBottom: "5px",
        //       marginTop: "7px",
        //     }}
        //   >
        //     {NLContent?.contact_us_heading}{" "}
        //     <Link
        //       sx={{
        //         color: "var(--black)",
        //         fontSize: "12px",
        //         textDecoration: "none",
        //         fontFamily: "var(--font_family_Medium)",
        //         cursor: "pointer",
        //       }}
        //       onClick={() => {
        //         window.open(configKeys?.BRAND_WEB_SUPPORT_LABEL_URL, "_blank");
        //       }}
        //     >
        //       {NLContent?.con_us}
        //       {configKeys?.BRAND_WEB_SUPPORT_LABEL_URL}
        //     </Link>{" "}
        //     {/* /<br />
        //     {configKeys?.BRAND_SUPPORT_MOBILE_NO} */}
        //   </Typography>
        //   <Divider sx={{ my: 1, border: "2px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "10px",
        //       fontWeight: "400",
        //       pt: "7px",
        //     }}
        //   >
        //     {NLContent?.gov_des}
        //   </Typography>
        //   <Link
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "500",
        //       textDecoration: "none",
        //       cursor: "pointer",
        //       color: "var(--black)",
        //     }}
        //     onClick={() => {
        //       window.open("https://www." + NLContent?.gov_link, "_blank");
        //     }}
        //   >
        //     {NLContent?.gov_link}
        //   </Link>
        //   <Divider sx={{ my: 1, border: "2px solid var(--black)" }} />
        //   <Typography
        //     sx={{
        //       fontSize: "12px",
        //       fontWeight: "500",
        //     }}
        //     dangerouslySetInnerHTML={{
        //       __html: NLContent?.uq_plan.replace(
        //         /number/g,
        //         plandata?.uniqueIdentifier || ""
        //       ),
        //     }}
        //   ></Typography>
        // </Box>
        <Box
          sx={{
            "& p": {
              margin: "0px",
            },
          }}
          data-testid={`plan_plannsec_boardbandfact_content_parent_${
            Number(index) + 1 || 1
          }`}
        >
          <Box
            dangerouslySetInnerHTML={{
              __html: NLTemplate,
            }}
            data-testid={`plan_plannsec_boardbandfact_content_child_${
              Number(index) + 1 || 1
            }`}
          ></Box>
        </Box>
      ) : null}
    </Box>
  ) : null;
};

export default T2_NutritionLabel;
