import { Box, Typography } from "@mui/material";
import React from "react";
import { ButtonCust } from "../../../widgets";
import styles from "./PaymentStatus.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const PaymentStatus = (props: any) => {
  const {
    title,
    description,
    button,
    onClick,
    variantType,
    icon,
    backIcon,
    isWidth,
    back_btn,
    imgHeight,
    imgWidth,
    onBackClick,
    amount,
  } = props;
  return (
    <Box className={styles.main_container} data-testid="pay_status_wrapper">
      <Typography
        onClick={onBackClick}
        sx={{
          cursor: "pointer",
          position: "absolute",
          left: 0,
          display: "flex",
          alignItems: "center",
          paddingLeft: "100px",
          gap: "5px",
        }}
        data-testid="pay_status_back"
      >
        {backIcon && (
          <img
            src={backIcon?.data.attributes.url}
            alt={backIcon?.data.attributes.alternativeText}
            width={17.495}
            height={14.586}
            data-testid="pay_status_back_img"
          />
        )}
        {back_btn}
      </Typography>
      <Box
        component="img"
        src={icon?.data?.attributes.url}
        alt={icon?.data?.attributes.alternativeText}
        width={imgWidth ? imgWidth : "50px"}
        height={imgHeight ? imgHeight : "50px"}
        sx={{ margin: "0 auto 15px" }}
        data-testid="pay_status_img"
      />
      <Typography className={styles.title} data-testid="pay_status_title">{title}</Typography>
      <Typography
        className={styles.description}
        sx={{
          width: isWidth ? "750px" : { xs: "100%", sm: "600px" },
        }}
        data-testid="pay_status_desc"
      >
        {description.replace(/creditAmt/g, GetCurrencyFixedToTwo(amount) || "")}
      </Typography>
      <Box m={"auto"}>
        <ButtonCust
          data-testid="pay_status_btn"
          variantType={variantType}
          onClick={onClick}
          sx={{
            width: { xs: "250px", sm: "var(--payment_success_btn_width)" },
            // "&:hover": {
            //   backgroundColor: "var(--button_hover_color) !important",
            //   border: "1px solid var(--button_hover_color) !important",
            // },
          }}
        >
          {button}
        </ButtonCust>
      </Box>
    </Box>
  );
};

export default PaymentStatus;
