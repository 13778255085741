import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import Styles from "./S2_IRPurchaseHistory.module.scss";
import { getMBorGBFromBytes } from "../../../../utils/commonFunctions";

const T2_IRDataUsage = (props: any) => {
  const {
    usage_title,
    data_usage,
    voice_usage,
    sms_usage,
    mms_usage,
    slash,
    refresh_icon,
  } = props.content;
  const { data, packId, handleDataUsage } = props;
  const { vertical_line } = Styles;
  const { getPackDataUsage, getPackDataUsageError, getPackDataUsagePending } =
    useAppSelector((state) => state.services);

  return (
    <Box
      sx={{
        px: "25px",
      }}
    >
      <Typography
        component="div"
        sx={{
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--font_weight_2)",
          color: "var(--text_color)",
          background: "var(--lightgrey_8)",
          mb: "10px",
          p: "10px 40px",
        }}
      >
        {usage_title}
        {refresh_icon && (
          <Box
            component="img"
            alt={refresh_icon?.data.attributes?.alternativeText || ""}
            src={refresh_icon?.data.attributes?.url || ""}
            ml="10px"
            position="relative"
            top="2px"
            sx={{
              cursor: "pointer",
              width: "18px",
              height: "18px",
            }}
            onClick={() => handleDataUsage(data)}
          />
        )}
      </Typography>

      <>
        {getPackDataUsagePending && data.id === packId ? (
          <Box
            sx={{
              textAlign: "center",
              mb: "30px",
            }}
          >
            <CircularProgress
              sx={{
                color: "var(--text_color)",
              }}
              style={{
                width: "25px",
                height: "25px",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "30px",
              px: "40px",
            }}
          >
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
              }}
            >
              <Box sx={{ pb: "5px" }}>{data_usage} </Box>
              {(getPackDataUsage?.dataUsage?.international ||
                getPackDataUsage?.dataUsage?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box component="span">
                    {getMBorGBFromBytes(
                      getPackDataUsage?.dataUsage?.international
                    )}
                    {slash}
                  </Box>
                  <Box component="span">{data.totalDataGB}</Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
            <Box className={vertical_line}></Box>
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
              }}
            >
              <Box sx={{ pb: "5px" }}>{voice_usage}</Box>
              {(getPackDataUsage?.voiceMinutes?.international ||
                getPackDataUsage?.voiceMinutes?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box component="span">
                    {getPackDataUsage?.voiceMinutes?.international}
                    {slash}
                  </Box>
                  <Box component="span">{data.voiceMins}</Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
            <Box className={vertical_line}></Box>
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
              }}
            >
              <Box sx={{ pb: "5px" }}>{sms_usage}</Box>
              {(getPackDataUsage?.sms?.international ||
                getPackDataUsage?.sms?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box component="span">
                    {getPackDataUsage?.sms?.international}
                    {slash}
                  </Box>
                  <Box component="span">{data.sms}</Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
            <Box className={vertical_line}></Box>
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
              }}
            >
              <Box sx={{ pb: "5px" }}>{mms_usage}</Box>
              {(getPackDataUsage?.mms?.international ||
                getPackDataUsage?.mms?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box component="span">
                    {getPackDataUsage?.mms?.international}
                    {slash}
                  </Box>
                  <Box component="span">{data.mms}</Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};

export default T2_IRDataUsage;
