import { ButtonCust, SimpleModal } from "../../../../widgets";
import { Box, Typography } from "@mui/material";
import EnvConfig from "../../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const T1_AddCredits = (props: any) => {
  const { add_credit_details, primary_txt, dec_inc_icon } = props.content;
  const {
    openCreditModal,
    setOpenCreditModal,
    userLineData,
    creditAmount,
    setcreditAmount,
    configKeys,
    handleConfirmPurchase,
    addCreditIncDecHandler,
  } = props;

  return (
    <SimpleModal
      isShowModal={openCreditModal ? true : false}
      onCloseModal={() => {
        setOpenCreditModal(false);
        setcreditAmount(Number(configKeys?.ILD_INCR_CREDIT_AMOUNT));
      }}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "80%", lg: "40%", xl: "45%" },
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          fontSize: "22px",
          fontWeight: "var(--font_weight_8)",
          color: "var(--tab_color_2)",
          fontFamily: "var(--font_family_medium)",
        }}
      >
        {add_credit_details.title}
      </Box>
      <Box
        sx={{
          width: "80px",
          height: "5px",
          borderRadius: "3px",
          background: "#333",
          margin: "20px auto",
          textAlign: "center",
        }}
      ></Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontFamily: "var(--font_family_medium)",
          margin: "10px 0 20px",
          color: "var(--text_color)",
          fontWeight: "var(--font_weight_1)",
        }}
      >
        <Box
          component="span"
          sx={{
            fontWeight: "var(--font_weight_3)",
          }}
        >
          {add_credit_details.lineTxt}
        </Box>{" "}
        <Box component="span">
          {userLineData?.firstName} {userLineData?.lastName}
        </Box>{" "}
        {userLineData?.isPrimary && (
          <Box
            component="span"
            sx={{
              color: "var(--footer_divider_color)",
            }}
          >
            {primary_txt}
          </Box>
        )}
      </Typography>
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: "var(--font_weight_8)",
          color: "var(--text_color)",
          fontFamily: "var(--font_family_medium)",
        }}
      >
        {add_credit_details.desc}
      </Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          margin: "30px 0 15px",
        }}
      >
        <Box
          component="img"
          alt={dec_inc_icon?.data[0].attributes?.alternativeText || ""}
          src={
            creditAmount === Number(configKeys?.ILD_INCR_CREDIT_AMOUNT)
              ? dec_inc_icon?.data[0].attributes?.url
              : dec_inc_icon?.data[1].attributes?.url || ""
          }
          sx={{
            cursor: "pointer",
          }}
          onClick={() => addCreditIncDecHandler("dec")}
        />
        <Typography
          component="div"
          sx={{
            fontSize: "20px",
          }}
        >
          {GetCurrencyFixedToTwo(creditAmount)}
        </Typography>
        <Box
          component="img"
          alt={dec_inc_icon?.data[2].attributes?.alternativeText || ""}
          src={dec_inc_icon?.data[2].attributes?.url || ""}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => addCreditIncDecHandler("inc")}
        />
      </Box>
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        sx={{
          width: "300px",
          mt: "25px",
        }}
        onClick={() => handleConfirmPurchase()}
      >
        {add_credit_details.btnTxt}
      </ButtonCust>
    </SimpleModal>
  );
};

export default T1_AddCredits;
