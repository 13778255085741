import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Card,
  ClickAwayListener,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import unlimitedPlanDiscount from "../../../config/discountCouponMNM.json";
import EnvConfig from "../../../config/env.config.json";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { planModalType } from "../../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import {
  caclulatePlanCostBasedOnEligibility,
  renderTextStyles,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NutritionLabel } from "../../home/PlanCard/NutritionLabel/NutritionLabel";
import { ButtonCust, CustToolTip, SimpleModal } from "../../widgets";
import { AddLine_ChangePlanTemplateProps } from "./AddLine_ChangePlan";
import { calculateUnlimitedCost } from "../../home/PlanCard/PlanCardsComponent";
import { BrandNameUpdate, getCache } from "../../../utils";
import { PlanSectionContent } from "../../../features/strapi/homePageSlice";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import styles from "../../../components/home/PlanCard/PlanCardsComponent/S13_PlanCard.module.scss";
import { updatePlanDetails } from "../../../features/checkout/checkoutSlice";
import { nutritionLabels } from "../../../features/planpage/planpageSlice";
const T3_AddLine_ChangePlan = (props: AddLine_ChangePlanTemplateProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showAnswer, setShowAnswer] = useState<any>(true);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };

  const {
    isShowModal,
    modalType,
    setOpenModal,
    plandata,
    index,
    formik,
    handleChangePlan,
    handleAddLine,
    content,
    currentPlan,
    amendedReachPlanId,
    initialCount = 0,
    isEsim,
    currentLine,
  } = props;
  const { heading1, heading2 } = content;

  const {
    current_plan,
    per_month_text,
    discount_text,
    tax_text,
    btn_txt,
    data,
    dollar,
    hd_vid,
    sd_vid,
    broadBandFacts,
    amendedPlan_desc,
    bundled_text,
    tax_text1,
  } = content.plan_features || {};
  const { tick_icon, mbl_img, video_img } = content;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { planDetails, isLoadedPlanTaxCharges } = useAppSelector(
    (state: any) => state.checkout
  );
  const [cacheDataPlanSec, setCacheDataPlanSec] = useState<any>(null);
  const [plan, setplan] = useState<any>(null);
  const [errorNl, setErrorNl] = useState(false);
  const { planCardContent } = useAppSelector(
    (state: any) => state.strapi.homepage || {}
  );
  const { errorNutritionalLabel, nutritionLabelData } = useAppSelector(
    (state: any) => state.planpage
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache("PlanSecHome", PlanSectionContent, setCacheDataPlanSec, dispatch);
  }, []);

  useEffect(() => {
    if (nutritionLabelData) {
      setErrorNl(false);
    } else if (errorNutritionalLabel) {
      setErrorNl(true);
    }
  }, [errorNutritionalLabel, nutritionLabelData]);

  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);

  useEffect(() => {
    dispatch(nutritionLabels());
    dispatch(getConfigKeys());
  }, []);

  // const {
  //   per_month_text,
  //   mbl_img,
  //   video_img,
  //   tax_text,
  //   btn_txt,
  //   data,
  //   hd_vid,
  //   sd_vid,
  //   mb_data,
  //   hotspot_img,
  //   no_hpt_fee,
  //   res,
  //   res1,
  //   res2,
  //   current_plan,
  //   broadband
  // } = cacheDataPlanSec ? cacheDataPlanSec : planCardContent;

  const [nutritionLabel, setNutritionLabel] = useState({
    show: false,
    name: "",
  });

  const selectPlanHandler = (newPlan: any) => {
    if (modalType === planModalType.changePlan) {
      handleChangePlan(index, newPlan);
    } else if (modalType === planModalType.addLine) {
      handleAddLine(index, newPlan);
    }
    onClose();
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const calculateUnlimitedCostMnM = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateCost = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;
    const planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    let planPerLinesCost = 0;
    if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    else planPerLinesCost = planCost / SELECTED_LINE;
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const priceCalculationHandler: any = (data: any, selectedLine: any = 1) => {
    let PlanDiscountCost, planPerLinesCost: any;
    if (data.isUnlimited) {
      const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
      );
    } else {
      const { planCost } = calculateCost(data, selectedLine);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
      );
    }
    planPerLinesCost = PlanDiscountCost / selectedLine;
    return planPerLinesCost;
  };

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <Box
          component="a"
          target="_blank"
          sx={{
            color: "var(--white)",
            fontSize: "14px",
            backgroundColor: "var(--navy)",
          }}
        >
          {data}
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <SimpleModal
      isShowModal={isShowModal}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        top: "0%",
        width: { xs: "90%", sm: "100%", md: "100%", lg: "97%", xl: "85%" },
        boxShadow:
          "0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11), 0px 4px 8px 0px rgba(79, 94, 113, 0.10), 0px 8px 16px 0px rgba(79, 94, 113, 0.09), 0px 16px 32px 0px rgba(79, 94, 113, 0.08), 0px 32px 64px 0px rgba(79, 94, 113, 0.07)",
        borderRadius: "9px",
        minHeight: "100vh",
        justifyContent: "flex-start !important",
      }}
    >
      <Typography sx={renderTextStyles(1, "24px", "title-color_1", "36px")} data-testid="addline_change_plan_heading">
        {modalType === planModalType.changePlan ? heading1 : heading2}
      </Typography>
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          justifyContent: { xs: "flex-start", md: "center" },
          overflow: { xs: "scroll", sm: "initial" },
          flexWrap: { xs: "nowrap", sm: "wrap" },
          m: "20px auto",
          p: "4px",
          gap: { xs: "20px", sm: "0px", md: "50px" },
          width: "100%",
        }}
        data-testid="addline_change_plan_card_parent"
      >
        {plandata?.map((p: any, index: any) => {
          const { planCost, planPerLinesCost } = p?.unlimited
            ? calculateUnlimitedCost(p, 1)
            : calculateCost(p, 1);
          // const planPerLinesCost = priceCalculationHandler(p);
          const hotspotData = () => {
            if (p?.planData >= 50) {
              return 10;
            } else {
              return 5;
            }
          };
          return (
            <Card
              data-testid={`addline_change_plan_card_wrapper_${Number(index) + 1 || 1}`}
              id={`home${index}`}
              key={p.displayName}
              raised
              elevation={isMobile ? 0 : 8}
              sx={{
                borderRadius: "14px",
                minWidth: "252px",
                p: "20px 10px",
                width: "15%",
                boxShadow: "none !important",
                overflow: {
                  xs:
                    nutritionLabel.show && nutritionLabel.name === p?.name
                      ? "scroll"
                      : "scroll",
                  sm:
                    nutritionLabel.show && nutritionLabel.name === p?.name
                      ? "scroll"
                      : "scroll",
                },
                position: {
                  xs:
                    modalType === planModalType.changePlan &&
                    currentPlan?.displayName === p.displayName
                      ? "relative"
                      : "initial",
                  sm: "unset",
                },
                border:
                  modalType === planModalType.changePlan &&
                  currentPlan?.displayName === p.displayName
                    ? "1px solid var(--primary_color)"
                    : "1px solid var(--plan_card_border_color)",
                height:
                  nutritionLabel.show && nutritionLabel.name === p?.name
                    ? "370px"
                    : "100%",
                maxHeight:
                  nutritionLabel.show && nutritionLabel.name === p?.name
                    ? "400px"
                    : "74vh",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "&-ms-overflow-style:": {
                  display: "none",
                },
              }}
            >
              {modalType === planModalType.changePlan &&
              currentPlan?.displayName === p.displayName ? (
                <Box
                  sx={{
                    mt: { xs: "-8px", sm: "-32px" },
                    ml: { xs: "-12px", sm: "0px" },
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    pb: "32px",
                    position: { xs: "absolute", sm: "unset" },
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      backgroundColor: "var(--white)",
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      top: { xs: "-10px", sm: "unset" },
                      gap: "2px",
                      px: "5px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={tick_icon?.data?.attributes?.alternativeText}
                      src={tick_icon?.data?.attributes?.url || ""}
                      width="17px"
                      data-testid={`addline_change_plan_tick_icon_${Number(index) + 1 || 1}`}
                    />
                    <Typography
                      component="span"
                      sx={_.merge(
                        renderTextStyles(3, "14px", "medium-green", "21px"),
                        {
                          fontStyle: "italic",
                        }
                      )}
                      data-testid={`addline_change_plan_cp_${Number(index) + 1 || 1}`}
                    >
                      {current_plan}
                    </Typography>
                  </Typography>
                </Box>
              ) : null}
              <Box sx={{ minHeight: "335px" }}>
                <Typography
                  component={"h5"}
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { xs: "20px", md: "18px" },
                    color: "var(--text_color_12)",
                    fontWeight: "600",
                    my: "6px",
                    textAlign: "center",
                  }}
                  data-testid={`addline_change_plan_name_${Number(index) + 1 || 1}`}
                >
                  {p.displayName}
                </Typography>
                <Typography
                  component={"div"}
                  sx={{
                    fontFamily: "var(--font_family_Bold)",
                    fontSize: { xs: "18px", md: "20px" },
                    color: "var(--text_color)",
                    my: "6px",
                    textAlign: "center",
                  }}
                  data-testid={`addline_change_plan_data_${Number(index) + 1 || 1}`}
                >
                  {p.planData + p.dataUnit}
                  {p.planType === "LSD" ? "+" : ""}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "32px",
                      fontFamily: "var(--font_family_Bold)",
                      color: "var(--primary_color)",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "32px",
                        fontFamily: "var(--font_family_Bold)",
                        fontWeight: "var(--font_weight_5)",
                        color: "inherit",
                      }}
                      data-testid={`addline_change_plan_amount_${Number(index) + 1 || 1}`}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "32px",
                          fontFamily: "var(--font_family_Bold)",
                          color: "inherit",
                        }}
                        data-testid={`addline_change_plan_amount_sign_${Number(index) + 1 || 1}`}
                      >
                        {dollar}
                      </Typography>
                      {GetCurrencyFixedToTwo(
                        planPerLinesCost ? planPerLinesCost : 0,
                        p.currency ? p.currency : "usd",
                        true
                      )}
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Bold)",
                        fontWeight: "var(--font_weight_4)",
                        fontSize: "9.42px",
                        color: "var(--text_color_12)",
                      }}
                      data-testid={`addline_change_plan_per_month_text_${Number(index) + 1 || 1}`}
                    >
                      {per_month_text}
                    </Typography>
                    <Typography
                      component={"div"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "12px",
                        color: "var(--black)",
                        my: "0px",
                        fontWeight: 400,
                      }}
                      data-testid={`addline_change_plan_tax_text_${Number(index) + 1 || 1}`}
                    >
                      {tax_text1}
                    </Typography>
                    {/* {bundled_text && (
                    <Typography
                      component={"div"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "12px",
                        color: "var(--black)",
                        my: "10px",
                      }}
                    >
                      {bundled_text}
                    </Typography>
                  )} */}
                  </Typography>
                  {/* <Typography
                  component={"span"}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    pl: "25px",
                    my: "20px",
                  }}
                >
                  <Box
                    component="img"
                    alt={mbl_img?.data?.attributes?.alternativeText}
                    src={mbl_img?.data?.attributes?.url || ""}
                    width="15px"
                  />
                  <Typography
                    component={"span"}
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "13px",
                      color: "var(--button_secondary_color)",
                      ml: "15px",
                    }}
                  >
                    {p.planData + p.dataUnit + " " + (data ?? "")}
                  </Typography>
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "start",
                    pl: "25px",
                    my: "20px",
                  }}
                >
                  <Box
                    component="img"
                    alt={video_img?.data?.attributes?.alternativeText}
                    src={video_img?.data?.attributes?.url || ""}
                    width="15px"
                  />
                  <Typography
                    component={"span"}
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "13px",
                      color: "var(--button_secondary_color)",
                      ml: "15px",
                    }}
                  >
                    {p.streamType === "HD" ? hd_vid : sd_vid}
                  </Typography>
                </Typography> */}
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "15px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={content?.mbl_img1?.data?.attributes?.alternativeText}
                      src={content?.mbl_img1?.data?.attributes?.url || ""}
                      width="15px"
                      // className={p?.planType !== "HSD" ? mb_img : ""}
                      data-testid={`addline_change_plan_icon_1_${Number(index) + 1 || 1}`}
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "14px",
                        color: "var( --text_color)",
                        ml: "15px",
                        textAlign: "left",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          p?.planType === "HSD"
                            ? content?.res1?.buy_data
                            : content?.res1?.streaming?.replace(
                                /plandata/g,
                                p?.planData || ""
                              ),
                      }}
                      data-testid={`addline_change_plan_text_1_${Number(index) + 1 || 1}`}
                    ></Typography>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "15px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={
                        content?.hotspot_img?.data?.attributes?.alternativeText
                      }
                      src={content?.hotspot_img?.data?.attributes?.url || ""}
                      width="15px"
                      // className={p?.planType !== "HSD" ? mb_img : ""}
                      data-testid={`addline_change_plan_icon_2_${Number(index) + 1 || 1}`}
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var( --text_color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        textAlign: "left",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          p?.planType === "HSD"
                            ? cacheDataPlanSec
                              ? cacheDataPlanSec?.res2?.buy_data
                              : planCardContent?.res2?.buy_data
                            : cacheDataPlanSec
                            ? cacheDataPlanSec?.res2?.streaming?.replace(
                                /hot/g,
                                hotspotData || ""
                              )
                            : planCardContent?.res2?.streaming?.replace(
                                /hot/g,
                                hotspotData || ""
                              ),
                      }}
                      data-testid={`addline_change_plan_text_2_${Number(index) + 1 || 1}`}
                    ></Typography>
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      pl: "15px",
                      my: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={content?.mb_data?.data?.attributes?.alternativeText}
                      src={content?.mb_data?.data?.attributes?.url}
                      width="15px"
                      data-testid={`addline_change_plan_icon_3_${Number(index) + 1 || 1}`}
                    />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "var(--font_family_Regular)",
                        fontSize: "13px",
                        color: "var( --text_color)",
                        ml: "15px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        textAlign: 'left',
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          p?.planType === "HSD"
                            ? content?.res?.buy_data
                            : content?.res?.streaming?.replace(
                                /plandata/g,
                                p?.planData || ""
                              ),
                      }}
                      data-testid={`addline_change_plan_text_3_${Number(index) + 1 || 1}`}
                    ></Typography>
                  </Typography>
                </Box>
              </Box>
              {!currentLine?.reachPlanChangeDate &&
              amendedReachPlanId === p.name ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                      margin: "15px 0px",
                      backgroundColor: "var(--light_primary_color)",
                    }}
                  >
                    <CustToolTip
                      title={PoperOver(
                        <>
                          <Typography
                            sx={{
                              fontWeight: "var(--font_weight_1)",
                              fontSize: "12px",
                              fontFamily: "var(--font_family_Medium)",
                              lineHeight: "21px",
                              "& span": {
                                fontWeight: "var(--font_weight_2)",
                                fontFamily: "var(--font_family_Semibold)",
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: BrandNameUpdate(amendedPlan_desc),
                            }}
                            data-testid={`addline_change_plan_tooltip_${Number(index) + 1 || 1}`}
                          />
                        </>
                      )}
                      open={anchorEl === data.id ? true : false}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      placement="top-start"
                      sx={{
                        width: "390px",
                        color: " var(--white) !important",

                        "& .MuiTooltip-tooltip": {
                          fontFamily: "var(--font_family_Medium) !important",
                          color: " var(--white)",
                          backgroundColor: "var(--primary_color) !important",
                          padding: " 15px !important",
                          "a > p": {
                            fontFamily: " var(--font_family_Medium) !important",
                            fontSize: "14px",
                          },
                        },

                        "& .MuiTooltip-arrow": {
                          "::before": {
                            backgroundColor: "var(--primary_color) !important",
                          },
                        },
                      }}
                      // className={tooltip_style}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      }}
                    >
                      <Box
                        component="img"
                        alt={
                          content?.info_icon?.data?.attributes?.alternativeText
                        }
                        src={content?.info_icon?.data?.attributes?.url}
                        onMouseEnter={() => {
                          setAnchorEl(data.id);
                        }}
                        sx={{
                          width: "10px",
                          height: "10px",
                          marginRight: "10px",
                        }}
                        onMouseLeave={() => {
                          setAnchorEl(null);
                        }}
                        data-testid={`addline_change_plan_toottip_icon_${Number(index) + 1 || 1}`}
                      ></Box>
                    </CustToolTip>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "var(--primary_color)",

                        fontWeight: "var(--font_weight_1)",
                        textAlign: "center",
                        width: "fit-content",
                        maxWidth: "130px",
                        fontFamily: "var(--font_family_Medium)",
                      }}
                      data-testid={`addline_change_plan_new_pna_${Number(index) + 1 || 1}`}
                    >
                      {BrandNameUpdate(content?.plan_features?.new_plan_update)}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                  }}
                >
                  <ButtonCust
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    data-testid="selectPlanHandler"
                    onClick={(e: any) => {
                      selectPlanHandler(p);
                      let eventPrefix =
                        location.pathname === RoutingURLs.checkout
                          ? globalVal?.gtmEvents?.changeSelectPlan
                          : location.pathname === RoutingURLs.activation
                          ? isEsim
                            ? "mobile_dashboard_esim_"
                            : "mobile_dashboard_psim_"
                          : "";
                      gtmTagManager({
                        event: `${eventPrefix}${p.planData}${p.dataUnit}_select_plan`,
                        click_section: "checkout",
                      });
                    }}
                    sx={{
                      "&:disabled": {
                        backgroundColor: "var(--primary_color) !important",
                        opacity: "30%",
                        border: "none !important",
                      },
                    }}
                    disabled={
                      modalType === planModalType.changePlan &&
                      (currentPlan?.displayName === p.displayName ||
                        (!currentLine?.reachPlanChangeDate &&
                          amendedReachPlanId === p.name))
                        ? true
                        : false
                    }
                  >
                    {btn_txt}
                  </ButtonCust>
                </Box>
              )}
              <Divider
                sx={{
                  borderColor: "var(--cascading-white)",
                  mb: "10px",
                  mx: { xs: "-10px", sm: "-10px" },
                }}
              />
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mx: "auto",
                  gap: "2px",
                  cursor: "pointer",
                  width: "max-content",
                }}
                onClick={() => {
                  setNutritionLabel((prevState) =>  ({
                    ...prevState,
                    show: prevState.show
                      ? nutritionLabel.name === p?.name
                        ? false
                        : true
                      : true,
                    name: p?.name,
                  }));
                  handleAnswer();
                }}
              >
                <Typography
                  component={"span"}
                  sx={_.merge(
                    renderTextStyles(2, "14px", "primary_color", "21px"),
                    {
                      display: "flex",
                      justifyContent: "center",
                      mx: "auto",
                      gap: "2px",
                      cursor: "pointer",
                      width: "max-content",
                    }}
                    onClick={() => {
                      setNutritionLabel((prevState) => ({
                        ...prevState,
                        show: prevState.show ? false : true,
                        name: p?.name,
                      }));
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={_.merge(
                        renderTextStyles(2, "14px", "primary_color", "21px"),
                        {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                      )}
                    >
                      {cacheDataPlanSec
                        ? cacheDataPlanSec?.broadband
                        : planCardContent?.broadband}
                    </Typography>
                    {nutritionLabel.show ? (
                      <KeyboardArrowUp sx={{ color: "var(--primary_color)" }} />
                    ) : (
                      <KeyboardArrowDown
                        sx={{ color: "var(--primary_color)" }}
                      />
                    )}
                  </Box>
                </>
              )}
              {nutritionLabel.show ? (
                <NutritionLabel
                  {...{
                    plandata,
                    priceCalculationHandler,
                  }}
                  nlPlan={p}
                  plandata={p}
                  selectedLine={1}
                  NLContent={content}
                  isheading={true}
                  showAnswer={showAnswer}
                  handleAnswer={handleAnswer}
                />
              {/* ) : null} */}
              <NutritionLabel
                {...{
                  plandata,
                  priceCalculationHandler,
                }}
                nlPlan={p}
                plandata={p}
                selectedLine={1}
                NLContent={content}
                isheading={true}
                showAnswer={showAnswer}
                handleAnswer={handleAnswer}
                index={index}
              />
            </Card>
          );
        })}
      </Box>
    </SimpleModal>
  );
};
export default T3_AddLine_ChangePlan;
