import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonCust, ManageCard, SwitchCust } from "../../../widgets";
import styles from "./S2_PaymentReviewCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { paymentProps } from "./PaymentReviewCard";
import { CardTypes } from "../../../../enums";

function T2_PaymentReviewCard(props: paymentProps) {
  const {
    visa_card,
    firstRowTitle,
    firstRowValue,
    secondRowTitle,
    secondRowValue,
    loading,
    thirdRowTitle,
    confirmBtn,
    changeCardText,
    btnLoading,
    dataCap,
    availableOfferingsData,
    defaultCard,
    isChangeCard,
    setIsChangeCard,
    getCCDetails,
    american_express,
    discover,
    jcb,
    master_card,
  } = props;

  return (
    <>
      {!loading ? (
        <>
          <Box className={styles.main_container}>
            <Box className={styles.rows}>
              <Typography className={styles.displayPack}>
                {firstRowTitle}
                {dataCap ? dataCap / availableOfferingsData.servicePulse : null}
              </Typography>
              <Typography className={styles.displayPack}>
                {GetCurrencyFixedToTwo(firstRowValue)}
              </Typography>
            </Box>
            <Box className={styles.rows}>
              <Typography className={styles.displayPack}>
                {secondRowTitle}
              </Typography>
              <Typography className={styles.displayPack}>
                {GetCurrencyFixedToTwo(secondRowValue)}
              </Typography>
            </Box>
            <Box className={styles.rows}>
              <Typography className={styles.displayPack}>
                {thirdRowTitle}
              </Typography>
              <Typography className={styles.displayPack}>
                {GetCurrencyFixedToTwo(firstRowValue + secondRowValue)}
              </Typography>
            </Box>
            <Box className={styles.main_card}>
              <Box className={styles.card}>
                <Box
                  className={styles.displayPack}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                >
                  <img
                    src={
                      defaultCard?.type?.toUpperCase() === CardTypes?.VISA
                        ? visa_card?.url
                        : defaultCard?.type?.toUpperCase() ===
                          CardTypes?.MASTERCARD
                        ? master_card?.data?.attributes?.url
                        : defaultCard?.type?.toUpperCase() === CardTypes?.JCB
                        ? jcb?.data?.attributes?.url
                        : defaultCard?.type?.toUpperCase() ===
                            CardTypes?.AMERICANEXPRESS ||
                          defaultCard?.type?.toUpperCase() === CardTypes?.AMEX
                        ? american_express?.data?.attributes?.url
                        : discover?.data?.attributes?.url
                    }
                    alt={
                      defaultCard && defaultCard.type === CardTypes.VISA
                        ? visa_card?.data?.attributes?.alternativeText
                        : american_express?.data?.attributes?.alternativeText
                    }
                  />
                  <Typography
                    className={styles.displayPack}
                    sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                  >
                    {`${props?.mockCard}${defaultCard?.ccNumberMask}`}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                  onClick={() => setIsChangeCard(true)}
                  className={styles.changeCard}
                >
                  {changeCardText}
                </Typography>
              </Box>
            </Box>
          </Box>
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={props.onClick}
            sx={{
              width: { xs: "90%", sm: "100%" },
              margin: "0 auto",
              "&:hover": {
                backgroundColor: "var(--button_hover_color) !important",
                border: "1px solid var(--button_hover_color) !important",
              },
            }}
          >
            {btnLoading ? <CircularProgress /> : confirmBtn}
          </ButtonCust>
        </>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
      <ManageCard
        cardData={getCCDetails}
        showCardChangeModal={isChangeCard ? true : false}
        setShowCardChangeModal={() => setIsChangeCard(false)}
        isModalRequired={true}
        pageName={"services"}
        isDescription={true}
        payType={true}
      />
    </>
  );
}

export default T2_PaymentReviewCard;
