import { HomeBannerProps } from "./HomeBanner";
import { Box, Grid, Paper, Typography, Skeleton, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import Styles from "./S1_HomeBanner.module.scss";
import config from "../../../config/env.config.json";
import clsx from "clsx";
import {
  getResolutionDetails,
  updateSvgFillColor,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { CustomerStatus as CS } from "../../../enums/CustomerStatus";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { useAppSelector } from "../../../app/hooks";

const HomeBannerT1 = (props: HomeBannerProps) => {
  const {
    heading,
    sub_heading,
    button_text,
    banner_web,
    banner_mobile,
    banner_ipad,
    button_text2,
    list_items,
    heading2,
    list_image_svg,
  } = props?.bannerContent || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const { subDomainData } = useAppSelector((state: any) => state.homepage);
  const { pageName } = props;
  const {
    content_carousel,
    ticks_main_div,
    list_item_txt,
    button2,
    banner_image,
    content_carousel_mweb,
  } = Styles;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const { status } = customerDetails || {};
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: "relative",
        minHeight: { xs: "350px", sm: "300px", md: "300px", lg: "500px" },
      }}
    >
      {banner_web || banner_ipad || banner_mobile ? (
        <>
          <Paper
            sx={{ padding: 0 }}
            elevation={0}
            data-testid="banner_img_parent"
          >
            {(largeDesktop || desktop) &&
              generateImageContainer({
                data:
                  subDomainData &&
                  subDomainData?.data?.bannerImageUrl?.replace(/\s+/g, "")
                    ? subDomainData?.data?.bannerImageUrl?.replace(/\s+/g, "")
                    : banner_web,
                attrs: {
                  width: "100%",
                  height: "500px",
                },
                className: { banner_image },
              })}
            {ipad &&
              generateImageContainer({
                data:
                  subDomainData &&
                  subDomainData?.data?.bannerImageIpadUrl?.replace(/\s+/g, "")
                    ? subDomainData?.data?.bannerImageIpadUrl?.replace(
                        /\s+/g,
                        ""
                      )
                    : banner_ipad,
                attrs: {
                  width: "100%",
                  height: "600px",
                },
                className: { banner_image },
              })}
            {mobile &&
              generateImageContainer({
                data:
                  subDomainData &&
                  subDomainData?.data?.bannerImageMwebUrl?.replace(/\s+/g, "")
                    ? subDomainData?.data?.bannerImageMwebUrl?.replace(
                        /\s+/g,
                        ""
                      )
                    : banner_mobile,
                attrs: {
                  width: "100%",
                  height: "auto",
                },
                className: { banner_image },
              })}
          </Paper>
          <Grid
            sx={{
              position: mobile ? "unset" : "absolute",
              textAlign: {
                xs: "var(--main_banner_title_f_align_mobile)",
                sm: "left",
              },
            }}
            className={mobile ? content_carousel_mweb : content_carousel}
          >
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "baseline" },
                }}
              >
                {heading && (
                  <Typography
                    component="h1"
                    variant="h3"
                    // gutterBottom
                    className="main_banner_title"
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(
                        subDomainData?.data?.brandMainTag
                          ? subDomainData?.data?.brandMainTag
                          : heading
                      ),
                    }}
                    sx={{
                      paddingBottom: "18px",
                      fontSize: {xs: "20px !important", md: "24px !important"},
                      color: "var(--dark-charcoal-fade)",
                    }}
                    data-testid="banner_heading"
                  />
                )}
                {heading2 && (
                  <Typography
                    component="h3"
                    variant="h3"
                    gutterBottom
                    className="main_banner_title"
                    data-testid="banner_sub_heading"
                    sx={{
                      color: "var(--dark-charcoal-fade)",
                    }}
                  >
                    {BrandNameUpdate(heading)}
                  </Typography>
                )}
                {subDomainData?.data?.brandSubTag && (
                  <Typography
                    variant="h4"
                    component="h4"
                    className={clsx(ticks_main_div, "main_banner_sub_title")}
                    data-testid="banner_sub_heading"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: {xs: "14px !important", md: "20px !important"},
                      paddingBottom: "18px",
                      textAlign: mobile ? "center" : "",
                      color: "var(--dark-charcoal-fade)",
                    }}
                  >
                    {BrandNameUpdate(
                      subDomainData?.data?.brandSubTag
                        ? subDomainData?.data?.brandSubTag
                        : sub_heading
                    )}
                  </Typography>
                )}
                {subDomainData?.data?.bannerSubHeadingList?.length > 0 ? (
                  <Box
                    pb="18px"
                    pl={{ xs: mobile ? "0px" : ipad ? "0px" : "35px", md: "0" }}
                    sx={{
                      ml: { md: "unset" },
                      width: mobile ? "unset" : { xs: "90%", md: "100%" },
                    }}
                    data-testid="banner_list_parent"
                  >
                    {subDomainData?.data?.bannerSubHeadingList?.map(
                      (item: any, index: any) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // p: "6px 0px 0px",
                              fontSize: "20px !important",
                            }}
                            data-testid={`banner_list_child_${
                              Number(index) + 1 || 1
                            }`}
                          >
                            {/* {item?.image?.data?.attributes?.url ? (
                            <Box
                              component={"img"}
                              alt={BrandNameUpdate(
                                item?.image?.data?.attributes?.alternativeText
                              )}
                              src={item?.image?.data?.attributes?.url}
                              sx={{ width: "16px" }}
                              data-testid={"banner_list_icon_" + index}
                            />
                          ) : null} */}
                            <Box
                              component="div"
                              sx={{
                                display: "flex",
                                "& svg": {
                                  width: "16px",
                                },
                              }}
                              dangerouslySetInnerHTML={{
                                __html: updateSvgFillColor(
                                  list_image_svg,
                                  subDomainData?.data?.bannerHeadingColorCode ??
                                    "var(--dark-charcoal-fade)"
                                ),
                              }}
                              data-testid={"banner_list_icon_" + index}
                            />
                            <Box
                              className={list_item_txt}
                              data-testid={`banner_list_text_${
                                Number(index) + 1 || 1
                              }`}
                              sx={{
                                color: "var(--dark-charcoal-fade)",
                                fontSize: {xs: "14px !important", md: "20px !important"},
                              }}
                            >
                              {item}
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ) : null}
                {button_text && (
                  <ButtonCust
                    variantType={config.PRIMARY_BUTTON_TYPE}
                    onClick={props.onButton1Click}
                    sx={{
                      position: "relative",
                      display: "flex",
                      margin: { md: "unset" },
                      marginBottom: "15px",
                      width: "250px",
                      height: "45px !important",
                      color: "#ffffff",
                      fontSize: "14px !important",
                      fontFamily: "var(--font_family_Semibold) !important",
                    }}
                    disabled={
                      pageName === "PostActivationHome" &&
                      status !== CS.ACTIVE &&
                      status !== CS.SUSPENDED
                        ? true
                        : false
                    }
                    data-testid="banner_button_1"
                  >
                    {button_text}
                  </ButtonCust>
                )}
                {button_text2 && (
                  <Box
                    className={button2}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      justifyContent: { xs: "center", sm: "start" },
                      width: { sm: "max-content" },
                      position: { sm: "absolute" },
                      left: { sm: "6%" },
                      color: `${subDomainData?.data?.buttonTextColorCode} !important`,
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ fontFamily: "inherit" }}
                      onClick={() => props.onButton2Click()}
                      data-testid="banner_button_2"
                    >
                      {button_text2}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: { xs: "500px", sm: "300px", md: "300px", lg: "500px" },
          }}
        >
          <Stack spacing={1} width="70%">
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
          </Stack>
        </Box>
      )}
    </Container>
  );
};

export default HomeBannerT1;
