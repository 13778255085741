import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./OfferCode.module.scss";
import { string, object } from "yup";
import { useFormik } from "formik";
import { TextFieldCust } from "../../widgets";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Coupon } from "../../../features/checkout/checkoutSlice";
import { CouponTypes } from "../../../features/checkout/CouponTypes";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { updateSvgFillColor } from "../../../utils";

type Props = {
  appliedCouponAmount: null | number;
  removeCouponCallback: any;
  content?: any;
  addToCartApiWithOfferCode?: any;
};

export const OfferCodeCmp = (props: Props) => {
  const dispatch = useAppDispatch();
  const { coupon, isLoaded, errorCoupon } = useAppSelector(
    (state: any) => state.checkout
  );
  const [offer, setOffer] = useState<any>(false);
  const [result, setResult] = useState<any>(false);
  const [visible, setVisible] = useState(false);
  const [couponType, setCouponType] = useState(CouponTypes);
  const [error, setError] = useState<any>(false);

  const closeHandler = () => {
    setOffer(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (coupon) {
      formik.resetForm();
      setResult(true);
      setOffer(false);
    }
  }, [coupon]);

  useEffect(() => {
    if (errorCoupon) {
      formik.resetForm();
      setVisible(true);
      const timer = setTimeout(() => setVisible(false), 5000); // Hide after 5 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount or error change
    }
  }, [errorCoupon]);

  const validateOfferCodeSchema = object({
    referrelCoupon: string().trim().required(props.content?.promo_f_err),
  });
  const applyOffer = () => {
    localStorage.removeItem("reachCoupon");
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Apply Offer",
    //   label: "",
    // });
    dispatch(Coupon({ couponCode: formik.values.referrelCoupon })).then((res) => {
      // if(res?.payload?.data?.status === "SUCCESS"){
      //   props.addToCartApiWithOfferCode(formik.values.referrelCoupon)
      // }
    }
  );
  };
  const formik: any = useFormik({
    initialValues: { referrelCoupon: "" },
    validationSchema: validateOfferCodeSchema,
    onSubmit: () => {
      applyOffer();
    },
  });
  const couponHandler = (isSendDevStore?: boolean) => {
    // isSendDevStore && props.addToCartApiWithOfferCode(formik.values.referrelCoupon)
    setOffer(true);
    setResult(false);
    localStorage.removeItem("reachCoupon");
    localStorage.removeItem("lpCoupon");
    // pushTrackingEventsToGAAndFB({
    //   category: "Swift Purchase",
    //   actionType: "Already  Have An Offer Or Referral Code?",
    //   label: "",
    // });
    props.removeCouponCallback();
  };
  // const savingsIcon = props.content?.savings_ico?.data?.attributes?.url;
  // const SavingsTcon_alt =
  //   props.content?.savings_ico?.data?.attributes?.alternativeText;

  return (
    <Grid container data-testid="promo_parent">
      {!offer && !result && props.appliedCouponAmount === 0 ? (
        <Grid item xs={12}>
          <Grid
            item
            className={styles.referal}
            sx={{ cursor: "pointer" }}
            data-testid="promo_coupon_handler"
            onClick={() => couponHandler(false)}
          >
            <Grid item className={styles.left_div}>
              {/* {savingsIcon ? (
                <Box
                  component="img"
                  alt={SavingsTcon_alt}
                  width="21px"
                  height="22px"
                  margin-right="10px"
                  src={savingsIcon}
                />
              ) : null} */}
              <Box
                component="div"
                sx={{
                  marginRight: "10px",
                  display: "flex",
                  cursor: "pointer",
                  "& svg": {
                    width: "21px",
                    height: "22px",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: updateSvgFillColor(
                    props.content?.savings_icon,
                    "var(--primary_color)"
                  ),
                }}
                data-testid="promo_coupon_saving_icon"
              />
              <Box
                className={styles.referal_text}
                data-testid="promo_coupon_ref_label"
              >
                {props.content?.ref_label}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {(offer && !result && props.appliedCouponAmount === 0) ||
      (coupon && coupon.expired) ? (
        <Grid item xs={12}>
          <Grid
            item
            className={styles.referal}
            style={{ padding: "0px", cursor: "pointer" }}
          >
            <Grid item className={styles.left_div}>
              {/* {savingsIcon ? (
                <img
                  height="22px"
                  width="21px"
                  alt={SavingsTcon_alt}
                  src={savingsIcon}
                  style={{ padding: "10px 0px 10px 10px" }}
                />
              ) : null} */}
              <Box
                component="div"
                sx={{
                  padding: "10px 0px 10px 10px",
                  display: "flex",
                  "& svg": {
                    width: "21px",
                    height: "22px",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: updateSvgFillColor(
                    props.content?.savings_icon,
                    "var(--primary_color)"
                  ),
                }}
                data-testid="promo_coupon_sav_icon"
              />
              {coupon && coupon?.delayByMonths ? (
                <Box
                  className={styles.delay_coupon_text}
                  data-testid="promo_coupon_coupon_text"
                >
                  {couponType && couponType[coupon && coupon?.type].message}{" "}
                  {coupon && coupon?.coupon} {props.content?.ref_r1}{" "}
                  {coupon && coupon?.delayByMonths} {props.content?.ref_r2}{" "}
                </Box>
              ) : (
                <Box>
                  {/* <Box className={styles.referal_text}>
                  {props.content?.savings_txt}
                </Box> */}
                  <Box
                    className={styles.referal_text_light}
                    data-testid="promo_coupon_texfield_wrapper"
                    sx={{
                      "&.TextField .MuiFormHelperText-root": {
                        borderColor: "var(--white) !important",
                      },
                      "& fieldset": {
                        borderColor: "var(--white) !important",
                      },
                      "& input": {
                        padding: "7px 14px !important",
                        borderColor: "var(--white) !important",
                      },
                      "& ::placeholder": {
                        fontSize: "13px !important",
                        fontWeight: "400 !important",
                        color: "#5A5A5A !important",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderColor: "var(--white) !important",
                        "&:hover": {
                          borderColor: "var(--white) !important",
                        },
                        "&.Mui-focused": {
                          borderColor: "var(--white) !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "var(--white) !important",
                          "&:hover": {
                            borderColor: "var(--white) !important",
                          },
                          "&.Mui-focused": {
                            borderColor: "var(--white) !important",
                          },
                        },
                      },
                    }}
                  >
                    <TextFieldCust
                      name="referrelCoupon"
                      placeholder={props.content?.ref_field_lable}
                      onChange={formik.handleChange}
                      value={formik.values.referrelCoupon}
                      data-testid="promo_coupon_texfield"
                    />
                    <Box
                      className={styles.error_message}
                      data-testid="promo_coupon_error"
                    >
                      {formik.touched?.Coupon && formik.errors?.Coupon}
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Box
              className={styles.right_div}
              style={{
                background: "var(--primary_color)",
                color: "var(--white)",
                padding: "0px 25px",
                cursor:
                  formik?.values?.referrelCoupon?.length > 0
                    ? "pointer"
                    : "not-allowed",
                opacity: formik?.values?.referrelCoupon?.length > 0 ? 1 : 0.5,
              }}
            >
              {/* {coupon &&
              props.appliedCouponAmount &&
              props.appliedCouponAmount > 0 && (
                <div className={styles.cost}>
                  {GetCurrencyFixedToTwo(props.appliedCouponAmount)}
                </div>
              )} */}
              {/* <Box className={styles.remove} onClick={couponHandler}> */}
              <Box
                className={styles.input_group_gb}
                style={{
                  background: "var(--primary_color)",
                  color: "var(--white)",
                  height: "44px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={formik.handleSubmit}
                data-testid="promo_coupon_button"
              >
                {isLoaded ? (
                  <CircularProgress
                    sx={{ color: "var(--white)" }}
                    data-testid="promo_coupon_progress_bar"
                  />
                ) : (
                   props.content?.ref_btn_save_txt ?? "Apply"
                )}
                {/* {'Apply' ?? props.content?.ref_btn_save_txt} */}
              </Box>
              {/* </Box> */}
            </Box>
          </Grid>
        </Grid>
      ) : null}
      {errorCoupon && visible && <span className={styles.error_coupon}>{errorCoupon}</span>}
      {coupon && props.appliedCouponAmount && props.appliedCouponAmount > 0 ? (
        <Grid item xs={12}>
          <Grid item className={styles.referal}>
            <Grid item className={styles.left_div}>
              {/* {savingsIcon ? (
                <img
                  height="22px"
                  width="21px"
                  alt={SavingsTcon_alt}
                  src={savingsIcon}
                />
              ) : null} */}
              <Box
                component="div"
                sx={{
                  display: "flex",
                  marginRight: "10px",
                  "& svg": {
                    width: "21px",
                    height: "22px",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: updateSvgFillColor(
                    props.content?.savings_icon,
                    "var(--primary_color)"
                  ),
                }}
                data-testid="promo_coupon_savings_icon"
              />
              {coupon && coupon?.delayByMonths ? (
                <Box
                  className={styles.delay_coupon_text}
                  data-testid="promo_coupon_delay_coupon_text"
                >
                  {couponType && couponType[coupon && coupon?.type].message}{" "}
                  {coupon && coupon?.coupon} {props.content?.ref_r1}{" "}
                  {coupon && coupon?.delayByMonths} {props.content?.ref_r2}{" "}
                </Box>
              ) : (
                <Box>
                  {/* <Box className={styles.referal_text}>
                    {props.content?.savings_txt}
                  </Box> */}
                  <Box
                    className={styles.referal_text_light}
                    data-testid="promo_coupon_referal_text_light"
                  >
                    <span
                      style={{ color: "var(--text_color)" }}
                      data-testid="promo_coupon_coupon_message"
                    >
                      {couponType &&
                        couponType[coupon && coupon?.type]?.message}{" "}
                      {coupon && coupon?.name} {props.content?.applied_txt}.
                    </span>{" "}
                    {/* {coupon &&
                      props.appliedCouponAmount &&
                      props.appliedCouponAmount > 0 &&
                      GetCurrencyFixedToTwo(props.appliedCouponAmount)}{" "}
                    off */}
                  </Box>
                </Box>
              )}
            </Grid>
            <Box className={styles.right_div}>
              {/* {coupon &&
                props.appliedCouponAmount &&
                props.appliedCouponAmount > 0 && (
                  <div className={styles.cost}>
                    {GetCurrencyFixedToTwo(props.appliedCouponAmount)}
                  </div>
                )} */}
              <Box
                className={styles.remove}
                onClick={()=>couponHandler(true)}
                data-testid="promo_coupon_remove_text"
              >
                {props.content?.remove_txt}
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      {error && (
        <Typography
          sx={{
            color: "var(--danger)",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "var(--poppins_medium)",
            paddingTop: "4px",
          }}
        >
          {errorCoupon}
        </Typography>
      )}
    </Grid>
  );
};
