import "./SearchBox.scss";
import { TextField, Autocomplete, Box, CircularProgress } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import { zipcodePattern } from "../../../utils/regexPatterns";

type Props = {
  value?: string | null;
  onChange?: any;
  label?: string;
  placeholder?: string;
  onRequest?: any;
  style?: any;
  onCancel?: any;
  dropdownList?: any;
  optionLabel?: any;
  logo?: any;
  renderOption?: any;
  sx?: any;
  no_option?: any;
  inputChangeHandler?: any;
  onCloseHandler?: any;
  onTextChange?: any;
  isinternational?: boolean;
  className?: any;
};

export const SearchBoxWithDropdown = (props: Props) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: any, val: string) => {
    // Allow only up to 5 digits and no non-numeric input
    if (props?.isinternational) {
      if (zipcodePattern.test(val)) {
        setInputValue(val); // Update the controlled input value
        props.inputChangeHandler(e, val); // Call the inputChangeHandler if valid
      }
    } else {
      setInputValue(val); // Update the controlled input value
      props.inputChangeHandler && props.inputChangeHandler(e, val);
    }
  };
  return (
    <Autocomplete
      className={`SearchBoxDropdown ${props.className ? props.className : ""}`}
      options={props?.dropdownList}
      getOptionLabel={props?.optionLabel}
      inputValue={inputValue} // Use controlled input value
      autoHighlight
      onChange={(e: any, val: any) => props.onChange(e, val)}
      value={props.value ? props.value : null}
      renderOption={props?.renderOption}
      onInputChange={handleInputChange}
      onClose={(e: any, val: any) => props.onCloseHandler(e, val)}
      clearOnBlur={false} // Prevent clearing on blur
      noOptionsText={
        props?.dropdownList?.length !== 0 ? (
          props.no_option
        ) : (
          <Box textAlign={"center"}>
            <CircularProgress sx={{ color: "var(--primary_color)" }} />
          </Box>
        )
      }
      popupIcon={
        // props.logo ? (
        //   <img
        //     src={props.logo.data.attributes.url}
        //     alt={props.logo.data.attributes.alternativeText}
        //   />
        // ) : (
        //   <Search color="inherit" height="16px" width="16px" />
        // )
        <Search
          sx={{ color: "var(--primary_color)" }}
          height="16px"
          width="16px"
        />
      }
      sx={{
        ...props.sx,
        width: "100%",
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            className="SearchBoxTextField"
            placeholder={props.placeholder}
            onChange={(e: any) => props.onTextChange(e)}
            variant="filled"
            sx={props.sx}
            {...params}
            data-testid="country_list_dropdown_texfield"
          />
        );
      }}
    />
  );
};
