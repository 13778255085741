import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";
import { ButtonCust, Divider as Divide, SimpleModal } from "../../../widgets";
import styles from "./S1_CountryDataModal.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";

const CountryDataModal = (props: any) => {
  const { name, maxIldCostForVoiceOut } = props.selectedCountry;

  const { view_Rates } = props;
  return (
    <Box>
      <SimpleModal
        isShowModal={props.isCountryModal}
        onCloseModal={props.setIsCountryModal}
        showClose={true}
        sx={{ top: " 20%", borderRadius: "6px", width: "100%" }}
      >
        <Box className={styles.modal_main_container}>
          <Typography className={styles.modal_country_name}>{name}</Typography>
          <Box
            sx={{
              width: "80px",
              height: "5px",
              borderRadius: "3px",
              background: "#333",
              margin: "0px auto",
              textAlign: "center",
            }}
          ></Box>
          <Box className={styles.section}>
            <Typography className={styles.modal_sub_title}>
              {view_Rates?.country_modal.first_subHead}
            </Typography>
            <Divider />

            <Typography className={styles.modal_data_text}>
              <span>
                {view_Rates.country_modal.voice}{" "}
                <Box
                  pl={0.5}
                  component="img"
                  src={view_Rates?.icons?.data[3].attributes.url}
                  alt={view_Rates?.icons?.data[3].attributes.alternativeText}
                />
              </span>
              -
              <span style={{ fontSize: "18px" }}>
                {GetCurrencyFixedToTwo(maxIldCostForVoiceOut)}/min
              </span>
            </Typography>
            <Typography className={styles.footer}>
              {view_Rates.country_modal.footer_txt}
            </Typography>
          </Box>
          <Box></Box>
        </Box>
      </SimpleModal>
    </Box>
  );
};

export default CountryDataModal;
