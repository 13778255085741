import { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import { useAppDispatch } from "../../../../app/hooks";
import AppConfig from "../../../../config/app.config.json";
import Config from "../../../../config/env.config.json";
import GlobalRoamingPack from "../GlobalRoamingPack/GlobalRoamingPack";
import {
  getCounriesList,
  getCounriesListATT,
  getCounriesRatesATT,
} from "../../../../features/internationalService/internationalServiceSlice";
import { getAvailableOfferings } from "../../../../features/services/servicesSlice";
import _ from "lodash";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
import { NotificationCust } from "../../../widgets";

const CountryListComp = isTemplateExisits(
  AppConfig.PostActivationServices.IRCountryList
)
  ? require(`./${AppConfig.PostActivationServices.IRCountryList.template}_CountryList`)
      .default
  : null;

const CountryList = (props: any) => {
  const { setIsCountryList, content } = props;

  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [isCalanderModal, setIsCalanderModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [isGlobalRoamingPack, setIsGlobalRoamingPack] = useState<any>(false);
  const [getCountryList, setGetCountryList] = useState<any>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Config.IS_ATT_COUNTRY_LIST) {
      dispatch(getCounriesListATT()).then((res) => {
        let cList = res?.payload?.data?.data?.countriesList;
        cList = _.sortBy(cList, "countryName");
        cList = cList.filter(
          (item: any) => item.status === 1 && item.zone !== "X"
        );
        setGetCountryList(cList);
      });
    } else {
      dispatch(getCounriesList()).then((res) => {
        let cList = res?.payload?.data?.data?.countriesList;
        cList = _.sortBy(cList, "name");
        setGetCountryList(cList);
      });
    }
  }, []);

  const setSelectedCountryHandler = (e: any, val: any) => {
    if (val) {
      setSelectedCountry(val);
      const countryObject = {
        code: val.code ? val.code : val.countryCode,
        name: val.name ? val.name : val.countryName,
      };
      localStorage.setItem("prRoamingCountry", JSON.stringify(countryObject));
      if (content?.user_data?.is_view_rates) {
        dispatch(
          getCounriesRatesATT({
            type: "IR",
            countries: [countryObject?.code],
            operator: val.mvne || "",
          })
        ).then((res: any) => {
          if (res?.error) {
            NotificationCust({
              message: "Oops! Something went wrong. Please try again",
              type: "danger",
              duration: 2000,
              id: "Error",
              showIcon: true,
            });
          }
        });
      }
    } else {
      setSelectedCountry(""); // Clear the selected country in your component's state
      localStorage.removeItem("prRoamingCountry");
    }
  };

  const formatDateForUI = (e: any) => {
    const date = new Date(e);
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const formatDateForAPI = (e: any) => {
    if (e) {
      const parsedDate = parse(e, "MMMM dd, yyyy", new Date());
      const formatted = format(parsedDate, "yyyy-MM-dd");
      return formatted;
    }
    return;
  };
  const calanderOnChangeHandler = (e: any) => {
    if (e == null) {
      setSelectedDate("");
    } else {
      const formattedForUi = formatDateForUI(e);
      setSelectedDate(formattedForUi);
    }
  };

  const calanderSubmitHandler = () => {
    setIsCalanderModal(false);
    const formattedForApi: any = formatDateForAPI(selectedDate);
    localStorage.setItem("postActivationDate", formattedForApi);
  };

  const continueSubmitHandler = () => {
    setIsGlobalRoamingPack(true);
    const payload = {
      type: "IR",
      countries: [
        selectedCountry.code
          ? selectedCountry.code
          : selectedCountry.countryCode,
      ],
      operator: Config.OPERATOR,
    };
    dispatch(getAvailableOfferings(payload));
  };

  const renderOption = (props: any, option: any) => {
    if (props.customRenderOption) {
      return props.customRenderOption(props, option);
    } else {
      return (
        <span
          {...props}
          style={{ fontFamily: "var(--font_family_Medium)", fontSize: "16px" }}
        >
          {option.name ? option.name : option.countryName}
        </span>
      );
    }
  };

  return (
    <>
      {!isGlobalRoamingPack ? (
        <CountryListComp
          isCalanderModal={isCalanderModal}
          setIsCalanderModal={setIsCalanderModal}
          getCountryList={getCountryList}
          setSelectedCountryHandler={setSelectedCountryHandler}
          calanderOnChangeHandler={calanderOnChangeHandler}
          calanderSubmitHandler={calanderSubmitHandler}
          continueSubmitHandler={continueSubmitHandler}
          renderOption={renderOption}
          selectedDate={selectedDate}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          {...props}
        />
      ) : (
        <GlobalRoamingPack
          props={props}
          setIsGlobalRoamingPack={setIsGlobalRoamingPack}
          setIsCountryList={setIsCountryList}
          selectedDateNew={selectedDate}
        />
      )}
    </>
  );
};

export default CountryList;
