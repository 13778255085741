import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import Styles from "./S3_IRPurchaseHistory.module.scss";
import {
  getMBorGBFromBytes,
  getResolutionDetails,
} from "../../../../utils/commonFunctions";
import { useEffect } from "react";

const T3_IRDataUsage = (props: any) => {
  const {
    usage_title,
    data_usage,
    voice_usage,
    sms_usage,
    mms_usage,
    slash,
    refresh_icon,
  } = props.content;
  const { data, packId, handleDataUsage } = props;
  const { vertical_line } = Styles;
  const { getPackDataUsage, getPackDataUsageError, getPackDataUsagePending } =
    useAppSelector((state) => state.services);
  const { mobile } = getResolutionDetails();

  useEffect(() => {
    handleDataUsage(data);
  }, []);

  return (
    <Box
      sx={{
        px: "25px",
      }}
      data-testid={`IR_data_usage_${Number(packId) + 1 || 1}`}
    >
      <Typography
        component="div"
        sx={{
          fontFamily: "var(--font_family_Medium)",
          fontWeight: "var(--font_weight_2)",
          color: "var(--text_color)",
          textAlign: "center",
          mb: "10px",
          p: "10px 40px",
        }}
        data-testid={`IR_data_usage_title_${Number(packId) + 1 || 1}`}
        onClick={() => handleDataUsage(data)}
      >
        {usage_title}
        {refresh_icon && (
          <Box
            component="img"
            alt={refresh_icon?.data.attributes?.alternativeText || ""}
            src={refresh_icon?.data.attributes?.url || ""}
            ml="10px"
            position="relative"
            top="2px"
            sx={{
              cursor: "pointer",
              width: "18px",
              height: "18px",
            }}
            data-testid={`IR_data_usage_ref_icon_${Number(packId) + 1 || 1}`}
            onClick={() => handleDataUsage(data)}
          />
        )}
      </Typography>

      <>
        {getPackDataUsagePending && data.id === packId ? (
          <Box
            sx={{
              textAlign: "center",
              mb: "30px",
            }}
          >
            <CircularProgress
              sx={{
                color: "var(--text_color)",
              }}
              style={{
                width: "25px",
                height: "25px",
              }}
              data-testid={`IR_data_usage_progress_${Number(packId) + 1 || 1}`}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "30px",
              px: "40px",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
                display: "flex",
                mb: { xs: "10px", md: 0 },
              }}
            >
              <Box sx={{ pb: "5px" }} data-testid={`IR_data_usage_text_${Number(packId) + 1 || 1}`}>{data_usage} </Box>
              {(getPackDataUsage?.dataUsage?.international ||
                getPackDataUsage?.dataUsage?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                    mb: { xs: "10px", md: 0 },
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_2)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                      margin: "-2px 0px 0px 5px",
                    }}
                    data-testid={`IR_data_usage_int_${Number(packId) + 1 || 1}`}
                  >
                    {getPackDataUsage?.dataUsage?.international === 0
                      ? parseInt(
                          getMBorGBFromBytes(
                            getPackDataUsage?.dataUsage?.international
                          )
                        )
                      : getMBorGBFromBytes(
                          getPackDataUsage?.dataUsage?.international
                        )}
                    {slash}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                    }}
                    data-testid={`IR_data_usage_total_gb_${Number(packId) + 1 || 1}`}
                  >
                    {data.totalDataGB}
                  </Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
            {!mobile ? <Box className={vertical_line}></Box> : null}
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
                display: "flex",
                mb: { xs: "10px", md: 0 },
              }}
            >
              <Box sx={{ pb: "5px" }} data-testid={`IR_data_usage_voice_${Number(packId) + 1 || 1}`}>{voice_usage}</Box>
              {(getPackDataUsage?.voiceMinutes?.international ||
                getPackDataUsage?.voiceMinutes?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_2)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                      margin: "-2px 0px 0px 5px",
                    }}
                    data-testid={`IR_data_usage_voice_min_int_${Number(packId) + 1 || 1}`}
                  >
                    {getPackDataUsage?.voiceMinutes?.international}
                    {slash}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                    }}
                    data-testid={`IR_data_usage_voice_min_${Number(packId) + 1 || 1}`}
                  >
                    {data.voiceMins}
                  </Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
            {!mobile ? <Box className={vertical_line}></Box> : null}
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
                display: "flex",
                mb: { xs: "10px", md: 0 },
              }}
            >
              <Box sx={{ pb: "5px" }} data-testid={`IR_data_usage_sms_usage_${Number(packId) + 1 || 1}`}>{sms_usage}</Box>
              {(getPackDataUsage?.sms?.international ||
                getPackDataUsage?.sms?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_2)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                      margin: "-2px 0px 0px 5px",
                    }}
                    data-testid={`IR_data_usage_sms_int_${Number(packId) + 1 || 1}`}
                  >
                    {getPackDataUsage?.sms?.international}
                    {slash}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                    }}
                    data-testid={`IR_data_usage_data_sms_${Number(packId) + 1 || 1}`}
                  >
                    {data.sms}
                  </Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
            {!mobile ? <Box className={vertical_line}></Box> : null}
            <Box
              sx={{
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_0)",
                fontSize: "14px",
                color: "var(--text_color)",
                display: "flex",
                mb: { xs: "10px", md: 0 },
              }}
            >
              <Box sx={{ pb: "5px" }} data-testid={`IR_data_usage_mms_usage_${Number(packId) + 1 || 1}`}>{mms_usage}</Box>
              {(getPackDataUsage?.mms?.international ||
                getPackDataUsage?.mms?.international === 0) &&
              data.id === packId ? (
                <Box
                  sx={{
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_2)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                      margin: "-2px 0px 0px 5px",
                    }}
                    data-testid={`IR_data_usage_$mms_int_{Number(packId) + 1 || 1}`}
                  >
                    {getPackDataUsage?.mms?.international}
                    {slash}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      fontSize: "16px",
                      color: "var(--primary_color)",
                    }}
                    data-testid={`IR_data_usage_data_mms_${Number(packId) + 1 || 1}`}
                  >
                    {data.mms}
                  </Box>
                </Box>
              ) : (
                "-"
              )}
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};

export default T3_IRDataUsage;
