import { ButtonCust, SimpleModal } from "../../../widgets";
import { Box } from "@mui/material";
import EnvConfig from "../../../../config/env.config.json";

const TermsAndCondition = (props: any) => {
  const { terms, termsAndCondition, okay } = props.content;
  const { openModal, setOpenModal } = props;

  return (
    <SimpleModal
      isShowModal={openModal ? true : false}
      onCloseModal={() => setOpenModal(false)}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "80%", lg: "40%", xl: "45%" },
        borderRadius: "4px",
      }}
      data-testid="terms_cdn_wrapper"
    >
      <Box
        sx={{
          fontSize: "22px",
          fontWeight: "var(--font_weight_8)",
          color: "var(--tab_color_2)",
          fontFamily: "var(--font_family_medium)",
        }}
        data-testid="terms_cdn_title"
      >
        {terms}
      </Box>
      <Box
        sx={{
          width: "80px",
          height: "5px",
          borderRadius: "3px",
          background: "#333",
          margin: "20px auto",
          textAlign: "center",
        }}
      ></Box>
      <ol style={{ paddingLeft: "37px" }}>
        {termsAndCondition?.lists?.map((data: any, index: number) => (
          <li
            key={index}
            style={{
              fontSize: "18px",
              fontWeight: "var(--font_weight_1)",
              color: "var(--tab_color_2)",
              fontFamily: "var(--font_family_medium)",
              lineHeight: "32px",
              width: "100%",
              listStyleType: "decimal",
            }}
            data-testid={`terms_cdn_list_${Number(index) + 1 || 1}`}
          >
            {data}
          </li>
        ))}
      </ol>
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        sx={{
          width: "300px",
          mt: "25px",
        }}
        data-testid="terms_cdn_btn"
        onClick={() => setOpenModal(false)}
      >
        {okay}
      </ButtonCust>
    </SimpleModal>
  );
};

export default TermsAndCondition;
