import axios from "./axios";
import setAuthToken from "./auth";
import { v4 as uuidv4 } from "uuid";
import axiosStrapi from "./axiosStrapi";
import axiosShopWare from "./axiosShopWare";
import axiosShopWareCustom from "./axiosShopwareCustom";
import EnvConfig from "../config/env.config.json";
import getAnnonymousUser from "./getAnnonymousUser";
import Cookies from "js-cookie";
import { getBrowserAndOsDetails } from "../utils/commonFunctions/getBrowserAndOsDetails";
import { LOGIN_PROVIDER } from "../enums";
import sha256 from "crypto-js/sha256";

type Params = {
  method: string;
  url: string;
  body?: any;
  token?: string;
  params?: any;
  headers?: any;
  auth?: boolean;
  other?: boolean;
  other1?: string;
  contentType?: string;
  isStrapiApi?: boolean;
  tenantId?: any;
  isShopWare?: boolean;
  domain?: string;
  isBearerTokenRequired?: boolean;
};

function XORString(string1: string, string2: string) {
  let result = "";
  for (let i = 0; i < string1.length; i++) {
    result += String.fromCharCode(
      string1.charCodeAt(i) ^ string2.charCodeAt(i % string2.length)
    );
  }
  return result;
}
function getPreSharedKey(): string {
  const key: any = process.env.REACT_APP_EVAL_TOKEN;
  return key.trim();
}
class ApiJunction {
  async makeRequest(params: Params) {
    const resToken = await setAuthToken();
    let customerDetail: any = localStorage.getItem("customerDetail");
    let subDomainData: any = localStorage.getItem("subDomainData");
    customerDetail = JSON.parse(customerDetail);
    subDomainData = JSON.parse(subDomainData);
    let details = getBrowserAndOsDetails();
    if (params.isStrapiApi) {
      return axiosStrapi.get(params.url, { params: params.params });
    }
    let token = resToken;
    if (!token) {
      token = await getAnnonymousUser();
    }
    if (params.isShopWare) {
      let customAxios: any =
        params?.domain === "shopware" ? axiosShopWare : axiosShopWareCustom;
      let swContextToken = Cookies.get("swContextToken");
      const headers = {
        "sw-access-key": subDomainData?.shopwareAccessKey,
        ...(swContextToken && { "sw-context-token": swContextToken }),
      };
      if (params.isBearerTokenRequired)
        customAxios.defaults.headers.common[
          "authorization"
        ] = `Bearer ${token}`;
      // axios.defaults.headers.post["sw-access-key"] =
      //   subDomainData?.shopwareAccessKey;
      // axios.defaults.headers.post["sw-context-token"] =
      //   swContextToken && swContextToken;
      if (params.contentType) {
        axios.defaults.headers.post["Content-Type"] = params.contentType;
      } else {
        axios.defaults.headers.common["Content-Type"] = `application/json`;
      }
      if (params.method === "post") {
        return customAxios
          .post(params.url, params.body, {
            headers: headers,
          })
          .then((res: any): any => {
            return res;
          })
          .then((err: any) => {
            return err;
          });
      } else if (params.method === "get") {
        return customAxios.get(params.url, { headers: headers });
      } else if (params.method === "patch") {
        return customAxios.patch(params.url, params.body, {
          headers: headers,
        });
      } else if (params.method === "delete") {
        return customAxios.delete(params.url, {
          headers: headers,
        });
      }
    }
    axios.defaults.headers.common["txnid"] = uuidv4();
    axios.defaults.headers.common["x-reach-mvne"] = EnvConfig.OPERATOR;
    let ssoToken = localStorage.getItem("ssoAccessToken");
    if (EnvConfig?.IS_SSO_LOGIN && ssoToken) {
      axios.defaults.headers.common[
        "authorization"
      ] = `Bearer ${true}|${ssoToken}`;
    } else {
      axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    }
    if (params.contentType) {
      axios.defaults.headers.post["Content-Type"] = params.contentType;
    } else {
      axios.defaults.headers.common["Content-Type"] = `application/json`;
    }
    if (params.other) {
      axios.defaults.headers.common["x-is-primary"] = params.other
        ? "Yes"
        : "No";
    }
    if (params.other1) {
      axios.defaults.headers.common["x-reach-mvne"] = params.other1
        ? params.other1
        : "";
    }
    if (subDomainData?.ptnrTntId) {
      axios.defaults.headers.common["x-partner-tenant-id"] =
        subDomainData.ptnrTntId;
    }

    if (EnvConfig?.IS_TOKEN_ID_REQD_IN_HEADERS) {
      const date = Date.now();
      let firstName = customerDetail?.firstName || "";
      let lastName = customerDetail?.lastName || "";
      let emailId = customerDetail?.emailId || "";
      let body = params?.method === "get" ? "" : JSON.stringify(params?.body);
      const xreach = `${firstName ? firstName.toLowerCase() : ""}${
        lastName ? lastName?.toLowerCase() : ""
      }|${emailId ? emailId : ""}|customer|${details.browser}/${
        details.browserVersion
      }|${details.operatingSystem}/${details.osVersion}|${
        EnvConfig?.IS_SSO_LOGIN ? LOGIN_PROVIDER.SSO : LOGIN_PROVIDER.FIREBASE
      }`.trim();
      const headersToHash = JSON.stringify({
        xReachSrc: `web|${xreach.replace(/ /g, "")}`,
        authorization: `Bearer ${
          EnvConfig?.IS_SSO_LOGIN ? ssoToken || token : token
        }`,
        xReachDate: date?.toString(),
      })?.trim();

      const preDefinedKey = getPreSharedKey();
      const queryParams = params?.url.split("?")[1];
      const param = queryParams ? queryParams : "";
      const path = params.url.split("?")[0];
      const url = `${process.env.REACT_APP_SERVER_ADDRESS}${path}`;
      let dataToHash;
      if (body) {
        dataToHash = `${url.replace(/ /g, "")}${headersToHash}${body}${param}`;
      } else {
        dataToHash = `${url.replace(/ /g, "")}${headersToHash}${param}`;
      }

      const dataToHashPostXOR = XORString(dataToHash, preDefinedKey);
      const hash: any = sha256(dataToHashPostXOR);
      axios.defaults.headers.common["x-reach-tokenid"] = hash;
      axios.defaults.headers.common["x-reach-date"] = date;
      axios.defaults.headers.common[
        params.auth ? "x-account-src" : "x-reach-src"
      ] = params.auth
        ? `reach`
        : `web|${firstName ? firstName.toLowerCase() : ""}${
            lastName ? lastName.toLowerCase() : ""
          }|${emailId ? emailId : ""}|customer|${details.browser}/${
            details.browserVersion
          }|${details.operatingSystem}/${
            details.osVersion ? details.osVersion : ""
          }|${
            EnvConfig?.IS_SSO_LOGIN
              ? LOGIN_PROVIDER.SSO
              : LOGIN_PROVIDER.FIREBASE
          }`;
    }

    if (params.method === "get") {
      return axios.get(params.url, { params: params.params });
    } else if (params.method === "post") {
      return axios
        .post(params.url, params.body)
        .then((res: any): any => {
          return res;
        })
        .then((err) => {
          return err;
        });
    } else if (params.method === "put") {
      return axios.put(params.url, params.body);
    } else if (params.method === "delete") {
      return axios.delete(params.url, params.body);
    } else if (params.method === "patch") {
      return axios.patch(params.url, params.body, params.headers);
    } else {
      return { success: false, msg: "No method provided, get, post?" };
    }
  }

  login(params: Params) {
    return axios.post(params.url, params.body);
  }
}

export default new ApiJunction();
