import { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "./S6_ActivationStep2.module.scss";
import { LinearProgressBarCust, ButtonCust } from "../../../widgets";
import { NewNumberModal } from "./NewNumberModal";
import { gaCategory, gaScreenNames } from "../../../../Types/GoogleAnalytics";
import CurrentNumberForm from "./CurrentNumberForm";
import PinHelperText from "./PinHelperText";
import AccountPinHelperText from "./AccountPinHelperText";
import Config from "../../../../config/env.config.json";
import { InternalBanner } from "../../../common";
import { gtmTagManager } from "../../../../utils";

const ActivationStep2 = (props: any) => {
  const {
    currentLine,
    content,
    GAAndFBEventsHandler,
    operators,
    currentOperator,
    zipcodeCheckHandler,
    setOpenSupportDialog,
    clickHandlerForPortNum,
    currentNum,
    handleBack,
    newNumHandler,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const { sp2_title, c_num, step_txt, num_pref, click_here_txt, help_txt, res } =
    content;
  return (
    <>
      {/* <InternalBanner
        // key={key}
        pageName={"MyAccount"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <Box
        sx={{
          cursor: "pointer",
          width: "18px",
          height: "18px",
          zIndex: "100",
          left: "0",
          position: "absolute",
          ml: { md: "30px", sm: "25px", xs: "25px" },
          mt: { md: "60px" },
          display: { xs: "none", sm: "inline-block" },
        }}
        data-testid="activation_step_3_wrapper"
      >
        <Box
          sx={{
            display: "flex",
            width: "18px",
            height: "18px",
            position: "absolute",
            left: "2.5rem",
          }}
          onClick={handleBack}
          data-testid="activation_step_3_back"
        >
          <ArrowBackIosIcon sx={{ width: "18px" }} data-testid="activation_step_3_back_icon"/>
          <Box data-testid="activation_step_3_back_text">Back</Box>
        </Box>
      </Box>
      <KeyboardBackspaceIcon
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          left: "1.4rem",
          top: "6.1rem",
          color: "#313131",
          cursor: "pointer",
        }}
        onClick={handleBack}
        data-testid="activation_step_3_back_icon"
      />
      <Box>
        {!currentNum ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: "88vh",
                width: { xs: "92%", sm: "100%" },
                mx: "auto",
              }}
            >
              <Paper
                sx={{
                  width: { sm: "724px" },
                  maxHeight: { xs: "570px" },
                  mt: { xs: "30px", sm: "60px" },
                  mb: { xs: "60px" },
                  boxShadow: { xs: "unset !important" },
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  // padding: "1.5rem",
                  paddingBottom: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: { xs: "25px", sm: "31px" },
                    pb: { xs: "16px", sm: "22px" },
                  }}
                >
                  {/* <Box
                    component="img"
                    alt={"circular_loader"}
                    src={content?.numberpre_icon?.data?.attributes?.url}
                    sx={{ paddingRight: "16px" }}
                  /> */}
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "24px" },
                      fontFamily: "var(--font_family_Bold)",
                    }}
                    data-testid="activation_step_3_title"
                  >
                    {currentNum ? c_num.title : sp2_title}
                  </Typography>
                </Box>
                <hr style={{ border: "1px solid #F8F8F8", width: "100%" }}></hr>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Semibold)",
                      my: { xs: "12px", sm: "25px" },
                      color: "var(--text_color)",
                    }}
                    data-testid="activation_step_3_step_text"
                  >
                    {step_txt}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Semibold)",
                      mb: { xs: "12px", sm: "26px" },
                      color: "var(--text_color)",
                      padding: {xs: "0px 10px", md: "0px 0px"}
                    }}
                    data-testid="activation_step_3_desc"
                  >
                    {num_pref?.desc}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    width: { xs: "90%", sm: "60%" },
                    margin: "0 auto 26px auto",
                  }}
                >
                  <ButtonCust
                    data-testid="activation_step_3_btn1"
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    sx={{
                      width: { xs: "250px", sm: "250px", md: "250px" },
                      height: { md: "50px !important" },
                      mb: { xs: "12px", sm: "26px" },
                    }}
                    onClick={() => {
                      clickHandlerForPortNum();
                      GAAndFBEventsHandler(
                        gaCategory.activation,
                        num_pref?.c_num_btn
                      );
                      gtmTagManager({
                        event: gaScreenNames.portinInitiated,
                        prefered_type: num_pref?.c_num_btn
                      });
                    }}
                  >
                    {num_pref?.c_num_btn}
                  </ButtonCust>
                  <Box>
                    <span
                      className={styles.card_description}
                      style={{
                        fontFamily: "var(--font_family_Regular)",
                        // mb: { xs: "12px", sm: "30px" },
                      }}
                      data-testid="activation_step_3_text1"
                    >
                      {num_pref?.txt1}&nbsp;
                    </span>
                    <span
                      style={{
                        color: "var(--primary_color)",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => window.open(content?.click_link, "_blank")}
                      data-testid="activation_step_3_clk_text"
                    >
                      {click_here_txt}
                    </span>
                    &nbsp;
                    <span
                      className={styles.card_description}
                      style={{
                        fontFamily: "var(--font_family_Regular)",
                      }}
                      data-testid="activation_step_3_help_text"
                    >
                      {help_txt}
                    </span>
                  </Box>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Semibold)",
                      mb: { xs: "12px", sm: "26px" },
                      color: "var(--text_color)",
                    }}
                    data-testid="activation_step_3_desc"
                  >
                    {c_num?.res?.desc2}
                  </Typography>
                </Box>
                <Box mb="16px" sx={{ textAlign: "center" }}>
                  <ButtonCust
                    data-testid="activation_step_3_btn2"
                    variantType={Config.PRIMARY_BUTTON_TYPE}
                    sx={{
                      width: { xs: "250px", sm: "250px", md: "250px" },
                      height: { md: "50px !important" },
                      mb: { xs: "12px", sm: "25px" },
                    }}
                    onClick={() => {
                      setOpenDialog(true);
                      newNumHandler(),
                        GAAndFBEventsHandler(
                          gaCategory.activation,
                          num_pref?.new_num_btn
                        );
                        gtmTagManager({
                          event: gaScreenNames.numPrefSel,
                          prefered_type: num_pref?.new_num_btn
                        });
                    }}
                  >
                    {num_pref?.new_num_btn}
                  </ButtonCust>
                  <Typography
                    className={styles.card_description}
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      mb: { xs: "12px", sm: "25px" },
                      width: { xs: "90%", sm: "60%" },
                      margin: "0 auto",
                    }}
                    data-testid="activation_step_3_text2"
                  >
                    {num_pref?.txt2}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </>
        ) : (
          <CurrentNumberForm styles={styles} {...props} />
        )}
      </Box>
      <Box data-testid="activation_step_3_helper_modal_wrapper">
        {currentNum && !currentOperator?.acctNumMsg && (
          <Box width={{ xs: "90%", sm: "45%" }} m="0 auto">
            <PinHelperText
              c_num={c_num}
              styles={styles}
              setOpenSupportDialog={setOpenSupportDialog}
            />
          </Box>
        )}
        {currentNum && (
          <Box width={{ xs: "90%", sm: "45%" }} m="0 auto" data-testid="activation_step_3_acc_pin_wrapper">
            <AccountPinHelperText
              currentOperator={currentOperator}
              styles={styles}
              setOpenSupportDialog={setOpenSupportDialog}
              {...props}
            />
          </Box>
        )}
        <NewNumberModal
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          content={content}
          zipCodeformik={props.zipCodeformik}
          zipcodeChangeHandler={props.zipcodeChangeHandler}
          {...props}
        />
      </Box>
    </>
  );
};
export default ActivationStep2;
