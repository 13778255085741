import { CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonCust } from "../../../../widgets";
import styles from "./S2_AddCreditPayment.module.scss";
import { useAppSelector } from "../../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import PaymentReviewCard from "../../../IRILDComponents/PaymentReviewCard/PaymentReviewCard";
import { getResolutionDetails } from "../../../../../utils/commonFunctions";

function AddCreditPayment(props: any) {
  const { ILDTaxCharges, isLoadedILDTaxes } = useAppSelector(
    (state: any) => state.checkout
  );

  const { content, totalCost, userLineData, setIsCreditPayment, isLoader } =
    props;

  const { visa_card, change_card, back_icon, back_txt, title, credit_payment } =
    content;

  const {
    taxAndFees,
    nameTxt,
    totalDue,
    btnTxt,
    mockCard,
    changeCardTxt,
    creditTitle,
    creditsFor,
  } = credit_payment;

  const { fullName } = styles;
  const { mobile } = getResolutionDetails();

  return (
    <>
      {!isLoadedILDTaxes ? (
        <Box width={{ xs: "100%", sm: "60%", md: "31%", lg: "31%" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "30px",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& span": {
                fontWeight: "var(--font_weight_5)",
              },
            }}
          >
            <Box
              component="span"
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                left: { xs: "20px", md: "103px" },
                cursor: "pointer",
                fontWeight: "var(--font_weight_3)",
              }}
              onClick={() => setIsCreditPayment(false)}
            >
              <Box
                component="img"
                src={back_icon?.data.attributes.url}
                alt={back_icon?.data.attributes.alternativeText}
              />
              {!mobile && (
                <Box
                  component="span"
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  {back_txt}
                </Box>
              )}
            </Box>
            <Box
              component="span"
              style={{ margin: "auto" }}
              sx={{
                fontFamily: "var(--font_family_Bold) !important",
                fontSize: { xs: "24px", md: "40px" },
              }}
            >
              {creditTitle}
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: "30px",
              mt: "40px",
            }}
          >
            <Typography className={fullName}>
              {creditsFor}
              <Box
                component="span"
                sx={{
                  textTransform: "capitalize",
                  pl: "5px",
                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {userLineData?.firstName?.toLowerCase()}{" "}
                {userLineData?.lastName?.toLowerCase()}
              </Box>
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"40px"}>
            <PaymentReviewCard
              firstRowTitle={nameTxt}
              firstRowValue={totalCost}
              secondRowTitle={taxAndFees}
              secondRowValue={ILDTaxCharges?.totalTax}
              thirdRowTitle={totalDue}
              mockCard={mockCard}
              visa_card={visa_card?.data.attributes}
              confirmBtn={btnTxt}
              carbon_edit={change_card?.data?.attributes}
              totalCost={totalCost}
              changeCardText={changeCardTxt}
              onClick={props.onClick}
              loading={isLoadedILDTaxes}
              btnLoading={isLoader}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
          />
        </Box>
      )}
    </>
  );
}

export default AddCreditPayment;
