import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { array, object, string } from "yup";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
// import data from "../../../../config/data.json";
import {
  addLineMcLoader,
  checkEmailAvailability,
  customerGroup,
  updateNewLineDetails,
} from "../../../../features/account/accountSlice";
import {
  CreateCustomer,
  GetCustomer,
  getOneTimeShippingTaxCharges,
  getOneTimeSimTaxCharges,
  getPlanTaxCharges,
  resetOneTimeShippingTaxCharges,
  resetOneTimeSimTaxCharges,
} from "../../../../features/checkout/checkoutSlice";
import { manageContentSlice } from "../../../../features/strapi/myAccountSlice";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { emailPattern, namePattern } from "../../../../utils/regexPatterns";
import { ShippingComponent } from "../AddLinesDialog/ShippingAddress";
import firebase from "../../../../adapters/firebase";
import {
  getShipmentOrderById,
  shipmentOrder,
} from "../../../../features/trackorder/trackorderSlice";
import EnvConfig from "../../../../config/env.config.json";
import ESimIMEI from "../../../common/EsimIMEI/EsimIMEI";
import { ReviewMonthlyStat } from "../AddLinesDialog/ReviewMonthlyStatementDialog/ReviewMonthlyStat";
import { EstimatedMonthlyBillDialog } from "../AddLinesDialog/EstimatedMonthlyBillDialog/EstimatedMonthlyBillDialog";
import { OrderSuccessDialog } from "../AddLinesDialog/OrderSuccessDialog/OrderSuccessDialog";
import { ManageCard, NotificationCust } from "../../../widgets";
import { SimTypes, addressType } from "../../../../features/checkout/Types";
import { getCustomerPlan } from "../../../../features/billing/billingSlice";
import { updateSecondaryUser } from "../../../../features/checkout/checkoutSlice";
const wowUnlimitedCoupons = EnvConfig?.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsUnlimited.json")
  : null;
const wowRegularCoupons = EnvConfig?.IS_PROMO_CODE_DISCOUNT_SEPARATE
  ? require("../../../../config/discountCouponsRegular.json")
  : null;
import { RoutingURLs } from "../../../../config/RoutingURLs";
import {
  liveOpsMessagesApi,
  reachPlanData,
} from "../../../../features/homepage/homepageSlice";
import { OpsMsgTypes } from "../../../../features/homepage/OpsMsgTypes";
import FirebaseDBURLs from "../../../../config/FirebaseDBURLs";

const ManageLinesComponent = isTemplateExisits(Config.Account.ManageLines)
  ? require(`./${Config.Account.ManageLines.template}_ManageLines`).default
  : null;
export type ManageLinesComponentProps = {
  content?: any;
};
type mangeLinesProps = {
  setCurrentSection: Function;
};
export type AddLineProps = {
  formik?: any;
  index: any;
  removeFormFields: any;
  handleFormikChange: (e: Event) => any;
  addUserError: string;
};
export interface ManageLinesProps extends AddLineProps {
  setCurrentSection: any;
  counter: number;
  pendingLines: any;
  user: any;
  handleAddLine: any;
  additionalCost: any;
  content: any;
  isSecondaryUserJourney: boolean;
  isEmailAVailable: boolean;
  handleLinesTab: any;
  stopAddLinesFlow?: boolean;
  setStopAddLinesFlow?: any;
}

export const ManageLines = ({ setCurrentSection }: mangeLinesProps) => {
  const dispatch = useAppDispatch();
  const { manageContent } = useAppSelector(
    (state: any) => state.strapi.myAccount
  );
  const { group } = useAppSelector((state: any) => state.account);
  const {
    emailAvailability,
    errorEmailAvailability,
    getCCDetails,
    newLineDetails,
  } = useAppSelector((state: any) => state.account);
  const { shipmentOrderSuccess, errorShipmentOrder } = useAppSelector(
    (state: any) => state.trackorder
  );
  const getAppDataState = useAppSelector((state) => state.checkout.getAppData);
  const { customerPlanDetails } = useAppSelector((state: any) => state.billing);
  const {
    getCustomer,
    planTaxCharges,
    oneTimeSIMTaxCharges,
    oneTimeShippingTaxCharges,
    createCustomer,
    errorCreateCustomer,
    landingPromoPlan,
  } = useAppSelector((state: any) => state.checkout);
  const { liveOpsMessagesSuccess, liveOpsMessagesError } = useAppSelector(
    (state: any) => state.homepage
  );

  const [counter, setCounter] = useState(0);
  const [pendingLines, setPendingLines] = useState(0);
  const [user, setUser] = useState<any>(null);
  const [cacheContent, setCacheContent] = useState<any>(null);
  const [sendSimOptions, setSendSimOptions] = useState<any>([]);
  const [shipPlan, setShipPlan] = useState<any>(null);
  const [isSecondaryUserJourney, setIsSecondaryUserJourney] = useState(false);
  const [addUserError, setAddUserError] = useState("");
  const [isEmailAVailable, setIsEmailAVailable] = useState(true);
  const [lineStep, setLineStep] = useState(1);
  const [shippingAddress, setShippingAddress] = useState<any>(null);
  const [secondaryLineAddress, setSecondaryLineAddress] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<any>(null);
  const [showReviewModal1, setShowReviewModal1] = useState(false);
  const [firstTax2Call, setFirstTax2Call] = useState(true);
  const [pricingTax, setPricingTax] = useState<any>({
    currentPricingTax: null,
    newPricingTax: null,
  });
  const [reviewDetails, setReviewDetails] = useState<any>({
    currentPricing: null,
    newPricing: null,
    previousAdditionalLines: null,
    currentAdditioinalLines: null,
    selectedPlanName: "",
  });
  const [reviewModalLoader, setReviewModalLoader] = useState(false);
  const [showEstBillModal, setShowEstBillModal] = useState(false);
  const [kitBasePrice, setKitBasePrice] = useState(0);
  const [secondarySimPrice, setSecondarySimPrice] = useState(0);
  const [estBillLoader, setEstBillLoader] = useState(false);
  const [createCustomerCounter, setCreateCustomerCounter] = useState(0);
  const [createdPsimCustomersId, setCreatedPsimCustomersId] = useState<any>([]);
  const [createdEsimCustomersId, setCreatedEsimCustomersId] = useState<any>([]);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [showCheckIMEIModal, setShowCheckIMEIModal] = useState(false);
  const [cardsFg, setCardsFg] = useState(false);
  const [eSimKitBasePrice, setESimKitBasePrice] = useState(0);
  const [eSimSecondarySimPrice, setESimSecondarySimPrice] = useState(0);
  const [stopAddLinesFlow, setStopAddLinesFlow] = useState(false);
  const [errorMsgOrder, setErrorMsgOrder] = useState("");
  const [addLineConfirmationOpsMessages, setAddLineConfirmationOpsMessages] =
    useState([]);
  const [priorityOpsMessageId, setPriorityOpsMessageId] = useState("");
  const esimCount = useRef(0);
  const psimCount = useRef(0);
  const numOfAddLines = useRef(0);

  const { planData } = useAppSelector((state: any) => state.homepage);
  useEffect(() => {
    if (!planData) {
      dispatch(reachPlanData({}));
    }
  }, []);

  useEffect(() => {
    getCache("ManageLines", manageContentSlice, setCacheContent, dispatch);
    fetchShipmentPlans();
    let currentuser: any = localStorage.getItem("currentuser");
    if (currentuser) {
      currentuser = JSON.parse(currentuser);
      dispatch(getCustomerPlan({ customerId: currentUser?.id }));
    }

    let currentdetails: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    let shippingAddressDetails = currentdetails?.addresses?.find(
      (el: any) => el.type === addressType.shipping
    );
    const customerBillingAddress = currentdetails?.addresses?.find(
      (el: any) => el.type === addressType.billing
    );
    setShippingAddress(customerBillingAddress);
    setSecondaryLineAddress({
      firstName: currentdetails?.firstName,
      lastName: currentdetails?.lastName,
      billingAddress: customerBillingAddress,
    });
    let plan: any;
    if (currentdetails?.reachPlanId === EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME) {
      plan = landingPromoPlan;
    } else {
      plan =
        planData?.data?.length > 0 &&
        planData?.data?.find(
          (el: any) => el.name === currentdetails?.reachPlanId
        );
    }
    if (plan) {
      setCurrentPlan(plan);
      setPendingLines(plan?.maxLines - (currentdetails?.additionalLines + 1));
    }
    setUser(currentdetails);
    localStorage.removeItem("secondaryCustomerDetail");
    localStorage.removeItem("simId");
    dispatch(getShipmentOrderById({ id: currentdetails && currentdetails.id }));
    if (newLineDetails.length > 0) {
      setIsEmailAVailable(true);
      setIsSecondaryUserJourney(true);
    }
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("ManageLines", manageContent);
  }, [manageContent]);

  useNonInitialEffect(() => {
    setEstBillLoader(false);
  }, [errorCreateCustomer]);

  useNonInitialEffect(() => {
    if (sendSimOptions.length > 0) setShipPlan(sendSimOptions[0]);
  }, [sendSimOptions]);

  useNonInitialEffect(() => {
    if (oneTimeSIMTaxCharges) {
      showEstBillModalHandler();
    }
  }, [oneTimeSIMTaxCharges]);

  useEffect(() => {
    EnvConfig?.IS_MSG_CENTER_ENABLED &&
      dispatch(
        liveOpsMessagesApi({
          limit: OpsMsgTypes.limit,
          partialMatch: true,
          query: "",
          status: [OpsMsgTypes.statusInProgress],
        })
      );
  }, []);

  useEffect(() => {
    // Live Ops Messages
    if (
      liveOpsMessagesSuccess?.status === "SUCCESS" &&
      liveOpsMessagesSuccess?.data?.results
    ) {
      let addLineConfirmationOpsMessages: any = [];
      let priorityOpsMessage: any = undefined;
      liveOpsMessagesSuccess?.data?.results.forEach((opsMessage: any) => {
        if (
          opsMessage?.inventory[
            "messaging_center.web_my_account.add_line_confirmation_psim"
          ]
        ) {
          addLineConfirmationOpsMessages.push(opsMessage);
        }
        if (
          opsMessage?.inventory[
            "messaging_center.email.purchase_order_confirmation_email"
          ]
        ) {
          // Setting Priority ops message to send to shipment API
          if (!priorityOpsMessage) {
            priorityOpsMessage = { ...opsMessage };
          } else if (
            new Date(opsMessage.endDate).getTime() >
            new Date(priorityOpsMessage?.endDate).getTime()
          ) {
            priorityOpsMessage = { ...opsMessage };
          }
        }
      });
      setAddLineConfirmationOpsMessages(addLineConfirmationOpsMessages);
      setPriorityOpsMessageId(priorityOpsMessage?.id ?? "");
    }
  }, [liveOpsMessagesSuccess]);

  const showEstBillModalHandler = () => {
    setReviewModalLoader(false);
    setShowReviewModal1(false);
    setErrorMsgOrder("");
    setShowEstBillModal(true);
  };

  const updateSourceToFirebase = async (email: any) => {
    await firebase
      .database()
      .ref(FirebaseDBURLs.source)
      .update({ [window.btoa(email)]: EnvConfig.OPERATOR }, (_error) => {
        return null;
      });
  };

  useNonInitialEffect(() => {
    if (
      createCustomer &&
      createCustomer.status === "SUCCESS" &&
      createCustomer.data
    ) {
      updateSourceToFirebase(createCustomer.data.emailId);
      if (createCustomer.data.esim)
        setCreatedEsimCustomersId((ids: any) => [
          ...ids,
          createCustomer.data.id,
        ]);
      else
        setCreatedPsimCustomersId((ids: any) => [
          ...ids,
          createCustomer.data.id,
        ]);
      setCreateCustomerCounter(createCustomerCounter + 1);
      if (numOfAddLines.current < formik.values.length)
        confirmPurchaseHandler();
    }
  }, [createCustomer]);

  useNonInitialEffect(() => {
    if (createCustomerCounter === formik.values.length) {
      if (formik.values.length > 0) {
        dispatch(updateNewLineDetails([]));
        formik.setValues([...formik.values, initialUser]);
        setIsEmailAVailable(true);
      }
      dispatch(
        customerGroup({
          groupId: getCustomer?.groupId,
        })
      );
      CallShipmentOrder();
    }
  }, [createCustomerCounter]);

  const refreshCustomer = () => {
    let currentdetails: any = JSON.parse(
      localStorage.getItem("customerDetail") || "null"
    );
    dispatch(
      GetCustomer({
        username: encodeURIComponent(currentdetails?.emailId),
      })
    );
  };
  useNonInitialEffect(() => {
    if (
      shipmentOrderSuccess?.status === "SUCCESS" &&
      shipmentOrderSuccess?.data
    ) {
      let timer: any;
      if (shipmentOrderSuccess) {
        timer = setInterval(() => {
          refreshCustomer();
        }, 5000);
      }
      return () => {
        clearInterval(timer);
      };
    }
  }, [shipmentOrderSuccess]);
  useNonInitialEffect(() => {
    if (
      shipmentOrderSuccess?.status === "SUCCESS" &&
      shipmentOrderSuccess?.data
    ) {
      if (
        !getCustomer?.operationsInProcess.includes(
          "SHIPMENT_PURCHASE_IN_PROGRESS"
        )
      ) {
        dispatch(resetOneTimeShippingTaxCharges());
        setEstBillLoader(false);
        if (shipmentOrderSuccess.data?.shipmentType === "ESIM") {
          localStorage.setItem(
            "secondaryCustomerDetail",
            JSON.stringify(createCustomer?.data)
          );
          dispatch(updateSecondaryUser(createCustomer?.data));
          setCurrentSection("DataDetails");
        } else {
          showSuccessPopup();
        }
      }
    }
  }, [getCustomer]);

  useNonInitialEffect(() => {
    if (
      shipmentOrderSuccess?.status === "SUCCESS" &&
      shipmentOrderSuccess?.data
    ) {
      dispatch(resetOneTimeShippingTaxCharges());
      setEstBillLoader(false);
      if (shipmentOrderSuccess.data?.shipmentType === "ESIM") {
        localStorage.setItem(
          "secondaryCustomerDetail",
          JSON.stringify(createCustomer?.data)
        );
        dispatch(updateSecondaryUser(createCustomer?.data));
        setCurrentSection("DataDetails");
      } else {
        showSuccessPopup();
      }
    }
  }, [shipmentOrderSuccess]);
  useNonInitialEffect(() => {
    if (errorShipmentOrder) {
      setEstBillLoader(false);
      setErrorMsgOrder("Something went wrong.Please try again later.");
    }
  }, [errorShipmentOrder]);
  useNonInitialEffect(() => {
    if (
      (emailAvailability?.status === "SUCCESS" && emailAvailability?.data) ||
      (errorEmailAvailability?.status?.toUpperCase() === "FAILURE" &&
        EnvConfig?.HIDE_SECONDARY_EMAIL_FIELD)
    ) {
      setAddUserError("");
      if (formik.values.length > 0) {
        const updateFormik = formik.values.map((data: any) => {
          return {
            ...data,
            isEmailAvalable: true,
          };
        });
        formik.setValues(updateFormik);
        setIsEmailAVailable(true);
      }
    } else if (
      emailAvailability?.status === "SUCCESS" &&
      !emailAvailability.data
    ) {
      setAddUserError(manageContent?.existing_email_err);
    }
  }, [emailAvailability, errorEmailAvailability]);

  useNonInitialEffect(() => {
    if (planTaxCharges) {
      if (firstTax2Call) {
        setPricingTax((prevState: any) => ({
          ...prevState,
          currentPricingTax: planTaxCharges,
        }));
        setFirstTax2Call(false);
        newPriceTaxCalc();
      } else {
        setPricingTax((prevState: any) => ({
          ...prevState,
          newPricingTax: planTaxCharges,
        }));
        setLoader(false);
        setShowCheckIMEIModal(false);
        setShowReviewModal1(true);
        setFirstTax2Call(true);
      }
    }
  }, [planTaxCharges]);

  const showSuccessPopup = () => {
    setShowEstBillModal(false);
    if (formik.values.length > 0) {
      dispatch(updateNewLineDetails([]));
      formik.setValues([...formik.values, initialUser]);
      setIsEmailAVailable(true);
    }
    setPurchaseSuccess(true);
    setTimeout(() => {
      setCurrentSection("DataDetails");
    }, 2000);
  };

  const CallShipmentOrder = () => {
    let { address1, city, country, name, state, zip } = shippingAddress;
    if (secondaryLineAddress) {
      let billingAddress = secondaryLineAddress.billingAddress;
      ({
        name = `${secondaryLineAddress.firstName} ${secondaryLineAddress.lastName}`,
        address1,
        city,
        country,
        state,
        zip,
      } = billingAddress);
    }

    let primaryAccount: any = localStorage.getItem("customerDetail");
    primaryAccount = JSON.parse(primaryAccount);

    let sendOption: any = sendSimOptions.filter(
      (el: any) => el.cost == shipPlan.cost
    );
    let totalAmount =
      shipPlan?.shippingAmount + oneTimeShippingTaxCharges?.totalTax;

    let estimatedDeliveryDate = new Date();
    estimatedDeliveryDate.setDate(
      estimatedDeliveryDate.getDate() + shipPlan?.deliveryDays
    );
    let mainCard: any =
      getCCDetails?.length > 0 &&
      getCCDetails.find((el: any) => el.isAutoPay === true);
    const psimPayload: any = {
      cId: primaryAccount.id,
      reachCarrierId: shipPlan?.reachCarrierId,
      deliveryDays: shipPlan?.deliveryDays,
      serviceType: shipPlan?.serviceType,
      serviceCode: shipPlan?.serviceCode,
      carrierCode: shipPlan?.carrierCode,
      shipmentType: "PHYSICAL",
      category: "SIM",
      shippingAmount: shipPlan?.shippingAmount,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: oneTimeShippingTaxCharges?.totalTax,
      totalAmount: totalAmount,
      taxId: oneTimeShippingTaxCharges?.taxId,
      taxInfo: oneTimeShippingTaxCharges,
      ccUUID: mainCard?.uuid,
      secondaryCustomers: createdPsimCustomersId,
      shipTo: {
        city: city,
        country: "US",
        name: name,
        phone: primaryAccount?.addresses[0]?.phone,
        postalCode: zip,
        residential: "false",
        state: state,
        street1: address1,
      },
      items: [
        {
          quantity: psimCount.current,
          unitPrice: 0,
        },
      ],
      ...(EnvConfig?.IS_MSG_CENTER_ENABLED && {
        opsMessageId: priorityOpsMessageId,
      }),
    };

    const esimTax = { totalTax: 0 };
    const esimPayload: any = {
      cId: primaryAccount.id,
      reachCarrierId: sendSimOptions[0]?.reachCarrierId,
      deliveryDays: 0,
      serviceType: "email",
      serviceCode: "email",
      carrierCode: sendSimOptions[0]?.carrierCode,
      shipmentType: "ESIM",
      category: "SIM",
      shippingAmount: 0,
      estimatedDeliveryDate: estimatedDeliveryDate,
      taxAmount: 0,
      totalAmount: 0,
      taxId: oneTimeSIMTaxCharges?.taxId
        ? oneTimeSIMTaxCharges?.taxId
        : esimTax?.totalTax,
      taxInfo: oneTimeSIMTaxCharges ? oneTimeSIMTaxCharges : esimTax,
      ccUUID: mainCard?.uuid,
      secondaryCustomers: createdEsimCustomersId,
      shipTo: {
        city: city,
        country: "US",
        name: name,
        phone: primaryAccount?.addresses[0]?.phone,
        postalCode: zip,
        residential: "false",
        state: state,
        street1: address1,
      },
      items: [
        {
          quantity: esimCount.current,
          unitPrice: 0,
        },
      ],
    };
    if (esimCount.current > 0) {
      dispatch(shipmentOrder(esimPayload)).then(() => {
        dispatch(addLineMcLoader(false));
      });
    }
    if (psimCount.current > 0) {
      dispatch(shipmentOrder(psimPayload)).then(() => {
        dispatch(addLineMcLoader(false));
      });
    }
  };

  let jsonData = planData;
  let currentUser: any = localStorage.getItem("customerDetail");
  currentUser = JSON.parse(currentUser);
  let additionalCost;
  if (currentUser?.reachPlanId === EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME) {
    additionalCost = landingPromoPlan?.additionalLinePrice;
  } else {
    jsonData?.data.map((plan?: any) => {
      if (plan?.name === currentUser?.reachPlanId) {
        additionalCost = plan?.additionalLinePrice;
      }
    });
  }

  const fetchShipmentPlans = async () => {
    // const shippingRate: any = await firebase
    //   .database()
    //   .ref(FirebaseDBURLs.shippingRates);
    // shippingRate.on("value", (snapshot: any) => {
    //   const data = snapshot.val();
    //   let shipmentPlansList: any = [];
    //   data.length > 0 &&
    //     data.map((el: any) => {
    //       return shipmentPlansList.push({
    //         label: `${el && el.name} - $${el && el.shippingAmount.toFixed(2)}`,
    //         value: el && el.serviceCode,
    //         cost: el && el.shippingAmount,
    //         ...el,
    //       });
    //     });
    //   setSendSimOptions(shipmentPlansList);
    // });
    if (
      getAppDataState &&
      getAppDataState.data &&
      getAppDataState.data.status === "SUCCESS" &&
      getAppDataState.data.data
    ) {
      let ShippingRates = getAppDataState.data.data.shippingRates;
      let shipmentPlansList: any = [];
      if (ShippingRates.length > 0) {
        ShippingRates.map((el: any) => {
          return shipmentPlansList.push({
            label: `${el && el.name} - $${el && el.shippingAmount.toFixed(2)}`,
            value: el && el.serviceCode,
            cost: el && el.shippingAmount,
            ...el,
          });
        });
        // ShippingRates.forEach((el: any) => {
        //   shipmentPlansList.push({
        //     label: `${el && el.name} - ${
        //       el && el.shippingAmount
        //         ? `$ ${el.shippingAmount}`
        //         : addressContentState?.zero_ship_rate
        //     }`,
        //     value: el && el.serviceCode,
        //     cost: el && el.shippingAmount,
        //     ...el,
        //   });
        // });
      }
      setSendSimOptions(shipmentPlansList);

      const perCost: any = await firebase
        .database()
        .ref(FirebaseDBURLs.simCost);
      let kitBasePrice = 0;
      let secondarySimPrice = 0;
      let eSimKitBasePrice = 0;
      let eSimSecondarySimPrice = 0;

      perCost.on("value", (snapshot: any) => {
        const data = snapshot.val();

        //sim price
        kitBasePrice =
          data &&
          data.USA &&
          ((100 - data.USA.discountPctg) * data.USA.cost) / 100;
        secondarySimPrice = kitBasePrice;
        if (data && data.USA && data.USA.subsequentCost) {
          secondarySimPrice =
            ((100 - data.USA.discountPctg) * data.USA.subsequentCost) / 100;
        }

        //eSim price
        eSimKitBasePrice =
          data &&
          data.USA &&
          ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimCost) / 100;
        eSimSecondarySimPrice = eSimKitBasePrice;
        if (data && data.USA && data.USA.eSimSubsequentCost) {
          eSimSecondarySimPrice =
            ((100 - data.USA.eSimDiscountPctg) * data.USA.eSimSubsequentCost) /
            100;
        }

        setKitBasePrice(kitBasePrice);
        setSecondarySimPrice(secondarySimPrice);
        setESimKitBasePrice(eSimKitBasePrice);
        setESimSecondarySimPrice(eSimSecondarySimPrice);
      });
    }
  };
  function isNameUnique(fieldName: any, value: any, context: any) {
    if (!EnvConfig?.IS_SAME_NAME_ALLOWED_ADD_LINES) {
      const formikData: any = formik.values;
      const currentIndexPath = context.path?.split(".")?.[0] || "";
      const currentIndex = parseInt(currentIndexPath.replace(/\D/g, ""));
      const existingNames = formikData.find(
        (data: any) => data[fieldName] === value
      );
      const existingNameIndex = formikData.findIndex(
        (data: any, index: any) => data[fieldName] === value
      );
      return !(existingNames && existingNameIndex !== currentIndex);
    } else {
      return true;
    }
  }

  const addLineSchema = array().of(
    object({
      firstName: string()
        .trim()
        .min(2, manageContent?.first_name_err)
        .max(24, manageContent?.first_name_err)
        .required(manageContent?.first_name_err2)
        .matches(namePattern, manageContent?.first_name_err)
        .test(
          "unique-firstName-group",
          manageContent?.first_name_exist_err,
          function (value: any) {
            if (!EnvConfig?.IS_SAME_NAME_ALLOWED_ADD_LINES) {
              const groupData: any = group.data;
              const existingNames = groupData.filter(
                (data: any) => data.firstName === value
              );
              return existingNames.length === 0;
            } else {
              return true;
            }
          }
        )
        .test(
          "unique-firstName",
          manageContent?.first_name_exist_err,
          function (value: any, context) {
            return isNameUnique("firstName", value, context);
          }
        ),
      lastName: string()
        .trim()
        .min(2, manageContent?.last_name_err)
        .max(24, manageContent?.last_name_err)
        .required(manageContent?.last_name_err2)
        .matches(namePattern, manageContent?.last_name_err)
        .test(
          "unique-lastName-group",
          manageContent?.last_name__exist_err,
          function (value: any) {
            if (!EnvConfig?.IS_SAME_NAME_ALLOWED_ADD_LINES) {
              const groupData: any = group.data;
              const existingNames = groupData.filter(
                (data: any) => data.lastName === value
              );
              return existingNames.length === 0;
            } else {
              return true;
            }
          }
        )
        .test(
          "unique-lastName",
          manageContent?.last_name__exist_err,
          function (value: any, context) {
            return isNameUnique("lastName", value, context);
          }
        ),
      email: EnvConfig?.HIDE_SECONDARY_EMAIL_FIELD
        ? string().trim()
        : string()
            .trim()
            .required(manageContent?.email_err1)
            .matches(emailPattern, manageContent?.email_err2),
    })
  );

  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
    isEmailAvalable: false,
  };

  const userData: any = [];

  const formik = useFormik({
    initialValues: newLineDetails,
    validationSchema: addLineSchema,
    onSubmit: async (values: any) => {
      let email = values[values.length - 1].email;
      const currentLineIndex = values.length - 1;
      const isEmailExisits = values.find(
        (line: any, index: number) =>
          currentLineIndex !== index &&
          line.email?.toLowerCase() === email?.toLowerCase()
      );
      if (isEmailExisits) {
        setAddUserError(manageContent?.existing_email_err);
      } else {
        dispatch(checkEmailAvailability({ email }));
      }
    },
    enableReinitialize: true,
  });
  const handleAddLine = () => {
    setIsEmailAVailable(false);
    setIsSecondaryUserJourney(true);
    setCounter((prevCounter) => prevCounter + 1);
    formik.setValues([...formik.values, initialUser]);
    if (currentUser?.isWowHSDCustomer) {
      let couponName: any;
      if (!customerPlanDetails?.isUnlimited) {
        couponName = wowRegularCoupons?.data?.name;
      } else if (customerPlanDetails?.isUnlimited) {
        couponName = wowUnlimitedCoupons?.data?.name;
      }
      let promo_notification = manageContent?.promo_notify?.replace(
        "{couponName}",
        couponName
      );
      manageContent?.promo_notify &&
        NotificationCust({
          message: (
            <div>
              <img
                src={manageContent?.gift_icon?.data?.attributes?.url || ""}
                alt={
                  manageContent?.gift_icon?.data?.attributes?.alternativeText ||
                  ""
                }
              />
              <span>{promo_notification}</span>
            </div>
          ),
          type: "info",
          duration: 5000,
          id: "copounCodeApplied",
          showIcon: true,
        });
    }
  };
  const removeFormFields = (index: number) => {
    if (newLineDetails.length > 0) {
      setIsEmailAVailable(true);
      let updatedNewConnections = [...newLineDetails];
      updatedNewConnections.splice(index, 1);
      dispatch(updateNewLineDetails(updatedNewConnections));
    } else {
      setIsEmailAVailable(true);
      let updatedConnections = formik?.values;
      updatedConnections.splice(index, 1);
      formik.setValues([...updatedConnections]);
      let updatedConnectionTouched = formik.touched;
      delete updatedConnectionTouched[index];
      const propertyValues: any = Object.values(updatedConnectionTouched);
      formik.setTouched({ ...propertyValues });
      setCounter((prevCounter) => prevCounter - 1);
      if (updatedConnections.length <= 0) {
        setIsSecondaryUserJourney(false);
      }
    }
  };

  const handleFormikChange = (e: any) => {
    formik.handleChange(e);
    let keyValue = e.target.name.split(".")[0];
    let keyNameNew = e.target.name.split(".")[1];
    let innerTouchedObj: any = formik.touched[keyValue];
    formik.setTouched({
      ...formik.touched,
      [keyValue]: {
        ...innerTouchedObj,
        [keyNameNew]: false,
      },
    });
  };

  const handleLinesTab = (step: number, isEsim?: boolean) => {
    if (EnvConfig.IS_ESIM_SUPPORTED && isEsim) {
      return setShowCheckIMEIModal(true);
    }
    setLineStep(step);
  };

  const updateAddress = (e: any) => {
    setSecondaryLineAddress(e);
  };
  const taxCalculationType2 = (
    type: string,
    amount: any = null,
    additionalLines: any = null
  ) => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    const customerBillingAddress = currentUser?.addresses?.find(
      (el: any) => el.type === addressType.billing
    );
    dispatch(
      getPlanTaxCharges({
        address: {
          name: customerBillingAddress?.name,
          address1: customerBillingAddress?.address1,
          city: customerBillingAddress?.city,
          country: "USA",
          state: customerBillingAddress?.state,
          type: type,
          zip: customerBillingAddress?.zip,
        },
        amount: amount ? amount : currentPlan?.baseLinePrice,
        reachPlanId: currentUser.reachPlanId,
        additionalLines: additionalLines
          ? additionalLines
          : currentUser?.additionalLines,
      })
    );
  };
  const taxCalculationType2WithCounter = (type: string, amount: any = null) => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    const customerBillingAddress = currentUser?.addresses?.find(
      (el: any) => el.type === addressType.billing
    );

    dispatch(
      getPlanTaxCharges({
        address: {
          name: customerBillingAddress?.name,
          address1: customerBillingAddress?.address1,
          city: customerBillingAddress?.city,
          country: "USA",
          state: customerBillingAddress?.state,
          type: type,
          zip: customerBillingAddress?.zip,
        },
        amount: amount ? amount : currentPlan?.baseLinePrice,
        reachPlanId: currentUser.reachPlanId,
        additionalLines: currentUser?.additionalLines + formik.values.length,
      })
    );
  };

  const newPriceTaxCalc = () => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    let currentUserPlan: any;
    if (currentUser?.reachPlanId === EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME) {
      currentUserPlan = landingPromoPlan;
    } else {
      currentUserPlan = planData?.data?.find(
        (x?: any) => x.name === currentUser.reachPlanId
      );
    }
    const numberOfAdditionalLines: any = currentUser.additionalLines;
    let currentPrice = 0;
    let newPricing = 0;
    if (currentUserPlan) {
      if (currentUserPlan?.addLineSplitPrice) {
        let additionalLineAmount = 0;
        let additionalLineNewAmount = 0;
        for (let i = 1; i <= numberOfAdditionalLines; i++) {
          additionalLineAmount =
            additionalLineAmount + currentUserPlan.addLineSplitPrice[i];
        }
        for (let i = 1; i <= numberOfAdditionalLines + 1; i++) {
          additionalLineNewAmount =
            additionalLineNewAmount + currentUserPlan.addLineSplitPrice[i];
        }
        currentPrice = additionalLineAmount + currentUserPlan.baseLinePrice;
        newPricing = additionalLineNewAmount + currentUserPlan.baseLinePrice;
      } else {
        currentPrice =
          currentUserPlan.baseLinePrice +
          currentUserPlan.additionalLinePrice * currentUser.additionalLines;
        newPricing =
          currentUserPlan.baseLinePrice +
          currentUserPlan.additionalLinePrice *
            (currentUser.additionalLines + formik.values.length);
      }
    }
    setReviewDetails((prevState: any) => ({
      ...prevState,
      currentPricing: currentPrice,
      newPricing: newPricing,
      previousAdditionalLines: currentUser.additionalLines,
      currentAdditioinalLines:
        currentUser.additionalLines + formik.values.length,
      selectedPlanName: currentUser.planName,
    }));
    taxCalculationType2WithCounter("billing", newPricing);
  };
  const checkHandler = () => {
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    let currentPricing: any;
    if (currentUser?.reachPlanId === EnvConfig?.SPECIAL_DISCOUNT_PLAN_NAME) {
      currentPricing = landingPromoPlan;
    } else {
      currentPricing = planData?.data?.find(
        (x?: any) => x.name === currentUser.reachPlanId
      );
    }
    currentPricing =
      currentPricing?.baseLinePrice +
      currentUser?.additionalLines * currentPricing?.additionalLinePrice;

    setLoader(true);
    taxCalculationType2("shipping", currentPricing);
  };

  const taxCalculationType1 = (
    type: string,
    amount: any = null,
    numOfPsim: number = 0,
    numOfEsim: number = 0
  ) => {
    let { address1, city, country, name, state, zip } = shippingAddress;
    if (secondaryLineAddress) {
      let billingAddress = secondaryLineAddress.billingAddress;
      ({
        name = `${secondaryLineAddress.firstName} ${secondaryLineAddress.lastName}`,
        address1,
        city,
        country,
        state,
        zip,
      } = billingAddress);
    }

    const esimCost =
      eSimKitBasePrice || 0 + (eSimSecondarySimPrice || 0) * (numOfEsim - 1);
    const pSimCost = kitBasePrice + secondarySimPrice * (numOfPsim - 1);

    const totalSimsCost = esimCost + pSimCost;

    const eSimPsim = numOfEsim > 0 && numOfPsim > 0 ? true : false;

    const oneTmeCost = eSimPsim
      ? totalSimsCost
      : numOfEsim > 0
      ? esimCost
      : numOfPsim > 0
      ? pSimCost
      : 0;

    let totalCost: number = shipPlan.cost + oneTmeCost;
    let currentUser: any = localStorage.getItem("customerDetail");
    currentUser = JSON.parse(currentUser);
    const customerBillingAddress = currentUser?.addresses?.find(
      (el: any) => el.type === addressType.billing
    );
    const taxPayload = {
      address: {
        name: customerBillingAddress?.name,
        address1: customerBillingAddress?.address1,
        city: customerBillingAddress?.city,
        country: "USA",
        state: customerBillingAddress?.state,
        type: type,
        zip: customerBillingAddress?.zip,
      },
      amount: amount ? amount : totalCost,
      reachPlanId: currentUser.reachPlanId,
    };
    const simTaxPayload = { ...taxPayload, amount: oneTmeCost };
    const shippingTaxPayload = { ...taxPayload, amount: shipPlan.cost };
    if (oneTmeCost > 0) {
      dispatch(getOneTimeSimTaxCharges(simTaxPayload));
    } else {
      dispatch(resetOneTimeSimTaxCharges());
      showEstBillModalHandler();
    }
    if (
      ((numOfPsim >= 1 && EnvConfig.IS_ESIM_SUPPORTED) ||
        !EnvConfig.IS_ESIM_SUPPORTED) &&
      shipPlan?.cost > 0
    ) {
      dispatch(getOneTimeShippingTaxCharges(shippingTaxPayload));
    } else {
      showEstBillModalHandler();
    }
  };
  const gotItHandler = () => {
    setReviewModalLoader(true);
    let simPreferenceStorage: any = localStorage.getItem("simPreference");
    simPreferenceStorage = JSON.parse(simPreferenceStorage);
    const isEsimSelected =
      simPreferenceStorage &&
      simPreferenceStorage?.every((data: any) => data.value === SimTypes.eSim);
    const isPhysicalSelected =
      simPreferenceStorage &&
      simPreferenceStorage?.every((data: any) => data.value === SimTypes.sim);
    const numOfEsim =
      simPreferenceStorage?.filter(
        (data: any) => data?.value === SimTypes?.eSim
      ).length || 0;
    const numOfPsim = simPreferenceStorage?.filter(
      (data: any) => data?.value === SimTypes?.sim
    ).length;
    esimCount.current = numOfEsim ? numOfEsim : 0;
    psimCount.current = numOfPsim
      ? numOfPsim
      : EnvConfig.IS_ESIM_SUPPORTED
      ? 0
      : formik.values.length;
    let oneTmeCost: number =
      !isEsimSelected && (isPhysicalSelected || !EnvConfig.IS_ESIM_SUPPORTED)
        ? kitBasePrice +
          secondarySimPrice *
            (EnvConfig.IS_ESIM_SUPPORTED ? numOfPsim - 1 : formik.values.length)
        : isEsimSelected && !isPhysicalSelected
        ? eSimKitBasePrice + eSimSecondarySimPrice * (numOfEsim - 1)
        : eSimKitBasePrice +
          eSimSecondarySimPrice * (numOfEsim - 1) +
          (kitBasePrice + secondarySimPrice * (numOfPsim - 1));
    let totalCost: number =
      numOfPsim > 0 || !EnvConfig.IS_ESIM_SUPPORTED
        ? shipPlan?.cost + oneTmeCost
        : oneTmeCost;
    const physicalSims =
      numOfPsim ||
      (EnvConfig.IS_ESIM_SUPPORTED ? 0 : formik.values.length) ||
      0;
    if (totalCost >= 0) {
      taxCalculationType1("billing", totalCost, physicalSims, numOfEsim);
    } else {
      setReviewModalLoader(false);
      setShowReviewModal1(false);
      setErrorMsgOrder("");
      setShowEstBillModal(true);
    }
  };
  const confirmPurchaseHandler = () => {
    setEstBillLoader(true);
    let shippingMethod = sendSimOptions.find(
      (data: any) => data.label === shipPlan.label
    );
    let address: any = null;
    if (secondaryLineAddress) {
      let billingAddress = secondaryLineAddress.billingAddress;
      address = {
        name: `${secondaryLineAddress.firstName} ${secondaryLineAddress.lastName}`,
        address1: billingAddress.address1,
        city: billingAddress.city,
        country: billingAddress.country,
        state: billingAddress.state,
        zip: billingAddress.zip,
        type: shippingAddress.type,
      };
    }

    let eSim: any = JSON.parse(localStorage.getItem("simSelection") || "null");
    let simPreference: any = localStorage.getItem("simPreference");
    simPreference = JSON.parse(simPreference);
    const linePreferenceData: any = {};
    simPreference &&
      simPreference.map((data: any, index: any) => {
        linePreferenceData["line" + (index + 1)] = {
          imei: data?.imei?.toString(),
          simPreference:
            data?.value === SimTypes.eSim ? "ESIM" : "PHYSICAL_SIM",
        };
      });
    const numOfEsimOrdered = simPreference?.filter(
      (data: any) => data?.value === SimTypes?.eSim
    ).length;
    const numOfPsimOrdered = simPreference?.filter(
      (data: any) => data?.value === SimTypes?.eSim
    ).length;

    dispatch(
      CreateCustomer({
        countryCode: "USA",
        zipcode: user?.zipcode ? user.zipcode : "01824",
        firstName: formik.values[numOfAddLines.current].firstName,
        lastName: formik.values[numOfAddLines.current].lastName,
        parentId: user?.id,
        isMNP: false,
        isPrimary: false,
        primaryNumber: user?.primaryNumber,
        addresses: address ? [address] : [shippingAddress],
        status: 0,
        emailId: formik.values[numOfAddLines.current].email.toLowerCase(),
        reachPlanId: user?.reachPlanId,
        isSimInHand: false,
        extraLines: 0,
        selectedShippingMethod: shippingMethod,
        userName: formik.values[numOfAddLines.current].email.toLowerCase(),
        esim:
          simPreference?.[numOfAddLines.current].value === SimTypes?.eSim
            ? true
            : false,
        activationJourneyStatus: "NOT_STARTED",
        physicalSimsOrdered: numOfPsimOrdered,
        eSimsOrdered: numOfEsimOrdered,
        linePreferences: linePreferenceData,
        flowVersion: 1,
        imei: simPreference?.[numOfAddLines.current]?.imei,
        make: simPreference?.[numOfAddLines.current]?.make,
        model: simPreference?.[numOfAddLines.current]?.model,
      })
    ).then((response) => {
      if (response?.payload?.status === 200) {
        setEstBillLoader(false);
        setShowEstBillModal(false);
        setCurrentSection("ManageLines");
        handleLinesTab(1);
        formik.resetForm();
        setCounter(0);
        setIsSecondaryUserJourney(false);
      }
    });
    numOfAddLines.current = numOfAddLines.current + 1;
  };

  const showStep2 = () => {
    window.scroll(0, 0);
    setShowCheckIMEIModal(false);
    setLineStep(2);
  };

  const showManageCard = () => {
    setShowEstBillModal(false);
    setCardsFg(true);
  };

  const showCardChangeModalHandler = (fg?: boolean) => {
    setCardsFg(fg ?? false);
    dispatch(addLineMcLoader(false));
  };
  const ProceedPayment = () => {
    dispatch(addLineMcLoader(true));
    confirmPurchaseHandler();
  };
  return (
    <>
      {lineStep === 1 && (
        <ManageLinesComponent
          setCurrentSection={setCurrentSection}
          counter={formik.values.length}
          pendingLines={pendingLines}
          user={user}
          handleAddLine={handleAddLine}
          additionalCost={additionalCost}
          content={cacheContent ? cacheContent : manageContent}
          formik={formik}
          removeFormFields={removeFormFields}
          isSecondaryUserJourney={isSecondaryUserJourney}
          handleFormikChange={handleFormikChange}
          addUserError={addUserError}
          userData={userData}
          isEmailAVailable={isEmailAVailable}
          handleLinesTab={handleLinesTab}
          stopAddLinesFlow={stopAddLinesFlow}
          setStopAddLinesFlow={setStopAddLinesFlow}
        />
      )}
      {lineStep === 2 && (
        <ShippingComponent
          shippingAddress={shippingAddress}
          sendSimOptions={sendSimOptions}
          shipPlan={shipPlan}
          setShipPlan={setShipPlan}
          updateAddress={updateAddress}
          secondaryLineAddress={secondaryLineAddress}
          checkHandler={checkHandler}
          loader={loader}
          handleLinesTab={handleLinesTab}
        />
      )}
      {showCheckIMEIModal && (
        <ESimIMEI
          showCheckIMEIModal={showCheckIMEIModal}
          hideCheckIMEIModal={() => setShowCheckIMEIModal(false)}
          fromAddLine={true}
          isAccountScreen={true}
          showStep2={showStep2}
          numOfNewLines={formik.values.length}
          loader={loader}
          showReviewModal={() => null}
          setShowReviewModal1={checkHandler}
          changePreferenceHandler={() => {}}
        />
      )}
      {showReviewModal1 && (
        <ReviewMonthlyStat
          showReviewModal1={showReviewModal1}
          pricingTax={pricingTax}
          reviewDetails={reviewDetails}
          setShowReviewModal1={setShowReviewModal1}
          reviewModalLoader={reviewModalLoader}
          gotItHandler={gotItHandler}
        />
      )}
      {showEstBillModal && (
        <EstimatedMonthlyBillDialog
          showEstBillModal={showEstBillModal}
          reviewDetails={reviewDetails}
          currentPlan={currentPlan}
          pricingTax={pricingTax}
          oneTmeCost={
            kitBasePrice + secondarySimPrice * (psimCount.current - 1)
          }
          counter={formik.values.length}
          oneTimeSIMTaxCharges={oneTimeSIMTaxCharges}
          shipPlan={shipPlan}
          setShowEstBillModal={setShowEstBillModal}
          estBillLoader={estBillLoader}
          confirmPurchaseHandler={confirmPurchaseHandler}
          showManageCard={showManageCard}
          numOfEsim={esimCount.current}
          numOfPsim={psimCount.current}
          eSimCostPerLine={
            eSimKitBasePrice + eSimSecondarySimPrice * (esimCount.current - 1)
          }
          errorMsgOrder={errorMsgOrder}
          oneTimeShippingTaxCharges={oneTimeShippingTaxCharges}
          {...(EnvConfig?.IS_MSG_CENTER_ENABLED && {
            addLineConfirmationOpsMessages: addLineConfirmationOpsMessages,
          })}
        />
      )}
      <OrderSuccessDialog
        purchaseSuccess={purchaseSuccess}
        content={manageContent}
      />
      <ManageCard
        cardData={getCCDetails}
        showCardChangeModal={cardsFg}
        setShowCardChangeModal={showCardChangeModalHandler}
        isModalRequired={true}
        pageName={"addlines"}
        isDescription={false}
        payType={false}
        ProceedPayment={ProceedPayment}
        isPayConfirm={true}
      />
    </>
  );
};
