import { CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonCust, ManageCard, SwitchCust } from "../../../widgets";
import styles from "./S1_PaymentReviewCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { paymentProps } from "./PaymentReviewCard";

function T1_PaymentReviewCard(props: paymentProps) {
  const {
    visa_card,
    firstRowTitle,
    firstRowValue,
    secondRowTitle,
    secondRowValue,
    loading,
    isAutoRenewal,
    thirdRowTitle,
    autoRenewalTitle,
    toggleTrueText,
    toggleFalseText,
    confirmBtn,
    carbon_edit,
    changeCardText,
    btnLoading,
    dataCap,
    autoRenewal,
    onChangeAutoRenewal,
    availableOfferingsData,
    defaultCard,
    isChangeCard,
    setIsChangeCard,
    getCCDetails,
  } = props;

  return (
    <>
      {!loading ? (
        <Box className={styles.main_container}>
          <Box className={styles.rows}>
            <Typography className={styles.displayPack}>
              {firstRowTitle}
              {dataCap ? dataCap / availableOfferingsData.servicePulse : null}
            </Typography>
            <Typography className={styles.displayPack}>
              {GetCurrencyFixedToTwo(firstRowValue)}
            </Typography>
          </Box>
          <Divider />
          <Box className={styles.rows}>
            <Typography className={styles.displayPack}>
              {secondRowTitle}
            </Typography>
            <Typography className={styles.displayPack}>
              {GetCurrencyFixedToTwo(secondRowValue)}
            </Typography>
          </Box>
          <Divider />
          <Box className={styles.rows}>
            <Typography className={styles.displayPack}>
              {thirdRowTitle}
            </Typography>
            <Typography className={styles.displayPack}>
              {GetCurrencyFixedToTwo(firstRowValue + secondRowValue)}
            </Typography>
          </Box>
          {isAutoRenewal ? <Divider /> : null}
          {isAutoRenewal ? (
            <Box className={styles.auto_renual}>
              <Typography className={styles.displayPack}>
                {autoRenewalTitle}
              </Typography>

              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                className={styles.toggleText}
              >
                {" "}
                <SwitchCust
                  checked={autoRenewal}
                  handleSwitchToggle={onChangeAutoRenewal}
                  name="autoRenewal"
                />
                {autoRenewal ? (
                  <Typography className={styles.toggleText}>
                    {toggleTrueText}
                  </Typography>
                ) : (
                  <Typography className={styles.toggleText}>
                    {toggleFalseText}
                  </Typography>
                )}
              </Box>
            </Box>
          ) : null}
          {isAutoRenewal ? <Divider /> : null}
          <Box className={styles.main_card}>
            <Box className={styles.card}>
              <Box
                className={styles.displayPack}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img src={visa_card.url} alt={visa_card.alternativeText} />
                <Typography className={styles.displayPack}>
                  {`${props?.mockCard}${defaultCard}`}
                </Typography>
              </Box>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => setIsChangeCard(true)}
                className={styles.changeCard}
              >
                {changeCardText}
                <img
                  src={carbon_edit?.url}
                  alt={carbon_edit?.alternativeText}
                />
              </Typography>
            </Box>
            <ButtonCust
              variantType={Config.QUATERNARY_BUTTON_TYPE}
              onClick={props.onClick}
            >
              {btnLoading ? <CircularProgress /> : confirmBtn}
            </ButtonCust>
          </Box>
        </Box>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
      <ManageCard
        cardData={getCCDetails}
        showCardChangeModal={isChangeCard ? true : false}
        setShowCardChangeModal={() => setIsChangeCard(false)}
        isModalRequired={true}
        pageName={"services"}
        isDescription={true}
        payType={true}
      />
    </>
  );
}

export default T1_PaymentReviewCard;
