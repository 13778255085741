import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import SwitchTabs from "../IRILDComponents/SwitchTabs";
import { PostActivationIRILDEnums } from "../../../enums";
import CountryList from "../IRILDComponents/CountryList/CountryList";
import IRPurchaseHistory from "./IRPurchaseHistory/IRPurchaseHistory";
import IRUserList from "./IRUserList/IRUserList";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import envConfig from "../../../config/env.config.json";
import { Link } from "react-router-dom";
import { resetPurchasePack } from "../../../features/services/servicesSlice";
import { getResolutionDetails } from "../../../utils/commonFunctions";

const T2_PAInternationalRoaming = (props: any) => {
  const { content, setInternationalService } = props;
  const {
    title,
    user_data,
    back_arrow,
    stayConnected,
    accessRoaming,
    clickHere,
    clickHereUrl,
  } = content;
  const { mobile } = getResolutionDetails();
  const { group } = useAppSelector((state: any) => state.account);
  const dispatch = useAppDispatch();

  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);
  const [isCountryList, setIsCountryList] = useState<boolean>(false);
  const [fullName, setFullName] = useState(false);
  const [isDataBoostJourney, setIsDataBoostJourney] = useState(false);
  const [rescheduleSuccess, setRescheduleSuccess] = useState(false);

  const onClickSwitchTabs = (type: any) => {
    if (type === PostActivationIRILDEnums.tab_left) {
      setShowPurchaseHistory(false);
    } else {
      setShowPurchaseHistory(true);
    }
  };

  const countryListHandler = (data: any, name: any) => {
    dispatch(resetPurchasePack());
    localStorage.setItem("SingleLineData", JSON.stringify(data));
    setIsCountryList(true);
    setFullName(name);
  };

  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);

  return (
    <>
      {!isCountryList ? (
        <Box
          sx={{
            margin: { xs: "auto", sm: "auto", md: "auto" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: { xs: "90%", sm: "60%", md: "75%", lg: "56%" },
          }}
        >
          {!isDataBoostJourney && !rescheduleSuccess ? (
            <>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "30px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& span": {
                    fontWeight: "var(--font_weight_5)",
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    left: { xs: "20px", md: "103px" },
                    cursor: "pointer",
                    fontWeight: "var(--font_weight_3)",
                  }}
                  onClick={() => setInternationalService("")}
                >
                  <Box
                    component="img"
                    src={back_arrow?.data.attributes.url}
                    alt={back_arrow?.data.attributes.alternativeText}
                  />
                  {!mobile && (
                    <Box
                      component="span"
                      sx={{
                        textDecoration: "underline",
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                    >
                      {user_data?.back}
                    </Box>
                  )}
                </Box>
                <Box
                  component="span"
                  style={{ margin: "auto" }}
                  sx={{
                    fontFamily: "var(--font_family_Bold) !important",
                    fontSize: { xs: "24px", md: "40px" },
                  }}
                >
                  {title}
                </Box>
              </Typography>
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontFamily: "var(--font_family_Bold)",
                    fontWeight: "var(--font_weight_4)",
                    mt: "30px",
                  }}
                >
                  {stayConnected}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    width: "80%",
                    margin: "auto",
                    marginTop: "15px",
                    fontSize: "16px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_0)",
                  }}
                >
                  {accessRoaming}{" "}
                  <Link
                    to={clickHereUrl}
                    style={{
                      color: "var(--primary_color)",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {clickHere}
                  </Link>
                </Typography>
              </Box>
              <SwitchTabs
                title_left={user_data?.lines}
                title_right={user_data?.pur_history1}
                onClickSwitchTabs={onClickSwitchTabs}
                showPurchaseHistory={showPurchaseHistory}
              />{" "}
            </>
          ) : null}

          {showPurchaseHistory ? (
            <IRPurchaseHistory
              isDataBoostJourney={isDataBoostJourney}
              setIsDataBoostJourney={setIsDataBoostJourney}
              onClickSwitchTabs={onClickSwitchTabs}
              rescheduleSuccess={rescheduleSuccess}
              setRescheduleSuccess={setRescheduleSuccess}
            />
          ) : (
            <IRUserList
              content={content}
              group={group}
              countryListHandler={countryListHandler}
            />
          )}
        </Box>
      ) : (
        <CountryList
          content={content}
          fullName={fullName}
          setIsCountryList={setIsCountryList}
          onClickSwitchTabs={onClickSwitchTabs}
        />
      )}
    </>
  );
};

export default T2_PAInternationalRoaming;
