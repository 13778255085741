import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import { version } from "../../config/RoutingURLs";

type InitialState = {
  servicesData: any;
  servicesErrorData: any;
  data: Array<any>;
  isBanner1: boolean;
  purchasePackError: any;
  purchasePackSuccess: any;
  purchasePackLoading: boolean;
  purchaseAddOnError: any;
  purchaseAddOnSuccess: any;
  purchaseAddOnLoading: boolean;
  availableOfferings: Array<any>;
  availableOfferingsError: any;
  availableOfferingsLoading: boolean;
  allPacksData: any;
  allPacksErrorData: any;
  allPacksPending: boolean;
  irAutoRenewData: any;
  irAutoRenewPending: boolean;
  irAutoRenewError: any;
  irDataAddOnData: Array<any>;
  deleteFuturePackData: any;
  deleteFuturePackError: any;
  deleteFuturePackPending: boolean;
  rescheduleFuturePackData: any;
  rescheduleFuturePackError: any;
  rescheduleFuturePackPending: boolean;
  getPackDataUsage: any;
  getPackDataUsageError: any;
  getPackDataUsagePending: boolean;
  getTransactionHistoryData: any;
  getTransactionHistoryError: any;
  getTransactionHistoryPending: boolean;
  getILDCreditsData: any;
  getILDCreditsError: any;
  getILDUsageHistoryData: any;
  getILDUsageHistoryError: any;
  getILDUsageHistoryPending: boolean;
};
const initialState: InitialState = {
  servicesData: {},
  servicesErrorData: null,
  allPacksData: {},
  allPacksErrorData: null,
  allPacksPending: false,
  irAutoRenewData: null,
  irAutoRenewPending: false,
  irAutoRenewError: null,
  data: [],
  availableOfferings: [],
  availableOfferingsError: null,
  availableOfferingsLoading: false,
  purchasePackError: null,
  purchasePackSuccess: null,
  purchasePackLoading: false,
  purchaseAddOnError: null,
  purchaseAddOnSuccess: null,
  purchaseAddOnLoading: false,
  isBanner1: true,
  irDataAddOnData: [],
  deleteFuturePackData: null,
  deleteFuturePackError: null,
  deleteFuturePackPending: false,
  rescheduleFuturePackData: null,
  rescheduleFuturePackError: null,
  rescheduleFuturePackPending: false,
  getPackDataUsage: null,
  getPackDataUsageError: null,
  getPackDataUsagePending: false,
  getTransactionHistoryData: [],
  getTransactionHistoryError: null,
  getTransactionHistoryPending: false,
  getILDCreditsData: null,
  getILDCreditsError: null,
  getILDUsageHistoryData: null,
  getILDUsageHistoryError: null,
  getILDUsageHistoryPending: false,
};

export const dataUsageByDate = createAsyncThunk(
  "services/dataUsageByDate",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: String(
        `${ApiEndPoints.customerDataUsage}${action.customerId}/from/${action.formatedStartDate}/to/${action.formatedEndDate}`
      ),
    });
  }
);

export const getAvailableOfferings = createAsyncThunk(
  "services/availableOfferings",
  async (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: ApiEndPoints.PaRoamingAvailableOfferings,
      body: action,
    });
  }
);
export const getAvailableOfferingsDataBoost = createAsyncThunk(
  "services/availableOfferingsDataBoost",
  async (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.irGetAvailOfferingId}${action.payload}`,
      body: action,
    });
  }
);

export const purchasePack = createAsyncThunk(
  "services/purchasePack",
  async (action: any) => {
    const { offeringId, tentativeStartDate, countries, autoRenew } = action;
    return apiJunction.makeRequest({
      method: "post",
      url: `${ApiEndPoints.paRoamingPurchasePack}${action.customerId}`,
      body: {
        offeringId,
        tentativeStartDate,
        countries,
        autoRenew,
      },
    });
  }
);

export const getAllPacks = createAsyncThunk(
  "services/getAllPacks",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      contentType: "application/json",
      url: String(`${ApiEndPoints.getAllPacks}${action.groupId}`),
    });
  }
);

export const irAutoRenew = createAsyncThunk(
  "services/irAutoRenew",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      contentType: "application/json",
      url: String(`${ApiEndPoints.irAutoRenew}${action.packId}/autorenew`),
      body: action.data,
    });
  }
);

export const irDataAddOnPurchase = createAsyncThunk(
  "services/irDataAddOnPurchase",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      contentType: "application/json",
      url: String(`${ApiEndPoints.irDataAddOn}${action.packId}/addon`),
      body: action.payload,
    });
  }
);

export const deleteFuturePack = createAsyncThunk(
  "services/deleteFuturePack",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "delete",
      contentType: "application/json",
      url: String(`${ApiEndPoints.deleteFuturePack}${action.packId}`),
    });
  }
);

export const rescheduleFuturePack = createAsyncThunk(
  "services/rescheduleFuturePack",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      contentType: "application/json",
      url: String(
        `${ApiEndPoints.rescheduleFuturePack}${action.packId}/reschedule`
      ),
      body: action.data,
    });
  }
);

export const getPackUsageHistory = createAsyncThunk(
  "services/getPackUsageHistory",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      contentType: "application/json",
      url: String(`${ApiEndPoints.getPackUsageHistory}${action.packId}/usage`),
      body: action.data,
    });
  }
);
export const getTransactionHistory = createAsyncThunk(
  "services/getTransactionHistory",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      contentType: "application/json",
      url: `${ApiEndPoints.getTransactionHistory}${action.customerID}?creditType=${action.params.creditType}`,
    });
  }
);

export const getILDCredits = createAsyncThunk(
  "services/getILDCredits",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      contentType: "application/json",
      url: String(`${ApiEndPoints.getILDCredits}${action.groupId}`),
      body: action.data,
    });
  }
);
export const getILDUsageHistory = createAsyncThunk(
  "services/getILDUsageHistory",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      contentType: "application/json",
      url: String(`${ApiEndPoints.getILDUsageHistory}${action.packId}`),
    });
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    resetPurchasePack: (state) => {
      state.purchasePackError = null;
      state.purchasePackSuccess = null;
      state.purchasePackLoading = false;
    },
    resetAddOnPurchase: (state) => {
      state.purchaseAddOnError = null;
      state.purchaseAddOnSuccess = null;
      state.purchaseAddOnLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      dataUsageByDate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.servicesData = action.payload;
        state.servicesErrorData = null;
      }
    );
    builder.addCase(dataUsageByDate.rejected, (state, action) => {
      state.servicesData = {};
      state.servicesErrorData = action.error || "Something went wrong";
    });
    builder.addCase(purchasePack.pending, (state) => {
      state.data = [];
      state.purchasePackError = null;
      state.purchasePackSuccess = false;
      state.purchasePackLoading = true;
    });
    builder.addCase(
      purchasePack.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload.data.data;
        state.purchasePackError = null;
        state.purchasePackSuccess = true;
        state.purchasePackLoading = false;
        localStorage.setItem(
          "SpecificPurchasePackId",
          action?.payload?.data.data.id
        );
      }
    );
    builder.addCase(purchasePack.rejected, (state, action) => {
      state.data = [];
      state.purchasePackError = action.error || "Something went wrong";
      state.purchasePackSuccess = null;
      state.purchasePackLoading = false;
    });
    builder.addCase(getAvailableOfferings.pending, (state) => {
      state.availableOfferings = [];
      state.availableOfferingsError = null;
      state.availableOfferingsLoading = true;
    });
    builder.addCase(
      getAvailableOfferings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.availableOfferings = action.payload.data.data;
        state.availableOfferingsError = null;
        state.availableOfferingsLoading = false;
      }
    );
    builder.addCase(getAvailableOfferings.rejected, (state, action) => {
      state.availableOfferings = [];
      state.availableOfferingsLoading = false;
      state.availableOfferingsError = action.error || "Something went wrong";
      state.availableOfferingsLoading = false;
    });
    builder.addCase(
      getAllPacks.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.allPacksData = action.payload;
        state.allPacksPending = false;
        state.allPacksErrorData = null;
      }
    );
    builder.addCase(getAllPacks.pending, (state, action) => {
      state.allPacksData = {};
      state.allPacksPending = true;
      state.allPacksErrorData = null;
    });
    builder.addCase(getAllPacks.rejected, (state, action) => {
      state.allPacksData = {};
      state.allPacksPending = false;
      state.allPacksErrorData = action.error || "Something went wrong";
    });
    builder.addCase(
      irAutoRenew.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.irAutoRenewData = action.payload;
        state.irAutoRenewPending = false;
        state.irAutoRenewError = null;
      }
    );
    builder.addCase(irAutoRenew.pending, (state, action) => {
      state.irAutoRenewData = null;
      state.irAutoRenewPending = true;
      state.irAutoRenewError = null;
    });
    builder.addCase(irAutoRenew.rejected, (state, action) => {
      state.irAutoRenewData = {};
      state.irAutoRenewPending = false;
      state.irAutoRenewError = action.error || "Something went wrong";
    });
    builder.addCase(
      irDataAddOnPurchase.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.irDataAddOnData = action.payload;
        state.purchaseAddOnLoading = false;
        state.purchaseAddOnError = null;
        state.purchaseAddOnSuccess = true;
      }
    );
    builder.addCase(irDataAddOnPurchase.pending, (state, action) => {
      state.irDataAddOnData = [];
      state.purchaseAddOnError = null;
      state.purchaseAddOnSuccess = false;
      state.purchaseAddOnLoading = true;
    });
    builder.addCase(irDataAddOnPurchase.rejected, (state, action) => {
      state.irDataAddOnData = [];
      state.purchaseAddOnLoading = false;
      state.purchaseAddOnError = action.error || "Something went wrong";
      state.purchaseAddOnSuccess = null;
    });
    builder.addCase(
      getAvailableOfferingsDataBoost.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.availableOfferings = action.payload.data;
        state.availableOfferingsError = null;
        state.availableOfferingsLoading = false;
      }
    );
    builder.addCase(getAvailableOfferingsDataBoost.pending, (state, action) => {
      state.availableOfferings = [];
      state.availableOfferingsError = null;
      state.availableOfferingsLoading = true;
    });
    builder.addCase(
      getAvailableOfferingsDataBoost.rejected,
      (state, action) => {
        state.availableOfferings = [];
        state.availableOfferingsError = action.error || "Something went wrong";
        state.availableOfferingsLoading = false;
      }
    );
    builder.addCase(
      deleteFuturePack.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteFuturePackData = action.payload;
        state.deleteFuturePackPending = false;
        state.deleteFuturePackError = null;
      }
    );
    builder.addCase(deleteFuturePack.pending, (state, action) => {
      state.deleteFuturePackData = {};
      state.deleteFuturePackPending = true;
      state.deleteFuturePackError = null;
    });
    builder.addCase(deleteFuturePack.rejected, (state, action) => {
      state.deleteFuturePackData = {};
      state.deleteFuturePackPending = false;
      state.deleteFuturePackError = action.error || "Something went wrong";
    });
    builder.addCase(
      rescheduleFuturePack.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.rescheduleFuturePackData = action.payload;
        state.rescheduleFuturePackPending = false;
        state.rescheduleFuturePackError = null;
      }
    );
    builder.addCase(rescheduleFuturePack.pending, (state, action) => {
      state.rescheduleFuturePackData = {};
      state.rescheduleFuturePackPending = true;
      state.rescheduleFuturePackError = null;
    });
    builder.addCase(rescheduleFuturePack.rejected, (state, action) => {
      state.rescheduleFuturePackData = {};
      state.rescheduleFuturePackPending = false;
      state.rescheduleFuturePackError = action.error || "Something went wrong";
    });
    builder.addCase(
      getPackUsageHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getPackDataUsage = action.payload.data.data;
        state.getPackDataUsagePending = false;
        state.getPackDataUsageError = null;
      }
    );
    builder.addCase(getPackUsageHistory.pending, (state, action) => {
      state.getPackDataUsage = {};
      state.getPackDataUsagePending = true;
      state.getPackDataUsageError = null;
    });
    builder.addCase(getPackUsageHistory.rejected, (state, action) => {
      state.getPackDataUsage = {};
      state.getPackDataUsagePending = false;
      state.getPackDataUsageError = action.error || "Something went wrong";
    });
    builder.addCase(
      getTransactionHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getTransactionHistoryData = action.payload.data.data;
        state.getTransactionHistoryPending = false;
        state.getTransactionHistoryError = null;
      }
    );
    builder.addCase(getTransactionHistory.pending, (state, action) => {
      state.getTransactionHistoryData = [];
      state.getTransactionHistoryPending = true;
      state.getTransactionHistoryError = null;
    });
    builder.addCase(getTransactionHistory.rejected, (state, action) => {
      state.getTransactionHistoryData = [];
      state.getTransactionHistoryPending = false;
      state.getTransactionHistoryError = action.error || "Something went wrong";
    });
    builder.addCase(
      getILDCredits.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getILDCreditsData = action.payload.data.data;
        state.getILDCreditsError = null;
      }
    );
    builder.addCase(getILDCredits.rejected, (state, action) => {
      state.getILDCreditsData = {};
      state.getILDCreditsError = action.error || "Something went wrong";
    });
    builder.addCase(getILDUsageHistory.pending, (state, action) => {
      state.getILDUsageHistoryData = [];
      state.getILDUsageHistoryPending = true;
      state.getILDUsageHistoryError = null;
    });
    builder.addCase(
      getILDUsageHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getILDUsageHistoryData = action.payload.data.data;
        state.getILDUsageHistoryError = null;
        state.getILDUsageHistoryPending = false;
      }
    );
    builder.addCase(getILDUsageHistory.rejected, (state, action) => {
      state.getILDUsageHistoryData = [];
      state.getILDUsageHistoryPending = false;
      state.getILDUsageHistoryError = action.error || "Something went wrong";
    });
  },
});

export const { resetPurchasePack, resetAddOnPurchase } = servicesSlice.actions;

export default servicesSlice.reducer;
