import { Box } from "@mui/material";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { PaymentProps } from "../PaymentButtons/PaymentButtons";
import { RoutingURLs } from "../../../config/RoutingURLs";

const T2_PaymentButtons = (props: PaymentProps) => {
  return (
    <Box
      style={{
        cursor: props.paymentClicked ? "not-allowed" : "pointer",
      }}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        // alignItems: "center",
        width: { xs: "90%", sm: "100%" },
        margin: { xs: "-10px auto 20px auto", md: "20px auto 20px auto" },
      }}
    >
      {!props.hideBackButton && props.backButtonTxt ? (
        <ButtonCust
          variantType={EnvConfig.SECONDARY_BUTTON_TYPE}
          onClick={() => props.navigate(RoutingURLs.home)}
          sx={{
            width: { xs: "90%", sm: "45%" },
            margin: "0 auto",
          }}
        >
          {props.backButtonTxt}
        </ButtonCust>
      ) : null}
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        onClick={props.formik}
        disabled={props.disabled}
        sx={{
          p: "11px 20px",
          width: {
            xs: "100%",
            sm: props.hideBackButton || !props.backButtonTxt ? "50%" : "45%",
          },
          mt: { xs: "10px", sm: "0px" },
        }}
        loading={props.paymentClicked}
      >
        {props.submitBtnTxt}
      </ButtonCust>
    </Box>
  );
};
export default T2_PaymentButtons;
