import { Box } from "@mui/material";
import { PostActivationIRILDEnums } from "../../../enums";
import clsx from "clsx";
import styles from "./SwitchTabs.module.scss";

const SwitchTabs = (props: any) => {
  const { title_left, title_right, onClickSwitchTabs, showPurchaseHistory, active } =
    props;
  const { switchTabContainer, tabInactive, tabActive, switchTab } = styles;
  return (
    <Box className={switchTabContainer} sx={{...props?.sx}} data-testid="switch_wrapper">
      <Box
        className={clsx(
          showPurchaseHistory ? tabInactive : tabActive,
          switchTab
        )}
        sx={{
          flex: "1",
          ...(showPurchaseHistory
            ? {}
            : props?.active),
        }}
        onClick={() => onClickSwitchTabs(PostActivationIRILDEnums.tab_left)}
        data-testid="switch_tab_left"
      >
        {title_left}
      </Box>
      <Box
        className={clsx(
          showPurchaseHistory ? tabActive : tabInactive,
          switchTab
        )}
        sx={{
          flex: "1",
          ...(showPurchaseHistory
            ? props?.active
            : {}),
        }}
        onClick={() => onClickSwitchTabs(PostActivationIRILDEnums.tab_right)}
        data-testid="switch_tab_right"
      >
        {title_right}
      </Box>
    </Box>
  );
};

export default SwitchTabs;
