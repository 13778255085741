import { Box, Typography } from "@mui/material";

const IRNoOrderHistory = (props: any) => {
  const { no_order_img, no_order_text } = props.content;
  const { no_order_icon } = props.style;
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      data-testid="IR_no_order_img_wrapper"
    >
      {no_order_img && (
        <Box
          component="img"
          className={no_order_icon}
          src={no_order_img?.data?.attributes?.url}
          data-testid="IR_no_order_img"
        ></Box>
      )}
      <Typography
        component="div"
        sx={{
          color: "var(--text-color)",
          fontFamily: "var(--font_family_Medium)",
          fontSize: { xs: "24px", sm: "46px" },
          fontStyle: "normal",
          fontWeight: "var(--font_weight_5)",
          lineHeight: "26px",
          margin: "5px",
          opacity: "0.3",
        }}
        data-testid="IR_no_order_text"
      >
        {no_order_text}
      </Typography>
    </Box>
  );
};

export default IRNoOrderHistory;
