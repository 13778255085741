import { Box, Skeleton, Typography } from "@mui/material";
import { SimpleModal } from "../widgets";
import styles from "./CountryDataModal.module.scss";
import { useAppSelector } from "../../app/hooks";
import { PacksDetails } from "../../enums/InternationalServicesPacks";
import React, { useEffect } from "react";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import SmsIcon from "@mui/icons-material/Sms";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MmsIcon from "@mui/icons-material/Mms";
import VerifiedIcon from "@mui/icons-material/Verified";

const CountryDataModal = (props: any) => {
  const { countryName, ildVoiceOut, ildSmsOut, status, ildStatus } =
    props?.selectedCountry ?? {};
  const { ISContent } = props;
  const { countriesRatesATT, errorInCountriesRatesATT } = useAppSelector(
    (state) => state.internationalService
  );
  const dailyPacks: any = countriesRatesATT?.find(
    (pack: any) => pack.packName === PacksDetails.DAILY_PASS
  );
  const weeklyPacks: any = countriesRatesATT?.find(
    (pack: any) => pack.packName === PacksDetails.WEEKLY_PASS
  );
  let daily_packs_Content = [
    {
      value: `${dailyPacks?.data}${dailyPacks?.dataUnit}`,
    },
    {
      value: `${dailyPacks?.voice} ${dailyPacks?.voiceUnit}`,
    },
    { value: `${dailyPacks?.sms}` },
    { value: `${dailyPacks?.mms}` },
    {
      value: `${dailyPacks?.validityDays} ${
        dailyPacks?.validityDays > 1 ? "Days" : "Day"
      }`,
    },
  ];
  let weekly_packs_Content = [
    {
      value: `${weeklyPacks?.data}${weeklyPacks?.dataUnit}`,
    },
    {
      value: `${weeklyPacks?.voice} ${weeklyPacks?.voiceUnit}`,
    },
    { value: `${weeklyPacks?.sms}` },
    { value: `${weeklyPacks?.mms}` },
    {
      value: `${weeklyPacks?.validityDays} ${
        weeklyPacks?.validityDays > 1 ? "Days" : "Day"
      }`,
    },
  ];

  useEffect(() => {
    if (errorInCountriesRatesATT !== null) {
      props.setIsCountryModal();
    }
  }, [errorInCountriesRatesATT]);
  return (
    <SimpleModal
      isShowModal={props.isCountryModal}
      onCloseModal={props.setIsCountryModal}
      showClose={true}
      sx={{
        top: " 20%",

        borderRadius: "10px",
        width: { lg: "40% !important", xs: "90% !important" },
        p: "25px",
      }}
    >
      {/* {countriesRatesATT?.length > 0 ? ( */}
      {countriesRatesATT !== null ? (
        <Box className={styles.modal_main_container} data-testid="country_data_modal_parent">
          <Box className={styles.modal_sub_container}>
            <Typography className={styles.modal_country_name} data-testid="country_data_modal_country_name">
              {countryName?.toLowerCase()}
            </Typography>
            {status === 1 ? (
              <Box className={styles.section}>
                <Typography
                  className={styles.modal_sub_title}
                  sx={{ padding: "10px 0px 20px !important" }}
                  data-testid="country_data_modal_tvl_out_us"
                >
                  {ISContent?.tvl_out_us}
                </Typography>

                <Box
                  className={styles.modal_status_container}
                  sx={{
                    flexDirection: { md: "row", xs: "column" },
                    margin: { md: "0px 20px" },
                    gap: "20px",
                  }}
                >
                  {dailyPacks ? (
                    <Box
                      className={styles.modal_status_daily_container}
                      sx={{
                        width: { md: "50%", xs: "80%" },
                        margin: { md: "0px", xs: "auto" },
                      }}
                    >
                      <Typography
                        className={styles.title}
                        data-testid="country_data_modal_title"
                      >{`${dailyPacks?.displayName} ${dailyPacks?.data}${dailyPacks?.dataUnit}`}</Typography>
                      <Box className={styles.modal_status_rates}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px 0px 20px",
                          }}
                        >
                          <Typography className={styles.price} data-testid="country_data_modal_price">
                            ${dailyPacks?.price.toFixed(2)}
                          </Typography>
                          <Box className={styles.border} />
                        </Box>
                        {daily_packs_Content?.length > 0 &&
                          daily_packs_Content?.map(
                            (item: any, index: number) => (
                              <>
                                {item.value !== null ||
                                item.value !== undefined ||
                                item.value.trim().length > 0 ? (
                                  <Box
                                    key={index}
                                    sx={{ paddingBottom: "5px" }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* <Box
                                        component="img"
                                        src={
                                          ISContent?.pck_info?.[index]?.img
                                            ?.data?.attributes?.url
                                        }
                                        alt={
                                          ISContent?.pck_info?.[index]?.img
                                            ?.data?.attributes?.alternativeText
                                        }
                                      /> */}
                                       {index == 0 && (
                                        <ImportExportIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_img_1"
                                        />
                                      )}
                                      {index == 1 && (
                                        <PhoneForwardedIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_img_2"
                                        />
                                      )}
                                      {index == 2 && (
                                        <SmsIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_img_3"
                                        />
                                      )}
                                      {index == 3 && (
                                        <MmsIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_img_4"
                                        />
                                      )}
                                      {index == 4 && (
                                        <VerifiedIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_img_5"
                                        />
                                      )}

                                      <Box
                                        component="span"
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "21px",
                                          fontWeight: "var(--font_weight_0)",
                                          color: "var(--primary_color)",
                                          fontFamily:
                                            "var(--font_family_Regular)",
                                        }}
                                        pl="5px"
                                        data-testid={`country_data_modal_title_${Number(index) + 1 || 1}`}
                                      >
                                        {ISContent?.pck_info?.[index]?.title}
                                      </Box>

                                      <Box
                                        component="span"
                                        style={{
                                          fontWeight: "var(--font_weight_1)",
                                          color: "var(--text_color)",
                                          fontFamily:
                                            "var(--font_family_Medium)",
                                        }}
                                        data-testid={`country_data_modal_value_${Number(index) + 1 || 1}`}
                                      >
                                        &nbsp;{item.value}
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : null}
                              </>
                            )
                          )}
                      </Box>
                    </Box>
                  ) : null}
                  {weeklyPacks ? (
                    <Box
                      className={styles.modal_status_weekly_container}
                      sx={{
                        width: {
                          md: "50%",
                          xs: "80%",
                          margin: { xs: "auto", md: "0px" },
                        },
                      }}
                    >
                      <Typography className={styles.title} data-testid="country_data_modal_wk_title">
                        {`${weeklyPacks?.displayName} ${weeklyPacks?.data}${weeklyPacks?.dataUnit}`}
                      </Typography>
                      <Box className={styles.modal_status_rates}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px 0px 20px",
                          }}
                        >
                          <Typography className={styles.price} data-testid="country_data_modal_wk_price">
                            ${weeklyPacks?.price.toFixed(2)}
                          </Typography>
                          <Box className={styles.border} />
                        </Box>
                        {weekly_packs_Content?.length > 0 &&
                          weekly_packs_Content?.map(
                            (item: any, index: number) => (
                              <React.Fragment key={index}>
                                {item.value !== null ||
                                item.value !== undefined ||
                                item.value.trim().length > 0 ? (
                                  <Box
                                    key={index}
                                    sx={{ paddingBottom: "5px" }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* <Box
                                        component="img"
                                        src={
                                          ISContent?.pck_info?.[index]?.img
                                            ?.data?.attributes?.url
                                        }
                                        alt={
                                          ISContent?.pck_info?.[index]?.img
                                            ?.data?.attributes?.alternativeText
                                        }
                                      /> */}
                                      {index == 0 && (
                                        <ImportExportIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_wk_img_1"
                                        />
                                      )}
                                      {index == 1 && (
                                        <PhoneForwardedIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_wk_img_2"
                                        />
                                      )}
                                      {index == 2 && (
                                        <SmsIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_wk_img_3"
                                        />
                                      )}
                                      {index == 3 && (
                                        <MmsIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_wk_img_4"
                                        />
                                      )}
                                      {index == 4 && (
                                        <VerifiedIcon
                                          sx={{
                                            color: "var(--primary_color)",
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          data-testid="country_data_modal_wk_img_5"
                                        />
                                      )}
                                      <Box
                                        component="span"
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "21px",
                                          fontWeight: "var(--font_weight_0)",
                                          color: "var(--primary_color)",
                                          fontFamily:
                                            "var(--font_family_Regular)",
                                        }}
                                        pl="5px"
                                        data-testid={`country_data_modal_wk_title_${Number(index) + 1 || 1}`}
                                      >
                                        {ISContent?.pck_info?.[index]?.title}
                                      </Box>

                                      <Box
                                        component="span"
                                        style={{
                                          fontWeight: "var(--font_weight_1)",
                                          color: "var(--text_color)",
                                          fontFamily:
                                            "var(--font_family_Medium)",
                                        }}
                                        data-testid={`country_data_modal_wk_value_${Number(index) + 1 || 1}`}
                                      >
                                        &nbsp;{item.value}
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : null}
                              </React.Fragment>
                            )
                          )}
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ) : null}
          </Box>
          {ildStatus === 1 && !props?.hide_IR ? (
            <Box className={styles.modal_sub_container}>
              <Box>
                <Typography
                  className={styles.modal_sub_title}
                  sx={{ paddingBottom: "30px !important" }}
                  data-testid="country_data_modal_cl_fr_us"
                >
                  {ISContent?.cl_fr_us_txt}
                </Typography>

                <Box
                  className={styles.calling_from_us_container}
                  sx={{
                    margin: { md: "20px 15%", xs: "10px 5%" },
                    "& >*": {
                      width: "50%",
                    },
                  }}
                >
                  <Box
                    component={"span"}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      {/* <Box
                        component="img"
                        sx={{ width: "12px", height: "12px" }}
                        src={
                          ISContent?.pck_info?.[1]?.img?.data?.attributes?.url
                        }
                        alt={
                          ISContent?.pck_info?.[1]?.img?.data?.attributes
                            ?.alternativeText
                        }
                      /> */}
                      <PhoneForwardedIcon
                        data-testid="country_data_modal_pck_info_img"
                        sx={{
                          color: "var(--primary_color)",
                          width: "12px",
                          height: "12px",
                        }}
                      />

                      <Box component="span" className={styles.calling_title} data-testid="country_data_modal_voice_txt">
                        {ISContent?.voice_txt}
                      </Box>
                    </Box>
                    <Box>-</Box>
                  </Box>
                  <Box component={"span"} className={styles.calling_sub_title} data-testid="country_data_modal_cl_sub_title">
                    ${ildVoiceOut && ildVoiceOut}
                    {ISContent?.min_txt}
                  </Box>
                </Box>
                <Box
                  className={styles.calling_from_us_container}
                  sx={{
                    margin: { md: "20px 15%", xs: "10px 5%" },
                    "& >*": {
                      width: "50%",
                    },
                  }}
                >
                  <Box
                    component={"span"}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      {/* <Box
                        component="img"
                        sx={{ width: "12px", height: "12px" }}
                        src={
                          ISContent?.pck_info?.[2]?.img?.data?.attributes?.url
                        }
                        alt={
                          ISContent?.pck_info?.[2]?.img?.data?.attributes
                            ?.alternativeText
                        }
                      /> */}
                      <SmsIcon
                        sx={{
                          color: "var(--primary_color)",
                          width: "12px",
                          height: "12px",
                        }}
                        data-testid="country_data_modal_pck_info_2_img"
                      />

                      <Box component="span" className={styles.calling_title} data-testid="country_data_modal_sms_txt">
                        {ISContent?.sms_txt}
                      </Box>
                    </Box>
                    <Box>-</Box>
                  </Box>
                  <Box component={"span"} className={styles.calling_sub_title} data-testid="country_data_modal_cl_sub_title_2">
                    {`${ildSmsOut > 0 ? `$${ildSmsOut}` : ISContent?.free_txt}`}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : (
        <>
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton
            variant="text"
            width="40%"
            height={25}
            sx={{ marginTop: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
              alignItems: "center",
              margin: "16px 5% ",
              width: "90%",
              gap: "20px",
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ width: { md: "49%", xs: "90%" } }}
              height={118}
            />
            <Skeleton
              variant="rectangular"
              sx={{ width: { md: "49%", xs: "90%" } }}
              height={118}
            />
          </Box>
          {!props?.hide_IR && (
            <>
              <Skeleton
                variant="text"
                width="40%"
                height={25}
                sx={{ marginTop: 2 }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  margin: 3,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton
                    variant="rectangular"
                    width="40%"
                    height={20}
                    sx={{ marginTop: 4 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width="40%"
                    height={20}
                    sx={{ marginTop: 4 }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton
                    variant="rectangular"
                    width="40%"
                    height={20}
                    sx={{ marginTop: 4 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width="40%"
                    height={20}
                    sx={{ marginTop: 4 }}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </SimpleModal>
  );
};

export default CountryDataModal;
