import { useState } from "react";
import { Popper, Box, styled } from "@mui/material";

const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop: any) => prop !== "arrow",
})(({ theme, arrow }: any) => ({
  zIndex: 2,
  "& > div": {
    position: "relative",
  },
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: arrow ? 2 : "4px",
    },
    "& .MuiPopper-arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.08))",
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.08))",
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.08))",
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.08))",
      },
    },
  },
}));

const Arrow = styled("div")({
  position: "absolute",
  fontSize: 7,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
  },
});

export function PopperCust({
  open,
  anchorEl,
  id,
  children,
  placement,
  needArrow = true,
}: any) {
  const [arrowRef, setArrowRef] = useState<any>(null);
  return (
    <StyledPopper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement={placement || "auto"}
      disablePortal={true}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <Box>
        {needArrow ? (
          <Arrow ref={setArrowRef} className="MuiPopper-arrow" sx={{
            color: "#000 !important"
          }} />
        ) : null}
        {open && children}
      </Box>
    </StyledPopper>
  );
}
