import WalletCard from "../HistoryCard/WalletCard";
import { useAppSelector } from "../../../../../app/hooks";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { format } from "date-fns";
import _ from "lodash";
import DropDownMenu from "../../../../widgets/DropDownMenu/DropDownMenu";

const T1_TransactionHistory = (props: any) => {
  const {
    date,
    amount,
    card,
    card_type,
    less_icon,
    greater_icon,
    no_transaction,
  } = props.content;

  const {
    userList,
    handleLineItemClick,
    handleLineClose,
    lineAnchorEl,
    handleClickLineItem,
    selectedLineIndex,
    lineOpen,
    toggleButtonText,
    handleViewMoreClick,
    walletuserData,
    getStartAndEndDateData,
    newFilteredData,
    itemsToShow,
    formatDate,
  } = props;
  const { getTransactionHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <DropDownMenu
          statuslist={userList}
          handleMenuItemClick={handleLineItemClick}
          handleClose={handleLineClose}
          menuAnchorEl={lineAnchorEl}
          handleClickListItem={handleClickLineItem}
          selectedIndex={selectedLineIndex}
          menuOpen={lineOpen}
          selectedIcon={props.content.select_img}
          uparrowIcon={props.content.up_arrow_img}
          downarrowIcon={props.content.down_arrow_img}
        />
      </Box>
      {walletuserData ? (
        <WalletCard lineDataForWallet={walletuserData} content={props.content}>
          {!getTransactionHistoryPending ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      props.selectedDateKeyIndex > 0
                    ) {
                      props.handleBillCycleChange("decrement");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={less_icon.data.attributes.url}
                    alt={less_icon.data.attributes.alternativeText}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {getStartAndEndDateData?.displayStartDate
                    ? format(
                        new Date(getStartAndEndDateData.displayStartDate),
                        "MMM dd, yyyy"
                      )
                    : null}
                </Typography>
                <span>-</span>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "var(--font_weight_1)",
                    fontFamily: "var(--font_family_Medium)",
                  }}
                >
                  {getStartAndEndDateData?.displayEndDate
                    ? format(
                        new Date(getStartAndEndDateData.displayEndDate),
                        "MMM dd, yyyy"
                      )
                    : null}
                </Typography>
                <Box
                  onClick={() => {
                    if (
                      getStartAndEndDateData.totalNumberOfKeys > 0 &&
                      props.selectedDateKeyIndex !==
                        getStartAndEndDateData.totalNumberOfKeys - 1
                    ) {
                      props.handleBillCycleChange("increment");
                    }
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={greater_icon.data.attributes.url}
                    alt={greater_icon.data.attributes.alternativeText}
                  />
                </Box>
              </Box>
              {newFilteredData?.length === 0 && (
                <Box p={10}>
                  <Typography
                    sx={{
                      fontSize: "34px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      opacity: 0.4,
                    }}
                  >
                    {no_transaction}
                  </Typography>
                </Box>
              )}
              {newFilteredData?.length !== 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: { xs: "40%", md: "85%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      flex: "1", // Allow the first column to grow
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                  >
                    {date}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                  >
                    {amount}
                  </Typography>
                  <Typography
                    sx={{
                      flex: "1",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                    }}
                  >
                    {card}
                  </Typography>
                </Box>
              ) : null}
              {_.orderBy(newFilteredData, ["purchaseDate"], ["desc"])
                ?.slice(0, itemsToShow)
                .map((data: any) => (
                  <Box
                    key={data.uuid}
                    sx={{
                      flex: "1",
                      display: "flex",
                      justifyContent: "space-between",
                      width: { xs: "40%", md: "85%" },
                      margin: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        flex: "1", // Allow the first column to grow
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                      }}
                    >
                      {formatDate(data.purchaseDate)}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                      }}
                    >
                      {GetCurrencyFixedToTwo(data.purchasedCredit)}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_1)",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {data.cardType === "Visa" && (
                        <img
                          src={card_type.data.attributes.url}
                          alt={card_type.data.attributes.alternativeText}
                        />
                      )}
                      xx
                      {data.ccNumberMask}
                    </Typography>
                  </Box>
                ))}
              <Divider />
              {newFilteredData?.length > 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    onClick={handleViewMoreClick}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_5)",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                  >
                    {toggleButtonText()}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box textAlign={"center"}>
              <CircularProgress sx={{ color: "var(--primary_color)" }} />
            </Box>
          )}
        </WalletCard>
      ) : (
        <Box textAlign={"center"}>
          <CircularProgress sx={{ color: "var(--primary_color)" }} />
        </Box>
      )}
    </>
  );
};

export default T1_TransactionHistory;
