import { CheckCoverageProps } from "./CheckCoverage";
import { SecondaryInfoCard } from "../../common";
import styles from "./S8_CheckCoverage.module.scss";
import { AutoComplete, SimpleModal, ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { Box, Container, Typography } from "@mui/material";
import { addressDisplayPattern } from "../../../utils/regexPatterns";
import { BrandNameUpdate, updateSvgFillColor } from "../../../utils";
import { ZipcodeModal } from "../../checkout/ZipcodeModal";

const CheckCoverageComponent = (props: CheckCoverageProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    checkCoverageHandler,
    coverageContent,
    checkAddress,
    coverageLoading,
    coverageAddress,
    signal4G,
    signal5G,
    coverageError,
    navigateCheckCoverage,
    coverageModal,
    checkDisableHandler,
    signalModal,
    openCoverageModal,
    closeModalHandler,
    searchAgainHandler,
    exploreHandler,
    showBox,
    setShowBox,
    closeZipModal,
    zipcodeOpen,
    setZipcodeOpen,
    clickHandler,
    content,
  } = props;

  const {
    tick_icon,
    heading,
    sub_heading,
    modal_heading,
    modal_subheading,
    place_holder,
    signal_note2,
    button_1,
    button_2,
    button_3,
    button_4,
    button_5,
    error,
    coverage_1,
    coverage_2,
    signal_strength1,
    signal_strength2,
    signal_strength3,
    signal_strength4,
    alt_image_text,
    fair_signal,
    good_signal,
    great_signal,
    no_coverage,
    coverage_map,
    modal_heading2,
    great_signal_code,
    no_signal_code,
    good_signal_code,
    fair_signal_code
  } = coverageContent || {};
  const isCompleteAddressDisplayFormat =
    addressDisplayPattern.test(checkAddress);
  const displayAddressValue = isCompleteAddressDisplayFormat
    ? checkAddress.replace(/,(\s*\d{5})$/, "$1")
    : checkAddress;
  const signalImage =
    signal5G === "fair"
      ? fair_signal_code
      : signal5G === "good"
      ? good_signal_code
      : signal5G === "great"
      ? great_signal_code
      : no_signal_code
  return (
    coverageContent && (
      <>
        <Box
          className={styles.check_coverage_container}
          minHeight={{ xs: "456px", sm: "614px", md: "340px" }}
        >
          <SecondaryInfoCard
            is_right={false}
            image={coverage_map?.data?.attributes?.url}
            btnWidth="200px"
            info={sub_heading}
            title={heading}
            btnTxt={button_1}
            onclick={openCoverageModal}
            alt={
              alt_image_text ?? coverage_map?.data?.attributes?.alternativeText
            }
          />
        </Box>
        <SimpleModal
          isShowModal={coverageModal || signalModal}
          onCloseModal={closeModalHandler}
          showClose={true}
          className={styles.coverage_modal}
          sx={{
            width: { xs: "95%", lg: "646px" },
            px: "30px !important",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: { xs: "30px 15px !important", sm: "30px 25px !important" },
          }}
        >
          {coverageModal ? (
            <>
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "22px", sm: "24px" },
                  fontFamily: "var(--font_family_Bold)",
                  fontWeight: "700",
                  my: "15px",
                  textAlign: "center",
                  color: "var(--title-color_1)",
                }}
                // className={styles.coverage_check_heading}
                data-testid="coverga_dialog_heading"
              >
                {modal_heading}
              </Typography>
              <Typography
                component="span"
                className={styles.coverage_check_subheading}
                sx={{ mb: "25px", textAlign: "center" }}
                data-testid="coverga_dialog_sub_heading"
              >
                {modal_subheading}
              </Typography>
              <Box sx={{ width: { xs: "100%", lg: "500px" } }}>
                <AutoComplete
                  value={displayAddressValue}
                  onChange={checkAddressHandler}
                  onSelect={(e: any, placeId: string) => {
                    handleSelect(placeId);
                  }}
                  placeholder={place_holder}
                  autoComplete="off"
                  name="address"
                  data-testid="coverga_dialog_textfiled_auto_complete"
                />
              </Box>
              {coverageError && (
                <Container
                  style={{ padding: 0 }}
                  className={styles.coverage_error}
                  data-testid="coverga_dialog_error"
                >
                  {error}
                </Container>
              )}
              <ButtonCust
                sx={{
                  my: 1,
                  width: { xs: "250px", sm: "40%" },
                  marginTop: "20px",
                }}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={() => checkCoverageHandler(coverageAddress)}
                disabled={checkDisableHandler()}
                loading={coverageLoading}
                data-testid="coverga_dialog_button"
              >
                {button_1}
              </ButtonCust>
            </>
          ) : (
            <>
              <Box
                component={"img"}
                src={tick_icon?.data.attributes.url}
                alt={tick_icon?.data.attributes.alternativeText}
                data-testid="coverga_dialog_icon"
              />
              <Typography
                variant="h5"
                sx={{
                  my: "15px",
                  textAlign: "center",
                  color: "var(--title-color_1)",
                  fontFamily: "var(--font_family_Bold) !important",
                  fontSize: "24px",
                  width: "60%",
                }}
                data-testid="coverga_dialog_heading_2"
              >
                {BrandNameUpdate(modal_heading2)}
              </Typography>

              {signal4G !== "no service" || signal5G !== "no service" ? (
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                    mt: "25px",
                  }}
                >
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="div"
                      // alt="success"
                      className={styles.coverage_image}
                      dangerouslySetInnerHTML={{
                        __html: updateSvgFillColor(
                          signalImage,
                          "var(--primary_color)"
                        ),
                      }}
                      data-testid="coverga_dialog_icon_2"
                    />
                  </Container>
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="div"
                      // alt="success"
                      className={styles.coverage_image}
                      dangerouslySetInnerHTML={{
                        __html: updateSvgFillColor(
                          signalImage,
                          "var(--primary_color)"
                        ),
                      }}
                      data-testid="coverga_dialog_icon_3"
                    />
                  </Container>
                </Container>
              ) : (
                <Box
                  component="img"
                  alt="success"
                  className={styles.modal_image}
                  src={no_coverage?.data?.[0]?.attributes?.url}
                  data-testid="coverga_dialog_image_1"
                />
              )}

              {signal4G !== "no service" || signal5G !== "no service" ? (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    my: 2.5,
                  }}
                  className={styles.coverage_final_text}
                  data-testid="coverga_dialog_final_text"
                >
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    sx={{ fontSize: "16px", textAlign: "center" }}
                    data-testid="coverga_dialog_strong_signal_text"
                  >
                    {coverage_1}{" "}
                    {signal4G === "fair"
                      ? signal_strength1
                      : signal4G === "good"
                      ? signal_strength2
                      : signal4G === "great"
                      ? signal_strength3
                      : signal_strength4}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    sx={{ fontSize: "16px", textAlign: "center" }}
                    data-testid="coverga_dialog_poor_signal_text"
                  >
                    {coverage_2}{" "}
                    {signal5G === "fair"
                      ? signal_strength1
                      : signal5G === "good"
                      ? signal_strength2
                      : signal5G === "great"
                      ? signal_strength3
                      : signal_strength4}
                  </Typography>
                </Container>
              ) : (
                <Container
                  sx={{ textAlign: "center", my: 2.5 }}
                  className={styles.coverage_final_text}
                  data-testid="coverga_dialog_signal_note_2"
                >
                  {signal_note2}
                </Container>
              )}
              <ButtonCust
                sx={{
                  my: 0.75,
                  width: "auto",
                  padding: { xs: "0px 20px", md: "0px 40px" },
                }}
                className={styles.detailed_coverage}
                onClick={navigateCheckCoverage}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                data-testid="coverga_dialog_button_5"
              >
                {button_5}
              </ButtonCust>
              {signal4G !== "no service" || signal5G !== "no service" ? (
                <Typography
                  variant="subtitle1"
                  sx={{ mt: "15px" }}
                  className={styles.detailed_coverage}
                  onClick={searchAgainHandler}
                  data-testid="coverga_dialog_btn_2"
                >
                  {button_2}
                </Typography>
              ) : (
                ""
              )}
              <Typography
                variant="subtitle1"
                sx={{ mb: "10px" }}
                className={styles.detailed_coverage}
                onClick={exploreHandler}
                data-testid="coverga_dialog_dc_text"
              >
                {signal4G !== "no service" || signal5G !== "no service"
                  ? button_3
                  : button_4}
              </Typography>
            </>
          )}
        </SimpleModal>
        <ZipcodeModal
          modalFg={zipcodeOpen}
          content={content}
          updateFg={true}
          closeHandler={closeZipModal}
          address={coverageAddress}
          showBox={showBox}
          setShowBox={setShowBox}
          clickHandler={clickHandler}
          showcheckcontent={true}
        />
      </>
    )
  );
};

export default CheckCoverageComponent;
