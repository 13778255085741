import { Box, Stack, Typography, makeStyles } from "@mui/material";
import { SupportTempProps } from "./Support";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import {
  BrandNameUpdate,
  formatPhoneNumber,
  updateSvgFillColor,
} from "../../../utils";

const SupportComponent = (props: SupportTempProps) => {
  const { content, chat_svg, phone_svg } = props.content;
  const navigate = useNavigate();

  return (
    <Stack
      sx={{ background: "var(--primary_brand_color_light)" }}
      data-testid="support_parent"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: { xs: "center", md: "center" },
          width: { xs: "80%", md: "70%" },
          margin: "0 auto",
          p: "35px",
          gap: { xs: "20px", md: "unset" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          component={"span"}
          sx={{
            fontFamily: {
              xs: "var(--font_family_Medium)",
              md: "var(--font_family_Semibold)",
            },
            color: "var(--dark-charcoal-fade)",
            fontSize: { xs: "14px", sm: "18px" },
          }}
          data-testid="support_title"
        >
          {content.title}
        </Typography>
        <Typography
          component={"span"}
          sx={{ display: "flex", gap: { xs: "25px", sm: "60px" } }}
        >
          <Box
            sx={{
              fontFamily: {
                xs: "var(--font_family_Medium)",
                md: "var(--font_family_Semibold)",
              },
              color: "var(--dark-charcoal-fade)",
              fontSize: { xs: "14px", sm: "18px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              component="div"
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              data-testid={`for_support_icon`}
              dangerouslySetInnerHTML={{
                __html: updateSvgFillColor(chat_svg, "var(--primary_color)"),
              }}
            />
            <Typography
              component={"span"}
              sx={{
                fontFamily: {
                  xs: "var(--font_family_Medium)",
                  md: "var(--font_family_Semibold)",
                },
                color: "var(--dark-charcoal-fade)",
                fontSize: { xs: "14px", sm: "18px" },
              }}
              data-testid="support_support_text"
            >
              {content.support}
            </Typography>
          </Box>
          <Box
            sx={{
              fontFamily: {
                xs: "var(--font_family_Medium)",
                md: "var(--font_family_Semibold)",
              },
              color: "var(--dark-charcoal-fade)",
              fontSize: { xs: "14px", sm: "18px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              component="div"
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              data-testid={`for_support_icon`}
              dangerouslySetInnerHTML={{
                __html: updateSvgFillColor(phone_svg, "var(--primary_color)"),
              }}
            />
            <Typography
              component={"span"}
              sx={{
                fontFamily: {
                  xs: "var(--font_family_Medium)",
                  md: "var(--font_family_Semibold)",
                },
                color: "var(--dark-charcoal-fade)",
                fontSize: { xs: "14px", sm: "18px" },
              }}
              data-testid="support_contact"
            >
              {formatPhoneNumber(BrandNameUpdate(content.contact))}
            </Typography>
          </Box>
        </Typography>
        <ButtonCust
          sx={{
            width: { xs: "250px", md: "200px" },
            height: "45px !important",
            color: "#ffffff",
            fontSize: "14px !important",
            fontFamily: "var(--font_family_Semibold) !important",
          }}
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
          onClick={() => navigate(RoutingURLs.help)}
          data-testid="support_btn"
        >
          {content.button}
        </ButtonCust>
      </Box>
    </Stack>
  );
};

export default SupportComponent;
