import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  c_num: any;
  styles: any;
  setOpenSupportDialog: any;
};

const PinHelperText = (props: Props) => {
  const { c_num, styles, setOpenSupportDialog } = props;
  return (
    <>
      <Box mb="20px" pt="40px" data-testid="activation_step_pin_helper_wrapper">
        <Typography className={styles.btm_title} sx={{ mb: "5px" }} data-testid="activation_step_pin_helper_pin">
          {c_num?.pin}
        </Typography>
        <Typography className={styles.btm_description} sx={{fontFamily: "var(--font_family_Regular)", fontSize: "14px"}} data-testid="activation_step_pin_helper_pin_text">
          {BrandNameUpdate(c_num?.pin_txt)}
        </Typography>
      </Box>
      <Box>
        <Typography className={styles.btm_title} sx={{ mb: "5px" }} data-testid="activation_step_pin_helper_car_info">
          {c_num?.car_info}
        </Typography>
        <Typography className={styles.btm_description} sx={{fontFamily: "var(--font_family_Regular)", fontSize: "14px"}} data-testid="activation_step_pin_helper_car_info_text">
          {c_num?.car_info_txt}&nbsp;
          <span
            // className={styles.btm_title}
            style={{
              color: "var(--primary_color)",
              cursor: "pointer",
            }}
            onClick={() => setOpenSupportDialog(true)}
            data-testid="activation_step_pin_helper_help_text"
          >
            {c_num?.help_txt}
          </span>
        </Typography>
      </Box>
    </>
  );
};
export default PinHelperText;
