import { Box, Typography } from "@mui/material";
import { ButtonCust, SearchBoxWithDropdown } from "../../../widgets";
import CountryDataModal from "./CountryDataModal";
import _ from "lodash";
import {
  capitalize,
  getResolutionDetails,
  updateSvgFillColor,
} from "../../../../utils/commonFunctions";
import Config from "../../../../config/env.config.json";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import { useAppSelector } from "../../../../app/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ViewRates = (props: any) => {
  const { view_Rates, back_icon, back_txt, desc, callAnywhere, phone_icon } = props.content;
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const {
    isCountryModal,
    countryModalHandler,
    selectedCountry,
    getCountryList,
    setSelectedCountryHandler,
    renderOption,
    setSelectedCountry,
  } = props;
  const { mobile } = getResolutionDetails();
  const onTextChange = (e: any) => {
    if (e.target.value === "") {
      setSelectedCountry(undefined);
    }
  };
  return (
    <>
      <CountryDataModal
        isCountryModal={isCountryModal}
        setIsCountryModal={countryModalHandler}
        selectedCountry={selectedCountry}
        view_Rates={view_Rates}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          textAlign: "center",
          top: "-50px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& span": {
              fontWeight: "var(--font_weight_5)",
            },
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              left: { xs: "0px", md: "103px" },
              top: { xs: "-94px", md: "auto" },
              cursor: "pointer",
              fontWeight: "var(--font_weight_3)",
            }}
            data-testid="view_rate_back"
            onClick={() => props.setIsViewRates(false)}
          >
            {!mobile ? (
              <ArrowBackIosIcon sx={{ width: "18px", fontSize: "1.125rem" }} data-testid="view_rate_back_icon"/>
            ) : (
              <Box
                component="img"
                src={back_icon?.data.attributes.url}
                alt={back_icon?.data.attributes.alternativeText}
                data-testid="view_rate_back_icon"
              />
            )}
            {!mobile && (
              <Box
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_1) !important",
                  fontSize: "18px",
                  lineHeight: "30px",
                }}
                component="span"
                data-testid="view_rate_back_txt"
              >
                {back_txt}
              </Box>
            )}
          </Box>
          <Box
            component="span"
            style={{ margin: "auto" }}
            sx={{
              fontFamily: "var(--font_family_Bold) !important",
              fontSize: { xs: "24px", md: "40px" },
              color: "var(--black)",
            }}
            data-testid="view_rate_sub_headig"
          >
            {view_Rates.subheading}
          </Box>
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "18px" },
              fontFamily: "var(--font_family_Medium)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: { xs: "20px", sm: "15px" },
              color: "var(--text_color)",
            }}
            data-testid="view_rate_call_anywhere"
          >
            {callAnywhere}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "inline-block",
            width: { xs: "100%", md: "670px !important" },
            mt: { xs: "20px", sm: "40px" },
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              // fontWeight: "var(--font_weight_0)",
              fontFamily: "var(--font_family_Medium)",
              textAlign: "left",
              pb: "5px",
            }}
            data-testid="view_rate_prem_equal"
          >
            {view_Rates.premium_qual}
          </Box>
          <SearchBoxWithDropdown
            dropdownList={getCountryList?.map((country: any) => ({
              ...country,
              countryName: capitalize(country?.countryName),
            }))}
            optionLabel={(option: any) =>
              option.name ? option.name : option.countryName
            }
            value={selectedCountry}
            onChange={setSelectedCountryHandler}
            placeholder={view_Rates.search_placeholder}
            onRequest={() => null}
            onCancel={() => setSelectedCountry("")}
            logo={view_Rates.search_icon}
            renderOption={renderOption}
            no_option={view_Rates.no_option}
            onTextChange={onTextChange}
            sx={{
              "& .MuiFilledInput-input": {
                fontFamily: "var(--font_family_Medium) !important",
                color: "var(--text-color) !important",
              },
            }}
          />
          {
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "24px" },
                  lineHeight: { xs: "30px", sm: "36px" },
                  color: "var(--black)",
                  fontWeight: {
                    xs: "var(--font_weight_4)",
                    sm: "var(--font_weight_2)",
                  },
                  fontFamily: {
                    xs: "var(--font_family_Bold)",
                    sm: "var(--font_family_Semibold)",
                  },
                  mt: { xs: "20px", sm: "30px" },
                  padding: { xs: "0px 7%", sm: "0px" },
                }}
                data-testid="view_rate_desc"
              >
                {desc}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* <Box
                  component="img"
                  src={view_Rates?.call_filled?.data.attributes.url}
                  alt={view_Rates?.call_filled?.data.attributes.alternativeText}
                  width={20}
                  height={20}
                /> */}
                <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "& svg": {
                    width: "20px",
                    height: "20px",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: updateSvgFillColor(    
                    phone_icon,
                    "var(--primary_color)"
                  ),
                }}
                data-testid="view_rate_phone_icon"
              />
                <Box
                  component="span"
                  sx={{
                    fontSize: { xs: "16px", sm: "20px" },
                    linHeight: "26px",
                    fontWeight: {
                      xs: "var(--font_weight_2)",
                      sm: "var(--font_weight_1)",
                    },
                    fontFamily: {
                      xs: "var(--font_family_Semibold)",
                      sm: "var(--font_family_Medium)",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  data-testid="view_rate_call_us"
                >
                  {view_Rates.call_US}
                </Box>
              </Box>
            </Box>
          }
          <Box
            sx={{
              display: "flex",
              fontFamily: "var(--font_family_Medium)",
              flexDirection: "column",
              marginTop: "30px",
            }}
          >
            <Box>
              <Typography
                onClick={() => {
                  window.open(globalVal?.helpCenter, "_blank");
                  gtmTagManager({
                    event: globalVal?.gtmEvents?.ILDFAQ,
                    click_section: "International Calling",
                  });
                }}
                sx={{
                  color: "var(--primary_color) !important",
                  padding: "0 20px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Semibold)",
                  fontWeight: "var(--font_weight_2)",
                }}
                data-testid="view_rate_ask_q"
              >
                {view_Rates?.ask_question}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--primary_color)",
                cursor: "pointer",
                fontFamily: "var(--font_family_Semibold)",
                fontWeight: "var(--font_weight_2)",
                mt: "30px",
                lineHeight: "26px",
              }}
              onClick={() => {
                window.open(props?.content?.terms_link, "_blank");
                gtmTagManager({
                  event: globalVal?.gtmEvents?.IRTermsAndConditions,
                  click_section: "International Calling",
                });
              }}
              data-testid="view_rate_term_txt"
            >
              {view_Rates?.terms_text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewRates;
