import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../utils";

interface ErrorComProps {
  submitHandler?: any;
  faultyError?: any;
}
export const ErrorComp = (props: ErrorComProps) => {
  let globalVal: any = localStorage.getItem("globalValues");
  globalVal = JSON.parse(globalVal);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "top",
        background: "#F6F6F6",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "800px" },
          background: "#FFF",
          borderRadius: "10px",
          padding: "40px 10px",
          textAlign: "center",
          height: "max-content",
          marginTop: "8%",
        }}
      >
        <img
          src={globalVal?.error_comp_icon?.data?.attributes?.url}
          alt={globalVal?.error_comp_icon?.data?.attributes?.alternativeText}
          style={{ width: "35px", height: "35px" }}
        />
        <Typography
          component={"p"}
          sx={{
            marginTop: "10px",
            color: "#000000",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 700,
            fontFamily: "poppins",
          }}
        >
          {BrandNameUpdate(props?.faultyError ? globalVal?.error_comp_header1 : globalVal?.error_comp_header)}
        </Typography>
        <Typography
          component={"p"}
          sx={{
            width: { xs: "100%", sm: "355px" },
            margin: "0 auto",
            paddingTop: "5px",
            color: "#56585E",
            fontSize: "16px",
            lineHeight: "23px",
            fontWeight: 400,
            fontFamily: "poppins",
          }}
        >
          {BrandNameUpdate(props?.faultyError ? globalVal?.error_comp_sub_header1 : globalVal?.error_comp_sub_header)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorComp;
